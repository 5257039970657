/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Route, Switch } from "react-router";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import logger from "redux-logger";
import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";

import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import rootReducer from "store/reducers/root";

import RegistrationActions from "store/actions/forms/registration";
import AuthenticationActions from "store/actions/auth";
import SystemActions from "store/actions/system";
import LeadsActions from "store/actions/recordsets/leads";

import { chain } from "store/actions/util";
import { push } from "connected-react-router";

import { firebase } from "firebase/client";
import "@firebase/auth";
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const hist = createBrowserHistory();

const stateStore = createStore(
  rootReducer(hist),
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(hist), logger))
);

// Initialize anything that doesn't require a login
stateStore.dispatch(RegistrationActions.initialize());

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // Do initialization that requires user login
    stateStore.dispatch(
      chain(
        AuthenticationActions.startLoadProfile(user),
        AuthenticationActions.adminStateInit(user),
        LeadsActions.initialize(),
        SystemActions.setReady(true)
      )
    );
    if (window.location.pathname === "/") {
      console.log("window.location.pathname", window.location.pathname === "/");
      hist.push("/admin/dashboard");
    } else {
      hist.push(window.location.pathname);
    }
  } else {
    if (window.location.pathname.includes("/auth/reservation-form")) {
      stateStore.dispatch(push("/auth/reservation-form"));
    } else {
      if (window.location.pathname.includes("/auth/temp-form")) {
        stateStore.dispatch(push("/auth/temp-form"));
      } else {
        if (window.location.pathname.includes("invoice")) {
          // stateStore.dispatch(push("/auth/login-page"));
        } else {
          stateStore.dispatch(push("/auth/login-page"));
        }
      }
    }
  }
});

ReactDOM.render(
  <Provider store={stateStore}>
    <ConnectedRouter history={hist}>
      <Switch>
        <Route path="/rtl" component={RtlLayout} />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
