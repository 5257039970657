import ExplorerCard from "./ExplorerCard";
import { connect } from "react-redux";
import BoundCardFields from "components/Card/BoundCardFields";
import BoundCardField from "components/Card/BoundCardField";
import actionsForNavigation from "store/actions/navigation";

function mapStateToProps(actionsForRecordset, accessorsForRecordset) {

    const CardFields = BoundCardFields(actionsForRecordset, accessorsForRecordset);
    const CardField = BoundCardField(actionsForRecordset, accessorsForRecordset);

    return (state, ownProps) => {
        return {
            CardFields: ownProps.CardFields || CardFields,
            CardField: ownProps.CardField || CardField
        };
    };
}

function mapDispatchToProps() {    
    return (dispatch) => ({
        openChild: (name, route) => dispatch(actionsForNavigation.openChild(name, route)),
    });
}

export default (actionsForRecordset, accessorsForRecordset)=>connect(mapStateToProps(actionsForRecordset, accessorsForRecordset), mapDispatchToProps())(ExplorerCard);
