import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Button from "components/CustomButtons/Button.js";
function SearchCustomers(props) {
  const { classes, searchString, setSearchString, handleSearchQuery } = props;

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem xs={12} style={{margin: "0px 0px 10px 0px"}}>
        <p className={classes.subHeader}>Search Customers</p>
      </GridItem>
      <GridItem xs={12}>
        <TextField
          className={classes.searchBar}
          placeholder="Search by name, company/organization, address, city, zip, phone, email"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          variant="outlined"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearchQuery()
            }
          }}
          InputProps={{
            classes: {
              adornedEnd: classes.adournedEnd,
            },
            endAdornment: (
              <InputAdornment className={classes.adournedEnd} position="end">
                <Button onClick={handleSearchQuery} className={classes.searchButton}>Search</Button>
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log('statteee', state)
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(dashboardStyles)
)(SearchCustomers);
