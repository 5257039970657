import React from "react";

import BoundEditorForm from 'components/Explorer/BoundEditorForm';

import actionsForExplorer from 'store/actions/explorers/leads';
import accessorsForExplorer from 'store/reducers/explorers/leads';

const EditorForm = BoundEditorForm(actionsForExplorer.newForm, accessorsForExplorer.newForm);

export default function NewLead(props) {
    return (
        <EditorForm order={["firstName", "lastName", "currentScore", "goalScore", "state", "notes"]}/>
    );
}