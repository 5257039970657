import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { BsJustify } from "react-icons/bs";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const selectOptions = ["Rental Number", "Scheduled Date", "End Date", "Site Address"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Inventory Size", "All Sizes", "10 Yard Dumpster", "15 Yard Dumpster",
  "20 Yard Dumpster", "30 Yard Dumpster", "Metal/Concrete", "Standard 30 Yard"];

const checkboxArr = ["10 Yard Dumpster", "15 Yard Dumpster",
  "20 Yard Dumpster", "30 Yard Dumpster", "Metal/Concrete", "Standard 30 Yard"]

function Filters(props) {
  const {
    classes,
    sortBySelection,
    setSortBySelection,
    setDirectionSelection,
    directionSelection,
    setFilterSelection,
    filterSelection,
    setSearchString,
    searchString,
    handleFilters,
    handleSearch,
    handleCommentFilter
  } = props;
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  return (
    <div>
      <GridContainer>
        <GridItem className={classes.filtersGridItem} xs={12} style={{ background: "#929292", }}>
          <h5
            className={classes.subHeader}
            style={{
              margin: "0px",
              fontSize: "19px",
              justifyContent: "center"
            }}>
            Filter by Product
          </h5>
        </GridItem>
        <GridItem xs={12} style={{ flexDirection: "column", display: "flex" }}>
          {checkboxArr.map((data, index) => {
            return (
              <span>
                <Checkbox
                  className={classes.checkboxes}
                // onChange={() => setPublishEmail(!publishEmail)}
                // checked={publishEmail}
                />
                {data}
              </span>
            )
          })}


        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(Filters));
