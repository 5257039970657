export const userTypes = {
    CLIENT: "Client",
    LAWYER: "Lawyer"
}

export const uris = Object.freeze({
    HOME: "/admin/dashboard"
});

export const logging = Object.freeze({
    LOG_ASYNC_ACTIONS : true
});