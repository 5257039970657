import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Card from "components/Card/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import { reportSelections } from "../../config/globals";
import Chart from "./Chart.jsx";
import { BsSquare } from "react-icons/bs";

const sortByOptions = ["Total Unpaid", "Scheduled Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];

const viewOptions = ["1 Month", "3 Months", "6 Months", "1 Year"];

function VolumnByTerritoryChart(props) {
  const {
    classes,
    agingData,
    history,
    setCheckedCount,
    checkedCount,
    setAgingData,
    setCheckedAll,
    checkedAll,
    inventoryData,
  } = props;
  const [tableSelection, setTableSelection] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [type, setType] = useState("1 Year");

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem xs={12}>
        <Card style={{ color: "#323232", marginTop: "0px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "20px",
              maxHeight: "80px",
            }}
          >
            <div className={classes.jobsGridItem}>
              <span className={classes.switchReport}>Switch Report</span>
              <Select
                variant="outlined"
                className={classes.filterMenu}
                value={tableSelection}
                sx={{ background: "#F5F5F5" }}
                disableUnderline
                defaultValue={"Select Option"}
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => history.push(e.target.value.link)}
                displayEmpty
                renderValue={
                  tableSelection !== "" ? undefined : () => "Volume by Territory"
                }
              >
                {reportSelections.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItemReport,
                      }}
                    >
                      {o.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div
              style={{ justifyContent: "space-between" }}
              className={classes.jobsGridItem}
            >
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "80%",
                  marginRight: "10px",
                }}
                selected={fromDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setFromDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      className={classes.datetime}
                      placeholder="From"
                      value={fromDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "80%",
                }}
                selected={toDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setToDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      className={classes.datetime}
                      placeholder="To"
                      value={toDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Button className={classes.exportCSV2}>Export CSV</Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "0px 20px 20px 20px",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                border: "2px solid #323232",
                borderRadius: "5px",
                background: "#323232",
                width: "29%",
                height: "45px",
              }}
            >
              {viewOptions.map((v, index) => {
                if (v === type) {
                  return (
                    <div
                      key={index}
                      onClick={() => setType(v)}
                      className={classes.viewSelection}
                      style={{ background: "#323232", color: "white", padding: "5px" }}
                    >
                      {v}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      onClick={() => setType(v)}
                      className={classes.viewSelection}
                      style={{ background: "white", color: "#323232", padding: "5px" }}
                    >
                      {v}
                    </div>
                  );
                }
              })}
            </div>
            <div style={{ display: "flex", width: "100%", marginTop: "15px" }}>
              <div style={{ width: "75%" }}>
                <Chart />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {inventoryData.map((d, index) => {
                  return (
                    <div
                      key={d.inventoryId}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          background: d.color,
                          height: "20px",
                          width: "20px",
                          marginRight: "20px",
                        }}
                      ></div>
                      <span className={classes.inventoryItemName}>
                        <BsSquare
                          style=
                          {d.inventoryItem.includes("10") ?
                            { background: "#929292", marginRight:"20px" }
                            : d.inventoryItem.includes("15") ?
                              { background: "#D9D9D9", marginRight:"20px" }
                              : d.inventoryItem.includes("20") ?
                                { background: "#323232", marginRight:"20px" }
                                : { background: "#FFC821", marginRight:"20px" }
                          }
                        /> {" "}
                        {d.inventoryItem}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("state.inventory", state.inventory);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(VolumnByTerritoryChart));
