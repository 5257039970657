import React from "react";
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import SortByAlpha from "@material-ui/icons/SortByAlpha";
import { Operators, Sorts, DataTypes } from "variables/constants";
import { withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/queryFieldStyle";
import { ControlTypes } from "variables/constants";

const { equalTo, notEqualTo, startsWith, ...otherOperators } = Operators;

function getOperators(type) {
    switch(type) {
        case DataTypes.ENUM:
        case DataTypes.BOOLEAN:
            return [ equalTo, notEqualTo ];
        case DataTypes.NUMBER:
        case DataTypes.DATETIME:
                return [ equalTo, notEqualTo, ...Object.values(otherOperators) ]
        default:
            return Object.values(Operators);
    }    
}

function getControlType(type, values) {
    switch (type) {
        case DataTypes.DATETIME:
            return ControlTypes.DATETIME;
        case DataTypes.BOOLEAN:
            return ControlTypes.SELECT;
        default:
            if (values) 
                return ControlTypes.SELECT;
            else
                return ControlTypes.INPUT;
    }
}

function getFormat(type) {
    switch (type) {
        case DataTypes.DATETIME:
            return value=>value.toISO({includeOffset: false});
        case DataTypes.BOOLEAN:
            return value=>String(value);
        default:
            return value=>value;
    }
}

function applyFormat(format, value) {
    if (value === '' || value === undefined) return '';
    if (typeof value === 'string') return value;
    return format(value);
}

export function QueryField(props) {

    const { id, type, inputProps: baseInputProps, operatorProps, sortProps, values, classes, ...rest } = props;

    const { value, ...inputProps } = baseInputProps;

    const format = getFormat(type);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSetOperator = (operator) => {
        setAnchorEl(null);
        if (operator !== operatorProps.value) {
            if (operatorProps.onChange) operatorProps.onChange(operator);
        }
    };

    const handleSetSort = (sort) => {
        setAnchorEl(null);
        if (sortProps.onChange) sortProps.onChange(sort);
    };    

    const startAdornment = (
        <InputAdornment position="start">
            <Button
                className = {classes.operatorButton}
                aria-label="set comparison type"
                onClick={handleClick}
                name="operator"
            >
                { operatorProps.value ? operatorProps.value.shortForm : "..."}
            </Button>
        </InputAdornment>
    );

    const endAdornment = (
        <InputAdornment position="end">
            <Button
                className = {classes.operatorButton}
                aria-label="add to sort order"
                onClick={handleClick}
                name="sort"
            >
                <SortByAlpha/>
            </Button>
        </InputAdornment>
    );

    function getControl() {
        const controlType = getControlType(type, values);
        switch(controlType) {
            case ControlTypes.SELECT:
                let controlValues = (type === DataTypes.BOOLEAN) ? {"true" : "set", "false" : "unset"} : values;
                return (
                    <CustomSelect { ...rest } 
                        id={`${id}-input`} inputProps={{ ...inputProps, value: applyFormat(format, value), startAdornment, endAdornment }} values={controlValues}                        
                    />
                );
            default:
                return (
                    <CustomInput { ...rest } 
                        id={`${id}-input`} inputProps={{ ...inputProps, value: applyFormat(format, value), startAdornment, endAdornment, type: controlType.inputType }}
                    />
                );
        }
    }


    return (
        <div>
            { getControl()}
            <Menu
                id={`${id}-comparison-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl && anchorEl.name === "operator")}
                onClose={handleClose}
            >
                {getOperators(type).map((value, index) => 
                    <MenuItem key={index} onClick={e => handleSetOperator(value)}>{value.longForm}</MenuItem>
                )}
            </Menu>
            <Menu
                id={`${id}-sort-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl && anchorEl.name === "sort")}
                onClose={handleClose}
            >
                <MenuItem onClick={e => handleSetSort(Sorts.ASCENDING)}>Sort Ascending</MenuItem>
                <MenuItem onClick={e => handleSetSort(Sorts.DESCENDING)}>Sort Descending</MenuItem>
            </Menu>            
        </div>
    );
}

export default withStyles(styles)(QueryField);
