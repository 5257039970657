import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";
import "views/Dashboard/components/pagination.css";
import Datetime from "react-datetime";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconWhite.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIconBlack.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";
import { ReactComponent as CreateRefund } from "assets/img/createRefund.svg";
import { ReactComponent as ApplyCredit } from "assets/img/applyCredit.svg";
import { ReactComponent as VoidPayment } from "assets/img/voidPayment.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";

import AccountingCustomTabs from "./AccountingCustomTabs";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowRight from "@material-ui/icons/ArrowRight";
import moment from "moment";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { Menu } from "@material-ui/core";


const mockData = [
  {
    id: "P242115",
    date: "Oct 18, 2022",
    method: "American Express xx3424",
    transactionId: "3242351457989",
    amount: "387.00",
    remaining: "0.00",
    status: "void"
  }
]
const headers = [
  "ID",
  "Date",
  "Method",
  "Transaction ID",
  "Amount",
  "Remaining",
  "Status",
  "Actions",
];
const sortByOptions = ["Payment Date", "Payment Number", "Amount Remaining"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];
const statementOptions = ["Statement", "Email Detail Statement", "Email Summary Statement"]

const pageArr = ["8", "All"];

function AccountingPaymentsTab(props) {
  const { classes, customerInfo, payments, history } = props;
  const [sortBy, setSortBy] = useState("Payment Date");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterBySelection, setFilterBySelection] = useState();
  const [statementSelection, setStatementSelection] = useState("Statement");
  const [searchString, setSearchString] = useState("");
  const [checkedCount, setCheckedCount] = useState(0);
  const [checkedAll, setCheckedAll] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterFromDate, setFilterFromDate] = useState("")
  const [filterToDate, setFilterToDate] = useState("")
  const itemsPerPage = 8;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("8");
  const [secOpen, setSecOpen] = useState(false)
  const [thirdOpen, setThirdOpen] = useState(false)
  const [menu, setMenu] = useState(null);
  const menuOpen = Boolean(menu);
  const [statusValue, setStatusValue] = useState("")

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  useEffect(() => {
    customerInfo.reservations.slice(index, endIndex);
  }, [index]);

  const renderTableHeader = () => {
    return (
      <tr className={classes.notesTableRow}>
        {headers.map((key, index) => {
          return (
            <th className={classes.notesTableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    let data;
    if (pageDropdown == "8") {
      data = mockData.slice(
        pageNumber * itemsPerPage,
        pageNumber * itemsPerPage + itemsPerPage
      );
    }
    else {
      data = mockData
    }
    return mockData.map((d, index) => {
      // const paymentDate = d.paymentDate.toDate();
      return (
        <tr className={classes.notesTableRow} key={d.paymentId}>
          <td
            // onClick={() =>
            //   history.push({
            //     pathname: `/admin/payment/${d.paymentId}`,
            //     state: { payment: d },
            //   })
            // }
            style={{ color: "#0071C5", textDecoration: "underline" }}
            className={classes.notesTableCell}
          >
            {/* {d.paymentId} */}
            {d.id}
          </td>
          <td className={classes.notesTableCell}>
            {" "}
            {/* {moment(paymentDate).format("MM/DD/YY")} */}
            {d.date}
          </td>
          <td className={classes.notesTableCell}>
            {/* {d.card.brand.toUpperCase()} xx {d.card.last4} */}
            {d.method}
          </td>
          <td className={classes.notesTableCell}>{d.transactionId}</td>
          <td className={classes.notesTableCell}>
            {/* {d.amountReceived} */}
            {d.amount}
          </td>
          <td className={classes.notesTableCell}>$0.00</td>
          <td className={classes.notesTableCell}>
            <div
              style={
                d.Status === "Unapplied"
                  ? { background: "#0071C5" }
                  : d.Status === "Closed"
                    ? { background: "##3F9C42" }
                    : d.Status === "Void"
                      ? { background: "#D9D9D9", color: "black" }
                      : { background: "#E0AC12", color: "black" }
                // { background: "#2EB67D" }
              }
              className={classes.statusDiv}
            >
              {d.status}
            </div>
          </td>
          <td className={classes.notesTableCell}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Tooltip
                id="tooltip-top"
                title="View Details"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{ background: "#FFC821" }}
                  className={classes.actionBtn}
                >
                  <EyeIcon />
                </Button>
              </Tooltip>
              <Tooltip
                id="tooltip-top"
                title="Edit Payment"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#323232" }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Send Email"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#FEDA1B" }}
                >
                  <MailIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Create Refund"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#929292", border: "1px solid #323232" }}
                >
                  <CreateRefund />
                </Button>
              </Tooltip>


              <Tooltip
                id="tooltip-top"
                title="Void Payment"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#F5EB29" }}
                >
                  <VoidPayment />
                </Button>
              </Tooltip>

            </div>
          </td>
        </tr>
      );
    });
    // }
  };

  return (
    <Card className={classes.tabCard3} style={{ paddingBottom: "-60px" }}>
      <GridContainer>
        <GridItem xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => history.push('/create-invoice')}
            className={classes.addCustomer} style={{ marginRight: "12px", textTransform: "capitalize" }}
          >
            <AddIcon style={{ marginRight: "12px" }} /> Record Payment
          </Button>
        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={1}>
          <h5 className={classes.subHeader}>Sort By</h5>
        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={6}>
          <Select
            className={classes.filterMenu}
            value={sortBy}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setSortBy(e.target.value)}
          >
            {sortByOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            style={{ fontFamily: "Archivo", width: "12%" }}
            className={classes.ascendingMenu}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            value={directionSelection}
            onChange={(e) => setDirectionSelection(e.target.value)}
          >
            {directionOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Button
            style={filterBySelection ? {
              height: "50px",
              width: "150px",
              marginLeft: "10px",
              fontWeight: 500,
              fontSize: "16px",
              background: "#FEDA1B",
              "&:hover": {
                color: "#323232",
                cursor: "pointer",
              }
            }
              : {
                height: "50px",
                width: "150px",
                marginLeft: "10px",
                fontWeight: 500,
                fontSize: "16px",
                background: "#D9D9D9",
                "&:hover": {
                  color: "#323232",
                  cursor: "pointer",
                }
              }}
            className={ classes.addCustomer }
            onClick={(e) => setMenu(e.currentTarget)}
          >
          Filter By
        </Button>
        <Menu
          anchorEl={menu}
          open={menuOpen}
          onClose={() => setMenu(null)}
          classes={{
            paper: classes.menuPaper
          }}
        >
          <p className={classes.dateRange}>Payment Method</p>
          <MenuItem>
            <Select
              open={thirdOpen}
              onOpen={() => setThirdOpen(true)}
              onClose={() => setThirdOpen(false)}
              style={{ width: "205px", height: "40px", marginLeft: "-7px" }}
              className={classes.filterMenu}
              value={filterBySelection}
              disableUnderline
              classes={{
                root: classes.selectText,
              }}
              // onChange={(e) => handleFilters("status", e.target.value)}
              onChange={(e) => setFilterBySelection(e.target.value)}
            >
              {sortByOptions.map((o, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={o}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {o}
                  </MenuItem>
                );
              })}
            </Select>
          </MenuItem>
          <p className={classes.dateRange}>Status</p>
          <MenuItem>
            <Select
              open={secOpen}
              onOpen={() => setSecOpen(true)}
              onClose={() => setSecOpen(false)}
              style={{ width: "205px", height: "40px", marginLeft: "-7px" }}
              className={classes.filterMenu}
              value={statusValue}
              disableUnderline
              classes={{
                root: classes.selectText,
              }}
              // onChange={(e) => handleFilters("status", e.target.value)}
              onChange={(e) => { setStatusValue(e.target.value) }}
            >
              {filterOptions.map((o, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={o}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {o}
                  </MenuItem>
                );
              })}
            </Select>
          </MenuItem>

          <p className={classes.dateRange}>Start Date Range</p>
          <MenuItem style={{ display: "contents" }}>
            <Datetime
              style={{
                color: "#323232 !important",
                // height: "100%",
                width: "100%",
              }}
              selected={fromDate}
              dateFormat="MM/DD/YY"
              onChange={(date, e) => {
                setFromDate(date.format("MM/DD/YY"));
              }}
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside
              renderInput={(props, openCalendar, closeCalendar) => (
                <div onClick={openCalendar}>
                  <TextField
                    style={{ width: "200px", }}
                    className={classes.datetime}
                    placeholder="From"
                    value={fromDate}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        adornedEnd: classes.adournedEnd,
                      },
                      endAdornment: (
                        <InputAdornment
                          className={classes.adournedEnd}
                          position="end"
                        >
                          <CalendarIcon
                            className={classes.calendarIcon}
                            onClick={openCalendar}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
            />
          </MenuItem>
          <p className={classes.dateRange}>End Date Range</p>
          <MenuItem style={{ display: "contents" }}>
            <Datetime
              style={{
                color: "#323232 !important",
                height: "100%",
                width: "100%",
              }}
              selected={toDate}
              dateFormat="MM/DD/YY"
              onChange={(date, e) => {
                setToDate(date.format("MM/DD/YY"));
              }}
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside
              renderInput={(props, openCalendar, closeCalendar) => (
                <div onClick={openCalendar}>
                  <TextField
                    style={{ width: "200px" }}
                    className={classes.datetime}
                    placeholder="To"
                    value={toDate}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        adornedEnd: classes.adournedEnd,
                      },
                      endAdornment: (
                        <InputAdornment
                          className={classes.adournedEnd}
                          position="end"
                        >
                          <CalendarIcon
                            className={classes.calendarIcon}
                            onClick={openCalendar}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
            />
          </MenuItem>
        </Menu>
      </GridItem>
      <GridItem className={classes.filtersGridItem} xs={5}>
        <Datetime
          style={{
            color: "#323232 !important",
            height: "100%",
            width: "100%",
            padding: "11.5px 14px"
          }}
          selected={fromDate}
          dateFormat="MM/DD/YY"
          onChange={(date, e) => {
            setFromDate(date.format("MM/DD/YY"));
          }}
          timeFormat={false}
          closeOnSelect={true}
          closeOnClickOutside
          renderInput={(props, openCalendar, closeCalendar) => (
            <div onClick={openCalendar}>
              <TextField
                className={classes.datetime}
                placeholder="From"
                value={fromDate}
                variant="outlined"
                InputProps={{
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <CalendarIcon
                        className={classes.calendarIcon}
                        onClick={openCalendar}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        />
        <Datetime
          style={{
            color: "#323232 !important",
            height: "100%",
            width: "100%",
          }}
          selected={toDate}
          dateFormat="MM/DD/YY"
          onChange={(date, e) => {
            setToDate(date.format("MM/DD/YY"));
          }}
          timeFormat={false}
          closeOnSelect={true}
          closeOnClickOutside
          renderInput={(props, openCalendar, closeCalendar) => (
            <div onClick={openCalendar}>
              <TextField
                className={classes.datetime}
                placeholder="To"
                value={toDate}
                variant="outlined"
                InputProps={{
                  classes: {
                    adornedEnd: classes.adournedEnd,
                    style: { padding: "11.5px 14px" }
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <CalendarIcon
                        className={classes.calendarIcon}
                        onClick={openCalendar}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        />
        <Button className={classes.createInvoiceButton}>Export CSV</Button>
      </GridItem>

      <GridItem xs={2}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            onChange={() => setCheckedAll((prevState) => !prevState)}
            checked={checkedAll}
          />
          <span style={{ fontSize: "18px", whiteSpace: "noWrap" }}>
            Select All ({checkedCount} of
            {/* {invoicesState.length} */}
            )
          </span>
        </div>
      </GridItem>
      <GridItem xs={4}>
        <Select
          style={{ height: "45px", fontFamily: "Archivo", width: "60%" }}
          className={classes.selectMenuSort}
          value={statementSelection}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => setStatementSelection(e.target.value)}
        >
          {statementOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>
      <GridItem xs={6}>
        <TextField
          className={classes.searchBar}
          placeholder="Search Payments"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          variant="outlined"
          InputProps={{
            classes: {
              adornedEnd: classes.adournedEnd,
            },
            endAdornment: (
              <InputAdornment className={classes.adournedEnd} position="end">
                <Button className={classes.searchButton}>Search</Button>
              </InputAdornment>
            ),
          }}
        />
      </GridItem>

      <GridItem xs={12}>
        <div
          style={
            pageDropdown != 3 && customerInfo.reservations.length > 3
              ? { height: "270px", width: "100%", overflowY: "scroll" }
              : null
          }
        >
          <table
            style={{ marginTop: "30px" }}
            className={classes.inventoryTable}
          >
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
        {pageDropdown == "3" ? (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={
                      {
                        // className: classes.selectMenu,
                      }
                    }
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginTop: "5px" }}>
              <h5
                className={classes.indexes}
              >
                {index + 1} - {endIndex} of{" "}
                {Math.ceil(customerInfo.reservations.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(
                  customerInfo.reservations.length / itemsPerPage
                )}
                nextLabel={
                  <ArrowRight
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                previousLabel={
                  <ArrowLeft
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={
                      {
                        // className: classes.selectMenu,
                      }
                    }
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
    </Card >
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(AccountingPaymentsTab));
