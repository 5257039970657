import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import { db } from "firebase/client";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

import AccountingCustomTabs from "./components/AccountingCustomTabs";
import AccountingPaymentsTab from "./components/AccountingPaymentsTab";
import AccountingRefunds from "./components/AccountingRefunds";
import AccountingInsideInvoicesTab from "./components/AccountingInsideInvoicesTab";
import CreditMemos from "./components/CreditMemos";
import { setPastReservations } from "store/actions/reservations/reservationsActions";
import loader from "assets/img/loader.gif";

function Accounting(props) {
  const {
    classes,
    reservationsData,
    pastReservations,
    handleSetPastReservations,
    history,
    tabIndex,
    creditMemos,
  } = props;
  const [invoices, setInvoices] = useState(false);
  const [payments, setPayments] = useState(false);
  const [startPeriod, setStartPeriod] = useState(new Date());
  const [endPeriod, setEndPeriod] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [timeSelect, setTimeSelect] = useState([]);
  const [tabName, setTabName] = useState("")
  const detectCurrentQuarterAndYear = (date) => {
    const currentMonth = date.getMonth();
    for (const quarter in quarterKey) {
      if (
        currentMonth >= quarterKey[quarter][0] &&
        currentMonth <= quarterKey[quarter][1]
      ) {
        return `${quarter} ${date.getFullYear()}`;
      }
    }
  };
  const [timePeriod, setTimePeriod] = useState(null);
  const [invoicesLoaded, setInvoicesLoaded] = useState(false);

  const monthDays = [0, 2, 4, 6, 7, 9, 11];

  const quarterKey = {
    Q4: [9, 11],
    Q3: [6, 8],
    Q2: [3, 5],
    Q1: [0, 2],
  };

  useEffect(() => {
    setTimeSelect(() => {
      const options = [];
      const today = new Date();
      const thisYear = today.getFullYear();
      for (let i = thisYear; i >= 2020; i--) {
        for (const quarter in quarterKey) {
          options.push(`${quarter} ${i}`);
        }
      }
      return options;
    });
  }, []);

  useEffect(() => {
    if (!timePeriod) {
      setTimePeriod(detectCurrentQuarterAndYear(new Date()));
      return;
    }
    const [quarter, timeYear] = timePeriod.split(" ");
    const [startMonth, endMonth] = quarterKey[quarter];
    let endMonthDay;
    if (monthDays.includes(endMonth)) {
      endMonthDay = 31;
    } else if (endMonth === 1) {
      endMonthDay = 28;
    } else {
      endMonthDay = 30;
    }

    const getInvoices = async () => {
      setInvoicesLoaded(false);
      return db
        .collection("invoices")
        .where("invoiceDate", ">=", new Date(timeYear, startMonth, 1))
        .where("invoiceDate", "<=", new Date(timeYear, endMonth, endMonthDay))
        .get()
        .then((snapshots) => {
          if (!snapshots.empty) {
            const allInvoices = [];
            const customerPromiseArray = [];
            snapshots.forEach((s) => {
              s = s.data();
              let id = s.customerId || s["Customer ID"];
              allInvoices.push(s);
              customerPromiseArray.push(
                db.collection("customers").doc(id).get()
              );
            });
            return Promise.all(customerPromiseArray).then((results) => {
              results.forEach((snapshot, index) => {
                snapshot = snapshot.data();
                allInvoices[index] = {
                  ...allInvoices[index],
                  customerInfo: snapshot,
                };
              });
              setInvoicesLoaded(true);
              setInvoices(allInvoices);
            });
          } else {
            setInvoices([]);
          }
        });
    };
    getInvoices();
    // }
  }, [timePeriod]);

  if (!invoices)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );

  return (
    <GridContainer>
      <GridItem xs={4}>
        <h1 style={{ textAlign: "left" }} className={classes.accountingTitle}>
          Accounting
        </h1>
      </GridItem>
      <GridItem xs={2} style={{ marginTop: "-25px" }}>
        <Card style={{ border: "2px #3F9C42 solid", marginBottom: "15px" }}>
          <p className={classes.paragraph}> $320.00</p>
          <p className={classes.paragraph} style={{ color: "#0071C5", marginTop: "-20px" }}>
            {reservationsData.length + " " + "OPEN INVOICE(S)"}
          </p>
        </Card>

      </GridItem>
      <GridItem xs={2} style={{ marginTop: "-25px" }}>
        <Card style={{ border: "2px #F83030 solid", marginBottom: "15px" }}>
          <p className={classes.paragraph}> $1,345.00</p>
          <p className={classes.paragraph} style={{ color: "#0071C5", marginTop: "-20px" }}>
            {reservationsData.length + " " + "OVERDUE"}
          </p>
        </Card>

      </GridItem>
      <GridItem xs={2} style={{ marginTop: "-15px" }}>
        <Card
          style={{
            border: "0.5px #929292 solid",
            width: "80%",
            marginLeft: "29px",
            padding: "5px",
            background: "#f5f5f5"
          }}
        >
          <p className={classes.paragraph}>
            Email Queue (0)
          </p>
        </Card>

      </GridItem>
      <GridItem xs={2}>
        {tabName === "Payments" ?
          <Button
            style={{ width: "100%", background: "#FFC821", color: "#323232", fontWeight: 700, marginRight: "15px" }}
            className={classes.createInvoiceButton}
            onClick={() => history.push('/admin/create-invoice')}
          >
            <AddIcon />
            Record Payment
          </Button>
          :
          tabName === "Credit Memos" ?
            <Button
              style={{ width: "100%", background: "#FFC821", color: "#323232", fontWeight: 700, marginRight: "15px" }}
              className={classes.createInvoiceButton}
              onClick={() => history.push('/admin/add-credit-memo')}
            >
              <AddIcon />
              Create Credit Memo
            </Button>
            :
            tabName === "Refund" ?
              <Button
                style={{ width: "100%", background: "#FFC821", color: "#323232", fontWeight: 700, marginRight: "15px" }}
                className={classes.createInvoiceButton}
                onClick={() => history.push('/admin/create-invoice')}
              >
                <AddIcon />
                Apply Credit
              </Button>
              :
              tabName === "Misc Charges" ?
                <Button
                  style={{ width: "100%", background: "#FFC821", color: "#323232", fontWeight: 700, marginRight: "15px" }}
                  className={classes.createInvoiceButton}
                  onClick={() => history.push('/admin/create-invoice')}
                >
                  <AddIcon />
                  Create Misc. Charges
                </Button>
                :
                <Button
                  style={{ width: "100%", background: "#FFC821", color: "#323232", fontWeight: 700, marginRight: "15px" }}
                  className={classes.createInvoiceButton}
                  onClick={() => history.push('/admin/create-invoice')}
                >
                  <AddIcon />
                  Create Invoice
                </Button>

        }

      </GridItem>
      <GridItem xs={12}>
        <GridContainer>
          <GridItem xs={12}>
            <AccountingCustomTabs
              tabIndex={tabIndex}
              classes={classes}
              tabs={[
                {
                  tabName: "Invoices",
                  tabContent: (
                    <AccountingInsideInvoicesTab
                      tabName={tabName}
                      setTabName={setTabName}
                      history={history}
                      invoices={invoices}
                      timeSelect={timeSelect}
                      setTimePeriod={setTimePeriod}
                      timePeriod={timePeriod}
                      invoicesLoaded={invoicesLoaded}
                    />
                  ),
                },
                {
                  tabName: "Payments",
                  tabContent: (
                    <AccountingPaymentsTab
                      invoices={invoices}
                      history={history}
                      tabName={tabName}
                      setTabName={setTabName}
                    />
                  ),
                },
                {
                  tabName: "Credit Memos",
                  tabContent: (
                    <CreditMemos
                      invoices={creditMemos}
                      history={history}
                      tabName={tabName}
                      setTabName={setTabName}
                    />
                  ),
                },
                {
                  tabName: "Refunds",
                  tabContent:
                    <AccountingRefunds
                      reservationsData={[]}
                      tabName={tabName}
                      history={history}
                      setTabName={setTabName}
                    />,
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer >
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    reservationsData: state.reservations.reservations,
    pastReservations: state.reservations.pastReservations,
    creditMemos: state.creditMemos.creditMemos,
    tabIndex: ownProps.location.state == undefined ? "" : ownProps.location.state.tabIndex
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSetPastReservations: (pastReservations) =>
    dispatch(setPastReservations(pastReservations)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(Accounting);
