/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Datetime from "react-datetime";
import loader from "assets/img/loader.gif";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";

const jobTypes = ["Pick Up", "Drop Off", "Service", "Swap"];

function ScheduleJobFor(props) {
  const {
    classes,
    jobType,
    setJobType,
    inventoryData,
    productType,
    setProductType,
    drivers,
    selectedDriver,
    setSelectedDriver,
    errors,
    date,
    setDate,
  } = props;

  return (
    <Card className={classes.invoiceCard}>
      <GridContainer>
        <GridItem
          className={classes.alignItems + " " + classes.marginBottom}
          xs={12}
        >
          <h1 style={{ marginBottom: "0px" }} className={classes.title}>
            Schedule Job for..
          </h1>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={3}>
          <span className={classes.inputTypeTitle}>
            Job Type{" "}
            {errors.jobType && (
              <span style={{ color: "red", marginLeft: "10px" }}>
                Required*
              </span>
            )}
          </span>
          <Select
            className={classes.filterMenu}
            variant="outlined"
            value={jobType}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setJobType(e.target.value)}
          >
            {jobTypes.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={3}>
          <span className={classes.inputTypeTitle}>
            Product Type{" "}
            {errors.productType && (
              <span style={{ color: "red", marginLeft: "10px" }}>
                Required*
              </span>
            )}
          </span>
          <Select
            className={classes.filterMenu}
            variant="outlined"
            value={productType}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setProductType(e.target.value)}
          >
            {inventoryData.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o.inventoryItem}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={3}>
          <span className={classes.inputTypeTitle}>
            Driver{" "}
            {errors.driver && (
              <span style={{ color: "red", marginLeft: "10px" }}>
                Required*
              </span>
            )}
          </span>
          <Select
            className={classes.filterMenu}
            variant="outlined"
            value={selectedDriver.driver}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setSelectedDriver(e.target.value)}
          >
            {drivers.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o.driver}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={3}>
          <span className={classes.inputTypeTitle}>
            Date{" "}
            {errors.date && (
              <span style={{ color: "red", marginLeft: "10px" }}>
                Required*
              </span>
            )}
          </span>
          <Datetime
            // style={{
            //   color: "black !important",
            //   height: "100%",
            //   width: "100%",
            // }}
            selected={date}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              setDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  // className={classes.datetime}
                  style={{ width: "100%" }}
                  value={date}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log("stateee", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    sites: state.sites.sites,
    inventoryData: state.inventory.inventory,
    drivers: state.drivers.drivers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(ScheduleJobFor));
