/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Datetime from "react-datetime";
import loader from "assets/img/loader.gif";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";

const jobTypes = ["Pick Up", "Drop Off", "Service", "Swap"];

function DriverNotes(props) {
  const {
    classes,
    driverNotes,
    setDriverNotes,
    sendConfirmationEmail,
    setSendConfirmationEmail,
  } = props;

  return (
    <Card className={classes.invoiceCard}>
      <GridContainer>
        <GridItem
          className={classes.alignItems + " " + classes.marginBottom}
          xs={12}
        >
          <h1 style={{ marginBottom: "0px" }} className={classes.title}>
            Driver Notes (optional)
          </h1>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={12}>
          <TextareaAutosize
            aria-label="empty textarea"
            className={classes.emailInput}
            onChange={(e) => setDriverNotes(e.target.value)}
            placeholder=""
            style={{ maxWidth: "100%", minHeight: "100px", marginBottom: "30px" }}
          />
          <span className={classes.attachmentInfo}>
            The customer signature section will appear at the bottom of the
            print (PDF) version of service tickets. The customer signature
            section will also appear in the Humpty Dump mobile app during job
            completion. Signatures captured using the mobile app include date,
            time and location of the signature.
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              marginTop: "20px",
            }}
          >
            <Checkbox
              style={{
                color: "#323232",
              }}
              onChange={() => setSendConfirmationEmail(!sendConfirmationEmail)}
              checked={sendConfirmationEmail}
            />
            <span className={classes.sameAsOwnerAddress}>
              Send Confirmation Email to Customer
            </span>
          </div>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log("stateee", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    sites: state.sites.sites,
    inventoryData: state.inventory.inventory,
    drivers: state.drivers.drivers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(DriverNotes));
