import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import moment from "moment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { CheckRounded, Search } from "@material-ui/icons";
import { filterData } from "globalHelperFunctions/globalHelperFunctions";

function QuoteType(props) {
  const {
    classes,
    errors,
    history,
    pickUpAddress,
    setPickUpAddress,
    swapDate,
    setSwapDate,
    sevenDayRental,
    setSevenDayRental,
    otherDuration,
    setOtherDuration,
    pickUpDate,
    setPickUpDate,
    handleSelect,
    searchString,
    setSearchString,
    handleSearch,
    filteredData,
    closeDropdown,
    setCloseDropdown
  } = props;

  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  const disablePickUpDt = (current) => {
    return current.isAfter(swapDate);
  };

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Pick Up Address{" "}
            {errors.pickUpAddress && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <GooglePlacesAutoComplete
            classes={classes}
            value={pickUpAddress}
            width={"100%"}
            placeholder="Search your drop-off address.."
            onChange={(e) => setPickUpAddress(e)}
            onSelect={handleSelect}
            margin="0px 0px 20px 0px"
            background="#F5F5F5"
            googleCallbackName="initOne"
          />


        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Requested Pick Up Date{" "}
            {errors.dropOffDate && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <Datetime
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
            }}
            isValidDate={disablePickUpDt}
            selected={pickUpDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              setPickUpDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  style={{}}
                  className={classes.datetime}
                  placeholder="MM/DD/YY"
                  value={pickUpDate}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />


        </GridItem>
        <GridItem
          className={classes.columnGridItem}
          xs={4}
        >
          <span className={classes.inputTypeTitle}>
            Requesting Early Pick Up for{" "}
            {errors.sevenDayRental && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <TextField
            className={classes.datetime}
            value={searchString}
            variant="outlined"
            onChange={handleSearch}
            // onKeyDown={(e) => {
            //   if (e.keyCode === 13) {
            //     handleSearch();
            //   }
            // }}
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
              endAdornment: (
                <InputAdornment
                  className={classes.adournedEnd}
                  position="end"
                >
                  <Button
                    // onClick={handleSearch}
                    className={classes.searchButton}
                  >
                    <Search
                      className={classes.calendarIcon}
                    />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {filteredData.length != 0 &&  closeDropdown === false && (
            <div className={classes.dataResult}>
              {filteredData.slice(0, 10).map((value, key) => {
                console.log("checking filtered", value)
                return (
                  <p
                    className={classes.hoverOne}
                    onClick={() => {
                      setSearchString(value.reservation.reservationId)
                      setCloseDropdown(true)
                    }}
                  >
                    {value.reservation.reservationId}
                  </p>
                );
              })}
            </div>
          )}


        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(QuoteType));
