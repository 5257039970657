import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/popupMenuStyle.js";
import Menu from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Button from "components/CustomButtons/Button.js";

import { startCase } from "lodash";
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function PopupMenu(props) {

    const { rtlActive, items, id } = props;
    const classes = useStyles();
    const [openProfile, setOpenProfile] = React.useState(false);

    const handleClickProfile = (event) => {
        if (openProfile && openProfile.contains(event.target)) {
            setOpenProfile(false);
        } else {
            setOpenProfile(event.currentTarget);
        }
    };

    const handleCloseProfile = () => setOpenProfile(false);

    const managerClasses = classNames({
        [classes.managerClasses]: true
    });

    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
        [classes.dropdownItemRTL]: rtlActive
    });

    function Item(props) {
        const { name, action } = props;
        return (
            <MenuItem
                onClick={e=>{handleCloseProfile(); action()}}
                className={dropdownItem}
            >
                {startCase(name)}
            </MenuItem>
        );
    }

    return (
        <div className={managerClasses}>
            <Button
                color="transparent"
                aria-label="CardMenu"
                justIcon
                aria-owns={openProfile ? `${id}-menu-list` : null}
                aria-haspopup="true"
                onClick={handleClickProfile}
                className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                muiClasses={{
                    label: rtlActive ? classes.labelRTL : ""
                }}
            >
                <Menu
                    className={
                        classes.headerLinksSvg +
                        " " +
                        (rtlActive
                            ? classes.links + " " + classes.linksRTL
                            : classes.links)
                    }
                />

            </Button>
            <Popper
                open={Boolean(openProfile)}
                anchorEl={openProfile}
                transition
                disablePortal
                placement="bottom"
                className={classNames({
                    [classes.popperClose]: !openProfile,
                    [classes.popperResponsive]: true,
                    [classes.popperNav]: true
                })}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        id={`${id}-menu-list`}
                        style={{ transformOrigin: "0 0 0" }}
                    >
                        <Paper className={classes.dropdown}>
                            <ClickAwayListener onClickAway={handleCloseProfile}>
                                <MenuList role="menu">
                                    {   Object.entries(items).map(([name, action]) =>
                                            <Item key={name} name={name} action={action}/>
                                        )
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

