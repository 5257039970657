/* eslint-disable no-inner-declarations */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import CustomerInfoHeader from "./components/CustomerInfoHeader";
import CustomerDetails from "./components/CustomerDetails";
import CustomerCustomTabs from "./components/CustomerCustomTabs/CustomerCustomTabs";
import NotesTab from "./components/CustomerCustomTabs/Notes/NotesTab";
import AccountingTab from "./components/CustomerCustomTabs/Accounting/AccountingTab";
import CreditDebitCardsTab from "./components/CustomerCustomTabs/CreditDebitCards/CreditDebitCards";
import RentalsTab from "./components/CustomerCustomTabs/Rentals/RentalsTab";
import RemindersTab from "./components/CustomerCustomTabs/Reminders/RemindersTab";
import PriceBooksTab from "./components/CustomerCustomTabs/PriceBooks/PriceBooksTab";
import { db } from "firebase/client";
import loader from "assets/img/loader.gif";
import axios from "axios";
import Sites from "./components/CustomerCustomTabs/Sites/Sites";

const mockPriceBooks = [
  { name: "Special Pricing October 2022" },
  { name: "Special Pricing November 2022" },
  { name: "Special Pricing December 2022" },
];

function CustomerInfo(props) {
  const { classes, history, tabIndex } = props;
  const [customerInfo, setCustomerInfo] = useState(false);
  const [pastAndCurrentReservations, setPastAndCurrentReservations] = useState(
    false
  );
  const [invoices, setInvoices] = useState(false);
  const [payments, setPayments] = useState(false);
  const [cards, setCards] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [openTab, setOpenTab] = useState("");

  const customerId = window.location.pathname.split("/")[3];

  const handleTabSwitch = (tab) => {
    setOpenTab(tab);
  };

  useEffect(() => {
    if (
      history.location &&
      history.location.state &&
      history.location.state.edit
    ) {
      setEditOpen(true);
    }
  }, [history.location.state])

  useEffect(() => {
    if (!customerInfo) {
      const getCustomerInfo = async () => {
        return db
          .collection("customers")
          .doc(customerId)
          .get()
          .then((snapshot) => {
            const customer = snapshot.data();
            setCustomerInfo(customer);
            // if (customer.reservations.length > 0) {
            //   customer.reservations.forEach((p) => {
            //     paymentArray.push(db.collection("reservations").doc(p).get());
            //   });
            // }
            const paymentArray = [];
            if (customer.payments && customer.payments.length > 0) {
              customer.payments.forEach((p) => {
                paymentArray.push(db.collection("payments").doc(p).get());
              });
              return Promise.all(paymentArray).then((results) => {
                const paymentsData = [];
                results.forEach((r) => {
                  const payment = r.data();
                  paymentsData.push(payment);
                });
                setPayments(paymentsData);
                if (customer.invoices && customer.invoices.length > 0) {
                  const invoicesPromiseArray = [];
                  customer.invoices.forEach((invoiceId) => {
                    invoicesPromiseArray.push(
                      db.collection("invoices").doc(invoiceId).get()
                    );
                  });
                  Promise.all(invoicesPromiseArray).then((results) => {
                    const invoicesData = [];
                    results.forEach((r) => {
                      invoicesData.push(r.data());
                    });
                    setInvoices(invoicesData);
                  });
                } else {
                  setInvoices([]);
                }
              });
            } else {
              setPayments([]);
              if (customer.invoices && customer.invoices.length > 0) {
                const invoicesPromiseArray = [];
                customer.invoices.forEach((invoiceId) => {
                  invoicesPromiseArray.push(
                    db.collection("invoices").doc(invoiceId).get()
                  );
                });
                Promise.all(invoicesPromiseArray).then((results) => {
                  const invoicesData = [];
                  results.forEach((r) => {
                    invoicesData.push(r.data());
                  });
                  setInvoices(invoicesData);
                  console.log("invoicesData", invoicesData);
                });
              } else {
                setInvoices([]);
              }
            }
          });
      };
      getCustomerInfo();
    } else {
    }
  }, []);

  useEffect(() => {
    if (customerInfo && !pastAndCurrentReservations) {
      const getPastAndCurrentReservations = async () => {
        const getPastAndPresentReservationsPromise = [];
        if (customerInfo.reservations.length > 0) {
          customerInfo.reservations.forEach((id) => {
            getPastAndPresentReservationsPromise.push(
              db.collection("reservations").doc(id).get()
            );
            getPastAndPresentReservationsPromise.push(
              db.collection("past-reservations").doc(id).get()
            );
            getPastAndPresentReservationsPromise.push(
              db.collection("pending-reservations").doc(id).get()
            );
          });
        }
        Promise.all(getPastAndPresentReservationsPromise).then((results) => {
          const allReservations = [];
          results.forEach((snapshot) => {
            if (snapshot.exists) {
              snapshot = snapshot.data();
              allReservations.push(snapshot);
            }
          });
          const paymentPromiseArray = [];
          allReservations.forEach((r) => {
            if (r.reservation.paymentId) {
              paymentPromiseArray.push(
                db.collection("payments").doc(r.reservation.paymentId).get()
              );
            }
          });
          setPastAndCurrentReservations(allReservations);
        });
      };
      getPastAndCurrentReservations();
    }
    if (customerInfo.stripeCustomerId) {
      async function fetchSecretExistingCustomer() {
        try {
          const paymentMethods = await axios.get(
            `https://us-central1-humpty-dump.cloudfunctions.net/app/retrieve-card/${customerInfo.stripeCustomerId}`
          );
          if (paymentMethods.data.paymentMethods.data[0]) {
            const allCards = paymentMethods.data.paymentMethods.data;
            setCards(allCards);
          }
        } catch (err) {
          console.log("Error", err);
        }
      }
      fetchSecretExistingCustomer();
    }
  }, [customerInfo]);

  if (!customerInfo || !payments || !pastAndCurrentReservations)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );
  return (
    <GridContainer>
      <GridItem xs={12}>
        <CustomerInfoHeader
          history={history}
          setCustomerInfo={setCustomerInfo}
          customerInfo={customerInfo}
          editOpen={editOpen}
        />
      </GridItem>
      <GridItem xs={12}>
        <CustomerDetails customerInfo={customerInfo} history={history} handleTabSwitch={handleTabSwitch} />
      </GridItem>
      <GridItem className={classes.gridItemWithMargin} xs={12}>
        <CustomerCustomTabs
          openTab={openTab}
          tabIndex={tabIndex}
          classes={classes}
          tabs={[
            {
              tabName: "Notes",
              tabContent: (
                <NotesTab history={history} customerInfo={customerInfo} />
              ),
            },
            {
              tabName: "Accounting",
              tabContent: (
                <AccountingTab
                  history={history}
                  invoices={invoices}
                  openTab={openTab}
                  pastAndCurrentReservations={pastAndCurrentReservations}
                  customerInfo={customerInfo}
                  payments={payments}
                  customerId={customerId}
                />
              ),
            },
            {
              tabName: "Rentals",
              tabContent: (
                <RentalsTab
                  history={history}
                  customerInfo={customerInfo}
                  pastAndCurrentReservations={pastAndCurrentReservations}
                />
              ),
            },
            {
              tabName: "Reminders",
              tabContent: (
                <RemindersTab history={history} customerInfo={customerInfo} />
              ),
            },
            {
              tabName: "Price Books",
              tabContent: (
                <PriceBooksTab
                  priceBooksData={mockPriceBooks}
                  history={history}
                  customerInfo={customerInfo}
                />
              ),
            },
            {
              tabName: "Credit/Debit Cards",
              tabContent: <CreditDebitCardsTab cards={cards} />,
            },
            {
              tabName: "Sites",
              tabContent: <Sites 
              history={history} 
              customerInfo={customerInfo} 
              />
            },
            {
              tabName: "Attachments",
              tabContent: <div></div>,
            },
          ]}
        />
      </GridItem>
      {console.log("checking customerinfo", customerInfo)}
    </GridContainer>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    tabIndex: ownProps.location.state == undefined ? "" : ownProps.location.state.tabIndex
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(CustomerInfo));
