/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, firebase } from "firebase/client";
import { useParams } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Checkbox from "@material-ui/core/Checkbox";

// actions
import AuthActions from "store/actions/auth";
import { chain, handleError } from "store/actions/util";
import { push } from "connected-react-router";

import { ReactComponent as Logo } from "assets/img/logo.svg";
// import GoogleSignIn from "assets/img/googleSignIn2.png";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import LoginBox from "./components/LoginBox.jsx";
import CustomerSignUpBox from "./components/CustomerSignUpBox.jsx"

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      passwordNotice: null,
      updateSignUpModal: false,
      updateLogInModal: false,
      rememberMe: false,
      screen: "Login",
      modalError: "",
      authInfo: {
        email: "",
        password: "",
      },
      userId: "",
      userInfo:""
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    if (this.props.authenticated) {
      console.log("authenticated", this.props.authenticated);
      push("/admin/operations");
    } else {
      this.timeOutFunction = setTimeout(
        function () {
          this.setState({ cardAnimaton: "" });
        }.bind(this),
        700
      );
    }
  }

  componentDidUpdate() {
    if (this.props.authenticated) {
      push("/admin/operations");
      console.log("authenticated", this.props.authenticated);
    } else {
      this.timeOutFunction = setTimeout(
        function () {
          this.setState({ cardAnimaton: "" });
        }.bind(this),
        700
      );
    }
    const { id } = this.props.match.params
    this.props.reservationsData.map((data,index) => {
      if(data.customerId === id && this.state.userId === ""){
        this.setState({userId : data.customerId, userInfo:data})
        
      }
    })
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleClickOpen = (modal) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
    });
  };
  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };
  submitSignup = () => {
    const basicVals = this.props.basicFormState;
    const commonVals = this.props.commonFormState;
    console.log("checking information: ", basicVals, )
    if (
      // !commonVals ||
      // !commonVals.fields.firstName ||
      // !commonVals.fields.lastName ||
      // !basicVals.fields.email ||
      !basicVals.fields.password ||
      !basicVals.fields.confirmPassword
    ) {
      this.setState({
        modalError: "Please Fill Out All Fields",
      });
    } else {
      return firebase
        .auth()
        .fetchSignInMethodsForEmail(this.state.userInfo.email)
        .then((method) => {
          if (method.includes("password")) {
            this.setState({
              modalError:
                "That email already exists in the system. Please click forgot password",
            });
          } else {
            this.props.emailSignup(
              {
                email: this.state.userInfo.email,
                password: basicVals.fields.password,
              },
              {
                firstName: this.state.userInfo.firstName,
                lastName: this.state.userInfo.lastName,
              }
            );
          }
        });
    }
  };

  // handleSubmitSignUp = (e) => {
  //   e.preventDefault();

  //   if (this.state.authInfo.password !== this.state.authInfo.confirmPassword) {
  //     this.setState({
  //       passError: true,
  //     });
  //     return;
  //   }

  //   if (this.state.checked[0] !== "accepted terms") {
  //     this.setState({
  //       termsError: true,
  //     });
  //     return;
  //   }

  //   this.setState({
  //     signupError: false,
  //   });

  //   this.props.emailSignup(this.state.authInfo);
  // };

  handleSubmitLogIn = (e) => {
    e.preventDefault();
    this.props.emailSignIn(this.state.authInfo);
  };
  handleChange = (e) => {
    this.setState({
      ...this.state,
      authInfo: {
        ...this.state.authInfo,
        [e.target.id]: e.target.value,
      },
    });
  };
  handlePasswordReset = (e) => {
    e.preventDefault();
    this.props.passwordReset(this.state.authInfo.email);
    this.setState({
      ...this.state,
      passwordNotice: "Password reset link sent. Please check your email.",
    });
  };
  render() {
    const { authError, classes, startGoogleLogin, authenticated } = this.props;
    const { passwordNotice } = this.state;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem className={classes.logoGridItem} xs={12}>
            <Logo />
          </GridItem>
        </GridContainer>
        {this.state.screen === "Login" ? (
          <LoginBox
            handleSubmitLogIn={this.handleSubmitLogIn}
            classes={classes}
            onChange={this.handleChange}
            handleRememberMe={() =>
              this.setState({
                rememberMe: !this.state.rememberMe,
              })
            }
            checked={this.state.rememberMe}
            handleSignUp={() =>
              this.setState({
                screen: "Sign Up",
              })
            }
          />
        ) : (
          <CustomerSignUpBox
            handleSubmitLogIn={this.handleSubmitLogIn}
            classes={classes}
            onChange={this.handleChange}
            handleRememberMe={() =>
              this.setState({
                rememberMe: !this.state.rememberMe,
              })
            }
            userInfo={this.state.userInfo}
            checked={this.state.rememberMe}
            handleSignUp={this.submitSignup}
            navigateToLogin={() =>
              this.setState({
                screen: "Login",
              })
            }
          />
        )}
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ color: "#323232", marginRight: 10 }}>
              © 2022 Over Easy Inc. All Rights Reserved. Terms of Use | Privacy
              Policy | User Notice
            </div>
          </div>
        </div>
        <Snackbar
          open={this.state.modalError}
          onClose={() =>
            this.setState({
              modalError: "",
            })
          }
        >
          <Alert
            onClose={() =>
              this.setState({
                modalError: "",
              })
            }
            severity="error"
          >
            {this.state.modalError}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  console.log("stateeeee", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    basicFormState: state.form.registration.steps.basicDetails,
    commonFormState: state.form.registration.steps.commonProfile,
    reservationsData: state.reservations.reservations
  };
};

const mapDispatchToProps = (dispatch) => ({
  startGoogleLogin: () => dispatch(AuthActions.startGoogleLogin()),
  emailSignIn: (credentials) =>
    dispatch(
      handleError(
        chain(
          AuthActions.startEmailLogin(credentials),
          push("/admin/operations")
        ),
        AuthActions.setError
      )
    ),
  authenticate: (credentials) =>
    dispatch(
      handleError(
        chain(
          AuthActions.startEmailLogin(credentials)
          // push("/admin/operations")
        ),
        AuthActions.setError
      )
    ),
  passwordReset: (email) => dispatch(AuthActions.startPasswordReset(email)),
  emailSignup: (credentials, profile) =>
    dispatch(AuthActions.startEmailSignup(credentials, profile)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(RegisterPage);
