import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import { db } from "firebase/client";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import settingsStyle from "assets/jss/material-dashboard-pro-react/views/settingsStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import SettingsCustomTabs from "./components/SettingsCustomTabs";
import CompanyTab from "./components/CompanyTab";
import UsersTab from "./components/Users/UsersTab";

import AccountingTab from "./components/Accounting/AccountingTab";
import PriceBooks from "./components/PriceBooks/PriceBooksTab";
import Card from "components/Card/Card";
import loader from "assets/img/loader.gif";
import Inventory from "./components/Inventory/Inventory";
import Schedule from "./components/Schedule/Schedule";
import LoadTypes from "./components/LoadTypes/LoadTypes";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse";

function Settings(props) {
  const { classes, history, userInfo, companyData, staffData } = props;
  const [company, setCompany] = useState(false);
  const [staff, setStaff] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState("");

  const handleModalOpen = (type, item) => {
    setModalOpen(false);
    setModalType(type);
    setSelectedItem(item);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalType("");
    setModalOpen(false);
    setSelectedItem("");
  };
  useEffect(() => {
    if (companyData) {
      setCompany(companyData);
      setStaff(staffData);
    }
  }, [companyData]);

  if (userInfo.companyId && !company)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h1 className={classes.title}>Settings</h1>
      </GridItem>
      <GridItem xs={12}>
        {userInfo.role && userInfo.role === "Super Admin" ?
          <>
            <SettingsCustomTabs
              classes={classes}
              tabs={[
                {
                  tabName: "Company",
                  tabContent: (
                    <CompanyTab
                      companyId={userInfo.companyId}
                      staff={staff}
                      setStaff={setStaff}
                      company={company}
                      history={history}
                      userInfo={userInfo}
                      handleModalClose={handleModalClose}
                      handleModalOpen={handleModalOpen}
                    />
                  ),
                },
                {
                  tabName: "Users",
                  tabContent: (
                    <UsersTab
                      companyId={userInfo.companyId}
                      staff={staff}
                      company={company}
                      history={history}
                      userInfo={userInfo}
                      setStaff={setStaff}
                    />
                  ),
                },
                {
                  tabName: "Accounting",
                  tabContent: (
                    <AccountingTab
                      companyId={userInfo.companyId}
                      staff={staff}
                      company={company}
                      history={history}
                      userInfo={userInfo}
                    />
                  ),
                },
                {
                  tabName: "Price Books",
                  tabContent: (
                    <Card className={classes.card} style={{ padding: "0px 30px" }}>
                      <PriceBooks
                        companyId={userInfo.companyId}
                        staff={staff}
                        company={company}
                        history={history}
                        userInfo={userInfo}
                      />
                    </Card>
                  ),
                },
                {
                  tabName: "Inventory",
                  tabContent: (
                    <Card className={classes.card}>
                      <Inventory
                      />
                    </Card>
                  ),
                },
                {
                  tabName: "Schedule",
                  tabContent: (
                    <Card className={classes.card}>
                      <Schedule />
                    </Card>
                  ),
                },
                {
                  tabName: "Load Types",
                  tabContent: (
                    <Card className={classes.card}>
                      <LoadTypes />
                    </Card>
                  ),
                },
                {
                  tabName: "Terms of Use",
                  tabContent: (
                    <Card className={classes.card}>
                      <TermsOfUse />
                    </Card>
                  ),
                },
              ]}
            />
          </>
          :
          <>
            <SettingsCustomTabs
              classes={classes}
              tabs={[
                {
                  tabName: "Company",
                  tabContent: (
                    <CompanyTab
                      companyId={userInfo.companyId}
                      staff={staff}
                      setStaff={setStaff}
                      company={company}
                      history={history}
                      userInfo={userInfo}
                      handleModalClose={handleModalClose}
                      handleModalOpen={handleModalOpen}
                    />
                  ),
                },
                {
                  tabName: "Users",
                  tabContent: (
                    <UsersTab
                      companyId={userInfo.companyId}
                      staff={staff}
                      company={company}
                      history={history}
                      userInfo={userInfo}
                      setStaff={setStaff}
                    />
                  ),
                },
              ]}
            />
          </>
        }
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
    companyData: state.company.company,
    staffData: state.company.staff,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(settingsStyle)
)(Settings);
