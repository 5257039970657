/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import addProductStyle from "assets/jss/material-dashboard-pro-react/views/addProductStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

function ScheduleCard(props) {
  const {
    classes,
    history,
    maintenanceSchedule,
    setMaintenanceSchedule,
  } = props;

  return (
    <Card
      style={{ marginTop: "30px", marginBottom: "30px" }}
      className={classes.card}
    >
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={12}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <span className={classes.maintenanceTitle}>
              Maintenance Schedule
            </span>
            <Button
              style={{ background: "#FFC821", color: "#323232", width: "15%" }}
              className={classes.addEditButton}
              onClick={() =>
                setMaintenanceSchedule([
                  ...maintenanceSchedule,
                  {
                    timeFrame: "",
                    description: "",
                  },
                ])
              }
            >
              {" "}
              <AddIcon />
              Add New Line
            </Button>
          </div>
        </GridItem>
        <GridItem xs={12}>
          {maintenanceSchedule.map((s, index) => {
            return (
              <GridContainer key={index}>
                <GridItem className={classes.columnGridItem} xs={5}>
                  <div style={{ display: "flex" }}>
                    <span className={classes.timeFrameIndex}>{index + 1}.</span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <span className={classes.inputTypeTitle}>
                        Recommended Time Frame
                      </span>
                      <TextField
                        value={maintenanceSchedule[index].timeFrame}
                        onChange={(e) => {
                          let maintenanceCopy = [...maintenanceSchedule];
                          maintenanceCopy[index].timeFrame = e.target.value;
                          setMaintenanceSchedule([...maintenanceCopy]);
                        }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </GridItem>
                <GridItem className={classes.columnGridItem} xs={7}>
                  <span className={classes.inputTypeTitle}>Description</span>
                  <TextField
                    value={maintenanceSchedule[index].description}
                    onChange={(e) => {
                      let maintenanceCopy = [...maintenanceSchedule];
                      maintenanceCopy[index].description = e.target.value;
                      setMaintenanceSchedule([...maintenanceCopy]);
                    }}
                    variant="outlined"
                  />
                </GridItem>
              </GridContainer>
            );
          })}
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(addProductStyle)
)(React.memo(ScheduleCard));
