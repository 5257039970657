import React from "react";
import ChartistGraph from "react-chartist";
import "./chart.css";

export default function App(props) {
  const { inventoryData } = props;
  var data = {
    labels: inventoryData && inventoryData.map((d) => d.inventoryItem),
    series: [
      [5000, 10000, 20000, 28000], //first item on all charts
    ],
  };

  var options = {
    high: 30000,
    stackBars: false,
    low: 0,
    seriesBarDistance: 5,
    axisX: {
      offset: 40,
    },
    axisY: {
      offset: 40,
      labelInterpolationFnc: function (value) {
        return `${value}`;
      },
      scaleMinSpace: 40,
    },
  };

  var responsiveOptions = [];

  let type = "Bar";

  return (
    <ChartistGraph
      style={{ width: "100%", height: "500px" }}
      data={data}
      options={options}
      type={type}
    />
  );
}
