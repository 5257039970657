import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchStyle from "assets/jss/material-dashboard-pro-react/views/SearchStyle.js";
import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
const searchOptions = ["Customers", "Jobs", "Rentals", "Sites"];

function SearchHeader(props) {
  const {
    classes,
    searchString,
    setSearchString,
    setCategorySelection,
    categorySelection,
    handleSearch,
    data,
    handleDropDownChange,
  } = props;
  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem xs={12}>
        <p className={classes.subHeader}>Search Category</p>
      </GridItem>
      <GridItem xs={2}>
        <Select
          className={classes.filterMenu}
          value={categorySelection}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={handleDropDownChange}
        >
          {searchOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>
      <GridItem xs={10}>
        <TextField
          className={classes.searchBar}
          placeholder="Search by name, company/organization, address, city, zip, phone, email"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          variant="outlined"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearch(data, categorySelection, searchString);
            }
          }}
          InputProps={{
            classes: {
              adornedEnd: classes.adournedEnd,
            },
            endAdornment: (
              <InputAdornment className={classes.adournedEnd} position="end">
                <Button
                  onClick={() =>
                    handleSearch(data, categorySelection, searchString)
                  }
                  className={classes.searchButton}
                >
                  Search
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(SearchStyle)
)(SearchHeader);
