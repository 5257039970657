import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";

const headers = [
  "#",
  "Site Address",
  "Original Amount",
  "Discount",
  "Balance",
  "Payment Amount",
];
function InvoiceCard(props) {
  const {
    classes,
    invoice,
    history,
    servicesSubTotal,
    taxTotal,
    servicesTotal,
    payment,
    reservation
  } = props;

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    return (
      <tr className={classes.tableRow}>
        <td
          onClick={() =>
            history.push({
              pathname: `/admin/invoice/${invoice.invoiceId}`,
              // state: {
              //   invoice: invoice,
              // },
            })
          }
          style={{ color: "#0071C5", textDecoration: "underline" }}
          className={classes.tableCell}
        >
          {invoice.invoiceId}
        </td>
        <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
          {reservation.reservation.siteAddress}
        </td>
        <td className={classes.tableCell}> {reservation.reservation.amount}</td>
        <td className={classes.tableCell}>
          {reservation.reservation.discount
            ? reservation.reservation.discount
            : "$0.00"}
        </td>
        <td className={classes.tableCell}>$0.00</td>
        <td className={classes.tableCell}>${payment.amountReceived}</td>
      </tr>
    );
  };
  return (
    <Card className={classes.invoiceCard}>
      <h1 style={{ marginBottom: "30px" }} className={classes.title}>
        Invoice
      </h1>
      <GridContainer>
        <GridItem className={classes.tableGridItem} xs={12}>
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {invoice && renderTableData()}
            </tbody>
          </table>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(InvoiceCard));
