import { firebase, db } from "firebase/client";

export const listenToTerritories = (customer) => {
  console.log("customer", customer);
  return (dispatch) => {
    db.collection("territories").onSnapshot((snapshot) => {
      const territories = snapshot.docs.map((d) => (d = d.data()));
      dispatch(setTerritories(territories));
    });
  };
};

export const setTerritories = (territories) => {
  return {
    type: "SET_TERRITORIES",
    territories,
  };
};

export const saveTerritory = (territoryData, setAlert) => {
  return (dispatch) => {
    const territoryId = db.collection("territories").doc().id;
    console.log("territoryData", territoryData, territoryId);
    return db
      .collection("territories")
      .doc(territoryId)
      .set({ ...territoryData })
      .then(() => {
        setAlert("Succesfully added new territory");
        dispatch(defaultType());
      });
  };
};

const defaultType = () => {
  return {
    type: "DEFAULT",
  };
};
