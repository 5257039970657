/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Datetime from "react-datetime";
import loader from "assets/img/loader.gif";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import moment from "moment";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";

const communcationsOptions = ["Email", "Call"];
const tagOptions = ["Overdue", "Unpaid", "New", "Regular", "Cautious"];

function Attachments(props) {
  const {
    classes,
    history,
    setSavingImage,
    savingImage,
    newInvoiceID,
    setAttachments,
    attachments,
    sendConfirmationEmail,
    setSendConfirmationEmail,
  } = props;

  const attachmentFileRef = createRef(null);

  const handleImageUpload = (event) => {
    setSavingImage(true);
    // var url = URL.createObjectURL(event.target.files[0]);
    // // setBasicNFTPreviewUrl(url);

    var array = [];
    Array.from(event.target.files).forEach((file) => {
      array = [...array, file];
    });
    const storagePromiseArray = [];
    const downloadRefArray = [];
    const downloadRefPromiseArray = [];
    const finalArrayOfNewAttachments = [];
    array.forEach((file, i) => {
      var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      storagePromiseArray.push(
        storage
          .ref()
          .child(`invoiceAttachments/${newInvoiceID}/${file.name}`)
          .put(file)
      );
      downloadRefArray.push(`invoiceAttachments/${newInvoiceID}/${file.name}`);
      finalArrayOfNewAttachments.push({
        fileName: file.name,
        dateUploaded: new Date(),
        fileSizeInMB: sizeInMB,
      });
    });
    Promise.all(storagePromiseArray).then(() => {
      downloadRefArray.forEach((s) => {
        downloadRefPromiseArray.push(storage.ref().child(s));
      });
      Promise.all(downloadRefPromiseArray).then((results) => {
        const downloadUrlPromiseArray = [];
        results.forEach((r) => {
          downloadUrlPromiseArray.push(r.getDownloadURL());
        });
        Promise.all(downloadUrlPromiseArray).then((resultssss) => {
          console.log("resultssss", resultssss);
          resultssss.forEach((url, index) => {
            finalArrayOfNewAttachments[index].fileUrl = url;
          });
          // return db
          //   .collection("jobs")
          //   .doc(newInvoiceID)
          //   .update({
          //     attachments: [...job.attachments, ...finalArrayOfNewAttachments],
          //   })
          //   .then(() => {
          setAttachments(finalArrayOfNewAttachments);
          setSavingImage(false);
          // });
        });
      });
    });
  };

  return (
    <Card className={classes.invoiceCard}>
      <GridContainer>
        {savingImage ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ width: "20%" }} src={loader} alt="..." />
          </div>
        ) : (
          <>
            <GridItem xs={10}>
              <h1 style={{ marginBottom: "30px" }} className={classes.title}>
                Attachments
              </h1>
            </GridItem>
            <GridItem className={classes.justifyEnd} xs={2}>
              <Button
                onClick={() => {
                  attachmentFileRef.current.click();
                }}
                style={{ height: "50px" }}
                className={classes.addCustomer}
              >
                <input
                  onChange={(event) => {
                    handleImageUpload(event);
                  }}
                  multiple="multiple"
                  type="file"
                  id="attachmentInput"
                  ref={attachmentFileRef}
                  style={{ display: "none" }}
                />
                <AddIcon />
                <span style={{ marginLeft: "10px" }}>Add Attachment</span>
              </Button>
            </GridItem>
            <GridItem xs={3}>
              <span className={classes.attachmentInfo}>
                Here are a few things to keep in mind before attaching files:{" "}
                <br />
                &bull; Each individual file must be less than 6MB in size.{" "}
                <br />
                &bull; For documents that will be sent as email attachments,
                such as when you send an invoice, estimate or credit memo by
                email, the combined size of all attachments together must be
                less than 20MB
              </span>
            </GridItem>
            <GridItem xs={9}>
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}
              >
                {attachments.map((a, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "0px 30px",
                      }}
                      key={index}
                    >
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={a.fileUrl}
                      />
                      <span className={classes.fileName}>{a.fileName}</span>
                      <span className={classes.fileDetails}>
                        {moment(a.dateUploaded).format("MM/DD/YY, hh:mm A")}{" "}
                      </span>
                    </div>
                  );
                })}
              </div>
            </GridItem>
            <GridItem xs={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  marginTop: "20px",
                }}
              >
                <Checkbox
                  style={{
                    color: "#323232",
                  }}
                  onChange={() =>
                    setSendConfirmationEmail(!sendConfirmationEmail)
                  }
                  checked={sendConfirmationEmail}
                />
                <span className={classes.sameAsOwnerAddress}>
                  Send Confirmation Email to Customer
                </span>
              </div>
            </GridItem>
          </>
        )}
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log("stateee", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    sites: state.sites.sites,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(Attachments));
