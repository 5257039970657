import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import scheduleStyle from "assets/jss/material-dashboard-pro-react/views/scheduleStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as BlackMailIcon } from "assets/img/blackMailIcon.svg";
import { ReactComponent as InfoIcon } from "assets/img/infoIcon.svg";
import { ReactComponent as AttentionIcon } from "assets/img/attentionIcon.svg";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { getThisWeekDays } from "../../../helpers/calendarHelpers";
import { setMessagingModal } from "store/actions/messaging/messagingActions";
const filterOptions = ["Filter By"];
const viewOptions = ["Week", "Day", "List", "Map"];

function ScheduleSubHeader(props) {
  const {
    classes,
    paymentDueDate,
    handleDayIncrement,
    handleDayDecrement,
    scheduleType,
    schedule,
    calendarOpen,
    setCalendarOpen,
    setSchedule,
    filterSelection,
    setFilterSelection,
    setScheduleType,
    setSitesIndex,
    sitesIndex,
    handleSitesChange,
    handleScheduleTypeChange,
    sites,
    searchString,
    setSearchString,
    handleFilterByDay,
    weeklySchedule,
    handleFilterByWeek,
    setWeeklySchedule,
    history,
  } = props;

  return (
    <GridContainer>
      <GridItem
        className={classes.upcomingPaymentsGridItem}
        xs={scheduleType === "Week" ? 3 : 2}
      >
        {/* <span className={classes.subHeader}>
          {scheduleType === "Week" ? "This Week" : "Today"}
        </span> */}

        {/* {scheduleType === "Week" ? ( */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <Button
            style={{ background: "#929292", color: "#323232" }}
            onClick={() => {
              const newDate = moment(new Date()).format("ddd MM/DD/YY");
              setSchedule(newDate);
              const tempWeeklySchedule = getThisWeekDays(newDate);
              setWeeklySchedule(tempWeeklySchedule);
              handleFilterByWeek(tempWeeklySchedule);
            }}
            // className={classes.headerButtons}
          >
            Today
          </Button>
          <KeyboardArrowLeft
            onClick={handleDayDecrement}
            style={{ fontSize: "45px" }}
          />
          {scheduleType === "Week" ? (
            <span>
              {weeklySchedule && weeklySchedule[0].split(" ")[1]} -{" "}
              {weeklySchedule &&
                weeklySchedule[weeklySchedule.length - 1].split(" ")[1]}
            </span>
          ) : (
            <span>{schedule}</span>
          )}
          <KeyboardArrowRight
            onClick={handleDayIncrement}
            style={{ fontSize: "45px" }}
          />
        </div>
        <Tooltip
          id="tooltip-top"
          title="Change Schedule Date"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <CalendarIcon
            className={classes.calendarIcon}
            onClick={() => setCalendarOpen(true)}
          />
        </Tooltip>
        {calendarOpen && (
          <Datetime
            open={calendarOpen}
            input
            // className={classes.textFieldRoot}
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
            }}
            selected={schedule}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              // handleFilterByDay(new Date(date));
              const newDate = date.format("ddd MM/DD/YY");
              setSchedule(newDate);
              const tempWeeklySchedule = getThisWeekDays(newDate);
              setWeeklySchedule(tempWeeklySchedule);
              handleFilterByWeek(tempWeeklySchedule);
              setCalendarOpen(false);
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
          />
        )}
      </GridItem>
      {scheduleType === "Week" ? (
        <GridItem className={classes.remindersGridItem} xs={4}></GridItem>
      ) : (
        <GridItem className={classes.remindersGridItem} xs={5}>
          <Button
            style={{ background: "#FFC821" }}
            className={classes.headerButtons}
            onClick={() => props.setMessagingModal(true)}
          >
            <BlackMailIcon />
            Send Reminder
          </Button>
          <Button
            style={{ background: "#323232", color: "white" }}
            className={classes.headerButtons}
            onClick={() => history.push('/admin/accounting')}
          >
            <InfoIcon />
            Check Invoices
          </Button>
          <Button
            style={{ background: "#FEDA1B" }}
            className={classes.headerButtons}
          >
            <AttentionIcon />
            Overdue Customers
          </Button>
        </GridItem>
      )}
      <GridItem className={classes.remindersGridItem} xs={5}>
        <TextField
          className={classes.searchBar}
          placeholder="Search Customers"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          variant="outlined"
          // onKeyDown={(e) => {
          //   if (e.keyCode === 13) {
          //     handleSearch();
          //   }
          // }}
          InputProps={{
            classes: {
              adornedEnd: classes.adournedEnd,
            },
            endAdornment: (
              <InputAdornment className={classes.adournedEnd} position="end">
                <Button
                  onClick={() => console.log("search")}
                  className={classes.searchButton}
                >
                  Search
                </Button>
              </InputAdornment>
            ),
          }}
        />

        {}
        <Select
          className={classes.filterMenu}
          value={filterSelection}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => setFilterSelection(e.target.value)}
        >
          {filterOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
        {/*
        <div className={classes.viewSelectionDiv}>
          {viewOptions.map((v, index) => {
            return (
              <div
                key={index}
                onClick={() => handleScheduleTypeChange(v)}
                className={classes.viewSelection}
                style={
                  scheduleType === v
                    ? { background: "#323232", color: "white" }
                    : {}
                }
              >
                {v}
              </div>
            );
          })}
        </div> */}

        <div
          style={{
            display: "flex",
            border: "2px solid #323232",
            borderRadius: "5px",
            background: "#323232",
            width: "40%",
            height: "50px",
          }}
        >
          {viewOptions.map((v, index) => {
            if (v === scheduleType) {
              return (
                <div
                  key={index}
                  onClick={() => handleScheduleTypeChange(v)}
                  className={classes.selectedViewSelection}
                >
                  {v}
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  onClick={() => handleScheduleTypeChange(v)}
                  className={classes.unselectedViewSelection}
                >
                  {v}
                </div>
              );
            }
          })}
        </div>
      </GridItem>
      <GridItem className={classes.siteGridItem} xs={12}>
        <GridContainer className={classes.sitesGridContainer}>
          <GridItem className={classes.gridItemWithArrow} xs={1}>
            {sitesIndex[0] !== 0 && (
              <KeyboardArrowLeft
                onClick={() => handleSitesChange("decrease")}
                style={{ fontSize: "22px" }}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className={classes.destinations}>DESTINATIONS:</span>
              <span
                onClick={() => history.push("/admin/add-new-site")}
                style={{ color: "blue", textDecoration: "underline" }}
              >
                Add New
              </span>
            </div>
          </GridItem>
          {sites.map((s, index) => {
            if (index >= sitesIndex[0] && index < sitesIndex[1]) {
              return (
                <GridItem
                  key={index}
                  className={classes.gridItemWithoutArrow}
                  xs={1}
                >
                  <div className={classes.siteCard}>
                    {s.siteName ? (
                      <div className={classes.siteDiv}>
                        <span className={classes.siteName}>{s.siteName}</span>
                        <span className={classes.cityName}>{s.city}</span>
                      </div>
                    ) : (
                      <div className={classes.siteDiv}>
                        <span className={classes.siteName}>
                          {s.siteAddress}
                        </span>
                      </div>
                    )}
                    <DragIndicatorIcon />
                  </div>
                </GridItem>
              );
            }
            if (index === sitesIndex[1]) {
              return (
                <GridItem
                  key={index}
                  className={classes.gridItemWithArrow}
                  xs={1}
                >
                  <div className={classes.siteCard}>
                    <div className={classes.siteDiv}>
                      <span className={classes.siteName}>{s.siteName}</span>
                      <span className={classes.cityName}>{s.city}</span>
                    </div>
                    <DragIndicatorIcon />
                  </div>
                  <KeyboardArrowRight
                    onClick={() => handleSitesChange("increase")}
                    style={{ fontSize: "22px" }}
                  />
                </GridItem>
              );
            }
          })}
          {/* <GridItem xs={1}>
            <KeyboardArrowRight
              onClick={() => handleSitesChange("increase")}
              style={{ fontSize: "22px" }}
            />
          </GridItem> */}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMessagingModal: (boolean) => dispatch(setMessagingModal(boolean)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(scheduleStyle)
)(ScheduleSubHeader);
