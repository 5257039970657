import React from "react";
// nodejs library to set properties for components
import { connect } from "react-redux";
import PropTypes from "prop-types";
// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from 'components/CustomButtons/Button';

import Close from "@material-ui/icons/Close";

function BoundDialog(props) {
    let { classes, active, TransitionComponent, closeDialog, closeAtBottom } = props;

    return (
        <Dialog
            classes={{
                root: classes.modalRoot,
                paper: classes.modal + ' ' + classes.modalLarge,
            }}
            open={active}
            TransitionComponent={TransitionComponent}
            keepMounted
            onClose={() => closeDialog()}
        >
            <DialogTitle
                disableTypography
                className={classes.modalHeader}
                id={`${props.name}-title`}
            >
                <Button
                    simple
                    className={classes.modalCloseButton}
                    key='close'
                    aria-label='Close'
                    onClick={() => closeDialog()}
                >
                    {' '}
                    <Close className={classes.modalClose} />
                </Button>
                { props.title && 
                    <strong className={classes.bodyText}>{props.title}</strong>
                }
            </DialogTitle>
            <DialogContent
                className={classes.modalBody}
            >
                    {
                        props.children
                    }
            </DialogContent>
            { closeAtBottom && (
                <DialogActions>
                    <Button
                        className={classes.ltrButtonWhite}
                        style={{
                            marginLeft: '10px',
                            marginTop: '10px',
                        }}
                        key='close'
                        aria-label='Close'
                        onClick={() => closeDialog()}
                        >
                            CLOSE
                    </Button>    
                </DialogActions>            
            )}                
        </Dialog>
    );
}

BoundDialog.propTypes = {
    open: PropTypes.bool,
    name: PropTypes.string
};


function mapStateToProps(accessors) { 
    return (state)=> {
        return {
            active: accessors.isActive(state)
        }
    };
}

function mapDispatchToProps(actions){
    return (dispatch) => {
        return {
            closeDialog: () => dispatch(actions.closeDialog()),
        };
    }
}

export default (actions, accessors) => connect(mapStateToProps(accessors), mapDispatchToProps(actions))(BoundDialog);
