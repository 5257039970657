import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import BoundExplorer from "components/Explorer/BoundExplorer";
import BoundExplorerCard from "components/Explorer/BoundExplorerCard";
import actionsForExplorer from "store/actions/explorers/leads";
import accessorsForExplorer from "store/reducers/explorers/leads";


import explorerPageStyle from "assets/jss/material-dashboard-pro-react/views/explorerPageStyle.js";

const ExplorerCard = BoundExplorerCard(actionsForExplorer.recordset, accessorsForExplorer.recordset);
const Explorer = BoundExplorer(actionsForExplorer, accessorsForExplorer);
const useStyles = makeStyles(explorerPageStyle);

function CustomExplorerCard(props) {

    const classes = useStyles();

    return (
        <ExplorerCard {...props}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button className={ classes.advanceStatus }>Advance Status</Button>
                <Button className={ classes.remind }>Remind Customer</Button>
            </div>            
        </ExplorerCard>
    )
}

export default function LeadsExplorer(props) {
    return (
        <Explorer 
            ExplorerCard={CustomExplorerCard}
            searchFields={["firstName", "lastName", "currentScore", "goalScore", "lastUpdated"]} 
            cardFields={["lastUpdated", "notes", "currentScore", "goalScore", "state"]}
            routeName="/admin/leads"
        />
    );
}
