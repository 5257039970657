import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "views/Dashboard/components/pagination.css";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Card from "components/Card/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import { reportSelections } from "../../config/globals";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
const headers = [
  "",
  "Customer",
  "Current",
  "1-30",
  "31-60",
  "61-90",
  ">90",
  "Total",
];

const sortByOptions = ["Total Unpaid", "Scheduled Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];
const pageArr = ["5", "All"];
function AgingTable(props) {
  const {
    classes,
    agingData,
    history,
    setCheckedCount,
    checkedCount,
    setAgingData,
    setCheckedAll,
    checkedAll,
  } = props;
  const [tableSelection, setTableSelection] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [unpaidAsOf, setUnpaidAsOf] = useState("");
  const [sortBy, setSortBy] = useState("Total Unpaid");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterBySelection, setFilterBySelection] = useState("Filter By");
  const [searchString, setSearchString] = useState("");
  const itemsPerPage = 5;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("5");

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  useEffect(() => {
    agingData.slice(index, endIndex);
  }, [index]);
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if (pageDropdown == "5") {
      return agingData
        .slice(
          pageNumber * itemsPerPage,
          pageNumber * itemsPerPage + itemsPerPage
        )
        .map((d, index) => {
          return (
            <tr className={classes.tableRow} key={index}>
              <td className={classes.tableCell}>
                <Checkbox
                  // classes={{
                  //   root: marginTop,
                  //   disabled: classes.disabled,
                  // }}
                  onChange={() => {
                    let newState = [...agingData];
                    if (newState[index].checked) {
                      newState[index].checked = false;
                      setCheckedCount((prevState) => prevState - 1);
                    } else {
                      newState[index].checked = true;
                      setCheckedCount((prevState) => prevState + 1);
                    }
                    setAgingData([...newState]);
                  }}
                  id={index}
                  checked={d.checked}
                />
              </td>
              <td
                style={{ background: "#FFFFFF" }}
                className={classes.tableCell}
              >
                {d.name}
              </td>
              <td
                className={classes.tableCell}
                style={{ background: "#FFFFFF" }}
              >
                {d.current}
              </td>
              <td className={classes.tableCell}>{d.oneThroughThirty}</td>
              <td className={classes.tableCell}>{d.thirtyOneThroughSixty}</td>

              <td className={classes.tableCell}>{d.sixtyOneThroughNinety}</td>
              <td className={classes.tableCell}>{d.overNinety}</td>
              <td className={classes.tableCell}>{d.total}</td>
            </tr>
          );
        });
    } else {
      return agingData.map((d, index) => {
        return (
          <tr className={classes.tableRow} key={index}>
            <td className={classes.tableCell}>
              <Checkbox
                // classes={{
                //   root: marginTop,
                //   disabled: classes.disabled,
                // }}
                onChange={() => {
                  let newState = [...agingData];
                  if (newState[index].checked) {
                    newState[index].checked = false;
                    setCheckedCount((prevState) => prevState - 1);
                  } else {
                    newState[index].checked = true;
                    setCheckedCount((prevState) => prevState + 1);
                  }
                  setAgingData([...newState]);
                }}
                id={index}
                checked={d.checked}
              />
            </td>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              {d.name}
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.current}
            </td>
            <td className={classes.tableCell}>{d.oneThroughThirty}</td>
            <td className={classes.tableCell}>{d.thirtyOneThroughSixty}</td>

            <td className={classes.tableCell}>{d.sixtyOneThroughNinety}</td>
            <td className={classes.tableCell}>{d.overNinety}</td>
            <td className={classes.tableCell}>{d.total}</td>
          </tr>
        );
      });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "30px",
              maxHeight: "80px",
            }}
          >
            <div style={{ width: "50%" }} className={classes.agingGridItem}>
              <span className={classes.switchReport}>Switch Report</span>
              <Select
                variant="outlined"
                className={classes.filterMenu}
                value={tableSelection}
                sx={{ background: "#F5F5F5" }}
                disableUnderline
                defaultValue={"Select Option"}
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => history.push(e.target.value.link)}
                displayEmpty
                renderValue={
                  tableSelection !== "" ? undefined : () => "Aging"
                }
              >
                {reportSelections.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItemReport,
                      }}
                    >
                      {o.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div style={{ width: "50%" }} className={classes.agingGridItem}>
              <TextField
                className={classes.agingSearchBar}
                placeholder="Search"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                variant="outlined"
                // onKeyDown={(e) => {
                //   if (e.keyCode === 13) {
                //     handleSearch();
                //   }
                // }}
                InputProps={{
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <Button
                        onClick={() => console.log("search")}
                        className={classes.searchButton2}
                      >
                        Search
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "30%",
                  marginRight: "10px",
                }}
                selected={unpaidAsOf}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setUnpaidAsOf(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      className={classes.datetime}
                      placeholder="Unpaid As Of"
                      value={unpaidAsOf}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Button className={classes.exportCSV}
                style={{
                  margin: "5px 5px 5px 10px",
                }}>
                Export CSV
              </Button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "20px",
              maxHeight: "80px",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "60%", display: "flex", alignItems: "center" }}
            >
              <h5 className={classes.agingText}>Aging ($17,529.41)</h5>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "25px",
                }}
              >
                <Checkbox
                  onChange={() => setCheckedAll((prevState) => !prevState)}
                  checked={checkedAll}
                />
                <span style={{ fontFamily: "Archivo", fontSize: "18px" }}>
                  Select All ({checkedCount} of {agingData.length})
                </span>
              </div>
              <Button className={classes.sendStatementsButton}>
                Send Statements
              </Button>
            </div>
            <div
              style={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <h5 className={classes.switchReport}>Sort By</h5>
              <Select
                className={classes.sortByMenu}
                value={sortBy}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => setSortBy(e.target.value)}
              >
                {sortByOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                className={classes.sortByMenu}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                value={directionSelection}
                onChange={(e) => setDirectionSelection(e.target.value)}
              >
                {directionOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>

          <div
            style={
              pageDropdown != 5 && agingData.length > 5
                ? { height: "500px", width: "100%", overflowY: "scroll", padding: "0px 30px 10px 20px" }
                : { padding: "0px 30px 10px 20px" }
            }
          >
            <table className={classes.inventoryTable}>
              <tbody>
                {renderTableHeader()}
                {renderTableData()}
              </tbody>
            </table>
          </div>
          {pageDropdown == "5" ? (
            <GridContainer style={{ padding: "0px 30px 0px 30px" }}>
              <GridItem xs={6}>
                <p className={classes.view}>
                  View
                  <span>
                    <Select
                      style={{
                        width: "13%",
                        marginLeft: "10px",
                        marginTop: "5px",
                        textAlign: "center",
                      }}
                      disableUnderline
                      MenuProps={
                        {
                          // className: classes.selectMenu,
                        }
                      }
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor,
                      }}
                      onChange={(e) => {
                        setPageDrowdown(e.target.value);
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple,
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
              <GridItem xs={4} >
                <h5 className={classes.indexes2}>{index + 1} - {endIndex} of
                  {agingData.length <= itemsPerPage ? " " + 1 :
                    " " + Math.ceil(agingData.length / itemsPerPage)}
                </h5>
              </GridItem>
              <GridItem xs={2}>
                <ReactPaginate
                  paginationSize={0}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(agingData.length / itemsPerPage)}
                  nextLabel={
                    <ArrowRight
                      style={{
                        fontSize: 25,
                        fontWeight: "bold",
                        color: "white",
                        marginBottom: "-8px",
                      }}
                    />
                  }
                  previousLabel={
                    <ArrowLeft
                      style={{
                        fontSize: 25,
                        fontWeight: "bold",
                        color: "white",
                        marginBottom: "-8px",
                      }}
                    />
                  }
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer style={{ padding: "0px 30px 0px 30px" }}>
              <GridItem xs={6}>
                <p className={classes.view}>
                  View
                  <span>
                    <Select
                      style={{
                        width: "13%",
                        marginLeft: "10px",
                        marginTop: "5px",
                        textAlign: "center",
                      }}
                      disableUnderline
                      MenuProps={
                        {
                          // className: classes.selectMenu,
                        }
                      }
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor,
                      }}
                      onChange={(e) => {
                        setPageDrowdown(e.target.value);
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple,
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
            </GridContainer>
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(AgingTable));
