import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";

const headers = ["#", "Name", "QTY", "Description", "Rate"];
function ServicesCard(props) {
  const {
    classes,
    invoice,
    history,
    servicesSubTotal,
    taxTotal,
    servicesTotal,
    customer,
    reservation
  } = props;

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const typeKey = {
    reservation: "Reservation",
    swap: "Swap",
    pickUp: "Pick Up",
    dropOff: "Drop Off",
  };

  const renderTableData = () => {
    return invoice.services.map((d, index) => {
      return (
        <tr className={classes.tableRow} key={index}>
          <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
            {index + 1}
          </td>
          <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
            {d.type === "reservation" ||
            d.type === "swap" ||
            d.type === "pickUp" ||
            d.type === "dropOff" ? (
              <div style={{ width: "100%" }}>
                <span>
                  {d.serviceInfo.equiptment} {typeKey[d.type]}
                </span>
                {/* {d.serviceInfo.reservationId && (
                  <span
                    onClick={() =>
                      history.push(
                        `/admin/rentals/${d.serviceInfo.reservationId}`
                      )
                    }
                    style={{
                      color: "#0071C5",
                      textDecoration: "none",
                      marginLeft: "20px",
                    }}
                    className={classes.reservationIdJobDetail}
                  >
                    {d.serviceInfo.reservationId}
                  </span>
                )} */}
              </div>
            ) : (
              <span>API NOT SET UP FOR THIS</span>
            )}
          </td>
          <td className={classes.tableCell}>{d.quantity}</td>
          <td className={classes.tableCell}>{d.description || 'N/A'}</td>
          <td className={classes.tableCell}>${d.rate}</td>
          {/* <td className={classes.tableCell}>
            {" "}
            <Checkbox
              // onChange={() => {
              //   handleSelect(customerBillingAddress);
              //   setSameAsAddress(!sameAsAddress);
              // }}
              checked={false}
            />
          </td> */}
        </tr>
      );
    });
  };
  let totalMinusTax = parseFloat(servicesTotal) - invoice.appliedTaxTotal

  return (
    <Card className={classes.invoiceCard}>
      <h1 style={{ marginBottom: "30px" }} className={classes.title}>
        Services
      </h1>
      <GridContainer>
        <GridItem className={classes.tableGridItem} xs={12}>
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </GridItem>
        <GridItem className={classes.end} xs={10}>
          <span className={classes.totalsTitle}>Subtotal</span>
        </GridItem>
        <GridItem xs={2}>
          <span className={classes.totalsValue}>${totalMinusTax}</span>
        </GridItem>

        <GridItem className={classes.end} xs={10}>
          <span className={classes.totalsTitle}>Discount</span>
        </GridItem>
        <GridItem xs={2}>
          <span className={classes.totalsValue}>${invoice.discount ? invoice.discount : "0.00"}</span>
        </GridItem>

        <GridItem className={classes.end} xs={10}>
          <span className={classes.totalsTitle}>Tax</span>
        </GridItem>
        <GridItem xs={2}>
          <span className={classes.totalsValue}>${invoice.appliedTaxTotal}</span>
        </GridItem>

        <GridItem className={classes.end} xs={10}>
          <span className={classes.invoiceTotal}>Invoice Total</span>
        </GridItem>
        <GridItem xs={2}>
          <span className={classes.totalsValue}>${servicesTotal}</span>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(ServicesCard));
