import { DataTypes } from "variables/constants";
import { getAccessorsForForm, addSteps } from "../form";

export const config = {
  basicDetails: {
    fields: {
      email: { mandatory: true },
      password: { mandatory: true },
      confirmPassword: { mandatory: true },
      userType: { mandatory: false },
      acceptedTerms: { mandatory: false, type: DataTypes.BOOLEAN },
    },
  },
  commonProfile: {
    fields: {
      firstName: { mandatory: true },
      lastName: { mandatory: true },
      phoneNumber: { mandatory: false },
      dateOfBirth: { type: DataTypes.DATETIME, mandatory: false },
    },
  },
  lawyerProfile: {
    fields: {
      practiceName: { mandatory: false },
      association: { mandatory: false },
      barNumber: { mandatory: false },
    },
  },
};

export default addSteps(getAccessorsForForm(config, "form", "registration"), [
  "basicDetails",
  "commonProfile",
  "lawyerProfile",
]);
