export const errorCodes = {
    FIREBASE_AUTH_WEAK_PASSWORD: "auth/weak-password",
    FIREBASE_AUTH_EMAIL_ALREADY_IN_USE: "auth/email-already-in-use",
    APP_AUTH_NOT_LOGGED_IN: "app-auth/user-not-logged-in",
    FORM_FIELD_VALIDATION_ERROR: "form/field-validation-error",
    FORM_FIELD_IS_MANDATORY: "form/field-is-mandatory",
    FORM_DATATYPE_ERROR: "form/datatype-error",
    FORM_STEP_VALIDATION_ERROR: "form/step-validation-error",
    FORM_STEP_VALIDATION_INVALID_FIELDS: "form/step-validation-invalid-fields",
    RECORDSET_INVALID_KEY: "recordset/invalid-key",
    RECORDSET_UNSUPPORTED_OPERATOR: "recordset/unsupported-operator"
}

export class InvalidKeyError extends Error {
    constructor(key) {
        super(`${key} is not valid for this operation`);
        this.code = errorCodes.RECORDSET_INVALID_KEY;
    }
}

export class MandatoryFieldError extends Error {
    constructor() {
        super("Field is mandatory");
        this.code = errorCodes.FORM_FIELD_IS_MANDATORY;
    }
}

export class DataTypeError extends Error {
    constructor(type) {
        super(`Cannot convert to a ${type.longForm}`);
        this.code = errorCodes.FORM_DATATYPE_ERROR;
    }
}

export class UnsupportedOperatorError extends Error {
    constructor() {
        super("Unsupported operator in query");
        this.code = errorCodes.RECORDSET_UNSUPPORTED_OPERATOR;
    }
}

export class FieldValidationError extends Error {
    constructor(message) {
        super(message);
        this.code = errorCodes.FORM_FIELD_VALIDATION_ERROR;
    }
}
