import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

function ProfileCard(props) {
  const { classes, history, userInfo, company } = props;
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");
  const [snackbarContent, setSnackBarContent] = useState("");
  const [passwordVisible, setPasswordVisible] = useState({
    retype: false,
    newPass: false,
    current: false,
  });
  const [savingPic, setSavingPic] = useState(false);
  const profilePicRef = createRef(null);
  const [profilePic, setProfilePic] = useState("");

  const uid = firebase.auth().currentUser.uid;
  const storageRef = storage.ref().child(`${uid}/profilePic`);

  useEffect(() => {
    if (!profilePic && userInfo.profilePic) {
      setProfilePic(userInfo.profilePic);
    }
  }, [userInfo]);

  const handleProfilePictureChange = (event) => {
    setSavingPic(true);
    console.log("url", event.target.files[0], firebase.auth().currentUser.uid);
    const file = event.target.files[0];
    return storageRef.put(file).then(() => {
      storageRef.getDownloadURL().then((url) => {
        return db
          .collection("users")
          .doc(uid)
          .update({ profilePic: url })
          .then(() => {
            setProfilePic(url);
            setSavingPic(false);
          });
      });
    });
  };

  const handleProfilePicDelete = () => {
    storageRef.delete().then(() => {
      return db
        .collection("users")
        .doc(uid)
        .update({
          profilePic: "",
        })
        .then(() => {
          setProfilePic("");
          setSnackBarContent("Successfully Deleted Profile Pic");
        });
    });
  };

  const handleChangePassword = () => {
    if (!newPassword || !retypeNewPassword || !currentPassword) {
      //show alert to fill in missing values
      setSnackBarContent("All Fields Are Mandatory");
    } else {
      if (newPassword !== retypeNewPassword) {
        setSnackBarContent(`The New Passwords Don't Match`);
        //show alert to fix passwords
      } else {
        return firebase
          .auth()
          .signInWithEmailAndPassword(userInfo.email, currentPassword)
          .then(function (user) {
            console.log("succesful", user);
            firebase
              .auth()
              .currentUser.updatePassword(newPassword)
              .then(function () {
                setSnackBarContent("Successfully Changed Password");
                setNewPassword("");
                setRetypeNewPassword("");
                setCurrentPassword("");
                //show alert successful
              })
              .catch(function (err) {
                setSnackBarContent(err.message);
                console.log("err", err);
                //show alert to fix passwords
              });
          })
          .catch(function (err) {
            //show alert with error
            setSnackBarContent(err.message);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <Card className={classes.card}>
      <GridContainer>
        {savingPic ? (
          <GridItem className={classes.cardGridItem} xs={5}>
            <img
              style={{ width: "60%", height: "60%" }}
              src={loader}
              alt="..."
            />
          </GridItem>
        ) : (
          <GridItem className={classes.cardGridItem} xs={5}>
            <span className={classes.sectionTitle}>Profile Photo</span>
            <div
              style={{
                width: "100%",
                display: "flex",
                marginTop: "30px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                onClick={() => {
                  profilePicRef.current.click();
                }}
              >
                <input
                  onChange={handleProfilePictureChange}
                  type="file"
                  ref={profilePicRef}
                  style={{ display: "none" }}
                />
                {profilePic ? (
                  <img style={{ maxWidth: "175px" }} src={profilePic} />
                ) : (
                  <BiggerLogo />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                }}
              >
                <span className={classes.uploadPhoto}>
                  Upload Your Photo (optional)
                </span>
                <span className={classes.uploadPhotoSize}>
                  Image should be at least 300px x 300px.{" "}
                </span>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <Button
                    style={{
                      background: "#FFC720",
                      color: "#323232",
                      width: "120px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      margin: "10px",
                    }}
                    className={classes.btn}
                    onClick={() => {
                      profilePicRef.current.click();
                    }}
                  >
                    <Upload />
                    Upload
                  </Button>
                  <Button
                    style={{
                      background: "#323232",
                      color: "white",
                      width: "120px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      margin: "10px",
                    }}
                    onClick={handleProfilePicDelete}
                    className={classes.btn}
                  >
                    <DenyIcon />
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </GridItem>
        )}
        <GridItem className={classes.cardGridItem} xs={4}>
          <span className={classes.sectionTitle}>Basic Information</span>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              marginTop: "30px",
            }}
          >
            <div className={classes.basicInfoDiv}>
              <span className={classes.basicInfoCategory}>Contact Name</span>
              <span className={classes.profileValues}>
                {userInfo.name || "N/A"}
              </span>
            </div>
            <div className={classes.basicInfoDiv}>
              <span className={classes.basicInfoCategory}>Email(s)</span>
              <span className={classes.profileValues}>
                {userInfo.email || "N/A"}
              </span>
            </div>
            <div className={classes.basicInfoDiv}>
              <span className={classes.basicInfoCategory}>Company</span>
              <span className={classes.profileValues}>
                {company && company.companyName || "N/A"}
              </span>
            </div>
            <div className={classes.basicInfoDiv}>
              <span className={classes.basicInfoCategory}>Website</span>
              <span className={classes.profileValues}>
                {userInfo.website || "N/A"}
              </span>
            </div>
            <div className={classes.basicInfoDiv}>
              <span className={classes.basicInfoCategory}>Phone (Primary)</span>
              <span className={classes.profileValues}>
                {userInfo.phoneNumber || "N/A"}
              </span>
            </div>
            <div className={classes.basicInfoDiv}>
              {console.log("checking userinfo", userInfo)}
              <span className={classes.basicInfoCategory}>Phone (Secondary)</span>
              <span className={classes.profileValues}>
                {userInfo.secondaryPhoneNumber || "N/A"}
              </span>
            </div>
            <div className={classes.basicInfoDiv}>
              <span className={classes.basicInfoCategory}>
                Permission Level
              </span>
              <span className={classes.profileValues}>
                {userInfo.role || "N/A"}
              </span>
            </div>
          </div>
        </GridItem>
        <GridItem className={classes.cardGridItem} xs={3}>
          <span className={classes.sectionTitle}>Change Password</span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "30px 0px",
            }}
          >
            <TextField
              className={classes.searchBar}
              placeholder="Current Password"
              value={currentPassword}
              type={passwordVisible.current ? "text" : "password"}
              onChange={(e) => setCurrentPassword(e.target.value)}
              variant="outlined"
              InputProps={{
                classes: {
                  adornedEnd: classes.adournedEnd,
                },
                endAdornment: (
                  <InputAdornment
                    onClick={() =>
                      setPasswordVisible((prevState) => ({
                        ...prevState,
                        current: !prevState.current,
                      }))
                    }
                    className={classes.adournedEnd}
                    position="end"
                  >
                    <Eyecon className={classes.eyecon} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.searchBar}
              placeholder="New Password"
              value={newPassword}
              type={passwordVisible.newPass ? "text" : "password"}
              onChange={(e) => setNewPassword(e.target.value)}
              variant="outlined"
              InputProps={{
                classes: {
                  adornedEnd: classes.adournedEnd,
                },
                endAdornment: (
                  <InputAdornment
                    onClick={() =>
                      setPasswordVisible((prevState) => ({
                        ...prevState,
                        newPass: !prevState.newPass,
                      }))
                    }
                    className={classes.adournedEnd}
                    position="end"
                  >
                    <Eyecon className={classes.eyecon} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.searchBar}
              placeholder="Re-type New Password"
              value={retypeNewPassword}
              type={passwordVisible.retype ? "text" : "password"}
              onChange={(e) => setRetypeNewPassword(e.target.value)}
              variant="outlined"
              InputProps={{
                classes: {
                  adornedEnd: classes.adournedEnd,
                },
                endAdornment: (
                  <InputAdornment
                    onClick={() =>
                      setPasswordVisible((prevState) => ({
                        ...prevState,
                        retype: !prevState.retype,
                      }))
                    }
                    className={classes.adournedEnd}
                    position="end"
                  >
                    <Eyecon className={classes.eyecon} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={handleChangePassword}
              className={classes.changePassword}
            >
              Change Password
            </Button>
          </div>
        </GridItem>
      </GridContainer>

      <Snackbar open={snackbarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackbarContent.includes("Successfully") ? "success" : "error"
          }
        >
          {snackbarContent}
        </Alert>
      </Snackbar>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    company: state.company.company
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(ProfileCard));
