import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
// import Checkbox from "@material-ui/core/Checkbox";
import Email from "@material-ui/icons/Email";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import PersonIcon from "@material-ui/icons/Person";
import BoundInput from "components/CustomInput/BoundInput";
import RegistrationActions from "store/actions/forms/registration";
import RegistrationAccessors from "store/reducers/forms/registration";

const CustomInputBasic = BoundInput(
  RegistrationActions.basicDetails,
  RegistrationAccessors.basicDetails
);
const CustomInputCommon = BoundInput(
  RegistrationActions.commonProfile,
  RegistrationAccessors.commonProfile
);

const SignupBox = (props) => {
  const {
    classes,
    handleSubmitLogIn,
    onChange,
    // handleRememberMe,
    // checked,
    handleSignUp,
    navigateToLogin,
    userInfo
  } = props;
  console.log("checking userInfo", userInfo)
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} className={classes.formContainer}>
        <form onSubmit={handleSubmitLogIn}>
          <Card className={classes.signInCard}>
            <CardHeader className={classes.cardHeaderSignIn}>
              Sign Up
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <CustomInputCommon
                    labelText= {userInfo.firstName}
                    id="firstName"
                    name="firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      onChange: onChange,
                      disableUnderline: true,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <CustomInputCommon
                    labelText= {userInfo.lastName}
                    name="lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      onChange: onChange,
                      disableUnderline: true,
                      autoComplete: "off",
                      endAdornment: (
                        <InputAdornment position="end">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <CustomInputBasic
                labelText= {userInfo.email}
                id="email"
                name="email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  onChange: onChange,
                  disableUnderline: true,
                  autoComplete: "off",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
              <CustomInputBasic
                labelText="Password"
                id="password"
                name="password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: onChange,
                  type: "password",
                  autoComplete: "off",
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon>lock_outline</Icon>
                    </InputAdornment>
                  ),
                }}
              />
              <CustomInputBasic
                labelText="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: onChange,
                  type: "password",
                  disableUnderline: true,
                  autoComplete: "off",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon>lock_outline</Icon>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <Checkbox onChange={handleRememberMe} checked={checked} />
                  <span>Remember Me</span>
                </div>

                <span className={classes.forgotYourPassword}>
                  Forgot Your Password?
                </span>
              </div> */}
            </CardBody>
            <CardFooter>
              <GridContainer>
                <GridItem xs={6}>
                  <Button
                    onClick={navigateToLogin}
                    className={classes.signInButton}
                  >
                    Log In
                  </Button>
                </GridItem>
                <GridItem xs={6}>
                  <Button
                    style={{ background: "#323232", color: "white" }}
                    onClick={handleSignUp}
                    className={classes.signInButton}
                  >
                    Submit
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </form>
      </GridItem>
    </GridContainer>
  );
};

export default SignupBox;
