import {
  whiteColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const profileStyles = (theme) => ({
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px 0px 30px 0px",
    textAlign: "left",
  },
  accountActivated: {
    fontWeight: 700,
    fontSize: "23px",
    fontFamily: "Archivo",
    color: "#2EB67D",
    margin: "0px 0px 30px 0px",
    alignItems: "center"
  },
  filterMenu: {
    background: "white",
    borderRadius: "7px",
    width: "300px",
    height: "50px",
    marginRight: "15px",
  },
  filtersGridItem: {
    display: "flex",
    alignItems: "center",
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "65px 0px",
  },
  sortByHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
  },
  selectMenuSort: {
    fontFamily: "Archivo",
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    textAlign: "center",
    fontWeight: "bold",
    margin: "15px 15px 0px 10px",
  },
  sendMessageSelects: {
    fontFamily: "Archivo",
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "100%",
    height: "50px",
    textAlign: "center",
    fontWeight: "bold",
    margin: "0px",
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  ascMenu: {
    fontFamily: "Archivo",
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "20%",
    height: "50px",
    textAlign: "center",
    fontWeight: "bold",
    margin: "15px 15px 0px 10px",
  },
  buttonGridItem: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "30px",
  },
  addCustomer: {
    fontWeight: 700,
    textTransform: "capitalize",
    whiteSpace: "noWrap",
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#323232",
    padding: "12px",
    display: "flex",
    justifyContent: "space-evenly",
    "&:hover": {
      background: "#FFC720",
    },
  },
  editIcon: {
    marginRight: "10px",
  },
  card: {
    padding: "30px",
    margin: "0px 0px 30px 0px",
  },
  sectionTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    marginBottom: "30px !important",
  },
  profileValues: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    marginBottom: "20px",
  },
  flex: {
    display: "flex",
  },
  btn: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    fontFamily: "Archivo",
  },
  logo: {
    width: "50%",
  },
  basicInfoCategory: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height: "40px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  staffTable: {
    width: "100% !important",
    borderCollapse: "collapse",
  },
  changePassword: {
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "100%",
    marginTop: "30px",
  },
  cardGridItem: {
    padding: "0px 35px !important",
  },
  columnGridItem: {
    padding: "0px 35px !important",
    display: "flex",
    flexDirection: "column",
  },
  gridItemPaddingTop: {
    padding: "20px 15px !important",
  },
  gridItemPaddingBottom: {
    padding: "0px 15px 30px 15px !important",
  },
  justifyEnd: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  basicInfoDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingBottom: "15px",
  },
  eyecon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadPhoto: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#323232",
    marginBottom: "10px",
  },
  uploadPhotoSize: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#323232",
    marginBottom: "30px",
  },
  columnGridItem: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  inputTypeTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    marginBottom: "10px",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sendMessageButton: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
  },
  cancelSaveButton: {
    background: "#323232",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "10%",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
  },
  submitButton: {
    background: "#323232",
    color: "#FFC821",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "100%",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
  },
  modalCard: {
    margin: "0px",
    background: "rgb(243, 250, 255)",
  },
  modalTitle: {
    textAlign: "center",
    background: "#F1C84E !important",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "30px",
    margin: "0px",
    padding: "30px",
  },
  passwordField: {
    width: "100%",
  },
  selected: {
    background: "#FEDA1B !important",
    borderWidth: "0px 0.5px 0px 0.5px",
    borderStyle: "solid",
    borderColor: "#929292",
    "& $tabRootButton": {
      background: "#FEDA1B !important",
    },
  },

  displayNone: {
    display: "none !important",
  },
  billingInfoGridItem: {
    marginBottom: "20px",
  },
  billingInfoKey: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
  },
  billingInfoTitle: {
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Archivo",
    color: "#323232",
    lineHeight: "26px",
    margin: "0px 0px 20px 0px",
    textAlign: "left",
  },
  billingInfoValue: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
  },
  bilingInfoGridContainer: {
    padding: "20px",
  },
  justifyEnd: {
    display: "flex",
    justifyContent: "end",
  },
  searchBar: {
    width: "20%",
    background: "white",
    height: "40px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    // marginBottom: "15px",
  },
  userSearchBar: {
    background: "white",
    width: "95%",
  },
  containerMargin: {
    margin: "10px 0px",
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "8px 14px !important",
    height: "40px",
    width: "120px",
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    "&:hover": {
      background: "#FFC720",
    },
  },
  radioChecked: {
    width: "30px",
    height: "30px",
    border: "1px solid #FFC821",
    color: "#FFC821",
    borderRadius: "50%",
  },
  radioUnchecked: {
    width: "0px",
    height: "0px",
    padding: "7px",
    border: "1px solid #FFC821",
    borderRadius: "50%",
  },
  checked: {
    color: "#4B8DF8",
  },
  tableRow: {
    height: "95px",
  },
  tableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "30px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "19px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  actionBtn: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "15px",
    height: "50px",
    marginRight: "20px",
    fontFamily: "Archivo"
  },
  notesTableRow: {
    height: "50px",
    border: "1px solid #8E8E8E",
  },
  notesTableHeaders: {
    border: "0.5px solid #8E8E8E",
    background: "#F3F3F3",
    color: "#323232",
    fontFamily: "Archivo",
    fontSize: "17px",
  },
  tableHeaders: {
    border: "0.5px solid #8E8E8E",
    background: "#D9D9D9",
    color: "#323232",
    fontFamily: "Archivo",
    fontSize: "17px",
    padding: "10px",
  },
  notesTableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "19px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  notesTableCell2: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "10px 20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px",
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important",
  },
  tabsRoot: {
    minHeight: "unset !important",
    background: "#D9D9D9",
    borderWidth: "0.5px",
    borderStyle: "solid",
    borderColor: "#929292",
    borderRadius: "2px 2px 0px 0px",
    "& $tabRootButton": {
      fontSize: "0.875rem",
    },
  },
  tabsRootMinusBorder: {
    minHeight: "unset !important",
    background: "#D9D9D9",
    // borderWidth: "0.5px",
    // borderStyle: "solid",
    // borderColor: "#929292",
    // borderRadius: "2px 2px 0px 0px",
    "& $tabRootButton": {
      fontSize: "0.875rem",
    },
  },
  tabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    padding: "15px !important",
    maxHeight: "unset !important",
    borderRadius: "3px",
    margin: "0px",
  },
  tabLabelContainer: {
    padding: "0px",
  },
  tabLabel: {
    fontWeight: "500",
    fontSize: "12px",
  },
  tabSelected: {
    backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.2)",
    transition: "0.2s background-color 0.1s",
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    textTransform: "none",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    fontFamily: "Archivo !important",
    fontStyle: "normal !important",
    fontWeight: 500,
    fontSize: "17px !important",
    lineHeight: "18px !important",
    color: "#323232 !important",
    "& > svg,& > .fab,& > .fas,& > .far,& > .fal,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0 !important",
    },
  },
  gridContainerPadding: {
    padding: "30px",
  },
  sendMessageTitle: {
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Archivo",
    color: "#323232",
    textAlign: "left",
  },
  subjectLine: {
    width: "100%",
  },
  emailInput: {
    background: "#F5F5F5",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    width: "100%",
    marginBottom: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "33px",
    color: "#323232",
  },
  closeModal: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  inventoryTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    // [theme.breakpoints.down("md")]: {
    //   tableLayout: "fixed",
    //   width: "100%",
    // },
  },
  tableRow: {
    height: "95px",
  },
  select: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    background: "#323232",
    "&.MuiSelect-select:focus ": {
      background: "#323232",
      color: "white",
      fontWeight: "bold",
      borderRadius: "5px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid red",
    },
  },
  whiteColor: {
    color: "white",
  },
  indexes: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
  view: {
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },
  selectMenuSort: {
    fontFamily: "Archivo",
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 10px",
  },
  ascMenu: {
    fontFamily: "Archivo",
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "20%",
    height: "50px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 10px",
  },
  filterMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    margin: "0px 15px 0px 10px",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "19px",
    textAlign: "center"
  },
  dateRange: {
    fontFamily: "Archivo",
    fontWeight: 400,
    lineHeight: "18.5px",
    fontSize: "17px",
    margin: "5px 10px",

  },
});

export default profileStyles;
