/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

function EditUserProfileCard(props) {
  const {
    classes,
    history,
    userInfo,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    website,
    setWebsite,
    phoneNumber,
    setPhoneNumber,
    secondaryPhoneNumber,
    setSecondaryPhoneNumber,
    email,
    setEmail,
    company,
    setCompany,
    setChangedEmail,
    showUserOnSchedule,
    setShowUserOnSchedule,
    companyPicRef,
    savingPic,
    setSavingPic,
    userPic,
    setUserPic,
    handleProfilePictureChange,
    handleProfilePicDelete,
    permissionLevel,
    setPermissionLevel
  } = props;

  return (
    <Card style={{ marginBottom: "0px" }} className={classes.card}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <div
            style={{
              width: "100%",
              // display: "flex",
              marginTop: "30px",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "30%" }}
              onClick={() => {
                companyPicRef.current.click();
              }}
            >
              <input
                onChange={handleProfilePictureChange}
                type="file"
                ref={companyPicRef}
                style={{ display: "none" }}
              />
              {savingPic ? (
                <img
                  style={{ width: "60%", height: "60%" }}
                  src={loader}
                  alt="..."
                />
              ) : userPic ? (
                <img style={{ maxWidth: "200px" }} src={userPic} />
              ) : (
                <BiggerLogo />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
              }}
            >
              <span style={{marginTop: "20px"}} className={classes.uploadPhoto}>
                Upload your photo (Optional)
              </span>
              <span className={classes.uploadPhotoSize}>
                Image should be at least 300px x 300px
              </span>
            </div>
          </div>
          <div
            style={{
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{
                background: "#FFC720",
                color: "#323232",
                width: "30%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                padding: "10px",
              }}
              className={classes.btn}
              onClick={() => {
                companyPicRef.current.click();
              }}
            >
              <Upload />
              Upload
            </Button>
            <Button
              style={{
                background: "#323232",
                color: "white",
                width: "30%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                padding: "10px",
              }}
              onClick={handleProfilePicDelete}
              className={classes.btn}
            >
              <DenyIcon />
              Delete
            </Button>
          </div>
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>First Name</span>
          <TextField
            className={classes.addUserTextFields}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            variant="outlined"
          />
          <span className={classes.inputTypeTitle}>
            Phone Number (Optional)
          </span>
          <TextField
            value={phoneNumber}
            className={classes.addUserTextFields}
            onChange={(e) => setPhoneNumber(e.target.value)}
            variant="outlined"
          />
          <span className={classes.inputTypeTitle}>Show User On Schedule?</span>

          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio
                checked={showUserOnSchedule.yes}
                onChange={() => setShowUserOnSchedule({ yes: true, no: false })}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span className={classes.values}>Yes</span>
            </div>
            <div
              style={{
                width: "45%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                checked={showUserOnSchedule.no}
                onChange={() => setShowUserOnSchedule({ yes: false, no: true })}
                value="a"
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{ root: classes.radio, checked: classes.checked }}
              />
              <span className={classes.values}>No</span>
            </div>
          </div>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Last Name</span>
          <TextField
            value={lastName}
            className={classes.addUserTextFields}
            onChange={(e) => setLastName(e.target.value)}
            variant="outlined"
          />
          <span className={classes.inputTypeTitle}>Email</span>
          <TextField
            value={email}
            disabled
            className={classes.addUserTextFields}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
          />
          <span className={classes.emailNote}>
            This email address is the username that allows this user to login to
            the ServiceCore web app and mobile app. This does not have to be a
            working email address.
          </span>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(EditUserProfileCard));
