import { firebase, db } from "firebase/client";
import {
  getTransformIn,
  getTransformOut,
  applyTransform,
} from "firebase/datasource";
import "@firebase/auth";
import { push } from "connected-react-router";
import { errorCodes } from "variables/errors";
import { DataTypes } from "variables/constants";
import { logger } from "./util";

import { listenToCustomers } from "store/actions/customers/customerActions";
import { listenToSites } from "store/actions/sites/sitesActions";
import { listenToInventory } from "store/actions/inventory/inventoryActions";
import { listenToReservations } from "store/actions/reservations/reservationsActions";
import { listenToQuotes } from "store/actions/quotes/quotesActions";
import { listenToSwaps } from "store/actions/swaps/swapsActions";
import { listenToPickups } from "store/actions/pickups/pickupsActions";
import { listenToDrivers } from "store/actions/drivers/driversActions";
import { listenToTerritories } from "store/actions/territories/territoriesActions";
import { listenToJobs } from "store/actions/jobs/jobsActions";
import { listenToCompany } from "store/actions/company/companyActions";
import { listenToCreditMemos } from "store/actions/creditMemos/creditMemosActions";

const users = db.collection("users");

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const config = {
  fields: {
    dateOfBirth: { type: DataTypes.DATETIME },
  },
};

const transformIn = getTransformIn(config);
const transformOut = getTransformOut(config);

function adminStateInit(user) {
  return (dispatch) => {
    dispatch(listenToCustomers());
    dispatch(listenToInventory());
    dispatch(listenToReservations());
    dispatch(listenToQuotes());
    dispatch(listenToSwaps());
    dispatch(listenToPickups());
    dispatch(listenToDrivers());
    dispatch(listenToTerritories());
    dispatch(listenToJobs());
    dispatch(listenToSites());
    dispatch(listenToCreditMemos());
  };
}

function startGoogleLogin() {
  return (dispatch) => {
    return firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((user) => {
        console.log("user", user);
        const uid = user.user.uid;
        return users
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            console.log("snapshots", snapshot);
            if (!snapshot.exists) {
              return users
                .doc(uid)
                .set({
                  firstName: user.user.displayName.split(" ")[0],
                  lastName: user.user.displayName.split(" ")[1],
                })
                .then(() => {
                  dispatch(setUser(user));
                });
            } else {
              dispatch(setUser(user));
            }
          });
      });
  };
}

function startEmailLogin(credentials) {
  return logger("startEmailLogin", (dispatch) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((user) => {
        dispatch(setUser(user));
      });
  });
}

function startLoadProfile(user) {
  return logger("startLoadProfile", (dispatch) => {
    return users.doc(user.uid).onSnapshot((snapshot) => {
      const userData = snapshot.data();
      if (userData.companyId.length > 0) {
        console.log('userDataxxx', userData.companyId)
        dispatch(listenToCompany(userData));
      }
      dispatch(
        setUser({
          ...applyTransform(transformIn, userData),
          uid: user.uid,
        })
      );
    });
  });
}

function startUpdateProfile(user) {
  return logger("startUpdateProfile", (dispatch) => {
    const { uid, ...docData } = user;
    return users
      .doc(uid)
      .set(applyTransform(transformOut, docData))
      .then(() => dispatch(updateUser(user)));
  });
}

function startUserAdvance(credentials) {
  // Check with Jonathan how he wants to handle the firebase call
  // return (dispatch) => {
  //     return firebase.auth()
  //         .signInWithEmailAndPassword(credentials.email, credentials.password)
  //         .then(user => {
  //             dispatch(setUser(user));
  //         }).catch((error) => {
  //             dispatch(setError(error));
  //         });
  // };
}

function startDelete(credentials) {
  return logger("startDelete", (dispatch) => {
    console.warn("deleting", credentials && credentials.email);

    const userCredential = credentials
      ? firebase
        .auth()
        .signInWithEmailAndPassword(credentials.email, credentials.password)
        .then((credential) => credential.user)
      : Promise.resolve(firebase.auth().currentUser);

    return userCredential
      .then((user) => {
        if (user) {
          return Promise.all([
            users.doc(user.uid).delete(),
            user.delete(),
          ]).then(() => dispatch(logout()));
        } else {
          return Promise.reject({
            code: errorCodes.APP_AUTH_NOT_LOGGED_IN,
            message: "user not logged in",
          });
        }
      })
      .catch((error) => {
        // firebase seems to generate an uncaught auth/wrong-password if the user does not exist. We get an error
        // saying the user does not exist here, but the auth/wrong-password seems totally uncatchable.
        console.warn(
          "Note, if there is an auth/wrong-password error just before/after this, don't worry about it"
        );
        return Promise.reject(error);
      });
  });
}

function startEmailSignup(credentials, profile) {
  console.log("checking creditienals", credentials, profile)
  const userDoc = {
    email: credentials.email,
    ...profile,
    defaultScheduleView: "Day",
    companyId: "",
    billing: {
      billingAddress: "",
      cardHolderName: "",
      cardNumber: "",
      cardType: "",
      expiration: "",
    },
  };
  userDoc.name = profile.firstName + " " + profile.lastName;
  return logger("startEmailSignup", (dispatch) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then(async (user) => {
        userDoc.uid = user.user.uid;
        await users.doc(user.user.uid).set(applyTransform(transformOut, userDoc));
      })
      .then(() => {
        dispatch(setUser(userDoc));
        window.location.pathname = "/admin/dashboard";
      })
  );

}

function startPasswordReset(emailAddress) {
  return (dispatch) =>
    firebase
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .catch((error) => {
        dispatch(setError(error));
      });
}

function startLogout() {
  return (dispatch) => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logout());
        // push('/admin/dashboard')
      })
      .catch((error) => {
        dispatch(setError(error));
      });
  };
}

export function setUser(user) {
  return {
    type: "AUTH_SET_USER",
    user,
  };
}

function updateUser(user) {
  return {
    type: "AUTH_UPDATE_USER",
    user,
  };
}

function setError(error) {
  return {
    type: "AUTH_SET_ERROR",
    error,
  };
}

function logout() {
  return {
    type: "AUTH_LOGOUT",
  };
}

// function thunkify(action) {
//   return (dispatch, getState) => {
//     try {
//       dispatch(action);
//       getState(); // I think this is only necessary becasue of the test framework.
//       return Promise.resolve();
//     } catch (error) {
//       return Promise.reject(error);
//     }
//   };
// }

// export function actionsForAuth() {
//   return {
//     updateUser: (user) => thunkify(updateUser(user)),
//   };
// }

export default {
  startGoogleLogin,
  startEmailLogin,
  startUserAdvance,
  startEmailSignup,
  startPasswordReset,
  startLogout,
  startDelete,
  startLoadProfile,
  startUpdateProfile,
  setUser,
  updateUser,
  setError,
  logout,
  adminStateInit,
};
