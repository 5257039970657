import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import addTerritoryStyle from "assets/jss/material-dashboard-pro-react/views/addTerritoryStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { saveTerritory } from "store/actions/territories/territoriesActions";
import Button from "@material-ui/core/Button";
import GoogleMap from "./components/GoogleMap";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const listOne = [
  "Select the polygon tool from the list at the top of the map",
  "Click the first corner of your territory to set a starting point",
  "Continue clicking along the edge of your territory to create the boundary of this territory",
  "To close the territory, click again on your starting point",
];

const listTwo = [
  "Zoom in and out at any time",
  "Click and drag points to fine-tune their position",
  "If you mess up and need to start over, refresh the page",
];

const colorMap = [
  "#F5EB29",
  "#2AA4F4",
  "#929292",
  "#2EB67D",
  "#E96920",
  "#1F9C9C",
  "#E01E5A",
  "#36C5F0",
  "#FFC821",
];

function AddTerritory(props) {
  const { classes, history, handleSaveTerritory, territoriesData } = props;
  const [newTerritoryName, setNewTerritoryName] = useState("");
  const [savedPolygon, setSavedPolygon] = useState(false);
  const [newTerritoryColor, setNewTerritoryColor] = useState("");
  const [territoriesState, setTerritoriesState] = useState(false);
  const [formattedStateForMap, setFormattedStateForMap] = useState(false);
  const [alreadySelectedColors, setAlreadySelectedColors] = useState([]);
  const [alert, setAlert] = useState("");
  console.log("savedPolygon", territoriesData);

  useEffect(() => {
    if (territoriesData.length > 0) {
      let territoryDuplicate = [...territoriesData];
      const format = territoryDuplicate.map((d) => {
        let territoryPolygonArray = [];
        for (var key in d.territoryPolygon) {
          territoryPolygonArray.push({
            lat: d.territoryPolygon[key][1],
            lng: d.territoryPolygon[key][0],
          });
        }
        return territoryPolygonArray;
      });
      setAlreadySelectedColors(territoriesData.map((d) => d.territoryColor));
      setFormattedStateForMap(format);
    }
  }, [territoriesData]);

  const handleSave = () => {
    if (!savedPolygon || !newTerritoryColor || !newTerritoryName) {
    } else {
      const mappedPolygonDataPoints = {};
      savedPolygon.features[0].geometry.coordinates[0].forEach(
        (array, index) => {
          mappedPolygonDataPoints[index] = array;
        }
      );
      const data = {
        territoryPolygon: mappedPolygonDataPoints,
        territoryName: newTerritoryName,
        territoryColor: newTerritoryColor,
      };
      handleSaveTerritory(data, setAlert);
      setSavedPolygon(false);
      setNewTerritoryColor("");
      setNewTerritoryName("");
    }
  };

  return (
    <>
      <h1 className={classes.title}>Add Territory</h1>
      <Card className={classes.card}>
        <GridContainer>
          <GridItem xs={12}>
            <h5 className={classes.rentalsSubheader}>
              Draw Territory Perimeter
            </h5>
          </GridItem>
          <GridItem className={classes.googleMapGridItem} xs={12}>
            <GoogleMap
              territoriesData={territoriesData}
              formattedStateForMap={formattedStateForMap}
              setSavedPolygon={setSavedPolygon}
              alreadySelectedColors={alreadySelectedColors}
              newTerritoryColor={newTerritoryColor}
            />
          </GridItem>
          <GridItem className={classes.instructionsGridItem} xs={6}>
            <span className={classes.listTitle}>
              Follow these steps to draw a territory{" "}
            </span>
            <ul className={classes.list}>
              {listOne.map((i) => {
                return <li className={classes.listItem}>{i}</li>;
              })}
            </ul>
          </GridItem>
          <GridItem className={classes.instructionsGridItem} xs={6}>
            <span className={classes.listTitle}>
              Additional tips and tricks{" "}
            </span>
            <ul className={classes.list}>
              {listTwo.map((i) => {
                return <li className={classes.listItem}>{i}</li>;
              })}
            </ul>
          </GridItem>
        </GridContainer>
      </Card>

      <Card className={classes.secondCard}>
        <GridContainer>
          <GridItem className={classes.nameGridItem} xs={5}>
            <span className={classes.nameField}>Name</span>
            <TextField
              value={newTerritoryName}
              onChange={(e) => setNewTerritoryName(e.target.value)}
              className={classes.nameField}
              variant="outlined"
            />
          </GridItem>
          <GridItem className={classes.nameGridItem} xs={7}>
            <span className={classes.nameField}>Color</span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "20px",
              }}
            >
              {colorMap.map((c, index) => {
                if (c === newTerritoryColor) {
                  return (
                    <div className={classes.selectedColor}>
                      <div
                        className={classes.colors}
                        style={
                          alreadySelectedColors.includes(c)
                            ? { border: "3px solid red", background: c }
                            : { background: c }
                        }
                        key={index}
                        onClick={() => setNewTerritoryColor(c)}
                      ></div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={classes.colors}
                      style={
                        alreadySelectedColors.includes(c)
                          ? { border: "3px solid red", background: c }
                          : { background: c }
                      }
                      key={index}
                      onClick={() => {
                        if (alreadySelectedColors.includes(c)) {
                          // please make new selection
                          setAlert(
                            "There is already a territory with that color, please select a different one"
                          );
                        } else {
                          setNewTerritoryColor(c);
                        }
                      }}
                    ></div>
                  );
                }
              })}
            </div>
            <span className={classes.colorsDescription}>
              Colors that are already in use will appear with a red border. To
              customize a color, select it then click ‘Choose Color’.
            </span>
          </GridItem>
        </GridContainer>
      </Card>
      <GridContainer>
        <GridItem className={classes.saveButtonsGridItem} xs={12}>
          <Button
            style={{ background: "#323232", color: "white" }}
            className={classes.saveButton}
          >
            Cancel
          </Button>
          <Button
            style={{ background: "#D40707", color: "white" }}
            className={classes.saveButton}
          >
            Delete
          </Button>
          <Button
            style={{ background: "#FFC821" }}
            className={classes.saveButton}
            onClick={handleSave}
          >
            Save
          </Button>
        </GridItem>
      </GridContainer>

      <Snackbar open={alert} onClose={() => setAlert("")}>
        <Alert
          onClose={() => setAlert("")}
          severity={alert.includes("already a territory") ? "error" : "success"}
        >
          {alert}
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    territoriesData: state.territories.territories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSaveTerritory: (
    territoryData,
    setAlert,
    setSavedPolygon,
    setNewTerritoryColor
  ) =>
    dispatch(
      saveTerritory(
        territoryData,
        setAlert,
        setSavedPolygon,
        setNewTerritoryColor
      )
    ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(addTerritoryStyle)
)(React.memo(AddTerritory));
