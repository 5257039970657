import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import moment from "moment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";
import { MenuItem, Radio, Select } from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";

function RentalInfo(props) {
  const {
    classes,
    errors,
    dropOffAddress,
    setDropOffAddress,
    handleSelect,
    dropOffDate,
    setDropOffDate,
    pickUpDate,
    setPickUpDate,
    inventoryData,
    equipmentNeeded,
    setEquipmentNeeded,
    setCost,
    cost,
    sevenDayRental,
    setSevenDayRental,
    otherDuration,
    setOtherDuration,
    locationSelections,
    equipmentLocation,
    setEquipmentLocation,
    notes,
    setNotes
  } = props;

  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  const disablePickUpDt = (current) => {
    return current.isAfter(dropOffDate);
  };

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Drop Off Address{" "}
            {errors.dropOffAddress && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <GooglePlacesAutoComplete
            classes={classes}
            value={dropOffAddress}
            width={"100%"}
            placeholder="Search your drop-off address.."
            onChange={(e) => setDropOffAddress(e)}
            onSelect={handleSelect}
            margin="0px 0px 20px 0px"
            background="#F5F5F5"
            googleCallbackName="initOne"
          />

          <span className={classes.inputTypeTitle}>
            Request Drop Off Date*{" "}
            {errors.dropOffDate && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>

          <Datetime
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
            }}
            isValidDate={disablePastDt}
            selected={dropOffDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              setDropOffDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  className={classes.datetime}
                  placeholder="MM/DD/YY"
                  value={dropOffDate}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />

        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Container Needed{" "}
            {errors.equipmentNeeded && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <Select
            className={classes.filterMenu}
            value={equipmentNeeded}
            sx={{ background: "#F5F5F5" }}
            disableUnderline
            defaultValue={"Select Option"}
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => {
              setEquipmentNeeded(e.target.value);
              setCost(e.target.value.cost);
            }}
          >
            {inventoryData.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o.inventoryItem}
                </MenuItem>
              );
            })}
          </Select>

          <span className={classes.inputTypeTitle}>
            Rental Duration{" "}
            {errors.sevenDayRental && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>

          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // marginRight: "30px",
              }}
            >
              <Radio
                checked={sevenDayRental}
                onChange={() => {
                  setPickUpDate(moment(dropOffDate, "MM/DD/YY").add(7, "days").format("MM/DD/YY"))
                  setSevenDayRental(true)
                  setOtherDuration(false)
                }}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                style={{
                  width: "50px",
                  height: "50px",
                  // borderRadius: "10%",
                  // border: "1px solid #929292",
                }}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span style={{ whiteSpace: "noWrap" }}>7 Day Rental</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Radio
                checked={otherDuration}
                onChange={() => {
                  setOtherDuration(true)
                  setSevenDayRental(false)
                }}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                style={{
                  width: "50px",
                  height: "50px",
                  // borderRadius: "10%",
                  // border: "1px solid #929292",
                }}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span>Other</span>
            </div>
          </div>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Location of Dumpster{" "}
            {errors.equipmentLocation && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <Select
            className={classes.filterMenu}
            value={equipmentLocation}
            sx={{ background: "#F5F5F5" }}
            disableUnderline
            defaultValue={"Select Option"}
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => {
              setEquipmentLocation(e.target.value);
            }}
          >
            {locationSelections.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <span className={classes.inputTypeTitle}>
            Request Pick Up Date{" "}
            {errors.pickUpDate && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>

          {sevenDayRental && dropOffDate ?
            <TextField
              disabled
              className={classes.datetime}
              placeholder="MM/DD/YY"
              value={moment(dropOffDate, "MM/DD/YY").add(7, "days").format("MM/DD/YY")}
              variant="outlined"
              InputProps={{
                classes: {
                  adornedEnd: classes.adournedEnd,
                },
                endAdornment: (
                  <InputAdornment
                    className={classes.adournedEnd}
                    position="end"
                  >
                    <CalendarIcon
                      className={classes.calendarIcon}
                      // onClick={openCalendar}
                    />
                  </InputAdornment>
                ),
              }}
            />
            :
            <Datetime
              style={{
                color: "#323232 !important",
                height: "100%",
                width: "100%",
              }}
              isValidDate={disablePickUpDt}
              selected={pickUpDate}
              dateFormat="MM/DD/YY"
              onChange={(date, e) => {
                setPickUpDate(date.format("MM/DD/YY"));
              }}
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside
              renderInput={(props, openCalendar, closeCalendar) => (
                <div onClick={openCalendar}>
                  <TextField
                    className={classes.datetime}
                    placeholder="MM/DD/YY"
                    value={pickUpDate}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        adornedEnd: classes.adournedEnd,
                      },
                      endAdornment: (
                        <InputAdornment
                          className={classes.adournedEnd}
                          position="end"
                        >
                          <CalendarIcon
                            className={classes.calendarIcon}
                            onClick={openCalendar}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
            />
          }
        </GridItem>
        <GridItem xs={12} className={classes.columnGridItem}>
          <span className={classes.inputTypeTitle} style={{ margin: "7px 0px 0px 0px" }}>
            *PLEASE NOTE WE WILL DROP OFF & PICK UP ON THE DAYS YOU SELECT. Please ensure you select the correct dates for your desired rental duration.
          </span>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
    sites: state.sites.sites,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(RentalInfo));
