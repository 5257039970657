import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

function QuoteJobCard(props) {
  const { classes, quote } = props;

  const getARangeOfDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    // To calculate the time difference of two dates
    var Difference_In_Time = end.getTime() - start.getTime();
    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
  };

  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.siteInfoTitle}>Product Type</span>
          <span className={classes.siteInfoValue}>
            {renderEquipment(quote.reservation.equiptment)}
          </span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.siteInfoTitle}>Quantity</span>
          <span className={classes.siteInfoValue}>
            {quote.reservation.quantity}
          </span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.siteInfoTitle}>Rate</span>
          <span className={classes.siteInfoValue}>
            {quote.reservation.amount}
          </span>
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.siteInfoTitle}>Start Date</span>
          <span className={classes.siteInfoValue}>
            {quote.reservation.startDate}
          </span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.siteInfoTitle}>Expected End Date</span>
          <span className={classes.siteInfoValue}>
            {quote.reservation.endDate}
          </span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.siteInfoTitle}>Duration</span>
          <span className={classes.siteInfoValue}>
            {getARangeOfDays(
              quote.reservation.startDate,
              quote.reservation.endDate
            )}{" "}
            Days
          </span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={12}>
          <span className={classes.siteInfoTitle}>Other Information</span>
          <span className={classes.siteInfoValue}>
            {quote.reservation.notes || "--"}
          </span>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(QuoteJobCard));
