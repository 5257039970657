import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import upcomingPaymentsStyle from "assets/jss/material-dashboard-pro-react/views/upcomingPaymentsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import PaymentsTable from "./components/PaymentsTable";
import PaymentsSubHeader from "./components/PaymentsSubHeader";
import moment from "moment";

import { getNextDay, getPreviousDay } from "../../../helpers/calendarHelpers";

function UpcomingPayments(props) {
  const { classes, reservationsData, history } = props;
  // const [paymentDueDate, setPaymentDueDate] = useState(
  //   props.location.state.paymentsDue
  //     ? props.location.state.paymentsDue
  //     : moment(new Date()).format("ddd MM/DD/YY")
  // );
  const [paymentDueDate, setPaymentDueDate] = useState("All");
  const [searchString, setSearchString] = useState("");
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [reservations, setReservations] = useState(reservationsData);

  useEffect(() => {
    setReservations(reservationsData);
  }, [reservationsData]);

  const handleSearch = () => {
    if (searchString) {
      const filtered = reservationsData.filter((r) => {
        if (
          r.name.toLowerCase().includes(searchString) ||
          r.billingAddress.toLowerCase().includes(searchString) ||
          r.company.toLowerCase().includes(searchString) ||
          r.email.toLowerCase().includes(searchString) ||
          r.phoneNumber.toLowerCase().includes(searchString) ||
          r.reservation.reservationId.includes(searchString)
        ) {
          return true;
        }
      });
      console.log("filtered", filtered);
      setReservations(filtered);
    } else {
      setReservations(reservationsData);
    }
  };

  const handleFilterByDay = (date) => {
    const filtered = reservationsData.filter((r) => {
      if (
        new Date(date).getTime() ===
        new Date(r.reservation.paymentDueDate).getTime()
      ) {
        return true;
      }
    });
    setReservations(filtered);
  };

  const handleDayIncrement = () => {
    const incrementedDate = getNextDay(paymentDueDate);
    handleFilterByDay(incrementedDate);
    setPaymentDueDate(incrementedDate);
  };

  const handleDayDecrement = () => {
    const decrementedDate = getPreviousDay(paymentDueDate);
    handleFilterByDay(decrementedDate);
    setPaymentDueDate(decrementedDate);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <h1 className={classes.title}>Upcoming Payments</h1>
        </GridItem>
        <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
          <PaymentsSubHeader
            handleDayIncrement={handleDayIncrement}
            handleDayDecrement={handleDayDecrement}
            handleFilterByDay={handleFilterByDay}
            paymentDueDate={paymentDueDate}
            searchString={searchString}
            setSearchString={setSearchString}
            calendarOpen={calendarOpen}
            setCalendarOpen={setCalendarOpen}
            setPaymentDueDate={setPaymentDueDate}
            handleSearch={handleSearch}
          />
        </GridItem>
        <GridItem xs={12}>
          <PaymentsTable history={history} reservationsData={reservations} />
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(upcomingPaymentsStyle)
)(React.memo(UpcomingPayments));
