import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";

const communcationsOptions = ["Email", "Call"];
function MemoCard(props) {
  const {
    classes,
    invoice,
    history,
    servicesSubTotal,
    taxTotal,
    servicesTotal,
    customer,
    reservation,
    inventoryNeeded,
    setInventoryNeeded,
    inventoryData,
    quantityNeeded,
    setQuantityNeeded,
    setServices,
    services,
    handleChangeDiscount,
    discount,
    setMemo,
    memo,
    setPreferredCommunication,
    preferredCommunication,
  } = props;

  return (
    <Card className={classes.invoiceCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <h1 style={{ marginBottom: "30px" }} className={classes.title}>
            Internal Memo (Optional)
          </h1>
          <TextareaAutosize
            aria-label="empty textarea"
            onChange={(e) => setMemo(e.target.value)}
            placeholder=""
            style={{ maxWidth: "100%", minHeight: "60px" }}
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <h1 style={{ marginBottom: "30px" }} className={classes.title}>
            Preferred Communication
          </h1>
          <Select
            className={classes.filterMenu}
            variant="outlined"
            value={preferredCommunication}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setPreferredCommunication(e.target.value)}
          >
            {communcationsOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem xs={4}>
          <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Checkbox
              style={{
                color: "#323232",
              }}
              checked={true}
              disabled
            />
            <span className={classes.sameAsOwnerAddress}>
              Allow online payment by credit card (Visa, MasterCard, American
              Express, Discover Card)
            </span>
          </div>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(MemoCard));
