import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconWhite.svg";
import { BsFillCircleFill } from "react-icons/bs";
const headers = [
  "Site Address",
  "Nickname",
  "Territory",
  "Customer",
  "Actions",
];

const mockData = [
  {
    siteAddress: "792 S Acoma St Englewood, CO 83234",
    nickname: "Wendy's Locked Backlot",
    territory: "Northwest",
    customer: "Steven Posada"
  }
]

function CustomerViewSitesTables(props) {
  const { classes, reservations, history, pageDropdown, pageNumber, itemsPerPage } = props;
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key} style={{ fontSize: "14px" }}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if (pageDropdown == "4") {
      return mockData
        .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
        .map((d, index) => {
          return (
            <tr className={classes.tableRow} key={d.name}>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell2}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <span
                      onClick={() =>
                        history.push({
                          pathname: `/admin/rentals/${d.reservation.reservationId}`,
                          state: { reservation: d },
                        })
                      }
                      className={classes.nameAndCompanyText}
                    >
                      {d.siteAddress}
                    </span>
                  </div>
                </div>
              </td>
              <td className={classes.tableCell2} style={{ background: "#FFFFFF" }}>
                {d.nickname}
              </td>
              <td className={classes.tableCell2}>
                <BsFillCircleFill
                  className={classes.bulletPoint}
                  style={{ color: "#E01E5A" }}
                />{d.territory}</td>
              <td className={classes.tableCell2}>{d.customer}</td>
              <td className={classes.tableCell2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      style={{ background: "#FFC720" }}
                      className={classes.actionBtn}
                    >
                      <EyeIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#323232" }}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          );
        });
    }
    else {
      return mockData.map((d, index) => {
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell2}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/rentals/${d.reservation.reservationId}`,
                        state: { reservation: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {d.siteAddress}
                  </span>
                </div>
              </div>
            </td>
            <td className={classes.tableCell2} style={{ background: "#FFFFFF" }}>
              {d.nickname}
            </td>
            <td className={classes.tableCell2}>
              <BsFillCircleFill
                className={classes.bulletPoint}
                style={{ color: "#E01E5A" }}
              />
              {d.territory}</td>
            <td className={classes.tableCell2}>{d.customer}</td>
            <td className={classes.tableCell2}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#323232" }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <GridContainer >
      <GridItem xs={12}>
        <div style={pageDropdown != 4 && reservations.length > 4 ? { height: "550px", width: "100%", overflowY: "scroll" } : null}>
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(CustomerViewSitesTables));
