import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "views/Dashboard/components/pagination.css";
// core components
import { MenuItem, Select } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

const headers = [
  "Customer",
  "Billing Address",
  "Contact Info",
  "Comments",
  "Actions",
];

const pageArr = ["1", "All"];

function CustomersTable(props) {
  const {
    classes,
    customersData,
    setAddRegularModal,
    handleAddRegularTagClick,
    history,
  } = props;

  const itemsPerPage = 1;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("1");

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  useEffect(() => {
    customersData.slice(index, endIndex);
  }, [index]);

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if (pageDropdown == "1") {
      return customersData
        .slice(
          pageNumber * itemsPerPage,
          pageNumber * itemsPerPage + itemsPerPage
        )
        .map((d, index) => {
          return (
            <tr className={classes.tableRow} key={d.name}>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                <span
                  onClick={() =>
                    history.push({
                      pathname: `/admin/customers/${d.customerId}`,
                      state: { customer: d },
                    })
                  }
                  className={classes.nameAndCompanyText}
                >
                  {" "}
                  {d.name}
                </span>{" "}
                |<span className={classes.nameAndCompanyText}>{d.company || 'N/A'}</span>
              </td>
              <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
                {d.billingAddress}
              </td>
              <td className={classes.tableCell}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <span>{d.phoneNumber}</span>
                  <span>{d.email}</span>
                </div>
              </td>
              <td className={classes.tableCell}>
                <div style={{ display: "flex", width: "100%" }}>
                  {d.comments.map((c, index) => {
                    return (
                      <div
                        key={index}
                        style={
                          c === "Regular"
                            ? { background: "#2EB67D" }
                            : c === "Cautious"
                              ? { background: "#323232" }
                              : c === "Overdue"
                                ? { background: "#F83030" }
                                : c === "New"
                                  ? { background: "#0071C5" }
                                  : { background: "#ECB22E", color: "#323232" }
                        }
                        className={classes.commentDiv}
                      >
                        {c}
                      </div>
                    );
                  })}
                </div>
              </td>
              <td className={classes.tableCell}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="Add 'Regular' Tag To Customer"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      onClick={() => handleAddRegularTagClick(d)}
                      style={{ background: "#FFC720" }}
                      className={classes.addRegularCommentButton}
                    >
                      <AddIcon />
                      <span>Add as Regular</span>
                    </Button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          );
        });
    }
    else {
      return customersData.map((d, index) => {
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              <span
                onClick={() =>
                  history.push({
                    pathname: `/admin/customers/${d.customerId}`,
                    state: { customer: d },
                  })
                }
                className={classes.nameAndCompanyText}
              >
                {" "}
                {d.name}
              </span>{" "}
              |<span className={classes.nameAndCompanyText}>{d.company || 'N/A'}</span>
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.billingAddress}
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <span>{d.phoneNumber}</span>
                <span>{d.email}</span>
              </div>
            </td>
            <td className={classes.tableCell}>
              <div style={{ display: "flex", width: "100%" }}>
                {d.comments.map((c, index) => {
                  return (
                    <div
                      key={index}
                      style={
                        c === "Regular"
                          ? { background: "#2EB67D" }
                          : c === "Cautious"
                            ? { background: "#323232" }
                            : c === "Overdue"
                              ? { background: "#F83030" }
                              : c === "New"
                                ? { background: "#0071C5" }
                                : { background: "#ECB22E", color: "#323232" }
                      }
                      className={classes.commentDiv}
                    >
                      {c}
                    </div>
                  );
                })}
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="Add 'Regular' Tag To Customer"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    onClick={() => handleAddRegularTagClick(d)}
                    style={{ background: "#FFC720" }}
                    className={classes.addRegularCommentButton}
                  >
                    <AddIcon />
                    <span>Add as Regular</span>
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }

  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div
          style={
            pageDropdown != 1 && customersData.length > 1
              ? { height: "270px", width: "100%", overflowY: "scroll" }
              : null
          }
        >
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
        {pageDropdown == "1" ? (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "20%",
                      marginLeft: "10px",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={
                      {
                        // className: classes.selectMenu,
                      }
                    }
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginTop: "10px" }}>
              <h5
                className={classes.indexes}
              >
                {index + 1} - {endIndex} of{" "}
                {Math.ceil(customersData.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={2} style={{ marginTop: "10px" }}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(customersData.length / itemsPerPage)}
                nextLabel={
                  <ArrowRight
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                previousLabel={
                  <ArrowLeft
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "20%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={
                      {
                        // className: classes.selectMenu,
                      }
                    }
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(CustomersTable));
