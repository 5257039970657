/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";
import Datetime from "react-datetime";
import moment from "moment";

const jobTypes = ["Pick Up", "Delivery"];
const productType = ["Pick Up", "Delivery"];

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

function DriverNotes(props) {
  const {
    classes,
    inventoryData,
    jobType,
    setJobType,
    productType,
    setProductType,
    quantity,
    setQuantity,
    selectedDriver,
    setSelectedDriver,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    emergencyJob,
    setEmergencyJob,
    setRecurringJob,
    recurringJob,
    setDriverNotes,
    driverNotes,
  } = props;

  const handleSelect = (address, setFunction) => {
    setFunction(address);
  };

  return (
    <Card style={{ marginTop: "0px" }} className={classes.card}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={12}>
          <span className={classes.subTitle}>Driver Notes</span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={6}>
          <TextareaAutosize
            aria-label="empty textarea"
            className={classes.emailInput}
            onChange={(e) => setDriverNotes(e.target.value)}
            placeholder=""
            style={{ maxWidth: "100%", minHeight: "100px" }}
          />
        </GridItem>
        <GridItem className={classes.columnGridItem + ' ' + classes.spaceBetween} xs={6}>
          <span className={classes.inputTypeTitle}>Miscellaneous Charges</span>
          <Autocomplete
            id="country-select-demo"
            // style={{ width: 180 }}
            // value={contactsString}
            inputProps={{ style: { maxHeight: 20 } }}
            className={classes.customerInput}
            // options={customersData.map((c) => c.name)}
            classes={{
              option: classes.option,
            }}
            onChange={(e) => {
              const index = e.target.id.split("-")[4];
              // if (index) {
              //   console.log("indexxxx", index, e.target.id);
              //   if (contacts.indexOf(customerData[index]) === -1) {
              //     setContacts((prevState) => [
              //       ...prevState,
              //       customerData[index],
              //     ]);
              //   }
              // }
            }}
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                // value={contactsString}
                // onChange={(e) => setContactsString(e.target.value)}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <SearchIcon
                        className={classes.calendarIcon}
                        // onClick={openCalendar}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </GridItem>
          <GridItem className={classes.columnGridItem} xs={12}>
          <span className={classes.subTitle}>Disclaimer</span>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log("stateee", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    sites: state.sites.sites,
    inventoryData: state.inventory.inventory,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(DriverNotes));
