import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

function InvoiceDetailsCard(props) {
  const { classes, invoice, history, customer, reservation } = props;

  const paymentDateString = moment(invoice.invoiceDate.toDate()).format(
    "MM/DD/YY"
  );
  return (
    <Card className={classes.invoiceCard}>
      <GridContainer>
        <GridItem className={classes.jobsHeaderGridItem} xs={12}>
          <h1 style={{ marginBottom: "30px" }} className={classes.title}>
            Invoice Details
          </h1>
        </GridItem>
        <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Invoice Date
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {paymentDateString}
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Amount
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              ${invoice.servicesTotal}
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Date Due
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {paymentDateString}
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Discount
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              <span className={classes.totalsValue}>
                ${invoice.discount ? invoice.discount : "0.00"}
              </span>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Invoice Status
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              <div
                style={
                  invoice.invoiceStatus === "Approved"
                    ? { background: "#2EB67D", width: "120px" }
                    : { background: "#323232", width: "120px" }
                }
                className={classes.statusDiv}
              >
                {" "}
                {invoice.invoiceStatus}
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>

        {/* <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Phone (Primary)
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {reservation.reservation.amountPaid}
            </GridItem>
          </GridContainer>
        </GridItem> */}

        <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Clerk
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {invoice.clerk}
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Open Balance
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              $
              {invoice.invoiceDetails.paymentStatus === "Paid"
                ? "0.00"
                : invoice.servicesTotal}
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Emailed On
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {invoice.emailed && typeof invoice.emailed !== 'boolean' ? moment(invoice.emailed.toDate()).format("MM/DD/YY") : "N/A"}
            </GridItem>
          </GridContainer>
        </GridItem>

        {reservation && (
          <GridItem xs={6}>
            <GridContainer>
              <GridItem className={classes.siteInfoTitle} xs={6}>
                Site Address
              </GridItem>
              <GridItem className={classes.siteInfoValue} xs={6}>
                {reservation.reservation.siteAddress}
              </GridItem>
            </GridContainer>
          </GridItem>
        )}
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(InvoiceDetailsCard));
