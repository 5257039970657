import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import moment from "moment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { CheckRounded, Search } from "@material-ui/icons";
import { filterData } from "globalHelperFunctions/globalHelperFunctions";

function ChangeDetails(props) {
  const {
    classes,
    errors,
    type,
    setType
  } = props;

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={12}>
          <span
            style={{ textTransform: "none" }}
            className={classes.siteInfoTitle}
          >
            What would you like to change?{" "}
            {errors.quoteType && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <GridContainer>
            <GridItem xs={4}>
              <Radio
                checked={type.rental}
                onChange={() =>
                  setType({ rental: true, job: false, invoice: false })
                }
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                // icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span>Rental</span>
            </GridItem>
            <GridItem xs={4}>
              <Radio
                checked={type.job}
                onChange={() =>
                  setType({ rental: false, job: true, invoice: false })
                }
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                // icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span>Job</span>
            </GridItem>
            <GridItem xs={4}>
              <Radio
                checked={type.invoice}
                onChange={() =>
                  setType({ rental: false, job: false, invoice: true })
                }
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                // icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span>Invoice</span>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(ChangeDetails));
