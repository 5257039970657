import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";

function ExistingCustomerBusiness(props) {
  const {
    classes,
    emailString,
    setEmailString,
    searchForEmailAndVerifyPin,
    searchingCustomers,
    didntFindCustomerError,
    customersLoaded,
    setCustomersLoaded,
    inventoryData,
    setCustomers,
    BusinessOrIndividual,
    pin,
    setPin,
    errors,
    businessError
  } = props;

  return (
    <Card className={classes.card}>
      <h1 className={classes.title}>
        Please Enter Your Details Below
      </h1>

      {searchingCustomers ? (
        <img style={{ width: "60%", height: "60%" }} src={loader} alt="..." />
      ) : (
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          {businessError && (
            <span style={{ color: "red", marginBottom: "20px" }}>
              {businessError}
            </span>
          )}

          <h5 className={classes.formTitle}>
            Email:{" "}
            {errors.emailString ? (
              <span style={{ color: "red" }}>Required*</span>
            ) : null}
          </h5>
          <TextField
            className={classes.emailInput}
            placeholder="Email"
            value={emailString}
            onChange={(e) => setEmailString(e.target.value)}
            variant="outlined"
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
            }}
          />

          <h5 className={classes.formTitle}>
            Business Pin:{" "}
            {errors.pin ? (
              <span style={{ color: "red" }}>Required*</span>
            ) : null}
          </h5>

          <TextField
            className={classes.emailInput}
            placeholder="Pin"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            variant="outlined"
            // onKeyDown={(e) => {
            //   if (e.keyCode === 13) {
            //     searchForEmail();
            //   }
            // }}
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
              // endAdornment: (
              //   <InputAdornment className={classes.adournedEnd} position="end">
              //     <Button
              //       onClick={searchForEmail}
              //       className={classes.searchButton}
              //     >
              //       Search
              //     </Button>
              //   </InputAdornment>
              // ),
            }}
          />

          <Button
            onClick={searchForEmailAndVerifyPin}
            className={classes.searchButton}
          >
            Search
          </Button>
        </div>
      )}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(ExistingCustomerBusiness));
