import React from "react";
import { useDroppable } from "@dnd-kit/core";

export default function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    data: {
      id: props.id,
      // change this if using for something other than reservations
    },
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        display: "flex",
        flexDirection: "column",
        width: `${props.columnWidth}%`,
      }}
    >
    </div>
  );
}
