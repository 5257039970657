import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import {
  filterData,
  filterDataWithComments,
} from "globalHelperFunctions/globalHelperFunctions";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { ReactComponent as SwapIcon } from "assets/img/swapIcons.svg";
import { ReactComponent as PickUpIcon } from "assets/img/pickupIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as BlackInfo } from "assets/img/blackInfoIcon.svg";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as WhitePickup } from "assets/img/whitePickupIcon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { MenuItem, Select } from "@material-ui/core";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

const pageArr = ["3", "All"];
const selectOptions = ["First Name", "Last Name", "Company", "Email"];
const directionOptions = ["Asc", "Desc"];

function FrequentCustomers(props) {
  const { classes, regularsData, history } = props;
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterSelection, setFilterSelection] = useState("First Name");
  const itemsPerPage = 3;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("3");
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(regularsData);
  }, [regularsData]);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  useEffect(() => {
    data.slice(index, endIndex);
  }, [index]);

  const handleFilters = (type, filterValue) => {
    if (type === "filter") {
      const filtered = filterDataWithComments(
        filterValue,
        directionSelection,
        "All",
        data
      );
      setFilterSelection(filterValue);
      setData([...filtered]);
    }
    if (type === "direction") {
      const filtered = filterDataWithComments(
        filterSelection,
        filterValue,
        "All",
        data
      );
      setDirectionSelection(filterValue);
      setData([...filtered]);
    }
    if (type === "comments") {
      const filtered = filterDataWithComments(
        filterSelection,
        directionSelection,
        filterValue,
        data
      );
      setData([...filtered]);
    }
  };

  const renderTableData = () => {
    if (pageDropdown == "3") {
      return data
        .slice(
          pageNumber * itemsPerPage,
          pageNumber * itemsPerPage + itemsPerPage
        )
        .map((d, index) => {
          return (
            <tr
              style={{ height: "100%" }}
              className={classes.tableRow}
              key={d.name}
            >
              <td
                style={{ background: "#FFFFFF", width: "50%" }}
                className={classes.tableCell}
              >
                <span
                  onClick={() =>
                    history.push({
                      pathname: `/admin/customers/${d.customerId}`,
                      state: { customer: d },
                    })
                  }
                  className={classes.nameAndCompanyText}
                >
                  {" "}
                  {d.name}
                </span>{" "}
                |
                <span className={classes.nameAndCompanyText}>
                  {d.company || "N/A"}
                </span>
              </td>
              <td
                style={{ background: "#FFFFFF", width: "50%" }}
                className={classes.tableCell}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <Tooltip
                    id="tooltip-top"
                    title="Swap"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      style={{ background: "#FFC821" }}
                      className={classes.frequentButtonDiv}
                    >
                      <SwapIcon />
                    </div>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Pick Up"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      style={{ background: "#323232" }}
                      className={classes.frequentButtonDiv}
                    >
                      <WhitePickup />
                    </div>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Add Rental"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      style={{ background: "#FEDA1B" }}
                      className={classes.frequentButtonDiv}
                    >
                      <AddIcon />
                    </div>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Customer Info"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      onClick={() =>
                        history.push({
                          pathname: `/admin/customers/${d.customerId}`,
                          state: { customer: d },
                        })
                      }
                      style={{ background: "#929292" }}
                      className={classes.frequentButtonDiv}
                    >
                      <BlackInfo />
                    </div>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Open In Slack"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #323232",
                      }}
                      className={classes.frequentButtonDiv}
                    >
                      <SlackIcon />
                    </div>
                  </Tooltip>
                </div>
              </td>
            </tr>
          );
        });
    } else {
      return data.map((d, index) => {
        return (
          <tr
            style={{ height: "85px" }}
            className={classes.tableRow}
            key={d.name}
          >
            <td
              style={{ background: "#FFFFFF", width: "50%" }}
              className={classes.tableCell}
            >
              <span
                onClick={() =>
                  history.push({
                    pathname: `/admin/customers/${d.customerId}`,
                    state: { customer: d },
                  })
                }
                className={classes.nameAndCompanyText}
              >
                {" "}
                {d.name}
              </span>{" "}
              |
              <span className={classes.nameAndCompanyText}>
                {d.company || "N/A"}
              </span>
            </td>
            <td
              style={{ background: "#FFFFFF", width: "50%" }}
              className={classes.tableCell}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <Tooltip
                  id="tooltip-top"
                  title="Swap"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div
                    style={{ background: "#FFC821" }}
                    className={classes.frequentButtonDiv}
                  >
                    <SwapIcon />
                  </div>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Pick Up"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div
                    style={{ background: "#323232" }}
                    className={classes.frequentButtonDiv}
                  >
                    <WhitePickup />
                  </div>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Add Rental"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div
                    style={{ background: "#FEDA1B" }}
                    className={classes.frequentButtonDiv}
                  >
                    <AddIcon />
                  </div>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Customer Info"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${d.customerId}`,
                        state: { customer: d },
                      })
                    }
                    style={{ background: "#929292" }}
                    className={classes.frequentButtonDiv}
                  >
                    <BlackInfo />
                  </div>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Open In Slack"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #323232",
                    }}
                    className={classes.frequentButtonDiv}
                  >
                    <SlackIcon />
                  </div>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
        <GridContainer>
          <GridItem xs={3}>
            <p className={classes.subHeader} style={{ whiteSpace: "noWrap" }}>
              Frequent Customers
            </p>
          </GridItem>
          <GridItem xs={8} style={{ display: "flex", justifyContent: "end", }}>
            <p className={classes.subHeader} style={{ fontSize: "15px" }}>Sort By</p>
            <Select
              style={{ marginTop: "-10px" }}
              className={classes.selectMenuSort}
              value={filterSelection}
              disableUnderline
              classes={{
                root: classes.selectText,
              }}
              onChange={(e) => handleFilters("filter", e.target.value)}
            >
              {selectOptions.map((o, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={o}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {o}
                  </MenuItem>
                );
              })}
            </Select>

            <Select
              style={{ marginTop: "-10px" }}
              className={classes.ascMenu}
              disableUnderline
              classes={{
                root: classes.selectText,
              }}
              value={directionSelection}
              onChange={(e) => handleFilters("direction", e.target.value)}
            >
              {directionOptions.map((o, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={o}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {o}
                  </MenuItem>
                );
              })}
            </Select>
          </GridItem>
          <GridItem xs={1}>
            <p
              onClick={() => history.push(`/admin/regulars`)}
              className={classes.seeMore}
              style={{ marginTop: "0px", whiteSpace: "noWrap" }}
            >
              See All
            </p>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <div
          style={
            pageDropdown != 3 && data.length > 3
              ? { height: "270px", width: "100%", overflowY: "scroll" }
              : null
          }
        >
          <table className={classes.inventoryTable}>
            <tbody>{renderTableData()}</tbody>
          </table>
        </div>
        {pageDropdown == "3" ? (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "20%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={3} style={{ marginTop: "5px" }}>
              <h5 className={classes.indexes}>
                {index + 1} - {endIndex} of{" "}
                {Math.ceil(data.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={3}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(data.length / itemsPerPage)}
                nextLabel={
                  <ArrowRight
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                previousLabel={
                  <ArrowLeft
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "20%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(dashboardStyles)
)(FrequentCustomers);
