import { connect } from "react-redux";
import Explorer from "./Explorer";
import BoundExplorerCard from "./BoundExplorerCard";
import BoundCardFields from "components/Card/BoundCardFields";
import BoundCardField from "components/Card/BoundCardField";
import BoundSearchControl from "./BoundSearchControl";
import BoundSearchDialog from "./BoundSearchDialog";

import actionsForNavigation from "store/actions/navigation";

function mapStateToProps(actionsForExplorer, accessorsForExplorer) {

    const ExplorerCard = BoundExplorerCard(actionsForExplorer.recordset, accessorsForExplorer.recordset);
    const CardFields = BoundCardFields(actionsForExplorer.recordset, accessorsForExplorer.recordset);
    const CardField = BoundCardField(actionsForExplorer.recordset, accessorsForExplorer.recordset);
    const SearchControl = BoundSearchControl(actionsForExplorer, accessorsForExplorer);
    const SearchDialog = BoundSearchDialog(actionsForExplorer, accessorsForExplorer);

    return (state, ownProps) => {
        return {
            records: accessorsForExplorer.recordset.get(state).records,
            ExplorerCard: ownProps.ExplorerCard || ExplorerCard,
            CardFields,
            CardField,
            SearchControl,
            SearchDialog
        };
    };
}

function mapDispatchToProps(actions) {    
    return (dispatch, ownProps) => ({
        openChild: (title, route) => dispatch(actionsForNavigation.openChild(title, route)),
    });
}

export default (actionsForExplorer, accessorsForExplorer)=>connect(mapStateToProps(actionsForExplorer, accessorsForExplorer), mapDispatchToProps(actionsForExplorer))(Explorer);
