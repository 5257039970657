import React, { useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { ReactComponent as SwapIcon } from "assets/img/swapIcons.svg";
import { ReactComponent as PickUpIcon } from "assets/img/pickupIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { ReactComponent as InfoIcon } from "assets/img/infoIcon.svg";
import { ReactComponent as BlackInfoIcon } from "assets/img/blackInfoIcon.svg";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";
import { ReactComponent as AnnouncementIcon } from "assets/img/announcement.svg";

import Tooltip from "@material-ui/core/Tooltip";

function UpdatesTable(props) {
  const {
    classes,
    updatesData,
    handleModalOpen,
    pageDropdown,
    pageNumber,
    itemsPerPage,
    history,
  } = props;

  let data;
  if (pageDropdown === "3") {
    data = updatesData.slice(
      pageNumber * itemsPerPage,
      pageNumber * itemsPerPage + itemsPerPage
    );
  } else {
    data = updatesData;
  }
  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };
  return data.map((d, index) => {
    return (
      <tr style={{ height: "85px", display: "flex" }} key={d.name}>
        <td
          style={{ alignItems: "center", justifyContent: "center" }}
          className={classes.pendingReservationsTableCell}
        >
          <div
            style={{
              textAlign: "left",
              width: "100%",
              justifyContent: "center",
            }}
            className={classes.column}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className={classes.pendingReservationsTableCellName}>
                {d.name}
              </span>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className={classes.pendingReservationsTableCellAddress}>
                {d.reservation.dropOffCity + ", " + d.reservation.dropOffState}
              </span>
              <span className={classes.pendingReservationsTableCellAddress}>
                {d.phoneNumber}
              </span>
            </div>
          </div>
        </td>
        <td className={classes.pendingReservationsAddressTableCell}>
          <div style={{ justifyContent: "center" }} className={classes.column}>
            <span
              style={{ textAlign: "left" }}
              className={classes.pendingReservationsTableCellName}
            >
              Equipment:
              <span style={{ fontWeight: 500, marginLeft: "5px" }}>
                {renderEquipment(d.reservation.equiptment)}
              </span>
            </span>
            <span
              style={{ textAlign: "left" }}
              className={classes.pendingReservationsTableCellName}
            >
              Drop Off:{" "}
              <span style={{ fontWeight: 500, marginRight: "10px" }}>
                {d.reservation.startDate}
              </span>
              Pickup:{" "}
              <span style={{ fontWeight: 500 }}>{d.reservation.endDate}</span>
            </span>
          </div>
        </td>
        <td className={classes.pendingReservationsButtonsTableCell}>
          <div style={{ display: "flex", width: "100%" }}>
            <Tooltip
              id="tooltip-top"
              title="Info"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <div
                onClick={() => history.push(`/admin/quotes/${d.quoteId}`)}
                style={{ background: "#FFC821" }}
                className={classes.pendingButtonDiv}
              >
                <BlackInfoIcon style={{ fill: "black" }} />
              </div>
            </Tooltip>
            {/* <Tooltip
              id="tooltip-top"
              title="View"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <div
                onClick={() => history.push(`/admin/quotes/${d.quoteId}`)}
                style={{ background: "#FFC821" }}
                className={classes.pendingButtonDiv}
              >
                <Eyecon style={{ fill: "black" }} />
              </div>
            </Tooltip> */}
            <Tooltip
              id="tooltip-top"
              title="Edit"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <div
                onClick={() => handleModalOpen("Edit Quote", d)}
                style={{ background: "#323232" }}
                className={classes.pendingButtonDiv}
              >
                <WhiteEditIcon />
              </div>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Notify"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <div
                style={{ background: "#F5EB29" }}
                className={classes.pendingButtonDiv}
                onClick={() => handleModalOpen("Notify", d)}
              >
                <AnnouncementIcon />
              </div>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Reserve"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <div
                style={{ background: "#FEDA1B" }}
                className={classes.pendingButtonDiv}
                onClick={() => handleModalOpen("Reserve", d)}
              >
                <CheckIcon />
              </div>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title="Cancel"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <div
                style={{ background: "#929292" }}
                className={classes.pendingButtonDiv}
                onClick={() => handleModalOpen("Cancel Quote", d)}
              >
                <BlackDenyIcon />
              </div>
            </Tooltip>
          </div>
        </td>
      </tr>
    );
  });
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(dashboardStyles)
)(UpdatesTable);
