/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import addProductStyle from "assets/jss/material-dashboard-pro-react/views/addProductStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Checkbox from "@material-ui/core/Checkbox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

function TypeCard(props) {
  const {
    classes,
    history,
    itemName,
    product,
    setProduct,
    setItemName,
    setTotalQty,
    totalQty,
    setAvailable,
    available,
    rate,
    setRate,
    outOfService,
    setOutOfService,
    itemizedProduct,
    setItemizedProduct,
  } = props;

  return (
    <Card style={{ marginTop: "0px", marginBottom: "30px" }} className={classes.card}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={12}>
          <span className={classes.inputTypeTitle}>Add New</span>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "15%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio
                checked={product.type}
                onChange={() => setProduct({ type: true, item: false })}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span className={classes.values}>Product Type</span>
            </div>
            <div
              style={{
                width: "45%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                checked={product.item}
                onChange={() => setProduct({ type: false, item: true })}
                value="a"
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{ root: classes.radio, checked: classes.checked }}
              />
              <span className={classes.values}>Product Item</span>
            </div>
          </div>
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Name</span>
          <TextField
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            variant="outlined"
          />
          {/* <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <div style={{ display: "flex", width: "45%", flexDirection: "column" }}>
              <span className={classes.inputTypeTitle}>Name</span>
              <TextField
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                variant="outlined"
              />
            </div>

            <div style={{ display: "flex", width: "45%", flexDirection: "column"}}>
              <span className={classes.inputTypeTitle}>Total Quantity</span>
              <TextField
                value={totalQty}
                type="number"
                onChange={(e) => setTotalQty(e.target.value)}
                variant="outlined"
              />
            </div>
          </div> */}
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <span className={classes.inputTypeTitle}>Total Quantity</span>
              <TextField
                value={totalQty}
                onChange={(e) => setTotalQty(e.target.value)}
                variant="outlined"
              />
            </div>

            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <span className={classes.inputTypeTitle}>Available</span>
              <TextField
                value={available}
                type="number"
                onChange={(e) => setAvailable(e.target.value)}
                variant="outlined"
                onWheel={() => document.activeElement.blur()}
              />
            </div>
          </div>
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <span className={classes.inputTypeTitle}>Out Of Service</span>
              <TextField
                value={outOfService}
                onChange={(e) => setOutOfService(e.target.value)}
                variant="outlined"
                type="number"
                onWheel={() => document.activeElement.blur()}
              />
            </div>

            <div
              style={{ display: "flex", width: "45%", flexDirection: "column" }}
            >
              <span className={classes.inputTypeTitle}>Rate</span>
              <TextField
                value={rate}
                onChange={(e) => setRate(e.target.valued)}
                variant="outlined"
                type="number"
                onWheel={() => document.activeElement.blur()}
              />
            </div>
          </div>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={12}>
          <span className={classes.inputTypeTitle}>Itemized Product</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
               style={{
                color: "#FFC720",
              }}
              onChange={() => setItemizedProduct(!itemizedProduct)}
              checked={itemizedProduct}
            />
            <span className={classes.values} style={{ marginLeft: "20px" }}>Yes</span>
          </div>
        </GridItem>
        <GridItem className={classes.columnGridItem}  xs={12}>
        <span style={{marginBottom: "20px"}} className={classes.values}>An itemized product allows you to add serial numbers to your inventory.</span>

        <span className={classes.values}> If you don't have serial numbers, leave this unchecked.</span>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(addProductStyle)
)(React.memo(TypeCard));
