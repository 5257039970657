import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const GooglePlacesAutocomplete = (props) => {
  const {
    value,
    onChange,
    classes,
    placeholder,
    onSelect,
    width,
    margin,
    background,
    googleCallbackName
  } = props;
  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={(e) => onSelect(e, placeholder)}
      googleCallbackName={googleCallbackName}
      searchOptions={{
        componentRestrictions: { country: "us" },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <FormControl
            style={{
              width: width ? width : "80%",
              background: background ? background : "white",
              margin: margin ? margin : "",
            }}
          >
            <TextField
              value={value}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              className={classes.calculatorInput}
              variant="outlined"
              // InputProps={{
              //   className: classes.input,
              // }}
              // FormControlProps={{
              //   root: classes.input,
              // }}
              // error={errors.address}
              // helperText={
              //   errors.address ? "Please add an address" : ""
              // }
              {...getInputProps({
                placeholder: placeholder,
                className: "location-search-input",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </FormControl>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlacesAutocomplete;
