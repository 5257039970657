const reportsStyle = (theme) => ({
  jobsHeaderGridItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    marginTop: "0px",
    marginBottom: "0px",
  },
  cardHeaderDiv: {
    width: "100%",
    height: "38px",
    background: "#FFC720",
    display: "flex",
    alignItems: "center",
    paddingLeft: "30px",
  },
  cardTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
    marginLeft: "30px",
  },
  cardBodyDiv: {
    padding: "15px",
  },
  reportGridItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0 30px !important",
    // marginBottom: "25px",
  },
  reportTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "28px",
    textDecoration: "underline",
    color: "#0071C5",
    "&:hover": {
      cursor: "pointer"
    }
  },
  reportDescription: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "28px",
    color: "#323232",
    width: "90%",
  },
  viewBtn: {
    background: "#C9C9C9",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#323232",
    height: "36.87px",
    fontWeight: 700,
    textTransform: "capitalize"
  },
  editRentalRatesButton: {
    textTransform: "capitalize",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px 15px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    background: "#FFC821",
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
      color: "#323232",
    },
  },
  select: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    background: "#323232",
    '&.MuiSelect-select:focus ': {
      background: "#323232",
      color: "white",
      fontWeight: "bold",
      borderRadius: "5px",
    },
    '& .MuiInput-underline:after': {
      borderBottom: "2px solid red"
    }
  },
  whiteColor: {
    color: "white",
  },
  indexes: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
});

export default reportsStyle;
