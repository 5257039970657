import React, { useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/modalsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { denyPendingReservation } from "store/actions/reservations/reservationsActions";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";
import { Checkbox, MenuItem, Select } from "@material-ui/core";

function DenyModal(props) {
  const {
    classes,
    selectedPendingReservation,
    handleModalClose,
    handleDenyPendingReservation,
    denySelections
  } = props;
  const [denialReason, setDenialReason] = useState("");
  const [denialNotes, setDenialNotes] = useState("")
  const [editErrors, setEditErrors] = useState({ denialReason: false });
  const [sendEmail, setSendEmail] = useState(false)

  const submitDenial = () => {
    if (!denialReason) {
      setEditErrors({ denialReason: true });
    } else {
      return handleDenyPendingReservation(
        selectedPendingReservation,
        denialReason,
        denialNotes,
        sendEmail
      ).then(() => {
        handleModalClose();
      });
    }
  };

  console.log("checking selectedPendingReservation", selectedPendingReservation)

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
        <Card style={{ paddingTop: "0px" }} className={classes.card}>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "end" }}
          >
            <BlackDenyIcon
              onClick={() => handleModalClose()}
              className={classes.closeModal}
            />
          </div>
          <CardBody className={classes.modalCardBody}>
            <h5 className={classes.formTitle}>
              Denial Reason:{" "}
              {editErrors.denialReason && (
                <span style={{ color: "red" }}>Required*</span>
              )}
            </h5>
            <Select
              className={classes.filterMenu}
              style={{ marginBottom: "20px", textTransform: "capitalize", padding: "5px" }}
              value={denialReason}
              disableUnderline
              classes={{
                root: classes.selectText,
              }}
              onChange={(e) => setDenialReason(e.target.value)}
            >
              {denySelections.map((o, i) => {
                return (
                  <MenuItem
                    style={{ textTransform: "capitalize" }}
                    key={i}
                    value={o}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {o}
                  </MenuItem>
                );
              })}
            </Select>

            <TextareaAutosize
              value={denialNotes}
              aria-label="empty textarea"
              className={classes.emailInput}
              onChange={(e) => setDenialNotes(e.target.value)}
              placeholder="Provide Extra Details here..."
              style={{ maxWidth: "100%", padding: "5px" }}
            />
            <div className={classes.divCheckbox}>
              <Checkbox
                style={{ color: "#929292" }}
                onChange={() => setSendEmail(!sendEmail)}
                checked={sendEmail}
              />
              <p style={{ marginTop: "10px" }}>
                Send Denial Email to Customer?
              </p>
            </div>


            <GridContainer>
              <GridItem xs={6}>
                <Button
                  onClick={submitDenial}
                  style={{ background: "#ECB22E", color: "#323232" }}
                  className={classes.modalCardButton}
                >
                  Send Denial
                </Button>
              </GridItem>
              <GridItem xs={6}>
                <Button
                  onClick={handleModalClose}
                  style={{ color: "white", background: "#323232" }}
                  className={classes.modalCardButton}
                >
                  Cancel
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleDenyPendingReservation: (pendingRes, denialMessage, denialNotes, sendEmail) =>
    dispatch(denyPendingReservation(pendingRes, denialMessage, denialNotes, sendEmail)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(modalStyles)
)(DenyModal);
