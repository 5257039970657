import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { BsFillCircleFill } from "react-icons/bs";
import InventoryRented from "./InventoryRented";
import Jobs from "./Jobs";
import Invoices from "./Invoices";
import SiteInformation from "./SiteInformation";
import BillTo from "./BillTo";
import Button from "components/CustomButtons/Button.js";
import Reminders from "./Reminders";
import { db } from "firebase/client";
import { InputAdornment, TextField } from "@material-ui/core";

function ReservationDetails(props) {
  const {
    classes,
    reservation,
    inventoryData,
    history,
    jobsData,
    invoices,
    user,
    customer,
    refresh,
    setRefresh
  } = props;

  const disablePastDt = (current) => {
    return current.isAfter(reservation.reservation.startDate);
  };

  const [newStart, setNewStart] = useState("")
  const [newEnd, setNewEnd] = useState("")

  const disableStartDt = (current) => {
    return current.isAfter(reservation.reservation.startDate)
  }

  const updateStartDate = (date) => {
    console.log("checking newStart", date)
    if (date) {
      db.collection('reservations').doc(reservation.reservation.reservationId).update({
        "reservation.startDate": date
      })
        .then(() => {
          setRefresh(true)
        })
        .catch(function (err) {
          console.log("Error:", err)
        })
    }
    setRefresh(false)
  }

  const updateEndDate = (date) => {
    console.log("checking newStart", date)
    if (date) {
      db.collection('reservations').doc(reservation.reservation.reservationId).update({
        "reservation.endDate": date
      })
        .then(() => {
          setRefresh(true)
        })
        .catch(function (err) {
          console.log("Error:", err)
        })
    }
    setRefresh(false)
  }

  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };

  const renderQuantity = (q) => {
    if (typeof q === "object") {
      let returnStr = "";
      Object.entries(q).forEach(([key, value]) => {
        returnStr = returnStr + value + " ";
      });
      return returnStr;
    } else {
      return q;
    }
  };

  return (
    <GridContainer>
      <GridItem xs={9}>
        <Card className={classes.ReservationInfoLeftCard}>
          <GridContainer className={classes.mainGrid}>
            {user.role && user.role === "Super Admin" ?
              <>
                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>Start</span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.startDate}
                  </span>
                  <Datetime
                    style={{
                      color: "#323232 !important",
                      height: "100%",
                      width: "100%",
                    }}
                    isValidDate={disablePastDt}
                    selected={newStart}
                    dateFormat="MM/DD/YY"
                    onChange={(date, e) => {
                      setNewStart(date.format("MM/DD/YY"));
                      updateStartDate(date.format("MM/DD/YY"))
                    }}
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside
                    // onClose={updateDate}
                    renderInput={(props, openCalendar, closeCalendar) => (
                      <div onClick={openCalendar} >
                        <CalendarIcon
                          style={{
                            marginTop: "-5px",
                            marginLeft: "10px"
                          }}
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </div>
                    )}
                  />
                </GridItem>
                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>P.O. Number</span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.poNumber
                      ? reservation.reservation.poNumber
                      : "N/A"}
                  </span>
                </GridItem>

                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>End</span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.endDate}
                  </span>
                  <Datetime
                    style={{
                      color: "#323232 !important",
                      height: "100%",
                      width: "100%",
                    }}
                    isValidDate={disableStartDt}
                    selected={newEnd}
                    dateFormat="MM/DD/YY"
                    onChange={(date, e) => {
                      setNewEnd(date.format("MM/DD/YY"));
                      updateEndDate(date.format("MM/DD/YY"))
                    }}
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside
                    renderInput={(props, openCalendar, closeCalendar) => (
                      <div onClick={openCalendar} >
                        <CalendarIcon
                          style={{
                            marginTop: "-5px",
                            marginLeft: "10px"
                          }}
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </div>
                    )}
                  />
                </GridItem>
                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>Invoice Due Date</span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.paymentDueDate}
                  </span>
                </GridItem>

                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>Next Service</span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.recurring ? (
                      "Need To add next service date to reservation if recurring"
                    ) : (
                      <span style={{ color: "blue", textDecoration: "underline" }}>
                        Schedule Service
                      </span>
                    )}
                  </span>
                </GridItem>
                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>
                    Invoice Payment Status
                  </span>
                  <span className={classes.categoryValue}>
                    {parseFloat(reservation.reservation.amount) ===
                      parseFloat(reservation.reservation.amountPaid) ? (
                      <span className={classes.paidDiv}>Paid</span>
                    ) : (
                      <span className={classes.overdueDiv}>Overdue</span>
                    )}
                  </span>
                </GridItem>

                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>Last Service</span>
                  <span className={classes.categoryValue}>
                    N/A
                  </span>
                </GridItem>
                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>
                    Next Recurring Invoice
                  </span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.recurring ? (
                      "Need To add next service date to reservation if recurring"
                    ) : (
                      <span style={{ color: "blue", textDecoration: "underline" }}>
                        Create Recurring Invoice
                      </span>
                    )}
                  </span>
                </GridItem>
              </>
              :
              <>
                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>Start</span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.startDate}
                  </span>
                </GridItem>
                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>P.O. Number</span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.poNumber
                      ? reservation.reservation.poNumber
                      : "N/A"}
                  </span>
                </GridItem>

                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>Current Duration</span>
                  <span className={classes.categoryValue}>8 Days</span>
                </GridItem>
                {/* <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>Invoice Due Date</span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.paymentDueDate}
                  </span>
                </GridItem>

                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>Next Service</span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.recurring ? (
                      "Need To add next service date to reservation if recurring"
                    ) : (
                      <span style={{ color: "blue", textDecoration: "underline" }}>
                        Schedule Service
                      </span>
                    )}
                  </span>
                </GridItem> */}

                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>
                    Last Invoice Status
                  </span>
                  <span className={classes.categoryValue}>
                    {parseFloat(reservation.reservation.amount) ===
                      parseFloat(reservation.reservation.amountPaid) ? (
                      <span className={classes.paidDiv}>Paid</span>
                    ) : (
                      <span className={classes.overdueDiv}>Overdue</span>
                    )}
                  </span>
                </GridItem>

                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>End</span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.endDate}
                  </span>
                </GridItem>
                {/* <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>
                    Next Recurring Invoice
                  </span>
                  <span className={classes.categoryValue}>
                    {reservation.reservation.recurring ? (
                      "Need To add next service date to reservation if recurring"
                    ) : (
                      <span style={{ color: "blue", textDecoration: "underline" }}>
                        Create Recurring Invoice
                      </span>
                    )}
                  </span>
                </GridItem> */}
                <GridItem
                  className={classes.row + " " + classes.addedPadding}
                  xs={6}
                >
                  <span className={classes.categoryTitle}>Approved By</span>
                  <span className={classes.categoryValue}>
                    Steve Clifton
                  </span>
                </GridItem>
              </>
            }
          </GridContainer>
        </Card>
      </GridItem>
      <GridItem xs={3}>

        <Card className={classes.ReservationInfoRightCard}>
          <GridContainer className={classes.mainGrid}>
            {user.role && user.role === "Super Admin" ?
              <>
                <GridItem
                  className={classes.column + " " + classes.addedPadding}
                  xs={12}
                >
                  <span
                    style={{ textAlign: "center" }}
                    className={classes.categoryTitle}
                  >
                    Rental Rates
                  </span>
                </GridItem>

                <GridItem
                  className={classes.column + " " + classes.addedPadding}
                  xs={12}
                >
                  <span className={classes.categoryTitle}>Product</span>
                  <span className={classes.categoryValue}>{`${renderEquipment(
                    reservation.reservation.equiptment
                  )} (${renderQuantity(
                    reservation.reservation.quantity
                  )})`}</span>
                </GridItem>

                <GridItem
                  className={classes.addedPadding + " " + classes.row}
                  xs={12}
                >
                  <div style={{ display: "flex", width: "50%" }}>
                    <div className={classes.column}>
                      <span className={classes.categoryTitle}>Rate</span>
                      <span className={classes.categoryValue}>
                        {reservation.reservation.amount}
                      </span>
                    </div>
                  </div>

                  <div style={{ display: "flex", width: "50%" }}>
                    <div className={classes.column}>
                      <span className={classes.categoryTitle}>Tax</span>
                      <span className={classes.categoryValue}>
                        Need to add tax info
                      </span>
                    </div>
                  </div>
                </GridItem>

                <GridItem className={classes.editRentalRatesGridItem} xs={12}>
                  <Button className={classes.editRentalRatesButton}>
                    <EditIcon /> Edit Rental Rates
                  </Button>
                </GridItem>
              </>
              :
              <>
                <GridItem
                  className={classes.column + " " + classes.addedPadding}
                  xs={12}
                >
                  <span
                    className={classes.categoryTitle}
                  >
                    Rentals
                  </span>
                  <span
                    className={classes.categoryValue}
                    style={{
                      textDecoration: "underline",
                      color: "#0071C5",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}>
                    4 Active Rentals
                  </span>
                </GridItem>

                <GridItem
                  className={classes.column + " " + classes.addedPadding}
                  xs={12}
                >
                  <span className={classes.categoryTitle}>Communication Via</span>
                  <span
                    className={classes.categoryValue}
                  >Text</span>
                </GridItem>

                <GridItem
                  className={classes.addedPadding + " " + classes.column}
                  xs={12}
                >
                  <span className={classes.categoryTitle}>Unpaid Invoices</span>
                  <span className={classes.categoryValue}
                    style={{
                      fontWeight: 700,
                      color: "#F83030",
                      fontSize: "18px",
                      textDecoration: "underline",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    12
                  </span>
                </GridItem>

                <GridItem className={classes.editRentalRatesGridItem} xs={12}>
                  <Button className={classes.editRentalRatesButton}>
                    Pay Invoice
                  </Button>
                </GridItem>
              </>
            }
          </GridContainer>
        </Card>
      </GridItem>
      <GridItem className={classes.column + " " + classes.cardMargin} xs={12}>
        <InventoryRented
          reservation={reservation}
          inventoryData={inventoryData}
        />
      </GridItem>
      <GridItem className={classes.column} xs={12}>
        <Jobs
          jobsData={jobsData}
          reservation={reservation}
          history={history}
          classes={classes}
        />
      </GridItem>
      <GridItem className={classes.column} xs={12}>
        <Invoices
          history={history}
          invoices={invoices}
          reservation={reservation}
          classes={classes}
        />
      </GridItem>
      <GridItem className={classes.column} xs={12}>
        <Reminders
          history={history}
          invoices={invoices}
          reservation={reservation}
          classes={classes}
        />
      </GridItem>
      <GridItem className={classes.column} xs={12}>
        <SiteInformation
          history={history}
          reservation={reservation}
          classes={classes}
        />
      </GridItem>
      <GridItem className={classes.column} xs={12}>
        <BillTo history={history} reservation={reservation} classes={classes} />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    inventoryData: state.inventory.inventory,
    jobsData: state.jobs.jobs,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(ReservationDetails));
