import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

const headers = [
  "#",
  "Amount",
  "Method",
  "Payment Date",
  "Status",
  "Memo",
  "Payment ID",
];

function PaymentsCard(props) {
  const {
    classes,
    invoice,
    history,
    servicesSubTotal,
    taxTotal,
    servicesTotal,
    payment,
  } = props;
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };
  const renderTableData = () => {
    const date = payment.paymentDate.toDate();
    return (
      <tr className={classes.tableRow}>
        <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
          1
        </td>
        <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
          {payment.amountReceived}
        </td>
        <td className={classes.tableCell}>
          {payment.card.brand.toUpperCase()}
        </td>
        <td className={classes.tableCell}>
          {moment(date).format("MM/DD/YY, hh:mm A")}
        </td>
        <td className={classes.tableCell}>
          <div
            style={{ background: "#2EB67D", width: "100%" }}
            className={classes.statusDiv}
          >
            Closed
          </div>
        </td>
        <td className={classes.tableCell}>
          {payment.memo ? payment.memo : "---"}
        </td>
        <td style={{}} className={classes.tableCell}>
          <span
            onClick={() =>
              history.push({
                pathname: `/admin/payment/${payment.paymentId}`,
                state: { payment: payment, invoice: invoice },
              })
            }
            className={classes.customerIdInvoiceDetail}
          >
            {payment.paymentId}
          </span>
        </td>
      </tr>
    );
  };
  return (
    <GridContainer>
      {payment &&
        <GridItem className={classes.invoiceGridItem} xs={12}>
          <Card className={classes.invoiceCard}>
            <h1 style={{ marginBottom: "30px" }} className={classes.title}>
              Payments
            </h1>
            <GridContainer>
              <GridItem className={classes.tableGridItem} xs={12}>
                <table className={classes.inventoryTable}>
                  <tbody>
                    {renderTableHeader()}
                    {payment && renderTableData()}
                  </tbody>
                </table>
              </GridItem>
              <GridItem className={classes.end} xs={10}>
                <span className={classes.totalsTitle}>Amount Received</span>
              </GridItem>
              <GridItem xs={2}>
                <span className={classes.totalsValue}>
                  ${payment.amountReceived}
                </span>
              </GridItem>

              <GridItem className={classes.end} xs={10}>
                <span className={classes.totalsTitle}>Amount Applied</span>
              </GridItem>
              <GridItem xs={2}>
                <span className={classes.totalsValue}>
                  ${payment.amountReceived}
                </span>
              </GridItem>

              <GridItem className={classes.end} xs={10}>
                <span className={classes.totalsTitle}>Amount Remaining</span>
              </GridItem>
              <GridItem xs={2}>
                <span className={classes.totalsValue}>$0</span>
              </GridItem>

              <GridItem className={classes.end} xs={10}>
                <span className={classes.invoiceTotal}>Invoice Total</span>
              </GridItem>
              <GridItem xs={2}>
                <span className={classes.totalsValue}>
                  ${payment.amountReceived}
                </span>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
    }
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(PaymentsCard));
