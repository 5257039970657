import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchStyle from "assets/jss/material-dashboard-pro-react/views/SearchStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { CSVLink, CSVDownload } from "react-csv";

const selectOptions = ["First Name", "Last Name", "Company", "Email"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["All", "Paid", "Overdue", "Unpaid", "New", "Regular"];

function SearchFilters(props) {
  const {
    classes,
    sortBySelection,
    directionSelection,
    filterSelection,
    handleFilters,
    categorySelection,
    customerData,
    reservationsData,
  } = props;

  let csvHeaders;
  let csvData;
  if (categorySelection === "Jobs" || categorySelection === "Rentals") {
    csvHeaders = [
      { label: "Name", key: "name" },
      { label: "Comments", key: "comments" },
      { label: "Company", key: "company" },
      { label: "Customer Address", key: "billingAddress" },
      { label: "Customer ID", key: "customerId" },
      { label: "Email", key: "email" },
      { label: "Phone Number", key: "phoneNumber" },

      { label: "Cost Of Rental", key: "amount" },
      { label: "Cost Paid", key: "amountPaid" },
      { label: "Start Date", key: "startDate" },
      { label: "End Date", key: "endDate" },
      { label: "Inventory Needed", key: "equiptment" },
      { label: "Dropoff Location", key: "equiptmentLocaion" },
      { label: "Existing Customer", key: "existingCustomer" },

      { label: "Referred from", key: "heardAboutUsFrom" },
      { label: "Last Emailed", key: "lastEmailedDate" },
      { label: "Notes", key: "notes" },
      { label: "Payment Due Date", key: "paymentDueDate" },
      { label: "PO #", key: "poNumber" },
      { label: "Quantity", key: "quantity" },
      { label: "Rental ID", key: "reservationId" },

      { label: "Seven Day Rental", key: "sevenDayReservation" },
      { label: "Site Address", key: "siteAddress" },
      { label: "Rental Status", key: "status" },
      { label: "Recurring Rental", key: "recurring" },
    ];
    csvData = reservationsData;
  } else if (categorySelection === "Customers") {
    csvHeaders = [
      { label: "Name", key: "name" },
      { label: "Comments", key: "comments" },
      { label: "Company", key: "company" },
      { label: "Customer Address", key: "billingAddress" },
      { label: "Customer ID", key: "customerId" },
      { label: "Email", key: "email" },
      { label: "Phone Number", key: "phoneNumber" },
    ];
    csvData = customerData;
  } else {
    csvHeaders = [];
    csvData = [];
  }

  return (
    <div>
      <GridContainer>
        <GridItem className={classes.filtersGridItem} xs={1}>
          <h5 className={classes.subHeader}>Sort By</h5>
        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={7}>
          <Select
            className={classes.selectMenuSort}
            value={filterSelection}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => handleFilters("filter", e.target.value)}
          >
            {selectOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            className={classes.ascMenu}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            value={directionSelection}
            onChange={(e) => handleFilters("direction", e.target.value)}
          >
            {directionOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            className={classes.selectMenuSort}
            value={sortBySelection}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => handleFilters("comments", e.target.value)}
            style={{width:"30%"}}
          >
            {filterOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.filtersGridItemWithCsvDownload} xs={4}>
          <CSVLink
            filename={
              categorySelection === "Jobs" || categorySelection === "Rentals"
                ? "Rentals_Data"
                : "Customer_Data"
            }
            data={csvData.map((x) => ({
              name: x.name,
              comments: x.comments,
              company: x.company,
              billingAddress: x.billingAddress,
              customerId: x.customerId,
              email: x.email,
              phoneNumber: x.phoneNumber,
            }))}
            headers={csvHeaders}
          >
            <Button style={{ width: "100%", background:"#323232", color:"white" }} className={classes.addCustomer}>
              Export CSV
            </Button>
          </CSVLink>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(SearchStyle)
)(React.memo(SearchFilters));
