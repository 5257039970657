import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/components/customTabsStyle.js";


export default function CustomerCustomTabs(props) {
  const [value, setValue] = React.useState(props.value);
  const handleChange = (event, value) => {
    setValue(value);
  };
  const { headerColor, plainTabs, tabs, title, rtlActive, classes, tabIndex, openTab } = props;

  React.useEffect(() => {
    if (openTab === "creditMemos") {
      setValue(1);
    }
    if (openTab === "rentals") {
      setValue(2);
    }
  }, [openTab]);

  React.useEffect(() => {
    if (tabIndex) {
      setValue(tabIndex)
    }
  }, [tabIndex,value]);

  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });

  return (
    <div>
      <Tabs
        value={value}
        onChange={props.changeValue ? props.changeValue : handleChange}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.displayNone,
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((prop, key) => {
          var icon = {};
          if (prop.tabIcon) {
            icon = {
              icon: <prop.tabIcon />,
            };
          }
          return (
            <Tab
              style={{ color: "#323232 !important" }}
              classes={{
                root: classes.tabRootButton,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              key={key}
              label={prop.tabName}
              {...icon}
            />
          );
        })}
      </Tabs>
      <div>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
}

CustomerCustomTabs.defaultProps = {
  value: 0,
};

CustomerCustomTabs.propTypes = {
  // the default opened tab - index starts at 0
  value: PropTypes.number,
  // function for changing the value
  // note, if you pass this function,
  // the default function that changes the tabs will no longer work,
  // so you need to create the changing functionality as well
  changeValue: PropTypes.func,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
};
