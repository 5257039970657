import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
import {
  filterData,
  filterDataWithComments,
} from "globalHelperFunctions/globalHelperFunctions";
// core components
import { Menu } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import settingsStyle from "assets/jss/material-dashboard-pro-react/views/settingsStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { CSVLink, CSVDownload } from "react-csv";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";

import StaffTable from "./StaffTable";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

const headers = ["Name", "Phone Number", "Email", "Actions"];
const sortByOptions = ["Company/Organization", "First Name", "Last Name", "Billing Address", "Email"];
const directionOptions = ["Asc", "Desc"];
const pageArr = ["5", "All"];
const filterOptions = ["All", "Super Admin", "Finance", "Dispatcher", "Driver"];

const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Role", key: "role" },
];

function UsersTab(props) {
  const {
    classes,
    history,
    userInfo,
    company,
    companyId,
    staff,
    setStaff,
    staffData,
  } = props;
  // const [directionSelection, setDirectionSelection] = useState("Asc");
  // const [sortBy, setSortBy] = useState("Account Name");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterSelection, setFilterSelection] = useState("Company/Organization");
  const [sortBySelection, setSortBySelection] = useState("");
  const [searchString, setSearchString] = useState("");
  const itemsPerPage = 5;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("5")
  const [data, setData] = useState([]);
  const [secOpen, setSecOpen] = useState(false)
  const [menu, setMenu] = useState(null);
  const menuOpen = Boolean(menu);
  const [inSched, setInSched] = useState(false);
  const [notSched, setNotSched] = useState(false)

  useEffect(() => {
    setData(staff)
  }, [staff])

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)
  };

  useEffect(() => {
    staff.slice(index, endIndex)
  }, [index])

  const handleFilters = (type, filterValue) => {
    if (type === "filter") {
      const filtered = filterDataWithComments(
        filterValue,
        directionSelection,
        "All",
        data
      );
      setFilterSelection(filterValue);
      setData([...filtered]);
    }
    if (type === "direction") {
      const filtered = filterDataWithComments(
        filterSelection,
        filterValue,
        "All",
        data
      );
      setDirectionSelection(filterValue);
      setData([...filtered]);
    }
    if (type === "comments") {
      const filtered = filterDataWithComments(
        filterSelection,
        directionSelection,
        filterValue,
        data
      );
      setData([...filtered]);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card className={classes.card}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              // marginBottom: "20px",
            }}
          >
            <span className={classes.sectionTitle}>
              {`${staff.length} ${company.companyName} Users`}{" "}
            </span>

            <div
              style={{
                display: "flex",
                width: "20%",
                justifyContent: "space-between",
              }}
            >
              <CSVLink
                filename={"Staff_Data"}
                data={staff.map((x) => ({
                  name: x.name,
                  email: x.email,
                  phoneNumber: x.phoneNumber,
                  role: x.role,
                }))}
                headers={csvHeaders}
              >
                <Button
                  style={{
                    width: "100%",
                    background: "#323232",
                    color: "white",
                    padding: "12px 16px"
                  }}
                  className={classes.addCustomer}
                >
                  Export CSV
                </Button>
              </CSVLink>
              <Button
                onClick={() => history.push("/admin/add-user")}
                className={classes.addCustomer}
              >
                <AddIcon className={classes.editIcon} /> Invite Staff
              </Button>
            </div>
          </div>
          <GridContainer className={classes.containerMargin}>
            <GridItem
              className={classes.alignCenter}
              xs={8}
              style={{ display: "flex" }}
            >
              <p
                className={classes.sortByHeader}
                style={{ fontWeight: "normal", fontSize: "18px" }}
              >
                Sort By
              </p>
              <Select
                style={{ width: "20%", margin: "0px 10px" }}
                className={classes.selectMenuSort}
                value={filterSelection}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => handleFilters("filter", e.target.value)}
              >
                {sortByOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>

              <Select
                style={{ width: "10%", margin: "0px 10px" }}
                className={classes.ascMenu}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                value={directionSelection}
                onChange={(e) => handleFilters("direction", e.target.value)}
              >
                {directionOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>

              <Button
                style={sortBySelection ? {
                  height: "50px",
                  width: "150px",
                  marginLeft: "10px",
                  fontWeight: 500,
                  fontSize: "16px",
                  background: "#FEDA1B"
                }
                  : {
                    height: "50px",
                    width: "150px",
                    marginLeft: "10px",
                    fontWeight: 500,
                    fontSize: "16px",
                    background: "#D9D9D9"
                  }}
                className={classes.addCustomer}
                onClick={(e) => setMenu(e.currentTarget)}
              >
                Filter By
              </Button>
              <Menu
                anchorEl={menu}
                open={menuOpen}
                onClose={() => setMenu(null)}
                classes={{
                  paper: classes.menuPaper
                }}
              >
                <p className={classes.dateRange}>Permission Level</p>
                <MenuItem>
                  <Select
                    open={secOpen}
                    onOpen={() => setSecOpen(true)}
                    onClose={() => setSecOpen(false)}
                    style={{ width: "205px", height: "40px", marginLeft: "-7px" }}
                    className={classes.filterMenu}
                    value={sortBySelection}
                    disableUnderline
                    classes={{
                      root: classes.selectText,
                    }}
                    onChange={(e) => handleFilters("status", e.target.value)}
                  >
                    {filterOptions.map((o, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={o}
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                        >
                          {o}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </MenuItem>

                <div style={{ borderTop: "1px solid black" }}>
                  <MenuItem style={{ display: "contents" }}>
                    <Checkbox
                      checked={inSched}
                      style={{
                        color: "#FFC720",
                      }}
                      onChange={() => {
                        setInSched(!inSched)
                      }}
                    />
                    <span>In Schedule</span>
                  </MenuItem>
                </div>

                <div style={{ borderTop: "1px solid black" }}>
                  <MenuItem style={{ display: "contents" }}>
                    <Checkbox
                      checked={notSched}
                      style={{
                        color: "#FFC720",
                      }}
                      onChange={() => {
                        setNotSched(!notSched)
                      }}
                    />
                    <span>Not In Schedule</span>
                  </MenuItem>
                </div>
              </Menu>
            </GridItem>
            <GridItem className={classes.alignCenter} xs={4}>
              <TextField
                className={classes.userSearchBar}
                placeholder="Search Users"
                value={searchString}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchString(value);
                  if (!value) {
                    setStaff(staffData);
                  } else {
                    setStaff((prevState) => [
                      ...staffData.filter(
                        (member) =>
                          member.firstName.toLowerCase().includes(value) ||
                          member.lastName.toLowerCase().includes(value) ||
                          member.name.toLowerCase().includes(value)
                      ),
                    ]);
                  }
                }}
                variant="outlined"
                InputProps={{
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <Button className={classes.searchButton}>Search</Button>
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>
          </GridContainer>
          <div>
            <StaffTable
              staff={staff}
              company={company}
              history={history}
              classes={classes}
              userInfo={userInfo}
              companyId={companyId}
              pageDropdown={pageDropdown}
              itemsPerPage={itemsPerPage}
              pageNumber={pageNumber}
            />
            {pageDropdown == "5" ?
              <GridContainer>
                <GridItem xs={6} >
                  <p className={classes.view}>View
                    <span>
                      <Select
                        style={{ width: "13%", marginLeft: "10px", marginTop: "10px", textAlign: "center" }}
                        disableUnderline
                        MenuProps={{
                          // className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                          icon: classes.whiteColor
                        }}

                        onChange={(e) => {
                          setPageDrowdown(e.target.value)
                        }}
                        value={pageDropdown}
                      >
                        {pageArr.map((d, index) => {
                          return (
                            <MenuItem
                              key={index}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelectedMultiple
                              }}
                              value={d}
                            >
                              {d}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </span>
                  </p>
                </GridItem>
                <GridItem xs={4} style={{ marginTop: "10px" }}>
                  <h5 className={classes.indexes}>{index + 1} - {endIndex} of
                    {staff.length < itemsPerPage ? " " + staff.length :
                      " " + Math.ceil(staff.length / itemsPerPage)}
                  </h5>
                </GridItem>
                <GridItem xs={2} style={{ marginTop: "10px" }}>
                  <ReactPaginate
                    paginationSize={0}
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(staff.length / itemsPerPage)}
                    nextLabel={<ArrowRight style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                    previousLabel={<ArrowLeft style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </GridItem>
              </GridContainer>
              :
              <GridContainer>
                <GridItem xs={6}>
                  <p className={classes.view}>View
                    <span>
                      <Select
                        style={{ width: "13%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                        disableUnderline
                        MenuProps={{
                          // className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                          icon: classes.whiteColor
                        }}
                        onChange={(e) => {
                          setPageDrowdown(e.target.value)
                        }}
                        value={pageDropdown}
                      >
                        {pageArr.map((d, index) => {
                          return (
                            <MenuItem
                              key={index}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelectedMultiple
                              }}
                              value={d}
                            >
                              {d}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </span>
                  </p>
                </GridItem>
              </GridContainer>
            }
          </div>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    staffData: state.company.staff,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(settingsStyle)
)(React.memo(UsersTab));
