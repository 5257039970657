import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import styles from "assets/jss/material-dashboard-pro-react/components/customLinearProgressStyle.js";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function CustomLinearProgress(props) {
  const classes = useStyles();
  const { color, ...rest } = props;
  console.log("propss", props);

  return (
    <LinearProgress
      {...rest}
      style={{ background: "#EBEBEB", color: "red !important" }}
      classes={{
        root: classes.referralRoot,
        bar: classes.referralBar + " " + classes[color],
      }}
    />
  );
}
