import {
  firebase,
  db,
  sendNewPendingReservationNotification,
} from "firebase/client";

export const listenToSites = () => {
  return (dispatch) => {
    db.collection("sites").onSnapshot((snapshot) => {
      const sites = snapshot.docs.map((d) => d.data());
      dispatch(siteSites(sites));
    });
  };
};

export const siteSites = (sites) => {
  return {
    type: "SET_SITES",
    sites,
  };
};
