import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import scheduleStyle from "assets/jss/material-dashboard-pro-react/views/scheduleStyle.js";
import moment from "moment";
import { ReactComponent as ArrowDown } from "assets/img/arrowDown.svg";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";
import { ReactComponent as DeliveryIcon } from "assets/img/deliveryIcon.svg";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  MouseSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Sortable from "./Dnd/Sortable";
import loader from "assets/img/loader.gif";
import UnassignedColumn from "./UnassignedColumn";
import DriverColumn from "./DriverColumn";

function DaySchedule(props) {
  const {
    classes,
    drivers,
    reservationsToday,
    schedule,
    history,
    setReservationsToday,
    weeklyItems,
    setItems,
    scheduledDate,
    scheduleYear,
    scheduleMonth,
    changedTable,
    setChangedTable,
    handleDragStart,
    handleDragEnd,
    submittingChanges,
    setSubmittingChanges,
    activeId,
    setActiveId,
    data,
    setData,
    searchString,
    territoriesNames,
    setHoveredItemId,
    hoveredItemId,
  } = props;
  const widthOfColumn = drivers.length + 1;
  class MyPointerSensor extends PointerSensor {
    static activators = [
      {
        eventName: "onPointerDown",
        handler: ({ nativeEvent: event }) => {
          if (event.target.id && event.target.id === "clickableInput") {
            return false;
          }

          return true;
        },
      },
    ];
  }

  const sensors = useSensors(useSensor(MyPointerSensor));
  let splitSchedule;
  if (schedule.length > 8) {
    splitSchedule = schedule.split(" ")[1];
  } else {
    splitSchedule = schedule;
  }
  return (
    <GridContainer className={classes.scheduleGridContainer}>
      <GridItem className={classes.dndContext} xs={12}>
        <DndContext
          sensors={sensors}
          // collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          // onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          autoScroll={false}
        >
          <UnassignedColumn
            classes={classes}
            searchString={searchString}
            id="unassigned"
            splitSchedule={splitSchedule}
            driver={weeklyItems.unassigned}
            items={weeklyItems.unassigned.weeklySchedule[splitSchedule]}
            width={`calc(100% / ${widthOfColumn})`}
            setHoveredItemId={setHoveredItemId}
            hoveredItemId={hoveredItemId}
            history={history}
          />

          {Object.values(weeklyItems)
            .filter((i) => i.name !== "unassigned")
            .map((i, index) => {
              return (
                <DriverColumn
                  width={`calc(100% / ${widthOfColumn})`}
                  items={weeklyItems[i.driverId].weeklySchedule[splitSchedule]}
                  classes={classes}
                  id={i.driverId}
                  driver={i}
                  key={index}
                  splitSchedule={splitSchedule}
                  history={history}
                  searchString={searchString}
                  setHoveredItemId={setHoveredItemId}
                  hoveredItemId={hoveredItemId}
                />
              );
            })}

          {activeId && data ? (
            <DragOverlay>
              <Sortable
                history={history}
                data={data}
                classes={classes}
                id={activeId}
                reservationId={data.reservation.reservationId}
                splitSchedule={splitSchedule}
                territoryColor={data.reservation.territoryColor}
                setHoveredItemId={setHoveredItemId}
                hoveredItemId={hoveredItemId}
                dontShowTooltip
              />
            </DragOverlay>
          ) : null}
        </DndContext>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(scheduleStyle)
)(React.memo(DaySchedule));
