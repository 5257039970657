import { connect } from 'react-redux';
import SearchControl from './SearchControl';
import { startCase } from "lodash";

function getLabel(config, key) {
    return (config && config.fields[key] && config.fields[key].label) || startCase(key);
}

function mapOperator(operator) {
    return operator ? operator.shortForm : ":";
}

function flattenSearchTerms({filter=[]}, config) {
    const result = [];
    filter.forEach(element=>{ 
        if (typeof element === 'string')
            result.push(element);
        else
            Object.entries(element).forEach(([key,{operator, value}])=>result.push(`${getLabel(config,key)}${mapOperator(operator)}${value}`));
    });
    return result;
}

function addFilter(filter, accessors, actions) {
    return (dispatch, getState) => {
        const search = accessors.getSearch(getState());
        dispatch(actions.search({ ...search, filter: [...search.filter, filter]}));
    }
}

function deleteFilter(index, accessors, actions) {
    return (dispatch, getState) => {
        const search = accessors.getSearch(getState());
        const filter = [...search.filter];
        filter.splice(index, 1);
        dispatch(actions.search({...search, filter}));
    }
}

function mapStateToProps(accessors) {
    return (state, ownProps) => { 
        return {
            value: flattenSearchTerms(accessors.recordset.getSearch(state), accessors.recordset.getConfig(state))
        }
    }
}

function mapDispatchToProps(accessors, actions) { 
    return (dispatch, ownProps) => ({
        onAdd: (filter) => dispatch(addFilter(filter, accessors.recordset, actions.recordset)),
        onDelete: (filter, index) => dispatch(deleteFilter(index, accessors.recordset, actions.recordset)),
        onOpenDialog: () => dispatch(actions.queryDialog.openDialog()),
        onLiveSearch: (filter) => dispatch(actions.recordset.liveFilter(filter))
    });
}

export default (actions, accessors) => connect(mapStateToProps(accessors), mapDispatchToProps(accessors, actions))(SearchControl);
