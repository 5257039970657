/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { db, firebase, storage } from "firebase/client";

import PaymentForm from "./PaymentForm";
import "./App.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

export default function StripeContainer(props) {
  const { invoiceId, setSecret, secret, classes, invoice, signature, customer, reservation } = props;

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: secret,
    appearance,
  };

  useEffect(() => {
    async function fetchSecret() {
      try {
        const response = await axios.post("https://us-central1-humpty-dump.cloudfunctions.net/app/secret", {
          amount: props.amount,
        });
        console.log("response", response);
        if (response.data) {
          setSecret(response.data.clientSecret);
        }
      } catch (err) {
        console.log("Error", err);
      }
    }
    fetchSecret();
  }, []);

  return (
    <div style={{ width: "600px", padding: "20px" }}>
      {secret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentForm
            classes={classes}
            amount={props.amount * 100}
            invoiceId={invoiceId}
            secret={secret}
            setPaymentModal={props.setPaymentModal}
            setSnackBarContent={props.setSnackBarContent}
            invoice={invoice}
            signature={signature}
            customer={customer}
            reservation={reservation}
          />
        </Elements>
      )}
    </div>
  );
}
