const initState = {
  drivers: [],
};

export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_DRIVERS":
      return {
        ...state,
        drivers: [...action.drivers],
      };
    default:
      return state;
  }
}
