import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";
import compose from "recompose/compose";
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// material-ui icons
import Menu from "@material-ui/icons/Menu";
import Button from "components/CustomButtons/Button.js";
import humptyLogo from "assets/img/humptyDumpLogoBlack.png";
import { ReactComponent as SmallLogo } from "assets/img/smallLogo.svg";
import { ReactComponent as Dumpster } from "assets/img/dumpster.svg";
import { ReactComponent as Calculator } from "assets/img/calculator.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";

import GlobalSearch from "./GlobalSearch";
import GlobalCalculator from "./GlobalCalculator";
import GlobalAdd from "./GlobalAdd";
import InventoryTable from "views/Dashboard/components/InventoryTable";
import {
  getPrevFourteen,
  getNextFourteen,
  getDates,
} from "views/Dashboard/helpers/helpers";

const useStyles = makeStyles(styles);

const headerRoutes = [
  { name: "Dashboard", link: "/admin/dashboard" },
  { name: "Regulars", link: "/admin/regulars", role: "Super Admin" },
  { name: "Customers", link: "/admin/customers", role: "Super Admin" },
  { name: "Schedule", link: "/admin/schedule", role: "Super Admin" },
  { name: "Rentals", link: "/admin/rentals" },
  { name: "Accounting", link: "/admin/accounting" },
  { name: "Reports", link: "/admin/reports" },
];

function AdminNavbar(props) {
  const classes = useStyles();

  const {
    pendingReservationsData,
    pickupsData,
    swapsData,
    quotesData,
    color,
    history,
    setGlobalSearch,
    globalSearchOpen,
    globalCalulcatorOpen,
    setGlobalCalulcator,
    setGlobalDumpster,
    globalDumpsterOpen,
    setGlobalAdd,
    globalAddOpen,
    userInfo,
    company,
    inventoryData
  } = props;
  console.log("userInfo", userInfo);
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const [searchString, setSearchString] = useState("");
  const [filterSelection, setFilterSelection] = useState("Customers");
  const [startAddressString, setStartAddressString] = useState("");
  const [endAddressString, setEndAddressString] = useState("");
  const [timeString, setTimeString] = useState("");
  const [distanceString, setDistanceString] = useState("");
  const [clickDropdown, setClickDropdown] = useState(false);
  const [defaultHeader, setDefaultHeader] = useState([]);

  const handleSearchQuery = () => {
    setGlobalSearch(false);
    history.push({
      pathname: `/admin/search/${filterSelection.toLowerCase()}/${searchString}`,
    });
  };

  useEffect(() => {
    const defaultDays = getDates(
      new Date(),
      new Date().setDate(new Date().getDate() + 8)
    );
    setDefaultHeader(defaultDays);
  }, []);

  const handlePreviousFourteenDays = () => {
    const prevNine = getPrevFourteen(defaultHeader[2]);
    setDefaultHeader(prevNine);
  };
  const handleNextFourteenDays = (header) => {
    const nextNine = getNextFourteen(defaultHeader[2]);
    setDefaultHeader(nextNine);
  };

  return (
    <AppBar id="admin-navbar" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden className={classes.hiddenSmall} smDown implementation="css">
          <GridContainer className={classes.sixtyPixels}>
            <GridItem className={classes.gridItem} xs={1}>
              {userInfo && userInfo.role === "Super Admin" ?
                <SmallLogo />
                :
                <img src={humptyLogo} style={{height:"25px"}}/>

              }
              <span className={classes.notificationsCount}>
                {" "}
                {pendingReservationsData.length + quotesData.length}
              </span>
            </GridItem>
            <GridItem className={classes.gridItem} xs={6}>
              <GridContainer
                className={userInfo.role === "Super Admin" ? classes.routesGridContainer :
                  classes.routesGridContainerCustomer}
              >
                {headerRoutes.map((r) => {
                  if (r.role && r.role !== userInfo.role) {
                    return (
                      null
                    )
                  }
                  else {
                    return (
                      <GridItem
                        onClick={() => history.push(r.link)}
                        className={
                          window.location.pathname === r.link && userInfo.role === "Super Admin"
                            ? classes.routesGridItemUnderline
                            : window.location.pathname === r.link && userInfo.role !== "Super Admin"
                              ? classes.routesGridItemUnderlineCustomer
                              : window.location.pathname !== r.link && userInfo.role === "Super Admin"
                                ? classes.routesGridItem
                                : classes.routesGridItemCustomer
                        }
                        key={r.name}
                        xs={1}
                      >
                        <span className={classes.headerLinksText}>{r.name}</span>
                      </GridItem>
                    );
                  }
                })}
              </GridContainer>
            </GridItem>
            <GridItem xs={5}>
              <GridContainer className={classes.GridContainerClass}>
                <GridItem
                  className={classes.userInfoDropdownMenuGridItem}
                  xs={6}
                  style={clickDropdown ? { borderBottom: "5px solid #FFC821" } : null}
                >
                  <div className={classes.initialsDiv}>
                    {userInfo.firstName
                      ? userInfo.firstName[0] + " " + userInfo.lastName[0]
                      : ""}
                  </div>
                  <div className={classes.nameAndCompanyDiv} >
                    <span className={classes.name}>
                      {userInfo.name ? userInfo.name : ""}
                    </span>
                    <span className={classes.company}>
                      {" "}
                      {company && company.companyName
                        ? company.companyName
                        : ""}
                    </span>
                  </div>
                  <CustomDropdown
                    setClickDropdown={setClickDropdown}
                    clickDropdown={clickDropdown}
                    adminNavbar
                    history={history}
                    dropdownList={[
                      "Settings",
                      "Profile",
                      { divider: true },
                      "Log Out",
                      "Report a Problem",
                    ]}
                  />
                </GridItem>
                <GridItem className={classes.masterIconGridItem} xs={6} >
                  {userInfo.role === "Super Admin" ?
                    <GridContainer className={classes.iconsGridContainer} >
                      <GridItem
                        onClick={() => setGlobalDumpster(!globalDumpsterOpen)}
                        className={
                          !globalDumpsterOpen
                            ? classes.iconGridItem
                            : classes.iconGridItemTrue
                        }
                        xs={3}
                      >
                        <Dumpster />
                      </GridItem>
                      <GridItem
                        onClick={() => setGlobalCalulcator(!globalCalulcatorOpen)}
                        className={
                          !globalCalulcatorOpen
                            ? classes.iconGridItem
                            : classes.iconGridItemTrue
                        }
                        xs={3}
                      >
                        <Calculator />
                      </GridItem>
                      <GridItem
                        onClick={() => {
                          setGlobalAdd(!globalAddOpen)
                        }}
                        className={
                          !globalAddOpen
                            ? classes.iconGridItem
                            : classes.iconGridItemTrue
                        }
                        xs={3}>
                        <GlobalAdd
                          history={history}
                          dropdownList={[
                            "Create Job",
                            "Create Rental",
                            "Create Invoice",
                            "Create Credit Memo",
                            "Create Refund",
                            "Record Payment",
                            "Add Customer",
                          ]}
                        />
                      </GridItem>
                      <GridItem
                        onClick={() => {
                          setGlobalSearch(!globalSearchOpen)
                        }
                        }
                        className={
                          !globalSearchOpen
                            ? classes.searchIconGridItem
                            : classes.iconGridItemTrue
                        }
                        xs={3}
                      >
                        <SearchIcon />
                      </GridItem>
                    </GridContainer>
                    :
                    <GridContainer className={classes.iconsGridContainer} style={{ justifyContent: "flex-end" }}>
                      <GridItem
                        onClick={() => {
                          setGlobalAdd(!globalAddOpen)
                        }}
                        className={
                          !globalAddOpen
                            ? classes.iconGridItem
                            : classes.iconGridItemTrue
                        }
                        xs={3}>
                        <GlobalAdd
                          history={history}
                          dropdownList={[
                            "Create Job",
                            "Create Rental",
                            "Create Invoice",
                            "Create Credit Memo",
                            "Create Refund",
                            "Record Payment",
                            "Add Customer",
                          ]}
                        />
                      </GridItem>
                      <GridItem
                        onClick={() => {
                          setGlobalSearch(!globalSearchOpen)
                        }
                        }
                        className={
                          !globalSearchOpen
                            ? classes.searchIconGridItem
                            : classes.iconGridItemTrue
                        }
                        xs={3}
                      >
                        <SearchIcon />
                      </GridItem>
                    </GridContainer>

                  }

                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              style={{
                display: globalCalulcatorOpen ? "" : "none",
              }}
              className={classes.searchDropDownGridItem}
              xs={12}
            >
              <GlobalCalculator
                setSearchString={setSearchString}
                searchString={searchString}
                filterSelection={filterSelection}
                setFilterSelection={setFilterSelection}
                setEndAddressString={setEndAddressString}
                endAddressString={endAddressString}
                timeString={timeString}
                setTimeString={setTimeString}
                distanceString={distanceString}
                setDistanceString={setDistanceString}
                setStartAddressString={setStartAddressString}
                startAddressString={startAddressString}
              />
            </GridItem>
            <GridItem
              style={{
                display: globalSearchOpen ? "" : "none",
                height: "132px",
              }}
              className={classes.searchDropDownGridItem}
              xs={12}
            >
              <GlobalSearch
                setSearchString={setSearchString}
                searchString={searchString}
                filterSelection={filterSelection}
                setFilterSelection={setFilterSelection}
                handleSearchQuery={handleSearchQuery}
              />
            </GridItem>
            <GridItem
              style={{
                display: globalDumpsterOpen ? "" : "none",
                height: "24rem",
              }}
              className={classes.searchDropDownGridItem}
              xs={12}
            >
              <div style={{ padding: "1rem 2.5rem" }}>
                <InventoryTable
                  handleNextFourteenDays={handleNextFourteenDays}
                  handlePreviousFourteenDays={handlePreviousFourteenDays}
                  defaultHeader={defaultHeader}
                  inventoryData={inventoryData}
                  history={history}
                />
              </div>
            </GridItem>
          </GridContainer>
        </Hidden>

        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    pendingReservationsData: state.reservations.pendingReservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
    userInfo: state.auth.user,
    company: state.company.company,
    inventoryData: state.inventory.inventory,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(React.memo(AdminNavbar));
