import { connect } from 'react-redux';
import CardFields from './CardFields';
import BoundCardField from './BoundCardField';

function mapStateToProps(actions, accessors) {
    const FieldFactory = BoundCardField(actions, accessors);
    return (state, ownProps) => ({ 
        FieldFactory
    })
}

function mapDispatchToProps(actions) {
    return (dispatch, ownProps) => ({
        // nothing yet
    });
}

export default (actions, accessors) => connect(mapStateToProps(actions, accessors), mapDispatchToProps(actions))(CardFields);
