import { firebase, db } from "firebase/client";

const pickupsCollection = db.collection("pickups");

export const listenToPickups = () => {
  return (dispatch) => {
    pickupsCollection.orderBy("pickupTimestamp").onSnapshot((snapshot) => {
      const pickups = snapshot.docs.map((d) => d.data());
      dispatch(setPickups(pickups));
    });
  };
};

export const setPickups = (pickups) => {
  return {
    type: "SET_PICKUPS",
    pickups,
  };
};
