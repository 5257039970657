/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import Button from "@material-ui/core/Button";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

function ServiceCard(props) {
  const { classes, history } = props;

  return (
    <Card className={classes.invoiceCard}>
      <span
        style={{ fontSize: "30px", fontWeight: 800, marginBottom: "1.5rem" }}
      >
        Service Job
      </span>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={3}>
          <Button
            onClick={() => {
              history.push({ pathname: "/admin/create-job" });
            }}
            style={{ height: "50px" }}
            className={classes.addCustomer}
          >
            <AddIcon />
            <span style={{ marginLeft: "10px" }}>Create Service Job</span>
          </Button>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(ServiceCard));
