import {
    grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const queryFieldStyle = {
    operatorButton: {
        backgroundColor: grayColor[3],
        padding: "2px",
        minWidth: "2em",
        borderRadius: "0.5em"
    }
};

export default queryFieldStyle;