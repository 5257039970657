import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import SalesByQuarterTable from "./components/SalesByQuarterTable";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
// import BusinessOverview from "./components/BusinessOverview";
// import WhoOwes from "./components/WhoOwes";
// import SalesAndCustomers from "./components/SalesAndCustomers";

const salesByQuarterData = [
  {
    title: "VS",
    lastYear: "$1,146,416.81",
    thisYear: "$2,240,214.55",
    change: "$1,093,797.74",
    changePercentage: "95.41%",
    upOrDown: "Up"
  },
  {
    title: "1st Quarter",
    lastYear: "$165,525.93",
    thisYear: "$360,351.93",
    change: "$194,826.00",
    changePercentage: "75.41%",
    upOrDown: "Up"
  },
  {
    title: "2nd Quarter",
    lastYear: "$1,146,416.81",
    thisYear: "$2,240,214.55",
    change: "$1,093,797.74",
    changePercentage: "117.70%",
    upOrDown: "Up"
  },
  {
    title: "3rd Quarter",
    lastYear: "$363,123.80",
    thisYear: "$769,004.64",
    change: "$405,880.84",
    changePercentage: "111.77%",
    upOrDown: "Up"
  },
  {
    title: "4th Quarter",
    lastYear: "$337,493.44",
    thisYear: "$223,796.62",
    change: "$86,303.18",
    changePercentage: "25.57%",
    upOrDown: "Down"
  },
];

function SalesByQuarterReport(props) {
  const { classes, reservationsData, history } = props;
  return (
    <GridContainer>
      <GridItem className={classes.jobsHeaderGridItem} xs={12}>
        <h1 className={classes.title}>Sales By Quarter</h1>
      </GridItem>
      <GridItem xs={12}>
        <SalesByQuarterTable
          history={history}
          salesByQuarterData={salesByQuarterData}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(SalesByQuarterReport));
