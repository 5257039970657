import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import DepositDetailTable from "./components/DepositDetailTable";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
// import BusinessOverview from "./components/BusinessOverview";
// import WhoOwes from "./components/WhoOwes";
// import SalesAndCustomers from "./components/SalesAndCustomers";

const DetailData = [
  {
    date: "11/26/22",
    type: "Visa",
    customer: "Aaron Guardado",
    referenceNumber: "484579272",
    txId: "2342434134",
    total: "$100.00",
  },
  {
    date: "11/26/22",
    type: "Mastercard",
    customer: "Lisa  Guardado",
    description: "(12/6/2022 - 12/10/2022) 30 yard dumpster - 30 Yard Dumpster",
    referenceNumber: "484512372",
    txId: "22312434134",
    total: "$100.00",
  },
  {
    date: "11/26/22",
    type: "Discover",
    customer: "Sandra Jimenez",
    description: "Environmental Fee at Transfer Station & Landfill",
    referenceNumber: "484579272",
    txId: "23432344134",
    total: "$100.00",
  },
  {
    date: "11/26/22",
    type: "American Express",
    customer: "Lisa  Guardado",
    description: "(12/7/2022 - 12/14/2022) 15 yard dumpster - 15 Yard Dumpster",
    referenceNumber: "484579272",
    txId: "9489483829",
    total: "$100.00",
  },
  {
    date: "11/26/22",
    type: "Visa",
    customer: "Joe Bernard",
    description: "Environmental Fee at Transfer Station & Landfill",
    referenceNumber: "82827273",
    txId: "4564564521",
    total: "$100.00",
  },
];

function DepositDetailReport(props) {
  const { classes, reservationsData, history } = props;
  return (
    <GridContainer>
      <GridItem className={classes.jobsHeaderGridItem} xs={12}>
        <h1 className={classes.title}>Deposit Detail</h1>
      </GridItem>
      <GridItem xs={12}>
        <DepositDetailTable
          history={history}
          DetailData={DetailData}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(DepositDetailReport));
