import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import scheduleStyle from "assets/jss/material-dashboard-pro-react/views/scheduleStyle.js";
import { firebase, db, storage } from "firebase/client";
import ScheduleSubHeader from "./components/ScheduleSubHeader";
import moment from "moment";
import Button from "components/CustomButtons/Button.js";
import { getNextDay, getPreviousDay } from "../../helpers/calendarHelpers";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { mockSites } from "./mockData/mockData";
import { ReactComponent as ArrowDown } from "assets/img/arrowDown.svg";
import Fab from "@material-ui/core/Fab";
import DaySchedule from "./components/DaySchedule/DaySchedule";
import ListSchedule from "./components/ListSchedule/ListSchedule";
import WeekSchedule from "./components/WeekSchedule/WeekSchedule.jsx";
import MapSchedule from "./components/MapSchedule/MapSchedule.jsx";
import { getThisWeekDays } from "../../helpers/calendarHelpers";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import Loader from "components/Loader/Loader";
import { updateSchedule } from "store/actions/reservations/reservationsActions";
import loader from "assets/img/loader.gif";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
function Schedule(props) {
  const {
    classes,
    reservationsData,
    drivers,
    history,
    handleUpdateSchedule,
    territoriesData,
    user,
    sitesData,
  } = props;
  const [scheduleType, setScheduleType] = useState(
    user.defaultScheduleView || ""
  );
  // const [scheduleType, setScheduleType] = useState("Day");
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [filterSelection, setFilterSelection] = useState("Filter By");
  const [navBarHidden, setNavBarHidden] = useState(false);
  const [sitesIndex, setSitesIndex] = useState([0, 10]);
  const [schedule, setSchedule] = useState(
    moment(new Date()).format("ddd MM/DD/YY")
  );
  const [weeklySchedule, setWeeklySchedule] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [reservationsToday, setReservationsToday] = useState(false);
  const [initialDataSet, setInitialDataSet] = useState(false);
  const [items, setItems] = useState(false);
  const scheduledDate = new Date(schedule);
  const scheduleYear = scheduledDate.getFullYear();
  const scheduleMonth = scheduledDate.toLocaleString("default", {
    month: "short",
  });
  const [changedTable, setChangedTable] = useState(false);
  const [submittingChanges, setSubmittingChanges] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [data, setData] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [savedScheduleAlert, setSavedScheduleAlert] = useState(false);
  const [savingSchedule, setSavingSchedule] = useState(false);
  const [weeklyItems, setWeeklyItems] = useState(false);
  const [reservationsThisWeek, setReservationsThisWeek] = useState(false);
  const [changedWeeklyTable, setChangedWeeklyTable] = useState(false);
  const [movingItemError, setMovingItemError] = useState("");
  const [mapData, setMapData] = useState([]);
  const [hoveredItemId, setHoveredItemId] = useState("");

  const territoriesNames = territoriesData.map((t) => t.territoryName);

  useEffect(() => {
    const tempWeeklySchedule = getThisWeekDays(schedule);
    setWeeklySchedule(tempWeeklySchedule);
    handleFilterByWeek(tempWeeklySchedule);
  }, [schedule]);

  useEffect(() => {
    if (reservationsData.length > 0) {
      handleFilterByWeek(weeklySchedule);
      setInitialDataSet(true);
    }
  }, [reservationsData]);

  useEffect(() => {
    const mapItems = [];
    const split = schedule.split(" ")[1];
    for (var key in weeklyItems) {
      const driver = weeklyItems[key];
      if (driver.name !== "unassigned") {
        const filtered = {
          name: driver.name,
          driverId: driver.driverId,
          route: driver.weeklySchedule[split],
          color: driver.color,
        };
        mapItems.push(filtered);
      }
    }
    setMapData(mapItems);
  }, [weeklyItems]);

  const handleWeeklyReservations = async (
    reservationsThisWeek,
    splitDateArray
  ) => {
    const unassignedItems = {
      name: "unassigned",
      weeklySchedule: {},
      driverId: "unassigned",
      open: false,
      color: "#323232",
    };

    const driverItems = {};
    splitDateArray.forEach((date) => {
      unassignedItems.weeklySchedule[date] = [];
    });
    drivers.forEach((driver) => {
      driverItems[driver.driverId] = {
        name: driver.driver,
        weeklySchedule: {},
        driverId: driver.driverId,
        open: false,
        color: driver.color,
      };
      splitDateArray.forEach((date) => {
        driverItems[driver.driverId].weeklySchedule[date] = [];
      });
    });
    const final = { unassigned: { ...unassignedItems }, ...driverItems };
    reservationsThisWeek.forEach((reservation) => {

      const currentStartDate = reservation.reservation.startDate;
      const currentEndDate = reservation.reservation.endDate;
      if (reservation.reservation.territory !== "N/A") {
        const indexOfTerritoryInNameArray = territoriesNames.indexOf(
          reservation.reservation.territory
        );
        reservation.reservation.territoryColor =
          territoriesData[indexOfTerritoryInNameArray].territoryColor;
      } else {
        reservation.reservation.territoryColor = "#323232";
      }

      const currentDeliveryDriverId =
        reservation.reservation.jobs.delivery.driverId;
      const currentPickupDriverId =
        reservation.reservation.jobs.pickup.driverId;

      if (!currentDeliveryDriverId && !currentPickupDriverId) {
        if (final.unassigned.weeklySchedule[currentStartDate]) {
          var reservationCopy = reservation;
          final.unassigned.weeklySchedule[currentStartDate] = [
            ...final.unassigned.weeklySchedule[currentStartDate],
            reservationCopy,
          ];
          final.unassigned.open = true;
        }
        if (final.unassigned.weeklySchedule[currentEndDate]) {
          let reservationCopy = reservation;
          final.unassigned.weeklySchedule[currentEndDate] = [
            ...final.unassigned.weeklySchedule[currentEndDate],
            reservationCopy,
          ];
          final.unassigned.open = true;
        }
      } else {
        if (
          currentDeliveryDriverId &&
          final.unassigned.weeklySchedule[currentStartDate]
        ) {
          let reservationCopy = reservation;
          final[currentDeliveryDriverId].weeklySchedule[currentStartDate] = [
            ...final[currentDeliveryDriverId].weeklySchedule[currentStartDate],
            reservationCopy,
          ];
          final[currentDeliveryDriverId].open = true;
        }
        if (
          currentPickupDriverId &&
          final.unassigned.weeklySchedule[currentEndDate]
        ) {
          let reservationCopy = reservation;
          final[currentPickupDriverId].weeklySchedule[currentEndDate] = [
            ...final[currentPickupDriverId].weeklySchedule[currentEndDate],
            reservationCopy,
          ];
          final[currentPickupDriverId].open = true;
        }

        if (
          !currentDeliveryDriverId &&
          final.unassigned.weeklySchedule[currentStartDate]
        ) {
          let reservationCopy = reservation;
          final.unassigned.weeklySchedule[currentStartDate] = [
            ...final.unassigned.weeklySchedule[currentStartDate],
            reservationCopy,
          ];
          final.unassigned.open = true;
        }
        if (
          !currentPickupDriverId &&
          final.unassigned.weeklySchedule[currentEndDate]
        ) {
          let reservationCopy = reservation;
          final.unassigned.weeklySchedule[currentEndDate] = [
            ...final.unassigned.weeklySchedule[currentEndDate],
            reservationCopy,
          ];
          final.unassigned.open = true;
        }
      }
    });
    console.log('final', final)
    setWeeklyItems({
      ...final,
    });
    const mapItems = [];
    const split = schedule.split(" ")[1];
    for (var key in final) {
      const driver = final[key];
      if (driver.name !== "unassigned") {
        const filtered = {
          name: driver.name,
          driverId: driver.driverId,
          route: driver.weeklySchedule[split],
          color: driver.color,
        };
        mapItems.push(filtered);
      }
    }
    setMapData(mapItems);
  };

  const handleDayIncrement = () => {
    setWeeklyItems(false)
    const incrementedDate = getNextDay(schedule);
    setSchedule(incrementedDate);
    const tempWeeklySchedule = getThisWeekDays(incrementedDate);
    setWeeklySchedule(tempWeeklySchedule);
    handleFilterByWeek(tempWeeklySchedule);
  };
  const handleDayDecrement = () => {
    setWeeklyItems(false)
    const decrementedDate = getPreviousDay(schedule);
    setSchedule(decrementedDate);
    const tempWeeklySchedule = getThisWeekDays(decrementedDate);
    setWeeklySchedule(tempWeeklySchedule);
    handleFilterByWeek(tempWeeklySchedule);
  };
  const handleSaveSchedule = () => {
    setSavingSchedule(true);
    handleUpdateSchedule(
      weeklyItems,
      scheduleYear,
      scheduleMonth,
      scheduledDate,
      drivers,
      setSavedScheduleAlert,
      setShowSaveButton,
      setSavingSchedule
    );
  };

  const hideNavBar = () => {
    document.getElementById("admin-navbar").style.display = "none";
    document.getElementById("main-content").style.marginTop = "0px";
    setNavBarHidden(true);
  };

  const showNavBar = () => {
    document.getElementById("admin-navbar").style.display = "flex";
    document.getElementById("main-content").style.marginTop = "70px";
    setNavBarHidden(false);
  };

  const handleScheduleTypeChange = (v) => {
    const uid = firebase.auth().currentUser.uid;
    setScheduleType(v);
    return db
      .collection("users")
      .doc(uid)
      .update({ defaultScheduleView: v })
      .then(() => {
      });
  };

  const handleFilterByWeek = (dateArray) => {
    if (dateArray) {
      const splitDateArray = dateArray.map((d) => d.split(" ")[1]);
      const filtered = reservationsData.filter((r) => {
        const currentStartDate = r.reservation.startDate;
        const currentEndDate = r.reservation.endDate;
        if (
          splitDateArray.includes(currentStartDate) ||
          splitDateArray.includes(currentEndDate)
        ) {
          return true;
        }
      });
      setReservationsThisWeek([...filtered]);
      handleWeeklyReservations([...filtered], splitDateArray);
    }
  };

  const handleSitesChange = (increaseOrDecrease) => {
    if (increaseOrDecrease === "increase") {
      setSitesIndex([sitesIndex[0] + 9, sitesIndex[1] + 9]);
    }
    if (increaseOrDecrease === "decrease" && sitesIndex[0] !== 0) {
      setSitesIndex([sitesIndex[0] - 9, sitesIndex[1] - 9]);
    }
  };

  function handleDragEnd(event) {
    const { active, over } = event;
    const startingOrEnding = active.data.current.startOrEnd;
    const splitSchedule = schedule.split(" ")[1];
    if (!active || !over) {
      return;
    }

    let overId;
    if (weeklyItems[over.id]) {
      overId = over.id;
    } else {
      overId = weeklyItems[over.data.current.driverId].driverId;
    }

    console.log('look id', active.id, overId, active)
    if (startingOrEnding === "START") {
      active.data.current.data.reservation.jobs = {
        ...active.data.current.data.reservation.jobs,
        delivery: {
          ...active.data.current.data.reservation.jobs.delivery,
          driverId: overId === "unassigned" ? "" : overId,
          driverName:
            weeklyItems[overId].name === "unassigned"
              ? "N/A"
              : weeklyItems[overId].name,
        },
      };
    } else {
      active.data.current.data.reservation.jobs = {
        ...active.data.current.data.reservation.jobs,
        pickup: {
          ...active.data.current.data.reservation.jobs.pickup,
          driverId: overId === "unassigned" ? "" : overId,
          driverName:
            weeklyItems[overId].name === "unassigned"
              ? "N/A"
              : weeklyItems[overId].name,
        },
      };
    }
    if (active.data.current.driverId === overId) {
      let itemsToUpdate = [
        ...weeklyItems[over.data.current.driverId].weeklySchedule[
          splitSchedule
        ],
      ];
      const currentIndex = itemsToUpdate.indexOf(active.data.current.data);
      const indexToMoveTo = itemsToUpdate.indexOf(over.data.current.data);

      itemsToUpdate.splice(currentIndex, 1);
      itemsToUpdate.splice(indexToMoveTo, 0, active.data.current.data);

      itemsToUpdate = Array.from(new Set(itemsToUpdate));

      const updatedDriver = drivers.filter(
        (d) => d.driverId === over.data.current.driverId
      )[0];
      updatedDriver[scheduleYear][scheduleMonth][scheduledDate] = itemsToUpdate;

      setWeeklyItems({
        ...weeklyItems,
        [over.data.current.driverId]: {
          ...weeklyItems[over.data.current.driverId],
          weeklySchedule: {
            ...weeklyItems[over.data.current.driverId].weeklySchedule,
            [splitSchedule]: itemsToUpdate,
          },
          open: true,
        },
      });
      setChangedTable(true);

      //sort
    } else {
      //move entirely
      setShowSaveButton(true);
      // user didn't just click reservation
      if (active.id !== overId) {
        setSubmittingChanges(true);
        // putting on blank spot no collisions

        if (overId === "unassigned") {
          const currentDriverId = active.data.current.driverId;
          setWeeklyItems({
            ...weeklyItems,
            [currentDriverId]: {
              ...weeklyItems[currentDriverId],
              weeklySchedule: {
                ...weeklyItems[currentDriverId].weeklySchedule,
                [splitSchedule]: weeklyItems[currentDriverId].weeklySchedule[
                  splitSchedule
                ].filter(
                  (d) =>
                    d.reservation.reservationId !==
                    active.data.current.reservationId
                ),
              },
              open: true,
            },
            unassigned: {
              ...weeklyItems.unassigned,
              weeklySchedule: {
                ...weeklyItems.unassigned.weeklySchedule,
                [splitSchedule]: [
                  ...weeklyItems.unassigned.weeklySchedule[splitSchedule],
                  active.data.current.data,
                ],
              },
              open: true,
            },
          });
          return;
        }
        if (!over.data.current.driverId) {
          const originalDriverId = active.data.current.driverId;
          const driverId = active.data.current.driverId;
          const itemToMove = weeklyItems[driverId].weeklySchedule[
            splitSchedule
          ].filter(
            (d) =>
              d.reservation.reservationId === active.data.current.reservationId
          )[0];

          const updatedDriverSchedule = [
            ...weeklyItems[overId].weeklySchedule[splitSchedule],
            itemToMove,
          ];
          const updatedDriver = drivers.filter((d) => d.driverId === overId)[0];
          updatedDriver[scheduleYear][scheduleMonth][
            scheduledDate
          ] = updatedDriverSchedule;

          if (!originalDriverId) {
            setWeeklyItems({
              ...weeklyItems,
              [driverId]: {
                ...weeklyItems[driverId],
                weeklySchedule: {
                  ...weeklyItems[driverId].weeklySchedule,
                  [splitSchedule]: weeklyItems[driverId].weeklySchedule[
                    splitSchedule
                  ].filter(
                    (d) =>
                      d.reservation.reservationId !==
                      active.data.current.reservationId
                  ),
                },
                open: true,
              },
              [overId]: {
                ...weeklyItems[overId],
                weeklySchedule: {
                  ...weeklyItems[overId].weeklySchedule,
                  [splitSchedule]: [
                    ...weeklyItems[overId].weeklySchedule[splitSchedule],
                    itemToMove,
                  ],
                },
                open: true,
              },
            });
            setActiveId(null);
            setChangedTable(true);
          } else {
            setWeeklyItems({
              ...weeklyItems,
              [driverId]: {
                ...weeklyItems[driverId],
                weeklySchedule: {
                  ...weeklyItems[driverId].weeklySchedule,
                  [splitSchedule]: weeklyItems[driverId].weeklySchedule[
                    splitSchedule
                  ].filter(
                    (d) =>
                      d.reservation.reservationId !==
                      active.data.current.reservationId
                  ),
                },
                open: true,
              },
              [overId]: {
                ...weeklyItems[overId],
                weeklySchedule: {
                  ...weeklyItems[overId].weeklySchedule,
                  [splitSchedule]: [
                    ...weeklyItems[overId].weeklySchedule[splitSchedule],
                    itemToMove,
                  ],
                },
                open: true,
              },
            });
            setActiveId(null);
            setChangedTable(true);
          }
        } else {
          // found a collision need to change indexes
          // just sorting within same list

          if (active.data.current.driverId === over.data.current.driverId) {
            let itemsToUpdate = [
              ...weeklyItems[over.data.current.driverId].weeklySchedule[
                splitSchedule
              ],
            ];
            const currentIndex = itemsToUpdate.indexOf(
              active.data.current.data
            );
            const indexToMoveTo = itemsToUpdate.indexOf(over.data.current.data);

            itemsToUpdate.splice(currentIndex, 1);
            itemsToUpdate.splice(indexToMoveTo, 0, active.data.current.data);

            itemsToUpdate = Array.from(new Set(itemsToUpdate));

            const updatedDriver = drivers.filter(
              (d) => d.driverId === over.data.current.driverId
            )[0];
            updatedDriver[scheduleYear][scheduleMonth][
              scheduledDate
            ] = itemsToUpdate;

            setWeeklyItems({
              ...weeklyItems,
              [over.data.current.driverId]: {
                ...weeklyItems[over.data.current.driverId],
                weeklySchedule: {
                  ...weeklyItems[over.data.current.driverId].weeklySchedule,
                  [splitSchedule]: itemsToUpdate,
                },
                open: true,
              },
            });
            setChangedTable(true);
          } else {
            // sorting in a new list
            const itemsToUpdate = [
              ...weeklyItems[over.data.current.driverId].weeklySchedule[
                splitSchedule
              ],
            ];
            const indexToMoveTo = itemsToUpdate.indexOf(over.data.current.data);
            itemsToUpdate.splice(indexToMoveTo, 0, active.data.current.data);
            if (over.data.current.driverId === "unassigned") {
              setWeeklyItems({
                ...weeklyItems,
                [active.data.current.driverId]: {
                  ...weeklyItems[active.data.current.driverId],
                  weeklySchedule: {
                    ...weeklyItems[active.data.current.driverId].weeklySchedule,
                    [splitSchedule]: weeklyItems[
                      active.data.current.driverId
                    ].weeklySchedule[splitSchedule].filter(
                      (i) =>
                        i.reservation.reservationId !==
                        active.data.current.reservationId
                    ),
                  },
                  open: true,
                },
                [over.data.current.driverId]: {
                  ...weeklyItems[over.data.current.driverId],
                  weeklySchedule: {
                    ...weeklyItems[over.data.current.driverId].weeklySchedule,
                    [splitSchedule]: itemsToUpdate,
                  },
                  open: true,
                },
              });
              setChangedTable(true);
            } else {
              const updatedDriver = drivers.filter(
                (d) => d.driverId === over.data.current.driverId
              )[0];
              updatedDriver[scheduleYear][scheduleMonth][
                scheduledDate
              ] = itemsToUpdate;
              setWeeklyItems({
                ...weeklyItems,
                [active.data.current.driverId]: {
                  ...weeklyItems[active.data.current.driverId],
                  weeklySchedule: {
                    ...weeklyItems[active.data.current.driverId].weeklySchedule,
                    [splitSchedule]: weeklyItems[
                      active.data.current.driverId
                    ].weeklySchedule[splitSchedule].filter(
                      (i) =>
                        i.reservation.reservationId !==
                        active.data.current.reservationId
                    ),
                  },
                  open: true,
                },
                [over.data.current.driverId]: {
                  ...weeklyItems[over.data.current.driverId],
                  weeklySchedule: {
                    ...weeklyItems[over.data.current.driverId].weeklySchedule,
                    [splitSchedule]: itemsToUpdate,
                  },
                  open: true,
                },
              });
              setChangedTable(true);
            }
          }
        }
      }
    }
  }

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;
    console.log('look ?', active, id)
    setData(active.data.current.data);
    setActiveId(id);
  }

  if (!weeklyItems)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );

  return (
    <GridContainer>
      <GridItem className={classes.scheduleHeaderGridItem} xs={11}>
        <h1 className={classes.title}>Schedule</h1>
      </GridItem>
      <GridItem className={classes.hideNavGridItem} xs={1}>
        {navBarHidden ? (
          <div className={classes.hideNavDiv} onClick={showNavBar}>
            <ArrowDown onClick={showNavBar} />
          </div>
        ) : (
          <div className={classes.hideNavDiv} onClick={hideNavBar}>
            <ArrowDown onClick={hideNavBar} />
          </div>
        )}
      </GridItem>
      <GridItem xs={12}>
        <ScheduleSubHeader
          setWeeklySchedule={setWeeklySchedule}
          handleFilterByWeek={handleFilterByWeek}
          setSearchString={setSearchString}
          searchString={searchString}
          setScheduleType={setScheduleType}
          filterSelection={filterSelection}
          setFilterSelection={setFilterSelection}
          calendarOpen={calendarOpen}
          setCalendarOpen={setCalendarOpen}
          handleDayIncrement={handleDayIncrement}
          handleDayDecrement={handleDayDecrement}
          schedule={schedule}
          setSchedule={setSchedule}
          scheduleType={scheduleType}
          handleScheduleTypeChange={handleScheduleTypeChange}
          sitesIndex={sitesIndex}
          setSitesIndex={setSitesIndex}
          handleSitesChange={handleSitesChange}
          sites={sitesData}
          weeklySchedule={weeklySchedule}
          history={history}
          setShowSaveButton={setShowSaveButton}
        />
      </GridItem>
      {scheduleType === "Day" && weeklyItems ? (
        <DaySchedule
          classes={classes}
          drivers={drivers}
          reservationsToday={reservationsToday}
          schedule={schedule}
          history={history}
          weeklyItems={weeklyItems}
          searchString={searchString}
          setWeeklyItems={setWeeklyItems}
          scheduledDate={scheduledDate}
          scheduleYear={scheduleYear}
          scheduleMonth={scheduleMonth}
          changedTable={changedTable}
          setChangedTable={setChangedTable}
          handleDragStart={handleDragStart}
          handleDragEnd={handleDragEnd}
          submittingChanges={submittingChanges}
          setSubmittingChanges={setSubmittingChanges}
          activeId={activeId}
          setActiveId={setActiveId}
          data={data}
          setHoveredItemId={setHoveredItemId}
          hoveredItemId={hoveredItemId}
          setData={setData}
          territoriesNames={territoriesNames}
          territoriesData={territoriesData}
        />
      ) : scheduleType === "List" && weeklyItems ? (
        <ListSchedule
          classes={classes}
          drivers={drivers}
          reservationsToday={reservationsToday}
          schedule={schedule}
          history={history}
          weeklyItems={weeklyItems}
          setWeeklyItems={setWeeklyItems}
          scheduledDate={scheduledDate}
          scheduleYear={scheduleYear}
          scheduleMonth={scheduleMonth}
          changedTable={changedTable}
          setChangedTable={setChangedTable}
          handleDragStart={handleDragStart}
          handleDragEnd={handleDragEnd}
          data={data}
          setData={setData}
          activeId={activeId}
          setActiveId={setActiveId}
        />
      ) : scheduleType === "Week" && weeklyItems ? (
        <WeekSchedule
          classes={classes}
          drivers={drivers}
          weeklyItems={weeklyItems}
          setWeeklyItems={setWeeklyItems}
          scheduledDate={scheduledDate}
          scheduleYear={scheduleYear}
          scheduleMonth={scheduleMonth}
          searchString={searchString}
          setMovingItemError={setMovingItemError}
          weeklySchedule={weeklySchedule ? weeklySchedule : []}
          data={data}
          setData={setData}
          activeId={activeId}
          setActiveId={setActiveId}
          handleDragStart={handleDragStart}
          setChangedWeeklyTable={setChangedWeeklyTable}
          setShowSaveButton={setShowSaveButton}
        />
      ) : (
        <MapSchedule
          classes={classes}
          drivers={drivers}
          weeklyItems={weeklyItems}
          setWeeklyItems={setWeeklyItems}
          scheduledDate={scheduledDate}
          scheduleYear={scheduleYear}
          scheduleMonth={scheduleMonth}
          searchString={searchString}
          setMovingItemError={setMovingItemError}
          weeklySchedule={weeklySchedule ? weeklySchedule : []}
          data={data}
          setData={setData}
          activeId={activeId}
          setActiveId={setActiveId}
          handleDragStart={handleDragStart}
          setChangedWeeklyTable={setChangedWeeklyTable}
          setShowSaveButton={setShowSaveButton}
          mapData={mapData}
        />
      )}
      {showSaveButton && (
        <Fab
          onClick={handleSaveSchedule}
          className={classes.anchoredFabLoggedIn}
          aria-label="add"
        >
          {savingSchedule ? (
            <img style={{ height: "50px" }} src={loader} />
          ) : (
            <>
              <span style={{ marginRight: "15px" }}>Save Schedule</span>
              <CheckIcon />
            </>
          )}
        </Fab>
      )}

      <Snackbar
        open={savedScheduleAlert}
        onClose={() => setSavedScheduleAlert(false)}
      >
        <Alert
          onClose={() => setSavedScheduleAlert(false)}
          severity={"success"}
        >
          Scheduled Saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={movingItemError}
        onClose={() => setMovingItemError(false)}
      >
        <Alert onClose={() => setMovingItemError(false)} severity={"error"}>
          {movingItemError}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
    drivers: state.drivers.drivers,
    territoriesData: state.territories.territories,
    user: state.auth.user,
    sitesData: state.sites.sites,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleUpdateSchedule: (
    scheduleObject,
    scheduleYear,
    scheduleMonth,
    scheduledDate,
    drivers,
    setSavedScheduleAlert,
    setShowSaveButton,
    setSavingSchedule
  ) =>
    dispatch(
      updateSchedule(
        scheduleObject,
        scheduleYear,
        scheduleMonth,
        scheduledDate,
        drivers,
        setSavedScheduleAlert,
        setShowSaveButton,
        setSavingSchedule
      )
    ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(scheduleStyle)
)(Schedule);
