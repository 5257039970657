import {
  whiteColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const customerInfoStyle = (theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
  },
  publicInvoiceGridContainer: {
    width: "95%",
    margin: "0px auto",
    zIndex: 9999
  },
  justifyContent: {
    justifyContent: "center",
  },
  modalCardButton: {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Archivo",
    color: "#323232",
    marginTop: "0px",
  },
  marginBottomGridItem: {
    marginBottom: '30px'
  },
  updateInvoiceStatusModal: {
    width: "600px"
  },
  addedPadding: {
    padding: "10px 20px 0px 20px !important",
  },
  addedPaddingCompany: {
    padding: "0px 20px 0px 20px !important",
  },
  companyBorderBottom: {
    paddingBottom: "15px !important",
    borderBottom: "1px solid #929292",
  },
  actionsDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
  },
  modalRoot: {
    zIndex: 9999,
    width: "100%",
  },
  accountingTitle: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    // marginBottom: "40px",
    // marginTop: "0px",
  },
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px",
  },
  commentsDiv: {
    display: "flex",
    marginLeft: "30px",
    width: "50%",
  },
  statusDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    margin: "0px 10px",
    justifyContent: "center",
    alignItems: "center",
  },
  commentDiv: {
    // color: "white !important"
    display: "flex",
    alignItems: "center",
    margin: "0px 25px",
    fontWeight: "700"
  },
  bulletPoint: {
    marginRight: "10px",
    fontWeight: "700"
  },
  slackButton: {
    whiteSpace: "noWrap",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "100%",
    padding: "10px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    border: "1px solid #323232",
    background: "#FFFFFF",
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
    height: "50px"
  },
  editCustomer: {
    whiteSpace: "noWrap",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "100%",
    padding: "10px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    color: "#323232",
    background: "#FFC720",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  customerInfoLeftCard: {
    background: "#FFFFFF",
    border: "0.5px solid #8E8E8E",
    padding: "10px 20px 0px 20px !important",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  customerInfoRightCard: {
    background: "#F3F3F3",
    border: "0.5px solid #8E8E8E",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "15px",
  },
  categoryTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
    marginBottom: "20px",
    whiteSpace: "noWrap"
  },
  categoryTitleCompany: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
    marginBottom: "20px",
    whiteSpace: "noWrap"
  },
  category: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
  },
  categoryValue: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
  },
  companyBlueDiv: {
    borderRadius: "7px",
    background: "#0071C5",
    color: "white",
    fontSize: "12px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    padding: "10px",
    display: "flex",
    justifyContent: "flex-end",
    whiteSpace: "noWrap"
  },

  categoryValueUnderline: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#0071C5",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  categoryValueUnderlineCompany: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#0071C5",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
    display: "flex"
  },
  mainGrid: {
    height: "100%",
  },
  gridItemWithMargin: {
    marginTop: "50px",
  },
  notesTabTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
    marginBottom: "10px",
  },
  notesTabTitle2: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "18px",
    // textTransform: "capitalize",
    color: "#323232",
    marginBottom: "10px",
  },
  tabCard: {
    padding: "30px 30px 0px 30px",
    marginTop: "0px",
    borderRadius: "0px 0px 6px 6px"
  },
  tabCard2: {
    padding: "30px",
    marginTop: "0px",
    borderRadius: "0px 0px 6px 6px"
  },
  tabCard3: {
    padding: "0px 30px",
    marginTop: "0px",
    borderRadius: "0px 0px 6px 6px"
  },
  inventoryTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    marginBottom: "10px"
  },
  notesTableHeaders: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    textTransform: "uppercase",
    textAlign: "left",
    paddingLeft: "20px",
    background: "#D9D9D9",
    whiteSpace: "noWrap"
  },
  notesTableHeaders2: {
    color: "#323232",
    border: "0.5px solid #8E8E8E",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    textTransform: "uppercase",
    paddingLeft: "10px",
    background: "#D9D9D9",
  },
  notesTableRow: {
    height: "32px",
    border: "1px solid #8E8E8E",
  },
  notesTableFs: {
    border: "0.5px solid #8E8E8E",
    background: "#F3F3F3",
    color: "#323232",
    fontFamily: "Archivo",
    fontSize: "17px",
  },
  tableCell: {
    width: "75%",
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "19px",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  notesTableCell: {
    textAlign: "left",
    border: "0.5px solid #8E8E8E",
    padding: "10px 20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  notesTableCell2: {
    textAlign: "left",
    border: "0.5px solid #8E8E8E",
    padding: "7px 10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  notesTableCell3: {
    textAlign: "left",
    border: "0.5px solid #8E8E8E",
    padding: "7px 10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  notesTableCellInvoiceId: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    fontFamily: "Archivo",
    color: "blue",
    textDecoration: "underline",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "19px",
    background: "#FFFFFF",
    "&:hover": {
      cursor: "pointer",
    },
  },
  paragraph: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#323232",
    textAlign: "center",
    padding: "6px 0px"

  },
  addCardButton: {
    width: "100%",
    background: "#FFC720",
    display: "flex",
    justifyContent: "space-evenly",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    margin: "5px",
    alignItems: "center",
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
    textTransform: "capitalize"
  },
  createInvoiceButton: {
    textTransform: "capitalize",
    width: "40%",
    background: "#323232",
    display: "flex",
    justifyContent: "space-evenly",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    margin: "5px",
    alignItems: "center",
    color: "white",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  filterMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    height: "50px",
    marginRight: "15px",
    color: "#323232",
    padding: "10px",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
  searchBar: {
    width: "100%",
    background: "white",
    height: "40px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",

  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height: "40px"
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "13px 14px !important",
    height: "40px",
    color: "#323232",
    width: "120px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    "&:hover": {
      background: "#FFC720",
    },
  },
  ascendingMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "10%",
    height: "50px",
    marginRight: "15px",
    padding: "10px",
    fontWeight: "500"
  },
  filtersGridItem: {
    display: "flex",
    alignItems: "center",
  },
  addCustomer: {
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#323232",
    padding: "12px",
    // width: "29%",
    display: "flex",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "17px",
    justifyContent: "space-evenly",
    "&:hover": {
      background: "#FFC720",
      color: "#323232",
    },
  },
  createRental: {
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#323232",
    padding: "12px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "17px",
    height: "48px",
    "&:hover": {
      background: "#FFC720",
    },
  },
  filtersGridItemWithCsvDownload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionBtn: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "15px",
    height: "50px",
    fontSize: "20px"
  },
  jobsGridItems: {
    display: "flex",
    alignItems: "center",
    height: "100px",
  },
  datetime: {
    background: "white",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    width: "100%",
    position: "relative",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "text",
    display: "inline-flex",
    fontSize: "1rem",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: "Archivo",
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    border: "1px solid lightgray",
  },
  calendarIcon: {
    marginRight: "10px",
    height: "18px"
  },
  justifyEnd: {
    display: "flex",
    justifyContent: "end",
  },
  select: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    background: "#323232",
    "&.MuiSelect-select:focus ": {
      background: "#323232",
      color: "white",
      fontWeight: "bold",
      borderRadius: "5px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid red",
    },
  },
  whiteColor: {
    color: "white",
  },
  closeModal: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px",
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important",
  },
  tabsRoot: {
    minHeight: "unset !important",
    background: "#D9D9D9",
    borderWidth: "0.5px",
    borderStyle: "solid",
    borderColor: "#929292",
    borderRadius: "2px 2px 0px 0px",
    "& $tabRootButton": {
      fontSize: "0.875rem",
    },
  },
  tabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    padding: "15px !important",
    maxHeight: "unset !important",
    borderRadius: "3px",
    margin: "0px",
  },
  displayNone: {
    display: "none !important",
    backgroundColor: "transparent !important"
  },
  tabLabelContainer: {
    padding: "0px",
  },
  tabLabel: {
    fontWeight: "500",
    fontSize: "12px",
  },
  tabSelected: {
    backgroundColor: "#FEDA1B",
    transition: "0.2s background-color 0.1s",
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    textTransform: "none",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    fontFamily: "Archivo !important",
    fontStyle: "normal !important",
    fontWeight: 500,
    fontSize: "17px !important",
    lineHeight: "18px !important",
    color: "#323232 !important",
    "& > svg,& > .fab,& > .fas,& > .far,& > .fal,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0 !important",
    },
  },
  indexes: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
  view: {
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },
  selectText: {
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    fontWeight: 400,
    fontFamily: "Archivo",
  },
  selectMenuSort: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    textAlign: "center",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "19px",
    margin: "0px 15px 0px 0px",
  },
  ascMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "20%",
    height: "50px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "19px",
  },
  filterMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    margin: "0px 15px 0px 10px",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "19px",
    textAlign: "center"
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "26px",
    color: "#323232",
    margin: "35px 0px",
  },
  editRentalRatesButton: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    background: "#FFC821",
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
      color: "#323232",
    },
  },
  nameAndCompanyText: {
    padding: "0px 5px",
    color: "#0071C5",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectAll: {
    fontSize: "19px",
    fontWeight: 500,
    fontFamily: "Archivo",
    marginRight: "10px"
  },
  dateRange: {
    fontFamily: "Archivo",
    fontWeight: 400,
    lineHeight: "18.5px",
    fontSize: "17px",
    margin: "5px 10px"
  },
});

export default customerInfoStyle;
