/*eslint-disable*/
import {
  CardElement,
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { NavLink, withRouter, useParams } from "react-router-dom";
import { db, firebase, storage } from "firebase/client";
import compose from "recompose/compose";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import axios from "axios";
import React, { useState, useEffect } from "react";
import "./App.css";
import { chain } from "store/actions/util";
import { push } from "connected-react-router";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#323232",
      fontFamily: "Archivo",
      fontStyle: "normal",
      padding: "0px",
      fontSize: "17px",
      lineHeight: "18px",
      color: "#323232",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#323232" },
      "::placeholder": { color: "#323232" },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
    empty: {
      fontFamily: "Archivo",
      fontStyle: "normal",
      fontSize: "17px",
      lineHeight: "18px",
      color: "#323232",
    },
    complete: {
      fontFamily: "Archivo",
      fontStyle: "normal",
      fontSize: "17px",
      lineHeight: "18px",
      color: "#323232",
    },
  },
};

const PaymentForm = (props) => {
  const {
    user,
    history,
    amount,
    setPaymentModal,
    setSnackBarContent,
    invoice,
    invoiceId,
    signature,
    customer,
    reservation,
  } = props;
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [
    unsuccessfulPaymentSnackbar,
    setUnsuccessfulPaymentSnackbar,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmitPay = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: customer.email,
        name: customer.name,
      },
    });

    if (!error) {
      try {
        console.log("hererereerer", paymentMethod);
        const { id } = paymentMethod;

        //single payment
        const response = await axios.post(
          "https://us-central1-humpty-dump.cloudfunctions.net/app/payment-new-customer",
          {
            amount,
            id,
            invoice,
            email: customer.email,
            name: customer.name,
            address: customer.billingAddress,
            reservation: reservation
          }
        );

        // const response = await axios.post(
        //   "http://localhost:4000/payment-new-customer",
        //   {
        //     amount,
        //     id,
        //     invoice,
        //     email: customer.email,
        //     name: customer.name,
        //     address: customer.billingAddress,
        //     reservation: reservation,
        //   }
        // );

        if (response.data) {
          console.log("Successful payment", response.data);
          // may need to update services array in invoice... not sure yet

          const signatureStorageRef = storage.ref(
            `invoiceSignatures/${invoice.customerId}/${invoiceId}`
          );
          await signatureStorageRef.putString(signature, "data_url");
          const signatureUrl = await firebase
            .storage()
            .ref(`invoiceSignatures/${invoice.customerId}/${invoiceId}`)
            .getDownloadURL();

          await db
            .collection("invoices")
            .doc(invoiceId)
            .update({
              ...invoice,
              stripeCustomerId: response.data.stripeCustomerId,
              signatureUrl,
              // reservation: {
              //   ...invoice.reservation,
              //   amountPaid: amount / 100,
              invoiceDetails: {
                paymentStatus: "Paid",
                paymentMethod: "Card",
                paymentId: id,
              },
              // },
            });
          await db
            .collection("reservations")
            .doc(invoice.reservationId)
            .update({
              // ...invoice,
              // stripeCustomerId: response.data.stripeCustomerId,
              reservation: {
                ...reservation.reservation,
                amountPaid: amount / 100,
                paymentId: id,
                // invoiceDetails: {
                //   paymentStatus: "Paid",
                //   paymentMethod: "Card",
                //   paymentId: id,
                // },
              },
            });
            let previousCustomerInvoices
            if (customer.invoices) {
              previousCustomerInvoices = customer.invoices
            } else {
              previousCustomerInvoices = []
            }

            let previousCustomerPayments
            if (customer.payments) {
              previousCustomerPayments = customer.payments
            } else {
              previousCustomerPayments = []
            }
          await db
            .collection("customers")
            .doc(invoice.customerId)
            .update({
              stripeCustomerId: response.data.stripeCustomerId,
              invoices: [...previousCustomerInvoices, invoiceId ],
              payments: [...previousCustomerPayments, id]
            });

          const justCustomerInfo = { ...invoice };
          delete justCustomerInfo.reservation;
          delete justCustomerInfo.services;

          await db
            .collection("payments")
            .doc(id)
            .set({
              card: { ...paymentMethod.card },
              paymentDate: new Date(paymentMethod.created * 1000),
              paymentId: id,
              customerId: invoice.customerId,
              amountReceived: response.data.paymentInfo.amount / 100,
              memo: "",
              invoiceId: invoiceId,
            });

          setPaymentModal(false);
          setSnackBarContent("You Successfully Paid Your Invoice");
          setLoading(false);
          // turn this into a cloud function
          // return db.collection('users').doc(user.uid).
        }
      } catch (error) {
        console.log("Error", error);
        setSnackBarContent(
          "There was a problem with your payment: " + error.message
        );
      }
    } else {
      setSnackBarContent(
        "There was a problem with your payment: " + error.message
      );
      console.log(error.message);
    }
  };

  const listPayments = async (e) => {
    e.preventDefault();
    const methods = await axios.post(
      "https://us-central1-humpty-dump.cloudfunctions.net/app/list-payments",
      {
        customerId: "cus_NC2zqoKhquzN8J",
      }
    );
    console.log("paymentMethods", methods);
    // const paymentMethods = await stripe.paymentMethods.list({
    //   customer: 'cus_NC2zqoKhquzN8J',
    //   type: 'card',
    // });
    // console.log('paymentMethods', paymentMethods)
  };

  return (
    <>
      {!success ? (
        <form>
          {/* <button onClick={listPayments}>LIST</button> */}
          <fieldset className="FormGroup">
            <div className="FormRow">
              {/* <PaymentElement /> */}
              <CardElement options={CARD_OPTIONS} />
            </div>
          </fieldset>

          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <button className="closeButton">Close</button>
              <button className="payButton">
                Pay
                <img
                  style={{ width: "10%", marginLeft: "20px" }}
                  src={loader}
                  alt="..."
                />
              </button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <button
                onClick={() => setPaymentModal(false)}
                className="closeButton"
              >
                Close
              </button>
              <button onClick={handleSubmitPay} className="payButton">
                Pay <CheckIcon style={{ marginLeft: "10px" }} />
              </button>
            </div>
          )}
        </form>
      ) : (
        <div>
          <h2>Payment Successful</h2>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(PaymentForm)
);
