import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "views/Dashboard/components/pagination.css";
// core components
import { MenuItem, Select } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconWhite.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIconYellowBg.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
const headers = [
  "Rental Details",
  "Scheduled",
  "End Date",
  "Duration",
  "Site Address",
  "Inventory",
  "Recurring",
  "Status",
  "Actions",
];


const pageArr = ["3", "All"];

function RentalsTable(props) {
  const { classes, reservations, history, justCustomerInfo } = props;
  console.log("reservations", reservations);
  const itemsPerPage = 3;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("3");

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  useEffect(() => {
    reservations.slice(index, endIndex);
  }, [index]);
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };

  const renderTableData = () => {
    if (pageDropdown == "3") {
      return reservations
        .slice(
          pageNumber * itemsPerPage,
          pageNumber * itemsPerPage + itemsPerPage
        )
        .map((d, index) => {
          console.log('dddd', d)
          return (
            <tr className={classes.tableRow} key={d.name}>
              <td
                style={{ background: "#FFFFFF" }}
                className={classes.tableCell}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <span
                      onClick={() =>
                        history.push({
                          pathname: `/admin/rentals/${d.reservation.reservationId}`,
                          state: {
                            reservation: d,
                          },
                        })
                      }
                      className={classes.nameAndCompanyText}
                    >
                      {" "}
                      {d.reservation.reservationId}
                    </span>{" "}
                    {/* <span
                      onClick={() =>
                        history.push({
                          pathname: `/admin/customers/${d.customerId}`,
                          state: { customer: d },
                        })
                      }
                      className={classes.nameAndCompanyText}
                    >
                      {d.name}
                    </span> */}
                  </div>
                  {/* <div style={{ fontSize: "16px" }}>
                    Driver: <span>{d.driver}</span>{" "}
                  </div> */}
                </div>
              </td>
              <td
                className={classes.tableCell}
                style={{ background: "#FFFFFF" }}
              >
                {d.reservation.startDate}
              </td>
              <td className={classes.tableCell}>{d.reservation.endDate}</td>
              <td className={classes.tableCell}>{d.reservation.endDate ? d.reservation.endDate : "N/A"}</td>
              <td className={classes.tableCell}>{d.reservation.siteAddress}</td>
              <td className={classes.tableCell}>{renderEquipment(d.reservation.equiptment)}</td>
              <td className={classes.tableCell}>
                {d.reservation.recurring ? "Yes" : "No"}
              </td>
              <td className={classes.tableCell}>
                <div
                  style={
                    d.reservation.status === "Scheduled"
                      ? { background: "#E01E5A" }
                      : d.reservation.status === "Completed"
                        ? { background: "#2EB67D" }
                        : d.reservation.status === "In Progress"
                          ? { background: "#E0AC12", color: "#323232" }
                          : d.reservation.status === "Approved"
                            ? { background: "#E0AC12", color: "#323232" }
                            : { background: "#323232" }
                  }
                  className={classes.commentDiv}
                  style={{ background: "#FFC821", fontWeight: 700 }}
                >
                  {d.reservation.status}
                </div>
              </td>
              <td className={classes.tableCell}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      style={{ background: "#FFC720" }}
                      className={classes.actionBtn}
                    >
                      <EyeIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#ECB22E" }}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#ECB22E" }}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          );
        });
    } else {
      return reservations.map((d, index) => {
        console.log('dddd', d)
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/rentals/${d.reservation.reservationId}`,
                        state: {
                          reservation: d,
                        },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {" "}
                    {d.reservation.reservationId}
                  </span>{" "}
                  |
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${d.customerId}`,
                        state: { customer: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {d.name}
                  </span>
                </div>
                {/* <div style={{ fontSize: "16px" }}>
                  Driver: <span>{d.driver}</span>{" "}
                </div> */}
              </div>
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.reservation.startDate}
            </td>
            <td className={classes.tableCell}>{d.reservation.endDate}</td>
            <td className={classes.tableCell}>
              <span
              onClick={() =>
                history.push({
                  pathname: `/admin/site/${d.reservation.siteId}`,
                  state: { reservationData: d.reservation },
                })}>
                {d.reservation.siteAddress}
              </span></td>
            <td className={classes.tableCell}>{renderEquipment(d.reservation.equiptment)}</td>
            <td className={classes.tableCell}>
              {d.reservation.recurring ? "Yes" : "No"}
            </td>
            <td className={classes.tableCell}>
              <div
                style={
                  d.reservation.status === "Scheduled"
                    ? { background: "#E01E5A" }
                    : d.reservation.status === "Completed"
                      ? { background: "#2EB67D" }
                      : d.reservation.status === "In Progress"
                        ? { background: "#E0AC12", color: "#323232" }
                        : d.reservation.status === "Approved"
                          ? { background: "#E0AC12", color: "#323232" }
                          : { background: "#323232" }
                }
                className={classes.commentDiv}
                style={{ background: "#FFC821", fontWeight: 700 }}
              >
                {d.reservation.status}
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#ECB22E" }}
                  >
                    <DenyIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div
          style={
            pageDropdown != 3 && reservations.length > 3
              ? { height: "270px", width: "100%", overflowY: "scroll" }
              : null
          }
        >
          <table className={classes.inventoryTable} style={{ marginBottom: "10px" }}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
        {pageDropdown == "3" ? (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={
                      {
                        // className: classes.selectMenu,
                      }
                    }
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginTop: "5px" }}>
              <h5
                className={classes.indexes}
              >
                {index + 1} - {endIndex} of{" "}
                {Math.ceil(reservations.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(reservations.length / itemsPerPage)}
                nextLabel={
                  <ArrowRight
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                previousLabel={
                  <ArrowLeft
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={
                      {
                        // className: classes.selectMenu,
                      }
                    }
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(RentalsTable));
