import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import Card from "components/Card/Card.js";
import { ReactComponent as Minus } from "assets/img/minus.svg";
import Button from "components/CustomButtons/Button.js";

const overviewsCustomer = [
  {
    title: "Rentals",
    description: "Your scheduled and completed rentals",
    link: "/admin/reports/rentals",
  },
]

const overviews = [
  {
    title: "Jobs",
    description: "Your scheduled and completed jobs",
    link: "/admin/reports/jobs",
  },
  // {
  //   title: "Rentals",
  //   description: "Your scheduled and completed rentals",
  //   link: "/admin/reports/rentals",
  // },
  {
    title: "Volume by territory",
    description: "Territory Volume pumped per job type, grouped by territory.",
    link: "/admin/reports/volumn-by-territory",
  },
  {
    title: "Email Activity",
    description:
      "A history log of opens, clicks, and delivery data for each email sent from your account.",
    link: "/admin/reports/emails",
  },
  {
    title: "Text Activity",
    description:
      "A history log of opens, clicks, and delivery data for each email sent from your account.",
    link: "/admin/reports/texts",
  },
  {
    title: "Dumpster Cost Analysis",
    description:
      "Analysis Analysis of amount and money earned on each dumpster size and specific barcodes.",
    link: "/admin/reports/dumpster-cost-analysis",
  },
];

function BusinessOverview(props) {
  const { classes, history, user } = props;

  return (
    <Card style={{ marginBottom: "0px" }}>
      <div className={classes.cardHeaderDiv}>
        <Minus />
        <span className={classes.cardTitle}>Business Overview</span>
      </div>
      <div className={classes.cardBodyDiv}>
        <GridContainer>
          {(user.role === "undefined" || user.role !== "Super Admin") ?
            overviewsCustomer.map((o, index) => {
              return (
                <GridItem className={classes.reportGridItem} key={index} xs={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className={classes.reportTitle}
                      onClick={() => history.push(o.link)}
                    >
                      {o.title}
                    </span>
                    <span className={classes.reportDescription}>
                      {o.description}
                    </span>
                  </div>
                  <Button
                    onClick={() => history.push(o.link)}
                    className={classes.viewBtn}
                  >
                    View Report{" "}
                  </Button>
                </GridItem>
              )

            })
            :
            overviews.map((o, index) => {
              return (
                <GridItem className={classes.reportGridItem} key={index} xs={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className={classes.reportTitle}
                      onClick={() => history.push(o.link)}
                    >
                      {o.title}
                    </span>
                    <span className={classes.reportDescription}>
                      {o.description}
                    </span>
                  </div>
                  <Button
                    onClick={() => history.push(o.link)}
                    className={classes.viewBtn}
                  >
                    View Report{" "}
                  </Button>
                </GridItem>
              )

            })
          }
        </GridContainer>
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(BusinessOverview));
