import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import regularsStyles from "assets/jss/material-dashboard-pro-react/views/regularsStyle.js";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";


import Card from "components/Card/Card";
import Territories from "./Territories/Territories";
import Sites from "./Sites/Sites";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Schedule(props) {
  const { classes, regularsData, customersData, history, inventoryData } = props;
  const [searchString, setSearchString] = useState("");
  const [regulars, setRegulars] = useState([]);
  const [customers, setCustomers] = useState([]);


  useEffect(() => {
    setRegulars(regularsData);
  }, [regularsData]);

  useEffect(() => {
    setCustomers(customersData);
  }, [customersData]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={6}>
          <Territories/>
        </GridItem>
        <GridItem xs={6}>
          <Sites
            history={history}
          />
        </GridItem>
      </GridContainer>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    regularsData: state.customers.regulars,
    customersData: state.customers.customers,
    inventoryData: state.inventory.inventory,
  };
};

const mapDispatchToProps = (dispatch) => ({
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(regularsStyles)
)(React.memo(Schedule));
