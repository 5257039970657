import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase } from "firebase/client";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";
import Checkbox from "@material-ui/core/Checkbox";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";

const sortByOptions = ["Last Sent Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];

function RemindersFilters(props) {
  const { classes, customerInfo } = props;
  const [sortBy, setSortBy] = useState("Last Sent Date");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterBySelection, setFilterBySelection] = useState("Filter By");
  const [searchString, setSearchString] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  // const [invoicesState, setInvoicesState] = useState([]);
  // const [checkedCount, setCheckedCount] = useState(0);
  // const [checkedAll, setCheckedAll] = useState(false);
  // const [invoicesLoaded, setInvoicesLoaded] = useState(false);

  // useEffect(() => {
  //   if (!invoicesLoaded && customerInfo) {
  //     const getInvoiceData = async () => {
  //       return db
  //         .collection("customers")
  //         .doc(customerInfo.customerId)
  //         .get()
  //         .then((snapshot) => {
  //           const customerData = snapshot.data();
  //           setInvoicesState(
  //             customerData.reservations.map((d) => {
  //               return { ...d, checked: false };
  //             })
  //           );
  //           setInvoicesLoaded(true);
  //         });
  //     };
  //     getInvoiceData();
  //   }
  // }, [customerInfo]);

  return (
    <GridContainer>
      <GridItem className={classes.jobsGridItems} xs={1}>
        <h5 className={classes.subHeader}>Sort By</h5>
      </GridItem>
      <GridItem className={classes.jobsGridItems} xs={6}>
        <Select
          className={classes.filterMenu}
          value={sortBy}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => setSortBy(e.target.value)}
        >
          {sortByOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          className={classes.ascMenu}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          value={directionSelection}
          onChange={(e) => setDirectionSelection(e.target.value)}
        >
          {directionOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(RemindersFilters));
