import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import NavigationActions from "store/actions/navigation";

export function TopNavLink(props) {
    const { to, title, handleNavigation, ...rest } = props;
    return (
        <NavLink {...rest} to={to} onClick={ e=>{ e.preventDefault(); handleNavigation(title, to);}}/>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        handleNavigation: (title, target) => dispatch(NavigationActions.open(title, target))
    }
}

function mapStateToProps() {
    return {
        // none yet
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNavLink);