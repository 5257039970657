import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Card from "components/Card/Card";

const priceBookMockData = [
  {
    type: "Service 1",
    rate: "350.00",
    adjustedRate: "300",
  },
  {
    type: "Service 1",
    rate: "350.00",
    adjustedRate: "300",
  },
  {
    type: "Service 1",
    rate: "350.00",
    adjustedRate: "300",
  },
];

const headers = ["Service", "Rate", "Adjusted Rate"];

function NotesTab(props) {
  const { classes } = props;

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.notesTableRow}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.notesTableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
      return priceBookMockData.map((d, index) => {
        return (
          <tr className={classes.notesTableRow} key={d.name}>
            <td className={classes.notesTableCell}>{d.type}</td>
            <td className={classes.notesTableCell}>{d.rate}</td>
            <td className={classes.notesTableCell}>{d.adjustedRate}</td>
          </tr>
        );
      });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card className={classes.tabCard2}>
          <GridContainer>
            <GridItem className={classes.column} xs={4}>
              <span className={classes.notesTabTitle}>Notes</span>
              <div className={classes.categoryValue}>
              <span>$375 / dumpster <br/>$350 smaller dumpsters (15 or 20)</span> 
              </div>
            </GridItem>
            <GridItem className={classes.column} xs={8}>
              <span className={classes.notesTabTitle}>Price Book</span>
                <table className={classes.inventoryTable}>
                  <tbody>
                    {renderTableHeader()}
                    {renderTableData()}
                  </tbody>
                </table>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(NotesTab));
