import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from "firebase/client.js";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import RentalDetails from "./components/RentalDetails";
import RentalInfo from "./components/RentalInfo";
import RentalOtherInfo from "./components/RentalOtherInfo";
import loader from "assets/img/loader.gif";
import Button from "@material-ui/core/Button";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { addQuote } from "store/actions/quotes/quotesActions";
import { Slide, Dialog } from "@material-ui/core";
import ReccurenceModal from "./modal/ReccurenceModal";
import moment from "moment";
import { setPickups } from "store/actions/pickups/pickupsActions";
import { addPendingReservationExistingCustomer } from "store/actions/reservations/reservationsActions";
import pointInPolygon from "point-in-polygon";
import { forEach } from "lodash";

const locationSelections = [
  "Select Option",
  "Right Side of Driveway",
  "Left Side of Driveway",
  "Middle of Driveway",
  "On Street",
  "Side of House",
  "Backyard",
  "Alley",
  "Other (Please specify below)"
];

const weekly = ["first", "second", "third", "fourth", "fifth"]

const days = ["Monday", "Tuesday", "Wednesday", "Thursday",
  "Friday", "Saturday", "Sunday"]

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RequestRental(props) {
  const { classes, history, user, handleAddPendingReservationExistingCustomer } = props;
  const [customerInfo, setCustomerInfo] = useState("")
  const [customerFound, setCustomerFound] = useState("")
  const [territoriesData, setTerritoriesData] = useState(false);
  const [productType, setProductType] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [rate, setRate] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setDuration] = useState("");
  const [notes, setNotes] = useState("");
  const [promoCode, setPromoCode] = useState("")
  const [spokenWithTeamMember, setSpokenWithTeamMember] = useState({ yes: false, no: false })
  const [billingAddress, setBillingAddress] = useState("");
  const [dropOffAddress, setDropOffAddress] = useState("")
  const [emails, setEmails] = useState([])
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [dropOffLat, setDropOffLat] = useState(false);
  const [dropOffLng, setDropOffLng] = useState(false);
  const [siteAddress, setSiteAddress] = useState("");
  const [cost, setCost] = useState("");
  const [equipmentNeeded, setEquipmentNeeded] = useState("")
  const [dropOffDate, setDropOffDate] = useState("");
  const [pickUpDate, setPickUpDate] = useState("")
  const [dataLoaded, setDataLoaded] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [sevenDayRental, setSevenDayRental] = useState(false);
  const [otherDuration, setOtherDuration] = useState(false);
  const [equipmentLocation, setEquipmentLocation] = useState("Select Option");
  const [recurrRental, setRecurrRental] = useState(false)
  const [recurring, setRecurring] = useState(false);
  const [freqValue, setFreqValue] = useState("Daily");
  const [dailyValue, setDailyValue] = useState("")
  const [dailyNum, setDailyNum] = useState(0)
  const [weeklyValue, setWeeklyValue] = useState('')
  const [weekFreq, setWeekFreq] = useState(0)
  const [monthlyValue, setMonthlyValue] = useState('')
  const [monthWeekFreq, setMonthWeekFreq] = useState("first")
  const [monthDayFreq, setMonthDayFreq] = useState("Monday")
  const [monthValue, setMonthValue] = useState(0)
  const [monthDayValue, setMonthDayValue] = useState(0)
  const [rangeValue, setRangeValue] = useState('')
  const [rangeStartDate, setRangeStartDate] = useState('')
  const [rangeEndNum, setRangeEndNum] = useState(0)
  const [rangeEndDate, setRangeEndDate] = useState('')
  const [imageFile, setImageFile] = useState('')
  const [errors, setErrors] = useState({
    spokenWithTeamMember: false,
    billingAddress: false,
    emails: false,
    dropOffAddress: false,
    dropOffDate: false,
    equipmentNeeded: false,
    sevenDayRental: false,
    equipmentLocation: false,
    pickUpDate: false
  });
  const [recurrError, setRecurrError] = useState({
    valueErr: false,
    dailyValueErr: false,
    weeklyValueErr: false,
    monthlyValueErr: false,
    monthWeekFreqErr: false,
    monthDayFreqErr: false,
    monthValueErr: false,
    yearlyValueErr: false,
    yearlyMonthValueErr: false,
    yearDayValueErr: false,
    yearWeekFreqErr: false,
    rangeValueErr: false,
    rangeStartDateErr: false,
    rangeEndDateErr: false,
    rangeEndNumErr: false
  })

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();
      if (placeholder === "Search your billing address..") {
        setBillingAddress(results[0].formatted_address);
      }
      if (placeholder === "Search your drop-off address..") {
        const addressResults = results[0];
        addressResults.address_components.forEach((r) => {
          if (r.types.includes("locality")) {
            setCity(r.long_name);
          }
          if (r.types.includes("administrative_area_level_1")) {
            setState(r.short_name);
          }
          if (r.types.includes("postal_code")) {
            setZipCode(r.short_name);
          }
        });
        setSiteAddress(addressResults.formatted_address);
        setDropOffLat(lat);
        setDropOffLng(lng);
      }
    });
  };

  useEffect(() => {
    if (!territoriesData) {
      const fetchData = async () => {
        return db
          .collection("territories")
          .get()
          .then((snapshot) => {
            const territories = snapshot.docs.map((d) => d.data());
            setTerritoriesData(territories);
          });
      };
      fetchData();
    }
  }, [territoriesData]);

  useEffect(() => {
    if (!customerInfo) {
      const fetchData = async () => {
        return db
          .collection("customers")
          .get()
          .then((snapshot) => {
            const customerSnap = snapshot.docs.map((d) => d.data());
            customerSnap.forEach((c) => {
              if (c.email === user.email) {
                setCustomerInfo(c)
                setCustomerFound(true)
                setBillingAddress(c.billingAddress)
              }

            })
          })
      };
      fetchData();
    }
  }, [])
  console.log("checking customer info", customerInfo)

  useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        return db
          .collection("inventory")
          .orderBy("sortOrder")
          .get()
          .then((snapshot) => {
            const inventory = snapshot.docs.map((d) => d.data());
            setInventoryData(inventory);
            setDataLoaded(true);
          });
      };
      fetchData();
    }
  }, [dataLoaded]);


  const checkRecurr = () => {
    var errorObj = recurrError
    var isErr = false
    if (!freqValue) {
      errorObj.valueErr = true;
      isErr = true
    }
    if (freqValue === "Daily" && !dailyValue) {
      errorObj.dailyValueErr = true;
      isErr = true
    }
    if (dailyValue === "Every" && dailyNum === 0) {
      errorObj.dailyValueErr = true;
      isErr = true
    }
    if (freqValue === "Weekly" && !weeklyValue) {
      errorObj.weeklyValueErr = true;
      isErr = true
    }
    if (freqValue === "Weekly" && !weekFreq) {
      errorObj.weekFreqErr = true;
      isErr = true
    }
    if (freqValue === "Monthly" && !monthlyValue) {
      errorObj.monthlyValueErr = true;
      isErr = true
    }
    if (monthlyValue &&
      (monthDayValue === 0 || monthValue === 0 || !monthWeekFreq || !monthDayFreq || monthValue === 0)) {
      errorObj.monthlyValueErr = true;
      isErr = true
    }
    if (!rangeStartDate) {
      errorObj.rangeStartDateErr = true;
      isErr = true
    }
    if (!rangeValue) {
      errorObj.rangeValueErr = true;
      isErr = true
    }
    if (rangeValue === "End after" && rangeEndNum === 0) {
      errorObj.rangeEndNumErr = true;
      isErr = true
    }
    if (rangeValue === "End by" && !rangeEndDate) {
      errorObj.rangeEndDateErr = true;
      isErr = true
    }
    if (isErr) {
      setRecurrError({ ...recurrError, errorObj })
    }
    else {
      setRecurrError({
        valueErr: false,
        weeklyValueErr: false,
        monthlyValueErr: false,
        monthWeekFreqErr: false,
        monthDayFreqErr: false,
        monthValueErr: false,
        yearlyValueErr: false,
        yearlyMonthValueErr: false,
        yearDayValueErr: false,
        yearWeekFreqErr: false,
        rangeValueErr: false,
        rangeStartDateErr: false,
        rangeEndDateErr: false,
        rangeDateDiffErr: false
      })
      setRecurring(false)
    }
  }


  const handleSubmit = async () => {
    let attachmentUrl = ""
    if (
      (!spokenWithTeamMember.yes && !spokenWithTeamMember.no) ||
      !billingAddress ||
      emails.length === 0 ||
      !dropOffAddress ||
      !dropOffDate ||
      !equipmentNeeded ||
      (!sevenDayRental && !otherDuration) ||
      equipmentLocation === "Select Option" ||
      !pickUpDate
    ) {
      let newErrors = {
        spokenWithTeamMember: false,
        billingAddress: false,
        emails: false
      };
      if (!spokenWithTeamMember.yes && !spokenWithTeamMember.no) {
        newErrors = { ...newErrors, spokenWithTeamMember: true };
      }
      if (!billingAddress) {
        newErrors = { ...newErrors, billingAddress: true };
      }
      if (emails.length === 0) {
        newErrors = { ...newErrors, emails: true };
      }
      if (!dropOffAddress) {
        newErrors = { ...newErrors, dropOffAddress: true };
      }
      if (!dropOffDate) {
        newErrors = { ...newErrors, dropOffDate: true };
      }
      if (!equipmentNeeded) {
        newErrors = { ...newErrors, equipmentNeeded: true };
      }
      if (!sevenDayRental && !otherDuration) {
        newErrors = { ...newErrors, sevenDayRental: true };
      }
      if (equipmentLocation === "Select Option") {
        newErrors = { ...newErrors, equipmentLocation: true };
      }
      if (!pickUpDate && !sevenDayRental) {
        newErrors = { ...newErrors, pickUpDate: true };
      }
      setErrors({ ...newErrors });
    } else {
      let territory = "N/A";
      const territoryPolygons = [];
      territoriesData.forEach((t) => {
        territoryPolygons.push(Object.values(t.territoryPolygon));
      });
      territoryPolygons.forEach((t, index) => {
        if (pointInPolygon([dropOffLng, dropOffLat], t)) {
          territory = territoriesData[index].territoryName;
        }
      });
      const file = imageFile;
      await firebase.storage().ref().child(`rentals/${customerInfo.customerId}/attachments/`).put(file)
        .then(async () => {
          await firebase
            .storage()
            .ref()
            .child(`rentals/${customerInfo.customerId}/attachments/`)
            .getDownloadURL()
            .then(async (url) => {
              attachmentUrl = url
            })
          return attachmentUrl
        })
      const pendingRes = {
        ...customerInfo,
        billingAddress: billingAddress,
        reservation: {
          amount: cost,
          amountPaid: "0",
          driver: "N/A",
          endDate: sevenDayRental ? moment(dropOffDate, "MM/DD/YY").add(7, "days").format("MM/DD/YY") : pickUpDate,
          territory,
          equiptment: equipmentNeeded.inventoryItem,
          dropOffCity: city,
          dropOffState: state,
          dropOffZipCode: zipCode,
          equiptmentLocation: equipmentLocation,
          existingCustomer: true,
          heardAboutUsFrom: "Past Customer",
          inventoryId: equipmentNeeded.inventoryId,
          lastEmailedDate: "N/A",
          notes: notes,
          otherInformation: "",
          paymentDueDate: dropOffDate,
          poNumber: "",
          promoCode: promoCode,
          quantity: 1,
          recurring: recurrRental,
          recurringRental: {
            frequency: freqValue,
            frequencyOption: freqValue === "Daily" && dailyValue === "Every" ?
              `Every ${dailyNum} day(s)` :
              freqValue === "Daily" && dailyValue === "Weekday" ?
                "Weekday" :
                freqValue === "Weekly" ?
                  `Every ${weekFreq} week(s) on ${weeklyValue}`
                  :
                  freqValue === "Monthly" && monthlyValue === "Day" ?
                    `Day ${monthDayValue} of every ${monthValue} month(s)`
                    :
                    freqValue === "Monthly" && monthlyValue === "The" ?
                      `The ${monthWeekFreq} ${monthDayFreq} of every ${monthValue}`
                      : "",
            startDateRangeRecurrence: rangeStartDate,
            endDateRangeRecurrence: rangeValue === "No End Date" ?
              rangeValue :
              rangeValue === "End after" ?
                `End after ${rangeEndNum} jobs` :
                rangeValue === "End by" ?
                  `End by ${rangeEndDate}` :
                  ""
          },
          sevenDayReservation: sevenDayRental ? true : false,
          siteAddress: siteAddress,
          startDate: dropOffDate,
          status: "Unapproved",
          spokenWithTeamMember: spokenWithTeamMember.yes ? true : false,
          attachmentUrl
        },
      };
      handleAddPendingReservationExistingCustomer(pendingRes)
        .then(async () => {
          await history.push('/admin/dashboard')
        })
    }
  };

  const handleModalClose = () => {
    setRecurrRental(false)
    setRecurring(false);
    setFreqValue("Daily");
    setDailyValue("")
    setDailyNum(0)
    setWeeklyValue('')
    setWeekFreq(0)
    setMonthlyValue('')
    setMonthWeekFreq("first")
    setMonthDayFreq("Monday")
    setMonthValue(0)
    setMonthDayValue(0)
    setRangeValue('')
    setRangeStartDate('')
    setRangeEndNum(0)
    setRangeEndDate('')
    setRecurrError({
      valueErr: false,
      weeklyValueErr: false,
      monthlyValueErr: false,
      monthWeekFreqErr: false,
      monthDayFreqErr: false,
      monthValueErr: false,
      yearlyValueErr: false,
      yearlyMonthValueErr: false,
      yearDayValueErr: false,
      yearWeekFreqErr: false,
      rangeValueErr: false,
      rangeStartDateErr: false,
      rangeEndDateErr: false,
      rangeDateDiffErr: false
    })

  };

  if (!customerFound)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );

  return (
    <GridContainer>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <h1 className={classes.title}>Request Rental</h1>
      </GridItem>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <RentalDetails
          customerInfo={customerInfo}
          handleSelect={handleSelect}
          user={user}
          setEmails={setEmails}
          emails={emails}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          spokenWithTeamMember={spokenWithTeamMember}
          setSpokenWithTeamMember={setSpokenWithTeamMember}
          history={history}
          errors={errors}
        />
      </GridItem>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <RentalInfo
          equipmentLocation={equipmentLocation}
          setEquipmentLocation={setEquipmentLocation}
          locationSelections={locationSelections}
          setSevenDayRental={setSevenDayRental}
          sevenDayRental={sevenDayRental}
          setOtherDuration={setOtherDuration}
          otherDuration={otherDuration}
          equipmentNeeded={equipmentNeeded}
          setEquipmentNeeded={setEquipmentNeeded}
          inventoryData={inventoryData}
          setInventoryData={setInventoryData}
          dropOffDate={dropOffDate}
          setDropOffDate={setDropOffDate}
          pickUpDate={pickUpDate}
          setPickUpDate={setPickUpDate}
          handleSelect={handleSelect}
          dropOffAddress={dropOffAddress}
          setDropOffAddress={setDropOffAddress}
          history={history}
          errors={errors}
          setCost={setCost}
          cost={cost}
        />
      </GridItem>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <RentalOtherInfo
          recurrRental={recurrRental}
          setRecurrRental={setRecurrRental}
          recurring={recurring}
          setRecurring={setRecurring}
          setProductType={setProductType}
          productType={productType}
          history={history}
          quantity={quantity}
          setQuantity={setQuantity}
          rate={rate}
          setRate={setRate}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          duration={duration}
          notes={notes}
          setNotes={setNotes}
          errors={errors}
          imageFile={imageFile}
          setImageFile={setImageFile}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
        />
      </GridItem>
      <GridItem className={classes.cancelSaveBtnGrid} xs={12}>
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.push("/admin/dashboard")}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          style={{
            background: "#FFC821",
            color: "#323232",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>
      <Dialog
        maxWidth={"md"}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={recurring}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleModalClose}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <ReccurenceModal
          recurrError={recurrError}
          setRecurrError={setRecurrError}
          freqValue={freqValue}
          setFreqValue={setFreqValue}
          weeklyValue={weeklyValue}
          setWeeklyValue={setWeeklyValue}
          weekFreq={weekFreq}
          setWeekFreq={setWeekFreq}
          monthlyValue={monthlyValue}
          setMonthlyValue={setMonthlyValue}
          monthWeekFreq={monthWeekFreq}
          setMonthWeekFreq={setMonthWeekFreq}
          monthDayFreq={monthDayFreq}
          setMonthDayFreq={setMonthDayFreq}
          monthValue={monthValue}
          setMonthValue={setMonthValue}
          monthDayValue={monthDayValue}
          setMonthDayValue={setMonthDayValue}
          rangeValue={rangeValue}
          setRangeValue={setRangeValue}
          rangeStartDate={rangeStartDate}
          setRangeStartDate={setRangeStartDate}
          rangeEndNum={rangeEndNum}
          setRangeEndNum={setRangeEndNum}
          rangeEndDate={rangeEndDate}
          setRangeEndDate={setRangeEndDate}
          days={days}
          weekly={weekly}
          dailyNum={dailyNum}
          setDailyNum={setDailyNum}
          dailyValue={dailyValue}
          setDailyValue={setDailyValue}
          handleModalClose={handleModalClose}
          checkRecurr={checkRecurr}
        />
      </Dialog>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleAddQuote: (quote) => dispatch(addQuote(quote)),
  handleAddPendingReservationExistingCustomer: (pendingRes) =>
    dispatch(addPendingReservationExistingCustomer(pendingRes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(RequestRental));
