import React, { useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/modalsStyle.js";
import Card from "components/Card/Card.js";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import moment from "moment";
import { updatePendingReservation } from "store/actions/reservations/reservationsActions";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { ReactComponent as BlackInfoIcon } from "assets/img/blackInfoIcon.svg";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";



const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

function ViewModal(props) {
  const {
    classes,
    selectedPendingReservation,
    handleApproveReservation,
    handleModalClose,
    editErrors,
    editHasErrors,
    setEditErrors,
    inventoryData,
    handleUpdatePendingReservation,
    setModalEditedSuccessful,
    handleModalOpen,
    heardAboutUsFromSelections,
    locationSelections
  } = props;

  const [billingAddress, setBillingAddress] = useState(
    selectedPendingReservation.billingAddress
  );
  const [phoneNumber, setPhoneNumber] = useState(
    selectedPendingReservation.phoneNumber
      ? selectedPendingReservation.phoneNumber
      : ""
  );
  const [addressLine2, setAddressLine2] = useState(
    selectedPendingReservation.addressLine2
      ? selectedPendingReservation.addressLine2
      : ""
  );
  const [price, setPrice] = useState(
    selectedPendingReservation.reservation.amount
      ? selectedPendingReservation.reservation.amount
      : ""
  );
  const [siteAddress, setSiteAddress] = useState(
    selectedPendingReservation.reservation.siteAddress
      ? selectedPendingReservation.reservation.siteAddress
      : ""
  );
  const [dropOffDate, setDropOffDate] = useState(
    selectedPendingReservation.reservation.startDate
      ? selectedPendingReservation.reservation.startDate
      : ""
  );
  const [pickupDate, setPickupDate] = useState(
    selectedPendingReservation.reservation.endDate
      ? selectedPendingReservation.reservation.endDate
      : selectedPendingReservation.reservation.sevenDayReservation ?
        moment(selectedPendingReservation.reservation.startDate, "MM/DD/YY").add(7, "days").format("MM/DD/YY")
        : ""
  );
  const [equiptmentNeeded, setEquiptmentNeeded] = useState(
    selectedPendingReservation.reservation.equiptment
  );
  const [equipmentLocation, setEquiptmentLocation] = useState(
    selectedPendingReservation.reservation.equiptmentLocation
      ? selectedPendingReservation.reservation.equiptmentLocation
      : ""
  );
  const [inventoryId, setInventoryId] = useState(
    selectedPendingReservation.reservation.inventoryId
      ? selectedPendingReservation.reservation.inventoryId
      : ""
  );

  const [heardAboutUsFrom, setHeardAboutUsFrom] = useState(
    selectedPendingReservation.reservation.heardAboutUsFrom ?
      selectedPendingReservation.reservation.heardAboutUsFrom
      : ""
  )

  const [notes, setNotes] = useState(
    selectedPendingReservation.reservation.notes ?
      selectedPendingReservation.reservation.notes
      : ""
  )

  const [driverNotes, setDriverNotes] = useState(
    selectedPendingReservation.reservation.driverNotes ?
      selectedPendingReservation.reservation.driverNotes
      : ""
  )

  var dayDiff = Math.abs(moment(dropOffDate).endOf('day').diff(moment(pickupDate).endOf('day'), 'days'))

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      if (placeholder === "Search your billing address..") {
        setBillingAddress(results[0].formatted_address);
      }
      if (placeholder === "Search your drop-off address..") {
        setSiteAddress(results[0].formatted_address);
      }
    });
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;
    setPhoneNumber(value);
  };

  const handleSubmit = () => {
    const pendingRes = {
      ...selectedPendingReservation,
      phoneNumber: phoneNumber
        ? phoneNumber
        : selectedPendingReservation.phoneNumber,
      billingAddress: billingAddress
        ? billingAddress
        : billingAddress.phoneNumber,
      billingAddressLine2: addressLine2
        ? addressLine2
        : selectedPendingReservation.billingAddressLine2,
      reservation: {
        ...selectedPendingReservation.reservation,
        siteAddress: siteAddress
          ? siteAddress
          : selectedPendingReservation.reservation.siteAddress,
        startDate: dropOffDate
          ? dropOffDate
          : selectedPendingReservation.reservation.startDate,
        endDate: pickupDate
          ? pickupDate
          : selectedPendingReservation.reservation.endDate,
        equiptment: equiptmentNeeded
          ? equiptmentNeeded
          : selectedPendingReservation.reservation.equiptment.inventoryItem,
        equipmentLocation: equipmentLocation
          ? equipmentLocation
          : selectedPendingReservation.reservation.equipmentLocation,
        inventoryId: inventoryId
          ? inventoryId
          : selectedPendingReservation.reservation.inventoryId,
        heardAboutUsFrom: heardAboutUsFrom ? heardAboutUsFrom
          : selectedPendingReservation.reservation.heardAboutUsFrom,
        notes: notes ? notes
          : selectedPendingReservation.reservation.notes,
        driverNotes: driverNotes && !selectedPendingReservation.reservation.driverNotes ?
          driverNotes
          : !notes && !selectedPendingReservation.reservation.driverNotes ?
            driverNotes
            : selectedPendingReservation.reservation.driverNotes
      },
    };
    handleUpdatePendingReservation(pendingRes).then(() => {
      handleModalClose();
      setModalEditedSuccessful(true);
    });
  };

  return (
    <Card style={{ margin: "0px" }} className={classes.card}>
      <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
        <BlackDenyIcon
          onClick={() => handleModalClose()}
          className={classes.closeModal}
        />
      </div>
      <h5 className={classes.formTitle}>
        Contact Information:{" "}
        {editErrors.phoneNumber && (
          <span style={{ color: "red" }}>Required*</span>
        )}
      </h5>

      <TextField
        className={classes.emailInput}
        value={selectedPendingReservation.email}
        disabled
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={selectedPendingReservation.firstName}
        disabled
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={selectedPendingReservation.lastName}
        disabled
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={phoneNumber}
        disabled
        placeholder="Phone Number"
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <h5 className={classes.formTitle}>
        Billing Address:{" "}
        {editErrors.billingAddress && (
          <span style={{ color: "red" }}>Required*</span>
        )}{" "}
      </h5>

      <TextField
        className={classes.emailInput}
        value={billingAddress}
        disabled
        placeholder="Phone Number"
        variant="outlined"
        onChange={(e) => {
          setBillingAddress(e);
        }}
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />
      <TextField
        className={classes.emailInput}
        value={addressLine2}
        disabled
        placeholder="Address line 2 (optional)"
        variant="outlined"
        onChange={(e) => setAddressLine2(e.target.value)}
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />
      <h5 className={classes.formTitle}>
        Price:{" "}
        {editErrors.price && <span style={{ color: "red" }}>Required*</span>}{" "}
      </h5>

      <TextField
        className={classes.emailInput}
        value={price}
        disabled
        type="number"
        onWheel={() => document.activeElement.blur()}
        placeholder="Price"
        variant="outlined"
        onChange={(e) => setPrice(e.target.value)}
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <h5 className={classes.formTitle}>
        Drop-Off Address:{" "}
        {editErrors.siteAddress && (
          <span style={{ color: "red" }}>Required*</span>
        )}{" "}
      </h5>

      <TextField
        className={classes.emailInput}
        value={siteAddress}
        disabled
        placeholder="Search your drop-off address.."
        variant="outlined"
        onChange={(e) => setSiteAddress(e.target.value)}
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <h5 className={classes.formTitle}>
        {`Requested Drop-Off Date (${dayDiff} Days) `}

        {editErrors.startDate && (
          <span style={{ color: "red" }}>Required*</span>
        )}{" "}
      </h5>
      <Datetime
        input
        // className={classes.textFieldRoot}
        inputProps={{
          className: classes.grayedOutDatetime,
          placeholder: "Choose rental drop-off date..",
          disabled: true,
        }}
        style={{
          color: "gray !important",
          height: "100%",
          width: "100%",
        }}
        disabled
        isValidDate={disablePastDt}
        value={dropOffDate}
        selected={dropOffDate}
        dateFormat="MM/DD/YY"
        onChange={(date, e) => {
          setDropOffDate(date.format("MM/DD/YY"));
        }}
        timeFormat={false}
        closeOnSelect={true}
        closeOnClickOutside
      />

      <h5 className={classes.formTitle}>
        Requested Pick Up Date:{" "}
        {editErrors.pickupDate && (
          <span style={{ color: "red" }}>Required*</span>
        )}
      </h5>
      <Datetime
        input
        // className={classes.textFieldRoot}
        isValidDate={disablePastDt}
        value={pickupDate}
        disabled
        inputProps={{
          className: classes.grayedOutDatetime,
          placeholder: "Choose rental pickup date..",
          disabled: true,
        }}
        style={{
          color: "gray !important",
          height: "100%",
          width: "100%",
        }}
        selected={pickupDate}
        dateFormat="MM/DD/YY"
        onChange={(date, e) => {
          setPickupDate(date.format("MM/DD/YY"));
        }}
        timeFormat={false}
        closeOnSelect={true}
        closeOnClickOutside
      />

      <h5 className={classes.formTitle}>
        Container Needed:{" "}
        {editErrors.equiptmentNeeded && (
          <span style={{ color: "red" }}>Required*</span>
        )}
      </h5>
      <Select
        className={classes.filterMenu}
        value={equiptmentNeeded}
        disabled
        disableUnderline
        classes={{
          root: classes.selectText,
        }}
        onChange={(e) => {
          const value = e.target.value;
          setEquiptmentNeeded(value);
          let id;
          inventoryData.forEach((d) => {
            if (d.inventoryItem === value) {
              id = d.inventoryId;
            }
          });
          setInventoryId(id);
        }}
      >
        {inventoryData.map((o, i) => {
          return (
            <MenuItem
              key={i}
              value={o.inventoryItem}
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              {o.inventoryItem}
            </MenuItem>
          );
        })}
      </Select>


      <h5 className={classes.formTitle}>
        Container Location (if you are facing the house){" "}
        {editErrors.equipmentLocation && (
          <span style={{ color: "red" }}>Required*</span>
        )}
      </h5>
      <Select
        className={classes.filterMenu}
        // style={{ marginBottom: "20px" }}
        disabled
        value={equipmentLocation}
        disableUnderline
        classes={{
          root: classes.selectText,
        }}
        onChange={(e) => setEquiptmentLocation(e.target.value)}
      >
        {locationSelections.map((o, i) => {
          return (
            <MenuItem
              key={i}
              value={o}
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              {o}
            </MenuItem>
          );
        })}
      </Select>

      <h5 className={classes.formTitle}>
        Heard About Us From: {" "}
        {editErrors.heardAboutUsFrom && (
          <span style={{ color: "red" }}>Required*</span>
        )}
      </h5>
      <Select
        className={classes.filterMenu}
        style={{ marginBottom: "20px" }}
        disabled
        value={heardAboutUsFrom}
        disableUnderline
        classes={{
          root: classes.selectText,
        }}
        onChange={(e) => setHeardAboutUsFrom(e.target.value)}
      >
        {heardAboutUsFromSelections.map((o, i) => {
          return (
            <MenuItem
              key={i}
              value={o}
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              {o}
            </MenuItem>
          );
        })}
      </Select>


      <h5 className={classes.formTitle}>
        Notes: {" "}
      </h5>
      <TextField
        disabled
        className={classes.emailInput}
        value={notes ? notes : "N/A"}
        placeholder="Notes"
        variant="filled"
        onChange={(e) => setNotes(e.target.value)}
        multiline={true}
        InputProps={{
          style: {
            paddingTop: 10
          }
        }}
      />

      <h5 className={classes.formTitle}>
        Driver Notes: {" "}
      </h5>
      <TextField
        disabled
        className={classes.emailInput}
        value={driverNotes ? driverNotes : "N/A"}
        placeholder="Driver Notes"
        variant="filled"
        onChange={(e) => setDriverNotes(e.target.value)}
        multiline={true}
        InputProps={{
          style: {
            paddingTop: 10
          }
        }}
      />



      <GridContainer>
        <GridItem xs={4}>
          <Button
            onClick={() =>
              handleModalOpen("Approve", selectedPendingReservation)
            }
            style={{ background: "#FEDA1B", color: "#323232" }}
            className={classes.viewModalCardButtons}
          >
            Approve <CheckIcon />
          </Button>
        </GridItem>
        <GridItem xs={4}>
          {" "}
          <Button
            onClick={() => handleModalOpen("Edit", selectedPendingReservation)}
            style={{ color: "white", background: "#323232" }}
            className={classes.viewModalCardButtons}
          >
            Edit <WhiteEditIcon />
          </Button>
        </GridItem>
        <GridItem xs={4}>
          {" "}
          <Button
            onClick={() => handleModalOpen("Deny", selectedPendingReservation)}
            style={{ color: "white", background: "#929292" }}
            className={classes.viewModalCardButtons}
          >
            Deny <BlackDenyIcon />
          </Button>
        </GridItem>
      </GridContainer>

      <Snackbar open={editHasErrors} onClose={() => setEditErrors(false)}>
        <Alert onClose={() => setEditErrors(false)} severity={"error"}>
          Please Fill Out All Mandatory Fields
        </Alert>
      </Snackbar>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleUpdatePendingReservation: (pendingRes) =>
    dispatch(updatePendingReservation(pendingRes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(modalStyles)
)(ViewModal);
