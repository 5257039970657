import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Dialog from "@material-ui/core/Dialog";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import axios from "axios";

import StripeContainer from "./StripeContainer.js";
import Slide from "@material-ui/core/Slide";
import { Checkbox } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BillingInformation(props) {
  const { classes, history, userInfo, secret, setSecret, customerId, setCustomerId } = props;
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [expiration, setExpiration] = useState("");
  const [billingModal, setBillingModal] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState("");
  const [sameAsCustomer, setSameAsCustomer] = useState(false)
  // const [cardNumber, setCardNumber] = useState('')
  useEffect(() => {
    if (userInfo.stripeCustomerId) {
      console.log("userInfo 1", userInfo);
      const listPayments = async () => {
        const methods = await axios.post(
          "https://us-central1-humpty-dump.cloudfunctions.net/app/list-payments",
          {
            customerId: userInfo.stripeCustomerId,
          }
        );
        console.log("paymentMethods", methods);
        const card = methods.data.paymentMethods.data[0].card;
        const billingDetails =
          methods.data.paymentMethods.data[0].billing_details;
        setCardNumber(`${card.brand.toUpperCase()} Ending in ${card.last4}`);
        setExpiration(`${card.exp_month}/${card.exp_year}`);
        setCardHolderName(billingDetails.name);
        // const paymentMethods = await stripe.paymentMethods.list({
        //   customer: 'cus_NC2zqoKhquzN8J',
        //   type: 'card',
        // });
        // console.log('paymentMethods', paymentMethods)
      };
      listPayments();
    }
  }, [userInfo]);

  return (
    <GridContainer className={classes.bilingInfoGridContainer}>
      <GridItem className={classes.columnGridItem} xs={4}>
        <h1 className={classes.billingInfoTitle}>Card Details</h1>
        <GridContainer>
          <GridItem className={classes.billingInfoGridItem} xs={6}>
            <span className={classes.billingInfoKey}>CARD NUMBER</span>
          </GridItem>
          <GridItem className={classes.billingInfoGridItem} xs={6}>
            <span className={classes.billingInfoValue}>
              {cardNumber ? cardNumber : "N/A"}
            </span>
          </GridItem>

          <GridItem className={classes.billingInfoGridItem} xs={6}>
            <span className={classes.billingInfoKey}>CARDHOLDER NAME</span>
          </GridItem>
          <GridItem className={classes.billingInfoGridItem} xs={6}>
            <span className={classes.billingInfoValue}>
              {cardHolderName ? cardHolderName : "N/A"}
            </span>
          </GridItem>

          <GridItem className={classes.billingInfoGridItem} xs={6}>
            <span className={classes.billingInfoKey}>EXPIRATION DATE</span>
          </GridItem>
          <GridItem className={classes.billingInfoGridItem} xs={6}>
            <span className={classes.billingInfoValue}>
              {" "}
              {expiration ? expiration : "N/A"}
            </span>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={classes.columnGridItem} xs={4}>
        <h1 className={classes.billingInfoTitle}>Billing Address</h1>
        <GridContainer>
          <GridItem className={classes.billingInfoGridItem} xs={6}>
            <span className={classes.billingInfoKey}>ADDRESS</span>
          </GridItem>
          <GridItem className={classes.billingInfoGridItem} xs={6}>
            <span className={classes.billingInfoValue}>
              {" "}
              {userInfo.billing.billingAddress
                ? userInfo.billing.billingAddress
                : "N/A"}
            </span>
          </GridItem>
          <GridItem xs={12} >
            <div style={{ display: "flex" }}>
              <Checkbox
                style={{ color: "#929292" }}
                checked={sameAsCustomer}
                onChange={() => setSameAsCustomer(!sameAsCustomer)}
              />
              <span className={classes.billingInfoValue} style={{ marginTop: "13px" }}>Same as Customer Address</span>
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={4}>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Button
            // onClick={() => history.push("/admin/edit-billing")}
            onClick={() => setBillingModal(true)}
            style={{ width: "fit-content", fontWeight: 700, whiteSpace: "noWrap" }}
            className={classes.addCustomer}
          >
            <EditIcon className={classes.editIcon} /> Edit Billing Information
          </Button>
        </div>
      </GridItem>

      <Dialog
        style={{ zIndex: "9999 !important" }}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        maxWidth="xl"
        open={billingModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setBillingModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <StripeContainer
          setSecret={setSecret}
          secret={secret}
          history={history}
          // payInvoice={payInvoice}
          amount={50}
          classes={classes}
          setBillingModal={setBillingModal}
          setSnackBarContent={setSnackBarContent}
          userInfo={userInfo}
          customerId={customerId}
          setCustomerId={setCustomerId}
        />
      </Dialog>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={snackBarContent.includes("problem") ? "error" : "success"}
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(BillingInformation);
