import React from "react";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/formFieldsStyle";

function findChild(children, name) {
    return children && children.find(child=>child.props.name === name);
}

function classesFor(classes, name) {
    return classes[name] || classes.field; // Needs improving?
}

function Wrapper(props) {
    return (
        <GridItem xs={12} sm={props.width}>
            { props.children }
        </GridItem>
    );
}

export function FormFields(props) {
    const { classes, order, FieldFactory, children } = props;
    return (
        <GridContainer className={classes.root}>
            {                
                order
                    ? order.map((name, index) => 
                        <Wrapper width={6} key={index}>
                            { findChild(children, name) || <FieldFactory name={name} classes={classesFor(classes, name)}/> }
                        </Wrapper>
                    )
                    : children.map((child, index) => 
                        <Wrapper width={6} key={index}>
                            {child}
                        </Wrapper>
                    )
            }
        </GridContainer>
    )
}

export default withStyles(styles)(FormFields);

