import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import RentalsCustomTabs from "./components/RentalsCustomTabs/RentalsCustomTabs";
import ListView from "./components/RentalsCustomTabs/List/ListView";
import MapView from "./components/RentalsCustomTabs/Map/MapView";
import QuotesView from "./components/RentalsCustomTabs/Quotes/QuotesView";

function Rentals(props) {
  const { classes, history, user } = props;
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <GridContainer>
      <GridItem xs={7} style={{ display: "flex", alignItems: "center" }}>
        <h1 style={{ textAlign: "left" }} className={classes.title}>
          Rentals
        </h1>
      </GridItem>
      <GridItem className={classes.buttonGridItem} xs={5}>
        {user.role && user.role === "Super Admin" && currentTab === 0 ?
          <Button className={classes.addCustomer}
            onClick={() => history.push("/admin/add-rental")}
          >
            <AddIcon style={{marginRight:"5px"}}/> Create Rental
          </Button>
          : user.role && user.role === "Super Admin" && currentTab === 2 ?
          <Button
            className={classes.addCustomer}
            onClick={() =>
              history.push({
                pathname: "/admin/create-quote",
              })
            }
          >
            <AddIcon style={{marginRight:"5px"}}/> Create Quote
          </Button> :
          <Button className={classes.addCustomer}>
            <AddIcon style={{marginRight:"5px"}}/> Request Rental
          </Button>
        }

      </GridItem>
      <GridItem className={classes.customTabsGridItem} xs={12}>
        <RentalsCustomTabs
          setCurrentTab={setCurrentTab}
          rentalsClasses={classes}
          tabs={[
            {
              tabName: "List",
              tabContent: <ListView classes={classes} history={history} />,
            },
            {
              tabName: "Map",
              tabContent: <MapView classes={classes} history={history} user={user}/>,
            },
            {
              tabName: "Quotes",
              tabContent: <QuotesView classes={classes} history={history} ser={user}/>,
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(Rentals));
