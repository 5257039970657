import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import moment from "moment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { InputAdornment, TextField } from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";

function QuoteType(props) {
  const {
    classes,
    errors,
    history,
    pickUpAddress,
    setPickUpAddress,
    swapDate,
    setSwapDate,
    sevenDayRental,
    setSevenDayRental,
    otherDuration,
    setOtherDuration,
    pickUpDate,
    setPickUpDate,
    handleSelect
  } = props;

  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  const disablePickUpDt = (current) => {
    return current.isAfter(swapDate);
  };

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={3}>
          <span className={classes.inputTypeTitle}>
            Pick Up Address{" "}
            {errors.pickUpAddress && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <GooglePlacesAutoComplete
            classes={classes}
            value={pickUpAddress}
            width={"100%"}
            placeholder="Search your drop-off address.."
            onChange={(e) => setPickUpAddress(e)}
            onSelect={handleSelect}
            margin="0px 0px 20px 0px"
            background="#F5F5F5"
            googleCallbackName="initOne"
          />


        </GridItem>
        <GridItem className={classes.columnGridItem} xs={3}>
          <span className={classes.inputTypeTitle}>
            Requested Swap Date{" "}
            {errors.dropOffDate && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>

          <Datetime
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
            }}
            isValidDate={disablePastDt}
            selected={swapDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              setSwapDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  className={classes.datetime}
                  placeholder="MM/DD/YY"
                  value={swapDate}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />
        </GridItem>
        <GridItem
          className={classes.columnGridItem}
          xs={6}
        >
          <span className={classes.inputTypeTitle}>
            Rental Duration{" "}
            {errors.sevenDayRental && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>

          <div
            style={{
              display: "flex",
              width: "100%",
              marginLeft: "-16px"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <Radio
                checked={sevenDayRental}
                onChange={() => {
                  setPickUpDate(moment(swapDate, "MM/DD/YY").add(7, "days").format("MM/DD/YY"))
                  setSevenDayRental(true)
                  setOtherDuration(false)
                }}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                style={{
                  width: "50px",
                  height: "50px",
                  // borderRadius: "10%",
                  // border: "1px solid #929292",
                }}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span style={{ whiteSpace: "noWrap" }}>7 Day Rental</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Radio
                checked={otherDuration}
                onChange={() => {
                  setOtherDuration(true)
                  setSevenDayRental(false)
                }}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                style={{
                  width: "50px",
                  height: "50px",
                  // borderRadius: "10%",
                  // border: "1px solid #929292",
                }}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span>Other</span>
            </div>

            <div 
            style={{ 
              marginLeft: "45px",
              marginTop:"5px" 
            }}
            >
              {sevenDayRental && swapDate ?
                <TextField
                  disabled
                  className={classes.datetime}
                  placeholder="MM/DD/YY"
                  value={moment(swapDate, "MM/DD/YY").add(7, "days").format("MM/DD/YY")}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                        // onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                :
                <Datetime
                  style={{
                    color: "#323232 !important",
                    height: "100%",
                    width: "100%",
                  }}
                  isValidDate={disablePickUpDt}
                  selected={pickUpDate}
                  dateFormat="MM/DD/YY"
                  onChange={(date, e) => {
                    setPickUpDate(date.format("MM/DD/YY"));
                  }}
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside
                  renderInput={(props, openCalendar, closeCalendar) => (
                    <div onClick={openCalendar}>
                      <TextField
                        style={{}}
                        className={classes.datetime}
                        placeholder="MM/DD/YY"
                        value={pickUpDate}
                        variant="outlined"
                        InputProps={{
                          classes: {
                            adornedEnd: classes.adournedEnd,
                          },
                          endAdornment: (
                            <InputAdornment
                              className={classes.adournedEnd}
                              position="end"
                            >
                              <CalendarIcon
                                className={classes.calendarIcon}
                                onClick={openCalendar}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  )}
                />
              }
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(QuoteType));
