/*eslint-disable*/
import {
  CardElement,
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { NavLink, withRouter, useParams } from "react-router-dom";
import { db, firebase, storage } from "firebase/client";
import compose from "recompose/compose";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import axios from "axios";
import React, { useState, useEffect } from "react";
import "./App.css";
import { chain } from "store/actions/util";
import { push } from "connected-react-router";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import {setUser} from "store/actions/auth"

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#323232",
      fontFamily: "Archivo",
      fontStyle: "normal",
      padding: "0px",
      fontSize: "17px",
      lineHeight: "18px",
      color: "#323232",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#323232" },
      "::placeholder": { color: "#323232" },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
    empty: {
      fontFamily: "Archivo",
      fontStyle: "normal",
      fontSize: "17px",
      lineHeight: "18px",
      color: "#323232",
    },
    complete: {
      fontFamily: "Archivo",
      fontStyle: "normal",
      fontSize: "17px",
      lineHeight: "18px",
      color: "#323232",
    },
  },
};

const PaymentForm = (props) => {
  const {
    user,
    history,
    amount,
    setBillingModal,
    setSnackBarContent,
    invoice,
    invoiceId,
    userInfo,
    secret,
    customerId,
    setUser
  } = props;
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmitUpdateBilling = async (e) => {
    e.preventDefault();
      const customer  = await stripe.confirmCardSetup(secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: userInfo.name,
            email: userInfo.email,
          },
        },
      }, {handleActions: false})
      if (!customer.error) {
        if (customer.setupIntent.status === "succeeded") {
          const uid = firebase.auth().currentUser.uid;
          await db.collection("users").doc(uid).update({
            stripeCustomerId: customerId
          })
          setUser({...userInfo})
          setBillingModal(false)
        } else {
          setSnackBarContent("There was problem with changing your billing information")
        }
      } else {
        setSnackBarContent(customer.error.message)
      }
  };

  return (
    <>
        <fieldset className="FormGroup">
          <div className="FormRow">
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>

        {loading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <button className="closeButton">Close</button>
            <button className="payButton">
              Update Billing
              <img
                style={{ width: "10%", marginLeft: "20px" }}
                src={loader}
                alt="..."
              />
            </button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => setBillingModal(false)}
              className="closeButton"
            >
              Close
            </button>
            <button onClick={handleSubmitUpdateBilling} className="payButton">
              Update Billing
            </button>
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user))
  };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(PaymentForm)
);
