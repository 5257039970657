import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from "firebase/client.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import SiteDetailHeader from "./components/SiteDetailHeader";
import SiteInformation from "./components/SiteInformation";
import loader from "assets/img/loader.gif";
import SiteDetailCustomTabs from "./components/SiteDetailCustomTabs";
import NotesTab from "./components/NotesTab";

function SiteDetail(props) {
  const { classes, history } = props;
  // const [editReservationModal, setEditReservationModal] = useState(false);
  // // const job = props.location.state.job;
  const [site, setSite] = useState(false);
  const [siteOwner, setSiteOwner] = useState(false);
  const [contacts, setContacts] = useState(false);
  const siteID = window.location.pathname.split("/")[3];

  useEffect(() => {
    if (!site) {
      const getSiteInfo = async () => {
        return db
          .collection("sites")
          .doc(siteID)
          .onSnapshot((snapshot) => {
            console.log("snapshot", snapshot, siteID);
            snapshot = snapshot.data();
            const contactsPromiseArray = snapshot.contacts.map((c) =>
              db.collection("customers").doc(c).get()
            );
            Promise.all(contactsPromiseArray).then((results) => {
              const contactsData = results.map((r) => r.data());
              return db
                .collection("customers")
                .doc(snapshot.siteOwner)
                .get()
                .then((siteOwnerSnap) => {
                  const siteOwnerData = siteOwnerSnap.data();
                  setContacts(contactsData);
                  setSiteOwner({ ...siteOwnerData });
                  setSite({ ...snapshot });
                });
            });
          });
      };
      getSiteInfo();
    }
  }, []);

  const handleContacts = () => {
    const docMap = contacts.map((c) => {
      const customerDoc = db.collection("customers").doc(c).get();
      if (customerDoc.exists) {
        return customerDoc.data();
      } else {
        return null;
      }
    });
    return docMap.filter((d) => d);
  }

  useEffect(() => {
    if (contacts.length) {
      setContacts(handleContacts());
    }
  }, [contacts]);

  if (!site || !siteOwner)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );
  return (
    <GridContainer>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <SiteDetailHeader history={history} site={site} siteID={siteID} />
      </GridItem>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <SiteInformation contacts={contacts} siteID={siteID} history={history} site={site} />
      </GridItem>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <SiteDetailCustomTabs
          classes={classes}
          tabs={[
            {
              tabName: "Notes",
              tabContent: <NotesTab site={site} />,
            },
            {
              tabName: "Jobs",
              tabContent: <></>,
            },
            {
              tabName: "Rentals",
              tabContent: <></>,
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(SiteDetail));
