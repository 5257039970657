import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Style from "assets/jss/material-dashboard-pro-react/components/formStyle"
import { OpenMode } from "variables/constants";

/**
 * 
 * A dialog box which contains all the fields specified in a config parameter.
 * 
 */
export function EditorForm(props) {
    const { classes, order, children, Form, FormFields, mode } = props;

    return (
        <Form readOnly={mode === OpenMode.READ_ONLY} classes={classes}>
            <FormFields order={order}>
                { children }
            </FormFields>
        </Form>
    );
}

export default withStyles(Style)(EditorForm);