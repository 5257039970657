import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./Dnd/Sortable";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";

const containerStyle = {
  background: "#dadada",
  padding: 10,
  margin: 10,
  flex: 1,
};

export default function Container(props) {
  const {
    id,
    items,
    classes,
    history,
    width,
    searchString,
    splitSchedule,
    driver,
    setHoveredItemId,
    hoveredItemId
  } = props;
  const { setNodeRef } = useDroppable({
    id,
  });
  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      {/* <div ref={setNodeRef} style={containerStyle}>
        {items.map((id) => (
          <SortableItem key={id} id={id} />
        ))}
      </div> */}

      <div
        style={{ width: width }}
        ref={setNodeRef}
        className={classes.scheduleColumn}
      >
        <div className={classes.scheduleColumnHeader}>
          <span className={classes.scheduleHeaderText}>Unassigned</span>
          <ThreeDots />
        </div>
        {items.map((item, index) => {
          // if (item && item.reservation.driver === "N/A") {
          return (
            <SortableItem
              splitSchedule={splitSchedule}
              searchString={searchString}
              key={item.reservation.reservationId}
              driverItemIndex={index}
              history={history}
              classes={classes}
              r={item || []}
              id={item.reservation.reservationId}
              reservationId={item.reservation.reservationId}
              data={item}
              driverId={id}
              territoryColor={item.reservation.territoryColor}
              setHoveredItemId={setHoveredItemId}
              hoveredItemId={hoveredItemId}
            />
          );
          // }
        })}
      </div>
    </SortableContext>
  );
}
