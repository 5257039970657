import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "views/Dashboard/components/pagination.css";
// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconWhite.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIconBlack.svg";
import { BsFillCircleFill } from "react-icons/bs";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";
import CollapseTable from "./CollapseTable";

const pageArr = ["3", "All"];
const headers = [
    "Product Type",
    "Available",
    "Rented",
    "Out of Service",
    "Total",
    "Rate",
    "Actions",
];

const mockData = [
    {
        productType: "10 Yard Dumpster",
        available: "4",
        rented: "3",
        outOfService: "0",
        total: "7",
        rate: "$300.00"
    },
    {
        productType: "15 Yard Dumpster",
        available: "13",
        rented: "3",
        outOfService: "0",
        total: "26",
        rate: "$325.00"
    },
    {
        productType: "20 Yard Dumpster",
        available: "14",
        rented: "20",
        outOfService: "0",
        total: "34",
        rate: "$300.00"
    },
]

function ProductTable(props) {
    const { classes, history } = props;
    const itemsPerPage = 3;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0)
    const [endIndex, setEndIndex] = useState(itemsPerPage);
    const [pageDropdown, setPageDrowdown] = useState("3");
    const [collapse, setCollapse] = useState(false);
    const [currIndex, setCurrIndex] = useState()


    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage)
        setEndIndex((selected * itemsPerPage) + itemsPerPage)
    };

    useEffect(() => {
        mockData.slice(index, endIndex)
    }, [index])
    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th className={classes.tableHeaders} key={key} style={{ fontSize: "14px" }}>
                            {key}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        let tableData;
        if (pageDropdown == "3") {
            tableData = mockData.slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            );
        }
        else {
            tableData = mockData
        }
        return tableData.map((d, i) => {
            return (
                <>
                    <tr className={classes.tableRow} key={d.name}>
                        <td style={{ background: "#FFFFFF" }} className={classes.tableCell2}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", marginBottom: "10px" }}>
                                    <span
                                        onClick={() => {
                                            setCollapse(!collapse)
                                            setCurrIndex(i)
                                        }}
                                        className={classes.nameAndCompanyText}
                                    >
                                        <Button className={classes.nameAndCompanyText}>
                                            {collapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </Button>
                                        {d.productType}
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td className={classes.tableCell2} style={{ background: "#FFFFFF" }}>
                            {d.available}
                        </td>
                        <td className={classes.tableCell2}>{d.rented}</td>
                        <td className={classes.tableCell2}>{d.outOfService}</td>
                        <td className={classes.tableCell2}>{d.total}</td>
                        <td className={classes.tableCell2}>{d.rate}</td>
                        <td className={classes.tableCell2}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Tooltip
                                    id="tooltip-top"
                                    title="View"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        style={{ background: "#FFC821" }}
                                        className={classes.actionBtn}
                                    >
                                        <EyeIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Edit"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionBtn}
                                        style={{ background: "#323232" }}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Delete"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionBtn}
                                        style={{ background: "#FEDA1B" }}
                                    >
                                        <DenyIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                    <td colSpan={8}>
                        {currIndex === i ?
                            <Collapse in={collapse} timeout="auto" unmountOnExit>
                                <CollapseTable />
                            </Collapse> :
                            null
                        }
                    </td>
                </>
            );
        });
    };

    return (
        <GridContainer >
            <GridItem xs={12}>
                <div style={pageDropdown != 4 && mockData.length > 4 ? { height: "550px", width: "100%", overflowY: "scroll" } : null}>
                    <table 
                    className={classes.inventoryTable} 
                    style={
                        collapse? {marginTop:"20px", marginLeft:"-25px"}
                        :{ marginTop: "20px"}}>
                        <tbody>
                            {renderTableHeader()}
                            {renderTableData()}
                        </tbody>
                    </table>
                </div>
                {pageDropdown == "3" ? (
                    <GridContainer style={{ paddingTop: "10px" }}>
                        <GridItem xs={6}>
                            <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                                View
                                <span>
                                    <Select
                                        style={{
                                            width: "10%",
                                            marginLeft: "10px",
                                            marginTop: "5px",
                                            textAlign: "center",
                                        }}
                                        disableUnderline
                                        MenuProps={
                                            {
                                                // className: classes.selectMenu,
                                            }
                                        }
                                        classes={{
                                            select: classes.select,
                                            icon: classes.whiteColor,
                                        }}
                                        onChange={(e) => {
                                            setPageDrowdown(e.target.value);
                                        }}
                                        value={pageDropdown}
                                    >
                                        {pageArr.map((d, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelectedMultiple,
                                                    }}
                                                    value={d}
                                                >
                                                    {d}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </span>
                            </p>
                        </GridItem>
                        <GridItem xs={4}>
                            <h5
                                style={{
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                {index + 1} - {endIndex} of{" "}
                                {Math.ceil(mockData.length / itemsPerPage)}
                            </h5>
                        </GridItem>
                        <GridItem xs={2}>
                            <ReactPaginate
                                paginationSize={0}
                                onPageChange={handlePageClick}
                                pageCount={Math.ceil(mockData.length / itemsPerPage)}
                                nextLabel={
                                    <ArrowRight
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "white",
                                            marginBottom: "-8px",
                                        }}
                                    />
                                }
                                previousLabel={
                                    <ArrowLeft
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "white",
                                            marginBottom: "-8px",
                                        }}
                                    />
                                }
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </GridItem>
                    </GridContainer>
                ) : (
                    <GridContainer>
                        <GridItem xs={6}>
                            <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                                View
                                <span>
                                    <Select
                                        style={{
                                            width: "10%",
                                            marginLeft: "10px",
                                            marginTop: "5px",
                                            textAlign: "center",
                                        }}
                                        disableUnderline
                                        MenuProps={
                                            {
                                                // className: classes.selectMenu,
                                            }
                                        }
                                        classes={{
                                            select: classes.select,
                                            icon: classes.whiteColor,
                                        }}
                                        onChange={(e) => {
                                            setPageDrowdown(e.target.value);
                                        }}
                                        value={pageDropdown}
                                    >
                                        {pageArr.map((d, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelectedMultiple,
                                                    }}
                                                    value={d}
                                                >
                                                    {d}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </span>
                            </p>
                        </GridItem>
                    </GridContainer>
                )}
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(customTableStyle)
)(React.memo(ProductTable));
