const rentalsStyles = (theme) => ({
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px",
  },
  buttonGridItem: {
    display: "flex",
    justifyContent: "end",
  },
  addCustomer: {
    textTransform: "capitalize",
    whiteSpace: "noWrap",
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#323232",
    padding: "12px",
    width: "29%",
    display: "flex",
    justifyContent: "space-evenly",
    "&:hover": {
      background: "#FFC720",
    },
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
  },
  displayNone: {
    display: "none !important",
    backgroundColor: "transparent"
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "65px 0px",
  },
  subHeader2: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "26px",
    color: "#323232",
    margin: "30px 0px",
    whiteSpace: "noWrap"
  },
  tabWrapper: {
    color: "#323232 !important",
    width: "100px !important",
  },
  tabSelected: {
    background: "#FEDA1B",
    borderWidth: "0.5px 0.5px 0px 0.5px",
    borderStyle: "solid",
    borderColor: "#8E8E8E",
    borderRadius: "2px 2px 0px 0px",
  },
  customTabsGridItem: {
    marginTop: "15px",
  },
  card: {
    padding: "10px 30px 30px 30px",
    marginTop: "0px",
  },
  selectMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "50%",
    height: "50px",
    marginRight: "15px",
    padding: "10px",
  },
  selectText: {
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    fontWeight: 400,
    fontFamily: "Archivo",
  },
  ascendingMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "10%",
    height: "50px",
    marginRight: "15px",
    padding: "10px",
  },
  filterMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    margin: "0px 15px 0px 10px",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "19px",
    textAlign: "center"
  },
  filtersGridItem: {
    display: "flex",
    alignItems: "center",
  },
  filtersGridItemWithCsvDownload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  searchBar: {
    width: "60%",
    background: "white",
    height: "40px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height: "40px"
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "8px 14px !important",
    height: "40px",
    width: "120px",
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    "&:hover": {
      background: "#FFC720",
    },
  },
  rentalsSubheader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    paddingBottom: "10px",
    whiteSpace: "noWrap",
  },
  googleMapGridItem: {
    height: "500px",
  },
  noPaddingGridItem: {
    padding: "0px 0px 0px 15px !important",
    display: "flex",
    alignItems: "center",
  },
  exportCsvGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  select: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    background: "#323232",
    "&.MuiSelect-select:focus ": {
      background: "#323232",
      color: "white",
      fontWeight: "bold",
      borderRadius: "5px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid red",
    },
  },
  whiteColor: {
    color: "white",
  },
  selectMenuSort: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 10px",
  },
  ascMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "20%",
    height: "50px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0px 15px 0px 10px",
  },
  indexes: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
  view: {
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },
  selectAll: {
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "19px",
  },
  tableCell: {
    textAlign: "left",
    border: "0.5px solid #8E8E8E",
    padding: "8px 10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    [theme.breakpoints.down("md")]: {
      padding: "9px",
      fontSize: "15px",
    },
  },
  tableCell2: {
    textAlign: "left",
    border: "0.5px solid #8E8E8E",
    padding: "7px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    [theme.breakpoints.down("md")]: {
      padding: "9px",
      fontSize: "15px",
    },
  },
  datetime: {
    marginLeft: "10px",
    background: "white",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    width: "90%",
    position: "relative",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "text",
    display: "inline-flex",
    fontSize: "1rem",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: "Archivo",
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    border: "1px solid lightgray",
  },
  calendarIcon: {
    marginRight: "10px",
    height: "18px"
  },
  selectMenuItem: {
    height: "100%"
  },
  dateRange: {
    fontFamily: "Archivo",
    fontWeight: 400,
    lineHeight: "18.5px",
    fontSize: "17px",
    margin: "5px 10px"
  },
  actionBtn: {
    midWidth: "42px",
    marginRight: "8px"
  },
  menuPaper: {
    height: "80% !important"
  }
});

export default rentalsStyles;
