export const filterData = (filterCategory, direction, data) => {
  const lowerCaseCategory = filterCategory.toLowerCase();
  console.log("lowerCaseCategory: ", lowerCaseCategory);
  if (direction === "Desc") {
    if (filterCategory === "First Name") {
      const sorted = data.sort((a, b) =>
        b.name
          .split(" ")[0]
          .toLowerCase()
          .localeCompare(a.name.split(" ")[0].toLowerCase())
      );
      return sorted;
    } else if (filterCategory === "Last Name") {
      const sorted = data.sort((a, b) =>
        b.name
          .split(" ")[1]
          .toLowerCase()
          .localeCompare(a.name.split(" ")[1].toLowerCase())
      );
      return sorted;
    } else {
      const sorted = data.sort((a, b) =>
        b[lowerCaseCategory].localeCompare(a[lowerCaseCategory])
      );
      return sorted;
    }
  }
  if (direction === "Asc") {
    if (filterCategory === "First Name") {
      const sorted = data.sort((a, b) =>
        a.name
          .split(" ")[0]
          .toLowerCase()
          .localeCompare(b.name.split(" ")[0].toLowerCase())
      );
      return sorted;
    } else if (filterCategory === "Last Name") {
      const sorted = data.sort((a, b) =>
        a.name
          .split(" ")[1]
          .toLowerCase()
          .localeCompare(b.name.split(" ")[1].toLowerCase())
      );
      return sorted;
    } else {
      const sorted = data.sort((a, b) =>
        a[lowerCaseCategory].localeCompare(b[lowerCaseCategory])
      );
      console.log("filter");
      return sorted;
    }
  }
};

export const filterDataWithComments = (
  filterCategory,
  direction,
  statusSelection,
  data
) => {
  const lowerCaseCategory = filterCategory.toLowerCase();
  console.log("lowerCaseCategory: ", lowerCaseCategory);
  if (direction === "Desc") {
    if (filterCategory === "First Name") {
      console.log("checking data:", data)
      let sorted = data.sort((a, b) =>
        b.name
          .split(" ")[0]
          .toLowerCase()
          .localeCompare(a.name.split(" ")[0].toLowerCase())
      );
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.comments.includes(statusSelection)) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    } else if (filterCategory === "Last Name") {
      const sorted = data.sort((a, b) =>
        b.name
          .split(" ")[1]
          .toLowerCase()
          .localeCompare(a.name.split(" ")[1].toLowerCase())
      );
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.comments.includes(statusSelection)) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    } else {
      const sorted = data.sort((a, b) =>
        b[lowerCaseCategory].localeCompare(a[lowerCaseCategory])
      );
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.comments.includes(statusSelection)) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    }
  }
  if (direction === "Asc") {
    if (filterCategory === "First Name") {
      const sorted = data.sort((a, b) =>
        a.name
          .split(" ")[0]
          .toLowerCase()
          .localeCompare(b.name.split(" ")[0].toLowerCase())
      );
      if (statusSelection !== "All") {
        console.log("sorted", sorted);
        const filteredByStatuses = sorted.filter((d) => {
          if (d.comments.includes(statusSelection)) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    } else if (filterCategory === "Last Name") {
      const sorted = data.sort((a, b) =>
        a.name
          .split(" ")[1]
          .toLowerCase()
          .localeCompare(b.name.split(" ")[1].toLowerCase())
      );
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.comments.includes(statusSelection)) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    } else {
      const sorted = data.sort((a, b) =>
        a[lowerCaseCategory].localeCompare(b[lowerCaseCategory])
      );
      console.log("filter");
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.comments.includes(statusSelection)) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    }
  }
};

export const filterDataWithStatus = (
  filterCategory,
  direction,
  statusSelection,
  data
) => {
  const lowerCaseCategory = filterCategory.toLowerCase();
  console.log(
    "lowerCaseCategory: ",
    filterCategory,
    direction,
    statusSelection,
    data
  );
  if (direction === "Desc") {
    if (filterCategory === "First Name") {
      let sorted = data.sort((a, b) =>
        b.name
          .split(" ")[0]
          .toLowerCase()
          .localeCompare(a.name.split(" ")[0].toLowerCase())
      );
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.reservation.status === statusSelection) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    } else if (filterCategory === "Last Name") {
      const sorted = data.sort((a, b) =>
        b.name
          .split(" ")[1]
          .toLowerCase()
          .localeCompare(a.name.split(" ")[1].toLowerCase())
      );
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.reservation.status === statusSelection) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    } else {
      const sorted = data.sort((a, b) =>
        b[lowerCaseCategory].localeCompare(a[lowerCaseCategory])
      );
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.reservation.status === statusSelection) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    }
  }
  if (direction === "Asc") {
    if (filterCategory === "First Name") {
      const sorted = data.sort((a, b) =>
        a.name
          .split(" ")[0]
          .toLowerCase()
          .localeCompare(b.name.split(" ")[0].toLowerCase())
      );
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.reservation.status === statusSelection) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    } else if (filterCategory === "Last Name") {
      const sorted = data.sort((a, b) =>
        a.name
          .split(" ")[1]
          .toLowerCase()
          .localeCompare(b.name.split(" ")[1].toLowerCase())
      );
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.reservation.status === statusSelection) {
            return true;
          }
        });
        console.log("sorted", sorted);
        return filteredByStatuses;
      } else {
        console.log("sorted return", sorted);
        return sorted;
      }
    } else {
      const sorted = data.sort((a, b) =>
        a[lowerCaseCategory].localeCompare(b[lowerCaseCategory])
      );
      console.log("filter");
      if (statusSelection !== "All") {
        const filteredByStatuses = sorted.filter((d) => {
          if (d.reservation.status === statusSelection) {
            return true;
          }
        });
        return filteredByStatuses;
      } else {
        return sorted;
      }
    }
  }
};
