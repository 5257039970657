import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import BillToCard from "./BillTo";
import InvoiceDetailsCard from "./InvoiceDetailsCard";
import ServicesCard from "./ServicesCard";
import TermsAndConditions from "./TermsAndConditions";
import PaymentsCard from "./PaymentsCard";
import { db } from "firebase/client";

function InvoiceDetails(props) {
  const { classes, invoice, history, reservation, customer } = props;
  const [servicesSubTotal, setServicesSubTotal] = useState("");
  const [servicesTotal, setServicesTotal] = useState("");
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState("$0.00");
  const [payment, setPayment] = useState(false);

  useEffect(() => {
    let subtotal = 0;
    invoice.services.forEach((s) => {
      const parsedTotal = parseFloat(s.rate);
      subtotal += parsedTotal;
    });
    setServicesSubTotal(subtotal);
    setServicesTotal(subtotal + taxTotal);
    if (invoice.invoiceDetails.paymentId) {
      console.log("invoice data", invoice.invoiceDetails.paymentId);
      const getPayment = async () => {
        await db
          .collection("payments")
          .doc(invoice.invoiceDetails.paymentId)
          .get()
          .then((snapshot) => {
            const data = snapshot.data();
            console.log("invoice data", data);
            setPayment(data);
          });
      };
      getPayment();
    }
  }, [invoice]);
  console.log("payment", payment);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <BillToCard
          customer={customer}
          reservation={reservation}
          invoice={invoice}
          history={history}
        />
      </GridItem>

      <GridItem className={classes.invoiceGridItem} xs={12}>
        <InvoiceDetailsCard
          customer={customer}
          reservation={reservation}
          invoice={invoice}
          history={history}
        />
      </GridItem>

      <GridItem className={classes.invoiceGridItem} xs={12}>
        <ServicesCard
          servicesTotal={servicesTotal}
          taxTotal={taxTotal}
          servicesSubTotal={servicesSubTotal}
          invoice={invoice}
          history={history}
          customer={customer}
          reservation={reservation}
        />
      </GridItem>

      <GridItem className={classes.invoiceGridItem} xs={12}>
        <PaymentsCard payment={payment} invoice={invoice} history={history} />
      </GridItem>

      <GridItem className={classes.invoiceGridItem} xs={12}>
        <TermsAndConditions />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(InvoiceDetails));
