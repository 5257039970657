import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getNextNine,
  getPrevFourteen,
  getDates,
  getNewDataFromCalendarSelection,
  getRangeBetweenTwoDates,
} from "../helpers/helpers";
import Datetime from "react-datetime";
import moment from "moment";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import JobsTable from "./JobsTable";
import JobsFilters from "./JobsFilters";

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

function Jobs(props) {
  const { classes, reservation, history, jobsData } = props;
  const [jobsDataState, setJobsDataState] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);


  useEffect(() => {
    if (!dataLoaded) {
      let delivery;
      let pickup;
      if (reservation.reservation && reservation.reservation.jobs) {
        delivery = reservation.reservation.jobs.delivery;
        pickup = reservation.reservation.jobs.pickup;
      }
      const jobsArray = [];
      if (delivery && delivery.jobId) {
        const deliveryJob = jobsData.filter(
          (d) => d.jobId === delivery.jobId
        )[0];
        jobsArray.push(deliveryJob);
      }
      if (pickup && pickup.jobId) {
        const pickupJob = jobsData.filter((d) => d.jobId === pickup.jobId)[0];
        jobsArray.push(pickupJob);
      }

      setJobsDataState(jobsArray);
      setDataLoaded(true);
    }
  }, [reservation]);

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.jobsHeaderGridItem} xs={12}>
          <h1 className={classes.title}>Jobs</h1>
        </GridItem>
        <GridItem xs={12}>
          <JobsFilters history={history}/>
        </GridItem>
        <GridItem xs={12}>
          <JobsTable
            jobsData={jobsDataState}
            reservation={reservation}
            classes={classes}
            history={history}
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(Jobs));
