import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import settingsStyle from "assets/jss/material-dashboard-pro-react/views/settingsStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Tooltip from "@material-ui/core/Tooltip";
import { MenuItem, Select } from "@material-ui/core";
import { CSVLink, CSVDownload } from "react-csv";

// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";
import { ReactComponent as SwapIcon } from "assets/img/swapIcons.svg";
import { ReactComponent as PickUpIcon } from "assets/img/pickupIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { ReactComponent as InfoIcon } from "assets/img/infoIcon.svg";
import { ReactComponent as BlackInfoIcon } from "assets/img/blackInfoIcon.svg";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import CompanyTable from "./CompanyTable";


const headers = ["Name", "Phone Number", "Email", "Actions"];

const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Role", key: "role" },
];

const pageArr = ["5", "All"];

function CompanyTab(props) {
  const { classes, history, userInfo, company, companyId, staff, setStaff, staffData } = props;
  const companyPicRef = createRef(null);
  const [savingPic, setSavingPic] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(company.companyLogo);
  const [snackbarContent, setSnackBarContent] = useState("");
  const [searchString, setSearchString] = useState("");
  const uid = firebase.auth().currentUser.uid;

  console.log("companyLogo", companyLogo);
  useEffect(() => {
    setCompanyLogo(company.companyLogo || "");
  }, [company]);

  const itemsPerPage = 5;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("5")

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)
  };

  useEffect(() => {
    staff.slice(index, endIndex)
  }, [index])

  const handleProfilePictureChange = (event) => {
    const storageRef = storage.ref().child(`/companyLogos/${companyId}/`);
    setSavingPic(true);
    const file = event.target.files[0];
    return storageRef.put(file).then(() => {
      storageRef.getDownloadURL().then((url) => {
        return db
          .collection("companies")
          .doc(companyId)
          .update({ companyLogo: url })
          .then(() => {
            setCompanyLogo(url);
            setSavingPic(false);
            setSnackBarContent("Successfully Uploaded Company Logo");
          });
      });
    });
  };

  const handleProfilePicDelete = () => {
    const storageRef = storage.ref().child(`/companyLogos/${companyId}/`);
    storageRef.delete().then(() => {
      return db
        .collection("companies")
        .doc(companyId)
        .update({
          companyLogo: "",
        })
        .then(() => {
          setCompanyLogo("");
          setSnackBarContent("Successfully Deleted Company Logo");
        });
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card className={classes.card}>
          <GridContainer>
            <GridItem className={classes.cardGridItem} xs={6}>
              <span className={classes.sectionTitle}>Basic Information</span>
              <div
                style={{
                  display: "flex",
                  width: "85%",
                  flexDirection: "column",
                  marginTop: "30px",
                }}
              >
                <div className={classes.basicInfoDiv}>
                  <span className={classes.basicInfoCategory}>
                    Contact Name
                  </span>
                  <span className={classes.profileValues}>
                    {company && company.contact ? company.contact : "N/A"}
                  </span>
                </div>
                <div className={classes.basicInfoDiv}>
                  <span className={classes.basicInfoCategory}>Company</span>
                  <span className={classes.profileValues}>
                    {company && company.companyName
                      ? company.companyName
                      : "N/A"}
                  </span>
                </div>
                <div className={classes.basicInfoDiv}>
                  <span className={classes.basicInfoCategory}>Website</span>
                  <span className={classes.profileValues}>
                    {company && company.website ? company.website : "N/A"}
                  </span>
                </div>
                <div className={classes.basicInfoDiv}>
                  <span className={classes.basicInfoCategory}>
                    Phone (Primary)
                  </span>
                  <span className={classes.profileValues}>
                    {company && company.primaryPhone
                      ? company.primaryPhone
                      : "N/A"}
                  </span>
                </div>
              </div>
            </GridItem>
            <GridItem className={classes.columnGridItem} xs={6}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <span className={classes.sectionTitle}>Physical Address</span>
                <Button
                  onClick={() =>
                    history.push({
                      pathname: "/admin/edit-company",
                      state: {
                        company: company,
                      },
                    })
                  }
                  className={classes.addCustomer}
                >
                  <EditIcon className={classes.editIcon} /> Edit Company
                  Information
                </Button>
              </div>
              <span className={classes.profileValues}>
                {company && company.physicalAddress
                  ? company.physicalAddress
                  : "N/A"}
              </span>
              <span className={classes.profileValues}>
                Your company's physical address is used for route planning and
                schedules.
              </span>

              <span className={classes.sectionTitle}>Mailing Address</span>
              <span className={classes.profileValues}>
                {company && company.mailingAddress
                  ? company.mailingAddress
                  : "N/A"}
              </span>
              <span className={classes.profileValues}>
                Your company's mailing address appears on company invoices and
                estimates.
              </span>
            </GridItem>
            <GridItem className={classes.cardGridItem} xs={12}>
              <span className={classes.sectionTitle}>Logo</span>
              <GridContainer>
                <GridItem className={classes.cardGridItem} xs={6} style={{ marginTop: "15px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: "30px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ width: "30%" }}
                      onClick={() => {
                        if (!company) {
                          setSnackBarContent(
                            "You must create a company before you upload a profile picture"
                          );
                        } else {
                          companyPicRef.current.click();
                        }
                      }}
                    >
                      <input
                        onChange={handleProfilePictureChange}
                        type="file"
                        ref={companyPicRef}
                        style={{ display: "none" }}
                      />
                      {savingPic ? (
                        <img
                          style={{ width: "60%", height: "60%" }}
                          src={loader}
                          alt="..."
                        />
                      ) : companyLogo ? (
                        <img style={{ maxWidth: "200px" }} src={companyLogo} />
                      ) : (
                        <BiggerLogo />
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "70%",
                      }}
                    >
                      <span className={classes.uploadPhoto}>
                        Upload your company logo.
                      </span>
                      <span className={classes.uploadPhotoSize}>
                        Image should be at least 300px x 300px. This logo will
                        appear on company invoices and emails.
                      </span>
                    </div>
                  </div>
                </GridItem>
                <GridItem className={classes.flex} xs={6}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      style={{
                        background: "#FFC720",
                        color: "#323232",
                        width: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        margin: "10px",
                        fontWeight: 700
                      }}
                      className={classes.btn}
                      onClick={() => {
                        companyPicRef.current.click();
                      }}
                    >
                      <Upload />
                      Upload
                    </Button>
                    <Button
                      style={{
                        background: "#323232",
                        color: "white",
                        width: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        margin: "10px",
                        fontWeight: 700
                      }}
                      onClick={handleProfilePicDelete}
                      className={classes.btn}
                    >
                      <DenyIcon />
                      Delete
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>

      {/* <GridItem xs={12}>
        <Card className={classes.card}>
          <GridContainer>

            <GridItem xs={6}>
              <p className={classes.sectionTitle}>
                {`${staff.length} ${company.companyName} Staff Members`}{" "}
              </p>
            </GridItem>

            <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => history.push("/admin/add-staff")}
                className={classes.addCustomer}
              >
                <AddIcon className={classes.editIcon} /> Invite Staff
              </Button>
            </GridItem>

            <GridItem xs={8} />
            <GridItem xs={4} style={{ display: "flex", justifyContent: "flex-end", margin: "20px 0px 20px 0px" }}>
              <TextField
                className={classes.userSearchBar}
                placeholder="Search Staff"
                value={searchString}
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchString(value);
                  if (!value) {
                    setStaff(staffData);
                  } else {
                    setStaff((prevState) => [
                      ...staffData.filter(
                        (member) =>
                          member.firstName.toLowerCase().includes(value) ||
                          member.lastName.toLowerCase().includes(value) ||
                          member.name.toLowerCase().includes(value)
                      ),
                    ]);
                  }
                }}
                variant="outlined"
                InputProps={{
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <Button className={classes.searchButton}>Search</Button>
                    </InputAdornment>
                  ),
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <CompanyTable
                history={history}
                staff={staff}
                pageDropdown={pageDropdown}
                itemsPerPage={itemsPerPage}
                pageNumber={pageNumber}
              />
              {pageDropdown == "5" ?
                <GridContainer>
                  <GridItem xs={6} >
                    <p className={classes.view}>View
                      <span>
                        <Select
                          style={{ width: "20%", marginLeft: "10px", marginTop: "10px", textAlign: "center" }}
                          disableUnderline
                          MenuProps={{
                            // className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                            icon: classes.whiteColor
                          }}

                          onChange={(e) => {
                            setPageDrowdown(e.target.value)
                          }}
                          value={pageDropdown}
                        >
                          {pageArr.map((d, index) => {
                            return (
                              <MenuItem
                                key={index}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }}
                                value={d}
                              >
                                {d}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </span>
                    </p>
                  </GridItem>
                  <GridItem xs={4} style={{ marginTop: "10px" }}>
                    <h5 className={classes.indexes}>{index + 1} - {endIndex} of
                      {staff.length < itemsPerPage ? " " + staff.length :
                        " " + Math.ceil(staff.length / itemsPerPage)}
                    </h5>
                  </GridItem>
                  <GridItem xs={2} style={{ marginTop: "10px" }}>
                    <ReactPaginate
                      paginationSize={0}
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(staff.length / itemsPerPage)}
                      nextLabel={<ArrowRight style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                      previousLabel={<ArrowLeft style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </GridItem>
                </GridContainer>
                :
                <GridContainer>
                  <GridItem xs={6}>
                    <p className={classes.view}>View
                      <span>
                        <Select
                          style={{ width: "20%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                          disableUnderline
                          MenuProps={{
                            // className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                            icon: classes.whiteColor
                          }}
                          onChange={(e) => {
                            setPageDrowdown(e.target.value)
                          }}
                          value={pageDropdown}
                        >
                          {pageArr.map((d, index) => {
                            return (
                              <MenuItem
                                key={index}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }}
                                value={d}
                              >
                                {d}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </span>
                    </p>
                  </GridItem>
                </GridContainer>
              }
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem> */}

      <Snackbar open={snackbarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackbarContent.includes("Successfully") ? "success" : "error"
          }
        >
          {snackbarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    staffData: state.company.staff,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(settingsStyle)
)(React.memo(CompanyTab));
