import BoundDialog from "components/Dialog/BoundDialog";
import BoundForm from "components/Form/BoundForm";
import BoundChipArray from "components/Chip/BoundChipArray";
import BoundQueryFields from "./BoundQueryFields";
import SearchDialog from "./SearchDialog";
import { connect } from "react-redux";


function mapStateToProps(actionsForExplorer, accessorsForExplorer) { 

    const Dialog = BoundDialog(actionsForExplorer.queryDialog, accessorsForExplorer.queryDialog);
    const Form = BoundForm(actionsForExplorer.queryForm, accessorsForExplorer.queryForm);
    const FormFields = BoundQueryFields(actionsForExplorer.queryForm, accessorsForExplorer.queryForm);
    const SortChips = BoundChipArray(actionsForExplorer.queryForm, accessorsForExplorer.queryForm);
    
    return (_state)=> {
        return {
            Dialog,
            Form,
            FormFields,
            SortChips
        }
    };
}

function mapDispatchToProps(_actions){
    return (_dispatch) => {
        return {
            // nothing yet
        };
    }
}

export default (actions, accessors)=>connect(mapStateToProps(actions, accessors), mapDispatchToProps(actions))(SearchDialog);