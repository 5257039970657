/* eslint-disable react/prop-types */
import React, { useState, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";
// core components
import { storage } from "firebase/client";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Datetime from "react-datetime";
import withStyles from "@material-ui/core/styles/withStyles";
import rentalFormStyle from "assets/jss/material-dashboard-pro-react/components/rentalFormStyle.js";
import loader from "assets/img/loader.gif";
import Card from "components/Card/Card";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

const chargesMock = [
  {
    name: "Charge 1",
  },
  {
    name: "Charge 2",
  },
  {
    name: "Charge 3",
  },
  {
    name: "Charge 4",
  },
  {
    name: "Charge 5",
  },
  {
    name: "Charge 6",
  },
];

function DeliveryJobCard(props) {
  const {
    classes,
    drivers,
    setSavingImage,
    savingImage,
    setAttachments,
    attachments,
    newRentalId,
    inventory,
    selectedInventory,
    setSelectedInventory,
    setDriver,
    setForm,
    form,
    errors,
  } = props;

  const attachmentFileRef = createRef(null);

  const handleImageUpload = (event) => {
    setSavingImage(true);
    var array = [];
    Array.from(event.target.files).forEach((file) => {
      array = [...array, file];
    });
    const storagePromiseArray = [];
    const downloadRefArray = [];
    const downloadRefPromiseArray = [];
    const finalArrayOfNewAttachments = [];
    array.forEach((file) => {
      var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      storagePromiseArray.push(
        storage
          .ref()
          .child(`invoiceAttachments/${newRentalId}/${file.name}`)
          .put(file)
      );
      downloadRefArray.push(`invoiceAttachments/${newRentalId}/${file.name}`);
      finalArrayOfNewAttachments.push({
        fileName: file.name,
        dateUploaded: new Date(),
        fileSizeInMB: sizeInMB,
      });
    });
    Promise.all(storagePromiseArray).then(() => {
      downloadRefArray.forEach((s) => {
        downloadRefPromiseArray.push(storage.ref().child(s));
      });
      Promise.all(downloadRefPromiseArray).then((results) => {
        const downloadUrlPromiseArray = [];
        results.forEach((r) => {
          downloadUrlPromiseArray.push(r.getDownloadURL());
        });
        Promise.all(downloadUrlPromiseArray).then((res) => {
          res.forEach((url, index) => {
            finalArrayOfNewAttachments[index].fileUrl = url;
          });
          setAttachments(finalArrayOfNewAttachments);
          setSavingImage(false);
        });
      });
    });
  };

  const handleSelectChange = (item, amount) => {
    setSelectedInventory((prevState) => {
      const newState = { ...prevState };
      newState[item] = amount;
      return newState;
    });
  };

  const handleTextChange = (item, value) => {
    setForm((prevState) => {
      const newState = { ...prevState };
      newState[item] = value;
      return newState;
    });
  };

  const handleCheckChange = (item) => {
    setForm((prevState) => {
      const newState = { ...prevState};
      newState[item] = !newState[item];
      return newState;
    });
  };

  const selectMap = (amount) => {
    const returnArr = [];
    for (let i = 0; i <= amount; i++) {
      returnArr.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return returnArr;
  };

  return (
    <Card className={classes.invoiceCard}>
      <span style={{ fontSize: "30px", fontWeight: 800, marginBottom: "1rem" }}>
        Delivery Job
        {errors.inventory && (
          <span style={{ color: "red", marginLeft: "10px" }}>Required*</span>
        )}
      </span>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={3}>
          <div className={classes.columnHeader}>
            <span>Product Type</span>
          </div>
          <div className={classes.columnDiv}>
            <span>10 Yard Dumpster</span>
            <span>{inventory.tenYard} available on drop off date</span>
          </div>
          <div className={classes.columnDiv}>
            <span>15 Yard Dumpster</span>
            <span>{inventory.fifteenYard} available on drop off date</span>
          </div>
          <div className={classes.columnDiv}>
            <span>20 Yard Dumpster</span>
            <span>{inventory.twentyYard} available on drop off date</span>
          </div>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={3}>
          <div className={classes.columnHeader}>
            <span>Quantity</span>
          </div>
          <div className={classes.columnSelectDiv}>
            <Select
              value={selectedInventory.tenYard}
              onChange={(e) => handleSelectChange("tenYard", e.target.value)}
            >
              {selectMap(inventory.tenYard)}
            </Select>
            <div style={{ marginLeft: "0.5rem" }}>of {inventory.tenYard}</div>
          </div>
          <div className={classes.columnSelectDiv}>
            <Select
              value={selectedInventory.fifteenYard}
              onChange={(e) =>
                handleSelectChange("fifteenYard", e.target.value)
              }
            >
              {selectMap(inventory.fifteenYard)}
            </Select>
            <div style={{ marginLeft: "0.5rem" }}>
              of {inventory.fifteenYard}
            </div>
          </div>
          <div className={classes.columnSelectDiv}>
            <Select
              value={selectedInventory.twentyYard}
              onChange={(e) => handleSelectChange("twentyYard", e.target.value)}
            >
              {selectMap(inventory.twentyYard)}
            </Select>
            <div style={{ marginLeft: "0.5rem" }}>
              of {inventory.twentyYard}
            </div>
          </div>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={3}>
          <div className={classes.columnHeader}>
            <span>Product Type</span>
          </div>
          <div className={classes.columnDiv}>
            <span>30 Yard Dumpster</span>
            <span>{inventory.thirtyYard} available on drop off date</span>
          </div>
          <div className={classes.columnDiv}>
            <span>METAL / CONCRETE</span>
            <span>{inventory.metalOrConcrete} available on drop off date</span>
          </div>
          <div className={classes.columnDiv}>
            <span>Standard 30 Yard Dumpster</span>
            <span>{inventory.standardThirty} available on drop off date</span>
          </div>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={3}>
          <div className={classes.columnHeader}>
            <span>Quantity</span>
          </div>
          <div className={classes.columnSelectDiv}>
            <Select
              value={selectedInventory.thirtyYard}
              onChange={(e) => handleSelectChange("thirtyYard", e.target.value)}
            >
              {selectMap(inventory.thirtyYard)}
            </Select>
            <div style={{ marginLeft: "0.5rem" }}>
              of {inventory.thirtyYard}
            </div>
          </div>
          <div className={classes.columnSelectDiv}>
            <Select
              value={selectedInventory.metalOrConcrete}
              onChange={(e) =>
                handleSelectChange("metalOrConcrete", e.target.value)
              }
            >
              {selectMap(inventory.metalOrConcrete)}
            </Select>
            <div style={{ marginLeft: "0.5rem" }}>
              of {inventory.metalOrConcrete}
            </div>
          </div>
          <div className={classes.columnSelectDiv}>
            <Select
              value={selectedInventory.standardThirty}
              onChange={(e) =>
                handleSelectChange("standardThirty", e.target.value)
              }
            >
              {selectMap(inventory.standardThirty)}
            </Select>
            <div style={{ marginLeft: "0.5rem" }}>
              of {inventory.standardThirty}
            </div>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ marginBottom: "2rem" }}>
        <GridItem xs={3}>
          <div className={classes.columnHeader}>
            <span>Driver Notes (optional)</span>
          </div>
          <div className={classes.columnDiv}>
            <TextField
              style={{ border: "1px solid #929292" }}
              multiline
              rows={4}
              value={form.notes}
              onChange={(e) => handleTextChange("notes", e.target.value)}
            />
          </div>
        </GridItem>
        <GridItem xs={2}>
          <div className={classes.columnHeader}>
            <span>Miscellaneous Charges</span>
          </div>
          <div className={classes.columnDiv}>
            <Autocomplete
              options={chargesMock}
              classes={{
                option: classes.option,
              }}
              onChange={(e, value) => {
                handleTextChange("charge", value);
              }}
              getOptionLabel={(c) => (c.name ? c.name : "No Name Found")}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <SearchIcon
                          className={classes.calendarIcon}
                          // onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </GridItem>
        <GridItem xs={2}>
          <div className={classes.columnHeader}>
            <span>
              Driver{" "}
              {errors.driver && (
                <span style={{ color: "red", marginLeft: "10px" }}>
                  Required*
                </span>
              )}
            </span>
          </div>
          <Select
            onChange={(e) => {
              handleTextChange("driver", e.target.value);
              setDriver(e.target.value);
            }}
            fullWidth
          >
            {drivers.map((driver) => {
              return (
                <MenuItem key={driver.driverId} value={driver}>
                  {driver.driver}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem xs={3}>
          <div className={classes.columnHeader}>
            <span>
              Date
              {errors.invoiceDate && (
                <span style={{ color: "red", marginLeft: "10px" }}>
                  Required*
                </span>
              )}
            </span>
          </div>
          <Datetime
            selected={form.invoiceDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              handleTextChange("invoiceDate", date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  // className={classes.datetime}
                  style={{ width: "100%" }}
                  value={form.invoiceDate}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />
        </GridItem>
        <GridItem xs={2}>
          <div className={classes.columnHeader} />
          <div>
            <FormControlLabel
              style={{
                color: "black",
              }}
              control={
                <Checkbox
                  checked={form.emergencyJob}
                  onChange={() => handleCheckChange("emergencyJob")}
                />
              }
              label="Emergency Job"
            />
          </div>
          <div>
            <FormControlLabel
              style={{
                color: "black",
              }}
              control={
                <Checkbox
                  checked={form.recurringJob}
                  onChange={() => handleCheckChange("recurringJob")}
                />
              }
              label="Recurring Job"
            />
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {savingImage ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img style={{ width: "20%" }} src={loader} alt="..." />
          </div>
        ) : (
          <>
            <GridItem xs={8}>
              <div className={classes.columnHeader}>
                <span>Attachments</span>
              </div>
              <div>
                <span>
                  Here are a few things to keep in mind before attaching files:
                </span>
                <ul style={{ margin: 0 }}>
                  <li>Each individual file must be less than 6MB in size</li>
                  <li>
                    For documents that will be sent as email attachments, such as
                    when you send an invoice, estimate or credit memo by email, the
                    combined size of all attachments together must be less than 20MB
                  </li>
                </ul>
              </div>
            </GridItem>
            <GridItem xs={2} />
            <GridItem xs={2}>
              <Button
                onClick={() => {
                  attachmentFileRef.current.click();
                }}
                style={{ height: "50px" }}
                className={classes.addCustomer}
              >
                <input
                  onChange={(event) => {
                    handleImageUpload(event);
                  }}
                  multiple="multiple"
                  type="file"
                  id="attachmentInput"
                  ref={attachmentFileRef}
                  style={{ display: "none" }}
                />
                <AddIcon />
                <span style={{ marginLeft: "10px" }}>Add Attachment</span>
              </Button>
            </GridItem>
            <GridItem xs={12} style={{ marginTop: "1rem" }}>
              {attachments.map((a, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "0px 30px",
                    }}
                    key={index}
                  >
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={a.fileUrl}
                    />
                    <span className={classes.fileName}>{a.fileName}</span>
                    <span className={classes.fileDetails}>
                      {moment(a.dateUploaded).format("MM/DD/YY, hh:mm A")}{" "}
                    </span>
                  </div>
                );
              })}
            </GridItem>
          </>
        )}
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationData: state.reservations.reservations,
    sites: state.sites.sites,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalFormStyle)
)(React.memo(DeliveryJobCard));
