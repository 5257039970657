import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import StepOne from "./StepOne.jsx";
import StepTwo from "./StepTwo.jsx";
import StepThree from "./StepThree.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { addPendingReservationExistingCustomer } from "store/actions/reservations/reservationsActions";
import { getOneWeekLater } from "../../Dashboard/helpers/helpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import pointInPolygon from "point-in-polygon";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Submitted from "./Submitted";
import moment from "moment";

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

const locationSelections = [
  "Backyard",
  "Sideyard",
  "Driveway",
  "Street",
  "Alley",
];
const heardAboutUsFromSelections = [
  "Past Customer",
  "Google",
  "Yelp",
  "HomeAdvisor",
  "Referral",
  "Other (Please specify below)",
  "NextDoor",
  "Social Media"
];
const communcationSelections = ["Email", "Call", "Text"];

function ExistingForm(props) {
  const {
    classes,
    customerInfo,
    inventoryData,
    handleAddPendingReservationExistingCustomer,
    territoriesData,
    setSpokenWithTeamMember,
    spokenWithTeamMember,
    // communicationPreference,
    // setCommunicationPreference,
  } = props;
  const [reservationType, setReservationType] = useState({ new: false, update: false })
  const [reserveTypeErr, setReserveTypeErr] = useState(false)
  const [billingAddress, setBillingAddress] = useState(
    customerInfo.billingAddress
  );
  const [addressLine2, setAddressLine2] = useState(
    customerInfo.billingAddressLine2
  );
  const [siteAddress, setSiteAddress] = useState("");
  const [sevenDayRental, setSevenDayRental] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    customerInfo.phoneNumber ? customerInfo.phoneNumber : ""
  );
  const [pickupDate, setPickupDate] = useState("");
  const [invenoryWithIds, setInventoryWithIds] = useState({ key: false });
  const [equiptmentNeeded, setEquiptmentNeeded] = useState("");
  const [equipmentLocation, setEquiptmentLocation] = useState("");
  const [heardAboutUsFrom, setHeardAboutUsFrom] = useState("");
  const [notes, setNotes] = useState("");
  const [communicationPreference, setCommunicationPreference] = useState("Email")
  const [promoCode, setPromoCode] = useState("");
  const [dropOffDate, setDropOffDate] = useState("");
  const [previousAddress, setPreviousAddress] = useState(false);
  const [previousSiteList, setPreviousSiteList] = useState([])
  const [submitted, setSubmitted] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [cost, setCost] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [dropOffLat, setDropOffLat] = useState(false);
  const [dropOffLng, setDropOffLng] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [errors, setErrors] = useState({
    phoneNumber: false,
    billingAddress: false,
    siteAddress: false,
    dropOffDate: false,
    equipmentLocation: false,
    equiptmentNeeded: false,
    pickupDate: false,
  });
  console.log("checking customer info", customerInfo)
  console.log("citycitycity", city, state, zipCode);

  useEffect(() => {
    db.collection('reservations')
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().customerId === customerInfo.customerId) {
            items.push(doc.data().reservation.siteAddress)
          }
        });
        setPreviousSiteList(items)
      })
  }, [])
  console.log("checking previous", previousSiteList)
  const previousSites = previousSiteList;
  const handleSelect = (address, placeholder) => {
    console.log("addressaddress", address);
    geocodeByAddress(address).then((results) => {
      if (placeholder === "Search your billing address..") {
        setBillingAddress(results[0].formatted_address);
      }
      if (placeholder === "Search your drop-off address..") {
        const addressResults = results[0];
        addressResults.address_components.forEach((r) => {
          if (r.types.includes("locality")) {
            setCity(r.long_name);
          }
          if (r.types.includes("administrative_area_level_1")) {
            setState(r.short_name);
          }
          if (r.types.includes("postal_code")) {
            setZipCode(r.short_name);
          }
        });
        setSiteAddress(addressResults.formatted_address);
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        setDropOffLat(lat);
        setDropOffLng(lng);
      }
    });
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;
    setPhoneNumber(value);
  };
  const handleSubmit = () => {
    // need error handling for all fields if they are missing
    // need error handling for if inventory isn't available at selected day
    // is the cost amount of the reservation calculated or will admin go in and add price
    //possibly send email a copy of reservation request?

    if (
      !phoneNumber ||
      !billingAddress ||
      !siteAddress ||
      !dropOffDate ||
      !equiptmentNeeded ||
      !equipmentLocation ||
      !pickupDate ||
      (!spokenWithTeamMember.yes && !spokenWithTeamMember.no)
    ) {
      setHasErrors(true);
      let newErrors = {
        phoneNumber: false,
        billingAddress: false,
        siteAddress: false,
        dropOffDate: false,
        equipmentLocation: false,
        equiptmentNeeded: false,
        pickupDate: false,
      };
      if (!spokenWithTeamMember.yes && !spokenWithTeamMember.no) {
        newErrors = { ...newErrors, spokenWithTeamMember: true };
      }
      if (!phoneNumber) {
        newErrors = { ...newErrors, phoneNumber: true };
      }
      if (!billingAddress) {
        newErrors = { ...newErrors, billingAddress: true };
      }
      if (!siteAddress) {
        newErrors = { ...newErrors, siteAddress: true };
      }
      if (!dropOffDate) {
        newErrors = { ...newErrors, dropOffDate: true };
      }
      if (!equiptmentNeeded) {
        newErrors = { ...newErrors, equiptmentNeeded: true };
      }
      if (!equipmentLocation) {
        newErrors = { ...newErrors, equipmentLocation: true };
      }
      if (!pickupDate) {
        newErrors = { ...newErrors, pickupDate: true };
      }
      setErrors({ ...newErrors });
    } else {
      let territory = "N/A";
      const territoryPolygons = [];
      territoriesData.forEach((t) => {
        territoryPolygons.push(Object.values(t.territoryPolygon));
      });
      territoryPolygons.forEach((t, index) => {
        if (pointInPolygon([dropOffLng, dropOffLat], t)) {
          territory = territoriesData[index].territoryName;
        }
      });

      const pendingRes = {
        ...customerInfo,
        phoneNumber: phoneNumber ? phoneNumber : customerInfo.phoneNumber,
        billingAddress: billingAddress
          ? billingAddress
          : billingAddress.phoneNumber,
        billingAddressLine2: addressLine2
          ? addressLine2
          : customerInfo.billingAddressLine2,
        reservation: {
          amount: cost,
          amountPaid: "0",
          driver: "N/A",
          endDate: pickupDate,
          territory,
          dropOffCity: city,
          dropOffState: state,
          dropOffZipCode: zipCode,
          equiptment: equiptmentNeeded.inventoryItem,
          equiptmentLocation: equipmentLocation,
          existingCustomer: true,
          heardAboutUsFrom: heardAboutUsFrom,
          inventoryId: equiptmentNeeded.inventoryId,
          lastEmailedDate: "N/A",
          notes: notes,
          otherInformation: "",
          paymentDueDate: dropOffDate,
          poNumber: "",
          promoCode: promoCode,
          quantity: 1,
          recurring: false,
          sevenDayReservation: sevenDayRental === "Yes" ? true : false,
          siteAddress: siteAddress,
          startDate: dropOffDate,
          status: "Unapproved",
          spokenWithTeamMember: spokenWithTeamMember.yes ? true : false,
        },
      };
      handleAddPendingReservationExistingCustomer(pendingRes);
      setSubmitted(true);
    }
  };
  const [step, setStep] = useState(1)
  const nextStep = () => {
    setStep(s => s + 1)
  }

  const prevStep = () => {
    setStep(s => s - 1)
  }

  // if (submitted) return <Submitted />;
  if (submitted) {
    window.open("https://humptydumpco.com/reserve-submitted/", "_blank")

  }

  switch (step) {
    case 1:
      return (
        <StepOne
          inventoryData={inventoryData}
          customerInfo={customerInfo}
          territoriesData={territoriesData}
          spokenWithTeamMember={spokenWithTeamMember}
          setSpokenWithTeamMember={setSpokenWithTeamMember}
          nextStep={nextStep}
          prevStep={prevStep}
          phoneNumber={phoneNumber}
          handlePhoneNumberChange={handlePhoneNumberChange}
          errors={errors}
          reservationType={reservationType}
          setReservationType={setReservationType}
          reserveTypeErr={reserveTypeErr}
          setReserveTypeErr={setReserveTypeErr}
        />
      )
    case 2:
      return (
        <StepTwo
          inventoryData={inventoryData}
          customerInfo={customerInfo}
          territoriesData={territoriesData}
          spokenWithTeamMember={spokenWithTeamMember}
          setSpokenWithTeamMember={setSpokenWithTeamMember}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          errors={errors}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          handleSelect={handleSelect}
          addressLine2={addressLine2}
          setAddressLine2={setAddressLine2}
          previousAddress={previousAddress}
          setPreviousAddress={setPreviousAddress}
          previousSites={previousSites}
          siteAddress={siteAddress}
          setSiteAddress={setSiteAddress}
          dropOffDate={dropOffDate}
          setDropOffDate={setDropOffDate}
          disablePastDt={disablePastDt}
          sevenDayRental={sevenDayRental}
          setSevenDayRental={setSevenDayRental}
          pickupDate={pickupDate}
          setPickupDate={setPickupDate}
        />
      )
    case 3:
      return (
        <StepThree
          inventoryData={inventoryData}
          customerInfo={customerInfo}
          territoriesData={territoriesData}
          spokenWithTeamMember={spokenWithTeamMember}
          setSpokenWithTeamMember={setSpokenWithTeamMember}
          nextStep={nextStep}
          prevStep={prevStep}
          dropOffDate={dropOffDate}
          sevenDayRental={sevenDayRental}
          setSevenDayRental={setSevenDayRental}
          pickupDate={pickupDate}
          setPickupDate={setPickupDate}
          getOneWeekLater={getOneWeekLater}
          equiptmentNeeded={equiptmentNeeded}
          setEquiptmentNeeded={setEquiptmentNeeded}
          equipmentLocation={equipmentLocation}
          setEquiptmentLocation={setEquiptmentLocation}
          cost={cost}
          setCost={setCost}
          locationSelections={locationSelections}
          communicationPreference={communicationPreference}
          setCommunicationPreference={setCommunicationPreference}
          communcationSelections={communcationSelections}
          heardAboutUsFrom={heardAboutUsFrom}
          setHeardAboutUsFrom={setHeardAboutUsFrom}
          heardAboutUsFromSelections={heardAboutUsFromSelections}
          notes={notes}
          setNotes={setNotes}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          handleSubmit={handleSubmit}
          hasErrors={hasErrors}
          setHasErrors={setHasErrors}
          submitted={submitted}
          setSubmitted={setSubmitted}
          errors={errors}
          dropOffLng={dropOffLng}
        />
      )
    default:
      console.log("default step ")
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  handleAddPendingReservationExistingCustomer: (pendingRes) =>
    dispatch(addPendingReservationExistingCustomer(pendingRes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(ExistingForm));
