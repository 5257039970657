import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, sendInvoiceLink, sendInvoiceLinkLocalHost } from "firebase/client";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import InvoiceDetailHeader from "./components/InvoiceDetailHeader";
import InvoiceDetails from "./components/InvoiceDetails";
import loader from "assets/img/loader.gif";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import UpdateInvoiceStatusModal from "./components/UpdateInvoiceStatusModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function InvoiceDetail(props) {
  const { classes, history } = props;
  const [editReservationModal, setEditReservationModal] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [reservation, setReservation] = useState(false);
  const [customer, setCustomer] = useState(false);
  const invoiceId = window.location.pathname.split("/")[3];
  const [updateInvoiceStatusModal, setUpdateInvoiceStatusModal] = useState(
    false
  );
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState("");

  const saveNewInvoiceStatus = () => {
    return db
      .collection("invoices")
      .doc(invoiceId)
      .update({
        invoiceStatus: selectedInvoiceStatus,
      })
      .then(() => {
        setUpdateInvoiceStatusModal(false);
        if (selectedInvoiceStatus === "Approved") {
          const linkData = { ...reservation, ...customer };
          console.log("linkData", linkData);
          sendInvoiceLink({ ...reservation, ...customer });
        }
      });
  };

  useEffect(() => {
    const getInvoiceInfo = async () => {
      return db
        .collection("invoices")
        .doc(invoiceId)
        .onSnapshot((snapshot) => {
          const invoiceData = snapshot.data();
          setInvoice(invoiceData);
          setSelectedInvoiceStatus(invoiceData.invoiceStatus);
          if (invoiceData.reservationId) {
            const reservationID = invoiceData.reservationId;
            const promiseArray = [
              db.collection("reservations").doc(reservationID).get(),
              db.collection("pending-reservations").doc(reservationID).get(),
              db.collection("past-reservations").doc(reservationID).get(),
            ];

            Promise.all(promiseArray).then((results) => {
              results.forEach((snapshot) => {
                if (snapshot.exists) {
                  snapshot = snapshot.data();
                  setReservation(snapshot);
                }
              });
              return db
                .collection("customers")
                .doc(invoiceData.customerId)
                .get()
                .then((customerSnap) => {
                  const customerData = customerSnap.data();
                  setCustomer(customerData);
                });
            });
          } else {
            return db
              .collection("customers")
              .doc(invoiceData.customerId)
              .get()
              .then((customerSnap) => {
                const customerData = customerSnap.data();
                setCustomer(customerData);
              });
          }
        });
    };
    getInvoiceInfo();
  }, []);

  console.log("invoiceeeee", invoice, customer);

  if (!invoice || !customer)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );
  return (
    <GridContainer>
      <GridItem xs={12}>
        <InvoiceDetailHeader
          setUpdateInvoiceStatusModal={setUpdateInvoiceStatusModal}
          invoice={invoice}
          invoiceId={invoiceId}
          history={history}
        />
      </GridItem>
      <GridItem xs={12}>
        <InvoiceDetails
          customer={customer}
          reservation={reservation}
          history={history}
          invoice={invoice}
        />
      </GridItem>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        maxWidth="xl"
        open={updateInvoiceStatusModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setUpdateInvoiceStatusModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <UpdateInvoiceStatusModal
          selectedInvoiceStatus={selectedInvoiceStatus}
          setSelectedInvoiceStatus={setSelectedInvoiceStatus}
          setUpdateInvoiceStatusModal={setUpdateInvoiceStatusModal}
          classes={classes}
          saveNewInvoiceStatus={saveNewInvoiceStatus}
        />
      </Dialog>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(InvoiceDetail));
