import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { db, firebase } from "firebase/client";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  ContentState,
  createWithContent,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
  convertToHTML,
} from "draft-js";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";

function EditTerms(props) {
  const {
    classes,
    history,
    userInfo,
    invoice,
    setViewAndEditTermsModal,
    invoiceId,
    setSnackBarContent,
    setDataLoaded,
    termsAndConditions,
    setTermsAndConditions
  } = props;
  const [editorState1, setEditorState1] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(termsAndConditions))
    )
  );

  const handleSaveTerms = () => {
    setTermsAndConditions(
      draftToHtml(convertToRaw(editorState1.getCurrentContent()))
    );
    // setDataLoaded(false);
    setViewAndEditTermsModal("");
    setSnackBarContent("Successfully updated terms of service");
  };

  return (
    <Card className={classes.modalCard}>
      <div
        style={{
          background: "#F1C84E",
          display: "flex",
          width: "100%",
          justifyContent: "end",
          padding: "10px 10px 0px 0px",
        }}
      >
        <BlackDenyIcon
          onClick={() => setViewAndEditTermsModal("")}
          className={classes.closeModal}
        />
      </div>
      <h1
        style={{ padding: "10px 30px 30px 30px" }}
        className={classes.modalTitle}
      >
        Edit Terms And Conditions
      </h1>
      <CardBody style={{ background: "white" }} className={classes.body}>
        <Editor
          id="description"
          // defaultValue={convertFromHTML(invoice.Item_Info.Item_Description)}
          placeholder="Description for your line item..."
          editorState={editorState1}
          onEditorStateChange={(state) => setEditorState1(state)}
          // onEditorStateChange={(e) => handleItem(e,index)}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          editorStyle={{ border: "1px solid grey" }}
          toolbarStyle={{ border: "1px solid grey" }}
        />
      </CardBody>
      <div
        style={{
          display: "flex",
          width: "100%",
          background: "white",
          justifyContent: "space-between",
          marginBottom: "30px",
          padding: "0.9375rem 20px",
        }}
      >
        <Button
          style={{ color: "white", background: "#323232", width: "45%" }}
          onClick={() => setViewAndEditTermsModal("")}
          className={classes.sendBtn}
        >
          Close
        </Button>
        <Button
          style={{ color: "#323232", background: "#FFC821", width: "45%" }}
          onClick={handleSaveTerms}
          className={classes.sendBtn}
        >
          Submit
        </Button>
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(EditTerms));
