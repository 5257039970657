import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as DeliveryIcon } from "assets/img/deliveryIcon.svg";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ArrowDown } from "assets/img/arrowDown.svg";
import { ReactComponent as SwapIcon } from "assets/img/swapIcons.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import { ReactComponent as BlackMailIcon } from "assets/img/blackMailIcon.svg";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export function Item(props) {
  const {
    id,
    classes,
    data,
    history,
    reservationId,
    driver,
    setItems,
    itemsState,
    reservation,
    columnWidth,
    searchString,
    splitDate,
    startOrEnd,
    dontShowTooltip
  } = props;
  return (
    <Tooltip
      id="tooltip-top"
      title={startOrEnd === "END" ? "Pickup" : "Delivery"}
      placement="top"
      classes={{ tooltip: classes.tooltip }}
      disableHoverListener={dontShowTooltip}
    >
      <div
        // key={index}
        style={
          searchString && !data.name.toLowerCase().includes(searchString)
            ? {
                background: "white",
                display: "flex",
                alignItems: "center",
                border: "0.5px solid #929292",
                padding: "10px",
                justifyContent: "space-between",
                opacity: 0.3,
              }
            : {
                // width: `${columnWidth}%`,
                background: "white",
                display: "flex",
                alignItems: "center",
                border: "0.5px solid #929292",
                padding: "10px",
                justifyContent: "space-between",
                borderLeft: `6px solid ${props.territoryColor}`,
              }
        }
      >
        {" "}
        <span className={classes.scheduleHeaderText}>{data.name}</span>
        <DragIndicatorIcon />
      </div>
    </Tooltip>
  );
}

export default function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.id,
    data: {
      // change this if using for something other than reservations
      reservationId: props.reservationId,
      data: props.data,
      driverId: props.driverId,
      date:
        props.startOrEnd === "END"
          ? props.data.reservation.endDate
          : props.data.reservation.startDate,
    },
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    // width: `${props.columnWidth}%`,
  };

  return (
    <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <Item
        reservationId={props.reservationId}
        classes={props.classes}
        data={props.data}
        id={props.reservationId}
        driverId={props.driverId}
        driver={props.driver}
        weeklyItems={props.weeklyItems}
        setWeeklyItems={props.setWeeklyItems}
        reservation={props.reservation}
        columnWidth={props.columnWidth}
        searchString={props.searchString}
        splitDate={props.splitDate}
        startOrEnd={props.startOrEnd}
        territoryColor={props.territoryColor}
        dontShowTooltip={props.dontShowTooltip}
      />
    </div>
  );
}
