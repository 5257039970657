import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";

import ExistingOrNewCustomer from "./components/ExistingOrNewCustomer";
import ExistingCustomerIndividual from "./components/ExistingCustomerIndividual";
import ExistingCustomerBusiness from "./components/ExistingCustomerBusiness";
import ExistingForm from "./components/ExistingForm";
import NewForm from "./components/NewForm";
import BusinessOrIndividualComponent from "./components/BusinessOrIndividualComponent";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Button } from "@material-ui/core";


function ReservationForm(props) {
  const { classes } = props;
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const [emailString, setEmailString] = useState("");
  const [searchingCustomers, setSearchingCustomers] = useState(false);
  const [didntFindCustomerError, setDidntFindCustomerError] = useState(false);
  const [businessError, setBusinessError] = useState("");
  const [individualError, setIndividualError] = useState("");
  const [customersLoaded, setCustomersLoaded] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerInfo, setCustomerInfo] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [BusinessOrIndividual, setBusinessOrInBusinessOrIndividual] = useState(
    ""
  );
  const [pin, setPin] = useState("");
  const [businesses, setBusinesses] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  const [errors, setErrors] = useState({
    emailString: false,
    pin: false,
  });
  const [returningCustomerAlert, setReturningCustomerAlert] = useState(false);
  const [territoriesData, setTerritoriesData] = useState(false);
  const [spokenWithTeamMember, setSpokenWithTeamMember] = useState({ yes: false, no: false })
  const [communicationPreference, setCommunicationPreference] = useState("Email")
  console.log("territoriesData", territoriesData);


  useEffect(() => {
    if (!territoriesData) {
      const fetchData = async () => {
        return db
          .collection("territories")
          .get()
          .then((snapshot) => {
            const territories = snapshot.docs.map((d) => d.data());
            setTerritoriesData(territories);
          });
      };
      fetchData();
    }
  }, [territoriesData]);

  useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        return db
          .collection("inventory")
          .orderBy("sortOrder")
          .get()
          .then((snapshot) => {
            const inventory = snapshot.docs.map((d) => d.data());
            setInventoryData(inventory);
            setDataLoaded(true);
          });
      };
      fetchData();
    }
  }, [dataLoaded]);

  useEffect(() => {
    if (!customersLoaded) {
      const fetchData = async () => {
        return db
          .collection("customers")
          .get()
          .then((snapshot) => {
            console.log("snapshot", snapshot);
            const customerSnap = snapshot.docs.map((d) => d.data());
            setCustomers(customerSnap);
            setCustomersLoaded(true);
          });
      };
      fetchData();
    }
  }, [customersLoaded]);

  const searchForEmailAndVerifyPin = () => {
    if (!pin || !emailString) {
      let newErrors = {
        emailString: false,
        pin: false,
      };
      if (!emailString) {
        newErrors = { ...newErrors, emailString: true };
      }
      if (!pin) {
        newErrors = { ...newErrors, pin: true };
      }
      setErrors({ ...newErrors });
      setHasErrors(true);
      // all fields
    } else {
      setErrors({ emailString: false, pin: false });
      setHasErrors(false);
      // if (!businesses) {
      setSearchingCustomers(true);
      let foundCustomer = false;
      customers.forEach((c) => {
        if (c.email === emailString) {
          foundCustomer = c;
        }
      });
      if (!foundCustomer) {
        setBusinessError(
          "Unfortunately that email isn't in the system. Please try another."
        );
        setSearchingCustomers(false);
      } else {
        console.log("foundCustomer", foundCustomer);
        if (!foundCustomer.businessId) {
          setSearchingCustomers(false);
          setBusinessError(
            "Unfortunately that email isn't linked to a business."
          );
          // customer not linked to a company account
        } else {
          return db
            .collection("companies")
            .doc(foundCustomer.businessId)
            .get()
            .then((snapshot) => {
              const businessData = snapshot.data();
              setSearchingCustomers(false);
              if (businessData.businessPin !== pin) {
                console.log("businessData", businessData);
                setBusinessError(
                  "Unfortunately that business pin is incorrect."
                );
                // incorrect pin
              } else {
                console.log("correct pin");
                setCustomerInfo(foundCustomer);
              }
            });
        }
      }
    }
  };

  const searchForEmail = () => {
    setSearchingCustomers(true);
    let foundCustomer = false;
    customers.forEach((c) => {
      if (c.email === emailString) {
        foundCustomer = c;
      }
    });
    setSearchingCustomers(false);
    if (!foundCustomer) {
      setIndividualError(
        `Unfortunately that email isn't in the system. Please try another.`
      );
    } else {
      if (foundCustomer.businessId) {
        setIndividualError(
          `Unfortunately that email is tied to a business. Please restart as a business`
        );
      } else {
        setCustomerInfo(foundCustomer);
      }
    }
  };

  if (newCustomer)
    return (
      <NewForm
        customers={customers}
        setIndividualError={setIndividualError}
        inventoryData={inventoryData}
        setReturningCustomerAlert={setReturningCustomerAlert}
        returningCustomerAlert={returningCustomerAlert}
        territoriesData={territoriesData}
        spokenWithTeamMember={spokenWithTeamMember}
        setSpokenWithTeamMember={setSpokenWithTeamMember}
        communicationPreference={communicationPreference}
        setCommunicationPreference={setCommunicationPreference}
      />
    );

  if (
    existingCustomer &&
    BusinessOrIndividual === "Individual" &&
    !customerInfo
  )
        return (
          <ExistingCustomerIndividual
            searchingCustomers={searchingCustomers}
            emailString={emailString}
            setEmailString={setEmailString}
            searchForEmail={searchForEmail}
            didntFindCustomerError={didntFindCustomerError}
            setDidntFindCustomerError={setDidntFindCustomerError}
            customersLoaded={customersLoaded}
            setCustomersLoaded={setCustomersLoaded}
            setCustomers={setCustomers}
            inventoryData={inventoryData}
            BusinessOrIndividual={BusinessOrIndividual}
            individualError={individualError}
            territoriesData={territoriesData}
          />
        );

  if (existingCustomer && BusinessOrIndividual === "Business" && !customerInfo)
    return (
      <>
        <ExistingCustomerBusiness
          searchingCustomers={searchingCustomers}
          emailString={emailString}
          setEmailString={setEmailString}
          searchForEmailAndVerifyPin={searchForEmailAndVerifyPin}
          didntFindCustomerError={didntFindCustomerError}
          setDidntFindCustomerError={setDidntFindCustomerError}
          customersLoaded={customersLoaded}
          setCustomersLoaded={setCustomersLoaded}
          setCustomers={setCustomers}
          inventoryData={inventoryData}
          BusinessOrIndividual={BusinessOrIndividual}
          pin={pin}
          setPin={setPin}
          errors={errors}
          businessError={businessError}
          territoriesData={territoriesData}
        />

        <Snackbar open={hasErrors} onClose={() => setHasErrors(false)}>
          <Alert onClose={() => setHasErrors(false)} severity={"error"}>
            Please Fill Out All Mandatory Fields
          </Alert>
        </Snackbar>
      </>
    );

  if (existingCustomer && !BusinessOrIndividual)
    return (
      <BusinessOrIndividualComponent
        setBusinessOrInBusinessOrIndividual={
          setBusinessOrInBusinessOrIndividual
        }
        BusinessOrIndividual={BusinessOrIndividual}
        territoriesData={territoriesData}
      />
    );

  if (existingCustomer && BusinessOrIndividual && customerInfo)
    return (
      <ExistingForm
        inventoryData={inventoryData}
        customerInfo={customerInfo}
        territoriesData={territoriesData}
        spokenWithTeamMember={spokenWithTeamMember}
        setSpokenWithTeamMember={setSpokenWithTeamMember}
      />
    );

  if (!newCustomer && !existingCustomer)
    return (
      <ExistingOrNewCustomer
        dataLoaded={dataLoaded}
        existingCustomer={existingCustomer}
        setExistingCustomer={setExistingCustomer}
        newCustomer={newCustomer}
        setNewCustomer={setNewCustomer}
        inventoryData={inventoryData}
        territoriesData={territoriesData}
      />
    );

  return <Card className={classes.card}></Card>;
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(ReservationForm));
