import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { BsFillCircleFill } from "react-icons/bs";

import EditCustomerModal from "./EditCustomerModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CompanyInfoHeader(props) {
  const { classes, companyInfo, setCompanyInfo, history } = props;

  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [customerEdited, setCustomerEdited] = useState(false);
  console.log('companyInfo', companyInfo)
  return (
    <GridContainer>
      <GridItem className={classes.gridItem} xs={9}>
        <h1 className={classes.title} style={{ textTransform: "capitalize" }}>{companyInfo.companyName}</h1>
        <div className={classes.commentsDiv}>
          {companyInfo.tags.map((c, index) => {
            return (
              <span className={classes.commentDiv} key={index}>
                <BsFillCircleFill
                  className={classes.bulletPoint}
                  style={
                    c === "Regular"
                      ? { color: "#2EB67D" }
                      : c === "Cautious"
                        ? { color: "#323232" }
                        : c === "Overdue"
                          ? { color: "#F83030" }
                          : c === "Unpaid"
                            ? { color: "#ECB22E" }
                            : { color: "#0071C5" }
                  }
                />
                {c}
              </span>
            );
          })}
        </div>
      </GridItem>
      <GridItem xs={3}>
        <GridContainer>
          <GridItem xs={6}>
            <div className={classes.slackButton}>
              <SlackIcon style={{ marginRight: "5px" }} />
              <span>Go To Slack</span>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <div
              style={{ background: "#FFC720", padding: "5px" }}
              onClick={() => setEditCustomerModal(true)}
              className={classes.slackButton}
            >
              <EditIcon style={{ fontSize: "16px" }} />
              <span style={{ marginLeft: "5px" }}>Edit Company</span>
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={12}>
        <h3
          className={classes.title}
          style={{
            textTransform: "capitalize",
            fontSize: "24px",
            textAlign: "left",
            margin: "15px 0px -20px 0px"
          }}
        >
          Company Details
        </h3>
      </GridItem>

      {/* <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={editCustomerModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setEditCustomerModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <EditCustomerModal
          setCustomerEdited={setCustomerEdited}
          setEditCustomerModal={setEditCustomerModal}
          companyInfo={companyInfo}
          setCompanyInfo={setCompanyInfo}
          history={history}
          customerInfoHeader
        />
      </Dialog> */}

      <Snackbar open={customerEdited} onClose={() => setCustomerEdited(false)}>
        <Alert onClose={() => setCustomerEdited(false)} severity={"success"}>
          Customer Edited
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(CompanyInfoHeader));
