import {
  addValidations,
  addWorkflow,
  addCloseHandlers,
  actionsForStep,
  actionsForForm,
} from "../form";
import { userTypes } from "variables/config";
import AuthActions from "store/actions/auth";
import { errorCodes } from "variables/errors";
import { config } from "../../reducers/forms/registration";
import { push } from "connected-react-router";
import { chain } from "store/actions/util";
import { OpenMode } from "variables/constants";

function getField(state, stepName, fieldName) {
  const form = state.form.registration;
  const step = form && form.steps ? form.steps[stepName] : undefined;
  return step && step.fields ? step.fields[fieldName] : undefined;
}

function verifyEmail(value) {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}

function validationError(message) {
  return {
    code: errorCodes.FORM_FIELD_VALIDATION_ERROR,
    message,
  };
}

const basicDetailsValidations = {
  validateField: (field, value, getState) => {
    // Don't validate blank fields
    if (value === undefined || value === "") return {};
    switch (field) {
      case "email":
        if (!verifyEmail(value))
          return { email: validationError("Not a valid email address") };
        else return { email: undefined };
      case "password":
        const confirmPass = getField(
          getState(),
          "basicDetails",
          "confirmPassword"
        );
        if (value === confirmPass)
          return { password: undefined, confirmPass: undefined };
        else
          return {
            password: validationError("Does not match confirmation"),
            confirmPassword: validationError("Does not match password"),
          };
      case "confirmPassword":
        const password = getField(getState(), "basicDetails", "password");
        if (value === password)
          return { password: undefined, confirmPass: undefined };
        else
          return {
            password: validationError("Does not match confirmation"),
            confirmPassword: validationError("Does not match password"),
          };
      case "acceptedTerms":
        if (value) return { acceptedTerms: undefined };
        else
          return { acceptedTerms: validationError("Terms must be accepted") };
      case "userType":
        if (userTypes[value]) return { userType: undefined };
        else return { userType: validationError("Not an accepted user type") };
      default:
        return {};
    }
  },
};

const commonProfileValidations = {
  validateField: (field, value) => {
    // Don't validate blank fields
    if (value === undefined || value === "") return {};
    switch (field) {
      case "firstName":
        if (value.length > 1) return { firstName: undefined };
        else
          return {
            firstName: validationError("Please input your first name in full"),
          };
      case "lastName":
        if (value.length > 1) return { firstName: undefined };
        else
          return {
            firstName: validationError("Please input your last name in full"),
          };
      case "birthDate":
        if (!isNaN(Date.parse(value))) return { birthDate: undefined };
        else return { birthDate: validationError("Not a valid date") };
      case "phoneNumber":
        if (value.length > 6) return { phoneNumber: undefined };
        else
          return {
            phoneNumber: validationError(
              "Please input your telephone number in full"
            ),
          };
      default:
        return {};
    }
  },
};

const lawyerProfileValidations = {
  validateField: (field, value) => {
    // Don't validate blank fields
    if (value === undefined || value === "") return {};
    switch (field) {
      case "barAssociation":
        if (value.length > 3) return { association: undefined };
        else
          return {
            association: validationError("Please input your bar association"),
          };
      case "barNumber":
        if (value.length > 3) return { barNumber: undefined };
        else
          return {
            barNumber: validationError("Please input your bar number in full"),
          };
      case "praticeEmail":
        if (verifyEmail(value)) return { praticeEmail: undefined };
        else
          return { praticeEmail: validationError("Not a valid email address") };
      default:
        return {};
    }
  },
};

function nextStep(currentStep, getState) {
  switch (currentStep) {
    case "basicDetails":
      return { step: "commonProfile", mode: OpenMode.UPDATE };
    case "commonProfile":
      const userType = getField(getState(), "basicDetails", "userType");
      if (userType === "CLIENT") return { step: "submit" };
      else return { step: "lawyerProfile", mode: OpenMode.UPDATE };
    case "lawyerProfile":
      return { step: "submit" };
    default:
      return { step: "basicDetails", mode: OpenMode.UPDATE };
  }
}

function lastStep(currentStep) {
  switch (currentStep) {
    case "commonProfile":
      return { step: "basicDetails", mode: OpenMode.UPDATE };
    case "lawyerProfile":
      return { step: "commonProfile", mode: OpenMode.UPDATE };
    default:
      return undefined;
  }
}

const closeHandlers = {
  submit: () => (dispatch, getState) => {
    const registration = getState().form.registration;
    const {
      confirmPassword,
      password,
      ...basicDetails
    } = registration.steps.basicDetails.fields;
    const lawyerProfile =
      (registration.steps.lawyerProfile &&
        registration.steps.lawyerProfile.fields) ||
      {};
    const commonProfile =
      (registration.steps.commonProfile &&
        registration.steps.commonProfile.fields) ||
      {};
    const profile = { ...basicDetails, ...commonProfile, ...lawyerProfile };
    return dispatch(
      chain(
        AuthActions.startEmailSignup(
          { email: basicDetails.email, password },
          profile
        ),
        push("/admin/dashboard")
      )
    );
  },
};

const form = addCloseHandlers(
  actionsForForm(config, "registration"),
  closeHandlers
);
const basicDetails = addValidations(
  actionsForStep(config, "registration", "basicDetails"),
  basicDetailsValidations
);
const commonProfile = addValidations(
  actionsForStep(config, "registration", "commonProfile"),
  commonProfileValidations
);
const lawyerProfile = addValidations(
  actionsForStep(config, "registration", "lawyerProfile"),
  lawyerProfileValidations
);

export default addWorkflow(
  form,
  { basicDetails, commonProfile, lawyerProfile },
  nextStep,
  lastStep
);
