import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { CSVLink } from "react-csv";

const selectOptions = ["Last Sent Date", "Next Sent Date", "Task ID"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = [
  "All",
  "Scheduled",
  "Completed",
  "In Progress",
  "Unassigned",
];
const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Comments", key: "comments" },
  { label: "Company", key: "company" },
  { label: "Customer Address", key: "billingAddress" },
  { label: "Customer ID", key: "customerId" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phoneNumber" },

  { label: "Cost Of Rental", key: "amount" },
  { label: "Cost Paid", key: "amountPaid" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Inventory Needed", key: "equiptment" },
  { label: "Dropoff Location", key: "equiptmentLocaion" },
  { label: "Existing Customer", key: "existingCustomer" },

  { label: "Referred from", key: "heardAboutUsFrom" },
  { label: "Last Emailed", key: "lastEmailedDate" },
  { label: "Notes", key: "notes" },
  { label: "Payment Due Date", key: "paymentDueDate" },
  { label: "PO #", key: "poNumber" },
  { label: "Quantity", key: "quantity" },
  { label: "Rental ID", key: "reservationId" },

  { label: "Seven Day Rental", key: "sevenDayReservation" },
  { label: "Site Address", key: "siteAddress" },
  { label: "Rental Status", key: "status" },
  { label: "Recurring Rental", key: "recurring" },
];

function Filters(props) {
  const {
    classes,
    sortBySelection,
    setSortBySelection,
    setDirectionSelection,
    directionSelection,
    setFilterSelection,
    filterSelection,
    setSearchString,
    searchString,
    handleFilters,
    handleSearch,
    handleCommentFilter,
    reservationsData,
  } = props;

  return (
    <div>
      <GridContainer>
        <GridItem className={classes.noPaddingGridItem} xs={12}>
          <h5 className={classes.subHeader} style={{ whiteSpace: "noWrap" }}>Sort By</h5>
          <Select
            className={classes.selectMenuSort}
            style={{width:"180px"}}
            value={filterSelection}
            disableUnderline
            classes={{
              // root: classes.selectText,
            }}
            onChange={(e) => handleFilters("filter", e.target.value)}
          >
            {selectOptions.map((o, i) => {
              return (
                <MenuItem
                
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
          style={{width:"120px"}}
            className={classes.ascMenu}
            disableUnderline
            classes={{
              // root: classes.selectText,
            }}
            value={directionSelection}
            onChange={(e) => handleFilters("direction", e.target.value)}
          >
            {directionOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(Filters));
