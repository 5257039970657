import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";
import { ReactComponent as CreateRefund } from "assets/img/createRefund.svg";
import { ReactComponent as ApplyCredit } from "assets/img/applyCredit.svg";
import { ReactComponent as VoidPayment } from "assets/img/voidPayment.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import AccountingCustomTabs from "./AccountingCustomTabs";
import Checkbox from "@material-ui/core/Checkbox";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

const headers = [
  "ID",
  "Date",
  "Amount",
  "Remaining",
  "Original Reservation",
  "Actions",
];
const sortByOptions = ["Payment Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];
const pageArr = ["3", "All"]

function AccountingRefunds(props) {
  const { classes, customerInfo } = props;
  const [sortBy, setSortBy] = useState("Payment Date");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterBySelection, setFilterBySelection] = useState("Filter By");
  const [searchString, setSearchString] = useState("");
  const itemsPerPage = 3;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("3")


  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)
  };

  useEffect(() => {
    customerInfo.reservations.slice(index, endIndex)
  }, [index])

  const renderTableHeader = () => {
    return (
      <tr className={classes.notesTableRow}>
        {headers.map((key, index) => {
          return (
            <th className={classes.notesTableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if (pageDropdown == "3") {
      return customerInfo.reservations
        .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
        .map((d, index) => {
          return (
            <tr className={classes.notesTableRow} key={d.reservationId}>
              <td className={classes.notesTableCell}>{d.reservationId}</td>
              <td className={classes.notesTableCell}>{d.paymentDueDate}</td>
              <td className={classes.notesTableCell}>{d.amount}</td>
              <td className={classes.notesTableCell}>
                {" "}
                ${parseFloat(d.amount) - parseFloat(d.amountPaid)}
              </td>
              <td className={classes.notesTableCell}>
                Driver: {d.driver}
              </td>
              <td className={classes.notesTableCell}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Tooltip
                    id="tooltip-top"
                    title="View Details"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      style={{ background: "#FFC720" }}
                      className={classes.actionBtn}
                    >
                      <EyeIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit Payment"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#ECB22E" }}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    id="tooltip-top"
                    title="Send Email"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#323232" }}
                    >
                      <MailIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    id="tooltip-top"
                    title="Create Refund"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#FFFFFF", border: "1px solid #323232" }}
                    >
                      <CreateRefund />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    id="tooltip-top"
                    title="Apply Credit"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#FFC720" }}
                    >
                      <ApplyCredit />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    id="tooltip-top"
                    title="Void Payment"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#ECB22E" }}
                    >
                      <VoidPayment />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    id="tooltip-top"
                    title="Delete Payment"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#323232" }}
                    >
                      <DenyIcon />
                    </Button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          );
        });
    }
    else {
      return customerInfo.reservations.map((d, index) => {
        return (
          <tr className={classes.notesTableRow} key={d.reservationId}>
            <td className={classes.notesTableCell}>{d.reservationId}</td>
            <td className={classes.notesTableCell}>{d.paymentDueDate}</td>
            <td className={classes.notesTableCell}>{d.amount}</td>
            <td className={classes.notesTableCell}>
              {" "}
              ${parseFloat(d.amount) - parseFloat(d.amountPaid)}
            </td>
            <td className={classes.notesTableCell}>
              Driver: {d.driver}
            </td>
            <td className={classes.notesTableCell}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Tooltip
                  id="tooltip-top"
                  title="View Details"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit Payment"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#ECB22E" }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>

                <Tooltip
                  id="tooltip-top"
                  title="Send Email"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#323232" }}
                  >
                    <MailIcon />
                  </Button>
                </Tooltip>

                <Tooltip
                  id="tooltip-top"
                  title="Create Refund"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#FFFFFF", border: "1px solid #323232" }}
                  >
                    <CreateRefund />
                  </Button>
                </Tooltip>

                <Tooltip
                  id="tooltip-top"
                  title="Apply Credit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#FFC720" }}
                  >
                    <ApplyCredit />
                  </Button>
                </Tooltip>

                <Tooltip
                  id="tooltip-top"
                  title="Void Payment"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#ECB22E" }}
                  >
                    <VoidPayment />
                  </Button>
                </Tooltip>

                <Tooltip
                  id="tooltip-top"
                  title="Delete Payment"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#323232" }}
                  >
                    <DenyIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }

  };

  return (
    <GridContainer>
      <GridItem className={classes.filtersGridItem} xs={1}>
        <h5 className={classes.subHeader}>Sort By</h5>
      </GridItem>
      <GridItem className={classes.filtersGridItem} xs={7}>
        <Select
          className={classes.filterMenu}
          value={sortBy}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => setSortBy(e.target.value)}
        >
          {sortByOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          className={classes.ascendingMenu}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          value={directionSelection}
          onChange={(e) => setDirectionSelection(e.target.value)}
        >
          {directionOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          className={classes.filterMenu}
          value={filterBySelection}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => setFilterBySelection(e.target.value)}
        >
          {filterOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>
      <GridItem className={classes.filtersGridItem} xs={4}>
        <TextField
          className={classes.searchBar}
          placeholder="Search Payments"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          variant="outlined"
          InputProps={{
            classes: {
              adornedEnd: classes.adournedEnd,
            },
            endAdornment: (
              <InputAdornment className={classes.adournedEnd} position="end">
                <Button className={classes.searchButton}>Search</Button>
              </InputAdornment>
            ),
          }}
        />
        <Button className={classes.createInvoiceButton}>Export CSV</Button>
      </GridItem>

      <GridItem xs={12}>
        <div style={pageDropdown != 3 && customerInfo.reservations.length > 3 ? { height: "270px", width: "100%", overflowY: "scroll" } : null}>
          <table style={{ marginTop: "30px" }} className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
        {pageDropdown == "3" ?
          <GridContainer>
            <GridItem xs={6} >
              <p className={classes.view}>View
                <span>
                  <Select
                    style={{ width: "10%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                    disableUnderline
                    MenuProps={{
                      // className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor
                    }}

                    onChange={(e) => {
                      setPageDrowdown(e.target.value)
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginTop: "5px" }}>
              <h5 className={classes.indexes}>{index + 1} - {endIndex} {customerInfo.length < itemsPerPage ?
                " " + customerInfo.length :
                " " + Math.ceil(customerInfo.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(customerInfo.reservations.length / itemsPerPage)}
                nextLabel={<ArrowRight style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                previousLabel={<ArrowLeft style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
          :
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>View
                <span>
                  <Select
                    style={{ width: "10%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                    disableUnderline
                    MenuProps={{
                      // className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value)
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        }
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(AccountingRefunds));
