import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import loader from "assets/img/loader.gif";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";

import ProfileCard from "./components/ProfileCard";
import BillingCard from "./components/BillingCard";
import axios from "axios"

function Profile(props) {
  const { classes, history, userInfo } = props;
  const [secret, setSecret] = useState(false);
  const [customerId, setCustomerId] = useState("")

  useEffect(() => {
    console.log("userinfooooo", userInfo);
    if (userInfo.stripeCustomerId) {
      async function fetchSecretExistingCustomer() {
        try {
          const response = await axios.post(
            "https://us-central1-humpty-dump.cloudfunctions.net/app/setup-intent-secret-existing-customer",
            {
              customerId: userInfo.stripeCustomerId,
            }
          );
          console.log("response", response);
          if (response.data) {
            setSecret(response.data.clientSecret);
            setCustomerId(userInfo.stripeCustomerId);
          }
        } catch (err) {
          console.log("Error", err);
        }
      }
      fetchSecretExistingCustomer();
    } else {
      async function fetchSecretNewCustomer() {
        try {
          const response = await axios.post(
            "https://us-central1-humpty-dump.cloudfunctions.net/app/setup-intent-secret",
            {
              email: userInfo.email,
              name: userInfo.name,
            }
          );
          if (response.data) {
            setSecret(response.data.clientSecret);
            setCustomerId(response.data.customerId);
          }
        } catch (err) {
          console.log("Error", err);
        }
      }
      fetchSecretNewCustomer();
    }
  }, [userInfo]);

  if (!userInfo || !secret)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );

  return (
    <GridContainer>
      <GridItem xs={7}>
        <h1 className={classes.title}>My Profile</h1>
      </GridItem>
      <GridItem className={classes.buttonGridItem} xs={5}>
        <Button
          onClick={() => history.push("/admin/edit-profile")}
          className={classes.addCustomer}
        >
          <EditIcon className={classes.editIcon} /> Edit Profile Information
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <ProfileCard userInfo={userInfo} />
      </GridItem>
      <GridItem xs={12} style={{ marginTop: "10px" }}> 
        <BillingCard
          secret={secret}
          setSecret={setSecret}
          userInfo={userInfo}
          history={history}
          customerId={customerId}
          setCustomerId={setCustomerId}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(Profile));
