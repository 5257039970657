import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import Card from "components/Card/Card.js";
import { ReactComponent as Minus } from "assets/img/minus.svg";
import Button from "components/CustomButtons/Button.js";

const overviewsCustomer = [
  {
    title: "Sales by Customer",
    description: "Your total sales for each customer.",
    link: "/admin/reports/sales-by-customer",
  },
]

const overviews = [
  {
    title: "Sales by Customer",
    description: "Your total sales for each customer.",
    link: "/admin/reports/sales-by-customer",
  },
  {
    title: "Sales by Invoice Line Item",
    description: "Your total sales for each line item.",
    link: "/admin/reports/sales-by-invoice-line-item",
  },
  {
    title: "Gross Sales",
    description:
      "Your gross sales of products and services. Includes invoiced date, transaction type, taxable and nontaxable totals, total taxes and total sales with tax. (Accrual Basis)",
    link: "/admin/reports/gross-sales",
  },

  {
    title: "Sales by quarter",
    description: "Volume pumped per job type, grouped by territory.",
    link: "/admin/reports/sales-by-quarter",
  },
  {
    title: "Deposit Summary",
    description: "Your deposits, with the payment type, amount, and quantity.",
    link: "/admin/reports/deposit-summary",
  },
  {
    title: "Deposit Detail",
    description:
      "Your payments that can be deposited into a bank account. This includes all payment methods except for credit memos.",
    link: "/admin/reports/deposit-detail",
  },
  {
    title: "Customer Referral Sources",
    description: "Referral Sources For All Customers",
    link: "/admin/reports/customer-referral-sources",
  },
];

function SalesAndCustomers(props) {
  const { classes, history, user } = props;

  return (
    <Card>
      <div className={classes.cardHeaderDiv}>
        <Minus />
        <span className={classes.cardTitle}>Sales & Customers</span>
      </div>
      <div className={classes.cardBodyDiv}>
        <GridContainer>
          {(user.role === "undefined" || user.role !== "Super Admin") ?
            overviewsCustomer.map((o, index) => {
              return (
                <GridItem className={classes.reportGridItem} key={index} xs={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className={classes.reportTitle}
                      onClick={() => history.push(o.link)}
                    >
                      {o.title}
                    </span>
                    <span className={classes.reportDescription}>
                      {o.description}
                    </span>
                  </div>
                  <Button
                    onClick={() => history.push(o.link)}
                    className={classes.viewBtn}
                  >
                    View Report{" "}
                  </Button>
                </GridItem>
              )

            })
            :
            overviews.map((o, index) => {
              return (
                <GridItem className={classes.reportGridItem} key={index} xs={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className={classes.reportTitle}
                      onClick={() => history.push(o.link)}
                    >
                      {o.title}
                    </span>
                    <span className={classes.reportDescription}>
                      {o.description}
                    </span>
                  </div>
                  <Button
                    onClick={() => history.push(o.link)}
                    className={classes.viewBtn}
                  >
                    View Report{" "}
                  </Button>
                </GridItem>
              )

            })
          }
        </GridContainer>
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(SalesAndCustomers));
