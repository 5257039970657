import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from '@material-ui/core/Fab';

import styles from "assets/jss/material-dashboard-pro-react/components/explorerStyle";

const useStyles = makeStyles(styles);

export default function Explorer(props) {

    const classes = useStyles();

    const { ExplorerCard, SearchControl, SearchDialog, routeName, searchFields, cardFields } = props;
    
    return (
        <div>
            <SearchControl />
            <SearchDialog order={searchFields}/>
            {props.records.map((record, index) => (
                <ExplorerCard 
                    key={index} 
                    index={index}
                    order={cardFields} 
                    editRoute={`${routeName}/editor/${record.key}`}
                    viewRoute={`${routeName}/editor/${record.key}?mode=READ_ONLY`}
                />
            ))}
            <Fab className={ classes.newRecord } onClick={e=>props.openChild("New Record", `${routeName}/new`)}>New Record</Fab>
        </div>
    );
}

