import moment from "moment";
import dateAndtime from "date-and-time";

export const getNextNine = (startDate) => {
  const start = new Date(startDate);
  var new_date = dateAndtime.addDays(start, 9);
  const dateArray = [];
  for (var x = 0; x < 9; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var dateToPush = dateAndtime.addDays(new_date, x);
    // return result;
    dateArray.push(moment(dateToPush).format("ddd MM/DD/YY"));
  }
  return ["Product", ...dateArray];
};

export const getPrevFourteen = (startDate) => {
  const date = new Date(startDate);
  const start = date.setDate(date.getDate() - 9);
  const officialStart = new Date(start);
  const dateArray = [];
  for (var x = 0; x < 9; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var dateToPush = dateAndtime.addDays(officialStart, x);
    // return result;
    dateArray.push(moment(dateToPush).format("ddd MM/DD/YY"));
  }

  return ["Product", ...dateArray];
};

export function getRangeBetweenTwoDates(s, e) {
  for (
    var a = [], d = new Date(s);
    d <= new Date(e);
    d.setDate(d.getDate() + 1)
  ) {
    a.push(moment(new Date(d)).format("ddd MM/DD/YY"));
  }
  return a;
}
export function getDates(startDate) {
  const start = new Date(startDate);
  const dateArray = [];
  for (var x = 0; x < 9; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var new_date = dateAndtime.addDays(start, x);
    // return result;
    dateArray.push(moment(new_date).format("ddd MM/DD/YY"));
  }

  return ["Product", ...dateArray];
}

export const getNewDataFromCalendarSelection = (startDate) => {
  const start = new Date(startDate);
  const dateArray = [];
  for (var x = 0; x < 9; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var dateToPush = dateAndtime.addDays(start, x);
    // return result;
    dateArray.push(moment(dateToPush).format("ddd MM/DD/YY"));
  }

  return ["Product", ...dateArray];
};
