import moment from "moment";
import dateAndtime from "date-and-time";
import { firebase, db } from "firebase/client";

export const seedDrivers = () => {
  const mockDrivers = [
    { name: "Chris White", color: "#FF4633" },
    { name: "Gary Allen", color: "#FFAF33" },
    { name: "Jim Nancy", color: "#F6FF33" },
    { name: "John Garcia", color: "#83FF33" },
    { name: "John Hansen", color: "#33FFC7" },
    { name: "Manuel Best", color: "#33A8FF" },
    { name: "Robert Covington", color: "#9933FF" },
  ];
  const getDaysInMonth = (month, year) =>
    new Array(31)
      .fill("")
      .map((v, i) => new Date(year, month - 1, i + 1))
      .filter((v) => v.getMonth() === month - 1);

  const twentonemonthsObj = {};

  for (let x = 1; x < 13; x++) {
    const days = getDaysInMonth(x, "2021");
    days.forEach((d) => {
      const month = d.toLocaleString("default", { month: "short" });
      if (!twentonemonthsObj[month]) {
        twentonemonthsObj[month] = {
          [d]: [],
        };
      } else {
        twentonemonthsObj[month][d] = [];
      }
    });
  }

  const twentytwomonthsObj = {};
  for (let x = 1; x < 13; x++) {
    const days = getDaysInMonth(x, "2022");
    days.forEach((d) => {
      const month = d.toLocaleString("default", { month: "short" });
      if (!twentytwomonthsObj[month]) {
        twentytwomonthsObj[month] = {
          [d]: [],
        };
      } else {
        twentytwomonthsObj[month][d] = [];
      }
    });
  }

  const twentythreemonthsObj = {};
  for (let x = 1; x < 13; x++) {
    const days = getDaysInMonth(x, "2023");
    days.forEach((d) => {
      const month = d.toLocaleString("default", { month: "short" });
      if (!twentythreemonthsObj[month]) {
        twentythreemonthsObj[month] = {
          [d]: [],
        };
      } else {
        twentythreemonthsObj[month][d] = [];
      }
    });
  }

  const driverPromiseArray = [];
  mockDrivers.forEach((d, index) => {
    const driverId = db.collection("drivers").doc().id;
    driverPromiseArray.push(
      db
        .collection("drivers")
        .doc(driverId)
        .set({
          "2021": { ...twentonemonthsObj },
          "2022": { ...twentytwomonthsObj },
          "2023": { ...twentythreemonthsObj },
          driverId,
          driver: d.name,
          color: d.color,
        })
    );
  });

  Promise.all(driverPromiseArray).then(() => {
    console.log("finished");
  });
};

export const seedInventory = () => {
  const mockInventory = [
    "10 Yard Container",
    "15 Yard Container",
    "20 Yard Container",
    "30 Yard Container",
  ];

  const getDaysInMonth = (month, year) =>
    new Array(31)
      .fill("")
      .map((v, i) => new Date(year, month - 1, i + 1))
      .filter((v) => v.getMonth() === month - 1);

  const randomtwentyone = () => {
    return Math.floor(Math.random() * (40 - -2 + 1)) + -2;
  };
  const randomtwentytwo = () => {
    return Math.floor(Math.random() * (20 - -6 + 1)) + -6;
  };
  const randomtwentythree = () => {
    return Math.floor(Math.random() * (80 - -4 + 1)) + -4;
  };
  const randomquantity = () => {
    return Math.floor(Math.random() * (80 - 5 + 1)) + 5;
  };
  const random10YardQty = randomquantity();
  const random15YardQty = randomquantity();
  const random20YardQty = randomquantity();
  const random30YardQty = randomquantity();

  const get2021 = (quantity) => {
    const twentonemonthsObj = {};
    for (let x = 1; x < 13; x++) {
      const days = getDaysInMonth(x, "2021");
      days.forEach((d) => {
        const month = d.toLocaleString("default", { month: "short" });
        if (!twentonemonthsObj[month]) {
          twentonemonthsObj[month] = {
            [d]: quantity,
          };
        } else {
          twentonemonthsObj[month][d] = quantity;
        }
      });
    }
    return twentonemonthsObj;
  };

  const get2022 = (quantity) => {
    const twentytwomonthsObj = {};
    for (let x = 1; x < 13; x++) {
      const days = getDaysInMonth(x, "2022");
      days.forEach((d) => {
        const month = d.toLocaleString("default", { month: "short" });
        if (!twentytwomonthsObj[month]) {
          twentytwomonthsObj[month] = {
            [d]: quantity,
          };
        } else {
          twentytwomonthsObj[month][d] = quantity;
        }
      });
    }
    return twentytwomonthsObj;
  };

  const get2023 = (quantity) => {
    const twentythreemonthsObj = {};
    for (let x = 1; x < 13; x++) {
      const days = getDaysInMonth(x, "2023");
      days.forEach((d) => {
        const month = d.toLocaleString("default", { month: "short" });
        if (!twentythreemonthsObj[month]) {
          twentythreemonthsObj[month] = {
            [d]: quantity,
          };
        } else {
          twentythreemonthsObj[month][d] = quantity;
        }
      });
    }
    return twentythreemonthsObj;
  };

  const get2024 = (quantity) => {
    const twentyfourmonthsObj = {};
    for (let x = 1; x < 13; x++) {
      const days = getDaysInMonth(x, "2024");
      days.forEach((d) => {
        const month = d.toLocaleString("default", { month: "short" });
        if (!twentyfourmonthsObj[month]) {
          twentyfourmonthsObj[month] = {
            [d]: quantity,
          };
        } else {
          twentyfourmonthsObj[month][d] = quantity;
        }
      });
    }
    return twentyfourmonthsObj;
  };

  const inventoryPromiseArray = [];
  mockInventory.forEach((i, index) => {
    const inventoryId = db.collection("inventory").doc().id;
    inventoryPromiseArray.push(
      db
        .collection("inventory")
        .doc(inventoryId)
        .set({
          "2021": get2021(
            i === "10 Yard Container"
              ? random10YardQty
              : i === "15 Yard Container"
              ? random15YardQty
              : i === "20 Yard Container"
              ? random20YardQty
              : random30YardQty
          ),
          "2022": get2022(
            i === "10 Yard Container"
              ? random10YardQty
              : i === "15 Yard Container"
              ? random15YardQty
              : i === "20 Yard Container"
              ? random20YardQty
              : random30YardQty
          ),
          "2023": get2023(
            i === "10 Yard Container"
              ? random10YardQty
              : i === "15 Yard Container"
              ? random15YardQty
              : i === "20 Yard Container"
              ? random20YardQty
              : random30YardQty
          ),
          "2024": get2024(
            i === "10 Yard Container"
              ? random10YardQty
              : i === "15 Yard Container"
              ? random15YardQty
              : i === "20 Yard Container"
              ? random20YardQty
              : random30YardQty
          ),
          sortOrder: index,
          inventoryId,
          inventoryItem: i,
          cost:
            i === "10 Yard Container"
              ? 300
              : i === "15 Yard Container"
              ? 375
              : i === "20 Yard Container"
              ? 450
              : 525,
          totalQuantity:
            i === "10 Yard Container"
              ? random10YardQty
              : i === "15 Yard Container"
              ? random15YardQty
              : i === "20 Yard Container"
              ? random20YardQty
              : random30YardQty,
        })
    );
  });

  Promise.all(inventoryPromiseArray).then(() => {
    console.log("finished");
  });
};

export const deleteCollections = () => {
  db.collection("customers")
    .get()
    .then((res) => {
      res.forEach((element) => {
        element.ref.delete();
      });
      db.collection("pending-reservations")
        .get()
        .then((res) => {
          res.forEach((element) => {
            element.ref.delete();
          });
          db.collection("reservations")
            .get()
            .then((res) => {
              res.forEach((element) => {
                element.ref.delete();
              });
              db.collection("past-reservations")
                .get()
                .then((res) => {
                  res.forEach((element) => {
                    element.ref.delete();
                  });
                  return;
                });
            });
        });
    });
};

export const seedCustomers = () => {
  const mockUsers = [
    {
      name: "Rick Mayo",
      company: "Kordspace",
      email: "rick@test.com",
      phoneNumber: "(303) 432 - 3235",
      comments: ["Regular"],
      billingAddress: "3792 S Acoma St Englewood, CO",
      billingAddressLine2: "",
      customerSince: firebase.firestore.Timestamp.now(),
      firstName: "Rick",
      lastName: "Mayo",
      reservations: [],
    },
    {
      name: "Justin Turner",
      company: "Apple",
      phoneNumber: "(303) 432 - 3235",
      email: "justin@test.com",
      comments: ["Regular", "Cautious", "Overdue"],
      billingAddress: "3792 S Acoma St Englewood, CO",
      billingAddressLine2: "",
      customerSince: firebase.firestore.Timestamp.now(),
      firstName: "Justin",
      lastName: "Turner",
      reservations: [],
    },
    {
      name: "Alex Smith",
      company: "Facebook",
      email: "alex@test.com",
      comments: ["Cautious", "New"],
      phoneNumber: "(303) 432 - 3235",
      billingAddress: "3792 S Acoma St Englewood, CO",
      billingAddressLine2: "",
      customerSince: firebase.firestore.Timestamp.now(),
      firstName: "Alex",
      lastName: "Smith",
      reservations: [],
    },
    {
      name: "Bryan Callen",
      company: "Netflix",
      email: "bryan@test.com",
      comments: ["Regular", "Overdue", "Unpaid"],
      phoneNumber: "(303) 432 - 3235",
      billingAddress: "3792 S Acoma St Englewood, CO",
      billingAddressLine2: "",
      customerSince: firebase.firestore.Timestamp.now(),
      firstName: "Bryan",
      lastName: "Callen",
      reservations: [],
    },
  ];

  const createUserPromise = [];
  mockUsers.forEach((u, index) => {
    const docId = db.collection("customers").doc().id;
    createUserPromise.push(
      db
        .collection("customers")
        .doc(docId)
        .set({
          ...mockUsers,
          customerId: docId,
        })
    );
  });

  return Promise.all(createUserPromise).then(() => {
    console.log("finished");
  });
};

export const seedReservations = () => {
  const mockReservations = [
    {
      equiptment: "Standard 30 Yard",
      quantity: 1,
      startDate: "Nov 1, 2022",
      endDate: "Nov 8, 2022",
      status: "In Progress",
      poNumber: null,
      recurring: false,
      sevenDayReservation: true,
      paymentDueDate: "Nov 1, 2022",
      amount: "387.00",
      amountPaid: "50.00",
      lastEmailedDate: "Nov 1, 2022",
      siteAddress: "7216 W Portland Ave, Littleton, CO",
      inventoryId: "0PPPlJjSakcdkCyntBpY",
      equiptmentLocaion: "Backyard",
      otherInformation: "",
      promoCode: "",
      heardAboutUsFrom: "",
      existingCustomer: true,
      notes: "gate code is 7227",
      driver: "Stephen Clifton",
    },
    {
      equiptment: "30 Yard Dumpster",
      quantity: 1,
      startDate: "Nov 2, 2022",
      endDate: "Nov 9, 2022",
      status: "In Progress",
      poNumber: null,
      recurring: true,
      sevenDayReservation: true,
      paymentDueDate: "Nov 2, 2022",
      amount: "215.00",
      amountPaid: "50.00",
      lastEmailedDate: "Nov 2, 2022",
      inventoryId: "ECiG5tYWJ37XWB1FfLr1",
      siteAddress: "679 W Sandbar Cir Louisville, CO",
      equiptmentLocaion: "Backyard",
      otherInformation: "",
      promoCode: "",
      heardAboutUsFrom: "",
      existingCustomer: true,
      notes: "gate code is 227",
      driver: "Stephen Clifton",
    },
    {
      equiptment: "15 Yard Dumpster",
      quantity: 1,
      startDate: "Nov 5, 2022",
      endDate: "Nov 15, 2022",
      status: "Scheduled",
      poNumber: null,
      recurring: false,
      sevenDayReservation: false,
      paymentDueDate: "Nov 3, 2022",
      amount: "515.00",
      amountPaid: "50.00",
      lastEmailedDate: "Nov 3, 2022",
      siteAddress: "3325 Shoshone St Denver, CO",
      inventoryId: "KSFALDwvENDYFcdvcIQt",
      equiptmentLocaion: "Backyard",
      otherInformation: "",
      promoCode: "",
      heardAboutUsFrom: "",
      existingCustomer: true,
      notes: "",
      driver: "Stephen Clifton",
    },
    {
      equiptment: "10 Yard Dumpster",
      quantity: 1,
      startDate: "Nov 4, 2022",
      endDate: "Nov 14, 2022",
      status: "Unassigned",
      poNumber: null,
      recurring: false,
      sevenDayReservation: false,
      paymentDueDate: "Nov 4, 2022",
      amount: "265.00",
      amountPaid: "265.00",
      lastEmailedDate: "Nov 4, 2022",
      inventoryId: "MotAA1xikG13cxgTgWfN",
      siteAddress: "12351 W 44th Ave Wheat Ridge, CO",
      equiptmentLocaion: "Backyard",
      otherInformation: "",
      promoCode: "",
      heardAboutUsFrom: "",
      existingCustomer: true,
      notes: "",
      driver: "N/A",
    },
  ];

  return db
    .collection("customers")
    .get()
    .then((snapshot) => {
      const customers = snapshot.docs.map((d) => d.data());
      console.log("customers", customers);
      const reservationsPromiseArray = [];
      const customerReservationsPromiseArray = [];
      customers.forEach((c, index) => {
        const reservation = mockReservations[index];
        const reservationId = db.collection("reservations").doc().id;
        reservation.reservationId = reservationId;
        reservationsPromiseArray.push(
          db
            .collection("reservations")
            .doc(reservationId)
            .set({
              ...c,
              paymentDueDateTimestamp: firebase.firestore.Timestamp.fromDate(
                new Date(reservation.paymentDueDate)
              ),
              reservation: {
                ...reservation,
              },
            })
        );

        customerReservationsPromiseArray.push(
          db
            .collection("customers")
            .doc(c.customerId)
            .update({
              reservations: [reservation],
            })
        );
      });
      return Promise.all(reservationsPromiseArray).then(() => {
        return Promise.all(customerReservationsPromiseArray).then(() => {
          console.log("finished");
        });
      });
    });
};

export const seedPastReservations = () => {
  const mockPendingReservations = [
    {
      name: "Kyle Shane",
      company: "Chase",
      email: "kyleshane@test.com",
      billingAddress: "353 Pacoma St Englewood, CO",
      phoneNumber: "(303) 432 - 3223",
      comments: [],
      reservation: {
        equiptment: "20 Yard Dumpster",
        quantity: 1,
        endDate: "Nov 1, 2022",
        status: "Completed",
        poNumber: null,
        recurring: false,
        sevenDayReservation: true,
        startDate: "Oct 25, 2022",
        paymentDueDate: "Oct 25, 2022",
        inventoryId: "bFxjNpCuTTE2Fui74bMN",
        siteAddress: "7216 W Portland Ave, Littleton, CO",
        amount: "387.00",
        amountPaid: "0",
        lastEmailedDate: "N/A",
        equiptmentLocaion: "Backyard",
        otherInformation: "Other Info",
        promoCode: "PROMO",
        heardAboutUsFrom: "Google",
        existingCustomer: false,
        notes: "gate code is 7",
        driver: "Stephen Clifton",
      },
    },
    {
      name: "Allie Jordan",
      company: "Snapchat",
      email: "ajordan@test.com",
      billingAddress: "144 Tacoma Rd Englewood, CO",
      phoneNumber: "(303) 432 - 3212",
      comments: [],
      reservation: {
        equiptment: "15 Yard Dumpster",
        quantity: 1,
        endDate: "Nov 4, 2022",
        status: "Completed",
        siteAddress: "679 W Sandbar Cir Louisville, CO",
        poNumber: null,
        recurring: false,
        sevenDayReservation: false,
        startDate: "Nov 1, 2022",
        paymentDueDate: "Nov 1, 2022",
        inventoryId: "KSFALDwvENDYFcdvcIQt",
        amount: "334.00",
        amountPaid: "0",
        lastEmailedDate: "N/A",
        equiptmentLocaion: "Garage",
        otherInformation: "",
        promoCode: "",
        heardAboutUsFrom: "",
        existingCustomer: false,
        notes: "gate code is 227",
        driver: "Stephen Clifton",
      },
    },
    {
      name: "Theo Von",
      company: "Mcdonalds",
      email: "tvon@test.com",
      billingAddress: "29 Promenade Rd Englewood, CO",
      phoneNumber: "(303) 432 - 3122",
      comments: [],
      reservation: {
        equiptment: "10 Yard Dumpster",
        quantity: 1,
        startDate: "Oct 31, 2022",
        paymentDueDate: "Oct 31, 2022",
        inventoryId: "MotAA1xikG13cxgTgWfN",
        endDate: "Nov 2, 2022",
        status: "Completed",
        poNumber: null,
        recurring: false,
        siteAddress: "3325 Shoshone St Denver, CO",
        sevenDayReservation: false,
        amount: "334.00",
        amountPaid: "0",
        lastEmailedDate: "N/A",
        equiptmentLocaion: "Street",
        otherInformation: "",
        promoCode: "",
        heardAboutUsFrom: "Friend",
        existingCustomer: false,
        notes: "",
        driver: "Stephen Clifton",
      },
    },
    {
      name: "Andrew Callaghan",
      company: "Youtube",
      email: "acallaghan@test.com",
      billingAddress: "14 Monica St Englewood, CO",
      phoneNumber: "(303) 432 - 3122",
      comments: [],
      reservation: {
        equiptment: "Metal/Concrete",
        quantity: 1,
        startDate: "Nov 2, 2022",
        paymentDueDate: "Nov 2, 2022",
        endDate: "Nov 3, 2022",
        status: "Completed",
        poNumber: null,
        recurring: false,
        sevenDayReservation: false,
        siteAddress: "12351 W 44th Ave Wheat Ridge, CO",
        inventoryId: "bFu73oE5SgYlEjwEeLvD",
        amount: "289.00",
        amountPaid: "0",
        lastEmailedDate: "N/A",
        equiptmentLocaion: "Driveway",
        otherInformation: "",
        promoCode: "",
        heardAboutUsFrom: "Friend",
        existingCustomer: false,
        notes: "",
        driver: "Stephen Clifton",
      },
    },
  ];
  const pendingReservationsPromiseArray = [];
  mockPendingReservations.forEach((r) => {
    const reservationId = db.collection("past-reservations").doc().id;
    r.reservation.reservationId = reservationId;
    r.paymentDueDateTimestamp = firebase.firestore.Timestamp.fromDate(
      new Date(r.reservation.paymentDueDate)
    );
    pendingReservationsPromiseArray.push(
      db
        .collection("past-reservations")
        .doc(reservationId)
        .set({
          ...r,
        })
    );
  });
  Promise.all(pendingReservationsPromiseArray).then(() => {
    console.log("finished");
  });
};

export const seedPendingReservations = () => {
  const mockPendingReservations = [
    {
      name: "Bryan Callen",
      company: "Netflix",
      email: "bryan@test.com",
      comments: ["Regular", "Overdue", "Unpaid"],
      phoneNumber: "(303) 432 - 3235",
      billingAddress: "3792 S Acoma St Englewood, CO",
      reservation: {
        equiptment: "20 Yard Dumpster",
        quantity: 1,
        startDate: "Nov 6, 2022",
        paymentDueDate: "Nov 6, 2022",
        endDate: "Nov 13, 2022",
        status: "Unapproved",
        siteAddress: "18 Beacon Hill Ln Greenwood Village, CO",
        poNumber: null,
        recurring: false,
        sevenDayReservation: true,
        inventoryId: "bFxjNpCuTTE2Fui74bMN",
        amount: "387.00",
        amountPaid: "0",
        lastEmailedDate: "N/A",
        equiptmentLocaion: "Backyard",
        otherInformation: "Other Info",
        promoCode: "PROMO",
        heardAboutUsFrom: "Google",
        existingCustomer: false,
        notes: "gate code is 227",
        driver: "N/A",
      },
    },
    {
      name: "Allie Jordan",
      company: "Snapchat",
      email: "ajordan@test.com",
      billingAddress: "144 Tacoma Rd Englewood, CO",
      phoneNumber: "(303) 432 - 3212",
      comments: [],
      reservation: {
        equiptment: "15 Yard Dumpster",
        quantity: 1,
        startDate: "Nov 5, 2022",
        paymentDueDate: "Nov 5, 2022",
        inventoryId: "KSFALDwvENDYFcdvcIQt",
        endDate: "Nov 12, 2022",
        status: "Unapproved",
        siteAddress: "10991 Spruce Mountain Rd Larkspur, CO",
        poNumber: null,
        recurring: true,
        sevenDayReservation: true,
        amount: "334.00",
        amountPaid: "0",
        lastEmailedDate: "N/A",
        equiptmentLocaion: "Garage",
        otherInformation: "",
        promoCode: "",
        heardAboutUsFrom: "",
        existingCustomer: false,
        notes: "this is a note",
        driver: "N/A",
      },
    },
    {
      name: "Theo Von",
      company: "Mcdonalds",
      email: "tvon@test.com",
      billingAddress: "29 Promenade Rd Englewood, CO",
      phoneNumber: "(303) 432 - 3122",
      comments: [],
      reservation: {
        equiptment: "10 Yard Dumpster",
        quantity: 1,
        startDate: "Nov 8, 2022",
        paymentDueDate: "Nov 8, 2022",
        inventoryId: "MotAA1xikG13cxgTgWfN",
        endDate: "Nov 15, 2022",
        status: "Unapproved",
        poNumber: null,
        recurring: false,
        siteAddress: "573 Rachael Pl Castle Pines, CO",
        sevenDayReservation: true,
        amount: "334.00",
        amountPaid: "0",
        lastEmailedDate: "N/A",
        equiptmentLocaion: "Street",
        otherInformation: "",
        promoCode: "",
        heardAboutUsFrom: "Friend",
        existingCustomer: false,
        notes: "notessss",
        driver: "N/A",
      },
    },
    {
      name: "Andrew Callaghan",
      company: "Youtube",
      email: "acallaghan@test.com",
      billingAddress: "14 Monica St Englewood, CO",
      phoneNumber: "(303) 432 - 3122",
      comments: [],
      reservation: {
        equiptment: "Metal/Concrete",
        quantity: 1,
        startDate: "Nov 9, 2022",
        paymentDueDate: "Nov 9, 2022",
        inventoryId: "bFu73oE5SgYlEjwEeLvD",
        endDate: "Nov 15, 2022",
        status: "Unapproved",
        poNumber: null,
        recurring: false,
        siteAddress: "982 S Sheridan Blvd Denver, CO",
        sevenDayReservation: false,
        amount: "289.00",
        amountPaid: "0",
        lastEmailedDate: "N/A",
        equiptmentLocaion: "Driveway",
        otherInformation: "",
        promoCode: "",
        heardAboutUsFrom: "Friend",
        existingCustomer: false,
        notes: "",
        driver: "N/A",
      },
    },
  ];
  const pendingReservationsPromiseArray = [];
  mockPendingReservations.forEach((r) => {
    const reservationId = db.collection("pending-reservations").doc().id;
    r.reservation.reservationId = reservationId;
    r.paymentDueDateTimestamp = firebase.firestore.Timestamp.fromDate(
      new Date(r.reservation.paymentDueDate)
    );
    pendingReservationsPromiseArray.push(
      db
        .collection("pending-reservations")
        .doc(reservationId)
        .set({
          ...r,
        })
    );
  });
  Promise.all(pendingReservationsPromiseArray).then(() => {
    console.log("finished");
  });
};

export const getOneWeekLater = (startDate) => {
  const start = new Date(startDate);
  var new_date = dateAndtime.addDays(start, 7);
  var toReturn = moment(new_date).format("MM/DD/YY");
  console.log("startDate", startDate, "toReturn", toReturn);
  return toReturn;
};

export const getNextFourteen = (startDate) => {
  const start = new Date(startDate);
  var new_date = dateAndtime.addDays(start, 14);
  console.log("date start", new_date, start);
  const dateArray = [];
  for (var x = 0; x < 14; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var dateToPush = dateAndtime.addDays(new_date, x);
    // return result;
    dateArray.push(moment(dateToPush).format("ddd MM/DD/YY"));
  }

  return ["Product", "Qty", ...dateArray];
};

export const getNextFourteenWithInput = (startDate) => {
  const start = new Date(startDate);
  console.log("date start", start);
  const dateArray = [moment(start).format("ddd MM/DD/YY")];
  for (var x = 1; x < 14; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var dateToPush = dateAndtime.addDays(start, x);
    // return result;
    dateArray.push(moment(dateToPush).format("ddd MM/DD/YY"));
  }

  return ["Product", "Qty", ...dateArray];
};

export const getNextSeven = (startDate) => {
  const start = new Date(startDate);
  var new_date = dateAndtime.addDays(start, 7);
  console.log("date start", new_date, start);
  const dateArray = [];
  for (var x = 0; x < 7; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var dateToPush = dateAndtime.addDays(new_date, x);
    // return result;
    dateArray.push(moment(dateToPush).format("ddd MM/DD/YY"));
  }

  return ["Product", "Qty", ...dateArray];
};

export const getPrevSeven = (startDate) => {
  const date = new Date(startDate);

  const start = date.setDate(date.getDate() - 7);
  const officialStart = new Date(start);
  const dateArray = [];
  for (var x = 0; x < 7; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var dateToPush = dateAndtime.addDays(officialStart, x);
    // return result;
    dateArray.push(moment(dateToPush).format("ddd MM/DD/YY"));
  }

  return ["Product", "Qty", ...dateArray];
};

export const getNewDataFromCalendarSelection = (startDate) => {
  const start = new Date(startDate);
  const dateArray = [];
  for (var x = 0; x < 9; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var dateToPush = dateAndtime.addDays(start, x);
    // return result;
    dateArray.push(moment(dateToPush).format("ddd MM/DD/YY"));
  }

  return ["Product", "Qty", ...dateArray];
};

export const getPrevFourteen = (startDate) => {
  const date = new Date(startDate);

  const start = date.setDate(date.getDate() - 14);
  const officialStart = new Date(start);
  const dateArray = [];
  for (var x = 0; x < 14; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var dateToPush = dateAndtime.addDays(officialStart, x);
    // return result;
    dateArray.push(moment(dateToPush).format("ddd MM/DD/YY"));
  }

  return ["Product", "Qty", ...dateArray];
};

export function getDates(startDate) {
  const start = new Date(startDate);
  const dateArray = [];
  for (var x = 0; x < 14; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var new_date = dateAndtime.addDays(start, x);
    // return result;
    dateArray.push(moment(new_date).format("ddd MM/DD/YY"));
  }

  return ["Product", "Qty", ...dateArray];
}

export function getDatesSeven(startDate) {
  const start = new Date(startDate);
  const dateArray = [];
  for (var x = 0; x < 7; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var new_date = dateAndtime.addDays(start, x);
    // return result;
    dateArray.push(moment(new_date).format("ddd MM/DD/YY"));
  }

  return ["Product", "Qty", ...dateArray];
}

export const handleCheckIfPaymentDue = (date, data) => {
  return data.filter((r) => {
    const reservationDueDate = new Date(r.reservation.paymentDueDate).setHours(
      0,
      0,
      0,
      0
    );
    const dateToCheck = new Date(date).setHours(0, 0, 0, 0);
    if (
      reservationDueDate === dateToCheck &&
      r.reservation.amount !== r.reservation.amountPaid
    ) {
      return true;
    }
  });
};
