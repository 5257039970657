import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import EmailsTable from "./components/EmailsTable";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { MenuItem, Select } from "@material-ui/core";
// import BusinessOverview from "./components/BusinessOverview";
// import WhoOwes from "./components/WhoOwes";
// import SalesAndCustomers from "./components/SalesAndCustomers";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";


const emails = [
  {
    sentTo: "misty@simplyfloorsinc.com",
    sentAt: "04/09/21, 4:08 PM",
    subject: "Service Status: Completed Service",
    content: "Thank you for renting with Hump...",
    status: "Rejected",
  },
  {
    sentTo: "All Overdue Customers",
    sentAt: "04/09/21, 4:08 PM",
    subject: "Payment Reminder",
    content: "Hi this is Steve with the Humpty ...",
    status: "Delivered",
  },
  {
    sentTo: "misty@simplyfloorsinc.com",
    sentAt: "04/09/21, 4:08 PM",
    subject: "Unpaid Balance",
    content: "Dear Misty, URGENT: PLEASE ...",
    status: "Soft Bounced",
  },
  {
    sentTo: "misty@simplyfloorsinc.com",
    sentAt: "04/09/21, 1:03 PM",
    subject: "Reservation Change Confirmation",
    content: "Hi Misty, Thanks for picking Hum...",
    status: "Marked Spam",
  },
  {
    sentTo: "misty@simplyfloorsinc.com",
    sentAt: "04/09/21, 12:48 PM",
    subject: "Quotes: 10 Yard",
    content: "Hi this is Humpty Dump! Our 10 c...",
    status: "Hard Bounced",
  },
];

function EmailsReport(props) {
  const { classes, reservationsData, history } = props;
  return (
    <GridContainer>
      <GridItem className={classes.jobsHeaderGridItem} xs={12}>
        <h1 className={classes.title}>Email Activity</h1>
        <Button className={classes.editRentalRatesButton}>
          <AddIcon />
          Create Email
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <EmailsTable
          history={history}
          emails={emails}
          reservationsData={reservationsData}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(EmailsReport));
