import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase, storage } from "firebase/client";
import EditUserProfileCard from "./components/EditUserProfileCard";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import loader from "assets/img/loader.gif";
import InputAdornment from "@material-ui/core/InputAdornment";

import PermissionLevelCard from "./components/PermissionLevelCard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditUser(props) {
  const { classes, history } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userInfo, setUserInfo] = useState(
    props.location.state ? props.location.state.user : false
  );

  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [changedEmail, setChangedEmail] = useState(false);
  const [company, setCompany] = useState("");
  const [needPasswordModal, setNeedPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [snackBarContent, setSnackBarContent] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [showUserOnSchedule, setShowUserOnSchedule] = useState({
    yes: false,
    no: false,
  });
  const [permissionLevel, setPermissionLevel] = useState({
    superAdmin: false,
    finance: false,
    dispatcher: false,
    driver: false,
  });
  const companyPicRef = createRef(null);
  const [savingPic, setSavingPic] = useState(false);
  const [userPic, setUserPic] = useState("");
  const [fileRandomId, setFileRandomId] = useState("");

  const userUid = window.location.pathname.split("/")[3];

  useEffect(() => {
    if (!userInfo) {
      const getUser = async () => {
        return db
          .collection("users")
          .doc(userUid)
          .get()
          .then((snapshot) => {
            const data = snapshot.data();
            console.log("data", data);
            setEmail(data.email);
            setFirstName(data.firstName);
            setLastName(data.lastName);
            if (data.userOnSchedule === "Yes") {
              setShowUserOnSchedule({
                yes: true,
                no: false,
              });
            }
            if (data.userOnSchedule === "No") {
              setShowUserOnSchedule({
                yes: false,
                no: true,
              });
            }
            setPhoneNumber(data.phoneNumber ? data.phoneNumber : "");
            setUserInfo(data);
            setUserPic(data.profilePic ? data.profilePic : "");
            if (data.role === "Super Admin") {
              setPermissionLevel({
                superAdmin: true,
                finance: false,
                dispatcher: false,
                driver: false,
              });
            }
            if (data.role === "Finance") {
              setPermissionLevel({
                superAdmin: false,
                finance: true,
                dispatcher: false,
                driver: false,
              });
            }
            if (data.role === "Dispatcher") {
              setPermissionLevel({
                superAdmin: false,
                finance: false,
                dispatcher: true,
                driver: false,
              });
            }
            if (data.role === "Driver") {
              setPermissionLevel({
                superAdmin: false,
                finance: false,
                dispatcher: false,
                driver: true,
              });
            }
          });
      };
      getUser();
    } else {
      setEmail(userInfo.email);
      setFirstName(userInfo.firstName);
      setLastName(userInfo.lastName);
      if (userInfo.userOnSchedule === "Yes") {
        setShowUserOnSchedule({
          yes: true,
          no: false,
        });
      }
      if (userInfo.userOnSchedule === "No") {
        setShowUserOnSchedule({
          yes: false,
          no: true,
        });
      }
      setPhoneNumber(userInfo.phoneNumber ? userInfo.phoneNumber : "");
      setUserInfo(userInfo);
      setUserPic(userInfo.profilePic ? userInfo.profilePic : "");
      if (userInfo.role === "Super Admin") {
        setPermissionLevel({
          superAdmin: true,
          finance: false,
          dispatcher: false,
          driver: false,
        });
      }
      if (userInfo.role === "Finance") {
        setPermissionLevel({
          superAdmin: false,
          finance: true,
          dispatcher: false,
          driver: false,
        });
      }
      if (userInfo.role === "Dispatcher") {
        setPermissionLevel({
          superAdmin: false,
          finance: false,
          dispatcher: true,
          driver: false,
        });
      }
      if (userInfo.role === "Driver") {
        setPermissionLevel({
          superAdmin: false,
          finance: false,
          dispatcher: false,
          driver: true,
        });
      }
    }
  }, []);

  const saveProfileChanges = () => {
    let permission = "";
    for (var key in permissionLevel) {
      if (permissionLevel[key]) {
        if (key === "superAdmin") {
          permission = "Super Admin";
        }
        if (key === "finance") {
          permission = "Finance";
        }
        if (key === "dispatcher") {
          permission = "Dispatcher";
        }
        if (key === "driver") {
          permission = "Driver";
        }
      }
    }
    return db
      .collection("users")
      .doc(userUid)
      .update({
        firstName,
        lastName,
        name: firstName + " " + lastName,
        // email,
        phoneNumber,
        company,
        role: permission,
        userOnSchedule: showUserOnSchedule.yes
          ? "Yes"
          : showUserOnSchedule.no
            ? "No"
            : "",
      })
      .then(() => {
        setSnackBarContent("Succesfully Updated Profile");
        history.push("/admin/settings");
        window.location.reload();
      });
  };

  // const submitWithPassword = () => {
  //   return firebase
  //     .auth()
  //     .signInWithEmailAndPassword(userInfo.email, password)
  //     .then(function () {
  //       return user.updateEmail(email).then(() => {
  //         return db
  //           .collection("users")
  //           .doc(uid)
  //           .update({
  //             firstName,
  //             lastName,
  //             name: firstName + " " + lastName,
  //             email,
  //             website,
  //             phoneNumber,
  //             secondaryPhoneNumber,
  //             company,
  //           })
  //           .then(() => {
  //             setSnackBarContent("Succesfully Updated Profile");
  //             history.push("/admin/profile");
  //           });
  //       });
  //     })
  //     .catch(function (err) {
  //       //show alert with error
  //       setSnackBarContent(err.message);
  //       // console.log("err", err);
  //     });
  // };
  console.log("uiduid", userUid);

  const handleProfilePictureChange = (event) => {
    const storageRef = storage.ref().child(`${userUid}/profilePic`);
    setSavingPic(true);
    const file = event.target.files[0];
    return storageRef.put(file).then(() => {
      storageRef.getDownloadURL().then((url) => {
        return db
          .collection("users")
          .doc(userUid)
          .update({ profilePic: url })
          .then(() => {
            setUserPic(url);
            setSavingPic(false);
          });
      });
    });
  };

  const handleProfilePicDelete = () => {
    const storageRef = storage.ref().child(`${userUid}/profilePic`);
    storageRef.delete().then(() => {
      setFileRandomId("");
      setUserPic("");
      return db
        .collection("users")
        .doc(userUid)
        .update({
          profilePic: "",
        })
        .then(() => { });
    });
  };

  if (!userInfo)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h1 style={{ marginBottom: "30px" }} className={classes.title}>
          Edit User
        </h1>
      </GridItem>
      <GridItem className={classes.marginBottom} xs={12}>
        <EditUserProfileCard
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          secondaryPhoneNumber={secondaryPhoneNumber}
          setSecondaryPhoneNumber={setSecondaryPhoneNumber}
          email={email}
          setEmail={setEmail}
          company={company}
          setCompany={setCompany}
          userPic={userPic}
          handleProfilePictureChange={handleProfilePictureChange}
          setUserPic={setUserPic}
          history={history}
          setChangedEmail={setChangedEmail}
          // userInfo={userInfo}
          showUserOnSchedule={showUserOnSchedule}
          setShowUserOnSchedule={setShowUserOnSchedule}
          companyPicRef={companyPicRef}
          savingPic={savingPic}
          setSavingPic={setSavingPic}
          handleProfilePicDelete={handleProfilePicDelete}
          permissionLevel={permissionLevel}
          setPermissionLevel={setPermissionLevel}
        />
      </GridItem>
      <GridItem xs={12} className={classes.marginBottom}>
        <PermissionLevelCard
          permissionLevel={permissionLevel}
          setPermissionLevel={setPermissionLevel}
        />
      </GridItem>
      <GridItem className={classes.spaceBetween} xs={12}>
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.push("/admin/settings")}
        >
          Cancel
        </Button>

        <Button
          style={{ background: "#D40707", color: "white" }}
          className={classes.cancelSaveButton}
        // onClick={() => history.push("/admin/settings")}
        >
          Delete
        </Button>


        <Button
          // onClick={() => {
          //   if (changedEmail) {
          //     setNeedPasswordModal(true);
          //   } else {
          //     saveProfileChanges();
          //   }
          // }}
          onClick={saveProfileChanges}
          style={{
            background: "#FFC821",
            color: "#323232",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={needPasswordModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNeedPasswordModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <Card className={classes.modalCard}>
          <h1 className={classes.modalTitle}>
            Please Provide Your Password To Make These Changes
          </h1>
          <CardBody className={classes.body}>
            <GridContainer>
              <GridItem xs={12}>
                <GridItem className={classes.columnGridItem} xs={12}>
                  <span className={classes.inputTypeTitle}>Password</span>
                  {/* <TextField
                    className={classes.passwordField}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    type="password"
                  /> */}
                  <TextField
                    // className={classes.searchBar}
                    placeholder="Password"
                    value={password}
                    type={passwordVisible ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        adornedEnd: classes.adournedEnd,
                      },
                      endAdornment: (
                        <InputAdornment
                          onClick={() => {
                            if (passwordVisible) {
                              setPasswordVisible(false);
                            } else {
                              setPasswordVisible(true);
                            }
                          }}
                          className={classes.adournedEnd}
                          position="end"
                        >
                          <Eyecon className={classes.eyecon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Button
                    // onClick={submitWithPassword}
                    className={classes.submitButton}
                  >
                    Submit
                  </Button>
                </GridItem>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </Dialog>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent !== "Succesfully Updated Profile"
              ? "error"
              : "success"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(EditUser));
