import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Card from "components/Card/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import { reportSelections } from "../../config/globals";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

const headers = [
  "Date",
  "Type",
  "Customer",
  "Reference #",
  "Transaction ID",
  "Amount",
];

const sortByOptions = ["Total Sales", "Scheduled Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];
const pageArr = ["5", "All"];
const viewOptions = ["Yesterday", "Today", "This Month", "This Year"];

function DetailData(props) {
  const { classes, DetailData, history } = props;
  const [tableSelection, setTableSelection] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [sortBy, setSortBy] = useState("Total Sales");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterBySelection, setFilterBySelection] = useState("Filter By");
  const [searchString, setSearchString] = useState("");
  const [type, setType] = useState("Today");
  const itemsPerPage = 5;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("5")

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)
  };

  useEffect(() => {
    DetailData.slice(index, endIndex)
  }, [index])
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if (pageDropdown == "5") {
      return DetailData
        .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
        .map((d, index) => {
          return (
            <tr className={classes.tableRow} key={index}>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                {d.date}
              </td>
              <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
                {d.type}
              </td>
              <td className={classes.tableCell}>
                <span
                  // onClick={() =>
                  //   history.push({
                  //     pathname: `/admin/customers/${d.customerId}`,
                  //     state: { customer: d },
                  //   })
                  // }
                  className={classes.toCustomerProfileText}
                >
                  {d.customer}
                </span>
              </td>
              <td className={classes.tableCell}>{d.referenceNumber}</td>

              <td className={classes.tableCell}>{d.txId}</td>
              <td className={classes.tableCell}>{d.total}</td>
            </tr>
          );
        });
    }
    else {
      return DetailData.map((d, index) => {
        return (
          <tr className={classes.tableRow} key={index}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              {d.date}
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.type}
            </td>
            <td className={classes.tableCell}>{d.customer}</td>
            <td className={classes.tableCell}>{d.referenceNumber}</td>

            <td className={classes.tableCell}>{d.txId}</td>
            <td className={classes.tableCell}>{d.total}</td>
          </tr>
        );
      });
    }

  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "20px",
              maxHeight: "80px",
            }}
          >
            <div style={{ width: "43%" }} className={classes.agingGridItem}>
              <span className={classes.switchReport}>Switch Report</span>
              <Select
                variant="outlined"
                className={classes.summaryFilterMenu}
                value={tableSelection}
                sx={{ background: "#F5F5F5" }}
                disableUnderline
                defaultValue={"Select Option"}
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => history.push(e.target.value.link)}
                displayEmpty
                renderValue={
                  tableSelection !== "" ? undefined : () => "Deposit Detail"
                }
              >
                {reportSelections.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItemReport,
                      }}
                    >
                      {o.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div
              style={{ justifyContent: "end" }}
              className={classes.agingGridItem}
            >
              <div
                style={{
                  display: "flex",
                  border: "2px solid #323232",
                  borderRadius: "5px",
                  background: "#323232",
                  width: "70%",
                  height: "60px",
                  fontSize: "15px",
                  whiteSpace: "noWrap"
                }}
              >
                {viewOptions.map((v, index) => {
                  if (v === type) {
                    return (
                      <div
                        key={index}
                        onClick={() => setType(v)}
                        className={classes.viewSelection}
                        style={{
                          background: "#323232",
                          color: "white",
                          padding: "10px",
                          fontSize: "15px",
                        }}
                      >
                        {v}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        onClick={() => setType(v)}
                        className={classes.viewSelection}
                        style={{
                          background: "white",
                          color: "#323232",
                          padding: "10px",
                          fontSize: "15px",
                        }}
                      >
                        {v}
                      </div>
                    );
                  }
                })}
              </div>
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "50%",
                  margin: "20px",
                }}
                selected={fromDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setFromDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      style={{ marginLeft: "30px", width: "70%" }}
                      className={classes.datetime}
                      placeholder="From"
                      value={fromDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "50%",
                  margin: "20px",
                }}
                selected={toDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setToDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      style={{ marginLeft: "30px", width: "70%" }}
                      className={classes.datetime}
                      placeholder="To"
                      value={toDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Button
                style={{
                  width: "25%",
                  margin: "5px 5px 5px 20px",
                }}
                className={classes.exportCSV}>
                Export CSV
              </Button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "20px",
              maxHeight: "80px",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "80%", display: "flex", alignItems: "center", justifyContent: "space-between" }}
            >
              <h5 className={classes.agingText}>
                Deposit Total ($2,316,931.05)
              </h5>
              <TextField
                style={{ width: "55%" }}
                className={classes.agingSearchBar}
                placeholder="Search"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                variant="outlined"
                // onKeyDown={(e) => {
                //   if (e.keyCode === 13) {
                //     handleSearch();
                //   }
                // }}
                InputProps={{
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <Button
                        onClick={() => console.log("search")}
                        className={classes.searchButton2}
                      >
                        Search
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <h5 className={classes.switchReport}>Sort By</h5>
              <Select
                style={{ width: "24%" }}
                className={classes.sortByMenu}
                value={sortBy}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => setSortBy(e.target.value)}
              >
                {sortByOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                className={classes.sortByMenu}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                value={directionSelection}
                onChange={(e) => setDirectionSelection(e.target.value)}
              >
                {directionOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                className={classes.sortByMenu}
                value={filterBySelection}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => setFilterBySelection(e.target.value)}
              >
                {filterOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>

          <div style={pageDropdown != 5 && DetailData.length > 5 ? { height: "500px", width: "100%", overflowY: "scroll", padding: "0px 30px 10px 20px" } : { padding: "0px 30px 10px 20px", }}>
            <table className={classes.inventoryTable}>
              <tbody>
                {renderTableHeader()}
                {renderTableData()}
              </tbody>
            </table>
          </div>
          {pageDropdown == "5" ?
            <GridContainer style={{ padding: "0px 30px 0px 30px" }}>
              <GridItem xs={6} >
                <p className={classes.view}>View
                  <span>
                    <Select
                      style={{ width: "13%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                      disableUnderline
                      MenuProps={{
                        // className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor
                      }}

                      onChange={(e) => {
                        setPageDrowdown(e.target.value)
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
              <GridItem xs={4} >
                <h5 className={classes.indexes2}>{index + 1} - {endIndex} of
                  {DetailData.length <= itemsPerPage ? " " + 1 :
                    " " + Math.ceil(DetailData.length / itemsPerPage)}
                </h5>
              </GridItem>
              <GridItem xs={2}>
                <ReactPaginate
                  paginationSize={0}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(DetailData.length / itemsPerPage)}
                  nextLabel={<ArrowRight style={{ fontSize: 25, fontWeight: "bold", color: "white", marginBottom: "-8px" }} />}
                  previousLabel={<ArrowLeft style={{ fontSize: 25, fontWeight: "bold", color: "white", marginBottom: "-8px" }} />}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </GridItem>
            </GridContainer>
            :
            <GridContainer style={{ padding: "0px 30px 0px 30px" }}>
              <GridItem xs={6}>
                <p className={classes.view}>View
                  <span>
                    <Select
                      style={{ width: "13%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                      disableUnderline
                      MenuProps={{
                        // className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor
                      }}
                      onChange={(e) => {
                        setPageDrowdown(e.target.value)
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
            </GridContainer>
          }
        </Card>
      </GridItem>
    </GridContainer >
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(DetailData));
