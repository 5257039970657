import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from "firebase/client.js";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import CreateQuoteHeader from "./components/CreateQuoteHeader";
import ChangeDetails from "./components/ChangeDetails"
import loader from "assets/img/loader.gif";
import Button from "@material-ui/core/Button";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { addQuote } from "store/actions/quotes/quotesActions";
import ChangeOtherInfo from "./components/ChangeOtherInfo";

const locationSelections = [
    "Select Option",
    "Right Side of Driveway",
    "Left Side of Driveway",
    "Middle of Driveway",
    "On Street",
    "Side of House",
    "Backyard",
    "Alley",
    "Other (Please specify below)"
];

function RequestChange(props) {
    const { classes, history, handleAddQuote, user } = props;
    const [quoteType, setQuoteType] = useState({
        individual: false,
        business: false,
    });
    const [textOrEmail, setTextOrEmail] = useState({
        text: false,
        email: false,
    });
    const [closeDropdown, setCloseDropdown] = useState(false)
    const [filteredData, setFilteredData] = useState([])
    const [customerInfo, setCustomerInfo] = useState("")
    const [customerFound, setCustomerFound] = useState("")
    const [customerReservationData, setCustomerReservationData] = useState([])
    const [customerId, setCustomerId] = useState("")
    const [customer, setCustomer] = useState("");
    const [site, setSite] = useState("");
    const [productType, setProductType] = useState("");
    const [quantity, setQuantity] = useState(0);
    const [rate, setRate] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [notes, setNotes] = useState("");
    const [type, setType] = useState({
        rental: false,
        job: false,
        invoice: false
    })

    const [errors, setErrors] = useState({
        pickUpAddress: false,
        swapDate: false,
        sevenDayRental: false,
        pickupDate: false,
        startDate: false,
        equipmentNeeded: false,
        equipmentLocation: false,
        schedulingConsent: false
    });

    const handleSubmit = () => {
        if (
            !customer ||
            !productType ||
            quantity === 0 ||
            !quantity ||
            !rate ||
            !startDate ||
            !endDate ||
            (!quoteType.individual && !quoteType.business) ||
            (!textOrEmail.text && !textOrEmail.email) ||
            !site
        ) {
            let newErrors = {
                customer: false,
                productType: false,
                quantity: false,
                rate: false,
                startDate: false,
                endDate: false,
                communication: false,
                quoteType: false,
                textOrEmail: false,
                site: false,
            };
            if (!customer) {
                newErrors = { ...newErrors, customer: true };
            }
            if (!productType) {
                newErrors = { ...newErrors, productType: true };
            }
            if (!quantity || quantity === 0) {
                newErrors = { ...newErrors, quantity: true };
            }
            if (!rate) {
                newErrors = { ...newErrors, rate: true };
            }
            if (!startDate) {
                newErrors = { ...newErrors, startDate: true };
            }
            if (!endDate) {
                newErrors = { ...newErrors, endDate: true };
            }
            if (!quoteType.individual && !quoteType.business) {
                newErrors = { ...newErrors, quoteType: true };
            }
            if (!textOrEmail.email && !quoteType.text) {
                newErrors = { ...newErrors, textOrEmail: true };
            }
            if (!site) {
                newErrors = { ...newErrors, site: true };
            }
            setErrors({ ...newErrors });
        } else {
            //   const pendingQuote = {
            //     ...customer,
            //     quoteType: quoteType.individual ? "Individual" : "Business",
            //     communicationPreference: textOrEmail.text ? "Text" : "Email",
            //     quoteTimestamp: firebase.firestore.Timestamp.fromDate(new Date()),
            //     reservation: {
            //       amount: rate,
            //       amountPaid: "0",
            //       driver: "N/A",
            //       endDate,
            //       territory: "",
            //       equiptment: productType.inventoryItem,
            //       dropOffCity: site.city,
            //       dropOffState: "N/A",
            //       dropOffZipCode: "N/A",
            //       equiptmentLocation: "N/A",
            //       existingCustomer: true,
            //       heardAboutUsFrom: "N/A",
            //       inventoryId: productType.inventoryId,
            //       lastEmailedDate: "N/A",
            //       notes: notes,
            //       otherInformation: "",
            //       paymentDueDate: endDate,
            //       poNumber: "",
            //       promoCode: "",
            //       quantity,
            //       recurring: false,
            //       sevenDayReservation:
            //         getARangeOfDays(startDate, endDate) === 7 ? true : false,
            //       siteAddress: site.siteAddress,
            //       siteId: site.siteId,
            //       startDate,
            //       status: "Unapproved",
            //       spokenWithTeamMember: false,
            //     },
            //   };
            //   handleAddQuote(pendingQuote);
        }
    };

    return (
        <GridContainer>
            <GridItem className={classes.marginBottomGridItem} xs={12}>
                <CreateQuoteHeader />
            </GridItem>
            <GridItem className={classes.marginBottomGridItem} xs={12}>
                <ChangeDetails
                    type={type}
                    setType={setType}
                    history={history}
                    errors={errors}
                />
            </GridItem>
            <GridItem className={classes.marginBottomGridItem} xs={12}>
                <ChangeOtherInfo
                    history={history}
                    errors={errors}
                    notes={notes}
                    setNotes={setNotes}
                />
            </GridItem>
            <GridItem className={classes.cancelSaveBtnGrid} xs={12}>
                <Button
                    style={{ background: "#323232", color: "white" }}
                    className={classes.cancelSaveButton}
                    onClick={() => history.push("/admin/settings")}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    style={{
                        background: "#FFC821",
                        color: "#323232",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                    }}
                    className={classes.cancelSaveButton}
                >
                    <CheckIcon />
                    Save
                </Button>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        customersData: state.customers.customers,
        user: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleAddQuote: (quote) => dispatch(addQuote(quote)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(reservationDetailStyle)
)(React.memo(RequestChange));
