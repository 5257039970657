import {
  whiteColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

const customTabsStyle = {
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px",
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important",
  },
  tabsRoot: {
    minHeight: "unset !important",
    background: "#D9D9D9",
    borderWidth: "0.5px",
    borderStyle: "solid",
    borderColor: "#929292",
    borderRadius: "2px 2px 0px 0px",
    "& $tabRootButton": {
      fontSize: "0.875rem",
    },
  },
  tabsRoot2: {
    minHeight: "unset !important",
    // background: "#D9D9D9",
    borderWidth: "0.5px",
    // borderStyle: "solid",
    borderColor: "#929292",
    borderRadius: "2px 2px 0px 0px",
    "& $tabRootButton": {
      fontSize: "0.875rem",
    },
  },
  filterMenu: {
    background: "white",
    borderRadius: "7px",
    height: "50px",
    marginRight: "15px",
  },
  filtersGridItem: {
    display: "flex",
    alignItems: "center",
  },
  ascendingMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    height: "50px",
    marginRight: "15px",
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "65px 0px",
  },
  tabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    padding: "15px !important",
    maxHeight: "unset !important",
    borderRadius: "3px",
    margin: "0px",
  },
  tabLabelContainer: {
    padding: "0px",
  },
  tabLabel: {
    fontWeight: "500",
    fontSize: "12px",
  },
  tabSelected: {
    backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.2)",
    transition: "0.2s background-color 0.1s",
  },
  tabWrapper: {
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    textTransform: "capitalize",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    fontFamily: "Archivo !important",
    fontStyle: "normal !important",
    fontWeight: 600,
    fontSize: "17px !important",
    lineHeight: "18px !important",
    color: "#323232 !important",
    "& > svg,& > .fab,& > .fas,& > .far,& > .fal,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0 !important",
    },
  },
};

export default customTabsStyle;
