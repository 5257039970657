import {
  successColor,
  tooltip,
  cardTitle,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";
import EggCount from "assets/img/eggCount.png";

const inventoryAvailabilityStyle = (theme) => ({
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center",
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0",
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: grayColor[0],
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px",
    },
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0",
  },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: "17px",
    height: "17px",
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px",
    },
  },
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    marginTop: "0px",
  },
  remindersGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  tableRow: {
    height: "45px",
  },
  eggNumberCount: {
    color: "#323232",
    padding: "14px",
    backgroundImage: `url(${EggCount})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "52px",
    textAlign: "center",
  },
  paymentsDue: {
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "17px",
    textDecoration: "underline",
  },
  searchGridContainer: {
    display: "flex",
    flexDirection: "column",
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "35px 0px",
  },
  inventorySubHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "35px 0px 0px 0px",
  },
  searchBar: {
    width: "100%",
    background: "white",
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "19px 30px !important",
    color: "#323232",
    "&:hover": {
      background: "#FFC720",
    },
  },
  inventoryTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    // [theme.breakpoints.down("md")]: {
    //   tableLayout: "fixed",
    //   width: "100%",
    // },
  },
  inventoryHeaderGridItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  seeMore: {
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "17px",
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
  },
  tableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    textAlign:"left",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  yellowTableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    background: "#F1C84E",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  redTableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    background: "#F83030",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  whiteTableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  frequentButtonDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "20%",
    padding: "10px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pendingButtonDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "25%",
    padding: "10px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pendingReservationsTableCell: {
    display: "flex",
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    background: "#FFFFFF",
    width: "30%",
    // fontFamily: "Archivo",
    // fontStyle: "normal",
    // fontWeight: 400,
    // fontSize: "19px",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  pendingReservationsAddressTableCell: {
    display: "flex",
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    background: "#FFFFFF",
    width: "35%",
    // fontFamily: "Archivo",
    // fontStyle: "normal",
    // fontWeight: 400,
    // fontSize: "19px",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  pendingReservationsButtonsTableCell: {
    display: "flex",
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    background: "#FFFFFF",
    width: "35%",
    // fontFamily: "Archivo",
    // fontStyle: "normal",
    // fontWeight: 400,
    // fontSize: "19px",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  pendingReservationsTableCellName: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "15px",
    textAlign: "center",
  },
  pendingReservationsTableCellAddress: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    textAlign: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  arrowIcon: {
    fontSize: "50px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  calendarIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  link: {
    display: "flex",
    color: "#323232 !important",
  },
  modalCard: {
    margin: "0px",
    background: "rgb(243, 250, 255)",
  },
  modalTitle: {
    textAlign: "center",
    background: "#F1C84E !important",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "30px",
    margin: "0px",
    padding: "30px",
  },
  modalCardBody: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
  },
  modalCardButton: {
    width: "30%",
    height: "60px",
    color: "#323232",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nameAndCompanyText: {
    padding: "0px 5px",
    color: "#0071C5",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  viewSelectionDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "7px 0px 0px 7px",
  },
  viewSelection: {
    padding: "13px",
    border: "1px solid #F1C84E",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  dateRange: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#323232",
    marginRight: "30px"
  },
  calendarIconDiv: {
    background: "#F1C84E",
    borderRadius: "7px",
    padding: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  select: {
    // width: "20%",
    // marginLeft: "10px",
    // marginTop: "5px",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    background: "#323232",
    '&.MuiSelect-select:focus ': {
      background: "#323232",
      color: "white",
      fontWeight: "bold",
      borderRadius: "5px",
    },
    '& .MuiInput-underline:after': {
      borderBottom: "2px solid red"
    }
  },
  whiteColor: {
    color: "white",
  },
  today: {
    width: "150px",
    alignItems: "center",
    background: "#929292",
    borderRadius: "7px",
    fontSize: "24px",
    lineHeight: "26px",
    marginRight: "30px",
    marginTop: "10px",
    height: "40px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    display: "inline-grid"
  }
});

export default inventoryAvailabilityStyle;
