import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase, sendInvoiceLinkLocalHost } from "firebase/client";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import loader from "assets/img/loader.gif";

import PoCard from "./components/PoCard";
import CustomerCard from "./components/CustomerCard";
import ScheduleJobFor from "./components/ScheduleJobFor";
import DriverNotes from "./components/DriverNotes";
import Attachments from "./components/Attachments";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

function CreateNewJob(props) {
  const { classes, history, userInfo } = props;
  const [snackBarContent, setSnackBarContent] = useState("");
  const [poNumber, setPoNumber] = useState("");
  const [jobName, setJobName] = useState("");
  const [siteInfo, setSiteInfo] = useState("");

  const [customer, setCustomer] = useState("");
  const [site, setSite] = useState("");
  const [jobType, setJobType] = useState("");
  const [productType, setProductType] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [date, setDate] = useState("");
  const [driverNotes, setDriverNotes] = useState("");
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState("");
  const [customerReservations, setCustomerReservations] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [savingImage, setSavingImage] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState("");

  const jobId = db.collection("jobs").doc().id;

  const [errors, setErrors] = useState({
    customer: false,
    date: false,
    jobType: false,
    productType: false,
    driver: false,
    reservation: false,
  });

  useEffect(() => {
    if (customer) {
      const getCustomerReservationsAndJobIds = async () => {
        const reservationsPromiseArray = customer.reservations.map((rId) =>
          db.collection("reservations").doc(rId).get()
        );
        Promise.all(reservationsPromiseArray).then((results) => {
          const reservationsData = results.map((r) => r.data());
          setCustomerReservations(reservationsData);
          console.log("reservationsData", reservationsData);
        });
      };
      getCustomerReservationsAndJobIds();
    }
  }, [customer]);

  const getJobNumber = (count) => {
    const prefix = Math.floor(count / 1000);
    let num = count;
    if (prefix) {
      num = count - prefix * 1000;
    }
    return `J${prefix}-${num}`;
  }

  const saveJob = async () => {
    if (
      !customer ||
      !date ||
      !jobType ||
      !productType ||
      !selectedDriver ||
      !selectedReservation
    ) {
      let newErrors = {
        customer: false,
        date: false,
        jobType: false,
        productType: false,
        driver: false,
        reservation: false,
      };
      if (!customer) {
        newErrors = { ...newErrors, customer: true };
      }
      if (!date) {
        newErrors = { ...newErrors, date: true };
      }
      if (!jobType) {
        newErrors = { ...newErrors, jobType: true };
      }
      if (!productType) {
        newErrors = { ...newErrors, productType: true };
      }
      if (!selectedDriver) {
        newErrors = { ...newErrors, driver: true };
      }
      if (!selectedReservation) {
        newErrors = { ...newErrors, reservation: true };
      }
      setErrors({ ...newErrors });
    } else {
      const jobCount = await db.collection("counts").doc("jobs");
      const jobNumber = getJobNumber(jobCount);
      return db
        .collection("jobs")
        .doc(jobId)
        .set({
          jobName,
          siteId: siteInfo ? siteInfo.siteId : "",
          customerId: customer.customerId,
          jobType,
          jobNumber,
          driverId: selectedDriver.driverId,
          date,
          driverNotes,
          attachments,
          reservationId: selectedReservation.reservation.reservationId,
          inventoryId: productType.inventoryId,
        })
        .then(() => {
          setSnackBarContent("Successfully created new job");
          db.collection("counts")
            .doc("jobs")
            .update({ count: jobCount + 1 });

          // need to add job id to driver data but will require a BIG refactor

          // const scheduledDate = new Date(date);
          // const scheduleYear = scheduledDate.getFullYear();
          // const scheduleMonth = scheduledDate.toLocaleString("default", {
          //   month: "short",
          // });

          // const selectedDriverCopy = { ...selectedDriver };
          // selectedDriver[scheduleYear][scheduleMonth][scheduledDate] = [
          //   ...selectedDriver[scheduleYear][scheduleMonth][scheduledDate],
          //   jobId,
          // ];
          // return db
          //   .collection("drivers")
          //   .doc(selectedDriver.driverId)
          //   .update({
          //     ...selectedDriverCopy,
          //   })
          //   .then(() => {
          //     setSnackBarContent("Successfully created new job");
          //   });
        });
    }
  };

  return (
    <GridContainer>
      <GridItem
        className={classes.alignItems + " " + classes.marginBottom}
        xs={12}
      >
        <h1 style={{ marginBottom: "0px" }} className={classes.title}>
          Create Job
        </h1>
      </GridItem>

      <GridItem
        className={classes.alignItems + " " + classes.marginBottom}
        xs={12}
      >
        <PoCard
          jobName={jobName}
          setJobName={setJobName}
          poNumber={poNumber}
          setPoNumber={setPoNumber}
        />
      </GridItem>

      <GridItem
        className={classes.alignItems + " " + classes.marginBottom}
        xs={12}
      >
        <CustomerCard
          customer={customer}
          setCustomer={setCustomer}
          site={site}
          setSite={setSite}
          errors={errors}
          siteInfo={siteInfo}
          setSiteInfo={setSiteInfo}
          customerReservations={customerReservations}
          setSelectedReservation={setSelectedReservation}
        />
      </GridItem>

      <GridItem
        className={classes.alignItems + " " + classes.marginBottom}
        xs={12}
      >
        <ScheduleJobFor
          productType={productType}
          setProductType={setProductType}
          jobType={jobType}
          setJobType={setJobType}
          selectedDriver={selectedDriver}
          setSelectedDriver={setSelectedDriver}
          errors={errors}
          date={date}
          setDate={setDate}
        />
      </GridItem>

      <GridItem className={classes.columnGridItem} xs={12}>
        <Attachments
          history={history}
          setAttachments={setAttachments}
          attachments={attachments}
          setSavingImage={setSavingImage}
          savingImage={savingImage}
          jobId={jobId}
          sendConfirmationEmail={sendConfirmationEmail}
          setSendConfirmationEmail={setSendConfirmationEmail}
        />
      </GridItem>

      <GridItem
        className={classes.alignItems + " " + classes.marginBottom}
        xs={12}
      >
        <DriverNotes
          driverNotes={driverNotes}
          setDriverNotes={setDriverNotes}
          sendConfirmationEmail={sendConfirmationEmail}
          setSendConfirmationEmail={setSendConfirmationEmail}
        />
      </GridItem>

      <GridItem
        className={classes.spaceBetween + " " + classes.marginTop}
        xs={12}
      >
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button
          onClick={saveJob}
          style={{
            background: "#FFC821",
            color: "black",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent.includes("Successfully") ? "success" : "error"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(CreateNewJob));
