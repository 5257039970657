import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Button from "@material-ui/core/Button";
import { db, firebase } from "firebase/client";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import PONumberCard from "./components/PONumberCard.jsx";
import CustomerCard from "./components/CustomerCard.jsx";
import DeliveryJobCard from "./components/DeliveryJobCard.jsx";
import PickUpCard from "./components/PickUpCard.jsx";
import ServiceCard from "./components/ServiceCard.jsx";

function AddRental(props) {
  const { classes, history, userInfo, drivers, inventoryProp } = props;
  const [snackBarContent, setSnackBarContent] = useState("");

  const [poNumber, setPoNumber] = useState("");
  const [customerInfo, setCustomerInfo] = useState(false);
  const [site, setSite] = useState({});
  const [driver, setDriver] = useState({});

  const [attachments, setAttachments] = useState([]);
  const [savingImage, setSavingImage] = useState(false);
  const [errors, setErrors] = useState({
    customer: false,
    invoiceDate: false,
    driver: false,
    site: false,
    inventory: false,
  });
  const [inventory, setInventory] = useState({
    tenYard: 0,
    fifteenYard: 0,
    twentyYard: 0,
    thirtyYard: 0,
    metalOrConcrete: 0,
    standardThirty: 0,
  });
  const [selectedInventory, setSelectedInventory] = useState({
    tenYard: 0,
    fifteenYard: 0,
    twentyYard: 0,
    thirtyYard: 0,
    metalOrConcrete: 0,
    standardThirty: 0,
  });
  const [form, setForm] = useState({
    notes: "",
    invoiceDate: null,
    driver: null,
    emergencyJob: false,
    recurringJob: false,
  });
  const newRentalId = db.collection("invoices").doc().id;

  const handlePONumber = (poNum) => {
    setPoNumber(poNum);
  };

  const handleCustomerChange = (cust) => {
    setCustomerInfo(cust);
  };

  const handleSiteChange = (site) => {
    setSite(site);
  };

  useEffect(() => {
    const inventoryState = { ...inventory };
    inventoryProp.forEach((item) => {
      switch (item.inventoryItem) {
        case "10 Yard Container":
          inventoryState.tenYard = item.totalQuantity; // Will need to refactor to account for days
          break;
        case "15 Yard Container":
          inventoryState.fifteenYard = item.totalQuantity;
          break;
        case "20 Yard Container":
          inventoryState.twentyYard = item.totalQuantity;
          break;
        case "30 Yard Container":
          inventoryState.thirtyYard = item.totalQuantity;
          break;
        case "Metal or Concrete":
          inventoryState.metalOrConcrete = item.totalQuantity;
          break;
        case "Standard 30 Yard":
          inventoryState.standardThirty = item.totalQuantity;
          break;
      }
    });
    setInventory(inventoryState);
  }, [inventoryProp]);

  const convertDateToTimestamp = (date) => {
    const dateParts = date.split("/");
    const year = parseInt(dateParts[2], 10) + 2000;
    const month = parseInt(dateParts[0], 10) - 1;
    const day = parseInt(dateParts[1], 10);
    const dateObject = new Date(year, month, day);
    return dateObject.toString();
  };

  const getInventoryIds = () => {
    const selectedInventories = Object.entries(selectedInventory)
      .filter(([key, value]) => value !== 0)
      .map(([key, val]) => key);
    const combinedInventory = inventoryProp
      .map((item) => {
        const itemId = item.inventoryId;
        switch (item.inventoryItem) {
          case "10 Yard Container":
            if (selectedInventories.includes("tenYard")) {
              return { itemId, quantity: selectedInventory.tenYard };
            }
            break;
          case "15 Yard Container":
            if (selectedInventories.includes("fifteenYard")) {
              return { itemId, quantity: selectedInventory.fifteenYard };
            }
            break;
          case "20 Yard Container":
            if (selectedInventories.includes("twentyYard")) {
              return { itemId, quantity: selectedInventory.twentyYard };
            }
            break;
          case "30 Yard Container":
            if (selectedInventories.includes("thirtyYard")) {
              return { itemId, quantity: selectedInventory.thirtyYard };
            }
            break;
          case "Metal or Concrete":
            if (selectedInventories.includes("metalOrConcrete")) {
              return { itemId, quantity: selectedInventory.metalOrConcrete };
            }
            break;
          case "Standard 30 Yard":
            if (selectedInventories.includes("standardThirty")) {
              return { itemId, quantity: selectedInventory.standardThirty };
            }
            break;
        }
      })
      .filter((val) => val !== undefined);
    const inventoryIds = combinedInventory.map((item) => item.itemId);
    return { combinedInventory, inventoryIds };
  };

  const onSubmit = () => {
    const inventoryIds = getInventoryIds();
    if (
      !customerInfo ||
      !driver ||
      !site ||
      !inventoryIds.inventoryIds.length ||
      !form.invoiceDate
    ) {
      let newErrors = {
        customer: false,
        invoiceDate: false,
        site: false,
        inventory: false,
        driver: false,
      };
      if (!customerInfo) {
        newErrors = { ...newErrors, customer: true };
      }
      if (!driver) {
        newErrors = { ...newErrors, driver: true };
      }
      if (!site) {
        newErrors = { ...newErrors, site: true };
      }
      if (!inventoryIds.inventoryIds.length) {
        newErrors = { ...newErrors, inventory: true };
      }
      if (!form.invoiceDate) {
        newErrors = { ...newErrors, invoiceDate: true };
      }
      setErrors(newErrors);
    } else {
      const rentalData = {
        customerId: customerInfo.customerId,
        reservation: {
          amount: "",
          amountPaid: "0",
          attachments,
          driver: driver.driver,
          dropOffAddressLine2: "",
          dropOffCity: site.city,
          dropOffZipCode: "",
          dropOffState: "",
          endDate: "",
          equiptment: selectedInventory,
          equiptmentLocation: "",
          existingCustomer: true,
          inventoryId: inventoryIds.inventoryIds,
          invoiceId: "",
          jobs: {
            delivery: {
              driverId: driver.driverId,
              driverName: driver.driver,
            },
            pickup: {
              driverId: "",
              driverName: "",
            },
          },
          lastEmailedDate: "",
          notes: form.notes,
          otherInformation: "",
          paymentDueDate: "",
          poNumber: "", // Add ponumber?
          promoCode: "",
          quantity: selectedInventory,
          recurring: form.recurringJob,
          reservationId: newRentalId,
          sevenDayReservation: false,
          siteAddress: site.siteAddress,
          spokenWithTeamMember: false,
          startDate: form.invoiceDate,
          status: "Unapproved",
          territory: "N/A",
          territoryColor: "",
        },
      };
      const timestampDate = convertDateToTimestamp(form.invoiceDate);
      const dateParts = form.invoiceDate.split("/");
      const year = parseInt(dateParts[2], 10) + 2000;
      const dateObj = new Date(form.invoiceDate);
      const month = dateObj.toLocaleString("default", { month: "short" });

      db.collection("reservations")
        .doc(newRentalId)
        .set(rentalData)
        .then(() => {
          db.collection("drivers")
            .doc(driver.driverId)
            .update({
              [`${year}.${month}.${timestampDate}`]: firebase.firestore.FieldValue.arrayUnion(
                rentalData
              ),
            });
        })
        .then(() => {
          inventoryIds.combinedInventory.forEach((item) => {
            const increment = 0 - item.quantity;
            db.collection("inventory")
              .doc(item.itemId)
              .update({
                [`${year}.${month}.${timestampDate}`]: firebase.firestore.FieldValue.increment(
                  increment
                ),
              });
          });
        })
        .then(() => {
          db.collection("customers")
            .doc(customerInfo.customerId)
            .update({
              reservations: firebase.firestore.FieldValue.arrayUnion(
                newRentalId
              ),
            });
        })
        .then(() => {
          history.push("/admin/rentals");
        })
    }
  };

  return (
    <GridContainer>
      <GridItem
        className={classes.alignItems + " " + classes.marginBottom}
        xs={12}
      >
        <h1 style={{ marginBottom: "0px" }} className={classes.title}>
          Create New Rental
        </h1>
      </GridItem>
      <GridItem
        className={classes.columnGridItem}
        style={{ marginBottom: "-1.5rem" }}
        xs={12}
      >
        <PONumberCard setPONumber={handlePONumber} />
      </GridItem>
      <GridItem
        className={classes.columnGridItem}
        style={{ marginBottom: "-1.5rem" }}
        xs={12}
      >
        <CustomerCard
          history={history}
          setCustomer={handleCustomerChange}
          errors={errors}
          setSite={handleSiteChange}
        />
      </GridItem>
      <GridItem
        className={classes.columnGridItem}
        style={{ marginBottom: "-1.5rem" }}
        xs={12}
      >
        <DeliveryJobCard
          newRentalId={newRentalId}
          drivers={drivers}
          setDriver={setDriver}
          inventory={inventory}
          selectedInventory={selectedInventory}
          setSelectedInventory={setSelectedInventory}
          setAttachments={setAttachments}
          attachments={attachments}
          setSavingImage={setSavingImage}
          savingImage={savingImage}
          setForm={setForm}
          errors={errors}
          form={form}
        />
      </GridItem>
      <GridItem
        className={classes.columnGridItem}
        style={{ marginBottom: "-1.5rem" }}
        xs={12}
      >
        <PickUpCard history={history} />
      </GridItem>
      <GridItem
        className={classes.columnGridItem}
        style={{ marginBottom: "-1.5rem" }}
        xs={12}
      >
        <ServiceCard history={history} />
      </GridItem>

      <GridItem
        className={classes.spaceBetween + " " + classes.marginTop}
        xs={12}
      >
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          style={{
            background: "#FFC821",
            color: "black",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent.includes("Successfully") ? "success" : "error"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationData: state.reservations.reservations,
    userInfo: state.auth.user,
    drivers: state.drivers.drivers,
    inventoryProp: state.inventory.inventory,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(AddRental));
