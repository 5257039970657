import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase } from "firebase/client";
import JobNameCard from "./components/JobNameCard";
import ScheduleJobFor from "./components/ScheduleJobFor";
import DriverNotes from "./components/DriverNotes";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditJob(props) {
  const { classes, history, userInfo } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState("");

  const uid = firebase.auth().currentUser.uid;
  const user = firebase.auth().currentUser;

  const jobId = window.location.pathname.split("/")[3];

  const [jobName, setJobName] = useState("");
  const [customer, setCustomer] = useState("");
  const [jobType, setJobType] = useState("");
  const [productType, setProductType] = useState("");
  const [quantity, setQuantity] = useState('')
  const [selectedDriver, setSelectedDriver] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [recurringJob, setRecurringJob] = useState('')
  const [emergencyJob, setEmergencyJob] = useState(false)
  const [driverNotes, setDriverNotes] = useState('')


  const saveCompanyChanges = () => {
    // if (userInfo.companyId) {
    //   return db
    //     .collection("companies")
    //     .doc(userInfo.companyId)
    //     .update({
    //       companyName,
    //       primaryPhone: primaryPhoneNumber,
    //       secondaryPhone: secondaryPhoneNumber,
    //       mailingAddress,
    //       physicalAddress,
    //       primaryEmailAddress: primaryEmailAddress.address,
    //       secondaryEmailAddress: secondaryEmailAddress.address,
    //       sendInvoicesPrimaryEmailAddress: primaryEmailAddress.sendInvoices,
    //       sendRemindersPrimaryEmailAddress: primaryEmailAddress.sendReminders,
    //       sendInvoicesSecondaryEmailAddress: secondaryEmailAddress.sendInvoices,
    //       sendRemindersSecondaryEmailAddress:
    //         secondaryEmailAddress.sendReminders,
    //       tags: tags.tagsArray,
    //       tagsVisibleToDrivers: tags.visibleToDrivers,
    //       preferredCommunication,
    //       billingAddress,
    //       industryType,
    //       website,
    //       notes,
    //       slackAddress,
    //     })
    //     .then(() => {
    //       setSnackBarContent("Successfully Updated Company");
    //       history.push("/admin/settings");
    //     });
    // } else {
    //   const newCompanyId = db.collection("companies").doc().id;
    //   return db
    //     .collection("companies")
    //     .doc(newCompanyId)
    //     .set({
    //       companyId: newCompanyId,
    //       companyName,
    //       primaryPhone: primaryPhoneNumber,
    //       secondaryPhone: secondaryPhoneNumber,
    //       mailingAddress,
    //       physicalAddress,
    //       sendInvoicesPrimaryEmailAddress: primaryEmailAddress.sendInvoices,
    //       sendRemindersPrimaryEmailAddress: primaryEmailAddress.sendReminders,
    //       sendInvoicesSecondaryEmailAddress: secondaryEmailAddress.sendInvoices,
    //       sendRemindersSecondaryEmailAddress:
    //         secondaryEmailAddress.sendReminders,
    //       tags: tags.tagsArray,
    //       tagsVisibleToDrivers: tags.visibleToDrivers,
    //       preferredCommunication,
    //       billingAddress,
    //       industryType,
    //       website,
    //       notes,
    //       slackAddress,
    //     })
    //     .then(() => {
    //       setSnackBarContent("Successfully Created Company");
    //       history.push("/admin/settings");
    //     });
    // }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h1 style={{ marginBottom: "30px" }} className={classes.title}>
          Edit Job {jobId}
        </h1>
      </GridItem>
      <GridItem className={classes.columnGridItem} xs={12}>
        <JobNameCard
          jobName={jobName}
          setJobName={setJobName}
          classes={classes}
          history={history}
          setCustomer={setCustomer}
          customer={customer}
        />
      </GridItem>
      <GridItem className={classes.columnGridItem} xs={12}>
        <ScheduleJobFor
          jobType={jobType}
          setJobType={setJobType}
          productType={productType}
          setProductType={setProductType}
          quantity={quantity}
          setQuantity={setQuantity}
          classes={classes}
          history={history}
          selectedDriver={selectedDriver}
          setSelectedDriver={setSelectedDriver}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          emergencyJob={emergencyJob}
          setEmergencyJob={setEmergencyJob}
          recurringJob={recurringJob}
          setRecurringJob={setRecurringJob}
        />
      </GridItem>
      <GridItem className={classes.columnGridItem} xs={12}>
        <DriverNotes
          jobType={jobType}
          setJobType={setJobType}
          productType={productType}
          setProductType={setProductType}
          quantity={quantity}
          setQuantity={setQuantity}
          classes={classes}
          history={history}
          selectedDriver={selectedDriver}
          setSelectedDriver={setSelectedDriver}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          emergencyJob={emergencyJob}
          setEmergencyJob={setEmergencyJob}
          recurringJob={recurringJob}
          setRecurringJob={setRecurringJob}
          driverNotes={driverNotes}
          setDriverNotes={setDriverNotes}
        />
      </GridItem>
      <GridItem
        className={classes.spaceBetween + " " + classes.marginTop}
        xs={12}
      >
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.push("/admin/settings")}
        >
          Cancel
        </Button>
        <Button
          onClick={saveCompanyChanges}
          style={{
            background: "#FFC821",
            color: "#323232",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent.includes("Successfully") ? "success" : "error"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(EditJob));
