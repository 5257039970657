import {
  firebase,
  db,
  sendNewPendingReservationNotification,
} from "firebase/client";

export const setMessagingModal = (boolean) => {
  return {
    type: "SET_MESSAGING_MODAL",
    boolean,
  };
};
