import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import "./Navbar.css"

const searchOptions = ["Customers", "Jobs", "Rentals", "Sites"];

function GlobalCalculator(props) {
  const {
    classes,
    startAddressString,
    setStartAddressString,
    endAddressString,
    setEndAddressString,
    timeString,
    setTimeString,
    distanceString,
    setDistanceString,
  } = props;

  const handleCalculate = () => {
    var directionsService = new window.google.maps.DirectionsService();
    var directionsRenderer = new window.google.maps.DirectionsRenderer();

    function calcRoute() {
      var request = {
        origin: startAddressString,
        destination: endAddressString,
        travelMode: "DRIVING",
      };
      directionsService.route(request, function (result, status) {
        console.log("result", result);
        setTimeString(result.routes[0].legs[0].duration.text);
        setDistanceString(result.routes[0].legs[0].distance.text);
        // if (status == "OK") {
        //   directionsRenderer.setDirections(result);
        //   setTimeString(result.routes[0].duration.text);
        //   setDistanceString(result.routes[0].distance.text);
        // }
      });
    }
    calcRoute();
  };

  const handleClear = () => {
    setTimeString("");
    setDistanceString("");
    setStartAddressString("");
    setEndAddressString("");
  };

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      const addressComponents = results[0].address_components;
      // for (var x = 0; x < addressComponents.length; x++) {
      //   var current = addressComponents[x];
      //   if (current && current.types.includes("street_number")) {
      //     setAddressLine1(
      //       current.long_name + " " + addressComponents[x + 1].long_name
      //     );
      //   }
      //   if (current && current.types.includes("locality")) {
      //     setCity(current.long_name);
      //   }
      //   if (current && current.types.includes("administrative_area_level_1")) {
      //     setState(current.long_name);
      //   }
      //   if (current && current.types.includes("country")) {
      //     setCountry(current.long_name);
      //   }
      //   if (current && current.types.includes("postal_code")) {
      //     setZipCode(current.long_name);
      //   }
      // }
      if (placeholder === "Search start address...") {
        setStartAddressString(results[0].formatted_address);
      }
      if (placeholder === "Search end address...") {
        setEndAddressString(results[0].formatted_address);
      }
    });
  };

  return (
    <div
      style={{
        height: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "75%",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <GridContainer>
          <GridItem className={classes.flexGridItem} xs={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <span className={classes.searchCategoryTitle} style={{marginBottom:"6px"}}>Start Address</span>
              <GooglePlacesAutoComplete
                classes={classes}
                value={startAddressString}
                placeholder="Search start address..."
                onChange={(e) => setStartAddressString(e)}
                onSelect={handleSelect}
                googleCallbackName="initOne"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <span className={classes.searchCategoryTitle}>End Address</span>
              <GooglePlacesAutoComplete
                classes={classes}
                value={endAddressString}
                placeholder="Search end address..."
                onChange={(e) => setEndAddressString(e)}
                onSelect={handleSelect}
                googleCallbackName="initTwo"
              />
            </div>
          </GridItem>
          <GridItem className={classes.flexGridItem} xs={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                justifyContent: "space-evenly",
              }}
            >
              <span className={classes.searchCategoryTitle}>Time</span>
              <span className={classes.calculatorOutput}>{timeString ? timeString : "-- h --m"}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                justifyContent: "space-evenly",
              }}
            >
              <span className={classes.searchCategoryTitle}>Distance</span>
              <span className={classes.calculatorOutput}>{distanceString ? distanceString : "0 Miles"}</span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "20%",
                justifyContent: "center",
              }}
            >
              {timeString && distanceString ? (
                <Button
                  onClick={handleClear}
                  style={{
                    width: "100%",
                    height: "50px",
                    color: "#323232",
                    background: "#F1C84E",
                  }}
                >
                  Clear
                </Button>
              ) : (
                <Button
                  onClick={handleCalculate}
                  disabled={!startAddressString || !endAddressString}
                  style={{
                    width: "100%",
                    height: "50px",
                    color: "white",
                    background: "#3F9C42",
                  }}
                >
                  Calculate
                </Button>
              )}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(React.memo(GlobalCalculator));
