/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

function BillingCard(props) {
  const {
    classes,
    history,
    userInfo,
    billingAddress,
    setBillingAddress,
    cardHolderName,
    setCardHolderName,
    cardNumber,
    setCardNumber,
    setExpiration,
    expiration,
    cvv,
    setCVV,
    setCardType,
    cardType,
    primaryOrSecondary,
    setPrimaryOrSecondary,
    setNotes,
    notes,
  } = props;

  return (
    <Card style={{ marginTop: "0px" }} className={classes.card}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Cardholder Name</span>
          <TextField
            value={cardHolderName}
            onChange={(e) => setCardHolderName(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Card Number</span>
          <TextField
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Billing Address</span>
          <TextField
            value={billingAddress}
            onChange={(e) => setBillingAddress(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "45%" }}>
              <span className={classes.inputTypeTitle}>Expiration Date</span>
              <TextField
                value={expiration}
                placeholder="MM/YY"
                style={{ width: "100%" }}
                onChange={(event) => {
                  if (event.target.value.length > 5) {
                  } else {
                    const value = event.target.value;
                    if (value.length === 2) {
                      setExpiration(value + "/");
                    } else {
                      setExpiration(value);
                    }
                  }
                }}
                variant="outlined"
              />
            </div>

            <div style={{ width: "45%" }}>
              <span className={classes.inputTypeTitle}>CVV</span>
              <TextField
                value={cvv}
                type="number"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => {
                  setCVV(e.target.value);
                }}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Card Type</span>
          <TextField
            value={cardType}
            onChange={(e) => setCardType(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Set Card As</span>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Radio
                checked={primaryOrSecondary.primary}
                onChange={() =>
                  setPrimaryOrSecondary({ primary: true, secondary: false })
                }
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span className={classes.inputTypeTitle}>Primary</span>
            </div>
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                checked={primaryOrSecondary.secondary}
                onChange={() =>
                  setPrimaryOrSecondary({ primary: false, secondary: true })
                }
                value="a"
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{ root: classes.radio, checked: classes.checked }}
              />
              <span className={classes.inputTypeTitle}>Secondary</span>
            </div>
          </div>
        </GridItem>
        <GridItem xs={12}>
          <span className={classes.inputTypeTitle}>Notes</span>
          <TextareaAutosize
            aria-label="empty textarea"
            className={classes.emailInput}
            onChange={(e) => setNotes(e.target.value)}
            placeholder=""
            style={{ width: "100%", minHeight: "100px" }}
          />
        </GridItem>
        {/* <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Phone Number (Secondary)
          </span>
          <TextField
            value={secondaryPhoneNumber}
            onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Email</span>
          <TextField
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Company</span>
          <TextField
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            variant="outlined"
          />
        </GridItem> */}
      </GridContainer>
      {/* <Snackbar open={snackbarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackbarContent !== "Succesfully Changed Password"
              ? "error"
              : "success"
          }
        >
          {snackbarContent}
        </Alert>
      </Snackbar> */}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(BillingCard));
