import { firebase, db } from "firebase/client";
import { createCustomer } from "store/actions/customers/customerActions";

const swapsCollection = db.collection("swaps");

export const listenToSwaps = () => {
  return (dispatch) => {
    swapsCollection.orderBy("swapsTimestamp").onSnapshot((snapshot) => {
      const swaps = snapshot.docs.map((d) => d.data());
      dispatch(setSwaps(swaps));
    });
  };
};

export const setSwaps = (swaps) => {
  return {
    type: "SET_SWAPS",
    swaps,
  };
};
