import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconWhite.svg";

const headers = [
  "Customer",
  "Scheduled",
  "End Date",
  "Site Address",
  "Inventory",
  "Quantity",
  "Actions",
];

function MapViewTable(props) {
  const {
    classes,
    reservations,
    history,
    pageDropdown,
    pageNumber,
    itemsPerPage,
    user
  } = props;
  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };

  const renderQuantity = (q) => {
    if (typeof q === "object") {
      let returnStr = "";
      Object.entries(q).forEach(([key, value]) => {
        returnStr = returnStr + value + " ";
      });
      return returnStr;
    } else {
      return q;
    }
  };
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if (pageDropdown == "4") {
      return reservations
        .slice(
          pageNumber * itemsPerPage,
          pageNumber * itemsPerPage + itemsPerPage
        )
        .map((d, index) => {
          return (
            <tr className={classes.tableRow} key={d.name}>
              <td
                style={{ background: "#FFFFFF" }}
                className={classes.tableCell}
              >
                {user.role && user.role === "Super Admin" ?
                  <div style={{ display: "flex" }}>
                    <span
                      onClick={() =>
                        history.push({
                          pathname: `/admin/rentals/${d.reservation.reservationId}`,
                          state: { reservation: d },
                        })
                      }
                      className={classes.nameAndCompanyText}
                    >
                      {" "}
                      {d.reservation.reservationId}
                    </span>{" "}
                    |
                    <span
                      onClick={() =>
                        history.push({
                          pathname: `/admin/customers/${d.customerId}`,
                          state: { customer: d },
                        })
                      }
                      className={classes.nameAndCompanyText}
                    >
                      {d.name}
                    </span>
                  </div>
                  :
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/rentals/${d.reservation.reservationId}`,
                        state: { reservation: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {" "}
                    {d.reservation.reservationId}
                  </span>
                }
              </td>
              <td
                className={classes.tableCell}
                style={{ background: "#FFFFFF" }}
              >
                {d.reservation.startDate}
              </td>
              <td className={classes.tableCell}>{d.reservation.endDate}</td>
              <td className={classes.tableCell}>{d.reservation.siteAddress}</td>
              <td className={classes.tableCell}>
                {renderEquipment(d.reservation.equiptment)}
              </td>
              <td className={classes.tableCell}>
                {renderQuantity(d.reservation.quantity)}
              </td>
              <td className={classes.tableCell}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      style={{ background: "#FFC720" }}
                      className={classes.actionBtn}
                    >
                      <EyeIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#323232" }}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          );
        });
    } else {
      return reservations.map((d, index) => {
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell} style={{ padding: "7px 10px" }}>
              {user.role && user.role === "Super Admin" ?
                <div style={{ display: "flex" }}>
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/rentals/${d.reservation.reservationId}`,
                        state: { reservation: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {" "}
                    {d.reservation.reservationId}
                  </span>{" "}
                  |
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${d.customerId}`,
                        state: { customer: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {d.name}
                  </span>
                </div>
                :
                <span
                  onClick={() =>
                    history.push({
                      pathname: `/admin/rentals/${d.reservation.reservationId}`,
                      state: { reservation: d },
                    })
                  }
                  className={classes.nameAndCompanyText}
                >
                  {" "}
                  {d.reservation.reservationId}
                </span>
              }
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.reservation.startDate}
            </td>
            <td className={classes.tableCell}>{d.reservation.endDate}</td>
            <td className={classes.tableCell}>{d.reservation.siteAddress}</td>
            <td className={classes.tableCell}>
              {renderEquipment(d.reservation.equiptment)}
            </td>
            <td className={classes.tableCell}>
              {renderQuantity(d.reservation.quantity)}
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#323232" }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <GridContainer className={classes.searchGridContainer2}>
      <GridItem xs={12}>
        <div
          style={
            pageDropdown != 4 && reservations.length > 4
              ? { height: "550px", width: "100%", overflowY: "scroll" }
              : null
          }
        >
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(MapViewTable));
