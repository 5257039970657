import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import SalesByInvoiceItemTable from "./components/SalesByInvoiceItemTable";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
// import BusinessOverview from "./components/BusinessOverview";
// import WhoOwes from "./components/WhoOwes";
// import SalesAndCustomers from "./components/SalesAndCustomers";

const SalesByInvoiceItemData = [
  {
    date: "11/26/22",
    type: "Invoice",
    item: "Late Reservation Fee",
    description:
      "Failure to give 24 hour notice prior to 7 am of drop off or pick up date.",
    qty: 1,
    rate: "$100.00",
    total: "$100.00",
  },
  {
    date: "11/26/22",
    type: "Invoice",
    item: "30 Yard Dumpster",
    description: "(12/6/2022 - 12/10/2022) 30 yard dumpster - 30 Yard Dumpster",
    qty: 1,
    rate: "$100.00",
    total: "$100.00",
  },
  {
    date: "11/26/22",
    type: "Credit Memo",
    item: "Environmental Fee",
    description: "Environmental Fee at Transfer Station & Landfill",
    qty: 1,
    rate: "$12.00",
    total: "$12.00",
  },
  {
    date: "11/26/22",
    type: "Invoice",
    item: "15 Yard Dumpster",
    description: "(12/7/2022 - 12/14/2022) 15 yard dumpster - 15 Yard Dumpster",
    qty: 1,
    rate: "$325.00",
    total: "$325.00",
  },
  {
    date: "11/26/22",
    type: "Refund",
    item: "Environmental Fee",
    description: "Environmental Fee at Transfer Station & Landfill",
    qty: 1,
    rate: "$12.00",
    total: "$12.00",
  },
];

function SalesByInvoiceItemReport(props) {
  const { classes, reservationsData, history } = props;
  return (
    <GridContainer>
      <GridItem className={classes.jobsHeaderGridItem} xs={12}>
        <h1 className={classes.title}>Sales By Invoice Line Item</h1>
      </GridItem>
      <GridItem xs={12}>
        <SalesByInvoiceItemTable
          history={history}
          SalesByInvoiceItemTable={SalesByInvoiceItemData}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(SalesByInvoiceItemReport));
