import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
const regularsStyles = (theme) => ({
  tooltip,
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    marginTop: "0px",
    textAlign: "left",
  },
  tableRow: {
    height: "95px",
  },
  tableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "19px",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  frequentButtonDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "20%",
    padding: "10px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchGridContainer: {
    display: "flex",
    flexDirection: "column",
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    // margin: "35px 0px",
  },
  inventoryHeaderGridItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  inventoryTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    // [theme.breakpoints.down("md")]: {
    //   tableLayout: "fixed",
    //   width: "100%",
    // },
  },
  filterGridContainer: {
    marginBottom: "30px"
  },
  searchBar: {
    width: "100%",
    background: "white",
    height:"40px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
  },
  view: {
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },
  indexes: {
    display: "flex", 
    justifyContent: "flex-end",
    fontWeight:"500",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height:"40px"
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding:"8px 14px !important",
    height: "40px",
    width:"120px",
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    "&:hover": {
      background: "#FFC720",
    },
  },
  filtersGridItem: {
    display: "flex",
    alignItems: "center",
  },
  sortBy: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "19px",
  },
  selectMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "50%",
    height: "50px",
    marginRight: "15px",
  },
  selectText: {
    fontSize: "19px",
    display: "flex",
    justifyContent: "center",
  },
  ascendingMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "10%",
    height: "50px",
    marginRight: "15px",
  },
  filterMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    marginRight: "15px",
  },
  nameAndCompanyText: {
    padding: "0px 5px",
    color: "#0071C5",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  select: {
    // width: "20%",
    // marginLeft: "10px",
    // marginTop: "5px",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    background: "#323232",
    '&.MuiSelect-select:focus ': {
      background: "#323232",
      color: "white",
      fontWeight: "bold",
      borderRadius: "5px",
    },
    '& .MuiInput-underline:after' :{
      borderBottom:"2px solid red"
    }
  },
  whiteColor: {
    color: "white",
  },
  selectMenuSort: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    textAlign:"center",
    fontWeight: "500",
    margin:"0px 15px 0px 10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
  ascMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "20%",
    height: "50px",
    textAlign:"center",
    fontWeight: "500",
    margin:"0px 15px 0px 10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
});

export default regularsStyles;
