const rentalFormStyle = {
  invoiceCard: {
    background: "#FFFFFF",
    border: "0.5px solid #8E8E8E",
    padding: "25px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "0px",
    marginBottom: "30px",
  },
  columnGridItem: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  columnDiv: {
    height: "2rem",
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem 0",
  },
  columnSelectDiv: {
    height: "2rem",
    display: "flex",
    flexDirection: "row",
    margin: "0.5rem 0",
    alignItems: "center",
    fontSize: "16px",
  },
  columnHeader: {
    height: "1.5rem",
    fontWeight: 600,
    margin: "0.5rem 0 0",
    fontSize: "0.9rem",
  },
  adournedEnd: {
    paddingRight: "10px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  addCustomer: {
    fontFamily: "Archivo",
    textTransform: "capitalize",
    fontWeight: 600,
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#323232",
    padding: "12px",
    display: "flex",
    justifyContent: "space-evenly",
    "&:hover": {
      background: "#FFC720",
    },
  },
};

export default rentalFormStyle;