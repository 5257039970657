import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase } from "firebase/client";
import ProfileCard from "./components/ProfileCard";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditProfile(props) {
  const { classes, history, userInfo } = props;

  const [firstName, setFirstName] = useState(userInfo.firstName);
  const [lastName, setLastName] = useState(userInfo.lastName);
  const [website, setWebsite] = useState(
    userInfo.website ? userInfo.website : ""
  );

  const [phoneNumber, setPhoneNumber] = useState(
    userInfo.phoneNumber ? userInfo.phoneNumber : ""
  );
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState(
    userInfo.secondaryPhoneNumber ? userInfo.secondaryPhoneNumber : ""
  );
  const [company, setCompany] = useState(
    userInfo.company ? userInfo.company : ""
  )
  const [email, setEmail] = useState(userInfo.email ? userInfo.email : "");
  const [changedEmail, setChangedEmail] = useState(false);
  const [needPasswordModal, setNeedPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [snackBarContent, setSnackBarContent] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const uid = firebase.auth().currentUser.uid;
  const user = firebase.auth().currentUser;

  const saveProfileChanges = () => {
    return db
      .collection("users")
      .doc(uid)
      .update({
        firstName,
        lastName,
        name: firstName + " " + lastName,
        email,
        website,
        phoneNumber,
        secondaryPhoneNumber,
      })
      .then(() => {
        setSnackBarContent("Succesfully Updated Profile");
        history.push("/admin/profile");
      });
  };

  const submitWithPassword = () => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(userInfo.email, password)
      .then(function () {
        return user.updateEmail(email).then(() => {
          return db
            .collection("users")
            .doc(uid)
            .update({
              firstName,
              lastName,
              name: firstName + " " + lastName,
              email,
              website,
              phoneNumber,
              secondaryPhoneNumber,
            })
            .then(() => {
              setSnackBarContent("Succesfully Updated Profile");
              history.push("/admin/profile");
            });
        });
      })
      .catch(function (err) {
        //show alert with error
        setSnackBarContent(err.message);
        // console.log("err", err);
      });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h1 style={{ marginBottom: "30px" }} className={classes.title}>
          Edit Profile Information
        </h1>
      </GridItem>
      <GridItem xs={12}>
        <ProfileCard
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          website={website}
          setWebsite={setWebsite}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          secondaryPhoneNumber={secondaryPhoneNumber}
          setSecondaryPhoneNumber={setSecondaryPhoneNumber}
          email={email}
          setEmail={setEmail}
          company={company}
          setCompany={setCompany}
          history={history}
          setChangedEmail={setChangedEmail}
          userInfo={userInfo}
        />
      </GridItem>
      <GridItem className={classes.spaceBetween} xs={12}>
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.push('/admin/profile')}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (changedEmail) {
              setNeedPasswordModal(true);
            } else {
              saveProfileChanges();
            }
          }}
          style={{
            background: "#FFC821",
            color: "#323232",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={needPasswordModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNeedPasswordModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <Card className={classes.modalCard}>
          <h1 className={classes.modalTitle}>
            Please Provide Your Password To Make These Changes
          </h1>
          <CardBody className={classes.body}>
            <GridContainer>
              <GridItem xs={12}>
                <GridItem className={classes.columnGridItem} xs={12}>
                  <span className={classes.inputTypeTitle}>Password</span>
                  {/* <TextField
                    className={classes.passwordField}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    type="password"
                  /> */}
                  <TextField
                    // className={classes.searchBar}
                    placeholder="Password"
                    value={password}
                    type={passwordVisible ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        adornedEnd: classes.adournedEnd,
                      },
                      endAdornment: (
                        <InputAdornment
                          onClick={() => {
                            if (passwordVisible) {
                              setPasswordVisible(false);
                            } else {
                              setPasswordVisible(true);
                            }
                          }}
                          className={classes.adournedEnd}
                          position="end"
                        >
                          <Eyecon className={classes.eyecon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Button
                    onClick={submitWithPassword}
                    className={classes.submitButton}
                  >
                    Submit
                  </Button>
                </GridItem>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </Dialog>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent !== "Succesfully Updated Profile"
              ? "error"
              : "success"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(EditProfile));
