import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.js";
import settingsStyle from "assets/jss/material-dashboard-pro-react/views/settingsStyle.js";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { BsFillCircleFill } from "react-icons/bs";
import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { db, firebase, storage } from "firebase/client.js";
import loader from "assets/img/loader.gif";
import moment from "moment";
import CardBody from "components/Card/CardBody";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { setMessagingModal } from "store/actions/messaging/messagingActions";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  createWithContent,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { messageKey } from "./messageKey";
console.log("messageKey", messageKey);

const messageTypes = [
  "24 hr Service Reminder",
  "12 hr Service Reminder",
  "Service Started",
  "Early Pick Up Confirmed",
  "Initial Invoice",
  "Invoice Details",
  "Driver Review Text",
  "New Reservation Approved",
  "New Reservation Denied",
  "Reservation Modified",
  "Payment Reminder",
  "Unpaid Balance",
  "Credit Memo",
  "Refund",
  "Billing Information Updated",
  "Company User Invitation",
  "Contact Information Updated",
  "Payment Receipt",
  "Extend Job Timeframe",
];

const recipientsTypes = [
  "All Relevant Customers",
  "All Regulars",
  "Select Customers",
];

const modeTypes = ["Email", "Text", "Call"];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SendMessageModal(props) {
  const { classes, setMessagingModal } = props;
  const [messageType, setMessageType] = useState("");
  const [recipientsType, setRecipientsType] = useState("");
  const [modeType, setModeType] = useState("");
  const [subjectLine, setSubjectLine] = useState("");
  const [editorState1, setEditorState1] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (messageType) {
      const content = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(messageKey[messageType])
        )
      );
      setEditorState1(content);
    }
  }, [messageType]);

  return (
    <GridContainer className={classes.gridContainerPadding}>
      <GridItem className={classes.spaceBetween + ' ' + classes.gridItemPaddingBottom} xs={12}>
        <h1 className={classes.sendMessageTitle}>Send Message</h1>
        <BlackDenyIcon onClick={() => props.setMessagingModal(false)} className={classes.closeModal} />
      </GridItem>
      <GridItem xs={4}>
        <p
          className={classes.sortByHeader}
          style={{ fontWeight: "normal", fontSize: "18px" }}
        >
          Message Type
        </p>
        <Select
          className={classes.sendMessageSelects}
          value={messageType}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => setMessageType(e.target.value)}
        >
          {messageTypes.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>

      <GridItem xs={4}>
        <p
          className={classes.sortByHeader}
          style={{ fontWeight: "normal", fontSize: "18px" }}
        >
          Recipients
        </p>
        <Select
          className={classes.sendMessageSelects}
          value={recipientsType}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => setRecipientsType(e.target.value)}
        >
          {recipientsTypes.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>

      <GridItem xs={4}>
        <p
          className={classes.sortByHeader}
          style={{ fontWeight: "normal", fontSize: "18px" }}
        >
          Mode
        </p>
        <Select
          className={classes.sendMessageSelects}
          value={modeType}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => setModeType(e.target.value)}
        >
          {modeTypes.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>

      <GridItem className={classes.gridItemPaddingTop} xs={12}>
        <p
          className={classes.sortByHeader}
          style={{ fontWeight: "normal", fontSize: "18px" }}
        >
          Subject Line
        </p>
        <TextField
          className={classes.subjectLine}
          value={subjectLine}
          onChange={(e) => setSubjectLine(e.target.value)}
          variant="outlined"
        />
      </GridItem>

      <GridItem className={classes.gridItemPaddingTop} xs={12}>
        <p
          className={classes.sortByHeader}
          style={{ fontWeight: "normal", fontSize: "18px" }}
        >
          Message Text
        </p>
        <Editor
          id="description"
          // defaultValue={convertFromHTML(invoice.Item_Info.Item_Description)}
          placeholder="Description for your line item..."
          editorState={editorState1}
          onEditorStateChange={(state) => setEditorState1(state)}
          // onEditorStateChange={(e) => handleItem(e,index)}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          editorStyle={{ border: "1px solid grey" }}
          toolbarStyle={{ border: "1px solid grey" }}
        />
      </GridItem>

      <GridItem
        className={classes.gridItemPaddingTop + " " + classes.justifyEnd}
        xs={12}
      >
        <Button
          style={{
            background: "#FFC821",
            color: "#323232",
            marginRight: "20px",
          }}
          className={classes.sendMessageButton}
        >
          Send Message
        </Button>
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.sendMessageButton}
          onClick={() => props.setMessagingModal(false)}
        >
          Cancel
        </Button>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    inventoryData: state.inventory.inventory,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMessagingModal: (boolean) => dispatch(setMessagingModal(boolean)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(settingsStyle)
)(SendMessageModal);
