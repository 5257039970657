import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import scheduleStyle from "assets/jss/material-dashboard-pro-react/views/scheduleStyle.js";
import moment from "moment";
import { ReactComponent as ArrowDown } from "assets/img/arrowDown.svg";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ReactComponent as DeliveryIcon } from "assets/img/deliveryIcon.svg";
import { useDroppable } from "@dnd-kit/core";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  MouseSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Sortable from "./Dnd/Sortable";
import DriverDayScheduleOpen from "./DriverDayScheduleOpen.jsx";

import DriverRow from "./DriverRow.jsx";
// import loader from "assets/img/loader.gif";
// import UnassignedColumn from "./UnassignedColumn";
// import DriverColumn from "./DriverColumn";

function WeekSchedule(props) {
  const {
    classes,
    drivers,
    weeklyItems,
    setWeeklyItems,
    scheduledDate,
    scheduleYear,
    scheduleMonth,
    weeklySchedule,
    activeId,
    setActiveId,
    data,
    setData,
    history,
    setChangedWeeklyTable,
    searchString,
    handleDragStart,
    setShowSaveButton,
    setMovingItemError
  } = props;
  const widthOfColumn = drivers.length + 1;

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEndWeekly = (event) => {
    const { over, active } = event;
    const overDate = over.data.current.date;

    if (!over) {
      return;
    }
    setShowSaveButton(true);

    const weeklyItemsCopy = { ...weeklyItems };
    const activeCurrentDriver = active.data.current.driverId;
    const activeId = active.id.split(" ")[0];
    const startingOrEnding = active.id.split(" ")[2];
    const currentDate =
      startingOrEnding === "START"
        ? active.data.current.data.reservation.startDate
        : active.data.current.data.reservation.endDate;
    if (over.id.includes("/") && over.id.includes("-")) {
      //empty spot
      const overDriverId = over.id.split(" ")[0];
      const dateToMoveTo = over.id.split(" ")[2];


      if (active.id.includes("END")) {
        if (
          new Date(dateToMoveTo) <=
          new Date(active.data.current.data.reservation.startDate)
        ) {
          setMovingItemError("Pickup must be after delivery")
          return;
        }
      }
      if (active.id.includes("START")) {
        if (
          new Date(dateToMoveTo) >=
          new Date(active.data.current.data.reservation.endDate)
        ) {
          setMovingItemError("Delivery must be before pickup")
          return;
        }
      }

      if (startingOrEnding === "START") {
        active.data.current.data.reservation.jobs = {
          ...active.data.current.data.reservation.jobs,
          delivery: {
            ...active.data.current.data.reservation.jobs.delivery,
            driverId: overDriverId === "unassigned" ? "" : overDriverId,
            driverName:
              weeklyItems[overDriverId].name === "unassigned"
                ? "N/A"
                : weeklyItems[overDriverId].name,
          },
        };
      } else {
        active.data.current.data.reservation.jobs = {
          ...active.data.current.data.reservation.jobs,
          pickup: {
            ...active.data.current.data.reservation.jobs.pickup,
            driverId: overDriverId === "unassigned" ? "" : overDriverId,
            driverName:
              weeklyItems[overDriverId].name === "unassigned"
                ? "N/A"
                : weeklyItems[overDriverId].name,
          },
        };
      }

      const currentIndex = weeklyItems[
        active.data.current.driverId
      ].weeklySchedule[currentDate].indexOf(active.data.current.data);

      weeklyItems[activeCurrentDriver].weeklySchedule[currentDate].splice(
        currentIndex,
        1
      );

      if (startingOrEnding === "START") {
        active.data.current.data.reservation.startDate = dateToMoveTo;
      } else {
        active.data.current.data.reservation.endDate = dateToMoveTo;
      }

      weeklyItemsCopy[overDriverId].weeklySchedule[dateToMoveTo] = [
        active.data.current.data,
      ];
      setWeeklyItems({ ...weeklyItemsCopy });
      setChangedWeeklyTable(true);
    } else {
      const overDriverId = over.data.current.driverId;
      const dateToMoveTo = over.data.current.date;
      if (!overDriverId) {
        return;
      }


      if (active.id.includes("END")) {
        if (
          new Date(dateToMoveTo) <=
          new Date(active.data.current.data.reservation.startDate)
        ) {
          setMovingItemError("Pickup must be after delivery")
          return;
        }
      }
      if (active.id.includes("START")) {
        if (
          new Date(dateToMoveTo) >=
          new Date(active.data.current.data.reservation.endDate)
        ) {
          setMovingItemError("Delivery must be before pickup")
          return;
        }
      }

      if (startingOrEnding === "START") {
        active.data.current.data.reservation.jobs = {
          ...active.data.current.data.reservation.jobs,
          delivery: {
            ...active.data.current.data.reservation.jobs.delivery,
            driverId: overDriverId === "unassigned" ? "" : overDriverId,
            driverName:
              weeklyItems[overDriverId].name === "unassigned"
                ? "N/A"
                : weeklyItems[overDriverId].name,
          },
        };
      } else {
        active.data.current.data.reservation.jobs = {
          ...active.data.current.data.reservation.jobs,
          pickup: {
            ...active.data.current.data.reservation.jobs.pickup,
            driverId: overDriverId === "unassigned" ? "" : overDriverId,
            driverName:
              weeklyItems[overDriverId].name === "unassigned"
                ? "N/A"
                : weeklyItems[overDriverId].name,
          },
        };
      }

      if (activeCurrentDriver === overDriverId) {
        // moving same driver but different date
        console.log(
          "adding moving same driver different date",
          active,
          over,
          startingOrEnding
        );
        if (
          startingOrEnding === "END" &&
          active.data.current.data.reservation.endDate !==
            over.data.current.data.reservation.endDate
        ) {
          const previousEndDate = active.data.current.data.reservation.endDate;
          active.data.current.data.reservation.endDate =
            over.data.current.data.reservation.endDate;

          const currentIndex = weeklyItems[
            active.data.current.driverId
          ].weeklySchedule[previousEndDate].indexOf(active.data.current.data);

          // const indexToMoveTo = over.data.current.data.currentIndex;
          const indexToMoveTo = weeklyItems[
            over.data.current.driverId
          ].weeklySchedule[previousEndDate].indexOf(over.data.current.data);

          let itemsToAddTo =
            weeklyItemsCopy[over.data.current.driverId].weeklySchedule[
              over.data.current.data.reservation.endDate
            ];
          let itemsToDeleteFrom =
            weeklyItemsCopy[active.data.current.driverId].weeklySchedule[
              previousEndDate
            ];
          itemsToDeleteFrom.splice(currentIndex, 1);
          itemsToAddTo.splice(indexToMoveTo, 0, active.data.current.data);

          weeklyItemsCopy[over.data.current.driverId].weeklySchedule[
            dateToMoveTo
          ] = itemsToAddTo;
          weeklyItemsCopy[active.data.current.driverId].weeklySchedule[
            previousEndDate
          ] = itemsToDeleteFrom;
          setWeeklyItems({ ...weeklyItemsCopy });
          setChangedWeeklyTable(true);
        } else if (
          startingOrEnding === "START" &&
          active.data.current.data.reservation.startDate !==
            over.data.current.data.reservation.startDate
        ) {
          const previousStartDate =
            active.data.current.data.reservation.startDate;
          active.data.current.data.reservation.startDate =
            over.data.current.data.reservation.startDate;

          const currentIndex = weeklyItems[
            active.data.current.driverId
          ].weeklySchedule[previousStartDate].indexOf(active.data.current.data);

          const indexToMoveTo = weeklyItems[
            over.data.current.driverId
          ].weeklySchedule[previousStartDate].indexOf(over.data.current.data);

          let itemsToAddTo =
            weeklyItemsCopy[over.data.current.driverId].weeklySchedule[
              over.data.current.data.reservation.startDate
            ];
          let itemsToDeleteFrom =
            weeklyItemsCopy[active.data.current.driverId].weeklySchedule[
              previousStartDate
            ];
          itemsToDeleteFrom.splice(currentIndex, 1);
          itemsToAddTo.splice(indexToMoveTo, 0, active.data.current.data);
          weeklyItemsCopy[over.data.current.driverId].weeklySchedule[
            dateToMoveTo
          ] = itemsToAddTo;
          weeklyItemsCopy[active.data.current.driverId].weeklySchedule[
            previousStartDate
          ] = itemsToDeleteFrom;
          setWeeklyItems({ ...weeklyItemsCopy });
          setChangedWeeklyTable(true);
        } else {
          console.log("adding sorting", active, over);
          //sorting in list
          let itemsToUpdate =
            weeklyItemsCopy[over.data.current.driverId].weeklySchedule[
              dateToMoveTo
            ];

          const currentIndex = itemsToUpdate.indexOf(active.data.current.data);
          const indexToMoveTo = itemsToUpdate.indexOf(over.data.current.data);
          if (startingOrEnding === "START") {
            active.data.current.data.reservation.startDate = dateToMoveTo;
          } else {
            active.data.current.data.reservation.endDate = dateToMoveTo;
          }

          itemsToUpdate.splice(currentIndex, 1);
          itemsToUpdate.splice(indexToMoveTo, 0, active.data.current.data);
          weeklyItemsCopy[over.data.current.driverId].weeklySchedule[
            dateToMoveTo
          ] = itemsToUpdate;
          setWeeklyItems({ ...weeklyItemsCopy });
          setChangedWeeklyTable(true);
        }
      } else {
        console.log("adding with other items", active, over);
        //adding to spot with other items
        weeklyItemsCopy[activeCurrentDriver].weeklySchedule[
          currentDate
        ].forEach((d, index) => {
          if (d.reservation.reservationId === activeId) {
            weeklyItems[activeCurrentDriver].weeklySchedule[currentDate].splice(
              index,
              1
            );
          }
        });

        if (startingOrEnding === "START") {
          active.data.current.data.reservation.startDate = dateToMoveTo;
        } else {
          active.data.current.data.reservation.endDate = dateToMoveTo;
        }

        weeklyItemsCopy[overDriverId].weeklySchedule[dateToMoveTo] = [
          ...weeklyItemsCopy[overDriverId].weeklySchedule[dateToMoveTo],
          active.data.current.data,
        ];

        setWeeklyItems({ ...weeklyItemsCopy });
        setChangedWeeklyTable(true);
      }
    }
    setActiveId(null);
    setData(null);
    // setChangedWeeklyTable(true);
  };

  const columnWidth = 100 / 8;
  return (
    <GridContainer className={classes.scheduleWeeklyGridContainer}>
      <GridItem className={classes.dndContext} xs={12}>
        <DndContext
          // sensors={sensors}
          // collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          // onDragOver={handleDragOver}
          onDragEnd={handleDragEndWeekly}
          // autoScroll={false}
        >
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                className={classes.scheduleColumn}
                style={{ width: `${columnWidth}%` }}
              >
                <div
                  style={{ background: "#D9D9D9" }}
                  className={classes.scheduleColumnHeader}
                >
                  <span className={classes.scheduleHeaderText}>Driver</span>
                </div>
              </div>
              {weeklySchedule.map((date, index) => {
                return (
                  <div
                    key={date}
                    style={{ width: `${columnWidth}%` }}
                    className={classes.scheduleColumn}
                  >
                    <div className={classes.scheduleColumnHeader}>
                      <span className={classes.scheduleHeaderText}>{date}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            {Object.values(weeklyItems)
              // .filter((i) => i.name !== "unassigned")
              .map((i, index) => {
                const driverScheduleCount = Object.values(
                  i.weeklySchedule
                ).flat().length;
                return (
                  <DriverRow
                    classes={classes}
                    key={i.driverId}
                    columnWidth={columnWidth}
                    driver={i}
                    items={Object.values(weeklyItems)}
                    weeklyItems={weeklyItems}
                    setWeeklyItems={setWeeklyItems}
                    id={i.driverId}
                    weeklySchedule={weeklySchedule}
                    index={index}
                    driverScheduleCount={driverScheduleCount}
                    searchString={searchString}
                  />
                );
              })}
          </div>

          {activeId && data ? (
            <DragOverlay>
              <Sortable
                history={history}
                data={data}
                classes={classes}
                id={activeId}
                reservationId={data.reservation.reservationId}
                territoryColor={data.reservation.territoryColor}
                dontShowTooltip
              />
            </DragOverlay>
          ) : null}
        </DndContext>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(scheduleStyle)
)(WeekSchedule);
