import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

import AccountingCustomTabs from "./AccountingCustomTabs";
import AccountingInvoicesTab from "./AccountingInvoicesTab";
import AccountingPaymentsTab from "./AccountingPaymentsTab";
import AccountingRefunds from "./AccountingRefunds";
import CreditMemos from "./CreditMemos";

function AccountingTab(props) {
  const { classes, customerInfo, pastAndCurrentReservations, invoices, history, payments, customerId } = props;

  console.log("customerInfo", customerInfo);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card className={classes.tabCard} style={{ padding: "0px 30px" }}>
          <GridContainer>
            <GridItem xs={12}>
              <AccountingCustomTabs
                classes={classes}
                tabs={[
                  {
                    tabName: "Invoices",
                    tabContent: (
                      <AccountingInvoicesTab
                        pastAndCurrentReservations={pastAndCurrentReservations}
                        customerInfo={customerInfo}
                        invoices={invoices}
                        history={history}
                        customerId={customerId}
                      />
                    ),
                  },
                  {
                    tabName: "Payments",
                    tabContent: (
                      <AccountingPaymentsTab
                        // pastAndCurrentReservations={pastAndCurrentReservations}
                        customerInfo={customerInfo}
                        payments={payments}
                        history={history}
                        payments={payments}
                      />
                    ),
                  },
                  {
                    tabName: "Credit Memos",
                    tabContent: (
                      <CreditMemos
                        invoices={invoices}
                        history={history}
                      />
                    ),
                  },
                  {
                    tabName: "Refunds",
                    tabContent: (
                      <AccountingRefunds
                        pastAndCurrentReservations={pastAndCurrentReservations}
                        customerInfo={customerInfo}
                      />
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(AccountingTab));
