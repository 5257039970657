/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Datetime from "react-datetime";
import loader from "assets/img/loader.gif";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";

function PoCard(props) {
  const { classes, poNumber, setPoNumber, jobName, setJobName } = props;

  return (
    <Card className={classes.invoiceCard}>
      <GridContainer>
        {/* <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>P.O. Number (optional)</span>
          <TextField
            value={poNumber}
            onChange={(e) => setPoNumber(e.target.value)}
            variant="outlined"
          />
        </GridItem> */}
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Job Name (optional)</span>
          <TextField
            value={jobName}
            onChange={(e) => setJobName(e.target.value)}
            variant="outlined"
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log("stateee", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    sites: state.sites.sites,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(PoCard));
