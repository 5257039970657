const initState = {
  sites: [],
};
export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_SITES":
      return {
        ...state,
        sites: [...action.sites],
      };
    default:
      return state;
  }
}
