import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import upcomingPaymentsStyle from "assets/jss/material-dashboard-pro-react/views/upcomingPaymentsStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";

const headers = [
  "Customer",
  "Rental #",
  "Due Date",
  "Amount",
  "Remaining",
  "Emailed",
  "Actions",
];

function PaymentsTable(props) {
  const { classes, reservationsData, history } = props;

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "0.5px solid #323232",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    return reservationsData.map((d, index) => {
      return (
        <tr className={classes.tableRow} key={index} >
          <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: 700 }}>{d.name}</span>
              <span>{d.billingAddress}</span>
            </div>
          </td>
          <td
            className={classes.tableCell}
            onClick={() =>
              history.push({
                pathname: `/admin/rentals/${d.reservation.reservationId}`,
                state: { reservation: d },
              })
            }
            style={{
              background: "#FFFFFF",
              color: "blue",
              textDecoration: "underline",
            }}
          >
            {d.reservation.reservationId}
          </td>
          <td style={{ fontWeight: 700 }} className={classes.tableCell}>
            {d.reservation.paymentDueDate}
          </td>
          <td className={classes.tableCell}>${d.reservation.amount}</td>
          <td className={classes.tableCell}>
            $
            {parseFloat(d.reservation.amount) -
              parseFloat(d.reservation.amountPaid)}
          </td>
          <td className={classes.tableCell}>{d.reservation.lastEmailedDate}</td>
          <td className={classes.tableCell}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <Tooltip
                id="tooltip-top"
                title="View"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{ background: "#FFC720" }}
                  className={classes.actionBtn}
                  onClick={() =>
                    history.push(
                      `/admin/rentals/${d.reservation.reservationId}`
                    )
                  }
                >
                  <EyeIcon />
                </Button>
              </Tooltip>
              <Tooltip
                id="tooltip-top"
                title="Edit"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#ECB22E" }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Contact"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#D9D9D9" }}
                >
                  <PhoneIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Email"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#323232" }}
                >
                  <MailIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Record Payment"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#FFFFFF", border: "1px solid #323232" }}
                >
                  <VerifiedIcon />
                </Button>
              </Tooltip>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem xs={12}>
        <table className={classes.inventoryTable}>
          <tbody>
            {renderTableHeader()}
            {renderTableData()}
          </tbody>
        </table>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(upcomingPaymentsStyle)
)(React.memo(PaymentsTable));
