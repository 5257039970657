import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import addProductStyle from "assets/jss/material-dashboard-pro-react/views/addProductStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase } from "firebase/client";
import ItemCard from "./components/ItemCard";
import TypeCard from "./components/TypeCard";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import InputAdornment from "@material-ui/core/InputAdornment";

import ScheduleCard from "./components/ScheduleCard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddProduct(props) {
  const { classes, history, userInfo } = props;

  const [product, setProduct] = useState({ type: false, item: false });
  const [type, setType] = useState({ dumpster: false, truck: false });

  const [serialNumber, setSerialNumber] = useState("");
  const [barcode, setBarcode] = useState("");
  const [make, setMake] = useState("");

  const [itemName, setItemName] = useState("");
  const [status, setStatus] = useState("");
  const [lastServiced, setLastServiced] = useState("");

  const [totalQty, setTotalQty] = useState("");

  const [available, setAvailable] = useState("");

  const [condition, setCondition] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [lastRental, setLastRental] = useState("");
  const [outOfService, setOutOfService] = useState("")
  const [rate, setRate] = useState("")
  const [itemizedProduct, setItemizedProduct] = useState(false)

  const [snackBarContent, setSnackBarContent] = useState("");

  const [maintenanceSchedule, setMaintenanceSchedule] = useState([
    {
      timeFrame: "",
      description: "",
    },
  ]);

  const uid = firebase.auth().currentUser.uid;
  const user = firebase.auth().currentUser;

  const saveBillingChanges = () => {
    // return db
    //   .collection("users")
    //   .doc(uid)
    //   .update({
    //     billing: {
    //       billingAddress,
    //       cardHolderName,
    //       cardNumber,
    //       cardType,
    //       expiration,
    //       notes,
    //       primaryOrSecondary: primaryOrSecondary.primary
    //         ? "Primary"
    //         : "Secondary",
    //     },
    //   })
    //   .then(() => {
    //     setSnackBarContent("Succesfully Updated Billing");
    //     history.push("/admin/profile");
    //   });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h1 style={{ marginBottom: "30px" }} className={classes.title}>
          Add Product
        </h1>
      </GridItem>

      {!product.type && !product.item && (
        <Card style={{ marginTop: "0px" }} className={classes.card}>
          <GridContainer>
            <GridItem className={classes.columnGridItem} xs={4}>
              <span className={classes.inputTypeTitle}>Add New</span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "45%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Radio
                    checked={product.type}
                    onChange={() => setProduct({ type: true, item: false })}
                    sx={{
                      "&, &.Mui-checked": {
                        color: "blue",
                      },
                    }}
                    aria-label="A"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                  <span className={classes.values}>Product Type</span>
                </div>
                <div
                  style={{
                    width: "45%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Radio
                    sx={{
                      "&, &.Mui-checked": {
                        color: "blue",
                      },
                    }}
                    checked={product.item}
                    onChange={() => setProduct({ type: false, item: true })}
                    value="a"
                    aria-label="A"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                  <span className={classes.values}>Product Item</span>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </Card>
      )}

      {product.item && (
        <>
          <GridItem xs={12}>
            <ItemCard
              history={history}
              product={product}
              setProduct={setProduct}
              type={type}
              setType={setType}
              serialNumber={serialNumber}
              setSerialNumber={setSerialNumber}
              barcode={barcode}
              setBarcode={setBarcode}
              make={make}
              setMake={setMake}
              itemName={itemName}
              setItemName={setItemName}
              status={status}
              setStatus={setStatus}
              lastServiced={lastServiced}
              setLastServiced={setLastServiced}
              condition={condition}
              setCondition={setCondition}
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation}
              lastRental={lastRental}
              setLastRental={setLastRental}
            />
          </GridItem>
          <GridItem xs={12}>
            <ScheduleCard
              maintenanceSchedule={maintenanceSchedule}
              setMaintenanceSchedule={setMaintenanceSchedule}
            />
          </GridItem>
        </>
      )}

      {product.type && (
        <>
          <GridItem xs={12}>
            <TypeCard
              history={history}
              product={product}
              setProduct={setProduct}
              itemName={itemName}
              setItemName={setItemName}
              totalQty={totalQty}
              setTotalQty={setTotalQty}
              available={available}
              setAvailable={setAvailable}
              rate={rate}
              setRate={setRate}
              outOfService={outOfService}
              setOutOfService={setOutOfService}
              itemizedProduct={itemizedProduct}
              setItemizedProduct={setItemizedProduct}
            />
          </GridItem>
        </>
      )}
      <GridItem className={classes.spaceBetween} xs={12}>
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.push("/admin/service-reminders")}
        >
          Cancel
        </Button>
        <Button
          onClick={saveBillingChanges}
          style={{
            background: "#FFC821",
            color: "#323232",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent !== "Succesfully Updated Billing"
              ? "error"
              : "success"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(addProductStyle)
)(React.memo(AddProduct));
