import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "views/Dashboard/components/pagination.css";
// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { BsFillCircleFill } from "react-icons/bs";
import { MenuItem, Select } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";


const pageArr = ["9", "All"];

const headers = [
    "Name",
    "Actions",
];

const mockData = [
    {
        name: "aerobic",
    },
    {
        name: "cesspool",
    },
    {
        name: "exterior grease",
    },
    {
        name: "grease",
    },
    {
        name: "grit chamber",
    }
];


function LoadTable(props) {
    const {
        classes,
        reservations,
        history,
        user
    } = props;
    const itemsPerPage = 9;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0)
    const [endIndex, setEndIndex] = useState(itemsPerPage)
    const [pageDropdown, setPageDrowdown] = useState("9")


    const handlePageClick = ({ selected }) => {
        setPageNumber(selected);
        setIndex(selected * itemsPerPage)
        setEndIndex((selected * itemsPerPage) + itemsPerPage)
    };

    useEffect(() => {
        mockData.slice(index, endIndex)
    }, [index])

    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th className={classes.tableHeaders} key={key}>
                            {key}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        let tableData;
        if (pageDropdown === "9") {
            tableData = mockData.slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            );
        }
        else {
            tableData = mockData
        }
        return tableData
            .map((d, index) => {
                return (
                    <tr className={classes.tableRow} key={d.name}>
                        <td className={classes.tableCell} >{d.name}</td>
                        <td className={classes.tableCell}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Tooltip
                                    id="tooltip-top"
                                    title="Edit"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionBtn}
                                        style={{ background: "#FFC821" }}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Deny"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        style={{ background: "#323232" }}
                                        className={classes.actionBtn}
                                    >
                                        <DenyIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                );
            });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <div style={pageDropdown != 9 && mockData.length > 9 ? { height: "550px", width: "100%", overflowY: "scroll" } : null}>
                    <table className={classes.inventoryTable}>
                        <tbody>
                            {renderTableHeader()}
                            {renderTableData()}
                        </tbody>
                    </table>
                </div>
                {pageDropdown == "9" ? (
                    <GridContainer style={{ paddingTop: "10px" }}>
                        <GridItem xs={6}>
                            <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                                View
                                <span>
                                    <Select
                                        style={{
                                            width: "18%",
                                            marginLeft: "10px",
                                            marginTop: "5px",
                                            textAlign: "center",
                                        }}
                                        disableUnderline
                                        MenuProps={
                                            {
                                                // className: classes.selectMenu,
                                            }
                                        }
                                        classes={{
                                            select: classes.select,
                                            icon: classes.whiteColor,
                                        }}
                                        onChange={(e) => {
                                            setPageDrowdown(e.target.value);
                                        }}
                                        value={pageDropdown}
                                    >
                                        {pageArr.map((d, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelectedMultiple,
                                                    }}
                                                    value={d}
                                                >
                                                    {d}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </span>
                            </p>
                        </GridItem>
                        <GridItem xs={3}>
                            <h5
                                style={{
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                {index + 1} - {endIndex} of{" "}
                                {Math.ceil(mockData.length / itemsPerPage)}
                            </h5>
                        </GridItem>
                        <GridItem xs={3}>
                            <ReactPaginate
                                paginationSize={0}
                                onPageChange={handlePageClick}
                                pageCount={Math.ceil(mockData.length / itemsPerPage)}
                                nextLabel={
                                    <ArrowRight
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "white",
                                            marginBottom: "-8px",
                                        }}
                                    />
                                }
                                previousLabel={
                                    <ArrowLeft
                                        style={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: "white",
                                            marginBottom: "-8px",
                                        }}
                                    />
                                }
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </GridItem>
                    </GridContainer>
                ) : (
                    <GridContainer>
                        <GridItem xs={6}>
                            <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                                View
                                <span>
                                    <Select
                                        style={{
                                            width: "10%",
                                            marginLeft: "10px",
                                            marginTop: "5px",
                                            textAlign: "center",
                                        }}
                                        disableUnderline
                                        MenuProps={
                                            {
                                                // className: classes.selectMenu,
                                            }
                                        }
                                        classes={{
                                            select: classes.select,
                                            icon: classes.whiteColor,
                                        }}
                                        onChange={(e) => {
                                            setPageDrowdown(e.target.value);
                                        }}
                                        value={pageDropdown}
                                    >
                                        {pageArr.map((d, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelectedMultiple,
                                                    }}
                                                    value={d}
                                                >
                                                    {d}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </span>
                            </p>
                        </GridItem>
                    </GridContainer>
                )}
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(customTableStyle)
)(React.memo(LoadTable));
