import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import SalesByCustomerTable from "./components/SalesByCustomerTable";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
// import BusinessOverview from "./components/BusinessOverview";
// import WhoOwes from "./components/WhoOwes";
// import SalesAndCustomers from "./components/SalesAndCustomers";

const salesByCustomerData = [
  {
    name: "Aaron Guardado",
    paid: "$149.345.21",
    unpaid: "$0.00",
    totalSales: "$149.345.21",
  },
  {
    name: "Steve Miller",
    paid: "$345.21",
    unpaid: "$0.00",
    totalSales: "$345.21",
  },
  {
    name: "Ron Becat",
    paid: "$45.21",
    unpaid: "$0.00",
    totalSales: "$45.21",
  },
  {
    name: "Bernard Charles",
    paid: "$14,345.21",
    unpaid: "$0.00",
    totalSales: "$14,345.21",
  },
];

function SalesByCustomerReport(props) {
  const { classes, reservationsData, history } = props;
  return (
    <GridContainer>
      <GridItem className={classes.jobsHeaderGridItem} xs={12}>
        <h1 className={classes.title}>Sales By Customer</h1>
      </GridItem>
      <GridItem xs={12}>
        <SalesByCustomerTable
          history={history}
          salesByCustomerData={salesByCustomerData}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(SalesByCustomerReport));
