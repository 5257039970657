const SearchStyle = (theme) => ({
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px",
    textAlign: "left"
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "65px 0px",
  },
  searchGridContainer: {
    width: "100%",
  },
  searchBar: {
    width: "100%",
    background: "white",
  },
  filterMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "100%",
    height: "50px",
    marginRight: "15px",
    color: "#323232",
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "19px 30px !important",
    color: "#323232",
    "&:hover": {
      background: "#FFC720",
    },
  },
  ascendingMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "10%",
    height: "50px",
    marginRight: "15px",
  },
  filtersGridItem: {
    display: "flex",
    alignItems: "center",
  },
  filtersGridItemWithCsvDownload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectText: {
    fontSize: "22px",
    display: "flex",
    justifyContent: "center",
  },
  filtersGridItemWithCsvDownload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  addCustomer: {
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#323232",
    padding: "12px",
    width: "29%",
    display: "flex",
    justifyContent: "space-evenly",
    "&:hover": {
      background: "#FFC720",
    },
  },
  commentDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    margin: "0px 10px",
  },
  statusDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    margin: "0px 10px",
    justifyContent: "center",
    alignItems: "center",
  },
  select: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    background: "#323232",
    '&.MuiSelect-select:focus ': {
      background: "#323232",
      color: "white",
      fontWeight: "bold",
      borderRadius: "5px",
    },
    '& .MuiInput-underline:after' :{
      borderBottom:"2px solid red"
    }
  },
  whiteColor: {
    color: "white",
  },
  selectMenuSort: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "35%",
    height: "50px",
    textAlign:"center",
    fontWeight: "500",
    margin:"15px 15px 0px 10px"
  },
  ascMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "20%",
    height: "50px",
    textAlign:"center",
    fontWeight: "500",
    margin:"15px 15px 0px 10px"
  },
});
export default SearchStyle;
