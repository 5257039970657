export const setGlobalSearchOpen = (boolean) => {
  return {
    type: "SET_GLOBAL_SEARCH",
    boolean,
  };
};

export const setGlobalCalulcatorOpen = (boolean) => {
  return {
    type: "SET_GLOBAL_CALCULATOR",
    boolean,
  };
};

export const setGlobalDumpsterOpen = (boolean) => {
  return {
    type: "SET_GLOBAL_DUMPSTER",
    boolean,
  };
};

export const setGlobalAddOpen = (boolean) => {
  return {
    type: "SET_GLOBAL_ADD",
    boolean,
  };
};
