const initState = {
  reservations: [],
  pendingReservations: [],
  pastReservations: false,
};
export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_RESERVATIONS":
      return {
        ...state,
        reservations: [...action.reservations],
        pendingReservations: [...action.pendingReservations],
      };
    case "SET_PAST_RESERVATIONS":
      return {
        ...state,
        pastReservations: [...action.pastReservations],
      };
    default:
      return state;
  }
}
