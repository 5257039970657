import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconWhite.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIconBlack.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";
import { ReactComponent as CreateRefund } from "assets/img/createRefund.svg";
import { ReactComponent as ApplyCredit } from "assets/img/applyCredit.svg";
import { ReactComponent as VoidPayment } from "assets/img/voidPayment.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import AccountingCustomTabs from "./AccountingCustomTabs";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

const headers = [
  "Details",
  "Date",
  "Amount",
  "Remaining",
  "Original Reservation",
  "Actions",
];

const sortByOptions = ["Refund Date", "Refund Number", "Refund Amount"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];
const pageArr = ["2", "All"];

const mockData = [
  {
    details: "R2 Michael Lloyd",
    date: "Oct 18, 2022",
    amount: "387.00",
    remain: "0.00",
    originalReservation: {
      jobID: "125431180",
      jobNumber: "J-4677",
      rental: "Delivery",
      driver: "stephen clifton"
    }
  }
]

function AccountingRefunds(props) {
  const { classes, reservationsData, tabName, setTabName, history } = props;
  const [sortBy, setSortBy] = useState("Refund Date");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterBySelection, setFilterBySelection] = useState("Filter By");
  const [searchString, setSearchString] = useState("");
  const itemsPerPage = 2;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("2")

  useEffect (() => {
    if(tabName !== "Refund"){
      setTabName("Refund")
    }
  }, [])

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)
  };

  useEffect(() => {
    mockData.slice(index, endIndex)
  }, [index])

  const renderTableHeader = () => {
    return (
      <tr className={classes.notesTableRow} style={{ height: "38px" }}>
        {headers.map((key, index) => {
          return (
            <th className={classes.notesTableHeaders2} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    let tableData;
    if (pageDropdown == "2") {
      tableData = mockData.slice(
        pageNumber * itemsPerPage,
        pageNumber * itemsPerPage + itemsPerPage)
    }
    else {
      tableData = mockData
    }
    return mockData
      .map((d, index) => {
        console.log("checking res", d.originalReservation.driver)
        return (
          <tr className={classes.notesTableRow}>
            <td
              style={{ color: "#0071C5", textDecoration: "underline" }}
              className={classes.notesTableCell}>
              {/* <span
                onClick={() =>
                  history.push({
                    pathname: `/admin/invoice/${d.invoiceId}`,
                    state: {
                      invoice: d,
                    },
                  })
                }
              > */}
                {d.details}
              {/* </span> */}

            </td>
            <td className={classes.notesTableCell}>
              {d.date}
            </td>
            <td className={classes.notesTableCell}>{" "}${d.amount}</td>
            <td className={classes.notesTableCell}>
              {/* {" "}
              $
              {parseFloat(d.reservation.amount) -
                parseFloat(d.reservation.amountPaid)} */}
              {" "}${d.remain}
            </td>
            <td className={classes.notesTableCell}>
              <div style={{display:"flex"}}>
              <span style={{ color: "#0071C5", textDecoration: "underline" }} onClick={() => history.push(`/admin/jobs/${d.originalReservation.jobID}`)}>
                Job #{d.originalReservation.jobNumber}
                </span>
                {" "}|{" "}
                Rental: <b>{d.originalReservation.rental} </b>
                {" "}|{" "}
                Driver: <b>{d.originalReservation.driver}</b>
              </div>

            </td>
            <td className={classes.notesTableCell}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Tooltip
                  id="tooltip-top"
                  title="View Details"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC821" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit Payment"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#323232" }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>

                <Tooltip
                  id="tooltip-top"
                  title="Send Email"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#FEDA1B" }}
                  >
                    <MailIcon />
                  </Button>
                </Tooltip>

                <Tooltip
                  id="tooltip-top"
                  title="Void Payment"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#929292" }}
                  >
                    <VoidPayment />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
  };

  return (
    <Card className={classes.tabCard} style={{ padding: "10px 30px 0px 30px" }}>
      <GridContainer>
        <GridItem className={classes.filtersGridItem} xs={1}>
          <h5 className={classes.subHeader} style={{ margin: "30px 0px" }}>Sort By</h5>
        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={6}>
          <Select
            className={classes.filterMenu}
            value={sortBy}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setSortBy(e.target.value)}
          >
            {sortByOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            className={classes.ascendingMenu}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            value={directionSelection}
            onChange={(e) => setDirectionSelection(e.target.value)}
          >
            {directionOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={5}>
          <TextField
            className={classes.searchBar}
            placeholder="Search Payments"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            variant="outlined"
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
              endAdornment: (
                <InputAdornment className={classes.adournedEnd} position="end">
                  <Button className={classes.searchButton}>Search</Button>
                </InputAdornment>
              ),
            }}
          />
          <Button
            className={classes.createInvoiceButton}
            style={{
              width: "25%",
              fontWeight: 600
            }}
          >
            Export CSV
          </Button>
        </GridItem>

        <GridItem xs={12}>
          <div style={pageDropdown != 2 && mockData.length > 2 ? { height: "500px", width: "100%", overflowY: "scroll" } : null}>
            <table
              className={classes.inventoryTable}
            >
              <tbody>
                {renderTableHeader()}
                {renderTableData()}
              </tbody>
            </table>
          </div>
          {pageDropdown == "2" ?
            <GridContainer style={{ paddingTop: "10px" }}>
              <GridItem xs={6} >
                <p className={classes.view}>View
                  <span>
                    <Select
                      style={{ width: "10%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                      disableUnderline
                      MenuProps={{
                        // className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor
                      }}

                      onChange={(e) => {
                        setPageDrowdown(e.target.value)
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
              <GridItem xs={4} >
                <h5 className={classes.indexes}>{index + 1} - {endIndex} of
                  {mockData.length < itemsPerPage ? " " + mockData.length :
                    " " + Math.ceil(mockData.length / itemsPerPage)}
                </h5>
              </GridItem>
              <GridItem xs={2}>
                <ReactPaginate
                  paginationSize={0}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(mockData.length / itemsPerPage)}
                  nextLabel={<ArrowRight style={{ fontSize: 25, fontWeight: "bold", color: "white", marginBottom: "-8px" }} />}
                  previousLabel={<ArrowLeft style={{ fontSize: 25, fontWeight: "bold", color: "white", marginBottom: "-8px" }} />}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </GridItem>
            </GridContainer>
            :
            <GridContainer>
              <GridItem xs={6}>
                <p className={classes.view}>View
                  <span>
                    <Select
                      style={{ width: "10%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                      disableUnderline
                      MenuProps={{
                        // className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor
                      }}
                      onChange={(e) => {
                        setPageDrowdown(e.target.value)
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
            </GridContainer>
          }
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(AccountingRefunds));
