import { db } from "firebase/client";

export const listenToCreditMemos = () => {
  return (dispatch) => {
    db.collection("credit-memos").onSnapshot((snapshot) => {
      const creditMemos = snapshot.docs.map((d) => d.data());
      dispatch(setCreditMemos(creditMemos));
    });
  };
};

export const setCreditMemos = (creditMemos) => {
  return {
    type: "SET_CREDIT_MEMOS",
    creditMemos,
  };
};
