import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';

import RegistrationActions from 'store/actions/forms/registration';
import Style from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Card from 'components/Card/Card';
import { TextField } from '@material-ui/core';


export function AlreadyRegistered(props) {

    const { classes, passwordReset } = props;
    const [email, setEmail] = useState("")

    return (
        <div className={classes.container}>
            <Card className={classes.signInCard}>
                <GridContainer style={{ justifyContent: "center" }}>
                    <GridItem xs={12} >
                        <p className={classes.errorMessage}>
                            Forgot your password?
                        </p>
                        <p className={classes.errorMessage}>
                            Type in your email in the box below
                        </p>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <TextField
                                style={{ width: "40%" }}
                                className={classes.emailInput}
                                value={email}
                                variant="outlined"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button
                                style={{
                                    backgroundColor: '#323232',
                                    color: 'white',
                                    fontWeight: '600',
                                    width: "50%"
                                }}
                                onClick={e => passwordReset(e, email)}
                                className={classes.signInButton}
                            >
                                Reset Password
                            </Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </Card>
        </div>
    );
}

AlreadyRegistered.propTypes = {
    classes: PropTypes.object.isRequired,
    passwordReset: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    passwordReset: () => { dispatch(RegistrationActions.cancelAndResetPassword()) }
});

const mapStateToProps = () => {
    return {
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
)(AlreadyRegistered);
