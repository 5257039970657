import { firebase, db } from "firebase/client";

const customersCollection = db.collection("customers");

export const makeCustomerARegular = (customer) => {
  console.log("customer", customer);
  return (dispatch) => {
    if (customer.comments.includes("Regular")) {
      dispatch(defaultType());
    } else {
      return customersCollection
        .doc(customer.customerId)
        .update({
          ...customer,
          comments: [...customer.comments, "Regular"],
        })
        .then(() => {
          dispatch(defaultType());
        });
    }
  };
};

export const removeCustomerTag = (customer) => {
  return (dispatch) => {
    if (!customer.comments.includes("Regular")) {
      dispatch(defaultType());
    } else {
      const indexToRemove = customer.comments.indexOf("Regular");
      customer.comments.splice(indexToRemove);
      return customersCollection
        .doc(customer.customerId)
        .update({
          ...customer,
          comments: [...customer.comments],
        })
        .then(() => {
          dispatch(defaultType());
        });
    }
  };
};

export const updateCustomer = (customer) => {
  return (dispatch) => {
    const capitalFirstName =
      customer.firstName.charAt(0).toUpperCase() + customer.firstName.slice(1);
    const capitalLastName =
      customer.lastName.charAt(0).toUpperCase() + customer.lastName.slice(1);
    customer.firstName = capitalFirstName;
    customer.lastName = capitalLastName;
    customer.name = capitalFirstName + " " + capitalLastName;
    return customersCollection
      .doc(customer.customerId)
      .update({
        ...customer,
      })
      .then(() => {
        dispatch(defaultType());
      });
  };
};

const defaultType = () => {
  return {
    type: "DEFAULT",
  };
};

export const listenToCustomers = () => {
  return (dispatch) => {
    customersCollection.onSnapshot((snapshot) => {
      const customers = snapshot.docs.map((d) => d.data());
      const regulars = customers.filter((customer) =>
        customer.comments.includes("Regular") ? true : false
      );
      const sortedRegulars = regulars.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const sortedCustomers = customers.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      dispatch(setCustomers(sortedCustomers, sortedRegulars));
    });
  };
};

export const createCustomer = (customerToCreate) => {
  return (dispatch) => {
    const customerId = customersCollection.doc().id;
    customerToCreate.customerId = customerId;
    const capitalFirstName =
      customerToCreate.firstName.charAt(0).toUpperCase() +
      customerToCreate.firstName.slice(1);
    const capitalLastName =
      customerToCreate.lastName.charAt(0).toUpperCase() +
      customerToCreate.lastName.slice(1);
    customerToCreate.firstName = capitalFirstName;
    customerToCreate.lastName = capitalLastName;
    customerToCreate.name = capitalFirstName + " " + capitalLastName;
    customerToCreate.reminders = []
    return customersCollection
      .doc(customerId)
      .set({
        ...customerToCreate,
        customerSince: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        dispatch(defaultType());
      });
  };
};

export const setCustomers = (customers, regulars) => {
  return {
    type: "SET_CUSTOMERS",
    customers,
    regulars,
  };
};
