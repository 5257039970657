import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";

const headers = [
  "Rental Details",
  "Scheduled",
  "End Date",
  "Site Address",
  "Inventory",
  "Recurring",
  "Status",
  "Actions",
];

function RentalsTable(props) {
  const { classes, reservations, history, pageDropdown, pageNumber, itemsPerPage } = props;
  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.entries(e).forEach(([key, value]) => {
        if (value) {
          let str = "";
          switch (key) {
            case "tenYard":
              str = "10 Yard Container";
              break;
            case "fifteenYard":
              str = "15 Yard Container";
              break;
            case "twentyYard":
              str = "20 Yard Container";
              break;
            case "thirtyYard":
              str = "30 Yard Container";
              break;
            case "metalOrConcrete":
              str = "Metal or Concrete";
              break;
            case "standardThirty":
              str = "Standard 30 Yard";
              break;
          }
          returnStr = returnStr + str + " ";
        }
      });
      return returnStr;
    } else {
      return e;
    }
  };

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if(pageDropdown == "3"){
      return reservations
      .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
      .map((d, index) => {
        console.log('reservations', reservations)
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/rentals/${d.reservation.reservationId}`,
                        state: { reservation: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {" "}
                    {d.reservation.reservationId}
                  </span>{" "}
                  |
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${d.customerId}`,
                        state: { customer: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {d.name}
                  </span>
                </div>
                <div style={{ fontSize: "16px" }}>
                  Driver: <span>{d.reservation.driver}</span>{" "}
                </div>
              </div>
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.reservation.startDate}
            </td>
            <td className={classes.tableCell}>{d.reservation.endDate}</td>
            <td className={classes.tableCell}>{d.reservation.siteAddress}</td>
              <td className={classes.tableCell}>
                {renderEquipment(d.reservation.equiptment)}
              </td>
            <td className={classes.tableCell}>
              {d.reservation.recurring ? "Yes" : "No"}
            </td>
            <td className={classes.tableCell}>
              <div
                style={
                  d.reservation.status === "Scheduled"
                    ? { background: "#E01E5A" }
                    : d.reservation.status === "Completed"
                    ? { background: "#2EB67D" }
                    : d.reservation.status === "In Progress"
                    ? { background: "#E0AC12", color: "#323232" }
                    : { background: "#323232" }
                }
                className={classes.commentDiv}
              >
                {d.reservation.status}
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#ECB22E" }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
    else{
      return reservations.map((d, index) => {
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/rentals/${d.reservation.reservationId}`,
                        state: { reservation: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {" "}
                    {d.reservation.reservationId}
                  </span>{" "}
                  |
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${d.customerId}`,
                        state: { customer: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {d.name}
                  </span>
                </div>
                <div style={{ fontSize: "16px" }}>
                  Driver: <span>{d.reservation.driver}</span>{" "}
                </div>
              </div>
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.reservation.startDate}
            </td>
            <td className={classes.tableCell}>{d.reservation.endDate}</td>
            <td className={classes.tableCell}>{d.reservation.siteAddress}</td>
            <td className={classes.tableCell}>
              {renderEquipment(d.reservation.equiptment)}
            </td>
            <td className={classes.tableCell}>
              {d.reservation.recurring ? "Yes" : "No"}
            </td>
            <td className={classes.tableCell}>
              <div
                style={
                  d.reservation.status === "Scheduled"
                    ? { background: "#E01E5A" }
                    : d.reservation.status === "Completed"
                    ? { background: "#2EB67D" }
                    : d.reservation.status === "In Progress"
                    ? { background: "#E0AC12", color: "#323232" }
                    : { background: "#323232" }
                }
                className={classes.commentDiv}
              >
                {d.reservation.status}
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#ECB22E" }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
      <div style={pageDropdown != 3 && reservations.length > 3 ? { height: "450px", width: "100%", overflowY: "scroll" } : null}>
        <table className={classes.inventoryTable}>
          <tbody>
            {renderTableHeader()}
            {renderTableData()}
          </tbody>
        </table>
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(RentalsTable));
