import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from "firebase/client.js";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import CreateQuoteHeader from "./components/CreateQuoteHeader";
import SwapDetails from "./components/SwapDetails";
import QuoteCustomerCard from "./components/QuoteCustomerCard";
import loader from "assets/img/loader.gif";
import Button from "@material-ui/core/Button";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { addQuote } from "store/actions/quotes/quotesActions";
import SwapInfo from "./components/SwapInfo";
import SwapOtherInfo from "./components/SwapOtherInfo";

const locationSelections = [
    "Select Option",
    "Right Side of Driveway",
    "Left Side of Driveway",
    "Middle of Driveway",
    "On Street",
    "Side of House",
    "Backyard",
    "Alley",
    "Other (Please specify below)"
];

function RequestSwap(props) {
    const { classes, history, handleAddQuote } = props;
    const [quoteType, setQuoteType] = useState({
        individual: false,
        business: false,
    });
    const [textOrEmail, setTextOrEmail] = useState({
        text: false,
        email: false,
    });
    const [customer, setCustomer] = useState("");
    const [site, setSite] = useState("");
    console.log("site", site);
    const [productType, setProductType] = useState("");
    const [quantity, setQuantity] = useState(0);
    const [rate, setRate] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [duration, setDuration] = useState("");
    const [notes, setNotes] = useState("");
    const [pickUpAddress, setPickUpAddress] = useState("")
    const [swapDate, setSwapDate] = useState("");
    const [pickUpDate, setPickUpDate] = useState("");
    const [sevenDayRental, setSevenDayRental] = useState(false);
    const [otherDuration, setOtherDuration] = useState(false);
    const [equipmentLocation, setEquipmentLocation] = useState("Select Option");
    const [inventoryData, setInventoryData] = useState([]);
    const [cost, setCost] = useState("")
    const [equipmentNeeded, setEquipmentNeeded] = useState("")
    const [dataLoaded, setDataLoaded] = useState(false);
    const [schedulingConsent, setSchedulingConsent] = useState({
        yes: false,
        no: false
    })
    const [file, setFile] = useState()
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [dropOffLat, setDropOffLat] = useState(false);
    const [dropOffLng, setDropOffLng] = useState(false);
    const [siteAddress, setSiteAddress] = useState("");
    const [imageFile, setImageFile] = useState('')

    const [errors, setErrors] = useState({
        pickUpAddress: false,
        swapDate: false,
        sevenDayRental: false,
        pickupDate: false,
        startDate: false,
        equipmentNeeded: false,
        equipmentLocation: false,
        schedulingConsent: false
    });

    const handleSelect = (address, placeholder) => {
        geocodeByAddress(address).then((results) => {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            //   if (placeholder === "Search your billing address..") {
            //     setBillingAddress(results[0].formatted_address);
            //   }
            if (placeholder === "Search your drop-off address..") {
                const addressResults = results[0];
                addressResults.address_components.forEach((r) => {
                    if (r.types.includes("locality")) {
                        setCity(r.long_name);
                    }
                    if (r.types.includes("administrative_area_level_1")) {
                        setState(r.short_name);
                    }
                    if (r.types.includes("postal_code")) {
                        setZipCode(r.short_name);
                    }
                });
                setSiteAddress(addressResults.formatted_address);
                setDropOffLat(lat);
                setDropOffLng(lng);
            }
        });
    };

    useEffect(() => {
        if (!dataLoaded) {
            const fetchData = async () => {
                return db
                    .collection("inventory")
                    .orderBy("sortOrder")
                    .get()
                    .then((snapshot) => {
                        const inventory = snapshot.docs.map((d) => d.data());
                        setInventoryData(inventory);
                        setDataLoaded(true);
                    });
            };
            fetchData();
        }
    }, [dataLoaded]);


    const handleSubmit = () => {
        if (
            !customer ||
            !productType ||
            quantity === 0 ||
            !quantity ||
            !rate ||
            !startDate ||
            !endDate ||
            (!quoteType.individual && !quoteType.business) ||
            (!textOrEmail.text && !textOrEmail.email) ||
            !site
        ) {
            let newErrors = {
                customer: false,
                productType: false,
                quantity: false,
                rate: false,
                startDate: false,
                endDate: false,
                communication: false,
                quoteType: false,
                textOrEmail: false,
                site: false,
            };
            if (!customer) {
                newErrors = { ...newErrors, customer: true };
            }
            if (!productType) {
                newErrors = { ...newErrors, productType: true };
            }
            if (!quantity || quantity === 0) {
                newErrors = { ...newErrors, quantity: true };
            }
            if (!rate) {
                newErrors = { ...newErrors, rate: true };
            }
            if (!startDate) {
                newErrors = { ...newErrors, startDate: true };
            }
            if (!endDate) {
                newErrors = { ...newErrors, endDate: true };
            }
            if (!quoteType.individual && !quoteType.business) {
                newErrors = { ...newErrors, quoteType: true };
            }
            if (!textOrEmail.email && !quoteType.text) {
                newErrors = { ...newErrors, textOrEmail: true };
            }
            if (!site) {
                newErrors = { ...newErrors, site: true };
            }
            setErrors({ ...newErrors });
        } else {
            //   const pendingQuote = {
            //     ...customer,
            //     quoteType: quoteType.individual ? "Individual" : "Business",
            //     communicationPreference: textOrEmail.text ? "Text" : "Email",
            //     quoteTimestamp: firebase.firestore.Timestamp.fromDate(new Date()),
            //     reservation: {
            //       amount: rate,
            //       amountPaid: "0",
            //       driver: "N/A",
            //       endDate,
            //       territory: "",
            //       equiptment: productType.inventoryItem,
            //       dropOffCity: site.city,
            //       dropOffState: "N/A",
            //       dropOffZipCode: "N/A",
            //       equiptmentLocation: "N/A",
            //       existingCustomer: true,
            //       heardAboutUsFrom: "N/A",
            //       inventoryId: productType.inventoryId,
            //       lastEmailedDate: "N/A",
            //       notes: notes,
            //       otherInformation: "",
            //       paymentDueDate: endDate,
            //       poNumber: "",
            //       promoCode: "",
            //       quantity,
            //       recurring: false,
            //       sevenDayReservation:
            //         getARangeOfDays(startDate, endDate) === 7 ? true : false,
            //       siteAddress: site.siteAddress,
            //       siteId: site.siteId,
            //       startDate,
            //       status: "Unapproved",
            //       spokenWithTeamMember: false,
            //     },
            //   };
            //   handleAddQuote(pendingQuote);
        }
    };

    return (
        <GridContainer>
            <GridItem className={classes.marginBottomGridItem} xs={12}>
                <CreateQuoteHeader />
            </GridItem>
            <GridItem className={classes.marginBottomGridItem} xs={12}>
                <SwapDetails
                    history={history}
                    errors={errors}
                    pickUpAddress={pickUpAddress}
                    setPickUpAddress={setPickUpAddress}
                    swapDate={swapDate}
                    setSwapDate={setSwapDate}
                    sevenDayRental={sevenDayRental}
                    setSevenDayRental={setSevenDayRental}
                    otherDuration={otherDuration}
                    setOtherDuration={setOtherDuration}
                    pickupDate={pickUpDate}
                    setPickUpDate={setPickUpDate}
                    handleSelect={handleSelect}
                />
            </GridItem>
            <GridItem className={classes.marginBottomGridItem} xs={12}>
                <SwapInfo
                    history={history}
                    equipmentLocation={equipmentLocation}
                    setEquipmentLocation={setEquipmentLocation}
                    locationSelections={locationSelections}
                    inventoryData={inventoryData}
                    setInventoryData={setInventoryData}
                    setCost={setCost}
                    cost={cost}
                    schedulingConsent={schedulingConsent}
                    setSchedulingConsent={setSchedulingConsent}
                    equipmentNeeded={equipmentNeeded}
                    setEquipmentNeeded={setEquipmentNeeded}
                    errors={errors}
                />
            </GridItem>
            <GridItem className={classes.marginBottomGridItem} xs={12}>
                <SwapOtherInfo
                    history={history}
                    errors={errors}
                    notes={notes}
                    setNotes={setNotes}
                    errors={errors}
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                />
            </GridItem>
            <GridItem className={classes.cancelSaveBtnGrid} xs={12}>
                <Button
                    style={{ background: "#323232", color: "white" }}
                    className={classes.cancelSaveButton}
                    onClick={() => history.push("/admin/settings")}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    style={{
                        background: "#FFC821",
                        color: "#323232",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                    }}
                    className={classes.cancelSaveButton}
                >
                    <CheckIcon />
                    Save
                </Button>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        customersData: state.customers.customers,
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleAddQuote: (quote) => dispatch(addQuote(quote)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(reservationDetailStyle)
)(React.memo(RequestSwap));
