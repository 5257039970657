import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import { firebase, db, storage } from "firebase/client";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import DepositDetailTable from "./components/DepositDetailTable";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Card from "components/Card/Card.js";
import ChartistGraph from "react-chartist";
import loader from "assets/img/loader.gif";
import CustomLinearProgress from "./components/CustomLinearProgress.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { reportSelections } from "../config/globals";
import "./components/chart.css";

import Slider from "@material-ui/core/Slider";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

function CustomerReferralReport(props) {
  const { classes, reservationsData, history } = props;
  const [referrals, setReferrals] = useState(false);
  const [tableSelection, setTableSelection] = useState("");
  const [percentages, setPercentages] = useState({});

  useEffect(() => {
    const getReferrals = async () => {
      return db
        .collection("referrals")
        .doc("referrals-doc")
        .get()
        .then((snapshot) => {
          const referralsData = snapshot.data();
          const googlePercentage =
            (referralsData["Google"] / referralsData.totalCount) * 100;
          const homeAdvisorPercentage =
            (referralsData["HomeAdvisor"] / referralsData.totalCount) * 100;
          const nextDoorPercentage =
            (referralsData["NextDoor"] / referralsData.totalCount) * 100;
          const otherPercentage =
            (referralsData["Other"] / referralsData.totalCount) * 100;
          const pastCustomerPercentage =
            (referralsData["Past Customer"] / referralsData.totalCount) * 100;
          const referralPercentage =
            (referralsData["Referral"] / referralsData.totalCount) * 100;
          const socialMediaPercentage =
            (referralsData["Social Media"] / referralsData.totalCount) * 100;
          const yelpPercentage =
            (referralsData["Yelp"] / referralsData.totalCount) * 100;

          const percentageData = {
            Google: googlePercentage,
            HomeAdvisor: homeAdvisorPercentage,
            NextDoor: nextDoorPercentage,
            Other: otherPercentage,
            "Past Customer": pastCustomerPercentage,
            Referral: referralPercentage,
            "Social Media": socialMediaPercentage,
            Yelp: yelpPercentage,
          };
          setPercentages(percentageData);
          setReferrals(referralsData);
        });
    };
    getReferrals();
  }, []);

  var delays2 = 80,
    durations2 = 500;

  const pieChart = {
    data: {
      labels: Object.keys(percentages),
      series: Object.values(percentages),
    },
    options: {
      height: "600px",
      width: "600px",
      donut: true,
      donutWidth: 130,
      showLabel: true,
    },
  };

  const simpleBarChart = {
    data: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]],
    },
    options: {
      seriesBarDistance: 10,
      axisX: {
        showGrid: false,
      },
    },
    responsiveOptions: [
      [
        "screen and (max-width: 640px)",
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            },
          },
        },
      ],
    ],
    animation: {
      draw: function (data) {
        if (data.type === "bar") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }
      },
    },
  };

  // if (!referrals)
  //   return (
  //     <div
  //       style={{
  //         width: "100%",
  //         height: "100vh",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <img style={{ width: "20%" }} src={loader} alt="..." />
  //     </div>
  //   );

  return (
    <GridContainer>
      <GridItem className={classes.jobsHeaderGridItem} xs={12}>
        <h1 className={classes.title}>Customer Referral Sources</h1>
      </GridItem>
      <GridItem xs={12}>
        <Card className={classes.referralCard}>
          <div style={{ width: "50%" }} className={classes.agingGridItem}>
            <span className={classes.switchReport}>Switch Report</span>
            <Select
              variant="outlined"
              className={classes.filterMenu}
              value={tableSelection}
              sx={{ background: "#F5F5F5" }}
              disableUnderline
              defaultValue={"Select Option"}
              classes={{
                root: classes.selectText,
              }}
              onChange={(e) => history.push(e.target.value.link)}
              displayEmpty
                renderValue={
                  tableSelection !== "" ? undefined : () => "Customer Referral Source"
                }
            >
              {reportSelections.map((o, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={o}
                    classes={{
                      root: classes.selectMenuItemReport,
                    }}
                  >
                    {o.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <GridContainer className={classes.referralCard}>
            <GridItem className={classes.justifyContentCenter} xs={8}>
              <ChartistGraph
                data={pieChart.data}
                type="Pie"
                options={pieChart.options}
              />
            </GridItem>
            <GridItem className={classes.columnGridItem} xs={4}>
              {Object.keys(percentages).map((key, index) => {
                return (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{key}</span>
                        <span>{Math.round(percentages[key])}%</span>
                      </div>
                      <CustomLinearProgress
                        variant="determinate"
                        color={
                          key === "Google"
                            ? "#ffc821"
                            : key === "Home Advisor"
                            ? "#d9d9d9"
                            : key === "Next Door"
                            ? "#323232"
                            : key === "Other"
                            ? "#F5EB29"
                            : key === "Past Customer"
                            ? "#929292"
                            : key === "Referral"
                            ? "#ffc821"
                            : key === "Social Media"
                            ? "#d9d9d9"
                            : "#323232"
                        }
                        value={Math.round(percentages[key])}
                      />
                    </div>
                  </>
                );
              })}
            </GridItem>
          </GridContainer>

          {/* <ChartistGraph
            className="ct-chart-white-colors"
            data={simpleBarChart.data}
            type="Bar"
            options={simpleBarChart.options}
            responsiveOptions={simpleBarChart.responsiveOptions}
            listener={simpleBarChart.animation}
          /> */}
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(CustomerReferralReport));
