import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase, sendInvoiceLinkLocalHost } from "firebase/client";
import InvoiceNameCard from "./components/InvoiceNameCard";
import ServicesCard from "./components/ServicesCard";
import MemoCard from "./components/MemoCard";
import Attachments from "./components/Attachments";
import ScheduleJobFor from "./components/ScheduleJobFor";
import DriverNotes from "./components/DriverNotes";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import loader from "assets/img/loader.gif";

import ViewTerms from "./components/ViewTerms";
import EditTerms from "./components/EditTerms";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddCreditMemo(props) {
  const { classes, history, userInfo } = props;
  const [snackBarContent, setSnackBarContent] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [servicesSubTotal, setServicesSubTotal] = useState(0);
  const [servicesTotal, setServicesTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discount, setDiscount] = useState("");

  const [invoiceDate, setInvoiceDate] = useState("");
  const [inventoryNeeded, setInventoryNeeded] = useState("");
  const [quantityNeeded, setQuantityNeeded] = useState(1);
  const [services, setServices] = useState([]);
  const [poNumber, setPoNumber] = useState("");
  const [recurring, setRecurring] = useState(false);
  const [memo, setMemo] = useState("");
  const [preferredCommunication, setPreferredCommunication] = useState("Email");
  const [customerInfo, setCustomerInfo] = useState(false);
  const [siteInfo, setSiteInfo] = useState(false);
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(false);

  const [viewAndEditTermsModal, setViewAndEditTermsModal] = useState("");

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [jobType, setJobType] = useState("");
  const [savingImage, setSavingImage] = useState(false);
  const [errors, setErrors] = useState({
    customer: false,
    invoiceDate: false,
    services: false,
  });

  const newInvoiceID = db.collection("credit-memos").doc().id;

  // console.log("setInvoiceDate", invoiceDate);
  const setDefaultCosts = () => {
    let subtotal = 0;
    let taxTotalSum = 0;
    services.forEach((s) => {
      const parsedRate = parseFloat(s.rate);
      const totalTimesQty = parsedRate * quantityNeeded;
      subtotal += totalTimesQty;
      if (s.tax) {
        const taxCalc = 4.4 / 100;
        const salestax = s.rate * taxCalc;
        taxTotalSum += salestax;
      }
    });
    setServicesSubTotal(subtotal);
    // setDiscount(invoice.discount ? invoice.discount : "");
    setServicesTotal(subtotal + taxTotalSum);
    setTaxTotal(taxTotalSum);
  };

  const submitInvoice = () => {
    if (!customerInfo || !invoiceDate || services.length === 0) {
      let newErrors = {
        customer: false,
        invoiceDate: false,
        services: false,
      };
      if (!customerInfo) {
        newErrors = { ...newErrors, customer: true };
      }
      if (!invoiceDate) {
        newErrors = { ...newErrors, invoiceDate: true };
      }
      if (services.length === 0) {
        newErrors = { ...newErrors, services: true };
      }
      setErrors({ ...newErrors });
    } else {
      const invoiceData = {
        customerId: customerInfo.customerId,
        billingAddress: customerInfo.billingAddress,
        billingAddressName: "",
        billingCity: "",
        billingSecondaryAddress: customerInfo.billingAddressLine2,
        billingState: "",
        billingZip: "",
        companyDivision: "",
        customerAddress1: "",
        customerAddress2: "",
        customerAddressCity: "",
        customerAddressState: "",
        customerAddressZip: "",
        customerCompanyOrOrganization: customerInfo.company,
        customerEmails: customerInfo.email,
        customerFirstName: customerInfo.firstName,
        customerID: customerInfo.customerId,
        customerLastName: customerInfo.lastName,
        customerName: customerInfo.name,
        customerNumber: "N/A",
        customerPhone: customerInfo.phoneNumber,
        customerPhoneExtension: "",
        dateDue: "",
        deliveryMethod: "",
        id: newInvoiceID,
        isCustomerNotified: "false",
        paymentTotal: "",
        poNumber,
        remaining: servicesTotal,
        serviceAddress: "",
        serviceAddressName: "",
        serviceCity: "",
        serviceSecondaryAddress: "",
        serviceState: "",
        serviceZip: "",
        tax: taxTotal,
        taxCode: "",
        transactionAmount: "",
        transactionNumber: "N/A",
        transactionDate: invoiceDate,
        total: servicesTotal,
        status: "Unapplied",
        services: services,
        termsAndConditions: termsAndConditions,
        attachments: attachments,
        internalMemo: memo,
        clerk: userInfo.firstName[0] + userInfo.lastName[0],
        recurring: recurring,
        discount: discount,
      };

      return db
        .collection("credit-memos")
        .doc(newInvoiceID)
        .set(invoiceData)
        .then(() => {
          history.push("/admin/accounting");
          // const customerInvoices = customerInfo.invoices || [];
          // return db
          //   .collection("customers")
          //   .doc(customerInfo.customerId)
          //   .update({
          //     invoices: [...customerInvoices, newInvoiceID],
          //   })
          //   .then(() => {
          //     if (sendConfirmationEmail) {
          //       sendInvoiceLinkLocalHost(customerInfo.email);
          //       setSnackBarContent("Successfully created invoice");
          //       history.push("/admin/accounting");
          //     } else {
          //       setSnackBarContent("Successfully created invoice");
          //       history.push("/admin/accounting");
          //     }
          //   });
        });
    }
  };

  useEffect(() => {
    if (!termsAndConditions) {
      const getTerms = async () => {
        return db
          .collection("terms-and-conditions")
          .doc("terms-and-conditions")
          .get()
          .then((termsSnap) => {
            const termsData = termsSnap.data();
            setTermsAndConditions(termsData.termsHtml);
          });
      };
      getTerms();
    }
  }, []);

  useEffect(() => {
    if (services.length > 0) {
      setDefaultCosts();
    }
  }, [services]);

  // useEffect(() => {
  //   setDefaultCosts()
  // }, [quantityNeeded])

  useEffect(() => {
    let subtotal = 0;
    services.forEach((s) => {
      const parsedRate = parseFloat(s.rate);
      const totalTimesQty = parsedRate * quantityNeeded;
      subtotal += totalTimesQty;
    });
    const total = subtotal + taxTotal;
    setServicesTotal(total.toFixed(2));
  }, [taxTotal]);

  const handleChangeDiscount = (value) => {
    if (!value) {
      setDiscount("");
      setDefaultCosts();
    } else {
      const valueNumber = parseFloat(value);
      let subtotal = 0;
      services.forEach((s) => {
        const parsedRate = parseFloat(s.rate);
        const totalTimesQty = parsedRate * quantityNeeded;
        subtotal += totalTimesQty;
      });
      setDiscount(valueNumber);
      setServicesTotal(subtotal + taxTotal - valueNumber);
      const total = subtotal + taxTotal - valueNumber;
      setServicesTotal(total.toFixed(2));
    }
  };

  return (
    <GridContainer>
      <GridItem
        className={classes.alignItems + " " + classes.marginBottom}
        xs={12}
      >
        <h1 style={{ marginBottom: "0px" }} className={classes.title}>
          Create New Credit Memo
        </h1>
      </GridItem>
      {/* <GridItem
        className={classes.alignItems + " " + classes.marginBottom}
        xs={1}
      >
        {!invoice.invoiceDetails.paymentStatus ? (
          <span className={classes.totalTitle}>$0.00</span>
        ) : (
          <span className={classes.totalTitle}>${servicesSubTotal}</span>
        )}
      </GridItem> */}
      <GridItem className={classes.columnGridItem} xs={12}>
        <InvoiceNameCard
          // invoice={invoice}
          // reservation={reservation}
          classes={classes}
          history={history}
          // customerInfo={customerInfo}
          invoiceDate={invoiceDate}
          setCustomerInfo={setCustomerInfo}
          setInvoiceDate={setInvoiceDate}
          userInfo={userInfo}
          poNumber={poNumber}
          setPoNumber={setPoNumber}
          recurring={recurring}
          setRecurring={setRecurring}
          setSiteInfo={setSiteInfo}
          errors={errors}
          setPreferredCommunication={setPreferredCommunication}
        />
      </GridItem>
      <GridItem className={classes.columnGridItem} xs={12}>
        <ServicesCard
          servicesTotal={servicesTotal}
          taxTotal={taxTotal}
          servicesSubTotal={servicesSubTotal}
          history={history}
          customer={customerInfo}
          jobType={jobType}
          setJobType={setJobType}
          setTaxTotal={setTaxTotal}
          inventoryNeeded={inventoryNeeded}
          setInventoryNeeded={setInventoryNeeded}
          quantityNeeded={quantityNeeded}
          setQuantityNeeded={setQuantityNeeded}
          services={services}
          setServices={setServices}
          discount={discount}
          handleChangeDiscount={handleChangeDiscount}
          setViewAndEditTermsModal={setViewAndEditTermsModal}
          invoiceDate={invoiceDate}
          poNumber={poNumber}
          recurring={recurring}
          setServicesTotal={setServicesTotal}
          setDefaultCosts={setDefaultCosts}
          errors={errors}
        />
      </GridItem>

      <GridItem className={classes.columnGridItem} xs={12}>
        <MemoCard
          history={history}
          memo={memo}
          setMemo={setMemo}
          preferredCommunication={preferredCommunication}
          setPreferredCommunication={setPreferredCommunication}
        />
      </GridItem>

      <GridItem className={classes.columnGridItem} xs={12}>
        <Attachments
          history={history}
          setAttachments={setAttachments}
          attachments={attachments}
          setSavingImage={setSavingImage}
          savingImage={savingImage}
          newInvoiceID={newInvoiceID}
          sendConfirmationEmail={sendConfirmationEmail}
          setSendConfirmationEmail={setSendConfirmationEmail}
        />
      </GridItem>

      <GridItem
        className={classes.spaceBetween + " " + classes.marginTop}
        xs={12}
      >
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button
          onClick={submitInvoice}
          style={{
            background: "#FFC821",
            color: "black",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent.includes("Successfully") ? "success" : "error"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={viewAndEditTermsModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setViewAndEditTermsModal("")}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        {viewAndEditTermsModal === "View" ? (
          <ViewTerms
            setViewAndEditTermsModal={setViewAndEditTermsModal}
            termsAndConditions={termsAndConditions}
          />
        ) : viewAndEditTermsModal === "Edit" ? (
          <EditTerms
            setViewAndEditTermsModal={setViewAndEditTermsModal}
            setTermsAndConditions={setTermsAndConditions}
            termsAndConditions={termsAndConditions}
            setSnackBarContent={setSnackBarContent}
            setDataLoaded={setDataLoaded}
          />
        ) : (
          <div></div>
        )}
      </Dialog>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(AddCreditMemo));
