const rentalsStyles = (theme) => ({
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px 0px 20px 0px",
    textAlign: "left",
  },
  buttonGridItem: {
    display: "flex",
    justifyContent: "end",
  },
  addCustomer: {
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#323232",
    padding: "12px",
    width: "29%",
    display: "flex",
    justifyContent: "space-evenly",
    "&:hover": {
      background: "#FFC720",
    },
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "65px 0px",
  },
  tabWrapper: {
    color: "#323232 !important",
    width: "100px !important",
  },
  tabSelected: {
    background: "#FFC720",
    borderWidth: "0.5px 0.5px 0px 0.5px",
    borderStyle: "solid",
    borderColor: "#8E8E8E",
    borderRadius: "2px 2px 0px 0px",
  },
  customTabsGridItem: {
    marginTop: "35px",
  },
  card: {
    marginTop: "0px",
  },
  secondCard: {
    marginTop: "20px",
  },
  selectMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "50%",
    height: "50px",
    marginRight: "15px",
    padding: "10px",
  },
  ascendingMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "10%",
    height: "50px",
    marginRight: "15px",
    padding: "10px",
  },
  filterMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    marginRight: "15px",
    padding: "10px",
  },
  filtersGridItem: {
    display: "flex",
    alignItems: "center",
  },
  filtersGridItemWithCsvDownload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  searchBar: {
    width: "60%",
    background: "white",
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "16px 30px !important",
    color: "#323232",
    "&:hover": {
      background: "#FFC720",
    },
  },
  rentalsSubheader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "0px",
    padding: "20px",
  },
  nameField: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "0px",
    paddingBottom: "20px",
  },
  googleMapGridItem: {
    height: "500px",
  },
  directionsGridItem: {
    display: "flex",
    flexDirection: "column",
  },
  noPaddingGridItem: {
    padding: "0px 0px 0px 15px !important",
    display: "flex",
    alignItems: "center",
  },
  exportCsvGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  instructionsGridItem: {
    display: "flex",
    flexDirection: "column",
    padding: "0 40px !important",
  },
  nameGridItem: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 40px !important",
  },
  listTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    color: "#323232",
  },
  list: {
    padding: "0px 20px",
  },
  listItem: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "14px",
    color: "#323232",
  },
  selectedColor: {
    width: "50px",
    height: "50px",
    border: "2px solid #323232",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  colors: {
    width: "40px",
    height: "40px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  colorsDescription: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "15px",
    color: "#323232",
  },
  saveButtonsGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  saveButton: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "6%",
  },
});

export default rentalsStyles;
