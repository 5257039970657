import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";
import { MenuItem, Select } from "@material-ui/core";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import LoadTable from "./LoadTable";


const pageArr = ["4", "All"];

function Sites(props) {
    const { classes, history, reservationsData, user } = props;
    const [sortBySelection, setSortBySelection] = useState("Inventory Size");
    const [directionSelection, setDirectionSelection] = useState("Asc");
    const [filterSelection, setFilterSelection] = useState("Rental Number");
    const [searchString, setSearchString] = useState("");
    const [reservations, setReservations] = useState([]);
    const [inProgressCount, setInProgressCount] = useState("");


    return (
        <GridContainer>
            <GridItem xs={6} style={{ display: "flex" }}>
                <GridContainer>
                    <GridItem xs={6}>
                        <h5 className={classes.title} style={{ whiteSpace: "noWrap" }}>Load Types</h5>
                    </GridItem>
                    <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            className={classes.addCustomer}
                            style={{
                                margin: "-2px 0px 5px 0px",
                                height: "42px",
                                width: "65%",
                            }}>
                            <AddIcon style={{ marginRight: "5px" }} /> Create Load Type
                        </Button>
                    </GridItem>
                    <GridItem xs={12}>
                        <LoadTable
                            user={user}
                            history={history}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        reservationsData: state.reservations.reservations,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(rentalsStyles)
)(React.memo(Sites));
