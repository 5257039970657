import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { BsFillCircleFill } from "react-icons/bs";
import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

function InvoiceDetailHeader(props) {
  const {
    classes,
    invoice,
    setEditReservationModal,
    setUpdateInvoiceStatusModal,
    history,
    invoiceId
  } = props;

  return (
    <GridContainer>
      <GridItem className={classes.gridItemInvoiceDetailHeader} xs={9}>
        <h1
          style={{ marginBottom: "30px" }}
          className={classes.title}
        >{`Invoice ${invoice.invoiceId}`}</h1>
      </GridItem>
      <GridItem className={classes.spaceBetween} xs={3}>
        <Button
          style={{
            color: "#323232",
            background: "#FFC821",
            marginBottom: "30px",
            width: "100%",
          }}
          className={classes.payNowButton}
          onClick={() => setUpdateInvoiceStatusModal(true)}
        >
          Update Invoice Status
        </Button>
        {invoice.invoiceStatus === "Draft" && (
          <Button
            style={{
              color: "white",
              background: "#323232",
              marginBottom: "30px",
              width: "100%",
            }}
            className={classes.payNowButton}
            onClick={() => history.push(`/admin/edit-invoice/${invoiceId}`)}
          >
            Edit Invoice
          </Button>
        )}
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(InvoiceDetailHeader));
