const initState = {
  pickups: [],
};

export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_PICKUPS":
      return {
        ...state,
        pickups: [...action.pickups],
      };
    default:
      return state;
  }
}
