import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";

const headers = ["#", "Name", "QTY", "Description", "Rate", "Tax", "Actions"];
function ServicesCard(props) {
  const {
    classes,
    invoice,
    history,
    servicesSubTotal,
    taxTotal,
    servicesTotal,
    customer,
    reservation,
    inventoryNeeded,
    setInventoryNeeded,
    inventoryData,
    quantityNeeded,
    setQuantityNeeded,
    setServices,
    services,
    handleChangeDiscount,
    discount,
    setViewAndEditTermsModal,
    invoiceDate,
    poNumber,
    recurring,
    setDefaultCosts,
    setTaxTotal,
    errors,
    setServicesTotal,
    jobType,
    setJobType,
  } = props;

  const jobTypes = ["Reservation", "Pick Up", "Drop Off", "Service", "Swap"];

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };
  console.log("serivcesss", services);
  const typeKey = {
    reservation: "Reservation",
    swap: "Swap",
    pickUp: "Pick Up",
    dropOff: "Drop Off",
  };
  const renderTableData = () => {
    return services.map((d, index) => {
      return (
        <tr className={classes.tableRow} key={index}>
          <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
            {index + 1}
          </td>
          <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
            {d.type === "reservation" ||
            d.type === "swap" ||
            d.type === "pickUp" ||
            d.type === "dropOff" ? (
              <div style={{ width: "100%" }}>
                <span
                  // onClick={() =>
                  //   history.push(
                  //     `/admin/rentals/${d.serviceInfo.reservationId}`
                  //   )
                  // }
                  style={{
                    color: "black",
                    textDecoration: "none",
                    marginRight: "20px",
                  }}
                  className={classes.reservationIdJobDetail}
                >
                  {/* {d.serviceInfo.reservationId} */}
                </span>
                <span>
                  {d.serviceInfo.equiptment} {typeKey[d.type]}
                </span>
              </div>
            ) : (
              <span>API NOT SET UP FOR THIS</span>
            )}
          </td>
          <td style={{ maxWidth: "90px" }} className={classes.tableCell}>
            <TextField
              style={{ width: "100%" }}
              value={services[index].quantity}
              type="number"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => {
                const parsedValue = parseFloat(e.target.value);
                const servicesCopy = [...services];
                servicesCopy[index].quantity = parsedValue;
                setServices([...servicesCopy]);
                if (parsedValue) {
                  console.log("parsedValue", parsedValue);
                  setQuantityNeeded(parsedValue);
                }
              }}
              variant="outlined"
            />
          </td>
          <td className={classes.tableCell}>
            <TextField
              style={{ width: "100%" }}
              value={services[index].description}
              onChange={(e) => {
                const servicesCopy = [...services];
                servicesCopy[index].description = e.target.value;
                setServices([...servicesCopy]);
              }}
              variant="outlined"
            />
          </td>
          <td className={classes.tableCell}>${d.rate}</td>
          <td className={classes.tableCell}>
            {" "}
            <Checkbox
              style={{ color: "#323232" }}
              onChange={() => {
                const servicesCopy = [...services];
                servicesCopy[index].tax = !servicesCopy[index].tax;
                setServices([...servicesCopy]);
              }}
              checked={d.tax}
            />
          </td>
          <td className={classes.tableCell}>
            <Button
              onClick={() => {
                const servicesCopy = [...services];
                servicesCopy.splice(index, 1);
                setServices(servicesCopy);
              }}
              className={classes.actionBtn}
              style={{ background: "#323232" }}
            >
              <DenyIcon />
            </Button>
          </td>
        </tr>
      );
    });
  };
  return (
    <Card className={classes.invoiceCard}>
      <GridContainer>
        <GridItem xs={12}>
          <h1 style={{ marginBottom: "30px" }} className={classes.title}>
            Services
            {errors.services && (
              <span style={{ color: "red", marginLeft: "10px" }}>
                Required*
              </span>
            )}
          </h1>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Job Type</span>
          <Select
            className={classes.filterMenu}
            variant="outlined"
            value={jobType}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setJobType(e.target.value)}
          >
            {jobTypes.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Product Type</span>
          <Select
            className={classes.filterMenu}
            variant="outlined"
            value={inventoryNeeded}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setInventoryNeeded(e.target.value)}
          >
            {inventoryData.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o.inventoryItem}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>

        {inventoryNeeded && (
          <GridItem className={classes.columnGridItemJustifyCenter} xs={2}>
            <Button
              onClick={() => {
                const newService = {
                  agreedToTermsOfService: false,
                  description: "",
                  quantity: quantityNeeded,
                  name: inventoryNeeded.inventoryItem,
                  rate: inventoryNeeded.cost,
                  tax: false,
                  type:
                    jobType === "Reservation"
                      ? "reservation"
                      : jobType === "Pick Up"
                      ? "pickUp"
                      : jobType === "Drop Off"
                      ? "dropOff"
                      : jobType === "Service"
                      ? "service"
                      : "swap",
                  serviceInfo: {
                    amount: inventoryNeeded.cost,
                    amountPaid: 0,
                    driver: "N/A",
                    dropOffCity: "",
                    dropOffState: "",
                    dropOffZipCode: "",
                    endDate: "",
                    equiptment: inventoryNeeded.inventoryItem,
                    equiptmentLocation: "",
                    existingCustomer: true,
                    heardAboutUsFrom: "",
                    inventoryId: inventoryNeeded.inventoryId,
                    jobs: {
                      delivery: {
                        driverId: "",
                        driverName: "N/A",
                      },
                      pickup: {
                        driverId: "",
                        driverName: "N/A",
                      },
                    },
                    lastEmailedDate: "",
                    notes: "",
                    otherInformation: "",
                    paymentDueDate: invoiceDate,
                    poNumber: poNumber,
                    promoCode: "",
                    quantity: "",
                    recurring: recurring,
                    reservationId: "",
                    sevenDayReservation: null,
                    siteAddress: "",
                    spokenWithTeamMember: null,
                    startDate: "",
                    status: "Unapproved",
                    territory: "N/A",
                  },
                };

                setServices([...services, newService]);
              }}
              style={{ color: "#323232" }}
              className={classes.viewEditButton}
            >
              Add
            </Button>
          </GridItem>
        )}
        <GridItem className={classes.tableGridItem} xs={12}>
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </GridItem>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem className={classes.columnGridItem} xs={7}>
              <h1 style={{ marginBottom: "30px" }} className={classes.title}>
                Terms And Conditions
              </h1>
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => setViewAndEditTermsModal("View")}
                  className={classes.viewEditButton}
                  style={{
                    background: "#FFC821",
                    color: "#323232",
                    marginRight: "10px",
                  }}
                >
                  {" "}
                  <Eyecon /> View
                </Button>
                <Button
                  onClick={() => setViewAndEditTermsModal("Edit")}
                  style={{ background: "#323232", color: "white" }}
                  className={classes.viewEditButton}
                >
                  {" "}
                  <WhiteEditIcon /> Edit
                </Button>
              </div>
            </GridItem>
            <GridItem xs={5}>
              <GridContainer>
                <GridItem
                  className={
                    classes.textCenter + " " + classes.marginBottomGridItem
                  }
                  xs={6}
                >
                  <span className={classes.totalsTitle}>Subtotal</span>
                </GridItem>
                <GridItem xs={6}>
                  <span className={classes.totalsValue}>
                    ${servicesSubTotal}
                  </span>
                </GridItem>

                <GridItem
                  className={
                    classes.textCenter + " " + classes.marginBottomGridItem
                  }
                  xs={6}
                >
                  <span className={classes.totalsTitle}>Discount</span>
                  <TextField
                    style={{ width: "25%", marginLeft: "10px", height: "20px" }}
                    value={discount}
                    type="number"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => handleChangeDiscount(e.target.value)}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        root: classes.modifiedDiscountInput,
                        input: classes.modifiedDiscountInput,
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "20px",
                      },
                    }}
                  />
                </GridItem>
                <GridItem className={classes.marginBottomGridItem} xs={6}>
                  <span className={classes.totalsValue}>
                    ${discount ? discount : 0}
                  </span>
                </GridItem>

                <GridItem
                  className={
                    classes.textCenter + " " + classes.marginBottomGridItem
                  }
                  xs={6}
                >
                  <span className={classes.totalsTitle}>Tax</span>
                </GridItem>
                <GridItem className={classes.marginBottomGridItem} xs={6}>
                  <span className={classes.totalsValue}>
                    ${taxTotal} (4.4%)
                  </span>
                </GridItem>

                <GridItem
                  className={
                    classes.textCenter + " " + classes.marginBottomGridItem
                  }
                  xs={6}
                >
                  <span className={classes.invoiceTotal}>Invoice Total</span>
                </GridItem>
                <GridItem className={classes.marginBottomGridItem} xs={6}>
                  <span className={classes.totalsValue}>${servicesTotal}</span>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(ServicesCard));
