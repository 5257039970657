import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { CSVLink } from "react-csv";

const selectOptions = ["Territory", "Address", "Company"];
const directionOptions = ["Asc", "Desc"];

function Filters(props) {
    const {
        classes,
        sortBySelection,
        setSortBySelection,
        setDirectionSelection,
        directionSelection,
        setFilterSelection,
        filterSelection,
        setSearchString,
        searchString,
        handleFilters,
        handleSearch,
        handleCommentFilter,
        reservationsData,
    } = props;

    return (
        <div>
            <GridContainer>
                <GridItem className={classes.noPaddingGridItem} xs={1}>
                    <h5 className={classes.subHeader} style={{ whiteSpace: "noWrap" }}>Sort By</h5>
                </GridItem >
                <GridItem className={classes.filtersGridItem} xs={7}>
                    <Select
                        className={classes.selectMenuSort}
                        style={{ width: "180px" }}
                        value={filterSelection}
                        disableUnderline
                        classes={{
                            // root: classes.selectText,
                        }}
                        onChange={(e) => handleFilters("filter", e.target.value)}
                    >
                        {selectOptions.map((o, i) => {
                            return (
                                <MenuItem

                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <Select
                        style={{ width: "120px" }}
                        className={classes.ascMenu}
                        disableUnderline
                        classes={{
                            // root: classes.selectText,
                        }}
                        value={directionSelection}
                        onChange={(e) => handleFilters("direction", e.target.value)}
                    >
                        {directionOptions.map((o, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={o}
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {o}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </GridItem>
                <GridItem className={classes.filtersGridItem} xs={4} style={{justifyContent:"flex-end", display:"flex"}}>
                    <TextField
                        className={classes.searchBar}
                        placeholder="Search Sites"
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        variant="outlined"
                        InputProps={{
                            classes: {
                                adornedEnd: classes.adournedEnd,
                            },
                            endAdornment: (
                                <InputAdornment className={classes.adournedEnd} position="end">
                                    <Button className={classes.searchButton}>Search</Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        reservationsData: state.reservations.reservations,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(rentalsStyles)
)(React.memo(Filters));
