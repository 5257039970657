import {
  whiteColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const profileStyles = (theme) => ({
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px",
    textAlign: "left",
  },
  totalTitle: {
    fontWeight: 700,
    fontSize: "25px",
    fontFamily: "Archivo",
    color: "black",
    margin: "0px",
  },
  permissionTitle: {
    fontWeight: 600,
    fontSize: "23px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px",
    textAlign: "left",
  },
  publicInvoiceGridContainer: {
    width: "95%",
    margin: "0px auto",
    zIndex: 9999,
  },
  buttonGridItem: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "30px",
  },
  addCustomer: {
    fontFamily: "Archivo",
    textTransform: "capitalize",
    fontWeight: 600,
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#323232",
    padding: "12px",
    display: "flex",
    justifyContent: "space-evenly",
    "&:hover": {
      background: "#FFC720",
    },
  },
  editIcon: {
    marginRight: "10px",
  },
  card: {
    padding: "25px 10px 5px 10px",
    margin: "0px",
  },
  sectionTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    marginBottom: "30px !important",
  },
  profileValues: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
  },
  values: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
  },
  addUserTextFields: {
    marginBottom: "20px",
  },
  btn: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
  },
  logo: {
    width: "50%",
  },
  searchBar: {
    background: "white",
    marginBottom: "15px",
  },
  basicInfoCategory: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
  },
  adournedEnd: {
    paddingRight: "10px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  changePassword: {
    fontFamily: "Archivo",
    fontWeight: 700,
    textTransform: "capitalize",
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "100%",
    marginTop: "30px",
    "&:hover": {
      opacity: "0.7",
    },
  },
  cardGridItem: {
    padding: "0px 35px !important",
  },
  basicInfoDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingBottom: "17px",
  },
  eyecon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  uploadPhoto: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#323232",
    marginBottom: "30px",
  },
  uploadPhotoSize: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#323232",
    marginBottom: "30px",
  },
  invoiceNameCardText: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "22px",
  },
  columnGridItem: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  columnGridItemJustifyCenter: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    justifyContent: "center"
  },
  rowGridItem: {
    display: "flex",
    marginBottom: "20px",
  },
  paddingBottom: {
    paddingBottom: "20px",
  },
  columnGridItemExtraPadding: {
    padding: "30px 30px 0px 30px !important",
    display: "flex",
    flexDirection: "column",
  },
  formTitles: {
    fontWeight: 300,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    marginBottom: "10px",
  },
  inputTypeTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    marginBottom: "10px",
  },
  emailNote: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "17px",
    lineHeight: "20px",
    color: "#323232",
    marginBottom: "10px",
  },
  marginBottom: {
    marginBottom: "30px",
  },
  inputTypeBillingCard: {
    // fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#323232",
    marginBottom: "10px",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  customerInput: {
    width: "70%",
    height: "50px",
  },
  subTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
  },
  spaceBetweenWidth100: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  cancelSaveButton: {
    textTransform: "capitalize",
    marginTop: "15px",
    background: "#323232",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "10%",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
  },
  submitButton: {
    background: "#323232",
    color: "#FFC821",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "100%",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
  },
  modalCard: {
    margin: "0px",
    background: "rgb(243, 250, 255)",
  },
  modalTitle: {
    textAlign: "center",
    background: "#F1C84E !important",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "30px",
    margin: "0px",
    padding: "30px",
  },
  passwordField: {
    width: "100%",
  },
  tabSelected: {
    transition: "0.2s background-color 0.1s",
    background: "#FEDA1B",
    color: "#323232 !important",
  },
  tabWrapper: {
    textTransform: "capitalize",
    display: "inline-block",
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    "& > svg,& > .fab,& > .fas,& > .far,& > .fal,& > .material-icons": {
      verticalAlign: "middle",
      margin: "-1px 5px 0 0 !important",
    },
  },
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px",
  },
  cardTitleRTL: {
    float: "right",
    padding: "10px 0px 10px 10px !important",
  },
  displayNone: {
    display: "none !important",
  },
  tabsRootsss: {
    minHeight: "unset !important",
    background: "#D9D9D9 !important",
    "& $tabRootButton": {
      fontSize: "0.875rem",
    },
  },
  tabRootButton: {
    minHeight: "unset !important",
    minWidth: "unset !important",
    width: "unset !important",
    height: "unset !important",
    maxWidth: "unset !important",
    maxHeight: "unset !important",
    padding: "10px 15px",
    lineHeight: "24px",
    border: "0 !important",
    color: "#323232 !important",
    marginLeft: "4px",
    background: "#FEDA1B",
    borderWidth: "0.5px 0.5px 0px 0.5px",
    borderStyle: "solid",
    borderColor: "#929292",
    borderRadius: "2px 2px 0px 0px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "17px",
    "&:last-child": {
      marginLeft: "0px",
    },
  },
  tabLabelContainer: {
    padding: "0px",
  },
  tabLabel: {
    fontWeight: "500",
    fontSize: "12px",
  },
  billingInfoGridItem: {
    marginBottom: "20px",
  },
  billingInfoKey: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
  },
  billingInfoTitle: {
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Archivo",
    color: "#323232",
    lineHeight: "26px",
    margin: "0px 0px 20px 0px",
    textAlign: "left",
  },
  billingInfoValue: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    whiteSpace: "noWrap"
  },
  bilingInfoGridContainer: {
    padding: "20px",
  },
  justifyEnd: {
    display: "flex",
    justifyContent: "end",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  invoiceCard: {
    background: "#FFFFFF",
    border: "0.5px solid #8E8E8E",
    padding: "25px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "0px",
    marginBottom: "30px",
  },
  radioChecked: {
    width: "30px",
    height: "30px",
    border: "1px solid #FFC821",
    color: "#FFC821",
    borderRadius: "50%",
  },
  radioUnchecked: {
    width: "30px",
    height: "30px",
    border: "1px solid #FFC821",
    color: "white",
    borderRadius: "50%",
  },
  checked: {
    color: "#4B8DF8",
  },
  sameAsOwnerAddress: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    marginLeft: "15px",
  },
  filterMenu: {
    width: "100%",
    height: "50px",
    marginRight: "15px",
    padding: "10px",
    border: "1px solid #D9D9D9",
  },
  marginTop: {
    marginTop: "30px",
  },
  qtyTextField: {
    width: "20%",
  },
  alignItems: {
    display: "flex",
    alignItems: "center",
  },
  datetime: {
    background: "white",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    width: "100%",
    marginBottom: "20px",
    position: "relative",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "text",
    display: "inline-flex",
    fontSize: "1rem",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    border: "1px solid lightgray",
    padding: "15px",
  },
  sendBtn: {
    background: "#323232",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    height: "70px",
    color: "#FFC720",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "33px",
    padding: "20px",
    "&:hover": {
      cursor: "pointer",
      background: "#323232",
      color: "#FFC720",
    },
  },
  attachmentInfo: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "15px",
    color: "#000000",
  }

});

export default profileStyles;
