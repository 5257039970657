import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";

function ViewTerms(props) {
  const {
    classes,
    history,
    userInfo,
    invoice,
    setViewAndEditTermsModal,
    termsAndConditions,
  } = props;

  return (
    <Card className={classes.modalCard}>
      <div
        style={{
          background: "#F1C84E",
          display: "flex",
          width: "100%",
          justifyContent: "end",
          padding: "10px 10px 0px 0px",
        }}
      >
        <BlackDenyIcon
          onClick={() => setViewAndEditTermsModal("")}
          className={classes.closeModal}
        />
      </div>
      <h1
        style={{ padding: "10px 30px 30px 30px" }}
        className={classes.modalTitle}
      >
        View Terms And Conditions{" "}
      </h1>
      <CardBody
        style={{ background: "white" }}
        className={classes.body}
      ></CardBody>
      <div
        id="descrip"
        style={{ background: "white", padding: "20px" }}
        dangerouslySetInnerHTML={{ __html: termsAndConditions }}
      />
      <div style={{ display: "flex", width: "100%", background: "white", justifyContent: "center", marginBottom: "30px" }}>
        <Button
          style={{ color: "#FFC821", background: "#323232", width: "90%" }}
          onClick={() => setViewAndEditTermsModal('')}
          className={classes.sendBtn}
        >
          Close
        </Button>
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(ViewTerms));
