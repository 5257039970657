export const reportSelections = [
  { name: "Jobs", link: "/admin/reports/jobs" },
  // { name: "Rentals", link: "/admin/reports/rentals" },
  { name: "Volume By Territory", link: "/admin/reports/volumn-by-territory" },
  { name: "Email Activity", link: "/admin/reports/emails" },
  { name: "Text Activity", link: "/admin/reports/texts" },
  {
    name: "Dumpster Cost Analysis",
    link: "/admin/reports/dumpster-cost-analysis",
  },
  { name: "Aging", link: "/admin/reports/aging" },
  { name: "Sales By Customer", link: "/admin/reports/sales-by-customer" },
  {
    name: "Sales By Invoice Line Item",
    link: "/admin/reports/sales-by-invoice-line-item",
  },
  { name: "Gross Sales", link: "/admin/reports/gross-sales" },
  { name: "Sales By Quarter", link: "/admin/reports/sales-by-quarter" },
  { name: "Deposit Summary", link: "/admin/reports/deposit-summary" },
  { name: "Deposit Detail", link: "/admin/reports/deposit-detail" },
  {
    name: "Customer Referral Sources",
    link: "/admin/reports/customer-referral-sources",
  },
];

export const reportSelectionsCustomer = [
  { name: "Rentals", link: "/admin/reports/rentals" },
  { name: "Sales By Customer", link: "/admin/reports/sales-by-customer" },
];
