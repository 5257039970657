import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";
import { MenuItem, Select } from "@material-ui/core";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";

import Filters from "./Filters";
import MapViewTable from "./MapViewTable";
import GoogleMap from "./GoogleMap";
import {
  filterData,
  filterDataWithComments,
} from "../../../../../globalHelperFunctions/globalHelperFunctions";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

const pageArr = ["4", "All"];

function MapView(props) {
  const { classes, history, reservationsData, user } = props;
  const [sortBySelection, setSortBySelection] = useState("Inventory Size");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterSelection, setFilterSelection] = useState("Rental Number");
  const [searchString, setSearchString] = useState("");
  const [reservations, setReservations] = useState([]);
  const [inProgressCount, setInProgressCount] = useState("");
  const itemsPerPage = 4;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("4")


  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)
  };

  useEffect(() => {
    reservations.slice(index, endIndex)
  }, [index])

  useEffect(() => {
    setReservations(reservationsData);
  }, [reservationsData]);

  useEffect(() => {
    setReservations(reservationsData);
    const inProgress = reservationsData.filter((d) => {
      if (d.reservation.status === "In Progress") {
        return true;
      }
    });
    setInProgressCount(inProgress.length);
  }, [reservationsData]);

  const handleFilters = (type, filterValue) => {
    if (type === "filter") {
      const filtered = filterDataWithComments(
        filterValue,
        directionSelection,
        sortBySelection,
        reservationsData
      );
      setFilterSelection(filterValue);
      // setCustomers([...filtered]);
    }
    if (type === "direction") {
      const filtered = filterDataWithComments(
        filterSelection,
        filterValue,
        sortBySelection,
        reservationsData
      );
      setDirectionSelection(filterValue);
      // setCustomers([...filtered]);
    }
    if (type === "comments") {
      const filtered = filterDataWithComments(
        filterSelection,
        directionSelection,
        filterValue,
        reservationsData
      );
      setSortBySelection(filterValue);
      // setCustomers([...filtered]);
    }
  };

  return (
      <GridContainer>
         <GridItem xs={2}>
          <Filters
            sortBySelection={sortBySelection}
            setSortBySelection={setSortBySelection}
            directionSelection={directionSelection}
            setDirectionSelection={setDirectionSelection}
            setFilterSelection={setFilterSelection}
            filterSelection={filterSelection}
            searchString={searchString}
            setSearchString={setSearchString}
            handleFilters={handleFilters}
          />
        </GridItem>
        <GridItem className={classes.googleMapGridItem} xs={10}>
          <GoogleMap reservations={reservations} />
        </GridItem>
      </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(MapView));
