import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";

const headers = [
  "Job Details",
  "Scheduled",
  "End Date",
  "Site Address",
  "Customer",
  "Status",
  "Actions",
];

function JobsTable(props) {
  const { classes, filteredTableData, pageDropdown, itemsPerPage, pageNumber } = props;
  console.log("filteredTableData", filteredTableData, pageDropdown);
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if(pageDropdown === "3"){
      return filteredTableData
      .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
      .map((d, index) => {
        return (
          <tr className={classes.tableRow} key={d.reservation.reservationId}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "16px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <span>Job #{d.reservation.reservationId} </span>
                </div>
                <span>Driver: {d.reservation.driver}</span>
              </div>
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.reservation.startDate}
            </td>
            <td className={classes.tableCell}>{d.reservation.endDate}</td>
            <td className={classes.tableCell}>{d.reservation.siteAddress}</td>
            <td className={classes.tableCell}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{d.name}</span>
                <span>{d.email}</span>
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                className={classes.statusDiv}
                key={index}
                style={
                  d.reservation.status === "Cancelled"
                    ? {
                        background: "#F83030",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        color: "white",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : d.reservation.status === "Finished"
                    ? {
                        background: "#2EB67D",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        color: "white",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : d.reservation.status === "Unassigned"
                    ? {
                        background: "#323232",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        color: "white",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : d.reservation.status === "Scheduled"
                    ? {
                        background: "#E01E5A",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        color: "white",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : {
                        background: "#E0AC12",
                        color: "#323232",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                }
              >
                {d.reservation.status}
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#ECB22E" }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
    else{
      return filteredTableData.map((d, index) => {
        return (
          <tr className={classes.tableRow} key={d.reservation.reservationId}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "16px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <span>Job #{d.reservation.reservationId} </span>
                </div>
                <span>Driver: {d.reservation.driver}</span>
              </div>
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.reservation.startDate}
            </td>
            <td className={classes.tableCell}>{d.reservation.endDate}</td>
            <td className={classes.tableCell}>{d.reservation.siteAddress}</td>
            <td className={classes.tableCell}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{d.name}</span>
                <span>{d.email}</span>
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                className={classes.statusDiv}
                key={index}
                style={
                  d.reservation.status === "Cancelled"
                    ? {
                        background: "#F83030",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        color: "white",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : d.reservation.status === "Finished"
                    ? {
                        background: "#2EB67D",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        color: "white",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : d.reservation.status === "Unassigned"
                    ? {
                        background: "#323232",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        color: "white",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : d.reservation.status === "Scheduled"
                    ? {
                        background: "#E01E5A",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        color: "white",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : {
                        background: "#E0AC12",
                        color: "#323232",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                }
              >
                {d.reservation.status}
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#ECB22E" }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
      <div style={pageDropdown != 3 && filteredTableData.length > 3 ? { height: "420px", width: "100%", overflowY: "scroll" } : null}>
        <table className={classes.inventoryTable}>
          <tbody>
            {renderTableHeader()}
            {renderTableData()}
          </tbody>
        </table>
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(JobsTable));
