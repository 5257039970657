import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { addPendingReservationExistingCustomer } from "store/actions/reservations/reservationsActions";
import { getOneWeekLater } from "../../Dashboard/helpers/helpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import pointInPolygon from "point-in-polygon";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Submitted from "./Submitted";
import moment from "moment";



function StepOne(props) {
  const {
    classes,
    customerInfo,
    inventoryData,
    handleAddPendingReservationExistingCustomer,
    territoriesData,
    setSpokenWithTeamMember,
    spokenWithTeamMember,
    communicationPreference,
    setCommunicationPreference,
    nextStep,
    phoneNumber,
    handlePhoneNumberChange,
    errors,
    reservationType,
    setReservationType,
    reserveTypeErr
  } = props;

  return (
    <Card className={classes.card}>
       <h5 className={classes.formTitle}>
                Type of Reservation:{" "}
                {reserveTypeErr === true ? (
                    <span style={{ color: "red" }}>Required*</span>
                ) : (
                    <div></div>
                )}
            </h5>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "30px",
                    }}
                >
                    <Checkbox
                        checked={reservationType.new}
                        style={{
                            color: "#FFC720",
                        }}
                        onChange={() => {
                            setReservationType({ new: true, update: false })
                        }}
                    />
                    <span>New Reservation</span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                        style={{
                            color: "#FFC720",
                        }}
                        checked={reservationType.update}
                        onChange={() => setReservationType({ new: false, update: true })}

                    />
                    <span>Update to Existing</span>
                </div>
            </div>
      <h5 className={classes.formTitle}>
        Are you ready to finalize?{" "}
        {errors.spokenWithTeamMember ? (
          <span style={{ color: "red" }}>Required*</span>
        ) : (
          <div></div>
        )}
      </h5>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "30px",
          }}
        >
          <Checkbox
            checked={spokenWithTeamMember.yes}
            style={{
              color: "#FFC720",
            }}
            onChange={() => {
              setSpokenWithTeamMember({ yes: true, no: false });
            }}
          />
          <span>Yes, I understand the rental process and I am ready to finalize!</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            style={{
              color: "#FFC720",
            }}
            checked={spokenWithTeamMember.no}
            onChange={() => setSpokenWithTeamMember({ yes: false, no: true })}
          />
          <span>No, I have questions, please call me to finalize my reservation</span>
        </div>
      </div>
      <h5 className={classes.formTitle}>
        Contact Information:{" "}
        {errors.phoneNumber && <span style={{ color: "red" }}>Required*</span>}
      </h5>
      <TextField
        className={classes.emailInput}
        value={customerInfo.email}
        disabled
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={customerInfo.name.split(" ")[0]}
        disabled
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={customerInfo.name.split(" ")[1]}
        disabled
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        placeholder="Phone Number"
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />
        {customerInfo && (!phoneNumber ||
                    (!reservationType.new && !reservationType.update) ||
                    (!spokenWithTeamMember.yes && !spokenWithTeamMember.no))?
                <Button
                    disabled
                    style={{ background: "#FFC821", color: "#323232" }}
                    onClick={() => nextStep()}
                >
                    Go Next
                </Button>
                : <Button
                    style={{ background: "#FFC821", color: "#323232" }}
                    onClick={() => nextStep()}
                >
                    Go Next
                </Button>}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  handleAddPendingReservationExistingCustomer: (pendingRes) =>
    dispatch(addPendingReservationExistingCustomer(pendingRes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(StepOne));
