import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Card from "components/Card/Card";
import { filterDataWithStatus } from "../../../../../globalHelperFunctions/globalHelperFunctions";
import Button from "components/CustomButtons/Button.js";
import PriceBooksTable from "./components/PriceBooksTable";
import Filters from "./components/Filters";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";


function PriceBooksTab(props) {
  const { classes, customerInfo, history, priceBooksData } = props;

  const [searchString, setSearchString] = useState("");
  const [reservations, setReservations] = useState(customerInfo.reservations);
  const [sortBySelection, setSortBySelection] = useState("All");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterSelection, setFilterSelection] = useState("First Name");

  let justCustomerInfo = { ...customerInfo };
  delete justCustomerInfo.reservations;

  const handleFilters = (type, filterValue) => {
    if (type === "filter") {
      const filtered = filterDataWithStatus(
        filterValue,
        directionSelection,
        sortBySelection,
        customerInfo.reservations
      );
      setFilterSelection(filterValue);
      setReservations([...filtered]);
    }
    if (type === "direction") {
      const filtered = filterDataWithStatus(
        filterSelection,
        filterValue,
        sortBySelection,
        customerInfo.reservations
      );
      setDirectionSelection(filterValue);
      setReservations([...filtered]);
    }
    if (type === "status") {
      const filtered = filterDataWithStatus(
        filterSelection,
        directionSelection,
        filterValue,
        customerInfo.reservations
      );
      setSortBySelection(filterValue);
      setReservations([...filtered]);
    }
  };

  const handleSearch = () => {
    if (searchString) {
      const filtered = reservations.filter((r) => {
        if (
          r.name.toLowerCase().includes(searchString.toLowerCase()) ||
          r.billingAddress.toLowerCase().includes(searchString.toLowerCase()) ||
          r.company.toLowerCase().includes(searchString.toLowerCase()) ||
          r.email.toLowerCase().includes(searchString.toLowerCase()) ||
          r.phoneNumber.toLowerCase().includes(searchString.toLowerCase()) ||
          r.reservation.reservationId
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          return true;
        }
      });
      console.log("filtered", filtered);
      setReservations([...filtered]);
    } else {
      setReservations([...customerInfo.reservations]);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card className={classes.tabCard}>
          <GridContainer>
            <GridItem
              className={classes.column + " " + classes.justifyContent}
              xs={4}
            >
              <span className={classes.notesTabTitle2}>
                {priceBooksData.length} Price Book(s)
              </span>
            </GridItem>
            <GridItem xs={8} style={{display:"flex", justifyContent:"flex-end"}}>
              <Button className={classes.addCustomer} style={{marginRight:"12px", textTransform:"capitalize"}}>
              <AddIcon style={{marginRight:"12px"}}/> Add Price Book
              </Button>
            </GridItem>
            <GridItem xs={12}>
              <Filters
                sortBySelection={sortBySelection}
                setSortBySelection={setSortBySelection}
                directionSelection={directionSelection}
                setDirectionSelection={setDirectionSelection}
                setFilterSelection={setFilterSelection}
                filterSelection={filterSelection}
                searchString={searchString}
                setSearchString={setSearchString}
                handleFilters={() => console.log("filter")}
                handleSearch={handleSearch}
              />
            </GridItem>
            <GridItem className={classes.column} xs={12}>
              <PriceBooksTable
                justCustomerInfo={justCustomerInfo}
                priceBooksData={priceBooksData}
                history={history}
              />
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(PriceBooksTab));
