import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { addPendingReservationNewCustomerTemp } from "store/actions/reservations/reservationsActions";
import { getOneWeekLater } from "../../Dashboard/helpers/helpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import pointInPolygon from "point-in-polygon";
import Alert from "@material-ui/lab/Alert";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Submitted from "./Submitted";
import moment from "moment";
import NewFormStepOne from "./NewFormStepOne.jsx";
import NewFormStepTwo from "./NewFormStepTwo.jsx";
import NewFormStepThree from "./NewFormStepThree.jsx";
import "calendarForm.css"



const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

const locationSelections = [
  "Select Option",
  "Right Side of Driveway",
  "Left Side of Driveway",
  "Middle of Driveway",
  "On Street",
  "Side of House",
  "Backyard",
  "Alley",
  "Other (Please specify below)"
];
const heardAboutUsFromSelections = [
  "Past Customer",
  "Google",
  "Yelp",
  "HomeAdvisor",
  "Referral",
  "Other (Please specify below)",
  "NextDoor",
  "Social Media"
];

function NewForm(props) {
  const {
    classes,
    inventoryData,
    handleAddPendingReservationNewCustomerTemp,
    customers,
    setIndividualError,
    returningCustomerAlert,
    setReturningCustomerAlert,
    territoriesData,
    setSpokenWithTeamMember,
    spokenWithTeamMember,
    customerInfo
  } = props;
  const [reservationType, setReservationType] = useState({ new: false, update: false })
  const [customerType, setCustomerType] = useState({ new: false, existing: false })
  const [reserveTypeErr, setReserveTypeErr] = useState(false)
  const [billingAddress, setBillingAddress] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [siteAddress, setSiteAddress] = useState("");
  const [dropOffAddressLine2, setDropOffAddressLine2] = useState("");
  const [sevenDayRental, setSevenDayRental] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [invenoryWithIds, setInventoryWithIds] = useState({ key: false });
  const [equiptmentNeeded, setEquiptmentNeeded] = useState("");
  const [equipmentLocation, setEquiptmentLocation] = useState("");
  const [heardAboutUsFrom, setHeardAboutUsFrom] = useState("");
  const [notes, setNotes] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [dropOffDate, setDropOffDate] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [cost, setCost] = useState("");
  const [dropOffLat, setDropOffLat] = useState(false);
  const [dropOffLng, setDropOffLng] = useState(false);
  const [optedIn, setOptedIn] = useState(false);
  const [errors, setErrors] = useState({
    phoneNumber: false,
    billingAddress: false,
    siteAddress: false,
    dropOffDate: false,
    equipmentLocation: false,
    equiptmentNeeded: false,
    pickupDate: false,
    firstName: false,
    lastName: false,
    email: false,
    heardAboutUsFrom: false,
  });

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      const lat = results[0].geometry.location.lat();
      const lng = results[0].geometry.location.lng();
      if (placeholder === "Search your billing address..") {
        setBillingAddress(results[0].formatted_address);
      }
      if (placeholder === "Search your drop-off address..") {
        const addressResults = results[0];
        addressResults.address_components.forEach((r) => {
          if (r.types.includes("locality")) {
            setCity(r.long_name);
          }
          if (r.types.includes("administrative_area_level_1")) {
            setState(r.short_name);
          }
          if (r.types.includes("postal_code")) {
            setZipCode(r.short_name);
          }
        });
        setSiteAddress(addressResults.formatted_address);
        setDropOffLat(lat);
        setDropOffLng(lng);
      }
    });
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;
    setPhoneNumber(value);
  };

  const handleSubmit = () => {
    let territory = "N/A";
    const territoryPolygons = [];
    territoriesData.forEach((t) => {
      territoryPolygons.push(Object.values(t.territoryPolygon));
    });
    territoryPolygons.forEach((t, index) => {
      if (pointInPolygon([dropOffLng, dropOffLat], t)) {
        territory = territoriesData[index].territoryName;
      }
    });
    const pendingRes = {
      comments: [],
      company: "",
      email: email,
      billingAddress: billingAddress,
      name: firstName + " " + lastName,
      firstName,
      lastName,
      phoneNumber: phoneNumber,
      billingAddressLine2: addressLine2,
      reservation: {
        amount: cost,
        amountPaid: "0",
        driver: "N/A",
        endDate: sevenDayRental === "Yes" ? moment(dropOffDate, "MM/DD/YY").add(7, "days").format("MM/DD/YY") : pickupDate,
        territory,
        equiptment: equiptmentNeeded.inventoryItem,
        dropOffCity: city,
        dropOffState: state,
        dropOffZipCode: zipCode,
        equiptmentLocation: equipmentLocation,
        existingCustomer: customerType.existing ? true : false,
        heardAboutUsFrom: heardAboutUsFrom,
        inventoryId: equiptmentNeeded.inventoryId,
        lastEmailedDate: "N/A",
        notes: notes,
        otherInformation: "",
        paymentDueDate: dropOffDate,
        poNumber: "",
        promoCode: promoCode,
        quantity: 1,
        recurring: false,
        sevenDayReservation: sevenDayRental === "Yes" ? true : false,
        siteAddress: siteAddress,
        dropOffAddressLine2: dropOffAddressLine2,
        startDate: dropOffDate,
        status: "Unapproved",
        spokenWithTeamMember: spokenWithTeamMember.yes ? true : false,
        optedIn: optedIn
      },
    };
    handleAddPendingReservationNewCustomerTemp(pendingRes).then(async () => {
      await setSubmitted(true);
    })
    // console.log("checking customer type:", pendingRes)

  };

  const [step, setStep] = useState(1)
  const nextStep = () => {
    setStep(s => s + 1)
    window.scrollTo(0, 0)
  }

  const prevStep = () => {
    setStep(s => s - 1)
    window.scrollTo(0, 0)
  }

  if (submitted) {
    window.open("https://humptydumpco.com/reserve-submitted/", "_top")
  }
  switch (step) {
    case 1:
      return (
        <NewFormStepOne
          inventoryData={inventoryData}
          customerInfo={customerInfo}
          territoriesData={territoriesData}
          spokenWithTeamMember={spokenWithTeamMember}
          setSpokenWithTeamMember={setSpokenWithTeamMember}
          nextStep={nextStep}
          prevStep={prevStep}
          phoneNumber={phoneNumber}
          handlePhoneNumberChange={handlePhoneNumberChange}
          errors={errors}
          email={email}
          setEmail={setEmail}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          reservationType={reservationType}
          setReservationType={setReservationType}
          reserveTypeErr={reserveTypeErr}
          setReserveTypeErr={setReserveTypeErr}
          customerType={customerType}
          setCustomerType={setCustomerType}
        />
      )
    case 2:
      return (
        <NewFormStepTwo
          inventoryData={inventoryData}
          // customerInfo={customerInfo}
          territoriesData={territoriesData}
          spokenWithTeamMember={spokenWithTeamMember}
          setSpokenWithTeamMember={setSpokenWithTeamMember}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          errors={errors}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          handleSelect={handleSelect}
          addressLine2={addressLine2}
          setAddressLine2={setAddressLine2}
          sevenDayRental={sevenDayRental}
          setSevenDayRental={setSevenDayRental}
          pickupDate={pickupDate}
          setPickupDate={setPickupDate}
          dropOffAddressLine2={dropOffAddressLine2}
          setDropOffAddressLine2={setDropOffAddressLine2}
          // previousAddress={previousAddress}
          // setPreviousAddress={setPreviousAddress}
          // previousSites={previousSites}
          siteAddress={siteAddress}
          setSiteAddress={setSiteAddress}
          dropOffDate={dropOffDate}
          setDropOffDate={setDropOffDate}
          disablePastDt={disablePastDt}
        />
      )
    case 3:
      return (
        <NewFormStepThree
          inventoryData={inventoryData}
          territoriesData={territoriesData}
          spokenWithTeamMember={spokenWithTeamMember}
          setSpokenWithTeamMember={setSpokenWithTeamMember}
          nextStep={nextStep}
          prevStep={prevStep}
          dropOffDate={dropOffDate}
          sevenDayRental={sevenDayRental}
          setSevenDayRental={setSevenDayRental}
          pickupDate={pickupDate}
          setPickupDate={setPickupDate}
          getOneWeekLater={getOneWeekLater}
          equiptmentNeeded={equiptmentNeeded}
          setEquiptmentNeeded={setEquiptmentNeeded}
          equipmentLocation={equipmentLocation}
          setEquiptmentLocation={setEquiptmentLocation}
          cost={cost}
          setCost={setCost}
          locationSelections={locationSelections}
          // communicationPreference={communicationPreference}
          // setCommunicationPreference={setCommunicationPreference}
          // communcationSelections={communcationSelections}
          heardAboutUsFrom={heardAboutUsFrom}
          setHeardAboutUsFrom={setHeardAboutUsFrom}
          heardAboutUsFromSelections={heardAboutUsFromSelections}
          notes={notes}
          setNotes={setNotes}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          optedIn={optedIn}
          setOptedIn={setOptedIn}
          handleSubmit={handleSubmit}
          hasErrors={hasErrors}
          setHasErrors={setHasErrors}
          submitted={submitted}
          setSubmitted={setSubmitted}
          errors={errors}
          returningCustomerAlert={returningCustomerAlert}
          setReturningCustomerAlert={setReturningCustomerAlert}
        />
      )
    default:
      console.log("default step ")
  }

}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  handleAddPendingReservationNewCustomerTemp: (pendingRes) =>
    dispatch(addPendingReservationNewCustomerTemp(pendingRes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(NewForm));
