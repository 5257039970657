import { OpenMode } from 'variables/constants';
import { errorCodes } from 'variables/errors';
import { pipeline, chain, handleError, bind } from './util';


function editField(config, form, step, field, value) {
    return {
        type: 'FORM_EDIT_FIELD',
        config, 
        form,
        step,
        field,
        value
    }
}

function validateField(config, form, step, field, value, error) {
    return {
        type: 'FORM_VALIDATE_FIELD',
        config, 
        form,
        step,
        field,
        value,
        error
    }
}

function validateStep(config, form, step) {
    return {
        type: 'FORM_VALIDATE_STEP',
        config, 
        form,
        step
    }
}

function editTableField(config, form, step, field, index, column, value) {
    return {
        type: 'FORM_EDIT_TABLE_FIELD',
        config, 
        form,
        step,
        field,
        index,
        column,
        value
    }
}

function validateTableField(config, form, step, field, index, column, value, error) {
    return {
        type: 'FORM_EDIT_TABLE_FIELD',
        config, 
        form,
        step,
        field,
        index,
        column,
        value,
        error
    }
}

function addTableRow(config, form, step, field) {
    return {
        type: 'FORM_ADD_TABLE_ROW',
        config, 
        form,
        step,
        field
    }
}

function removeTableRow(config, form, step, field, index) {
    return {
        type: 'FORM_REMOVE_TABLE_ROW',
        config, 
        form,
        step,
        field,
        index
    }
}

function initialize(config, form, step, openMode, values) {
    return {
        type: 'FORM_INITIALIZE_STEP',
        config, 
        form,
        step,
        openMode,
        values
    }
}

function startStep(config, form, step, openMode, values) {
    return {
        type: 'FORM_START_STEP',
        config, 
        form,
        step,
        openMode,
        values
    }
}

function initializeForm(config, form, values) {
    return {
        type: 'FORM_INITIALIZE',
        config, 
        form,
        values
    }
}

function setError(config, form, step, error) {
    return {
        type: 'FORM_SET_ERROR',
        config, 
        form,
        step,
        error
    }
}

function submit(config, form) {
    return {
        type: 'FORM_SUBMIT',
        config, 
        form
    }
}

function cancel(config, form) {
    return {
        type: 'FORM_CANCEL',
        config, 
        form
    }
}

export default {
    editField,
    editTableField,
    addTableRow,
    removeTableRow,
    startStep,
    initialize,
    initializeForm,
    setError,
    submit,
    cancel,
    validateStep
}

function getForm(state, form) {
    return state.form[form] || { steps : {} };
}

function getStep(state, form, step) {
    return getForm(state,form).steps[step] || { fields: {} };
}

function defaultStepValidation(config, form, step) {
    const stepConfig = config[step] || config;
    if (stepConfig && stepConfig.fields) {
        return (_dispatch, getState) => {
            const stepState = getStep(getState(), form, step);
            const errors = { ...stepState.fieldErrors };
            for (const [key,props] of Object.entries(stepConfig.fields)) {
                const value = stepState.fields[key];
                if (props.mandatory) {
                    if (value === undefined || value === '') {
                        errors[key] = { code: errorCodes.FORM_FIELD_IS_MANDATORY, message: "Field must be filled in" }
                    } else {
                        if (errors[key] && errors[key].code === errorCodes.FORM_FIELD_IS_MANDATORY) errors[key] = undefined;
                    }
                }
            }
            return Object.values(errors).find(error=>!!error) ? Promise.reject({ code: errorCodes.FORM_STEP_VALIDATION_ERROR,  message: "Uncorrected field errors", errors}) : Promise.resolve();
        }
    } else {
        return () => Promise.resolve();
    }
}

export function actionsForStep(config, form, step) {
    return {
        _validateStep: () => chain(validateStep(config, form, step), defaultStepValidation(config, form, step)),
        _initialize: (openMode, values) => initialize(config, form, step, openMode, values),
        _startStep: (openMode, values) => startStep(config, form, step, openMode, values),
        editField: (field, value) => editField(config, form, step, field, value),
        editTableField: (field, index, column, value) => editTableField(config, form, step, field, index, column, value),
        validateField: (field, value, error) => validateField(config, form, step, field, value, error),
        validateStep: function() { return handleError(pipeline(this.getStep(), this._validateStep), this.setError) },
        validateTableField: (field, row, column, value, error) => validateTableField(config, form, step, field, row, column, value, error),
        addTableRow: (field) => addTableRow(config, form, step, field),
        removeTableRow: (field, index) => removeTableRow(config, form, step, field, index),
        initialize: function(openMode, values) { return handleError(this._initialize(openMode, values), this.setError) },
        startStep: function(openMode, values) { return handleError(this._startStep(openMode, values), this.setError) },
        setError: (error) => setError(config, form, step, error),
        getStep : () => (_,getState) => Promise.resolve(getStep(getState(), form, step))
    }
}

export function actionsForForm(config, form) {
    return {
        ...actionsForStep(config, form, 'DEFAULT'),
        _submit: function() { return chain(submit(config, form), this._validateStep()); },
        _cancel: () => cancel(config, form), 
        _initializeForm: (values) => initializeForm(config, form, values),
        initializeForm: function(values) { return handleError(this._initializeForm(values), this.setError) },
        submit: function() { return handleError(this._submit(), this.setError)},
        cancel: function() { return handleError(this._cancel(), this.setError)},
        getForm : () => (_,getState) => Promise.resolve(getForm(getState(), form))
    };
}

function actionForFieldValidation(fieldValidation, field, value, error = {}) {
    return (_dispatch, getState) => Promise.resolve({ ...error, ...fieldValidation(field, value, getState)});
}

function actionForTableFieldValidation(fieldValidation, field, index, column, value, error) {
    return (_dispatch, getState) => Promise.resolve({ ...error, ...fieldValidation(field, index, column, value, getState)});
}

export function addValidations(actions, validations) {
    return {
        ...actions,
        validateField: validations.validateField ? (field, value, error)=>pipeline(actionForFieldValidation(validations.validateField, field, value, error), error=>actions.validateField(field, value, error)) : actions.validateField,
        validateTableField: validations.validateTableField ? (field, index, column, value, error)=>chain(actionForTableFieldValidation(validations.validateTableField, field, index, column, value, error), actions.validateTableField(field, index, column, value, error)) : actions.validateTableField
    }
}

export function addInitializers(actions, initializers) {
    return {
        ...actions,
        _initializeForm: initializers.initializeForm ? values => pipeline(initializers.initializeForm(values), actions._initializeForm) : actions._initializeForm,
        _initialize: initializers.initialize ? (openMode, values) => pipeline(initializers.initialize(openMode, values), bind(actions._initialize, openMode)) : actions._initialize,
        _startStep: initializers.startStep ? (openMode, values) => pipeline(initializers.startStep(openMode, values), bind(actions._startStep, openMode)) : actions._startStep
    }
}
 
export function addCloseHandlers(actions, handlers) {
    return {
        ...actions,
        _submit: handlers.submit ? () => chain(actions._submit(), handlers.submit()) : actions._submit,
        _cancel: handlers.cancel ? () => chain(actions._cancel(), handlers.cancel()) : actions._cancel
    }
}

function nextStep(step, form, steps, getNextStep) {
    return (dispatch, getState) => {
        const { step: nextStep, mode = OpenMode.UPDATE } = getNextStep(step, getState);
        if (!nextStep) {
            return Promise.reject({ code: errorCodes.FORM_STEP_VALIDATION_ERROR, message: "Workflow error: no next step" });
        } else if (nextStep === 'submit') {
            return dispatch(form._submit());
        } else {
            return dispatch(steps[nextStep].startStep(mode));
        }
    }
}

function lastStep(step, steps, getLastStep) {
    return (dispatch, getState) => {
        const { step: lastStep, mode = OpenMode.UPDATE } = getLastStep(step, getState);
        if (!lastStep) {
            return Promise.reject({code: errorCodes.FORM_STEP_VALIDATION_ERROR, message: "Workflow error: cannot go back from here"});
        } else {
            return dispatch(steps[lastStep].startStep(mode));
        }
    }
}

export function addWorkflow(form, steps, getNextStep, getLastStep) {

    const { step: initialStep } = getNextStep();

    const stepsWithWorkflow = { ...steps }
    for (const [step, actions] of Object.entries(steps)) {
        stepsWithWorkflow[step] = { 
            ...actions,
            nextStep: () => handleError(chain(actions._validateStep(), nextStep(step, form, steps, getNextStep)), actions.setError),
            lastStep: () => handleError(lastStep(step, steps, getLastStep), actions.setError)
        }
    }
    
    return {
        ...form,
        ...stepsWithWorkflow,
        _initializeForm : (openMode, values)=>chain(form._initializeForm(values), steps[initialStep]._startStep(openMode)),
        _initialize : (openMode, values)=>steps[initialStep]._initialize(openMode, values),
        nextStep: () => (dispatch) => dispatch(form.getForm()).then(form=>dispatch(stepsWithWorkflow[form.step].nextStep())),
        lastStep: () => (dispatch) => dispatch(form.getForm()).then(form=>dispatch(stepsWithWorkflow[form.step].lastStep()))
    }
}