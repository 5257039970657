import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase } from "firebase/client";
import CompanyCard from "./components/CompanyCard";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditCompany(props) {
  const { classes, history, userInfo } = props;
  const [company, setCompany] = useState(
    props.location.state ? props.location.state.company : false
  );
  const [companyName, setCompanyName] = useState("");
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [primaryEmailAddress, setPrimaryEmailAddress] = useState({
    address: "",
    sendInvoices: true,
    sendReminders: true,
  });
  const [secondaryEmailAddress, setSecondaryEmailAddress] = useState({
    address: "",
    sendInvoices: true,
    sendReminders: true,
  });
  const [preferredCommunication, setPreferredCommunication] = useState("Email");
  const [billingAddress, setBillingAddress] = useState("");
  const [slackAddress, setSlackAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [physicalAddress, setPhysicalAddress] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [website, setWebsite] = useState("");
  const [tags, setTags] = useState({ tagsArray: [], visibleToDrivers: true });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState("");

  const uid = firebase.auth().currentUser.uid;
  const user = firebase.auth().currentUser;

  useEffect(() => {
    if (!company) {
      if (userInfo.companyId) {
        console.log("userInfo.companyId", userInfo.companyId);
        const getCompany = async () => {
          return db
            .collection("companies")
            .doc(userInfo.companyId)
            .get()
            .then((snapshot) => {
              const data = snapshot.data();
              setCompany(data);
              setCompanyName(data.companyName ? data.companyName : "");
              setPrimaryPhoneNumber(data.primaryPhone ? data.primaryPhone : "");
              setSecondaryPhoneNumber(
                data.secondaryPhone ? data.secondaryPhone : ""
              );
              setPrimaryEmailAddress(
                data.primaryEmailAddress
                  ? {
                      address: data.primaryEmailAddress,
                      sendInvoices: data.sendInvoicesPrimaryEmailAddress,
                      sendReminders: data.sendRemindersPrimaryEmailAddress,
                    }
                  : {
                      address: "",
                      sendInvoices: true,
                      sendReminders: true,
                    }
              );
              setSecondaryEmailAddress(
                data.secondaryEmailAddress
                  ? {
                      address: data.secondaryEmailAddress,
                      sendInvoices: data.sendInvoicesSecondaryEmailAddress,
                      sendReminders: data.sendRemindersSecondaryEmailAddress,
                    }
                  : {
                      address: "",
                      sendInvoices: true,
                      sendReminders: true,
                    }
              );
              setPreferredCommunication(
                data.preferredCommunication ? data.preferredCommunication : ""
              );
              setBillingAddress(data.billingAddress ? data.billingAddress : "");
              setSlackAddress(data.slackAddress ? data.slackAddress : "");
              setNotes(data.notes ? data.notes : "");
              setIndustryType(data.industryType ? data.industryType : "");
              setPhysicalAddress(
                data.physicalAddress ? data.physicalAddress : ""
              );
              setMailingAddress(data.mailingAddress ? data.mailingAddress : "");
              setWebsite(data.website ? data.website : "");
              setTags(
                data.tags
                  ? {
                      tagsArray: [...data.tags],
                      visibleToDrivers: data.tagsVisibleToDrivers,
                    }
                  : { tagsArray: [], visibleToDrivers: true }
              );
              setDataLoaded(true);
            });
        };
        getCompany();
      }
    } else {
      if (!dataLoaded) {
        setCompanyName(company.companyName ? company.companyName : "");
        setPrimaryPhoneNumber(company.primaryPhone ? company.primaryPhone : "");
        setSecondaryPhoneNumber(
          company.secondaryPhone ? company.secondaryPhone : ""
        );
        setPrimaryEmailAddress(
          company.primaryEmailAddress
            ? {
                address: company.primaryEmailAddress,
                sendInvoices: company.sendInvoicesPrimaryEmailAddress,
                sendReminders: company.sendRemindersPrimaryEmailAddress,
              }
            : {
                address: "",
                sendInvoices: true,
                sendReminders: true,
              }
        );
        setSecondaryEmailAddress(
          company.secondaryEmailAddress
            ? {
                address: company.secondaryEmailAddress,
                sendInvoices: company.sendInvoicesSecondaryEmailAddress,
                sendReminders: company.sendRemindersSecondaryEmailAddress,
              }
            : {
                address: "",
                sendInvoices: true,
                sendReminders: true,
              }
        );
        setPreferredCommunication(
          company.preferredCommunication ? company.preferredCommunication : ""
        );
        setBillingAddress(company.billingAddress ? company.billingAddress : "");
        setSlackAddress(company.slackAddress ? company.slackAddress : "");
        setNotes(company.notes ? company.notes : "");
        setIndustryType(company.industryType ? company.industryType : "");
        setPhysicalAddress(
          company.physicalAddress ? company.physicalAddress : ""
        );
        setMailingAddress(company.mailingAddress ? company.mailingAddress : "");
        setWebsite(company.website ? company.website : "");
        setTags(
          company.tags
            ? {
                tagsArray: [...company.tags],
                visibleToDrivers: company.tagsVisibleToDrivers,
              }
            : { tagsArray: [], visibleToDrivers: true }
        );
        setDataLoaded(true);
      }
    }
  }, [company]);

  const saveCompanyChanges = () => {
    if (userInfo.companyId) {
      return db
        .collection("companies")
        .doc(userInfo.companyId)
        .update({
          companyName,
          primaryPhone: primaryPhoneNumber,
          secondaryPhone: secondaryPhoneNumber,
          mailingAddress,
          physicalAddress,
          primaryEmailAddress: primaryEmailAddress.address,
          secondaryEmailAddress: secondaryEmailAddress.address,
          sendInvoicesPrimaryEmailAddress: primaryEmailAddress.sendInvoices,
          sendRemindersPrimaryEmailAddress: primaryEmailAddress.sendReminders,
          sendInvoicesSecondaryEmailAddress: secondaryEmailAddress.sendInvoices,
          sendRemindersSecondaryEmailAddress:
            secondaryEmailAddress.sendReminders,
          tags: tags.tagsArray,
          tagsVisibleToDrivers: tags.visibleToDrivers,
          preferredCommunication,
          billingAddress,
          industryType,
          website,
          notes,
          slackAddress,
        })
        .then(() => {
          setSnackBarContent("Successfully Updated Company");
          history.push("/admin/settings");
        });
    } else {
      const newCompanyId = db.collection("companies").doc().id;
      return db
        .collection("companies")
        .doc(newCompanyId)
        .set({
          companyId: newCompanyId,
          companyName,
          primaryPhone: primaryPhoneNumber,
          secondaryPhone: secondaryPhoneNumber,
          mailingAddress,
          physicalAddress,
          sendInvoicesPrimaryEmailAddress: primaryEmailAddress.sendInvoices,
          sendRemindersPrimaryEmailAddress: primaryEmailAddress.sendReminders,
          sendInvoicesSecondaryEmailAddress: secondaryEmailAddress.sendInvoices,
          sendRemindersSecondaryEmailAddress:
            secondaryEmailAddress.sendReminders,
          tags: tags.tagsArray,
          tagsVisibleToDrivers: tags.visibleToDrivers,
          preferredCommunication,
          billingAddress,
          industryType,
          website,
          notes,
          slackAddress,
        })
        .then(() => {
          setSnackBarContent("Successfully Created Company");
          history.push("/admin/settings");
        });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h1 style={{ marginBottom: "30px" }} className={classes.title}>
          Edit Company Information
        </h1>
      </GridItem>
      <GridItem xs={12}>
        <CompanyCard
          classes={classes}
          companyName={companyName}
          setCompanyName={setCompanyName}
          primaryPhoneNumber={primaryPhoneNumber}
          setPrimaryPhoneNumber={setPrimaryPhoneNumber}
          secondaryPhoneNumber={secondaryPhoneNumber}
          setSecondaryPhoneNumber={setSecondaryPhoneNumber}
          primaryEmailAddress={primaryEmailAddress}
          setPrimaryEmailAddress={setPrimaryEmailAddress}
          secondaryEmailAddress={secondaryEmailAddress}
          setSecondaryEmailAddress={setSecondaryEmailAddress}
          preferredCommunication={preferredCommunication}
          setPreferredCommunication={setPreferredCommunication}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          slackAddress={slackAddress}
          setSlackAddress={setSlackAddress}
          notes={notes}
          setNotes={setNotes}
          industryType={industryType}
          setIndustryType={setIndustryType}
          tags={tags}
          setTags={setTags}
          history={history}
          physicalAddress={physicalAddress}
          setPhysicalAddress={setPhysicalAddress}
          mailingAddress={mailingAddress}
          setMailingAddress={setMailingAddress}
          website={website}
          setWebsite={setWebsite}
        />
      </GridItem>
      <GridItem
        className={classes.spaceBetween + " " + classes.marginTop}
        xs={12}
      >
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.push("/admin/settings")}
        >
          Cancel
        </Button>
        <Button
          onClick={saveCompanyChanges}
          style={{
            background: "#FFC821",
            color: "#323232",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent.includes("Successfully") ? "success" : "error"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(EditCompany));
