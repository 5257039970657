import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import withStyles from '@material-ui/core/styles/withStyles';
import Style from 'assets/jss/material-dashboard-pro-react/components/formStyle';

export function Form(props) {
    const { classes, submit, cancel, submitButtonLabel, cancelButtonLabel, children, readOnly } = props;
    return (
        <GridContainer className={classes.formContainer} justify='center'>
            <GridItem xs={12}>
                {children}
            </GridItem>
            { cancel &&
                <GridItem xs={12} sm={6}>
                    <Button className={classes.cancelButton} onClick={e=>cancel(e)}>{cancelButtonLabel || "Cancel"}</Button>
                </GridItem>
            }
            { !readOnly && submit &&
                <GridItem xs={12} sm={6}>
                    <Button className={classes.submitButton} onClick={e=>submit(e)}>{submitButtonLabel || "Submit"}</Button>
                </GridItem>
            }
        </GridContainer>
    );
}

Form.propTypes = {
    submitButton: PropTypes.func,
    cancleButton: PropTypes.func,
    submitButtonLabel: PropTypes.node,
    cancelButtonLabel: PropTypes.node,
    readOnly: PropTypes.bool
}

export default withStyles(Style)(Form);