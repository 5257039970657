import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";
import "./pagination.css";
// core components
import { MenuItem, Select } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { ReactComponent as SwapIcon } from "assets/img/swapIcons.svg";
import { ReactComponent as PickUpIcon } from "assets/img/pickupIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { ReactComponent as InfoIcon } from "assets/img/infoIcon.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

import Tooltip from "@material-ui/core/Tooltip";

import PendingReservationTable from "./PendingReservationTable";
import QuotesTable from "./QuotesTable";
import UpdatesTable from "./UpdatesTable";

const pageArr = ["3", "All"];

function Pending(props) {
  const updatesData = [
    {
      "businessType": "",
      "Tax Code Name": "",
      "referral": "denver hardwood",
      "deliveryMethod": "email",
      "fax": "",
      "reminders": [],
      "reservation": {
        "equiptment": "10 Yard Container",
        "dropOffState": "N/A",
        "endDate": "03/17/23",
        "dropOffZipCode": "N/A",
        "driver": "N/A",
        "otherInformation": "",
        "inventoryId": "39myDD43aKWTeb2U4p0l",
        "existingCustomer": true,
        "amountPaid": "0",
        "territory": "",
        "status": "Unapproved",
        "equiptmentLocation": "N/A",
        "heardAboutUsFrom": "N/A",
        "siteAddress": "2700 Ellison Dr, Beverly Hills, CA 90210, USA",
        "recurring": false,
        "siteId": "bp1v1n54q0egRAn1t2Hi",
        "quantity": 1,
        "poNumber": "",
        "dropOffCity": "Beverly Hills",
        "spokenWithTeamMember": false,
        "paymentDueDate": "03/17/23",
        "startDate": "03/10/23",
        "lastEmailedDate": "N/A",
        "amount": 300,
        "sevenDayReservation": false,
        "notes": "",
        "promoCode": ""
      },
      "customerSince": "2022-11-08T07:00:00.000000+0000",
      "comments": [],
      "communicationPreference": "Email",
      "name": "   Alonzo Herrera",
      "quoteId": "Cc5cO6p8hfWtq2PIIesK",
      "zip": "80239",
      "Tax Exemption Details": "",
      "divisions": "",
      "county": "Denver County",
      "company": "Humpty Dump, Inc.",
      "lastName": "",
      "organization": "",
      "externalMessage": "",
      "phoneExt": "",
      "customerId": "1925213",
      "customerNumber": "C4465",
      "phone": "+17206752269",
      "faxExt": "",
      "address": "4967 Joplin St",
      "state": "CO",
      "firstName": "",
      "secondaryPhone": "",
      "city": "Denver",
      "reservations": [],
      "id": "1925213",
      "secondaryPhoneExt": "",
      "internalMemo": "",
      "quoteTimestamp": {
        "seconds": 1678475639,
        "nanoseconds": 464000000
      },
      "secondaryAddress": "",
      "quoteType": "Individual",
      "emails": "valleyhardwood@gmail.com"
    },
    {
      "secondaryPhone": "",
      "reservation": {
        "endDate": "03/17/23",
        "paymentDueDate": "03/17/23",
        "dropOffZipCode": "N/A",
        "amountPaid": "0",
        "amount": "300",
        "notes": "",
        "startDate": "03/10/23",
        "promoCode": "",
        "existingCustomer": true,
        "status": "Unapproved",
        "quantity": "1",
        "equiptmentLocation": "N/A",
        "driver": "N/A",
        "sevenDayReservation": false,
        "dropOffCity": "Beverly Hills",
        "siteId": "bp1v1n54q0egRAn1t2Hi",
        "lastEmailedDate": "N/A",
        "siteAddress": "2700 Ellison Dr, Beverly Hills, CA 90210, USA",
        "otherInformation": "",
        "equiptment": "10 Yard Container",
        "dropOffState": "N/A",
        "poNumber": "",
        "spokenWithTeamMember": false,
        "territory": "",
        "inventoryId": "39myDD43aKWTeb2U4p0l",
        "recurring": false,
        "heardAboutUsFrom": "N/A"
      },
      "internalMemo": "",
      "phoneExt": "",
      "quoteType": "Individual",
      "lastName": "",
      "fax": "",
      "businessType": "",
      "phone": "+17206752269",
      "name": "   Alonzo Herrera",
      "communicationPreference": "Email",
      "quoteTimestamp": {
        "seconds": 1678475723,
        "nanoseconds": 131000000
      },
      "comments": [],
      "county": "Denver County",
      "secondaryPhoneExt": "",
      "zip": "80239",
      "Tax Code Name": "",
      "customerSince": "2022-11-08T07:00:00.000000+0000",
      "firstName": "",
      "reminders": [],
      "city": "Denver",
      "emails": "valleyhardwood@gmail.com",
      "deliveryMethod": "email",
      "address": "4967 Joplin St",
      "externalMessage": "",
      "referral": "denver hardwood",
      "id": "1925213",
      "faxExt": "",
      "Tax Exemption Details": "",
      "organization": "",
      "company": "Humpty Dump, Inc.",
      "customerId": "1925213",
      "secondaryAddress": "",
      "quoteId": "SPpB7Umv9QHmoYQ5w03e",
      "reservations": [],
      "customerNumber": "C4465",
      "state": "CO",
      "divisions": ""
    }
  ];
  const {
    classes,
    pendingReservationsData,
    setModalOpen,
    handleModalOpen,
    pickupsData,
    swapsData,
    quotesData,
    // updatesData,
    history
  } = props;

  const viewOptions = [
    `New Reservations (${pendingReservationsData.length})`,
    `Quotes (${quotesData.length})`,
    `Updates (${updatesData.length})`,
    // `Pick Ups (${pickupsData.length})`,
  ];
  const [pendingType, setPendingType] = useState(
    `New Reservations (${pendingReservationsData.length})`
  );

  console.log('pendingType', pendingType.split(" ")[0])

  const itemsPerPage = 3;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("3");

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  useEffect(() => {
    pendingReservationsData.slice(index, endIndex);
  }, [index]);

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
        <GridContainer>
          <GridItem xs={4}>
            <p className={classes.subHeader} style={{marginTop:"20px"}}>
              Pending ({pendingReservationsData.length + quotesData.length})
            </p>
          </GridItem>

          <GridItem xs={8}>
            <div
              style={{ justifyContent: "end", marginTop:"5px" }}
              className={classes.viewSelectionDiv}
            >
              {viewOptions.map((v, index) => {
                const splitByParenthesis = v.split(" ");
                if (v.includes("New")) {
                  return (
                    <div
                      key={index}
                      onClick={() => setPendingType(v)}
                      className={classes.viewSelection}
                      style={
                        pendingType.split(" ")[0].includes(v.split(" ")[0])
                          ? {
                              background: "#323232",
                              color: "white",
                              borderRadius: "7px 0px 0px 7px",
                            }
                          : {
                              color: "#323232",
                              background: "white",
                              borderRadius: "7px 0px 0px 7px",
                            }
                      }
                    >
                      {`${
                        splitByParenthesis[0] + " " + splitByParenthesis[1]
                      } `}{" "}
                      <span
                        style={
                          pendingType.split(" ")[0].includes(v.split(" ")[0])
                            ? { color: "#FFC821" }
                            : { color: "#323232" }
                        }
                      >
                        {splitByParenthesis[2]}
                      </span>
                    </div>
                  );
                }
                if (v.includes("Quotes")) {
                  return (
                    <div
                      key={index}
                      onClick={() => setPendingType(v)}
                      className={classes.viewSelection}
                      style={
                        pendingType.split(" ")[0].includes(v.split(" ")[0])
                          ? { background: "#323232", color: "white" }
                          : { color: "#323232", background: "white" }
                      }
                    >
                      {`${splitByParenthesis[0]} `}{" "}
                      <span
                        style={
                          pendingType.split(" ")[0].includes(v.split(" ")[0])
                            ? { color: "#FFC821" }
                            : { color: "#323232" }
                        }
                      >
                        {splitByParenthesis[1]}
                      </span>
                    </div>
                  );
                }
                if (v.includes("Updates")) {
                  return (
                    <div
                      key={index}
                      onClick={() => setPendingType(v)}
                      className={classes.viewSelection}
                      style={
                        pendingType.split(" ")[0].includes(v.split(" ")[0])
                          ? {
                              background: "#323232",
                              color: "white",
                              borderRadius: "0px 7px 7px 0px",
                            }
                          : {
                              color: "#323232",
                              background: "white",
                              borderRadius: "0px 7px 7px 0px",
                            }
                      }
                    >
                      {`${splitByParenthesis[0]} `}{" "}
                      <span
                        style={
                          pendingType.split(" ")[0].includes(v.split(" ")[0])
                            ? { color: "#FFC821" }
                            : { color: "#323232" }
                        }
                      >
                        {splitByParenthesis[1]}
                      </span>
                    </div>
                  );
                }
              })}
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <div
          style={
            pageDropdown != 3 && pendingReservationsData.length > 3
              ? { height: "270px", width: "100%", overflowY: "scroll" }
              : null
          }
        >
          <table className={classes.inventoryTable} style={{marginTop:"-5px"}}>
            {pendingType.split(" ")[0].includes("New") ? (
              <PendingReservationTable
                itemsPerPage={itemsPerPage}
                pageNumber={pageNumber}
                pageDropdown={pageDropdown}
                pendingReservationsData={pendingReservationsData}
                handleModalOpen={handleModalOpen}
                classes={classes}
              />
            ) : pendingType.split(" ")[0].includes("Quotes") ? (
              <QuotesTable
                itemsPerPage={itemsPerPage}
                pageNumber={pageNumber}
                pageDropdown={pageDropdown}
                quotesData={quotesData}
                handleModalOpen={handleModalOpen}
                classes={classes}
                history={history}
              />
            ) : (
              <UpdatesTable
                itemsPerPage={itemsPerPage}
                pageNumber={pageNumber}
                pageDropdown={pageDropdown}
                updatesData={updatesData}
                handleModalOpen={handleModalOpen}
                classes={classes}
                history={history}
              />
            )}
          </table>
        </div>
        {pageDropdown == "3" ? (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "13%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={3} style={{ marginTop: "5px" }}>
              <h5 className={classes.indexes}>
                {index + 1} - {endIndex} of{" "}
                {Math.ceil(pendingReservationsData.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={3}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(
                  pendingReservationsData.length / itemsPerPage
                )}
                nextLabel={
                  <ArrowRight
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                previousLabel={
                  <ArrowLeft
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "13%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(dashboardStyles)
)(Pending);
