import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Card from "components/Card/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { reportSelections } from "../../config/globals";

const headers = [
  "Phone Number(s)",
  "Sent",
  "Sent To",
  "Content",
  "Status",
  "Actions",
];
const pageArr = ["5", "All"]
const sortByOptions = ["Scheduled Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];

function TextsTable(props) {
  const { classes, texts, history } = props;
  const [tableSelection, setTableSelection] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [sortBy, setSortBy] = useState("Scheduled Date");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterBySelection, setFilterBySelection] = useState("Filter By");
  const [searchString, setSearchString] = useState("");
  const itemsPerPage = 5;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("5")

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)
  };

  useEffect(() => {
    texts.slice(index, endIndex)
  }, [index])
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if (pageDropdown == "5") {
      return texts
        .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
        .map((d, index) => {
          console.log("dddd", d.status);
          return (
            <tr className={classes.tableRow} key={index}>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              <span
              className={classes.toCustomerProfileText}
              // onClick={() => history.push({
              //   pathname: `/admin/customers/${d.customerId}`,
              //   state: { customer: d },
              // })}
              >
                {d.phoneNumber}
              </span>
              </td>
              <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
                {d.sentAt}
              </td>
              <td className={classes.tableCell}>
              <span
                className={classes.toCustomerProfileText}
              // onClick={() => history.push({
              //   pathname: `/admin/customers/${d.customerId}`,
              //   state: { customer: d },
              // })}
              >
                {d.name}
              </span>
                </td>
              <td className={classes.tableCell}>{d.content}</td>
              <td className={classes.tableCell}>
                <div
                  className={classes.statusDiv}
                  key={index}
                  style={
                    d.status === "Failed"
                      ? {
                        background: "#F83030",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        color: "white",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                      : d.status === "Delivered"
                        ? {
                          background: "#2EB67D",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                          borderRadius: "8px",
                          color: "white",
                          padding: "12px",
                          display: "flex",
                          margin: "0px 10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                        : d.status === "Undelivered"
                          ? {
                            background: "#0071C5",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                            borderRadius: "8px",
                            color: "white",
                            padding: "12px",
                            display: "flex",
                            margin: "0px 10px",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                          : {
                            background: "#929292",
                            color: "white",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                            borderRadius: "8px",
                            padding: "12px",
                            display: "flex",
                            margin: "0px 10px",
                            justifyContent: "center",
                            alignItems: "center",
                          }
                  }
                >
                  {d.status}
                </div>
              </td>
              <td className={classes.tableCell}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      style={{ background: "#FFC720" }}
                      className={classes.actionBtn}
                    >
                      <EyeIcon />
                    </Button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          );
        });
    }
    else {
      return texts.map((d, index) => {
        console.log("dddd", d.status);
        return (
          <tr className={classes.tableRow} key={index}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              <span
              className={classes.toCustomerProfileText}
              // onClick={() => history.push({
              //   pathname: `/admin/customers/${d.customerId}`,
              //   state: { customer: d },
              // })}
              >
                {d.phoneNumber}
              </span>
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.sentAt}
            </td>
            <td className={classes.tableCell}>
              <span
                className={classes.toCustomerProfileText}
              // onClick={() => history.push({
              //   pathname: `/admin/customers/${d.customerId}`,
              //   state: { customer: d },
              // })}
              >
                {d.name}
              </span>
            </td>
            <td className={classes.tableCell}>{d.content}</td>
            <td className={classes.tableCell}>
              <div
                className={classes.statusDiv}
                key={index}
                style={
                  d.status === "Failed"
                    ? {
                      background: "#F83030",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                      borderRadius: "8px",
                      color: "white",
                      padding: "12px",
                      display: "flex",
                      margin: "0px 10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                    : d.status === "Delivered"
                      ? {
                        background: "#2EB67D",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                        borderRadius: "8px",
                        color: "white",
                        padding: "12px",
                        display: "flex",
                        margin: "0px 10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                      : d.status === "Undelivered"
                        ? {
                          background: "#0071C5",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                          borderRadius: "8px",
                          color: "white",
                          padding: "12px",
                          display: "flex",
                          margin: "0px 10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                        : {
                          background: "#929292",
                          color: "white",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                          borderRadius: "8px",
                          padding: "12px",
                          display: "flex",
                          margin: "0px 10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                }
              >
                {d.status}
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <Card style={{ padding: "15px", marginTop:"0px" }}>
      <GridContainer>
        <GridItem xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "20px",
              maxHeight: "80px",
            }}
          >
            <div className={classes.jobsGridItem}>
              <span className={classes.switchReport}>Switch Report</span>
              <Select
                variant="outlined"
                className={classes.filterMenu}
                value={tableSelection}
                sx={{ background: "#F5F5F5" }}
                disableUnderline
                defaultValue={"Select Option"}
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => history.push(e.target.value.link)}
                displayEmpty
                renderValue={
                  tableSelection !== "" ? undefined : () => "Text Activity"
                }
              >
                {reportSelections.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItemReport,
                      }}
                    >
                      {o.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div
              style={{ justifyContent: "space-between" }}
              className={classes.jobsGridItem}
            >
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "100%",
                  marginRight: "10px",
                }}
                selected={fromDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setFromDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      className={classes.datetime}
                      placeholder="From"
                      value={fromDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "100%",
                  marginRight: "10px",
                }}
                selected={toDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setToDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      className={classes.datetime}
                      placeholder="To"
                      value={toDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Button className={classes.exportCSV2}>Export CSV</Button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "20px",
              maxHeight: "80px",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "70%", display: "flex", alignItems: "center" }}
            >
              <h5 className={classes.switchReport}>Sort By</h5>
              <Select
                className={classes.sortByMenu}
                value={sortBy}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => setSortBy(e.target.value)}
                displayEmpty
                renderValue={
                  tableSelection !== "" ? undefined : () => "Text Activity"
                }
              >
                {sortByOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItemReport,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                className={classes.sortByMenu}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                value={directionSelection}
                onChange={(e) => setDirectionSelection(e.target.value)}
              >
                {directionOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                className={classes.sortByMenu}
                value={filterBySelection}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => setFilterBySelection(e.target.value)}
              >
                {filterOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div style={{ width: "41%" }}>
              <TextField
                className={classes.searchBar}
                placeholder="Search"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                variant="outlined"
                // onKeyDown={(e) => {
                //   if (e.keyCode === 13) {
                //     handleSearch();
                //   }
                // }}
                InputProps={{
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <Button
                        onClick={() => console.log("search")}
                        className={classes.searchButton2}
                      >
                        Search
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div style={pageDropdown != 5 && texts.length > 5 ? { height: "500px", width: "100%", overflowY: "scroll" } : null}>
            <table className={classes.inventoryTable}>
              <tbody>
                {renderTableHeader()}
                {renderTableData()}
              </tbody>
            </table>
          </div>
          {pageDropdown == "5" ?
            <GridContainer style={{ marginTop: "10px" }}>
              <GridItem xs={6} >
                <p className={classes.view}>View
                  <span>
                    <Select
                      style={{ width: "13%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                      disableUnderline
                      MenuProps={{
                        // className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor
                      }}

                      onChange={(e) => {
                        setPageDrowdown(e.target.value)
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
              <GridItem xs={4} >
                <h5 className={classes.indexes2}>{index + 1} - {endIndex} of
                  {texts.length < itemsPerPage ? " " + texts.length :
                    " " + Math.ceil(texts.length / itemsPerPage)}
                </h5>
              </GridItem>
              <GridItem xs={2}>
                <ReactPaginate
                  paginationSize={0}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(texts.length / itemsPerPage)}
                  nextLabel={<ArrowRight style={{ fontSize: 25, fontWeight: "bold", color: "white", marginBottom: "-8px" }} />}
                  previousLabel={<ArrowLeft style={{ fontSize: 25, fontWeight: "bold", color: "white", marginBottom: "-8px" }} />}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </GridItem>
            </GridContainer>
            :
            <GridContainer>
              <GridItem xs={6}>
                <p className={classes.view}>View
                  <span>
                    <Select
                      style={{ width: "13%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                      disableUnderline
                      MenuProps={{
                        // className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor
                      }}
                      onChange={(e) => {
                        setPageDrowdown(e.target.value)
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
            </GridContainer>
          }

        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(TextsTable));
