import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { BsFillCircleFill } from "react-icons/bs";
import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const jobActions = ["Job Actions"];

function JobDetailHeader(props) {
  const { classes, job, setEditReservationModal } = props;

  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };

  return (
    <GridContainer>
      <GridItem className={classes.gridItem} xs={10}>
        <h1 className={classes.title}>{`${job.jobId}: ${
          job.reservation.reservation.jobs.delivery &&
          job.reservation.reservation.jobs.delivery.jobId &&
          job.reservation.reservation.jobs.delivery.jobId === job.jobId
            ? "Delivery"
            : "Pickup"
        } ${renderEquipment(job.reservation.reservation.equiptment)}`}</h1>
        <div
          style={
            job.reservation.reservation.status === "Scheduled"
              ? { background: "#E01E5A" }
              : job.reservation.reservation.status === "Completed"
              ? { background: "#2EB67D" }
              : job.reservation.reservation.status === "In Progress"
              ? { background: "#E0AC12", color: "#323232" }
              : job.reservation.reservation.status === "Approved"
              ? { background: "#E0AC12", color: "#323232" }
              : { background: "#323232" }
          }
          className={classes.commentDiv}
        >
          {job.reservation.reservation.status}
        </div>
      </GridItem>
      <GridItem xs={2}>
        <Select
          className={classes.jobsSelectMenu}
          value={jobActions[0]}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          // onChange={(e) => setSortBy(e.target.value)}
        >
          {jobActions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(JobDetailHeader));
