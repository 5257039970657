import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Tooltip from "@material-ui/core/Tooltip";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";
import { ReactComponent as BlackMailIcon } from "assets/img/blackMailIcon.svg";

const headers = ["Name", "Phone Number", "Email", "Actions"];

function CompanyTab(props) {
    const { classes, history, userInfo, company, companyId, staff, pageDropdown, pageNumber, itemsPerPage } = props;
    const uid = firebase.auth().currentUser.uid;

    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "45px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th
                            // style={key === "Actions" ? { width: "250px" } : {}}
                            className={classes.tableHeaders}
                            key={key}
                        >
                            {key}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        if (pageDropdown == "5") {
            return staff
                .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
                .map((d, index) => {
                    console.log("dddd", d);
                    // if (d.id === uid) {
                    //     return null;
                    // }
                    return (
                        <tr className={classes.notesTableRow} key={d.name}>
                            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>{d.firstName + " " + d.lastName}</td>
                            <td className={classes.tableCell}>
                                {d.phoneNumber ? d.phoneNumber : "N/A"}
                            </td>
                            <td className={classes.tableCell}>{d.email}</td>
                            <td className={classes.tableCell} style={{width:"150px"}}>
                                <div style={{
                                    display: "flex",
                                    // width: "100%",
                                    justifyContent: "space-evenly",
                                }}>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="View Staff"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <Button
                                            style={{ background: "#FFC720", marginRight:"10px" }}
                                            className={classes.actionBtn}
                                        >
                                            <EyeIcon />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="Edit Staff"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <Button
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/admin/edit-user/${d.uid}`,
                                                    state: {
                                                        user: d,
                                                    },
                                                })
                                            }
                                            className={classes.actionBtn}
                                            style={{ background: "#323232", marginRight:"10px" }}
                                        >
                                            <WhiteEditIcon />
                                        </Button>
                                    </Tooltip>

                                    <Tooltip
                                        id="tooltip-top"
                                        title="Message Staff"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <Button
                                            className={classes.actionBtn}
                                            style={{ background: "#FEDA1B" }}
                                        >
                                            <BlackMailIcon />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </td>
                        </tr>
                    );
                });
        }
        else {
            return staff.map((d, index) => {
                console.log("dddd", d);
                if (d.id === uid) {
                    return null;
                }
                return (
                    <tr className={classes.notesTableRow} key={d.name}>
                        <td className={classes.tableCell}>{d.name}</td>
                        <td className={classes.tableCell}>
                            {d.phoneNumber ? d.phoneNumber : "N/A"}
                        </td>
                        <td className={classes.tableCell}>{d.email}</td>
                        <td className={classes.tableCell}>
                            <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%", }}>
                                <Tooltip
                                    id="tooltip-top"
                                    title="View Staff"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        style={{ background: "#FFC720", marginRight:"10px" }}
                                        className={classes.actionBtn}
                                    >
                                        <EyeIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Edit Staff"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        onClick={() =>
                                            history.push({
                                                pathname: `/admin/edit-user/${d.uid}`,
                                                state: {
                                                    user: d,
                                                },
                                            })
                                        }
                                        className={classes.actionBtn}
                                        style={{ background: "#323232", marginRight:"10px" }}
                                    >
                                        <WhiteEditIcon />
                                    </Button>
                                </Tooltip>

                                <Tooltip
                                    id="tooltip-top"
                                    title="Message Staff"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionBtn}
                                        style={{ background: "#FEDA1B" }}
                                    >
                                        <BlackMailIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                );
            });
        }
    };

    return (
        <GridContainer >
            <GridItem xs={12}>
                <div style={pageDropdown != 5 && staff.length > 5 ? { height: "550px", width: "100%", overflowY: "scroll" } : null}>
                    <table className={classes.inventoryTable}>
                        <tbody>
                            {renderTableHeader()}
                            {renderTableData()}
                        </tbody>
                    </table>
                </div>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        customersData: state.customers.customers,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(customTableStyle)
)(React.memo(CompanyTab));
