import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase, storage, createNewUser } from "firebase/client";
import AddUserProfileCard from "./components/AddUserProfileCard";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";

import PermissionLevelCard from "./components/PermissionLevelCard";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddUser(props) {
  const { classes, history, userInfo } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [website, setWebsite] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [changedEmail, setChangedEmail] = useState(false);
  const [company, setCompany] = useState("");
  const [needPasswordModal, setNeedPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [snackBarContent, setSnackBarContent] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    permission: false,
  });

  const [showUserOnSchedule, setShowUserOnSchedule] = useState({
    yes: true,
    no: false,
  });
  const [permissionLevel, setPermissionLevel] = useState({
    superAdmin: false,
    finance: false,
    dispatcher: false,
    driver: false,
  });

  // const uid = firebase.auth().currentUser.uid;
  const companyPicRef = createRef(null);
  const [savingPic, setSavingPic] = useState(false);
  const [userPic, setUserPic] = useState("");
  const [fileRandomId, setFileRandomId] = useState("");

  const [profilePicFile, setProfilePicFile] = useState(false);

  const saveProfileChanges = async () => {
    let permission = "";
    for (var key in permissionLevel) {
      if (permissionLevel[key]) {
        if (key === "superAdmin") {
          permission = "Super Admin";
        }
        if (key === "finance") {
          permission = "Finance";
        }
        if (key === "dispatcher") {
          permission = "Dispatcher";
        }
        if (key === "driver") {
          permission = "Driver";
        }
      }
    }
    if (!permission || !firstName || !lastName || !email || !password) {
      let newErrors = {
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        permission: false,
      };
      if (!firstName) {
        newErrors = { ...newErrors, firstName: true };
      }
      if (!lastName) {
        newErrors = { ...newErrors, lastName: true };
      }

      if (!email) {
        newErrors = { ...newErrors, email: true };
      }
      if (!password) {
        newErrors = { ...newErrors, password: true };
      }
      if (!permission) {
        newErrors = { ...newErrors, permission: true };
      }
      setErrors({ ...newErrors });
      return;
    }
    const newUID = db.collection("users").doc().id;
    await createNewUser({
      uid: newUID,
      email: email,
      password: password,
    });
    let companyData = await db
      .collection("companies")
      .doc(userInfo.companyId)
      .get();
    companyData = companyData.data();
    companyData.staff.push(newUID);
    if (userPic) {
      const storageRef = storage.ref().child(`${newUID}/profilePic`);
      await storageRef.put(profilePicFile);
      const downloadURL = await storageRef.getDownloadURL();
      await db
        .collection("users")
        .doc(newUID)
        .set({
          firstName,
          lastName,
          name: firstName + " " + lastName,
          email,
          phoneNumber,
          company: userInfo.company,
          companyId: userInfo.companyId,
          profilePic: downloadURL,
          uid: newUID,
          role: permission,
          defaultScheduleView: "Day",
          userOnSchedule: showUserOnSchedule.yes
            ? "Yes"
            : showUserOnSchedule.no
              ? "No"
              : "",
        });
      await db
        .collection("companies")
        .doc(userInfo.companyId)
        .update({
          staff: [...companyData.staff],
        });
      setSnackBarContent("Succesfully Created Profile");
      history.push("/admin/settings");
    } else {
      await db
        .collection("users")
        .doc(newUID)
        .set({
          firstName,
          lastName,
          name: firstName + " " + lastName,
          email,
          phoneNumber,
          company: userInfo.company,
          companyId: userInfo.companyId,
          uid: newUID,
          role: permission,
          defaultScheduleView: "Day",
          userOnSchedule: showUserOnSchedule.yes
            ? "Yes"
            : showUserOnSchedule.no
              ? "No"
              : "",
        });
      await db
        .collection("companies")
        .doc(userInfo.companyId)
        .update({
          staff: [...companyData.staff],
        });
      setSnackBarContent("Succesfully Created Profile");
      history.push("/admin/settings");
    }
  };

  const handleProfilePictureChange = (event) => {
    const randomId = db.collection("users").doc().id;
    const storageRef = storage.ref().child(`tempProfilePics/${randomId}`);
    setSavingPic(true);
    const file = event.target.files[0];
    return storageRef.put(file).then(() => {
      storageRef.getDownloadURL().then((url) => {
        setProfilePicFile(file);
        setFileRandomId(randomId);
        setUserPic(url);
        setSavingPic(false);
        // return db
        //   .collection("companies")
        //   .doc(companyId)
        //   .update({ companyLogo: url })
        //   .then(() => {
        //     setCompanyLogo(url);
        //     setSavingPic(false);
        //     setSnackBarContent("Successfully Uploaded Company Logo");
        //   });
      });
    });
  };

  const handleProfilePicDelete = () => {
    if (fileRandomId) {
      const storageRef = storage.ref().child(`tempProfilePics/${fileRandomId}`);
      storageRef.delete().then(() => {
        setFileRandomId("");
        setUserPic("");
      });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h1 style={{ marginBottom: "30px" }} className={classes.title}>
          Add User
        </h1>
      </GridItem>
      <GridItem className={classes.marginBottom} xs={12}>
        <AddUserProfileCard
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          website={website}
          setWebsite={setWebsite}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          secondaryPhoneNumber={secondaryPhoneNumber}
          setSecondaryPhoneNumber={setSecondaryPhoneNumber}
          email={email}
          setEmail={setEmail}
          company={company}
          setCompany={setCompany}
          userPic={userPic}
          handleProfilePictureChange={handleProfilePictureChange}
          setUserPic={setUserPic}
          history={history}
          setChangedEmail={setChangedEmail}
          userInfo={userInfo}
          showUserOnSchedule={showUserOnSchedule}
          setShowUserOnSchedule={setShowUserOnSchedule}
          companyPicRef={companyPicRef}
          savingPic={savingPic}
          setSavingPic={setSavingPic}
          handleProfilePicDelete={handleProfilePicDelete}
          permissionLevel={permissionLevel}
          setPermissionLevel={setPermissionLevel}
          password={password}
          setPassword={setPassword}
          errors={errors}
        />
      </GridItem>
      <GridItem xs={12} className={classes.marginBottom}>
        <PermissionLevelCard
          permissionLevel={permissionLevel}
          setPermissionLevel={setPermissionLevel}
          errors={errors}
        />
      </GridItem>
      <GridItem className={classes.spaceBetween} xs={12}>
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.push("/admin/settings")}
        >
          Cancel
        </Button>

        <Button
          style={{ background: "#D40707", color: "white" }}
          className={classes.cancelSaveButton}
        // onClick={() => history.push("/admin/settings")}
        >
          Delete
        </Button>
        
        <Button
          onClick={saveProfileChanges}
          style={{
            background: "#FFC821",
            color: "#323232",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent !== "Succesfully Updated Profile"
              ? "error"
              : "success"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(AddUser));
