import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { BsJustify } from "react-icons/bs";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";

const selectOptions = ["Rental Number", "Scheduled Date", "End Date", "Site Address"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Inventory Size", "All Sizes", "10 Yard Dumpster", "15 Yard Dumpster",
  "20 Yard Dumpster", "30 Yard Dumpster", "Metal/Concrete", "Standard 30 Yard"];

function Filters(props) {
  const {
    classes,
    sortBySelection,
    setSortBySelection,
    setDirectionSelection,
    directionSelection,
    setFilterSelection,
    filterSelection,
    setSearchString,
    searchString,
    handleFilters,
    handleSearch,
    handleCommentFilter
  } = props;
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  return (
    <div>
      <GridContainer>
        <GridItem className={classes.filtersGridItem} xs={1}>
          <h5 className={classes.subHeader} style={{ margin: "0px", fontSize: "19px", justifyContent: "flex-start" }}>Sort By</h5>
        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={5}>
          <Select
            className={classes.selectMenuSort}
            value={filterSelection}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => handleFilters("filter", e.target.value)}
          >
            {selectOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            style={{ width: "15%" }}
            className={classes.ascMenu}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            value={directionSelection}
            onChange={(e) => handleFilters("direction", e.target.value)}
          >
            {directionOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            style={{ width: "35%" }}
            className={classes.filterMenu}
            value={sortBySelection}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => handleFilters("comments", e.target.value)}
          >
            {filterOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.filtersGridItemWithCsvDownload} xs={6}>
          <TextField
            style={{ marginLeft: "20px" }}
            className={classes.searchBar}
            placeholder="Search Rentals"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            variant="outlined"
              onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
              endAdornment: (
                <InputAdornment className={classes.adournedEnd} position="end">
                  <Button
                    onClick={handleSearch}
                    className={classes.searchButton}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(Filters));
