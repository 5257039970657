import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { CheckRounded } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";

function RentalDetails(props) {
  const {
    classes,
    errors,
    setSpokenWithTeamMember,
    spokenWithTeamMember,
    setBillingAddress,
    billingAddress,
    setEmails,
    emails,
    handleSelect,
    customerInfo,
  } = props;

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={5}>
          <span
            style={{ textTransform: "none" }}
            className={classes.siteInfoTitle}
          >
            Have you spoken with a member of our team?{" "}
            {errors.spokenWithTeamMember && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // marginRight: "30px",
              }}
            >
              <Radio
                checked={spokenWithTeamMember.yes}
                onChange={() => {
                  setSpokenWithTeamMember({ yes: true, no: false });
                }}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                style={{
                  width: "50px",
                  height: "50px",
                  // borderRadius: "10%",
                  // border: "1px solid #929292",
                }}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span>I understand the rental process and I am ready to finalize.</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Radio
                checked={spokenWithTeamMember.no}
                onChange={() => setSpokenWithTeamMember({ yes: false, no: true })}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                style={{
                  width: "50px",
                  height: "50px",
                  // borderRadius: "10%",
                  // border: "1px solid #929292",
                }}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span>I have questions, please call me to finalize my reservation.</span>
            </div>
          </div>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={3}>
          <span
            style={{ textTransform: "none" }}
            className={classes.siteInfoTitle}
          >
            Billing Address
            {errors.billingAddress && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <GooglePlacesAutoComplete
            classes={classes}
            value={billingAddress}
            width={"100%"}
            placeholder="Search your billing address.."
            onChange={(e) => setBillingAddress(e)}
            onSelect={handleSelect}
            margin="0px 0px 20px 0px"
            background="#F5F5F5"
            googleCallbackName="initOne"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span
            style={{ textTransform: "none" }}
            className={classes.siteInfoTitle}
          >
            Emails for Rental Information
            {errors.emails && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <ReactMultiEmail
            placeholder='Input your email'
            emails={emails}
            onChange={(_emails: string[]) => {
              setEmails(_emails);
            }}
            autoFocus={true}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(RentalDetails));
