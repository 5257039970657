import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./Dnd/Sortable";
import Droppable from "./Dnd/Droppable";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ArrowDown } from "assets/img/arrowDown.svg";
import { ReactComponent as DeliveryIcon } from "assets/img/deliveryIcon.svg";
import { ReactComponent as SwapIcon } from "assets/img/swapIcons.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import { ReactComponent as BlackMailIcon } from "assets/img/blackMailIcon.svg";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const containerStyle = {
  background: "#dadada",
  padding: 10,
  margin: 10,
  flex: 1,
};

export default function DriverRow(props) {
  const {
    id,
    items,
    classes,
    width,
    history,
    driver,
    setWeeklyItems,
    key,
    itemsState,
    splitSchedule
  } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useDroppable({
    id,
    driverId: id,
    data: {
      data: items,
    },
  });
  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <div
        style={{ width: width }}
        ref={setNodeRef}
        className={classes.scheduleRow}
      >
        <GridContainer className={classes.listItemGridContainer} key={key}>
          <GridItem className={classes.noPadding} xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {driver.open ? (
                <ArrowDropDownIcon
                  onClick={() => {
                    const itemsCopy = itemsState;
                    itemsCopy[driver.driverId].open = false;
                    setWeeklyItems({ ...itemsCopy });
                  }}
                />
              ) : (
                <ArrowRightIcon
                  onClick={() => {
                    const itemsCopy = itemsState;
                    itemsCopy[driver.driverId].open = true;
                    setWeeklyItems({ ...itemsCopy });
                  }}
                />
              )}

              <span
                style={{
                  color: "#0071C5",
                  fontFamily: "Archivo",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                {`${driver.name} (${driver.weeklySchedule[splitSchedule].length})`}
              </span>
            </div>
          </GridItem>

          {driver.open ? (
            <GridItem className={classes.noPadding} xs={12}>
              {items.map((reservation, index) => {
                return (
                  <SortableItem
                    key={index}
                    driverItemIndex={index}
                    reservation={reservation}
                    history={history}
                    classes={classes}
                    r={reservation}
                    id={reservation.reservation.reservationId}
                    reservationId={reservation.reservation.reservationId}
                    data={reservation}
                    driverId={id}
                    territoryColor={reservation.reservation.territoryColor}
                  />
                );
              })}
            </GridItem>
          ) : null}
        </GridContainer>
      </div>
    </SortableContext>
  );
}
