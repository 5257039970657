import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";

import BusinessOverview from "./components/BusinessOverview";
import WhoOwes from "./components/WhoOwes";
import SalesAndCustomers from "./components/SalesAndCustomers";

function Reports(props) {
  const { classes, history, user } = props;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h1 style={{ textAlign: "left" }} className={classes.title}>
          Reports
        </h1>
      </GridItem>
      <GridItem xs={12}>
        <BusinessOverview history={history} user={user} />
      </GridItem>
      {user.role && user.role === "Super Admin" ?
        <GridItem xs={12}>
          <WhoOwes history={history} />
        </GridItem>
        : null
      }
      <GridItem xs={12}>
        <SalesAndCustomers history={history} user={user} />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(Reports));
