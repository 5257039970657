import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import regularsStyles from "assets/jss/material-dashboard-pro-react/views/regularsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

const selectOptions = [
  "First Name",
  "Last Name",
  "Company",
];
const directionOptions = ["Asc", "Desc"];

function Filters(props) {
  const {
    classes,
    setDirectionSelection,
    directionSelection,
    setFilterSelection,
    filterSelection,
    setSearchString,
    searchString,
    handleSearch,
    handleFilters,
  } = props;

  return (
    <GridContainer className={classes.filterGridContainer}>
      <GridItem className={classes.filtersGridItem} xs={2}>
        <span className={classes.sortBy}>Sort By</span>
      </GridItem>
      <GridItem className={classes.filtersGridItem} xs={5}>
        <Select
          style={{ leftPadding: "20px", width: "60%" }}
          className={classes.selectMenuSort}
          value={filterSelection}
          disableUnderline
          classes={{
            // root: classes.selectText,
          }}
          onChange={(e) => handleFilters("filter", e.target.value)}
        >
          {selectOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          style={{ leftPadding: "20px", width: "80px" }}
          className={classes.ascMenu}
          value={directionSelection}
          disableUnderline
          classes={{
            // root: classes.selectText,
          }}
          value={directionSelection}
          onChange={(e) => handleFilters("direction", e.target.value)}
        >
          {directionOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>
      <GridItem className={classes.filtersGridItemWithCsvDownload} xs={5} style={{display:"flex", justifyContent:"flex-end"}}>
        <TextField
        style={{marginLeft:"-23px", width:"275px"}}
          className={classes.searchBar}
          placeholder="Search Regulars"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearch();
            }
          }}
          variant="outlined"
          InputProps={{
            classes: {
              adornedEnd: classes.adournedEnd,
            },
            endAdornment: (
              <InputAdornment className={classes.adournedEnd} position="end">
                <Button className={classes.searchButton}>Search</Button>
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(regularsStyles)
)(React.memo(Filters));
