import React from "react";
import BoundEditorForm from 'components/Explorer/BoundEditorForm';
import { connect } from "react-redux";
import actions from 'store/actions/forms/profile';
import accessors from 'store/reducers/forms/profile';

const EditorForm = BoundEditorForm(actions, accessors);

export function ProfileEditor(props) {

    const { initialize, uid } = props;

    React.useEffect(() => {
        if (uid) initialize();
    },[uid, initialize]);

    return (
        <EditorForm order={["firstName", "lastName", "phoneNumber"]} />
    );
}

function mapStateToProps() { 
    return (state)=> {
        return {
            uid: state.auth.uid
        }
    };
}

function mapDispatchToProps(){
    return (dispatch) => {
        return {
            initialize: (mode, key)=>dispatch(actions.initialize(mode, key))
        };
    }
}

export default connect(mapStateToProps(), mapDispatchToProps())(ProfileEditor);