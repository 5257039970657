/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import dotenv from "dotenv";
import compose from "recompose/compose";
import { connect } from "react-redux";
import axios from "axios";
import Geocode from "react-geocode";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import "./googleMap.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { NavLink, withRouter } from "react-router-dom";
import CustomMarker from "assets/img/customGoogleMapMarker.png";

let map;
dotenv.config();

const loader = new Loader({
  apiKey: process.env.REACT_APP_FB_API_KEY,
  version: "weekly",
});

const GoogleMap = (props) => {
  const { mapData } = props;

  useEffect(() => {
    async function fetchData() {
      loader
        .load()
        .then((google) => {
          map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: 39.655, lng: -105.01186 },
            zoom: 10,
          });

          var directionService = new google.maps.DirectionsService();
          const startLat = 34.22174;
          const startLong = -119.08175;
          const promiseArray = [];
          mapData.forEach((d) => {
            let previous = false;
            const schedule = d.route;
            // if (schedule.length > 0) {
            // starting point will be humpty dump lat lng
            // replace it with drop off after point is created
            // let previous = { lat: 34.22174, lng: -119.08175 };
            // schedule.forEach((s) => {
            // promiseArray
            //   .push(
            // if (d.schedule.length > 0) {
              directionService
              .route({
                origin: { lat: 39.655, lng: -105.01186 },
                destination: { lat: 39.655, lng: -105.01186 },
                travelMode: "DRIVING",
                // transitOptions: TransitOptions,
                // drivingOptions: DrivingOptions,
                // unitSystem: UnitSystem,
                waypoints: schedule.map((s) => {
                  return {
                    location: s.reservation.siteAddress,
                    stopover: true,
                  };
                }),
                // optimizeWaypoints: true,
                // provideRouteAlternatives: Boolean,
                // avoidFerries: Boolean,
                // avoidHighways: Boolean,
                avoidTolls: true,
                // region: String
              })
              .then((r) => {
                var directionsRenderer = new google.maps.DirectionsRenderer({
                  suppressMarkers: false,
                  polylineOptions: { strokeColor: d.color, strokeWeight: 10 },
                });
                directionsRenderer.setDirections(r);
                directionsRenderer.setMap(map);
              });
            // }

            // )
            // previous = s.reservation.siteAddress;
            // .then((result) => {
            //   if (result.status == "OK") {
            //     directionsRenderer.setDirections(result);
            //     previous = s.reservation.siteAddress;
            //   }
            //   console.log("directionService", result);
            // })
            // .catch((err) => {
            //   console.log("directionService err", err);
            // });
            // });
            // }
          });
          // Promise.all(promiseArray).then((results) => {
          //   console.log('result', results)
          //   results.forEach((r) => {
          //     var directionsRenderer = new google.maps.DirectionsRenderer({ suppressMarkers: true, polylineOptions: { strokeColor: '#5cb85c' } });
          //     directionsRenderer.setDirections(r);
          //     directionsRenderer.setMap(map);
          //   })
          // })
        })
        .catch((e) => {
          console.log("error initializing map");
          // do something
        });
    }
    fetchData();
  }, [props]);

  return <div id="map" style={{ height: "100%", zIndex: 3 }}></div>;
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(GoogleMap));
