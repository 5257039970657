import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { BsFillCircleFill } from "react-icons/bs";
import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const jobActions = ["Job Actions"];

function QuoteDetailHeader(props) {
  const {
    classes,
    siteID,
    setEditReservationModal,
    quote,
    history,
    modalOpen,
    setModalOpen,
  } = props;

  return (
    <GridContainer>
      <GridItem className={classes.gridItem} xs={10}>
        <h1 className={classes.title}>Quote {quote.quoteId}</h1>
      </GridItem>
      <GridItem className={classes.gridItem} xs={2}>
        <div
          onClick={() => setModalOpen(true)}
          className={classes.editCustomer}
        >
          <EditIcon />
          <span>Edit</span>
        </div>
        <div
          // onClick={() => history.push(`/admin/edit-site/${quote}`)}
          style={{ background: "#323232", color: "white" }}
          className={classes.editCustomer}
        >
          <MailIcon />
          <span>Email</span>
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(QuoteDetailHeader));
