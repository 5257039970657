import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { updateCustomer } from "store/actions/customers/customerActions";
import { getOneWeekLater } from "../../Dashboard/helpers/helpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const commentSelections = ["Cautious", "New", "Regular", "Overdue", "Unpaid"];

function EditCustomerModal(props) {
  const {
    classes,
    customerInfo,
    handleUpdateCustomer,
    setCustomerEdited,
    setEditCustomerModal,
    setCustomerInfo,
    history,
    customerInfoHeader
  } = props;
  const [billingAddress, setBillingAddress] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  console.log('phoneNumber', phoneNumber)
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  const [infoReceived, setInfoReceived] = useState(false);
  const [errors, setErrors] = useState({
    phoneNumber: false,
    billingAddress: false,
    firstName: false,
    lastName: false,
    email: false,
  });
  const [comments, setComments] = useState([]);
  const [commentSelections, setCommentSelections] = useState([
    "Cautious",
    "New",
    "Regular",
    "Overdue",
    "Unpaid",
  ]);
  const [fullAddress, setFullAddress] = useState("")

  useEffect(() => {
    if (customerInfo.billingAddress === undefined) {
      let combine = customerInfo.address + ',' + customerInfo.city + ',' + customerInfo.state + ' ' + customerInfo.zip
      setFullAddress(combine)
    }
  }, [])

  useEffect(() => {
    let newFirst;
    if (!infoReceived) {
      if (customerInfo.comments.length > 0) {
        const commentsToShow = [];
        commentSelections.forEach((c) => {
          if (!customerInfo.comments.includes(c)) {
            commentsToShow.push(c);
          }
        });
        setCommentSelections([...commentsToShow]);
      }
      console.log('customerInfo', customerInfo)
      setComments([...customerInfo.comments]);
      setEmail(customerInfo.email);
      if(!customerInfo.firstName){
        let splitName = customerInfo.name.split(" ")
        if(!splitName[0]){
          setFirstName(splitName[1])
          setLastName(splitName[2])
        }
        else{
          setFirstName(splitName[0])
          setLastName(splitName[1])
        }
      }
      else{
        setLastName(customerInfo.lastName);
        setFirstName(customerInfo.firstName);
      }
      
      setPhoneNumber(customerInfo.phoneNumber);
      setAddressLine2(customerInfo.billingAddressLine2);
      setBillingAddress(customerInfo.billingAddress);
      setInfoReceived(true);
    }
  }, [customerInfo]);

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      if (placeholder === "Search your billing address..") {
        setBillingAddress(results[0].formatted_address);
      }
    });
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;
    setPhoneNumber(value);
  };
  const handleSubmit = () => {
    if (!phoneNumber || !billingAddress || !firstName || !lastName || !email) {
      setHasErrors(true);
      let newErrors = {
        phoneNumber: false,
        billingAddress: false,
        firstName: false,
        lastName: false,
        email: false,
      };
      if (!phoneNumber) {
        newErrors = { ...newErrors, phoneNumber: true };
      }
      if (!billingAddress) {
        newErrors = { ...newErrors, billingAddress: true };
      }

      if (!firstName) {
        newErrors = { ...newErrors, firstName: true };
      }
      if (!lastName) {
        newErrors = { ...newErrors, lastName: true };
      }
      if (!email) {
        newErrors = { ...newErrors, email: true };
      }
      setErrors({ ...newErrors });
    } else {
      const editedCustomerInfo = {
        ...customerInfo,
        phoneNumber: phoneNumber ? phoneNumber : customerInfo.phoneNumber,
        billingAddress: billingAddress
          ? billingAddress
          : billingAddress.phoneNumber,
        billingAddressLine2: addressLine2 ? addressLine2 : "",
        firstName: firstName ? firstName : customerInfo.firstName,
        lastName: lastName ? lastName : customerInfo.lastName,
        email: customerInfo.email,
        comments: comments,
      };
      handleUpdateCustomer(editedCustomerInfo).then(() => {
        history.replace({
          ...history.location,
          state: {
            customer: editedCustomerInfo,
          },
        });
        setEditCustomerModal(false);
        setCustomerEdited(true);
        if (!customerInfoHeader) {
          setCustomerInfo(false);
          setComments([])
        } else {
          setCustomerInfo(editedCustomerInfo);
        }
      });
    }
  };

  const handleCommentsChange = (e) => {
    const indexToRemove = commentSelections.indexOf(e.target.value);
    let newCommentsSelections = [...commentSelections];
    newCommentsSelections.splice(indexToRemove, 1);
    setCommentSelections([...newCommentsSelections]);
    setComments([...comments, e.target.value]);
  };

  const handleRemoveComment = (comment) => {
    const indexToRemove = comments.indexOf(comment);
    let newComments = [...comments];
    newComments.splice(indexToRemove, 1);
    setCommentSelections([...commentSelections, comment]);
    setComments([...newComments]);
  };

  return (
    <Card style={{ margin: "0px" }} className={classes.card}>
      <h5 className={classes.formTitle}>
        Contact Information:{" "}
        {errors.phoneNumber || errors.lastName || errors.firstName ? (
          <span style={{ color: "red" }}>Required*</span>
        ) : null}
      </h5>

      <TextField
        className={classes.emailInput}
        value={customerInfo.email ? customerInfo.email : customerInfo.emails}
        disabled
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />
      <TextField
        className={classes.emailInput}
        value={phoneNumber ? phoneNumber : customerInfo.phone}
        onChange={handlePhoneNumberChange}
        placeholder="Phone Number"
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <h5 className={classes.formTitle}>
        Billing Address:{" "}
        {errors.billingAddress && (
          <span style={{ color: "red" }}>Required*</span>
        )}{" "}
      </h5>
      <GooglePlacesAutoComplete
        classes={classes}
        width={"100%"}
        value={billingAddress ? billingAddress : fullAddress}
        placeholder="Search your billing address.."
        onChange={(e) => {
          setBillingAddress(e);
        }}
        onSelect={handleSelect}
        margin="0px 0px 20px 0px"
        background="#F5F5F5"
        googleCallbackName="initOne"
      />

      <TextField
        className={classes.emailInput}
        value={
          customerInfo.billingAddressLine2
            ? customerInfo.billingAddressLine2
            : addressLine2
        }
        placeholder="Address line 2 (optional)"
        variant="outlined"
        onChange={(e) => setAddressLine2(e.target.value)}
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <h5 className={classes.formTitle}>Comments: </h5>

      <Select
        className={classes.filterMenu}
        value={""}
        sx={{ background: "#F5F5F5" }}
        disableUnderline
        defaultValue={"Select Option"}
        classes={{
          root: classes.selectText,
        }}
        onChange={handleCommentsChange}
      >
        {commentSelections.map((o, i) => {
          return (
            <MenuItem
              key={i}
              value={o}
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              {o}
            </MenuItem>
          );
        })}
      </Select>

      <div style={{ display: "flex", width: "100%", marginTop: "15px" }}>
        {comments.map((c) => {
          console.log("comments", comments);
          return (
            <div
              style={
                c === "Regular"
                  ? { background: "#2EB67D" }
                  : c === "Cautious"
                    ? { background: "#323232" }
                    : c === "Overdue"
                      ? { background: "#F83030" }
                      : c === "New"
                        ? { background: "#0071C5" }
                        : { background: "#ECB22E", color: "#323232" }
              }
              className={classes.editCustomerCommentDiv}
            >
              {c}{" "}
              <span
                onClick={() => handleRemoveComment(c)}
                style={{ color: "white", fontSize: "16px" }}
              >
                X
              </span>
            </div>
          );
        })}
      </div>

      <Button onClick={handleSubmit} className={classes.sendBtn}>
        Edit Customer
      </Button>

      <Snackbar open={hasErrors} onClose={() => setHasErrors(false)}>
        <Alert onClose={() => setHasErrors(false)} severity={"error"}>
          Please Fill Out All Mandatory Fields
        </Alert>
      </Snackbar>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  handleUpdateCustomer: (editedCustomer) =>
    dispatch(updateCustomer(editedCustomer)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(EditCustomerModal));
