import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import serviceRemindersStyle from "assets/jss/material-dashboard-pro-react/views/serviceRemindersStyle.js";
import Switch from "@material-ui/core/Switch";
import { ReactComponent as DumpsterIcon } from "assets/img/dumpsterServicesIcon.svg";
import { Button } from "@material-ui/core";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";

function DumpsterServicesMap(props) {
  const { classes, MockDumpsters } = props;

  return (
    <GridContainer>
      {MockDumpsters.map((d, index) => {
        return (
          <GridItem key={index} xs={6}>
            <Card className={classes.card}>
              <GridContainer className={classes.dumpsterGridContainer}>
                <GridItem className={classes.dumpsterIcon} xs={1}>
                  <DumpsterIcon />
                </GridItem>
                <GridItem className={classes.dumpsterNumber} xs={8}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontWeight: 700 }}>
                      Dumpster #{d.dumpsterNumber}
                    </span>
                    <span>{d.barCode}</span>
                  </div>
                </GridItem>
                <GridItem className={classes.noPaddingRight} xs={3}>
                  <div className={classes.serviceDueDiv}>
                    <span style={{ fontWeight: 700 }}>Service Due:</span>
                    <span>{d.recommendedServices[0].months} months</span>
                  </div>
                </GridItem>
                <GridItem className={classes.recommendedMaintenance} xs={6}>
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "17px",
                      paddingBottom: "20px"
                    }}
                  >
                    Recommended Maintenance
                  </span>
                </GridItem>
                <GridItem className={classes.recommendedMaintenance} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    style={{ background: "#323232", color: "white" }}
                    className={classes.addEditButtonSmaller}
                  >
                    <WhiteEditIcon />
                    Edit
                  </Button>
                </GridItem>
                <GridItem className={classes.recommendedMaintenance} xs={12}>
                  {d.recommendedServices.map((s, index) => {
                    return (
                      <GridContainer className={classes.dumpsterGridContainer} key={index}>
                        <GridItem
                          className={classes.recommendedServicesYellow}
                          xs={3}
                          key={index}
                        >
                          <span>{s.months} Months</span>
                        </GridItem>
                        <GridItem
                          className={classes.recommendedServicesBlue}
                          xs={9}
                        >
                          <span>{s.service}</span>
                        </GridItem>
                      </GridContainer>
                    );
                  })}
                </GridItem>
                <GridItem xs={12} className={classes.recommendedMaintenance}>
                  {d.recommendedServices[0].months === "2" ? (
                    <div
                      style={{ background: "#F83030" }}
                      className={classes.conditionDiv}
                    >
                      Repair Needed
                    </div>
                  ) : (
                    <div
                      style={{ background: "#2EB67D" }}
                      className={classes.conditionDiv}
                    >
                      Good Condition
                    </div>
                  )}
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        );
      })}
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(serviceRemindersStyle)
)(React.memo(DumpsterServicesMap));
