const initState = {
  globalSearchOpen: false,
  globalCalulcatorOpen: false,
  globalDumpsterOpen: false,
  globalAddOpen: false,
};

export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_GLOBAL_SEARCH":
      console.log("setttting", action.boolean);
      return {
        ...state,
        globalSearchOpen: action.boolean,
        globalCalulcatorOpen: false,
        globalDumpsterOpen: false,
        globalAddOpen: false
      };
    case "SET_GLOBAL_CALCULATOR":
      console.log("setttting", action.boolean);
      return {
        ...state,
        globalSearchOpen: false,
        globalCalulcatorOpen: action.boolean,
        globalDumpsterOpen: false,
        globalAddOpen: false
      };
    case "SET_GLOBAL_DUMPSTER":
      console.log("settttingdumpster", action.boolean);
      return {
        ...state,
        globalSearchOpen: false,
        globalCalulcatorOpen: false,
        globalDumpsterOpen: action.boolean,
        globalAddOpen: false
      };
      case "SET_GLOBAL_ADD":
      console.log("settttingadd", action.boolean);
      return {
        ...state,
        globalSearchOpen: false,
        globalCalulcatorOpen: false,
        globalDumpsterOpen: false,
        globalAddOpen: action.boolean
      };
    default:
      return state;
  }
}
