export function isReady(state) {
    return state.system && state.system.ready;
}

const initState = { ready: false };

export default (state = initState, action) => {
    switch (action.type) {
        case "SYSTEM_SET_READY":
            return {
                ...state,
                ready : action.ready
            }
        default:
            return state;
    }
}
