import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";

const headers = [
  "Job Details",
  "Scheduled",
  "End Date",
  "Site Address",
  "Status",
  "Actions",
];

function JobsTable(props) {
  const { classes, jobsData, reservation, history } = props;
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };
  console.log("jobsData", jobsData);
  const renderTableData = () => {
    return jobsData.map((d, index) => {
      return (
        <tr className={classes.tableRow} key={d.jobNumber}>
          <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
              }}
            >
              <div style={{ display: "flex" }}>
                {d.jobId ? (
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/jobs/${d.jobId}`,
                        state: { job: d },
                      })
                    }
                    style={{ color: "#0071C5", textDecoration: "underline" }}
                  >
                    {d.jobNumber}{" "}
                  </span>
                ) : (
                  <span>N/A</span>
                )}
              </div>
              <span>
                Driver:
                {d.reservation.reservation.jobs.delivery.jobId &&
                d.reservation.reservation.jobs.delivery.jobId === d.jobId
                  ? d.reservation.reservation.jobs.delivery.driverName
                  : d.reservation.reservation.jobs.pickup.driverName}
              </span>
            </div>
          </td>
          <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
            {reservation.reservation.startDate}
          </td>
          <td className={classes.tableCell}>
            {" "}
            {reservation.reservation.endDate}
          </td>
          <td className={classes.tableCell}>
            {reservation.reservation.siteAddress}
          </td>
          <td className={classes.tableCell}>
            <div
              className={classes.statusDiv}
              key={index}
              style={
                reservation.reservation.status === "Cancelled"
                  ? {
                      background: "#F83030",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                      borderRadius: "8px",
                      color: "white",
                      padding: "12px",
                      display: "flex",
                      margin: "0px 10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : reservation.reservation.status === "Finished"
                  ? {
                      background: "#2EB67D",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                      borderRadius: "8px",
                      color: "white",
                      padding: "12px",
                      display: "flex",
                      margin: "0px 10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : reservation.reservation.status === "Unassigned"
                  ? {
                      background: "#323232",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                      borderRadius: "8px",
                      color: "white",
                      padding: "12px",
                      display: "flex",
                      margin: "0px 10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : reservation.reservation.status === "Scheduled"
                  ? {
                      background: "#E01E5A",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                      borderRadius: "8px",
                      color: "white",
                      padding: "12px",
                      display: "flex",
                      margin: "0px 10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : {
                      background: "#E0AC12",
                      color: "#323232",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                      borderRadius: "8px",
                      padding: "12px",
                      display: "flex",
                      margin: "0px 10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }
              }
            >
              {reservation.reservation.status}
            </div>
          </td>
          <td className={classes.tableCell}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <Tooltip
                id="tooltip-top"
                title="View"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{ background: "#FFC720" }}
                  className={classes.actionBtn}
                >
                  <EyeIcon />
                </Button>
              </Tooltip>
              <Tooltip
                id="tooltip-top"
                title="Edit"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#ECB22E" }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem xs={12}>
        <table className={classes.inventoryTable}>
          <tbody>
            {renderTableHeader()}
            {renderTableData()}
          </tbody>
        </table>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(JobsTable));
