import { firebase, db } from "firebase/client";

const inventoryCollection = db.collection("inventory");

export const listenToInventory = () => {
  return (dispatch) => {
    inventoryCollection.orderBy("sortOrder").onSnapshot((snapshot) => {
      const inventory = snapshot.docs.map((d) => d.data());
      dispatch(setInventory(inventory));
    });
  };
};

export const setInventory = (inventory) => {
  return {
    type: "SET_INVENTORY",
    inventory,
  };
};
