import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
import { CSVLink } from "react-csv";
import { db } from "firebase/client";
// core components
import Checkbox from "@material-ui/core/Checkbox";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";

import CustomerViewInvoicesFilter from "./CustomerViewInvoicesFilter";
import CustomerViewInvoicesTables from "./CustomerViewInvoicesTables";
import { filterDataWithStatus } from "../../../../globalHelperFunctions/globalHelperFunctions";

import { MenuItem, Select } from "@material-ui/core";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

const pageArr = ["4", "All"];
const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Comments", key: "comments" },
  { label: "Company", key: "company" },
  { label: "Customer Address", key: "billingAddress" },
  { label: "Customer ID", key: "customerId" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phoneNumber" },

  { label: "Cost Of Rental", key: "amount" },
  { label: "Cost Paid", key: "amountPaid" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Inventory Needed", key: "equiptment" },
  { label: "Dropoff Location", key: "equiptmentLocaion" },
  { label: "Existing Customer", key: "existingCustomer" },

  { label: "Referred from", key: "heardAboutUsFrom" },
  { label: "Last Emailed", key: "lastEmailedDate" },
  { label: "Notes", key: "notes" },
  { label: "Payment Due Date", key: "paymentDueDate" },
  { label: "PO #", key: "poNumber" },
  { label: "Quantity", key: "quantity" },
  { label: "Rental ID", key: "reservationId" },

  { label: "Seven Day Rental", key: "sevenDayReservation" },
  { label: "Site Address", key: "siteAddress" },
  { label: "Rental Status", key: "status" },
  { label: "Recurring Rental", key: "recurring" },
];
const statementOptions =
  [
    "Statement",
    "Email Detail Statement",
    "Email Summary Statement"
  ];

function CustomerViewInvoices(props) {
  const { classes, history, reservationsData } = props;
  const [sortBySelection, setSortBySelection] = useState("");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterSelection, setFilterSelection] = useState("Invoice Date");
  const [searchString, setSearchString] = useState("");
  const [reservations, setReservations] = useState([]);
  const itemsPerPage = 4;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("4")
  const [invoicesState, setInvoicesState] = useState([]);
  const [checkedCount, setCheckedCount] = useState(0);
  const [checkedAll, setCheckedAll] = useState(false);
  const [statementSelection, setStatementSelection] = useState("Statement");

  useEffect(() => {
    // if (!invoicesState) {
    const getInvoices = async () => {
      return db
        .collection("invoices")
        .get()
        .then((snapshots) => {
          if (!snapshots.empty) {
            const allInvoices = [];
            const customerPromiseArray = [];
            snapshots.forEach((s, index) => {
              const invoiceIndex = index;
              s = s.data();
              allInvoices.push(s);
              customerPromiseArray.push(
                db.collection("customers").doc(s.customerId).get()
              );
            });
            return Promise.all(customerPromiseArray).then((results) => {
              results.forEach((snapshot, index) => {
                snapshot = snapshot.data();
                allInvoices[index] = {
                  ...allInvoices[index],
                  customerInfo: snapshot,
                };
              });
              setInvoicesState(allInvoices);
            });
          }
          else {
            setInvoicesState([]);
          }
        });
    };
    getInvoices();
    // }
  }, []);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)
  };

  useEffect(() => {
    reservations.slice(index, endIndex)
  }, [index])

  useEffect(() => {
    setReservations(reservationsData);
  }, [reservationsData]);

  const handleFilters = (type, filterValue) => {
    if (type === "filter") {
      const filtered = filterDataWithStatus(
        filterValue,
        directionSelection,
        sortBySelection,
        reservationsData
      );
      setFilterSelection(filterValue);
      setReservations([...filtered]);
    }
    if (type === "direction") {
      const filtered = filterDataWithStatus(
        filterSelection,
        filterValue,
        sortBySelection,
        reservationsData
      );
      setDirectionSelection(filterValue);
      setReservations([...filtered]);
    }
    if (type === "status") {
      const filtered = filterDataWithStatus(
        filterSelection,
        directionSelection,
        filterValue,
        reservationsData
      );
      setSortBySelection(filterValue);
      setReservations([...filtered]);
    }
  };

  const handleSearch = () => {
    if (searchString) {
      const filtered = reservations.filter((r) => {
        if (
          r.name.toLowerCase().includes(searchString.toLowerCase()) ||
          r.billingAddress.toLowerCase().includes(searchString.toLowerCase()) ||
          r.company.toLowerCase().includes(searchString.toLowerCase()) ||
          r.email.toLowerCase().includes(searchString.toLowerCase()) ||
          r.phoneNumber.toLowerCase().includes(searchString.toLowerCase()) ||
          r.reservation.reservationId
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          return true;
        }
      });
      console.log("filtered", filtered);
      setReservations([...filtered]);
    } else {
      setReservations([...reservationsData]);
    }
  };

  return (
    <Card className={classes.card}>
      <GridContainer>
        <GridItem xs={2}>
          <h5 className={classes.rentalsSubheader}>
            {reservationsData.length} Rentals
          </h5>
        </GridItem>
        <GridItem xs={8} style={{ marginTop: "-10px" }}>
          <CustomerViewInvoicesFilter
            sortBySelection={sortBySelection}
            setSortBySelection={setSortBySelection}
            directionSelection={directionSelection}
            setDirectionSelection={setDirectionSelection}
            setFilterSelection={setFilterSelection}
            filterSelection={filterSelection}
            searchString={searchString}
            setSearchString={setSearchString}
            handleFilters={handleFilters}
            handleSearch={handleSearch}
            checkedAll={checkedAll}
            setCheckedAll={setCheckedAll}
            checkedCount={checkedCount}
            invoicesState={invoicesState}
          />
        </GridItem>
        <GridItem xs={2}>
          <CSVLink
            filename={"Rentals_Data"}
            data={reservationsData.map((x) => ({
              name: x.name,
              comments: x.comments,
              company: x.company,
              billingAddress: x.billingAddress,
              customerId: x.customerId,
              email: x.email,
              phoneNumber: x.phoneNumber,
              amount: x.reservation.amount,
              amountPaid: x.reservation.amountPaid,
              startDate: x.reservation.startDate,
              endDate: x.reservation.endDate,
              equiptment: x.reservation.equiptment,
              equiptmentLocaion: x.reservation.equiptmentLocaion,
              existingCustomer: x.reservation.existingCustomer,
              heardAboutUsFrom: x.reservation.heardAboutUsFrom,
              lastEmailedDate: x.reservation.lastEmailedDate,
              notes: x.reservation.notes,
              paymentDueDate: x.reservation.paymentDueDate,
              poNumber: x.reservation.poNumber,
              quantity: x.reservation.quantity,
              reservationId: x.reservation.reservationId,
              sevenDayReservation: x.reservation.sevenDayReservation,
              siteAddress: x.reservation.siteAddress,
              status: x.reservation.status,
              recurring: x.reservation.recurring,
            }))}
            headers={csvHeaders}
          >
            <Button
              style={{
                width: "80%",
                color: "#ffffff",
                background: "#323232",
                fontSize: "17px"
              }}
              className={classes.addCustomer}>
              Export CSV
            </Button>
          </CSVLink>
        </GridItem>
        <GridItem xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              onChange={() => setCheckedAll((prevState) => !prevState)}
              checked={checkedAll}
            />
            <span className={classes.selectAll}>
              Select All ({checkedCount} of {invoicesState.length})
            </span>

            <Select
              style={{ height: "45px", fontFamily: "Archivo", width: "15%" }}
              className={classes.selectMenuSort}
              value={statementSelection}
              disableUnderline
              classes={{
                root: classes.selectText,
              }}
              onChange={(e) => setStatementSelection(e.target.value)}
            >
              {statementOptions.map((o, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={o}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {o}
                  </MenuItem>
                );
              })}
            </Select>
          </div>

        </GridItem>
        <GridItem xs={12} style={{ marginTop: "10px" }}>
          <CustomerViewInvoicesTables
            history={history}
            reservations={reservations}
            pageDropdown={pageDropdown}
            itemsPerPage={itemsPerPage}
            pageNumber={pageNumber}
            checkedAll={checkedAll}
            setCheckedAll={setCheckedAll}
            checkedCount={checkedCount}
            setCheckedCount={setCheckedCount}
            invoicesState={invoicesState}
            setInvoicesState={setInvoicesState}
          />
        </GridItem>
        {pageDropdown == "4" ?
          <GridContainer style={{ paddingTop: "20px" }}>
            <GridItem xs={6} >
              <p className={classes.view}>View
                <span>
                  <Select
                    style={{ width: "10%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                    disableUnderline
                    MenuProps={{
                      // className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor
                    }}

                    onChange={(e) => {
                      setPageDrowdown(e.target.value)
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginTop: "5px" }}>
              <h5 className={classes.indexes}>{index + 1} - {endIndex} of
                {reservations.length < itemsPerPage ? " " + reservations.length :
                  " " + Math.ceil(reservations.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(reservations.length / itemsPerPage)}
                nextLabel={<ArrowRight style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                previousLabel={<ArrowLeft style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
          :
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>View
                <span>
                  <Select
                    style={{ width: "10%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                    disableUnderline
                    MenuProps={{
                      // className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value)
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        }
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(CustomerViewInvoices));
