const upcomingPaymentsStyle = (theme) => ({
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px",
    textAlign: "left"
  },
  tableRow: {
    height: "55px",
  },
  upcomingPaymentsGridItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px"
  },
  upcomingPaymentsGridItemEnd: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    justifyContent: 'end'
  },
  tableHeaders: {
    border: "0.5px solid #323232",
    background: "#D9D9D9",
    color: "#323232",
    fontFamily: "Archivo",
    fontSize: "17px",
    textTransform: "uppercase"
  },
  tableCell: {
    textAlign: "center",
    border: "0.5px solid #323232",
    padding: "7px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "19px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "7px",
      fontSize: "14px",
    },
  },
  searchGridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'end'
  },
  inventoryHeaderGridItem: {
    display: "flex",
    justifyContent: "space-between",
    height: "100px"
  },
  inventoryTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    // [theme.breakpoints.down("md")]: {
    //   tableLayout: "fixed",
    //   width: "100%",
    // },
  },
  actionBtn: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "15px",
    height: "50px",
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
  },
  paymentsDue: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
  },
  searchBar: {
    width: "60%",
    background: "white",
    borderRadius:"4px"
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "16px 30px !important",
    color: "#323232",
    "&:hover": {
      background: "#FFC720",
    },
  },
  arrowIcon: {
    fontSize: "62px"
  }
});

export default upcomingPaymentsStyle;
