import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Datetime from "react-datetime";
import moment from "moment";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Button, Checkbox } from "@material-ui/core";

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

function QuoteJobCard(props) {
  const {
    classes,
    setNotes,
    notes,
    errors,
    recurring,
    setRecurring,
    imageFile,
    setImageFile,
    recurrRental,
    setRecurrRental,
    promoCode,
    setPromoCode
  } = props;

  const onImageChange = (e) => {
    setImageFile(e.target.files[0])
  }

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle} style={{ fontSize: "25px" }}>
            Useful Photos for Rental{" "}
          </span>

          <p style={{
            fontWeight: 400,
            fontSize: "14px"
          }}
            className={classes.inputTypeTitle}
          >
            Exact location of the dumpster, pictures helpful for directions, etc.
            <br />
            <br />
            Here are a few things to keep in mind before attaching files: <br />
            • Each individual file must be less than 6MB in size<br />
            • For documents that will be sent as email attachments, such as when you send an invoice, estimate or credit memo by email, the combined size of all attachments together must be less than 20MB
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              style={{
                color: "#929292",
              }}
              checked={recurring}
              onChange={() => {
                setRecurrRental(!recurrRental)
                setRecurring(!recurring)
              }}
            />
            <span
              className={classes.inputTypeTitle}
              style={{
                fontWeight: 400,
                marginTop: "10px"
              }}
            >
              Recurring Rental
            </span>
          </div>

          <p className={classes.inputTypeTitle} style={{ marginTop: "20px", fontSize: "14px", fontWeight: 400 }}>
            Checking this means you are requesting regular pick up and drop offs.
          </p>
        </GridItem>
        <GridItem
          className={classes.columnGridItem}
          xs={4}
          style={{
            alignItems: "center"
          }}>
          <Button
            className={classes.addCustomer}
            component="label"
            variant="outlined"
          >
            <AddIcon /> Add Attachment<input type="file" hidden onChange={onImageChange} />
          </Button>
          {imageFile ? <p>{imageFile.name}</p> : null}
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle} style={{ fontSize: "25px" }}>
            Other Information
          </span>
          <TextareaAutosize
            aria-label="empty textarea"
            className={classes.emailInput}
            onChange={(e) => setNotes(e.target.value)}
            placeholder=""
            style={{ maxWidth: "100%", minHeight: "100px" }}
          />

          <span className={classes.inputTypeTitle} style={{ fontSize: "25px", marginTop: "20px" }}>
            Promo Code
          </span>
          <TextField
            className={classes.searchBar}
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            variant="outlined"
          />
        </GridItem>

      </GridContainer>
    </Card >
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(QuoteJobCard));
