import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import settingsStyle from "assets/jss/material-dashboard-pro-react/views/settingsStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Tooltip from "@material-ui/core/Tooltip";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as CheckIcon } from "assets/img/GreenCheckIcon.svg";

function QuickbooksData(props) {
    const { classes, history, userInfo } = props;

    return (
        <GridContainer>
            <GridItem xs={6}>
                <h1 style={{ marginTop: "30px", marginBottom: "10px" }} className={classes.title}>Connect QuickBooks</h1>
                <h4 style={{
                    fontWeight: 400,
                    fontSize: "18px",
                    fontFamily: "Archivo",
                    fontStyle: "normal",
                }}>Connecting your QuickBooks software to ServiceCore saves time and eliminates double data entry.</h4>
            </GridItem>
            <GridItem xs={6}>
                <h1 style={{ marginTop: "30px", marginBottom: "10px" }} className={classes.title}>Support?</h1>
                <h4 style={{
                    fontWeight: 400,
                    fontSize: "18px",
                    fontFamily: "Archivo",
                    fontStyle: "normal",
                }}>
                    Have any questions?
                </h4>
                <h4 style={{
                    fontWeight: 400,
                    fontSize: "18px",
                    fontFamily: "Archivo",
                    fontStyle: "normal",
                    textDecoration: "underline",
                    color: "#0071C5"
                }}>
                    Contact Us
                </h4>
            </GridItem>
            <GridItem xs={12}>
                <h1 style={{ marginTop: "15px", marginBottom: "10px" }} className={classes.title}>QuickBooks Online</h1>
                <h4 style={{ fontWeight: 700, }}>ServiceCore offers a full 2-way sync with QuickBooks Online</h4>
                <Button
                    style={{
                        height: "45px",
                        background: "#FFC821",
                        fontWeight: 700,
                        textTransform: "capitalize",
                        whiteSpace: "noWrap",
                        marginRight: "0px"
                    }}
                    className={classes.actionBtn}
                >
                    Connect to QuickBooks
                </Button>
                <h1 style={{ display: 'flex', marginTop: "20px", marginBottom: "10px" }} className={classes.accountActivated}>
                    <CheckIcon style={{ fill: "green", marginRight: "20px" }} /> Your account activation was successful!
                </h1>
                <h4 style={{
                    fontWeight: 400,
                    fontSize: "18px",
                    fontFamily: "Archivo",
                    fontStyle: "normal",
                }}>
                    If you need to reauthorize your account, click the green button.
                </h4>

            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        customersData: state.customers.customers,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(settingsStyle)
)(React.memo(QuickbooksData));
