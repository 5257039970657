export function getDialog(state, reducer, dialog) {
    return (state[reducer] && state[reducer][dialog]) || {};
}

export function isActive(state, reducer, dialog) {
    return !!(getDialog(state, reducer, dialog).active);
}

export function bindReducerAndDialog(reducer, dialog, accessor) {
    return (state, ...rest) => accessor(state, reducer, dialog, ...rest);
}

export function getAccessorsForDialog(reducer, dialog) {
    return {
        isActive: bindReducerAndDialog(reducer, dialog, isActive),
        get: bindReducerAndDialog(reducer, dialog, getDialog)
    }
}

const initState = {};

export default (state = initState, action) => {
    switch (action.type) {
        case "DIALOG_OPEN":
            return {
                ...state,
                [action.dialog] : { ...state[action.dialog], active: true }
            }
        case "DIALOG_CLOSE":
            return {
                ...state,
                [action.dialog] : { ...state[action.dialog], active: false }
            }
        default:
            return state;
    }
}
