import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import settingsStyle from "assets/jss/material-dashboard-pro-react/views/settingsStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Tooltip from "@material-ui/core/Tooltip";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as CheckIcon } from "assets/img/GreenCheckIcon.svg";

function CCProcessingTab(props) {
  const { classes, history, userInfo } = props;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h1 style={{marginTop: "30px"}} className={classes.title}>Credit Card Processing</h1>
      </GridItem>
      <GridItem xs={12}>
        <h1 style={{display: 'flex'}} className={classes.accountActivated}>
          <CheckIcon style={{ fill: "green", marginRight: "20px" }} /> CardConnect account activated
        </h1>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(settingsStyle)
)(React.memo(CCProcessingTab));
