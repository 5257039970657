import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Card from "components/Card/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import { reportSelections } from "../../config/globals";
import Chart from "./Chart.jsx";

const sortByOptions = ["Total Unpaid", "Scheduled Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];

const viewOptions = ["1 Month", "3 Months", "6 Months", "1 Year"];

const territorySelections = ["Northeast", "Northwest", "Southeast", "Southwest"]

function DumpsterByCostTable(props) {
  const {
    classes,
    agingData,
    history,
    setCheckedCount,
    checkedCount,
    setAgingData,
    setCheckedAll,
    checkedAll,
    inventoryData,
  } = props;
  const [tableSelection, setTableSelection] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [type, setType] = useState("1 Year");
  const [territory, setTerritory] = useState("Northeast")

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem xs={12}>
        <Card style={{ marginTop: "0px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "20px",
              maxHeight: "80px",
            }}
          >
            <div className={classes.jobsGridItem}>
              <span className={classes.switchReport}>Switch Report</span>
              <Select
                variant="outlined"
                className={classes.filterMenu}
                value={tableSelection}
                sx={{ background: "#F5F5F5" }}
                disableUnderline
                defaultValue={"Select Option"}
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => history.push(e.target.value.link)}
                displayEmpty
                renderValue={
                  tableSelection !== "" ? undefined : () => "Dumpster Cost Analysis"
                }
              >
                {reportSelections.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItemReport,
                      }}
                    >
                      {o.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div
              style={{ justifyContent: "space-between" }}
              className={classes.jobsGridItem}
            >
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "80%",
                  marginRight: "10px",
                }}
                selected={fromDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setFromDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      className={classes.datetime}
                      placeholder="From"
                      value={fromDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "80%",
                }}
                selected={toDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setToDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      className={classes.datetime}
                      placeholder="To"
                      value={toDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Button
                style={{
                  margin: "5px 5px 5px 10px"
                }}
                className={classes.exportCSV}
              >
                Export CSV
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "20px",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", width: "100%", height: "60px" }}>
              <div
                style={{
                  display: "flex",
                  border: "2px solid #323232",
                  borderRadius: "5px",
                  background: "#323232",
                  width: "29%",
                  height: "45px",
                }}
              >
                {viewOptions.map((v, index) => {
                  if (v === type) {
                    return (
                      <div
                        key={index}
                        onClick={() => setType(v)}
                        className={classes.viewSelection2}
                        style={{ background: "#323232", color: "white" }}
                      >
                        {v}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        onClick={() => setType(v)}
                        className={classes.viewSelection2}
                        style={{ background: "white", color: "#323232" }}
                      >
                        {v}
                      </div>
                    );
                  }
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  width: "75%",
                  height: "60px",
                  justifyContent: "end",
                  alignItems: "center"
                }}
              >
                <span className={classes.territoryText}>Territory</span>
                <Select
                  variant="outlined"
                  className={classes.territoryMenu}
                  value={territory}
                  sx={{ background: "#F5F5F5" }}
                  disableUnderline
                  defaultValue={"Select Option"}
                  classes={{
                    root: classes.selectText,
                  }}
                  onChange={(e) => setTerritory(e.target.value)}
                >
                  {territorySelections.map((o, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={o}
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        {o}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            </div>

            <div style={{ display: "flex", width: "100%", marginTop: "30px" }}>
              <div style={{ width: "100%" }}>
                {inventoryData && <Chart inventoryData={inventoryData} />}
              </div>
            </div>
          </div>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("state.inventory", state.inventory);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(DumpsterByCostTable));
