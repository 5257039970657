import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import scheduleStyle from "assets/jss/material-dashboard-pro-react/views/scheduleStyle.js";
import Card from "components/Card/Card.js";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ArrowDown } from "assets/img/arrowDown.svg";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";
import { ReactComponent as DeliveryIcon } from "assets/img/deliveryIcon.svg";
import { ReactComponent as SwapIcon } from "assets/img/swapIcons.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import { ReactComponent as BlackMailIcon } from "assets/img/blackMailIcon.svg";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  MouseSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Sortable from "./Dnd/Sortable";
// import Droppable from "components/Dnd/Droppable";
// import Draggable from "components/Dnd/Draggable";
// import Sortable from "components/Dnd/Sortable";
// import loader from "assets/img/loader.gif";
// import UnassignedColumn from "./UnassignedColumn";
import DriverRow from "./DriverRow";
import UnassignedRow from "./UnassignedRow";

import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
function ListSchedule(props) {
  const {
    classes,
    drivers,
    reservationsToday,
    schedule,
    history,
    setReservationsToday,
    weeklyItems,
    setWeeklyItems,
    scheduledDate,
    scheduleYear,
    scheduleMonth,
    changedTable,
    setChangedTable,
    handleDragStart,
    handleDragEnd,
    submittingChanges,
    setSubmittingChanges,
    activeId,
    setActiveId,
    data,
    setData,
  } = props;

  // const scheduledDate = new Date(schedule);
  // const scheduleYear = scheduledDate.getFullYear();
  // const scheduleMonth = scheduledDate.toLocaleString("default", {
  //   month: "short",
  // });
  // const [data, setData] = useState(false);
  // const [loaded, setLoaded] = useState(false);
  // const [changedTable, setChangedTable] = useState(false);
  // const [submittingChanges, setSubmittingChanges] = useState(false);
  // const [activeId, setActiveId] = useState("");
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  let splitSchedule;
  if (schedule.length > 8) {
    splitSchedule = schedule.split(" ")[1];
  } else {
    splitSchedule = schedule;
  }

  return (
    <Card className={classes.listScheduleGridContainer}>
      <DndContext
        sensors={sensors}
        // collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        // onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        autoScroll={false}
      >
        {weeklyItems.unassigned && (
          <UnassignedRow
            classes={classes}
            id="unassigned"
            items={weeklyItems.unassigned.weeklySchedule[splitSchedule]}
            width={`100%`}
            driver={weeklyItems.unassigned}
            history={history}
            itemsState={weeklyItems}
            setWeeklyItems={setWeeklyItems}
            splitSchedule={splitSchedule}
          />
        )}

        {Object.values(weeklyItems)
          .filter((i) => i.name !== "unassigned")
          .map((i, index) => {
            return (
              <DriverRow
                key={index}
                setWeeklyItems={setWeeklyItems}
                width={`100%`}
                items={i.weeklySchedule[splitSchedule]}
                itemsState={weeklyItems}
                classes={classes}
                id={i.driverId}
                driver={i}
                // key={index}
                history={history}
                splitSchedule={splitSchedule}
              />
            );
          })}

        {activeId && data ? (
          <DragOverlay>
            <Sortable
              history={history}
              data={data}
              classes={classes}
              id={activeId}
              reservationId={data.reservation.reservationId}
              reservation={data}
              setWeeklyItems={setWeeklyItems}
              itemsState={weeklyItems}
              splitSchedule={splitSchedule}
              territoryColor={data.reservation.territoryColor}
              dontShowTooltip
            />
          </DragOverlay>
        ) : null}
      </DndContext>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(scheduleStyle)
)(React.memo(ListSchedule));
