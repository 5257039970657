import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";

const headers = [
  "Customer",
  "Billing Address",
  "Contact Info",
  "Comments",
  "Actions",
];

function CustomersTable(props) {
  const { classes, filteredTableData, history, pageDropdown, itemsPerPage, pageNumber } = props;

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if(pageDropdown == "3"){
      return filteredTableData
      .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
      .map((d, index) => {
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              <span
                onClick={() =>
                  history.push({
                    pathname: `/admin/customers/${d.customerId}`,
                    state: { customer: d },
                  })
                }
                className={classes.nameAndCompanyText}
              >
                {" "}
                {d.name}
              </span>{" "}
              |<span className={d.company ? classes.nameAndCompanyText : ''}>{d.company || 'N/A'}</span>
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.billingAddress}
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <span>{d.phoneNumber}</span>
                <span>{d.email}</span>
              </div>
            </td>
            <td className={classes.tableCell}>
              <div style={{ display: "flex", width: "100%" }}>
                {d.comments.map((c, index) => {
                  return (
                    <div
                      key={index}
                      style={
                        c === "Regular"
                          ? { background: "#2EB67D" }
                          : c === "Cautious"
                            ? { background: "#323232" }
                            : c === "Overdue"
                              ? { background: "#F83030" }
                              : c === "New"
                                ? { background: "#0071C5" }
                                : { background: "#ECB22E", color: "#323232" }
                      }
                      className={classes.commentDiv}
                    >
                      {c}
                    </div>
                  );
                })}
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                    onClick={() =>
                        history.push({
                          pathname: `/admin/customers/${d.customerId}`,
                          state: { customer: d },
                        })
                      }
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#ECB22E" }}
                    onClick={() =>
                        history.push({
                          pathname: `/admin/customers/${d.customerId}`,
                          state: { customer: d, edit: true },
                        })
                      }
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    } else {
      return filteredTableData.map((d, index) => {
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
              <span
                onClick={() =>
                  history.push({
                    pathname: `/admin/customers/${d.customerId}`,
                    state: { customer: d },
                  })
                }
                className={classes.nameAndCompanyText}
              >
                {" "}
                {d.name}
              </span>{" "}
              |<span className={d.company ? classes.nameAndCompanyText : ''}>{d.company || 'N/A'}</span>
            </td>
            <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
              {d.billingAddress}
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <span>{d.phoneNumber}</span>
                <span>{d.email}</span>
              </div>
            </td>
            <td className={classes.tableCell}>
              <div style={{ display: "flex", width: "100%" }}>
                {d.comments.map((c, index) => {
                  return (
                    <div
                      key={index}
                      style={
                        c === "Regular"
                          ? { background: "#2EB67D" }
                          : c === "Cautious"
                            ? { background: "#323232" }
                            : c === "Overdue"
                              ? { background: "#F83030" }
                              : c === "New"
                                ? { background: "#0071C5" }
                                : { background: "#ECB22E", color: "#323232" }
                      }
                      className={classes.commentDiv}
                    >
                      {c}
                    </div>
                  );
                })}
              </div>
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${d.customerId}`,
                        state: { customer: d },
                      })
                    }
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#ECB22E" }}
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${d.customerId}`,
                        state: { customer: d, edit: true },
                      })
                    }
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div style={pageDropdown != 3 && filteredTableData.length > 3 ? { height: "420px", width: "100%", overflowY: "scroll" } : null}>
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(CustomersTable));
