import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.js";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIconBlack.svg";
import { ReactComponent as CheckIcon } from "assets/img/checkIconGreenBg.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconSmall.svg";
import { BsFillCircleFill } from "react-icons/bs";
import moment from 'moment'
import { Link } from "react-router-dom";
import { Button, IconButton } from "@material-ui/core";

const formatDate = (value) => {
  if (value) {
    return moment(value).format("MM/DD/YY");
  }
}

function CustomerDetails(props) {
  const { classes, companyInfo, history } = props;
  console.log('companyInfo', companyInfo)
  return (
    <GridContainer>
      <GridItem xs={9}>
        <Card className={classes.customerInfoLeftCard}>
          <GridContainer className={classes.mainGrid}>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Name </span>
              <span className={classes.categoryValue}>
                {companyInfo.companyName}
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Company Type</span>
              <span className={classes.categoryValue}>
                {companyInfo.industryType}
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Phone(Primary)</span>
              <span className={classes.categoryValue}>{companyInfo.primaryPhone}</span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Phone(Secondary)</span>
              <p className={classes.categoryValue}>
                {companyInfo.secondaryPhone ? "(" + companyInfo.secondaryPhone.slice(0, 3) + ") " +
                  companyInfo.secondaryPhone.slice(4, 7) + "-" +
                  companyInfo.secondaryPhone.slice(7, 12) : "N/A"}
              </p>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={4}
            >
              <span className={classes.categoryTitle} >Main emails
                <IconButton style={{ marginTop: "-5px", marginLeft: "5px", background: "#FFC821", width: "26px", height: "26px", padding: "7px" }}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </span>
              <span className={classes.categoryValue} style={{ textDecoration: "underline" }}>
                {companyInfo.primaryEmailAddress || 'N/A'}
              </span>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={4}
            >
              <span className={classes.categoryTitle}>Communicate VIA</span>
              <span className={classes.categoryValue}>
                {companyInfo.preferredCommunication || 'N/A'}
              </span>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={4}
            >
              <span className={classes.categoryTitle}>Customer Since</span>
              <span className={classes.categoryValue}>
                {companyInfo.company || 'N/A'}
              </span>
            </GridItem>
            {/* <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={4}
            >
              <span className={classes.categoryTitle}>Communication Via</span>
              <span className={classes.categoryValue} style={{ whiteSpace: "noWrap" }}>
                Email
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={4}
            >
              <span className={classes.categoryTitle}>Customer Since</span>
              <span className={classes.categoryValue}>
                Friend
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Communicate Via</span>
              <span className={classes.categoryValue}>Email</span>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Billing Address</span>
              <span className={classes.categoryValue}>
                {companyInfo.billingAddress || companyInfo.address}
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Emails
                <Button style={{ marginTop: "-5px", marginLeft: "-10px" }}>
                  <EditIcon />
                </Button>
              </span>
              <span className={classes.categoryValue}>
                {companyInfo.email || companyInfo.emails}
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Phone (Primary)</span>
              <span className={classes.categoryValue}>
                {companyInfo.phoneNumber ? "(" + companyInfo.phoneNumber.slice(0, 3) + ") " +
                  companyInfo.phoneNumber.slice(3, 6) + "-" +
                  companyInfo.phoneNumber.slice(6, 10) : "N/A"}

              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Phone (Secondary)</span>
              <span className={classes.categoryValue}>
                {companyInfo.secondaryPhoneNumber
                  ? companyInfo.secondaryPhoneNumber
                  : "N/A"}
              </span>
            </GridItem> */}
          </GridContainer>
        </Card>
      </GridItem>
      <GridItem xs={3}>
        <Card className={classes.customerInfoRightCard}>
          <GridContainer className={classes.mainGrid}>
            <GridItem
              className={classes.column + " " + classes.addedPaddingCompany}
              xs={6}
            >
              <span className={classes.categoryTitle} style={{ whiteSpace: "pre-wrap" }}>In Progress{"\n"}Rentals</span>
              {/* <span className={classes.categoryValueUnderline}
                onClick={() =>
                  history.push({
                    pathname: `/admin/accounting`,
                    state: { tabIndex: 2 },
                  })}
              >
                2 Remaining
              </span> */}
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPaddingCompany}
              xs={6}
            >
              <span className={classes.categoryValueUnderline} style={{ display: "flex", justifyContent: "flex-end" }}>See More</span>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPaddingCompany}
              xs={12}
            >
              <GridContainer className={classes.companyBorderBottom}>
                <GridItem xs={6}>
                  <span className={classes.categoryTitleCompany}>
                    Pick Up: 30 YD
                  </span>
                  <span className={classes.categoryValueUnderlineCompany}
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${companyInfo.customerId}`,
                        state: { tabIndex: 5 },
                      })}
                  >
                    J2342
                  </span>
                </GridItem>

                <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div className={classes.companyBlueDiv}>
                    Began 10/20/2022 08:23AM
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPaddingCompany}
              xs={12}
            >
              <GridContainer className={classes.companyBorderBottom}>
                <GridItem xs={6}>
                  <span className={classes.categoryTitleCompany}>
                    Pick Up: 30 YD
                  </span>
                  <span className={classes.categoryValueUnderlineCompany}
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${companyInfo.customerId}`,
                        state: { tabIndex: 5 },
                      })}
                  >
                    J2342
                  </span>
                </GridItem>

                <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div className={classes.companyBlueDiv}>
                    Began 10/20/2022 08:23AM
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPaddingCompany}
              xs={12}
            >
              <GridContainer className={classes.companyBorderBottom}>
                <GridItem xs={6}>
                  <span className={classes.categoryTitleCompany}>
                    Pick Up: 30 YD
                  </span>
                  <span className={classes.categoryValueUnderlineCompany}
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${companyInfo.customerId}`,
                        state: { tabIndex: 5 },
                      })}
                  >
                    J2342
                  </span>
                </GridItem>

                <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div className={classes.companyBlueDiv}>
                    Began 10/20/2022 08:23AM
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>

          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,

  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(CustomerDetails));
