const initState = {
    user: {},
    authenticated: false
}
  
export default function reduce(state = initState, action) {
    switch (action.type) {
      case 'AUTH_SET_USER':
        return {
            ...state,
            user: action.user,
            error: undefined,
            authenticated: true
        }
      case 'AUTH_UPDATE_USER':
        return {
          ...state,
          user: action.user,
          error: undefined,
          authenticated: true
        }
      case 'AUTH_SET_ERROR':
        return {
            ...state,
            user: {},
            error: action.error,
            authenticated: false
        }
      case 'AUTH_LOGOUT':
        return {
            ...state,
            user: {},
            error: undefined,
            authenticated: false
        }
        default:
        return state
    }
  }
  