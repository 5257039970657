import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { addPendingReservationExistingCustomer } from "store/actions/reservations/reservationsActions";
import { getOneWeekLater } from "../../Dashboard/helpers/helpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import pointInPolygon from "point-in-polygon";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Submitted from "./Submitted";
import moment from "moment";

function NewFormStepOne(props) {
  const {
    classes,
    customerInfo,
    inventoryData,
    handleAddPendingReservationExistingCustomer,
    territoriesData,
    communicationPreference,
    setCommunicationPreference,
    nextStep,
    phoneNumber,
    handlePhoneNumberChange,
    errors,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    quoteType,
    setQuoteType,
    reserveTypeErr,
    setReserveTypeErr,
    setTextOrEmail,
    textOrEmail,
  } = props;

  return (
    <Card className={classes.card}>
      <h5 className={classes.formTitle}>
        Type of Quote:{" "}
        {reserveTypeErr === true ? (
          <span style={{ color: "red" }}>Required*</span>
        ) : (
          <div></div>
        )}
      </h5>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "30px",
          }}
        >
          <Checkbox
            checked={quoteType.individual}
            style={{
              color: "#FFC720",
            }}
            onChange={() => {
              setQuoteType({ individual: true, business: false });
            }}
          />
          <span>Individual</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            style={{
              color: "#FFC720",
            }}
            checked={quoteType.business}
            onChange={() => setQuoteType({ individual: false, business: true })}
          />
          <span>Business</span>
        </div>
      </div>
      <h5 className={classes.formTitle}>
        How would you like to receive this quote?{" "}
        {errors.textOrEmail ? (
          <span style={{ color: "red" }}>Required*</span>
        ) : (
          <div></div>
        )}
      </h5>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "30px",
          }}
        >
          <Checkbox
            checked={textOrEmail.text}
            style={{
              color: "#FFC720",
            }}
            onChange={() => {
              setTextOrEmail({ text: true, email: false });
            }}
          />
          <span>Text</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            style={{
              color: "#FFC720",
            }}
            checked={textOrEmail.email}
            onChange={() => setTextOrEmail({ text: false, email: true })}
          />
          <span>Email</span>
        </div>
      </div>
      <h5 className={classes.formTitle}>
        Contact Information:{" "}
        {errors.phoneNumber ||
        errors.firstName ||
        errors.lastName ||
        errors.email ? (
          <span style={{ color: "red" }}>Required*</span>
        ) : (
          <div></div>
        )}
      </h5>
      <TextField
        className={classes.emailInput}
        value={email}
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={firstName}
        placeholder="First Name"
        onChange={(e) => {
          if (e.target.value.length === 1) {
            setFirstName(e.target.value.toUpperCase());
          } else {
            setFirstName(e.target.value);
          }
        }}
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={lastName}
        onChange={(e) => {
          if (e.target.value.length === 1) {
            setLastName(e.target.value.toUpperCase());
          } else {
            setLastName(e.target.value);
          }
        }}
        variant="outlined"
        placeholder="Last Name"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        variant="outlined"
        placeholder="Phone Number"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />
      {!phoneNumber ||
      !quoteType ||
      !textOrEmail.email && !textOrEmail.text  ||
      !firstName ||
      !lastName ||
      !email ? (
        <Button
          disabled
          style={{ background: "#FFC821", color: "#323232" }}
          onClick={() => nextStep()}
        >
          Go Next
        </Button>
      ) : (
        <Button
          style={{ background: "#FFC821", color: "#323232" }}
          onClick={() => nextStep()}
        >
          Go Next
        </Button>
      )}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  handleAddPendingReservationExistingCustomer: (pendingRes) =>
    dispatch(addPendingReservationExistingCustomer(pendingRes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(NewFormStepOne));
