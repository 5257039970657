const modalsStyle = (theme) => ({
  modalCard: {
    margin: "0px",
    background: "rgb(243, 250, 255)",
  },
  modalTitle: {
    textAlign: "center",
    background: "#F1C84E !important",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "30px",
    margin: "0px",
    padding: "30px",
  },
  // card: {
  //   width: "500px",
  //   height: "100%",
  //   background: "#FFFFFF",
  //   boxShadow: "0px 14px 35px rgba(49, 49, 49, 0.08)",
  //   borderRadius: "12px",
  //   padding: "0px 30px 30px 30px !important",
  //   zIndex: 500,
  // },
  modalCardBody: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    flexDirection: "column",
  },
  modalCardButton: {
    fontWeight: 700,
    width: "100%",
    height: "60px",
    color: "#323232",
    "&:hover": {
      cursor: "pointer",
    },
    borderRadius: "5px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)"
  },
  modalCustomerButton: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    width: "20%",
    height: "42px",
    color: "#323232",
    background: "#FFC821",
    "&:hover": {
      cursor: "pointer",
      background: "#FFC821",
      color: "#323232",
    },
    borderRadius: "5px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    textTransform: "capitalize"
  },
  title: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "33px",
    color: "#323232",
    textAlign: "center",
  },
  formTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "33px",
    color: "#323232",
  },
  card: {
    width: "500px",
    height: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 14px 35px rgba(49, 49, 49, 0.08)",
    borderRadius: "12px",
    padding: "30px",
    zIndex: 500,
  },
  emailInput: {
    background: "#F5F5F5",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    width: "100%",
    marginBottom: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "33px",
    color: "#323232",
  },
  grayedOutDatetime: {
    background: "#F5F5F5",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    width: "100%",
    marginBottom: "20px",
    position: "relative",
    borderRadius: "4px",
    color: "gray !important",
    cursor: "text",
    display: "inline-flex",
    fontSize: "1rem",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    border: "1px solid lightgray",
    padding: "15px",
  },
  datetime: {
    background: "#F5F5F5",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    width: "100%",
    marginBottom: "20px",
    position: "relative",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "text",
    display: "inline-flex",
    fontSize: "1rem",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    border: "1px solid lightgray",
    padding: "15px",
  },
  filterMenu: {
    background: "#F5F5F5",
    border: "1px solid #C7C7C7",
    borderRadius: "1px",
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "19px 30px !important",
    color: "#323232",
    "&:hover": {
      background: "#FFC720",
    },
  },
  sendBtn: {
    background: "#323232",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    height: "70px",
    color: "#FFC720",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "33px",
    padding: "20px",
    "&:hover": {
      cursor: "pointer",
      background: "#323232",
      color: "#FFC720",
    },
  },
  receivedRequest: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "15px",
    lineHeight: "33px",
    color: "#323232",
    textAlign: "center",
    marginBottom: "30px",
  },
  humptyLogo: {
    margin: "0px auto",
  },
  commentDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    marginRight: "10px",
    width: "30%",
    fontSize: "18px",
    justifyContent: "space-evenly",
  },
  editCustomerCommentDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    marginRight: "10px",
    width: "17%",
    fontSize: "15px",
    justifyContent: "space-evenly",
  },
  viewModalCardButtons: {
    width: "100%",
    height: "60px",
    color: "#323232",
    padding: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "&:hover": {
      cursor: "pointer",
    },
  },
  closeModal: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  divCheckbox: {
    display: "flex",
    fontWeight: 600,
    margin: "-10px 0px 0px -12px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "14px"
  },
  datetimeCustomerSide: {
    background: "white",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    width: "80%",
    position: "relative",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "text",
    display: "inline-flex",
    fontSize: "1rem",
    boxSizing: "border-box",
    fontFamily: "Archivo",
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    border: "1px solid lightgray",
  },
  adournedEndCustomer:{
    paddingRight: "5px !important",
    margin: "0px !important",
    // maxHeight: "none !important",
  }
});
export default modalsStyle;
