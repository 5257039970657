import {
  firebase,
  db,
  sendNewPendingReservationNotification,
  sendInvoiceLink,
} from "firebase/client";
import { createCustomer } from "store/actions/customers/customerActions";

export const listenToCompany = (userInfo) => {
  return (dispatch) => {
    return db
      .collection("companies")
      .doc(userInfo.companyId)
      .onSnapshot((snapshot) => {
        const companyData = snapshot.data();
        console.log("snapshotsnapshot", companyData);
        const staffPromiseArray = [];
        if (companyData.staff.length > 0) {
          companyData.staff.forEach((id) => {
            staffPromiseArray.push(db.collection("users").doc(id).get());
          });
          Promise.all(staffPromiseArray).then((data) => {
            const staffArray = [];
            data.forEach((d) => {
              const userId = d.id;
              const user = d.data();
              user.id = userId;
              staffArray.push(user);
            });
            dispatch(setCompany(companyData, staffArray));
          });
        } else {
          dispatch(setCompany(companyData, []));
        }
      });
  };
};

export const setCompany = (company, staff) => {
  return {
    type: "SET_COMPANY",
    company,
    staff,
  };
};
