import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as DeliveryIcon } from "assets/img/deliveryIcon.svg";
import { ReactComponent as SmallPickupIcon } from "assets/img/smallPickupIcon.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as SwapIcon } from "assets/img/swapIcons.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import { ReactComponent as BlackMailIcon } from "assets/img/blackMailIcon.svg";
import { ReactComponent as AnnouncementIcon } from "assets/img/announcement.svg";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { setMessagingModal } from "store/actions/messaging/messagingActions";

export function Item(props) {
  const {
    id,
    classes,
    data,
    history,
    reservationId,
    searchString,
    splitSchedule,
    hoveredItemId,
    setHoveredItemId,
  } = props;

  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };

  const renderQuantity = (q) => {
    if (typeof q === "object") {
      let returnStr = "";
      Object.entries(q).forEach(([key, value]) => {
        returnStr = returnStr + value + " ";
      });
      return returnStr;
    } else {
      return q;
    }
  };

  if (hoveredItemId && hoveredItemId === reservationId) {
    return (
      <div
        className={classes.hoverSort}
        onMouseOver={() => setHoveredItemId(reservationId)}
        style={
          searchString && !data.name.toLowerCase().includes(searchString)
            ? {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                background: "#FFFFFF",
                border: "1px solid #323232",
                opacity: 0.3,
              }
            : {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                background: "#FFFFFF",
                border: "1px solid #323232",
                borderLeft: `6px solid ${props.territoryColor}`,
              }
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "20%",
            background: "#D9D9D9",
          }}
          id="clickableInput"
        >
          {/* <DragIndicatorIcon /> */}
          <Tooltip
            id="tooltip-top"
            title="Create Swap"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              id="clickableInput"
              onClick={() => console.log("click swap")}
              style={{ background: "#FFC821" }}
              className={classes.scheduleSwapButtons}
            >
              <SwapIcon
                style={{ width: "20px", height: "20px" }}
                id="clickableInput"
              />
            </div>
          </Tooltip>
          <Tooltip
            id="tooltip-top"
            title="Edit Job"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{ background: "#323232" }}
              className={classes.scheduleSwapButtons}
              id="clickableInput"
              onClick={() => {
                if (data.reservation.startDate === splitSchedule) {
                  if (data.reservation.jobs.delivery.jobId) {
                    history.push(
                      `/admin/edit-job/${data.reservation.jobs.delivery.jobId}`
                    );
                  } else {
                    console.log("no delivery job id");
                  }
                }
                if (data.reservation.endDate === splitSchedule) {
                  if (data.reservation.jobs.pickup.jobId) {
                    history.push(
                      `/admin/edit-job/${data.reservation.jobs.pickup.jobId}`
                    );
                  } else {
                    console.log("no pickup job id");
                  }
                }
              }}
            >
              <WhiteEditIcon
                style={{ width: "20px", height: "20px" }}
                id="clickableInput"
              />
            </div>
          </Tooltip>
          <Tooltip
            id="tooltip-top"
            title="Edit Job Schedule"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{ background: "#929292" }}
              className={classes.scheduleSwapButtons}
              id="clickableInput"
            >
              <CalendarIcon
                style={{ width: "20px", height: "20px" }}
                id="clickableInput"
              />
            </div>
          </Tooltip>
          <Tooltip
            id="tooltip-top"
            title="Contact"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              onClick={() => props.setMessagingModal(true)}
              style={{ background: "#F5EB29" }}
              className={classes.scheduleSwapButtons}
              id="clickableInput"
            >
              <AnnouncementIcon
                style={{ width: "20px", height: "20px" }}
                id="clickableInput"
              />
            </div>
          </Tooltip>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
            padding: "5px 8px",
          }}
          className={classes.deliveryTypeAndNote}
        >
          {data.reservation.startDate === splitSchedule ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "25%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <DeliveryIcon />
                <span className={classes.jobType}>Delivery</span>
              </div>
              {data.reservation.notes && (
                // add exclamation point
                <div></div>
              )}
            </>
          ) : data.reservation.endDate === splitSchedule ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "25%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SmallPickupIcon />
                <span className={classes.jobType}>Pickup</span>
              </div>
              {data.reservation.notes && (
                // add exclamation point
                <div></div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{ padding: "0px 8px" }}
          className={classes.reservationNameDiv}
        >
          <span className={classes.name}>{data.name}</span>
          <div className={classes.nameAndCommentsDiv}>
            {data.comments.map((c, index) => {
              if (c === "New") {
                return (
                  <div
                    className={classes.commentColorDiv}
                    style={{
                      background: "#0071C5",
                    }}
                  ></div>
                );
              }
              if (c === "Regular") {
                return (
                  <div
                    className={classes.commentColorDiv}
                    style={{
                      background: "#2EB67D",
                    }}
                  ></div>
                );
              }
              if (c === "Cautious") {
                return (
                  <div
                    className={classes.commentColorDiv}
                    style={{
                      background: "#323232",
                    }}
                  ></div>
                );
              }
              if (c === "Overdue") {
                return (
                  <div
                    className={classes.commentColorDiv}
                    style={{
                      background: "#F83030",
                    }}
                  ></div>
                );
              }
              if (c === "Unpaid") {
                return (
                  <div
                    className={classes.commentColorDiv}
                    style={{
                      background: "#FFC821",
                    }}
                  ></div>
                );
              }
            })}
          </div>
        </div>
        <div style={{ padding: "0px 8px" }} className={classes.reservationInfo}>
          <span>{data.reservation.siteAddress}</span>
        </div>
        <div style={{ padding: "0px 8px" }} className={classes.reservationInfo}>
          {data.reservation.startDate === splitSchedule &&
            data.reservation.jobs.delivery.jobId && (
              <span
                onClick={() =>
                  history.push(
                    `/admin/jobs/${data.reservation.jobs.delivery.jobId}`
                  )
                }
                id="clickableInput"
                className={classes.jobIdText}
              >
                {data.reservation.jobs.delivery.jobId.slice(0, 10)}...
              </span>
            )}

          {data.reservation.endDate === splitSchedule &&
            data.reservation.jobs.pickup.jobId && (
              <span
                onClick={() =>
                  history.push(
                    `/admin/jobs/${data.reservation.jobs.pickup.jobId}`
                  )
                }
                id="clickableInput"
                className={classes.jobIdText}
              >
                {data.reservation.jobs.pickup.jobId.slice(0, 10)}...
              </span>
            )}

          <span className={classes.equiptment}>
            {renderEquipment(data.reservation.equiptment) +
              " (" +
              renderQuantity(data.reservation.quantity) +
              ")"}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={classes.hoverSort}
        onMouseOver={() => setHoveredItemId(reservationId)}
        style={
          searchString && !data.name.toLowerCase().includes(searchString)
            ? {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: "8px",
                background: "#FFFFFF",
                border: "1px solid #323232",
                opacity: 0.3,
              }
            : {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: "8px",
                background: "#FFFFFF",
                border: "1px solid #323232",
                borderLeft: `6px solid ${props.territoryColor}`,
              }
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
          className={classes.deliveryTypeAndNote}
        >
          {data.reservation.startDate === splitSchedule ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "25%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <DeliveryIcon />
                <span className={classes.jobType}>Delivery</span>
              </div>
              {data.reservation.notes && (
                // add exclamation point
                <div></div>
              )}
            </>
          ) : data.reservation.endDate === splitSchedule ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "25%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SmallPickupIcon />
                <span className={classes.jobType}>Pickup</span>
              </div>
              {data.reservation.notes && (
                // add exclamation point
                <div></div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={classes.reservationNameDiv}>
          <span className={classes.name}>{data.name}</span>
          <div className={classes.nameAndCommentsDiv}>
            {data.comments.map((c, index) => {
              if (c === "New") {
                return (
                  <div
                    className={classes.commentColorDiv}
                    style={{
                      background: "#0071C5",
                    }}
                  ></div>
                );
              }
              if (c === "Regular") {
                return (
                  <div
                    className={classes.commentColorDiv}
                    style={{
                      background: "#2EB67D",
                    }}
                  ></div>
                );
              }
              if (c === "Cautious") {
                return (
                  <div
                    className={classes.commentColorDiv}
                    style={{
                      background: "#323232",
                    }}
                  ></div>
                );
              }
              if (c === "Overdue") {
                return (
                  <div
                    className={classes.commentColorDiv}
                    style={{
                      background: "#F83030",
                    }}
                  ></div>
                );
              }
              if (c === "Unpaid") {
                return (
                  <div
                    className={classes.commentColorDiv}
                    style={{
                      background: "#FFC821",
                    }}
                  ></div>
                );
              }
            })}
          </div>
        </div>
        <div className={classes.reservationInfo}>
          <span>{data.reservation.siteAddress}</span>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={classes.reservationInfo}
        >
          {data.reservation.startDate === splitSchedule &&
            data.reservation.jobs.delivery.jobId && (
              <span
                onClick={() =>
                  history.push(
                    `/admin/jobs/${data.reservation.jobs.delivery.jobId}`
                  )
                }
                id="clickableInput"
                className={classes.jobIdText}
              >
                {data.reservation.jobs.delivery.jobId.slice(0, 10)}...
              </span>
            )}
          {}
          {data.reservation.endDate === splitSchedule &&
            data.reservation.jobs.pickup.jobId && (
              <span
                onClick={() =>
                  history.push(
                    `/admin/jobs/${data.reservation.jobs.pickup.jobId}`
                  )
                }
                id="clickableInput"
                className={classes.jobIdText}
              >
                {data.reservation.jobs.pickup.jobId.slice(0, 10)}...
              </span>
            )}

          <span className={classes.equiptment}>
            {renderEquipment(data.reservation.equiptment) +
              " (" +
              renderQuantity(data.reservation.quantity) +
              ")"}
          </span>
        </div>
      </div>
    );
  }
}

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.id,
    data: {
      // change this if using for something other than reservations
      reservationId: props.reservationId,
      data: props.data,
      driverId: props.driverId,
      startOrEnd:
        props.data.reservation.startDate === props.splitSchedule
          ? "START"
          : "END",
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <Item
        reservationId={props.reservationId}
        classes={props.classes}
        data={props.data}
        id={props.id}
        driverId={props.driverId}
        searchString={props.searchString}
        splitSchedule={props.splitSchedule}
        territoryColor={props.territoryColor}
        setHoveredItemId={props.setHoveredItemId}
        hoveredItemId={props.hoveredItemId}
        history={props.history}
        setMessagingModal={props.setMessagingModal}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMessagingModal: (boolean) => dispatch(setMessagingModal(boolean)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SortableItem
);
