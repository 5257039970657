import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";

function BillTo(props) {
  const { classes, invoice, history, customer } = props;
  return (
    <GridContainer>
      <GridItem className={classes.invoiceGridItem} xs={12}>
        <Card className={classes.invoiceCard}>
          <GridContainer>
            <GridItem className={classes.jobsHeaderGridItem} xs={12}>
              <h1 style={{ marginBottom: "30px" }} className={classes.title}>
                Bill To (Customer)
              </h1>
            </GridItem>
            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Company/Organization
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {customer.company ? customer.company : "N/A"}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Customer ID
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${invoice.customerId}`,
                        // state: { customer: invoice },
                      })
                    }
                    className={classes.customerIdInvoiceDetail}
                  >
                    {invoice.customerId}
                  </span>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Customer Name
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {customer.name}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Billing Address
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {customer.billingAddress}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Email
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {customer.email}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Accounting Status
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  <div
                    className={classes.statusDiv}
                    style={
                      invoice.invoiceDetails.paymentStatus ===
                      "Paid"
                        ? { background: "#2EB67D" }
                        : { background: "#323232" }
                    }
                  >
                    {" "}
                    {customer.accountingStatus ? customer.accountingStatus : "N/A"}
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Phone (Primary)
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {customer.phoneNumber}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Preferred Communication
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {customer.communicationPreference}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(BillTo));
