import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";

import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import ExplorerPage from "views/Pages/ExplorerPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";
import LeadsEditor from "views/Pages/leads/LeadsEditor";
import NewLead from "views/Pages/leads/NewLead";
import ProfileEditor from "views/Pages/profile/ProfileEditor";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";

import Regulars from "views/Regulars/Regulars";
import Customers from "views/Customers/Customers";
import Schedule from "views/Schedule/Schedule";
import AddTerritory from "views/AddTerritory/AddTerritory";
import Accounting from "views/Accounting/Accounting";
import Reports from "views/Reports/Reports";
import RentalsReport from "views/Reports/Rentals/RentalsReport.jsx";
import JobsReport from "views/Reports/Jobs/JobsReport.jsx";
import VolumnByTerritoryReport from "views/Reports/VolumnByTerritory/VolumnByTerritoryReport.jsx";
import SalesByInvoiceItemReport from "views/Reports/SalesByInvoiceItem/SalesByInvoiceItemReport.jsx";
import DumpsterByCostReport from "views/Reports/DumpsterByCost/DumpsterByCostReport.jsx";
import EmailsReport from "views/Reports/Emails/EmailsReport.jsx";
import TextsReport from "views/Reports/Texts/TextsReport.jsx";
import AgingReport from "views/Reports/Aging/AgingReport.jsx";
import DepositDetailReport from "views/Reports/DepositDetail/DepositDetailReport.jsx";
import CustomerReferralReport from "views/Reports/CustomerReferralReport/CustomerReferralReport.jsx";
import DepositSummaryReport from "views/Reports/DepositSummary/DepositSummaryReport.jsx";
import GrossSalesReport from "views/Reports/GrossSales/GrossSalesReport.jsx";
import SalesByCustomerReport from "views/Reports/SalesByCustomer/SalesByCustomerReport.jsx";
import SalesByQuarterReport from "views/Reports/SalesByQuarter/SalesByQuarterReport.jsx";
import UpcomingPayments from "views/Dashboard/UpcomingPayments/UpcomingPayments";
import ServiceReminders from "views/Dashboard/ServiceReminders/ServiceReminders";

import JobsDetail from "views/JobDetail/JobDetail";
import InvoiceDetail from "views/InvoiceDetail/InvoiceDetail";

import Search from "views/Search/Search";
import CustomerInfo from "views/CustomerInfo/CustomerInfo";
import Rentals from "views/Rentals/Rentals";
import InventoryAvailability from "views/Dashboard/InventoryAvailability/InventoryAvailability";
import Profile from "views/Profile/Profile";
import ReservationDetail from "views/ReservationDetail/ReservationDetail";
import PaymentDetail from "views/PaymentDetail/PaymentDetail";
import AddNewSite from "views/AddNewSite/AddNewSite";

import ReservationForm from "views/ReservationForm/ReservationForm";

import QuoteForm from "views/QuoteForm/QuoteForm";

import EditProfile from "views/EditProfile/EditProfile";
import EditBilling from "views/EditBilling/EditBilling";
import EditCompany from "views/EditCompany/EditCompany";

import TempReservationForm from "views/TempReservationForm/TempReservationForm";

import Settings from "views/Settings/Settings";

import AddProduct from "views/AddProduct/AddProduct";

import PublicInvoicePage from "views/PublicInvoiceDetailPage/PublicInvoiceDetailPage";

import AddUser from "views/AddUser/AddUser";
import EditUser from "views/EditUser/EditUser";
import EditJob from "views/EditJob/EditJob";
import EditInvoice from "views/EditInvoice/EditInvoice";
import CreateInvoice from "views/CreateInvoice/CreateInvoice";
import CreateNewJob from "views/CreateNewJob/CreateNewJob";
import AddRental from "views/AddRental/AddRental";
import AddCreditMemo from "views/AddCreditMemo/AddCreditMemo";

import SiteDetail from "views/SiteDetail/SiteDetail";
import EditSite from "views/EditSite/EditSite";
import QuoteDetail from "views/QuoteDetail/QuoteDetail";

import CreateQuote from "views/CreateQuote/CreateQuote";

import ServiceForm from "views/Dashboard/ServiceReminders/components/ServiceForm";

import CompanyInfo from "views/CompanyInfo/CompanyInfo";
import RequestRental from "views/CreateRequestRental/RequestRental";
import RequestSwap from "views/CreateRequestSwap/RequestSwap";
import RequestEarlyPickUp from "views/CreateRequestEarlyPickUp/RequestEarlyPickUp";
import RequestChange from "views/CreateRequestChange/RequestChange";


var dashRoutes = [
  {
    path: "/create-quote",
    name: "Create Quote",
    rtlName: "لوحة القيادة",
    component: CreateQuote,
    layout: "/admin",
  },
  {
    path: "/quotes/:id",
    name: "Quote Detail",
    rtlName: "لوحة القيادة",
    component: QuoteDetail,
    layout: "/admin",
  },
  {
    path: "/edit-site/:id",
    name: "Edit Site",
    rtlName: "لوحة القيادة",
    component: EditSite,
    layout: "/admin",
  },
  {
    path: "/site/:id",
    name: "Site Detail",
    rtlName: "لوحة القيادة",
    component: SiteDetail,
    layout: "/admin",
  },
  {
    path: "/create-job",
    name: "Create Job",
    rtlName: "لوحة القيادة",
    component: CreateNewJob,
    layout: "/admin",
  },
  {
    path: "/create-invoice",
    name: "Create Invoice",
    rtlName: "لوحة القيادة",
    component: CreateInvoice,
    layout: "/admin",
  },
  {
    path: "/request-rental",
    name: "Request Rental",
    rtlName: "لوحة القيادة",
    component: RequestRental,
    layout: "/admin",
  },
  {
    path: "/request-swap",
    name: "Request Swap",
    rtlName: "لوحة القيادة",
    component: RequestSwap,
    layout: "/admin",
  },
  {
    path: "/request-early-pickup",
    name: "Request Early Pickup",
    rtlName: "لوحة القيادة",
    component: RequestEarlyPickUp,
    layout: "/admin",
  },
  {
    path: "/request-change",
    name: "Request Change",
    rtlName: "لوحة القيادة",
    component: RequestChange,
    layout: "/admin",
  },
  {
    path: "/edit-invoice/:id",
    name: "Edit Invoice",
    rtlName: "لوحة القيادة",
    component: EditInvoice,
    layout: "/admin",
  },
  {
    path: "/edit-job/:id",
    name: "Edit Job",
    rtlName: "لوحة القيادة",
    component: EditJob,
    layout: "/admin",
  },
  {
    path: "/edit-user/:id",
    name: "Edit User",
    rtlName: "لوحة القيادة",
    component: EditUser,
    layout: "/admin",
  },
  {
    path: "/add-user",
    name: "Add User",
    rtlName: "لوحة القيادة",
    component: AddUser,
    layout: "/admin",
  },
  {
    path: "/invoice/:id",
    name: "Public Invoice Page",
    rtlName: "لوحة القيادة",
    component: PublicInvoicePage,
    layout: "/auth",
  },
  {
    path: "/payment/:id",
    name: "Payment Detail",
    rtlName: "لوحة القيادة",
    component: PaymentDetail,
    layout: "/admin",
  },
  {
    path: "/add-product",
    name: "Add Product",
    rtlName: "لوحة القيادة",
    component: AddProduct,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    rtlName: "لوحة القيادة",
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    rtlName: "لوحة القيادة",
    component: EditProfile,
    layout: "/admin",
  },
  {
    path: "/edit-company",
    name: "Edit Company",
    rtlName: "لوحة القيادة",
    component: EditCompany,
    layout: "/admin",
  },
  {
    path: "/edit-billing",
    name: "Reservation Form",
    rtlName: "لوحة القيادة",
    component: EditBilling,
    layout: "/admin",
  },
  {
    path: "/temp-form",
    name: "Reservation Form",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: TempReservationForm,
    layout: "/auth",
  },
  {
    path: "/quote-form",
    name: "Quote Form",
    rtlName: "لوحة القيادة",
    component: QuoteForm,
    layout: "/auth",
  },
  {
    path: "/reservation-form",
    name: "Reservation Form",
    rtlName: "لوحة القيادة",
    component: ReservationForm,
    layout: "/auth",
  },
  {
    path: "/invoice/:id",
    name: "Invoice Detail",
    rtlName: "لوحة القيادة",
    component: InvoiceDetail,
    layout: "/admin",
  },
  {
    path: "/jobs/:id",
    name: "Job Detail",
    rtlName: "لوحة القيادة",
    component: JobsDetail,
    layout: "/admin",
  },
  {
    path: "/add-new-site",
    name: "Add New Site",
    rtlName: "لوحة القيادة",
    component: AddNewSite,
    layout: "/admin",
  },
  {
    path: "/add-credit-memo",
    name: "Add Credit Memo",
    component: AddCreditMemo,
    layout: "/admin",
  },
  {
    path: "/rentals/:id",
    name: "Reservation Detail",
    rtlName: "لوحة القيادة",
    component: ReservationDetail,
    layout: "/admin",
  },
  {
    path: "/add-rental",
    name: "Create New Rental",
    component: AddRental,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profie",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/dashboard/inventory",
    name: "Inventory",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: InventoryAvailability,
    layout: "/admin",
  },
  {
    path: "/customers/:id",
    name: "Customers",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: CustomerInfo,
    layout: "/admin",
  },
  {
    path: "/company/:id/:uid",
    name: "Company",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: CompanyInfo,
    layout: "/admin",
  },
  {
    path: "/search/:category/:query",
    name: "Search",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Search,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/regulars",
    name: "Regulars",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Regulars,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Customers,
    layout: "/admin",
    role: "Super Admin"
  },
  {
    path: "/add-territory",
    name: "Territory",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: AddTerritory,
    layout: "/admin",
  },
  {
    path: "/schedule",
    name: "Schedule",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Schedule,
    layout: "/admin",
    role: "Super Admin"
  },
  {
    path: "/service-reminders",
    name: "Service Reminders",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: ServiceReminders,
    layout: "/admin",
  },
  {
    path: "/create-service-reminder",
    name: "Create Service Reminder",
    rtlName: "لوحة القيادة",
    component: ServiceForm,
    layout: "/admin",
  },
  {
    path: "/upcoming-payments",
    name: "Upcoming Payments",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: UpcomingPayments,
    layout: "/admin",
  },
  {
    path: "/rentals",
    name: "Rentals",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Rentals,
    layout: "/admin",
  },
  {
    path: "/accounting",
    name: "Accounting",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Accounting,
    layout: "/admin",
  },
  {
    path: "/reports/gross-sales",
    name: "Gross Sales",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: GrossSalesReport,
    layout: "/admin",
  },
  {
    path: "/reports/deposit-summary",
    name: "Deposit Summary Report",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: DepositSummaryReport,
    layout: "/admin",
  },
  {
    path: "/reports/customer-referral-sources",
    name: "Customer Referral Sources",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: CustomerReferralReport,
    layout: "/admin",
  },
  {
    path: "/reports/deposit-detail",
    name: "Deposit Detail Report",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: DepositDetailReport,
    layout: "/admin",
  },
  {
    path: "/reports/dumpster-cost-analysis",
    name: "Sales By Invoice Item Report",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: DumpsterByCostReport,
    layout: "/admin",
  },
  {
    path: "/reports/sales-by-invoice-line-item",
    name: "Sales By Invoice Item Report",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: SalesByInvoiceItemReport,
    layout: "/admin",
  },
  {
    path: "/reports/volumn-by-territory",
    name: "Volumn By Territory",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: VolumnByTerritoryReport,
    layout: "/admin",
  },
  {
    path: "/reports/sales-by-quarter",
    name: "Sales By Customer",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: SalesByQuarterReport,
    layout: "/admin",
  },
  {
    path: "/reports/sales-by-customer",
    name: "Sales By Customer",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: SalesByCustomerReport,
    layout: "/admin",
  },
  {
    path: "/reports/aging",
    name: "Aging",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: AgingReport,
    layout: "/admin",
  },
  {
    path: "/reports/texts",
    name: "Jobs",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: TextsReport,
    layout: "/admin",
  },
  {
    path: "/reports/emails",
    name: "Jobs",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: EmailsReport,
    layout: "/admin",
  },
  {
    path: "/reports/jobs",
    name: "Jobs",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: JobsReport,
    layout: "/admin",
  },
  {
    path: "/reports/rentals",
    name: "Jobs",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: RentalsReport,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reports",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Reports,
    layout: "/admin",
    role: "Super Admin"
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/leads/explorer",
        name: "Leads Explorer",
        rtlName: "عالتسعير",
        mini: "LX",
        rtlMini: "ع",
        component: ExplorerPage,
        layout: "/admin",
      },
      {
        path: "/pricing-page",
        name: "Pricing Page",
        rtlName: "عالتسعير",
        mini: "PP",
        rtlMini: "ع",
        component: PricingPage,
        layout: "/auth",
      },
      {
        path: "/rtl-support-page",
        name: "RTL Support",
        rtlName: "صودعم رتل",
        mini: "RS",
        rtlMini: "صو",
        component: RTLSupport,
        layout: "/rtl",
      },
      {
        path: "/timeline-page",
        name: "Timeline Page",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: TimelinePage,
        layout: "/admin",
      },
      {
        path: "/register-page/:id",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: RegisterPage,
        layout: "/auth",
      },
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
      // {
      //   path: "/register-page",
      //   name: "Register Page",
      //   rtlName: "تسجيل",
      //   mini: "R",
      //   rtlMini: "صع",
      //   component: RegisterPage,
      //   layout: "/auth",
      // },
      {
        path: "/lock-screen-page",
        name: "Lock Screen Page",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: LockScreenPage,
        layout: "/auth",
      },
      {
        path: "/user-page",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: UserProfile,
        layout: "/admin",
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: Apps,
    state: "componentsCollapse",
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MC",
        rtlMini: "ر",
        state: "multiCollapse",
        views: [
          {
            path: "/buttons",
            name: "Buttons",
            rtlName: "وصفت",
            mini: "B",
            rtlMini: "ب",
            component: Buttons,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: GridSystem,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: SweetAlert,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "content_paste",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: GridOn,
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: Place,
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-maps",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: FullScreenMap,
        layout: "/admin",
      },
      {
        path: "/vector-maps",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: VectorMap,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: WidgetsIcon,
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: Timeline,
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: DateRange,
    component: Calendar,
    layout: "/admin",
  },
  {
    path: "/leads/editor/:key",
    component: LeadsEditor,
    layout: "/admin",
  },
  {
    path: "/leads/new",
    component: NewLead,
    layout: "/admin",
  },
  {
    path: "/user/editor",
    component: ProfileEditor,
    layout: "/admin",
  },
];
export default dashRoutes;
