import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIconBlack.svg";

const debitCreditMockData = [
  {
    type: "Mastercard ending in 7122",
    cardholder: "BLine Flooring Group",
    expiration: "08 / 2024",
  },
];

const headers = ["TYPE", "CARDHOLDER NAME", "EXPIRATION DATE", "ACTIONS"];

function CreditDebitCards(props) {
  const { classes, cards } = props;

  const renderTableHeader = () => {
    return (
      <tr className={classes.notesTableRow}>
        {headers.map((key, index) => {
          return (
            <th className={classes.notesTableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    return debitCreditMockData.map((c, index) => {
      return (
        <tr className={classes.notesTableRow} >
          <td className={classes.notesTableCell}>
            {" "}
            {/* {c.card.brand.toUpperCase()} ending in123123 {c.card.last4} */}
            {c.type}
          </td>
          <td className={classes.notesTableCell}>
            {/* {c.billing_details.name} */}
            {c.cardholder}
          </td>
          <td
            className={classes.notesTableCell}
          >
            {/* {`${c.card.exp_month}/${c.card.exp_year}`} */}
            {c.expiration}
          </td>
          <td
            style={{ display: "flex", justifyContent: "center" }}
            className={classes.notesTableCell}
          >
            <Button
              style={{ background: "#FFC821", color: "#323232", width: "45%", fontWeight: 700 }}
              className={classes.createInvoiceButton}
            >
              <DenyIcon />
              Delete Card
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card className={classes.tabCard2}>
          <GridContainer>
            <GridItem xs={10}>
              <span className={classes.notesTabTitle2}>1 Credit/Debit Card</span>
            </GridItem>
            <GridItem xs={2}>
              <Button className={classes.addCardButton}
                style={{
                  justifyContent: "center",
                  width: "75%",
                  float: "right"
                }}>
                <AddIcon style={{ marginRight: "12px", width: "25px", height: "25px" }} />
                Add Card
              </Button>
            </GridItem>
            <GridItem xs={12}>
              <table
                style={{ marginTop: "30px" }}
                className={classes.inventoryTable}
              >
                <tbody>
                  {renderTableHeader()}
                  {renderTableData()}
                </tbody>
              </table>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(CreditDebitCards));
