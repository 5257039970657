/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import dotenv from "dotenv";
import compose from "recompose/compose";
import { connect } from "react-redux";
import axios from "axios";
import Geocode from "react-geocode";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import "./googleMap.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { NavLink, withRouter } from "react-router-dom";
import CustomMarker from "assets/img/customGoogleMapMarker.png";

let map;
dotenv.config();

const loader = new Loader({
  apiKey: process.env.REACT_APP_FB_API_KEY,
  version: "weekly",
});

const GoogleMap = (props) => {
  const { reservations, selectedEvent, zoom } = props;
  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };
  // useEffect(() => {
  //   if (address) {
  //     Geocode.setApiKey(process.env.REACT_APP_FB_API_KEY);
  //     Geocode.fromAddress(address).then(
  //       (response) => {
  //         const { lat, lng } = response.results[0].geometry.location;

  //         loader.load().then((google) => {
  //           map = new google.maps.Map(document.getElementById("map"), {
  //             center: { lat, lng },
  //             zoom: 15,
  //           });
  //           const marker = new google.maps.Marker({
  //             position: { lat, lng },
  //             map,
  //             title: "Cleanup",
  //           });

  //           const contentString =
  //             '<div class="content">' +
  //             '<div id="siteNotice">' +
  //             "</div>" +
  //             `<h1 id="firstHeading" class="heading">${selectedEvent.heading}</h1>` +
  //             '<div id="bodyContent">' +
  //             `<p class="dateString">${selectedEvent.eventDateString}</p>` +
  //             `<p> ${selectedEvent.address} </p>` +
  //             `<button
  //           onclick="() => console.log('heyyy')"` +
  //             'class="detailsBtn"> Details </button>';
  //           ("</div>");

  //           const infowindow = new google.maps.InfoWindow({
  //             content: contentString,
  //           });

  //           marker.addListener("click", () => {
  //             infowindow.open({
  //               anchor: marker,
  //               map,
  //               shouldFocus: false,
  //             });
  //           });
  //         });
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  //   }
  // }, [address]);

  useEffect(() => {
    // if (!loaded && data.length > 0) {
    async function fetchData() {
      const promisesAddressArr = [];
      Geocode.setApiKey(process.env.REACT_APP_FB_API_KEY);
      reservations.forEach((d, index) => {
        promisesAddressArr.push(Geocode.fromAddress(d.reservation.siteAddress));
      });

      Promise.all(promisesAddressArr).then((arrOfResults) => {
        loader.load().then((google) => {
          return axios
            .get(
              `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_FB_API_KEY}&components=postal_code:80110`
            )
            .then((response) => {
              const lat = response.data.results[0].geometry.location.lat;
              const lng = response.data.results[0].geometry.location.lng;
              map = new google.maps.Map(document.getElementById("map"), {
                center: { lat, lng },
                zoom: 10,
              });
              const handleClick = (id) => {
                console.log('clickeddd', id)
              }

              arrOfResults.map((r, index) => {
                const contentString =
                  '<div class="content">' +
                  '<div id="siteNotice">' +
                  "</div>" +
                  `<h1 id="firstHeading" class="heading">${reservations[index].name}</h1>` +
                  '<div id="bodyContent">' +
                  `<p class="dateString">${reservations[index].reservation.startDate}</p>` +
                  `<p> ${renderEquipment(reservations[index].reservation.equiptment)} </p>` +
                  `<p> ${reservations[index].reservation.siteAddress} </p>` +
                  `<a href=http://localhost:3000/admin/customers/${reservations[index].customerId} class="detailsBtn"> Details </a>`;
                ("</div>");

                const iconBase =
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAtCAYAAADLEbkXAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMwSURBVHgBzVi9ctpAEF4J7ElJutiV8gRRDMzQGXcp8ROAn4CkTGXTpUv8BuQJsLt0kI4ZBlDe4CqgC6YLv/mWOWkEBrQnA+NvRug47Z0+7Xe3u5JFBsjn86nRaFSwLOsSf93FYuHgnNKXh+hXOHvo/316etpoNptKOrclMcpkMnlMXsaRD904enLLqp6cnFQkhHYS0R64RfMzvQASQluJZLNZdzab1dB0aD9QiUTiutVqeZsu2ps60+l0ESTqeyTBcDBnl+fedDGx3qHXwwOab+gwKJyfn6t+v/8n3LkiTS6Xc8bjcZcMFmRMDLGrPobXzIo0IFE/AglGajKZ1MIdgTQXFxclnEp0PLw7OzuzIFGD/4Q9ckvxoPQRB2XXdVMBEe0NRzoaccHDcT2fz992Op33fHAbl24MSaVs2y4t5+QfbKm6jpoS3OPGOwMcHuyOhB7GAzXa7faVxa4Bq78kQySJEJkfOJUltuxNO5lMuiSDwoA7oS1p26HEFhE3b2OAlMij53miiRna9l5iy1mcF6sjMYZ8D2QIPKl0zAcbbEQBbDqdKjIEgpaS2tr0SmDrqioScLN0LQUw2AhP7BElsTSIM+ExRaGpsvGkntC4yNlZaLvM5FLy2AierSsmybZcZkw/N0QBtt9JuCOxEbzlYgXzR8kA2LnwYH2XZ7jO1SmjQLI5l/EpyX8weRWBTaQnk+G6BTdrYKH/xIJU3I+ncjDHJYptTgEmlf4y1gQVmmHi2xcUZ25u2CFmFTo+gnsGFVqv11OomLimyNFxUIU3AiIrkVVnTEWHB2fyFQVWiPDqxZ6+ocOjgnupcMez95ojSMTF1bf1zo1J74ASbS2uNhI5lEQ857biKrFt0AEkqqBIrm67GPl9BIGuy9GUXoYgcG1DZGG0D4mwLq6ibBJRBpBoAIme0PxE8VDpdruRtavo0xUjZi6KlMSHuGbFRxaWSPw6wZBI4iNSGh+DwWAIif6RXCKRJD7E0viQSOS/z5IBjF8nBBINtY0RxNL4EEj0FZL8IkMYS+MDEtU21KVcY8SKO7Hf9LT7VajrWY1xFCKcvPgDribD3+G/rNcYJvgPRzCVQirtgqQAAAAASUVORK5CYII=";

                const marker = new google.maps.Marker({
                  position: {
                    lat: r.results[0].geometry.location.lat,
                    lng: r.results[0].geometry.location.lng,
                  },
                  map,
                  title: "Rental",
                  icon: iconBase,
                });
                const infowindow = new google.maps.InfoWindow({
                  content: contentString,
                });

                marker.addListener("click", () => {
                  infowindow.open({
                    anchor: marker,
                    map,
                    shouldFocus: false,
                  });
                  marker.setMap(map);
                });
                marker.setMap(map);
              });
            });
        });
      });
    }
    fetchData();
    // }
  }, [reservations]);

  return <div id="map" style={{ height: "95%", zIndex: 3 }}></div>;
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(GoogleMap));
