import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import "views/Dashboard/components/pagination.css"
// core components
import { MenuItem, Select } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import TextsTable from "./components/TextsTable";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
// import BusinessOverview from "./components/BusinessOverview";
// import WhoOwes from "./components/WhoOwes";
// import SalesAndCustomers from "./components/SalesAndCustomers";


const texts = [
  {
    phoneNumber: "(234) 453 - 2342",
    name: "Aaron Guardado",
    sentAt: "04/09/21, 4:08 PM",
    content: "Hi Aaron, Thanks for picking Humpty Dump for you...",
    status: "Failed",
  },
  {
    phoneNumber: "(234) 453 - 2355",
    name: "Steven Protok",
    sentAt: "04/09/21, 3:08 PM",
    content: "Hi Steven, Thank you for contacting Humpty Dum...",
    status: "Delivered",
  },
  {
    phoneNumber: "(234) 453 - 2332",
    name: "Misty Hernandez",
    sentAt: "04/09/21, 2:08 PM",
    content: "Dear Misty, URGENT: PLEASE MAKE PAYMEN...",
    status: "Undelivered",
  },
  {
    phoneNumber: "(234) 453 - 2311",
    name: "Carol Johnson",
    sentAt: "04/09/21, 4:08 PM",
    content: "Hi Carol, We have other customer drop offs in you...",
    status: "Delivered",
  },
  {
    phoneNumber: "(234) 453 - 2342, (234) 453 - 2342...",
    name: "All Today Customers",
    sentAt: "04/09/21, 4:08 PM",
    content: "Hi this is Steve with the Humpty Dump Roll-Offs ...",
    status: "Unknown",
  },
];

function TextsReport(props) {
  const { classes, reservationsData, history } = props;
  
  return (
    <GridContainer>
      <GridItem className={classes.jobsHeaderGridItem} xs={12}>
        <h1 className={classes.title}>Text Activity</h1>
        <Button className={classes.editRentalRatesButton}>
          <AddIcon />
          Create Text
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <TextsTable
          history={history}
          texts={texts}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(TextsReport));
