import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from "firebase/client.js";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import CreateQuoteHeader from "./components/CreateQuoteHeader";
import EarlyPickUpDetails from "./components/EarlyPickUpDetails"
import loader from "assets/img/loader.gif";
import Button from "@material-ui/core/Button";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { addQuote } from "store/actions/quotes/quotesActions";
import EarlyPickUpOtherInfo from "./components/EarlyPickUpOtherInfo";

const locationSelections = [
    "Select Option",
    "Right Side of Driveway",
    "Left Side of Driveway",
    "Middle of Driveway",
    "On Street",
    "Side of House",
    "Backyard",
    "Alley",
    "Other (Please specify below)"
];

function RequestEarlyPickUp(props) {
    const { classes, history, handleAddQuote, user } = props;
    const [quoteType, setQuoteType] = useState({
        individual: false,
        business: false,
    });
    const [textOrEmail, setTextOrEmail] = useState({
        text: false,
        email: false,
    });
    const [closeDropdown, setCloseDropdown] = useState(false)
    const [filteredData, setFilteredData] = useState([])
    const [customerInfo, setCustomerInfo] = useState("")
    const [customerFound, setCustomerFound] = useState("")
    const [customerReservationData, setCustomerReservationData] = useState([])
    const [customerId, setCustomerId] = useState("")
    const [customer, setCustomer] = useState("");
    const [site, setSite] = useState("");
    const [productType, setProductType] = useState("");
    const [quantity, setQuantity] = useState(0);
    const [rate, setRate] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [duration, setDuration] = useState("");
    const [notes, setNotes] = useState("");
    const [pickUpAddress, setPickUpAddress] = useState("")
    const [swapDate, setSwapDate] = useState("");
    const [pickUpDate, setPickUpDate] = useState("");
    const [sevenDayRental, setSevenDayRental] = useState(false);
    const [otherDuration, setOtherDuration] = useState(false);
    const [equipmentLocation, setEquipmentLocation] = useState("Select Option");
    const [inventoryData, setInventoryData] = useState([]);
    const [cost, setCost] = useState("")
    const [equipmentNeeded, setEquipmentNeeded] = useState("")
    const [dataLoaded, setDataLoaded] = useState(false);
    const [schedulingConsent, setSchedulingConsent] = useState({
        yes: false,
        no: false
    })
    const [file, setFile] = useState()
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [dropOffLat, setDropOffLat] = useState(false);
    const [dropOffLng, setDropOffLng] = useState(false);
    const [siteAddress, setSiteAddress] = useState("");
    const [imageFile, setImageFile] = useState('');
    const [searchString, setSearchString] = useState("")

    const [errors, setErrors] = useState({
        pickUpAddress: false,
        swapDate: false,
        sevenDayRental: false,
        pickupDate: false,
        startDate: false,
        equipmentNeeded: false,
        equipmentLocation: false,
        schedulingConsent: false
    });

    const handleSelect = (address, placeholder) => {
        geocodeByAddress(address).then((results) => {
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            //   if (placeholder === "Search your billing address..") {
            //     setBillingAddress(results[0].formatted_address);
            //   }
            if (placeholder === "Search your drop-off address..") {
                const addressResults = results[0];
                addressResults.address_components.forEach((r) => {
                    if (r.types.includes("locality")) {
                        setCity(r.long_name);
                    }
                    if (r.types.includes("administrative_area_level_1")) {
                        setState(r.short_name);
                    }
                    if (r.types.includes("postal_code")) {
                        setZipCode(r.short_name);
                    }
                });
                setSiteAddress(addressResults.formatted_address);
                setDropOffLat(lat);
                setDropOffLng(lng);
            }
        });
    };

    useEffect(() => {
        if (!dataLoaded) {
            const fetchData = async () => {
                return db
                    .collection("inventory")
                    .orderBy("sortOrder")
                    .get()
                    .then((snapshot) => {
                        const inventory = snapshot.docs.map((d) => d.data());
                        setInventoryData(inventory);
                        setDataLoaded(true);
                    });
            };
            fetchData();
        }
    }, [dataLoaded]);

    useEffect(() => {

        if (!customerInfo) {
            const fetchData = async () => {

                return db
                    .collection("customers")
                    .get()
                    .then((snapshot) => {
                        const customerSnap = snapshot.docs.map((d) => d.data());
                        customerSnap.forEach((c) => {
                            if (c.email === user.email && c.firstName === user.firstName && c.lastName === user.lastName) {
                                console.log("checking c.customerid", c.customerId)
                                setCustomerInfo(c)
                                // setCustomerId(c.customerId)
                                // id = c.customerId
                                setCustomerFound(true)
                            }

                        })
                    })
            };
            fetchData();
        }
    }, [])
    useEffect(() => {
        let items = []
        if (customerFound) {
            const fetchData = async () => {
                return db
                    .collection("reservations")
                    .get()
                    .then((snapshot) => {
                        const reservationSnap = snapshot.docs.map((d) => d.data());
                        reservationSnap.forEach((c) => {
                            if (c.customerId === customerInfo.customerId) {
                                items.push(c)
                            }
                        })
                        setCustomerReservationData(items)
                    })
            };
            fetchData();
        }
    }, [customerFound])

    const handleSearch = (event) => {
        setCloseDropdown(false)
        const searchWord = event.target.value;
        setSearchString(searchWord);
        const newFilter = customerReservationData.filter((u) => {
          return u.reservation.reservationId.toLowerCase().includes(searchWord.toLowerCase());
        });
    
        if (searchWord === "") {
          setFilteredData(customerReservationData);
        } else {
          setFilteredData(newFilter);
        }
      };

    // const handleSearch = () => {
    //     if (searchString) {
    //         const lowerCaseQuery = searchString.toLowerCase();
    //         const filtered = customerReservationData.filter((u) => {
    //             let lowerCaseReservationId = u.reservation.reservationId.toLowerCase();
    //             // let lowerCaseEmail = '';
    //             // let lowerCaseCustomerId = '';
    //             // const lowerCaseName = u.name.toLowerCase();
    //             // if (u.billingAddress) {
    //             //   lowerCaseBillingAddress = u.billingAddress.toLowerCase();
    //             // } else if (u.address) {
    //             //   lowerCaseBillingAddress = u.address.toLowerCase();
    //             // }
    //             // const lowerCaseBillingAddress = u.billingAddress.toLowerCase();
    //             // const lowerCaseCompany = u.company.toLowerCase();
    //             // if (u.email) {
    //             //   lowerCaseEmail = u.email.toLowerCase();
    //             // } else if (u.emails) {
    //             //   lowerCaseEmail = u.emails.toLowerCase();
    //             // }
    //             // if (u.customerId) {
    //             //   lowerCaseCustomerId = u.customerId.toLowerCase();
    //             // } else if (u.id) {
    //             //   lowerCaseCustomerId = u.id.toLowerCase();
    //             // }
    //             if (
    //                 //   lowerCaseName.includes(lowerCaseQuery) ||
    //                 //   lowerCaseBillingAddress.includes(lowerCaseQuery) ||
    //                 //   lowerCaseCompany.includes(lowerCaseQuery) ||
    //                 //   lowerCaseEmail.includes(lowerCaseQuery) ||
    //                 //   lowerCaseCustomerId.includes(lowerCaseQuery)
    //                 lowerCaseReservationId.includes(lowerCaseQuery)
    //             ) {
    //                 return true;
    //             }
    //         });
    //         console.log("filtered", filtered);
    //         setFilteredData(filtered);
    //     } else {
    //         setFilteredData(customerReservationData);
    //     }
    // };

    const handleSubmit = () => {
        if (
            !customer ||
            !productType ||
            quantity === 0 ||
            !quantity ||
            !rate ||
            !startDate ||
            !endDate ||
            (!quoteType.individual && !quoteType.business) ||
            (!textOrEmail.text && !textOrEmail.email) ||
            !site
        ) {
            let newErrors = {
                customer: false,
                productType: false,
                quantity: false,
                rate: false,
                startDate: false,
                endDate: false,
                communication: false,
                quoteType: false,
                textOrEmail: false,
                site: false,
            };
            if (!customer) {
                newErrors = { ...newErrors, customer: true };
            }
            if (!productType) {
                newErrors = { ...newErrors, productType: true };
            }
            if (!quantity || quantity === 0) {
                newErrors = { ...newErrors, quantity: true };
            }
            if (!rate) {
                newErrors = { ...newErrors, rate: true };
            }
            if (!startDate) {
                newErrors = { ...newErrors, startDate: true };
            }
            if (!endDate) {
                newErrors = { ...newErrors, endDate: true };
            }
            if (!quoteType.individual && !quoteType.business) {
                newErrors = { ...newErrors, quoteType: true };
            }
            if (!textOrEmail.email && !quoteType.text) {
                newErrors = { ...newErrors, textOrEmail: true };
            }
            if (!site) {
                newErrors = { ...newErrors, site: true };
            }
            setErrors({ ...newErrors });
        } else {
            //   const pendingQuote = {
            //     ...customer,
            //     quoteType: quoteType.individual ? "Individual" : "Business",
            //     communicationPreference: textOrEmail.text ? "Text" : "Email",
            //     quoteTimestamp: firebase.firestore.Timestamp.fromDate(new Date()),
            //     reservation: {
            //       amount: rate,
            //       amountPaid: "0",
            //       driver: "N/A",
            //       endDate,
            //       territory: "",
            //       equiptment: productType.inventoryItem,
            //       dropOffCity: site.city,
            //       dropOffState: "N/A",
            //       dropOffZipCode: "N/A",
            //       equiptmentLocation: "N/A",
            //       existingCustomer: true,
            //       heardAboutUsFrom: "N/A",
            //       inventoryId: productType.inventoryId,
            //       lastEmailedDate: "N/A",
            //       notes: notes,
            //       otherInformation: "",
            //       paymentDueDate: endDate,
            //       poNumber: "",
            //       promoCode: "",
            //       quantity,
            //       recurring: false,
            //       sevenDayReservation:
            //         getARangeOfDays(startDate, endDate) === 7 ? true : false,
            //       siteAddress: site.siteAddress,
            //       siteId: site.siteId,
            //       startDate,
            //       status: "Unapproved",
            //       spokenWithTeamMember: false,
            //     },
            //   };
            //   handleAddQuote(pendingQuote);
        }
    };

    return (
        <GridContainer>
            <GridItem className={classes.marginBottomGridItem} xs={12}>
                <CreateQuoteHeader />
            </GridItem>
            <GridItem className={classes.marginBottomGridItem} xs={12}>
                <EarlyPickUpDetails
                    history={history}
                    errors={errors}
                    pickUpAddress={pickUpAddress}
                    setPickUpAddress={setPickUpAddress}
                    swapDate={swapDate}
                    setSwapDate={setSwapDate}
                    sevenDayRental={sevenDayRental}
                    setSevenDayRental={setSevenDayRental}
                    otherDuration={otherDuration}
                    setOtherDuration={setOtherDuration}
                    pickupDate={pickUpDate}
                    setPickUpDate={setPickUpDate}
                    handleSelect={handleSelect}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    handleSearch={handleSearch}
                    filteredData={filteredData}
                    closeDropdown={closeDropdown}
                    setCloseDropdown={setCloseDropdown}
                />
            </GridItem>
            <GridItem className={classes.marginBottomGridItem} xs={12}>
                <EarlyPickUpOtherInfo
                    history={history}
                    errors={errors}
                    notes={notes}
                    setNotes={setNotes}
                    errors={errors}
                />
            </GridItem>
            <GridItem className={classes.cancelSaveBtnGrid} xs={12}>
                <Button
                    style={{ background: "#323232", color: "white" }}
                    className={classes.cancelSaveButton}
                    onClick={() => history.push("/admin/settings")}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    style={{
                        background: "#FFC821",
                        color: "#323232",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                    }}
                    className={classes.cancelSaveButton}
                >
                    <CheckIcon />
                    Save
                </Button>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        customersData: state.customers.customers,
        user: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleAddQuote: (quote) => dispatch(addQuote(quote)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(reservationDetailStyle)
)(React.memo(RequestEarlyPickUp));
