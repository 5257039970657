import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { addPendingReservationExistingCustomer } from "store/actions/reservations/reservationsActions";
import { getOneWeekLater } from "../../Dashboard/helpers/helpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import pointInPolygon from "point-in-polygon";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

function NewFormStepTwo(props) {
  const {
    classes,
    nextStep,
    prevStep,
    step,
    errors,
    billingAddress,
    setBillingAddress,
    handleSelect,
    addressLine2,
    setAddressLine2,
    siteAddress,
    setSiteAddress,
    dropOffDate,
    setDropOffDate,
    disablePastDt,
    pickupDate,
    setPickupDate,
  } = props;

  return (
    <Card className={classes.card}>
      <h5 className={classes.formTitle}>
        Project Address:{" "}
        {errors.siteAddress && <span style={{ color: "red" }}>Required*</span>}{" "}
      </h5>
      <GooglePlacesAutoComplete
        classes={classes}
        value={siteAddress}
        width={"100%"}
        placeholder="Search your drop-off address.."
        onChange={(e) => setSiteAddress(e)}
        onSelect={handleSelect}
        margin="0px 0px 20px 0px"
        background="#F5F5F5"
        googleCallbackName="initTwo"
      />

      <TextField
        className={classes.emailInput}
        value={addressLine2}
        placeholder="Address line 2 (optional)"
        variant="outlined"
        onChange={(e) => setAddressLine2(e.target.value)}
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <h5 className={classes.formTitle}>
        Desired Drop-Off Date:{" "}
        {errors.dropOffDate && <span style={{ color: "red" }}>Required*</span>}{" "}
      </h5>
      <Datetime
        input
        isValidDate={disablePastDt}
        // className={classes.textFieldRoot}
        type="text"
        inputProps={{
          className: classes.datetime,
          placeholder: "Choose rental drop-off date..",
        }}
        style={{
          color: "#323232 !important",
          height: "100%",
          width: "100%",
        }}
        selected={dropOffDate}
        dateFormat="MM/DD/YY"
        onChange={(date, e) => {
          if (typeof date === "string") {
          } else {
            setDropOffDate(date.format("MM/DD/YY"));
          }
        }}
        timeFormat={false}
        closeOnSelect={true}
        closeOnClickOutside
      />
      <h5 className={classes.formTitle}>Desired Pick Up Date</h5>
      <Datetime
        input
        isValidDate={disablePastDt}
        // className={classes.textFieldRoot}
        inputProps={{
          className: classes.datetime,
          placeholder: "Choose rental pickup date..",
        }}
        style={{
          color: "#323232 !important",
          height: "100%",
          width: "100%",
        }}
        selected={pickupDate}
        dateFormat="MM/DD/YY"
        onChange={(date, e) => {
          if (typeof date === "string") {
          } else {
            setPickupDate(date.format("MM/DD/YY"));
          }
        }}
        timeFormat={false}
        closeOnSelect={true}
        closeOnClickOutside
      />
      <Button
        style={{ background: "#323232", color: "white" }}
        onClick={() => prevStep()}
      >
        Go Back
      </Button>
      {!pickupDate || !siteAddress || !dropOffDate ? (
        <Button
          disabled
          style={{ background: "#FFC821", color: "#323232" }}
          onClick={() => nextStep()}
        >
          Go Next
        </Button>
      ) : (
        <Button
          style={{ background: "#FFC821", color: "#323232" }}
          onClick={() => nextStep()}
        >
          Go Next
        </Button>
      )}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  handleAddPendingReservationExistingCustomer: (pendingRes) =>
    dispatch(addPendingReservationExistingCustomer(pendingRes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(NewFormStepTwo));
