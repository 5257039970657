import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { BsFillCircleFill } from "react-icons/bs";
import InventoryRented from "./InventoryRented";
import Jobs from "./Jobs";
import Invoices from "./Invoices";
import SiteInformation from "./SiteInformation";
import Dialog from "@material-ui/core/Dialog";
import BillTo from "./BillTo";
import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { db, firebase, storage } from "firebase/client.js";
import loader from "assets/img/loader.gif";
import moment from "moment";
import CardBody from "components/Card/CardBody";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function JobDetails(props) {
  const { classes, job, inventoryData, history, jobId } = props;
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const attachmentFileRef = createRef(null);
  const [savingImage, setSavingImage] = useState(false);
  const [areYouSureDelete, setAreYouSureDelete] = useState(false);

  const sortBy = ["Attachment Name"];
  const directionOptions = ["Asc", "Desc"];
  console.log("propsssssx", props);
  const handleImageUpload = (event) => {
    setSavingImage(true);
    // var url = URL.createObjectURL(event.target.files[0]);
    // // setBasicNFTPreviewUrl(url);

    var array = [];
    Array.from(event.target.files).forEach((file) => {
      array = [...array, file];
    });
    const storagePromiseArray = [];
    const downloadRefArray = [];
    const downloadRefPromiseArray = [];
    const finalArrayOfNewAttachments = [];
    array.forEach((file, i) => {
      console.log("fileeee", file);
      var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      storagePromiseArray.push(
        storage.ref().child(`jobAttachments/${jobId}/${file.name}`).put(file)
      );
      downloadRefArray.push(`jobAttachments/${jobId}/${file.name}`);
      finalArrayOfNewAttachments.push({
        fileName: file.name,
        dateUploaded: new Date(),
        fileSizeInMB: sizeInMB,
      });
    });
    Promise.all(storagePromiseArray).then(() => {
      downloadRefArray.forEach((s) => {
        downloadRefPromiseArray.push(storage.ref().child(s));
      });
      Promise.all(downloadRefPromiseArray).then((results) => {
        const downloadUrlPromiseArray = [];
        results.forEach((r) => {
          downloadUrlPromiseArray.push(r.getDownloadURL());
        });
        Promise.all(downloadUrlPromiseArray).then((resultssss) => {
          console.log("resultssss", resultssss);
          resultssss.forEach((url, index) => {
            finalArrayOfNewAttachments[index].fileUrl = url;
          });
          return db
            .collection("jobs")
            .doc(jobId)
            .update({
              attachments: [...job.attachments, ...finalArrayOfNewAttachments],
            })
            .then(() => {
              setSavingImage(false);
            });
        });
      });
    });
  };

  const deleteJob = () => {
    return db
      .collection("reservations")
      .doc(job.reservation.reservation.reservationId)
      .get()
      .then((snapshot) => {
        const res = snapshot.data();
        if (
          res.reservation.jobs.delivery &&
          res.reservation.jobs.delivery.jobId &&
          res.reservation.jobs.delivery.jobId === jobId
        ) {
          delete res.reservation.jobs.delivery.jobId;
          res.reservation.jobs.delivery.driverName = "N/A";
          res.reservation.jobs.delivery.driverId = "";
        }
        if (

          res.reservation.jobs.pickup &&
          res.reservation.jobs.pickup.jobId &&
          res.reservation.jobs.pickup.jobId === jobId
        ) {
          delete res.reservation.jobs.pickup.jobId;
          res.reservation.jobs.pickup.driverName = "N/A";
          res.reservation.jobs.pickup.driverId = "";
        }
        return db
          .collection("reservations")
          .doc(job.reservation.reservation.reservationId)
          .update({
            ...res,
          })
          .then(() => {
            return db
              .collection("jobs")
              .doc(jobId)
              .delete()
              .then(() => {
                setAreYouSureDelete(false);
                history.push(`/admin/customers/${job.reservation.customerId}`);
              });
          });
      });
  };

  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };

  return (
    <GridContainer>
      <GridItem xs={4}>
        <Card className={classes.ReservationInfoLeftCard}>
          <GridContainer className={classes.mainGrid}>
            <GridItem
              className={classes.row + " " + classes.addedPadding}
              xs={12}
            >
              <span className={classes.categoryTitle}>Rental</span>
              <span
                onClick={() =>
                  history.push({
                    pathname: `/admin/rentals/${job.reservation.reservation.reservationId}`,
                    state: { reservation: { ...job.reservation } },
                  })
                }
                className={classes.reservationIdJobDetail}
              >
                {job.reservation.reservation.reservationId}
              </span>
            </GridItem>
            <GridItem
              className={classes.row + " " + classes.addedPadding}
              xs={12}
            >
              <span className={classes.categoryTitle}>Date</span>
              <span className={classes.categoryValue}>
                {
                job.reservation.reservation.jobs.delivery &&
                job.reservation.reservation.jobs.delivery.jobId &&
                job.reservation.reservation.jobs.delivery.jobId === job.jobId
                  ? job.reservation.reservation.startDate
                  : job.reservation.reservation.endDate}
              </span>
            </GridItem>

            <GridItem
              className={classes.row + " " + classes.addedPadding}
              xs={12}
            >
              <span className={classes.categoryTitle}>Driver</span>
              <span className={classes.categoryValue}>
                {" "}
                {
                job.reservation.reservation.jobs.delivery &&
                job.reservation.reservation.jobs.delivery.jobId &&
                job.reservation.reservation.jobs.delivery.jobId === job.jobId
                  ? job.reservation.reservation.jobs.delivery && job.reservation.reservation.jobs.delivery.driverName
                  : job.reservation.reservation.jobs.pickup && job.reservation.reservation.jobs.pickup.driverName}
              </span>
            </GridItem>
            <GridItem
              className={classes.row + " " + classes.addedPadding}
              xs={12}
            >
              <span className={classes.categoryTitle}>Inventory</span>
              <span className={classes.categoryValue}>
                {renderEquipment(job.reservation.reservation.equiptment)}
              </span>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
      <GridItem xs={8}>
        <Card className={classes.jobsLeftCard}>
          <GridContainer className={classes.mainGrid}>
            <GridItem xs={12}>
              <span className={classes.attachmentsTitle}>Attachments</span>
            </GridItem>

            <GridItem xs={2}>
              <h5 className={classes.subHeader}>Sort By</h5>
            </GridItem>
            <GridItem xs={6}>
              <Select
                className={classes.attachmentsSelectMenu}
                value={sortBy[0]}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                // onChange={(e) => handleFilters("filter", e.target.value)}
              >
                {sortBy.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>

              <Select
                className={classes.attachmentsSelectMenu}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                value={directionSelection}
                // onChange={(e) => handleFilters("direction", e.target.value)}
              >
                {directionOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </GridItem>
            <GridItem xs={4}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  onClick={() => {
                    attachmentFileRef.current.click();
                  }}
                  className={classes.addCustomer}
                >
                  <input
                    onChange={(event) => {
                      handleImageUpload(event);
                    }}
                    multiple="multiple"
                    type="file"
                    id="attachmentInput"
                    ref={attachmentFileRef}
                    style={{ display: "none" }}
                  />
                  <AddIcon /> Add Attachment
                </Button>
              </div>
            </GridItem>

            <GridItem xs={12}>
              {savingImage ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img style={{ width: "20%" }} src={loader} alt="..." />
                </div>
              ) : (
                <GridContainer>
                  <GridItem xs={12}>
                    {job.attachments.map((a, index) => {
                      const date = a.dateUploaded.toDate();
                      return (
                        <GridContainer
                          className={classes.attachmentGridContainer}
                          key={index}
                        >
                          <GridItem xs={4}>
                            <span className={classes.fileName}>
                              {a.fileName}
                            </span>
                          </GridItem>
                          <GridItem xs={3}>
                            <span className={classes.fileDetails}>
                              {a.fileSizeInMB}MB
                            </span>
                          </GridItem>
                          <GridItem xs={3}>
                            <span className={classes.fileDetails}>
                              {moment(date).format("MM/DD/YY, hh:mm A")}
                            </span>
                          </GridItem>
                          <GridItem className={classes.justifyEnd} xs={2}>
                            <BlackDenyIcon
                              // onClick={() => handleModalClose()}
                              className={classes.closeModal}
                            />
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                  </GridItem>
                </GridContainer>
              )}
            </GridItem>

            <GridItem xs={12}>
              <span className={classes.attachmentsTitle}>Driver Notes</span>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
      <GridItem className={classes.column} xs={12}>
        <SiteInformation job={job} classes={classes} />
      </GridItem>
      <GridItem className={classes.column} xs={12}>
        <BillTo history={history} job={job} classes={classes} />
      </GridItem>
      <GridItem className={classes.justifyEnd} xs={12}>
        <Button
          onClick={() => setAreYouSureDelete(true)}
          className={classes.deleteJob}
        >
          <DenyIcon />
          <span className={classes.deleteJobText}>Delete Job</span>
        </Button>
      </GridItem>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={areYouSureDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAreYouSureDelete(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <Card className={classes.modalCard}>
          <h1 className={classes.modalTitle}>
            Are you sure you want to delete this job?
          </h1>
          <CardBody className={classes.body}>
            <Button
              onClick={deleteJob}
              style={{ background: "#ECB22E", color: "#323232" }}
              className={classes.modalCardButton}
            >
              Yes
            </Button>
            <Button
              onClick={() => setAreYouSureDelete(false)}
              style={{ color: "white", background: "#323232" }}
              className={classes.modalCardButton}
            >
              No
            </Button>
          </CardBody>
        </Card>
      </Dialog>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    inventoryData: state.inventory.inventory,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(JobDetails);
