import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"

// core components
import { Card, MenuItem, Select, Tooltip } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchStyle from "assets/jss/material-dashboard-pro-react/views/SearchStyle.js";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";

import SearchHeader from "./components/SearchHeader";
import SearchFilters from "./components/SearchFilters";
import CustomersTable from "./components/CustomersTable";
import JobsTable from "./components/JobsTable";
import RentalsTable from "./components/RentalsTable";
import SitesTable from "./components/SitesTable";
import AddCustomerModal from "../Customers/components/AddCustomerModal";

import {
  filterData,
  filterDataWithComments,
} from "../../globalHelperFunctions/globalHelperFunctions";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { KeyboardReturn } from "@material-ui/icons";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

const pageArr = ["3", "All"];
const handleFilterData = (data, selection, query) => {
  const lowerCaseQuery = query.toLowerCase();
  if (selection === "Customers" && data) {
    return data.filter((u, index) => {
      let lowerCaseName = '';
      let lowerCaseBillingAddress = '';
      let lowerCaseCompany = '';
      let lowerCaseEmail = '';
      let lowerCaseCustomerId = '';
      if (u.name) {
        lowerCaseName = u.name.toLowerCase();
      }
      if (u.billingAddress) {
        lowerCaseBillingAddress = u.billingAddress.toLowerCase();
      } else if (u.address) {
        lowerCaseBillingAddress = u.address.toLowerCase();
      }
      if (u.company) {
        lowerCaseCompany = u.company.toLowerCase();
      }
      if (u.email) {
        lowerCaseEmail = u.email.toLowerCase();
      }
      if (u.customerId) {
        lowerCaseCustomerId = u.customerId.toLowerCase();
      }
      if (
        lowerCaseName.includes(lowerCaseQuery) ||
        lowerCaseBillingAddress.includes(lowerCaseQuery) ||
        lowerCaseCompany.includes(lowerCaseQuery) ||
        lowerCaseEmail.includes(lowerCaseQuery) ||
        lowerCaseCustomerId.includes(lowerCaseQuery)
      ) {
        return true;
      }
    });
  }
  if (selection === "Jobs" || selection === "Rentals") {
    return data.filter((j, index) => {
      const lowerCaseName = j.name.toLowerCase();
      const lowerCaseBillingAddress = j.billingAddress.toLowerCase();
      const lowerCaseCompany = j.company.toLowerCase();
      const lowerCaseEmail = j.email.toLowerCase();
      const lowerCaseReservationId = j.reservation.reservationId.toLowerCase();
      if (
        lowerCaseName.includes(lowerCaseQuery) ||
        lowerCaseBillingAddress.includes(lowerCaseQuery) ||
        lowerCaseCompany.includes(lowerCaseQuery) ||
        lowerCaseEmail.includes(lowerCaseQuery) ||
        lowerCaseReservationId.includes(lowerCaseQuery)
      ) {
        return true;
      }
    });
  }
  if (selection === "Sites" && data) {
    return data.filter((s) => {
      const lowerCaseCity = s.city.toLowerCase();
      const lowerCaseAddress = s.siteAddress.toLowerCase();
      const lowerCaseSiteName = s.siteName.toLowerCase();
      if (
        lowerCaseCity.includes(lowerCaseQuery) ||
        lowerCaseAddress.includes(lowerCaseQuery) ||
        lowerCaseSiteName.includes(lowerCaseQuery)
      ) {
        return true;
      }
    });
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Search(props) {
  const { classes, customerData, history, reservationsData, sitesData } = props;
  const { category, query } = useParams();
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [searchString, setSearchString] = useState(query);
  const [categorySelection, setCategorySelection] = useState(
    category[0].toUpperCase() + category.slice(1)
  );
  const [filterSelection, setFilterSelection] = useState("First Name");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [sortBySelection, setSortBySelection] = useState("All");
  const [customers, setCustomers] = useState([]);
  const [sites, setSites] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [customerCreated, setCustomerCreated] = useState(false);
  const itemsPerPage = 3;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("3");

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)

  };

  useEffect(() => {
    if (categorySelection === "Customers") {
      customerData.slice(index, endIndex);
    } else if (
      categorySelection === "Rentals" ||
      categorySelection === "Jobs"
    ) {
      reservationsData.slice(index, endIndex);
    } else if (categorySelection === "Sites") {
      sitesData.slice(index, endIndex);
    } else {
      return;
    }
  }, [index]);

  useEffect(() => {
    if (categorySelection === "Customers") {
      console.log("customerData", customerData);
      const filtered = handleFilterData(
        customerData,
        categorySelection,
        searchString
      );
      setCustomers(filtered);
    } else {
      setCustomers(customerData);
    }
  }, [customerData]);

  useEffect(() => {
    if (categorySelection === "Sites") {
      console.log("sitesData", sitesData);
      const filtered = handleFilterData(
        sitesData,
        categorySelection,
        searchString
      );
      setSites(filtered);
    } else {
      setSites(sitesData);
    }
  }, [sitesData]);

  useEffect(() => {
    if (categorySelection === "Rentals" || categorySelection === "Jobs") {
      const filtered = handleFilterData(
        reservationsData,
        categorySelection,
        searchString
      );
      setReservations(filtered);
    } else {
      setReservations(reservationsData);
    }
  }, [reservationsData]);

  const handleFilters = (type, filterValue) => {
    if (type === "filter") {
      if (categorySelection === "Jobs" || categorySelection === "Rentals") {
        const filtered = filterDataWithComments(
          filterValue,
          directionSelection,
          sortBySelection,
          reservationsData
        );
        setReservations(filtered);
      }

      if (categorySelection === "Customers") {
        const filtered = filterDataWithComments(
          filterValue,
          directionSelection,
          sortBySelection,
          customerData
        );
        setCustomers(filtered);
      }

      // if (categorySelection === "Sites") {
      //   const filtered = filterDataWithComments(
      //     filterValue,
      //     directionSelection,
      //     sortBySelection,
      //     sitesData
      //   );
      //   setSites(filtered);
      // }
      setFilterSelection(filterValue);
    }
    if (type === "direction") {
      if (categorySelection === "Jobs" || categorySelection === "Rentals") {
        const filtered = filterDataWithComments(
          filterSelection,
          filterValue,
          sortBySelection,
          reservationsData
        );
        setReservations(filtered);
      }
      if (categorySelection === "Customers") {
        const filtered = filterDataWithComments(
          filterSelection,
          filterValue,
          sortBySelection,
          customerData
        );
        setCustomers(filtered);
      }
      // if (categorySelection === "Sites") {
      //   const filtered = filterDataWithComments(
      //     filterSelection,
      //     filterValue,
      //     sortBySelection,
      //     sitesData
      //   );
      //   setSites(filtered);
      // }
      setDirectionSelection(filterValue);
      // setCustomers([...filtered]);
    }
    if (type === "comments") {
      if (categorySelection === "Jobs" || categorySelection === "Rentals") {
        const filtered = filterDataWithComments(
          filterSelection,
          directionSelection,
          filterValue,
          reservationsData
        );
        setReservations(filtered);
      }
      if (categorySelection === "Customers") {
        const filtered = filterDataWithComments(
          filterSelection,
          directionSelection,
          filterValue,
          customerData
        );
        setCustomers(filtered);
      }
      setSortBySelection(filterValue);
      // setCustomers([...filtered]);
    }
  };

  const handleDropDownChange = (e) => {
    if (e.target.value === "Customers") {
      setCustomers(customerData);
    }
    if (e.target.value === "Jobs") {
      setReservations(reservationsData);
    }
    if (e.target.value === "Rentals") {
      setReservations(reservationsData);
    }
    if (e.target.value === "Sites") {
      setSites(sitesData);
    }
    setSearchString("");
    setCategorySelection(e.target.value);
  };

  const handleSearch = (data, selection, query) => {
    if (selection === "Customers") {
      const filtered = handleFilterData(customerData, selection, query);
      setCustomers(filtered);
    }
    if (selection === "Jobs") {
      const filtered = handleFilterData(reservationsData, selection, query);
      setReservations(filtered);
    }
    if (selection === "Rentals") {
      const filtered = handleFilterData(reservationsData, selection, query);
      setReservations(filtered);
    }
    if (selection === "Sites") {
      const filtered = handleFilterData(sitesData, selection, query);
      setSites(filtered);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <p className={classes.title}>Search</p>
      </GridItem>
      <GridItem xs={12}>
        <SearchHeader
          searchString={searchString}
          setSearchString={setSearchString}
          categorySelection={categorySelection}
          setCategorySelection={setCategorySelection}
          handleSearch={handleSearch}
          data={categorySelection === "Customers" ? customers : reservations}
          handleDropDownChange={handleDropDownChange}
        />
      </GridItem>
      <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-start", margin: "-15px 0px 0px 0px" }}>
        <h3 className={classes.subHeader}>{(
          categorySelection === "Customers" ?
          customers.length :
          categorySelection === "Jobs" || categorySelection === "Rentals" ?
          reservations.length :
          categorySelection === "Sites" ?
          sites.length : 0
          ) + " " + categorySelection
          }</h3>
      </GridItem>
      <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end", marginTop: "25px" }}>
        <Button
          onClick={() => setAddCustomerModal(true)}
          style={{ height: "50px", width: "150px", fontWeight: "600" }}
          className={classes.addCustomer}
        >
          <AddIcon /> Add Customer
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <Card style={{ padding: "5px 25px 5px 25px", marginTop: "5px" }}>
          <GridContainer>
            <GridItem xs={12} style={{ marginTop: "-15px" }}>
              <SearchFilters
                filterSelection={filterSelection}
                setFilterSelection={setFilterSelection}
                directionSelection={directionSelection}
                setDirectionSelection={setDirectionSelection}
                sortBySelection={sortBySelection}
                setSortBySelection={setSortBySelection}
                handleFilters={handleFilters}
                categorySelection={categorySelection}
                data={categorySelection === "Customers" ? customers : reservations}
                customerData={customerData}
                reservationsData={reservationsData}
              />
            </GridItem>
            <GridItem xs={12}>
              {categorySelection === "Customers" ? (
                <CustomersTable
                  history={history}
                  filteredTableData={customers}
                  itemsPerPage={itemsPerPage}
                  pageNumber={pageNumber}
                  pageDropdown={pageDropdown} />
              ) : categorySelection === "Jobs" ? (
                <JobsTable
                  pageDropdown={pageDropdown}
                  itemsPerPage={itemsPerPage}
                  pageNumber={pageNumber}
                  filteredTableData={reservations} />
              ) : categorySelection === "Rentals" ? (
                <RentalsTable
                  itemsPerPage={itemsPerPage}
                  pageNumber={pageNumber}
                  pageDropdown={pageDropdown}
                  history={history}
                  reservations={reservations} />
              ) : categorySelection === "Sites" ? (
                <SitesTable
                  history={history}
                  pageDropdown={pageDropdown}
                  itemsPerPage={itemsPerPage}
                  pageNumber={pageNumber}
                  filteredTableData={sites} />
              ) : (
                <div></div>
              )}
              {pageDropdown == "3" ?
                <GridContainer>
                  <GridItem xs={6} >
                    <p style={{ marginTop: "10px" }} className={classes.view}>View
                      <span>
                        <Select
                          style={{ width: "20%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                          disableUnderline
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                            icon: classes.whiteColor
                          }}

                          onChange={(e) => {
                            setPageDrowdown(e.target.value)
                          }}
                          value={pageDropdown}
                        >
                          {pageArr.map((d, index) => {
                            return (
                              <MenuItem
                                key={index}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }}
                                value={d}
                              >
                                {d}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </span>
                    </p>
                  </GridItem>
                  <GridItem xs={4} style={{ marginTop: "10px" }}>
                    <h5 className={classes.indexes}>{index + 1} -
                      {categorySelection === "Customers" &&
                      customers.length < endIndex
                        ? customers.length
                        : (categorySelection === "Jobs" ||
                            categorySelection === "Rentals") &&
                          reservations.length < endIndex
                        ? reservations.length
                        : categorySelection === "Sites" &&
                          sites.length < endIndex
                        ? sites.length
                        : endIndex}{" "}
                      of{" "}
                      {/* {customers.length < itemsPerPage ? " " + customers.length :
                        " " + Math.ceil(customers.length / itemsPerPage)} */}
                      {categorySelection === "Customers"
                        ? customers.length
                        : categorySelection === "Jobs" ||
                          categorySelection === "Rentals"
                        ? reservations.length
                        : categorySelection === "Sites"
                        ? sites.length
                        : 0}
                    </h5>
                  </GridItem>
                  <GridItem xs={2} style={{ marginTop: "10px" }}>
                    <ReactPaginate
                      paginationSize={0}
                      onPageChange={handlePageClick}
                      pageCount={categorySelection === "Customers" ? Math.ceil(customers.length / itemsPerPage) : Math.ceil(reservations.length / itemsPerPage)}
                      nextLabel={<ArrowRight style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                      previousLabel={<ArrowLeft style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </GridItem>
                </GridContainer>
                :
                <GridContainer>
                  <GridItem xs={6}>
                    <p className={classes.view}>View
                      <span>
                        <Select
                          style={{ width: "20%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                          disableUnderline
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                            icon: classes.whiteColor
                          }}
                          onChange={(e) => {
                            setPageDrowdown(e.target.value)
                          }}
                          value={pageDropdown}
                        >
                          {pageArr.map((d, index) => {
                            return (
                              <MenuItem
                                key={index}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }}
                                value={d}
                              >
                                {d}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </span>
                    </p>
                  </GridItem>
                </GridContainer>
              }
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={addCustomerModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAddCustomerModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <AddCustomerModal
          setCustomerCreated={setCustomerCreated}
          setAddCustomerModal={setAddCustomerModal}
        />
      </Dialog>
      <Snackbar
        open={customerCreated}
        onClose={() => setCustomerCreated(false)}
      >
        <Alert onClose={() => setCustomerCreated(false)} severity={"success"}>
          Customer Created
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("reservationsData", state.reservations.reservations);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    sitesData: state.sites.sites,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(SearchStyle)
)(Search);
