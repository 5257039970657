import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import BillToCard from "./BillTo";
import InvoiceDetailsCard from "./InvoiceDetailsCard";
import ServicesCard from "./ServicesCard";
import TermsAndConditions from "./TermsAndConditions";
import SignatureCanvas from "react-signature-canvas";
import Checkbox from "@material-ui/core/Checkbox";

function InvoiceDetails(props) {
  const {
    classes,
    invoice,
    history,
    setPaymentModal,
    sigCanvas,
    agreedToTermsOfService,
    setAgreedToTermsOfService,
    setSnackBarContent,
    setSignature,
    customer,
    reservation,
  } = props;
  const [servicesSubTotal, setServicesSubTotal] = useState("");
  const [servicesTotal, setServicesTotal] = useState("");
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState("$0.00");

  useEffect(() => {
    let subtotal = 0;
    invoice.services.forEach((s) => {
      const parsedTotal = parseFloat(s.rate);
      subtotal += parsedTotal;
    });
    setServicesSubTotal(subtotal);
    setServicesTotal(subtotal + taxTotal);
  }, [invoice]);
  console.log("servicesSubTotal", servicesSubTotal);
  return (
    <GridContainer>
      <GridItem className={classes.invoiceGridItem} xs={12}>
        <BillToCard
          customer={customer}
          reservation={reservation}
          invoice={invoice}
          history={history}
        />
      </GridItem>

      <GridItem className={classes.invoiceGridItem} xs={12}>
        <InvoiceDetailsCard
          customer={customer}
          reservation={reservation}
          invoice={invoice}
          history={history}
        />
      </GridItem>

      <GridItem className={classes.invoiceGridItem} xs={12}>
        <ServicesCard
          servicesTotal={servicesTotal}
          taxTotal={taxTotal}
          servicesSubTotal={servicesSubTotal}
          invoice={invoice}
          history={history}
          customer={customer}
          reservation={reservation}
        />
      </GridItem>

      <GridItem className={classes.invoiceGridItem} xs={12}>
        <TermsAndConditions />
      </GridItem>

      {invoice.invoiceDetails.paymentStatus !== "Paid" &&
        invoice.invoiceStatus === "Approved" && (
          <>
            <GridItem xs={12}>
              <h1 style={{ marginBottom: "20px" }} className={classes.title}>
                Please sign the canvas below with your mouse and click the
                checkbox to agree to the terms of service
                <Checkbox
                  style={{
                    color: "#323232",
                  }}
                  onChange={() => {
                    setAgreedToTermsOfService(!agreedToTermsOfService);
                  }}
                  checked={agreedToTermsOfService}
                />
              </h1>
              <SignatureCanvas
                ref={sigCanvas}
                penColor="#323232"
                canvasProps={{
                  className: classes.sigCanvas,
                }}
              />
            </GridItem>
            <GridItem className={classes.invoiceGridItem} xs={6}>
              <Button
                className={classes.payNowButton}
                onClick={() => {
                  sigCanvas.current.clear();
                }}
                style={{ background: "red", width: "100%" }}
              >
                Clear
              </Button>
            </GridItem>
            <GridItem className={classes.invoiceGridItem} xs={6}>
              <Button
                onClick={() => {
                  if (sigCanvas.current.isEmpty()) {
                    setSnackBarContent(
                      "There is a problem with your submission. You must sign the canvas before proceeding to payment"
                    );
                    // setNeedsToSignWaiverSnackbar(true);
                  } else {
                    if (!agreedToTermsOfService) {
                      setSnackBarContent(
                        "There is a problem with your submission. You must sign the canvas before proceeding to payment"
                      );
                    } else {
                      const base64Signature = sigCanvas.current
                        .getTrimmedCanvas()
                        .toDataURL("image/png");
                      setSignature(base64Signature);
                      setPaymentModal(true);
                    }
                  }
                }}
                style={{ width: "100%" }}
                className={classes.payNowButton}
              >
                Pay Now
              </Button>
            </GridItem>
          </>
        )}
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(InvoiceDetails));
