const initState = {
  messagingModal: false,
};
export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_MESSAGING_MODAL":
      return {
        ...state,
        messagingModal: action.boolean,
      };
    default:
      return state;
  }
}
