import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import regularsStyles from "assets/jss/material-dashboard-pro-react/views/regularsStyle.js";

import FrequentCustomers from "./components/FrequentCustomers";
import AddRegulars from "./components/AddRegulars";
import CustomersTable from "./components/CustomersTable";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import AddRegularModal from "./modals/AddRegularModal";
import RemoveRegularModal from "./modals/RemoveRegularModal";
import {
  makeCustomerARegular,
  removeCustomerTag,
} from "store/actions/customers/customerActions";

import {
  getNextSeven,
  getPrevSeven,
  getDatesSeven
}
  from "views/Dashboard/helpers/helpers";

import { filterData } from "../../globalHelperFunctions/globalHelperFunctions";
import InventoryTableNew from "./components/InventoryTableNew";
import Card from "components/Card/Card";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Regulars(props) {
  const { classes, regularsData, customersData, history, inventoryData } = props;
  const [searchString, setSearchString] = useState("");
  const [regulars, setRegulars] = useState([]);
  const [filterSelection, setFilterSelection] = useState("First Name");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [addRegularsSearchString, setAddRegularsSearchString] = useState("");
  const [customers, setCustomers] = useState([]);
  const [queriedCustomers, setQueriedCustomers] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [defaultHeader, setDefaultHeader] = useState([]);


  useEffect(() => {
    setRegulars(regularsData);
  }, [regularsData]);

  useEffect(() => {
    setCustomers(customersData);
  }, [customersData]);

  useEffect(() => {
    const defaultDays = getDatesSeven(
      new Date(),
      new Date().setDate(new Date().getDate() + 8)
    );
    setDefaultHeader(defaultDays);
  }, []);

  const handleAddRegularTagClick = (c) => {
    setModalType("AddRegular");
    setSelectedCustomer(c);
    setModalOpen(true);
  };
  const handleRemoveRegularTagClick = (c) => {
    setModalType("RemoveRegular");
    setSelectedCustomer(c);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalType("");
    setSelectedCustomer("");
    setModalOpen(false);
  };

  const handleFilters = (type, filterValue) => {
    if (type === "filter") {
      const filtered = filterData(
        filterValue,
        directionSelection,
        regulars
      );
      setFilterSelection(filterValue);
      setCustomers([...filtered]);
    }
    if (type === "direction") {
      const filtered = filterData(filterSelection, filterValue, regulars);
      setDirectionSelection(filterValue);
      setCustomers([...filtered]);
    }
  };

  const handleRegularsSearch = () => {
    if (searchString) {
      const filtered = regulars.filter((r) => {
        if (
          r.name.toLowerCase().includes(searchString.toLowerCase()) ||
          r.company.toLowerCase().includes(searchString.toLowerCase()) ||
          r.email.toLowerCase().includes(searchString.toLowerCase())
        ) {
          return true;
        }
      });
      setRegulars(filtered);
    } else {
      setRegulars(regularsData);
    }
  };

  const handleCustomersSearch = () => {
    if (addRegularsSearchString) {
      const filtered = customersData.filter((r) => {
        if (
          r.name
            .toLowerCase()
            .includes(addRegularsSearchString.toLowerCase()) ||
          r.company
            .toLowerCase()
            .includes(addRegularsSearchString.toLowerCase()) ||
          r.email.toLowerCase().includes(addRegularsSearchString.toLowerCase())
        ) {
          return true;
        }
      });
      setCustomers(filtered);
      setQueriedCustomers(true);
    }
  };

  const handleApproveRegular = () => {
    props.makeCustomerRegular(selectedCustomer);
    handleModalClose();
  };

  const handleRemoveCustomerTag = () => {
    props.removeCustomerTag(selectedCustomer);
    handleModalClose();
  };

  const handleNextFourteenDays = (header) => {
    const nextNine = getNextSeven(defaultHeader[2]);
    setDefaultHeader(nextNine);
  };

  const handlePreviousFourteenDays = () => {
    const prevNine = getPrevSeven(defaultHeader[2]);
    setDefaultHeader(prevNine);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} style={{marginBottom:"20px"}}>
          <h1 className={classes.title} style={{display:"flex", alignItems:"center"}}>Regulars</h1>
        </GridItem>
        <GridItem xs={6}>
          <FrequentCustomers
            handleFilters={handleFilters}
            filterSelection={filterSelection}
            setFilterSelection={setFilterSelection}
            directionSelection={directionSelection}
            setDirectionSelection={setDirectionSelection}
            regulars={regulars}
            handleSearch={handleRegularsSearch}
            searchString={searchString}
            setSearchString={setSearchString}
            history={history}
            handleRemoveRegularTagClick={handleRemoveRegularTagClick}
          />
        </GridItem>
        <GridItem xs={6}>
          <InventoryTableNew
            handleNextFourteenDays={handleNextFourteenDays}
            handlePreviousFourteenDays={handlePreviousFourteenDays}
            defaultHeader={defaultHeader}
            inventoryData={inventoryData}
            history={history}
          />
        </GridItem>
        <GridItem xs={12} style={{marginTop:"15px"}}>
          <AddRegulars
            addRegularsSearchString={addRegularsSearchString}
            setAddRegularsSearchString={setAddRegularsSearchString}
            handleSearch={handleCustomersSearch}
          />
        </GridItem>
        <Card>
          {queriedCustomers && (
            <GridItem xs={12}>
              <p style={{ margin: "20px 0px" }} className={classes.subHeader}>
                Results for "{addRegularsSearchString}"
              </p>
              <CustomersTable
                history={history}
                handleAddRegularTagClick={handleAddRegularTagClick}
                customersData={customers}
              />
            </GridItem>
          )}
        </Card>
      </GridContainer>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        {modalType === "AddRegular" ? (
          <AddRegularModal
            selectedCustomer={selectedCustomer}
            handleModalClose={handleModalClose}
            handleApproveRegular={handleApproveRegular}
          />
        ) : (
          <RemoveRegularModal
            selectedCustomer={selectedCustomer}
            handleModalClose={handleModalClose}
            handleRemoveCustomerTag={handleRemoveCustomerTag}
          />
        )}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    regularsData: state.customers.regulars,
    customersData: state.customers.customers,
    inventoryData: state.inventory.inventory,
  };
};

const mapDispatchToProps = (dispatch) => ({
  makeCustomerRegular: (customer) => dispatch(makeCustomerARegular(customer)),
  removeCustomerTag: (customer) => dispatch(removeCustomerTag(customer)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(regularsStyles)
)(React.memo(Regulars));
