import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db } from "firebase/client";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import InvoiceDetailHeader from "./components/InvoiceDetailHeader";
import InvoiceDetails from "./components/InvoiceDetails";
import loader from "assets/img/loader.gif";
import Dialog from "@material-ui/core/Dialog";
import StripeContainer from "./components/StripeContainer.js";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PublicInvoiceDetailPage(props) {
  const { classes, history } = props;
  const [editReservationModal, setEditReservationModal] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [secret, setSecret] = useState(false);
  const invoiceId = window.location.pathname.split("/")[3];
  const [paymentModal, setPaymentModal] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState("");
  const [agreedToTermsOfService, setAgreedToTermsOfService] = useState(false);
  const sigCanvas = useRef({});
  const [signature, setSignature] = useState("");
  const [customer, setCustomer] = useState(false);
  const [reservation, setReservation] = useState(false);

  useEffect(() => {
    if (!invoice) {
      const getInvoiceInfo = async () => {
        return db
          .collection("invoices")
          .doc(invoiceId)
          .onSnapshot((snapshot) => {
            const invoiceSnapshot = snapshot.data();
            console.log("snapshot", invoiceSnapshot);
            setInvoice(invoiceSnapshot);
            return db
              .collection("customers")
              .doc(invoiceSnapshot.customerId)
              .get()
              .then((customerSnap) => {
                const customerData = customerSnap.data();
                setCustomer(customerData);

                if (invoiceSnapshot.reservationId) {
                  return db
                    .collection("reservations")
                    .doc(invoiceSnapshot.reservationId)
                    .onSnapshot((reservationSnap) => {
                      const reservationData = reservationSnap.data();
                      setReservation(reservationData);
                    });
                } else {
                  setReservation(true)
                }
              });
          });
      };
      getInvoiceInfo();
    }
  }, []);

  if (!invoice || !customer || !reservation)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );
  return (
    <GridContainer className={classes.publicInvoiceGridContainer}>
      <GridItem xs={12}>
        <InvoiceDetailHeader
          invoiceId={invoiceId}
          history={history}
          invoice={invoice}
          setPaymentModal={setPaymentModal}
          customer={customer}
          reservation={reservation}
        />
      </GridItem>
      <GridItem xs={12}>
        <InvoiceDetails
          sigCanvas={sigCanvas}
          setPaymentModal={setPaymentModal}
          history={history}
          invoice={invoice}
          agreedToTermsOfService={agreedToTermsOfService}
          setAgreedToTermsOfService={setAgreedToTermsOfService}
          setSnackBarContent={setSnackBarContent}
          setSignature={setSignature}
          customer={customer}
          reservation={reservation}
        />
      </GridItem>

      <Dialog
        style={{ zIndex: "9999 !important" }}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        maxWidth="xl"
        open={paymentModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPaymentModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <StripeContainer
          setSecret={setSecret}
          secret={secret}
          history={history}
          // payInvoice={payInvoice}
          invoiceId={invoiceId}
          amount={invoice.servicesTotal}
          invoice={invoice}
          classes={classes}
          setPaymentModal={setPaymentModal}
          setSnackBarContent={setSnackBarContent}
          signature={signature}
          customer={customer}
          reservation={reservation}
        />
      </Dialog>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={snackBarContent.includes("problem") ? "error" : "success"}
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(PublicInvoiceDetailPage));
