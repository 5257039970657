import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { firebase, db, testingCustomerSignUp } from "firebase/client";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconWhite.svg";
import { ReactComponent as SwapIcon } from "assets/img/swapIconsWhite.svg";
import { ReactComponent as AddIcon } from "assets/img/addIconWhite.svg";
import { ReactComponent as ClockIcon } from "assets/img/whiteClockIcon.svg";
import moneyIcon from "assets/img/whiteMoneyIcon.png";

import "./inputForm.css";

import SearchCustomers from "./components/SearchCustomers";
import InventoryTable from "./components/InventoryTable";
import FrequentCustomers from "./components/FrequentCustomers";
import Pending from "./components/Pending";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {
  getNextFourteen,
  getPrevFourteen,
  getDates,
  seedCustomers,
  seedInventory,
  deleteCollections,
  seedReservations,
  seedPendingReservations,
  handleCheckIfPaymentDue,
  seedPastReservations,
  seedDrivers,
} from "./helpers/helpers";
import Datetime from "react-datetime";
import moment from "moment";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import ApproveModal from "./modals/ApproveModal";
import ViewModal from "./modals/ViewModal";
import ViewQuoteModal from "./modals/ViewQuoteModal";
import EditQuoteModal from "./modals/EditQuoteModal";
import ReserveModal from "./modals/ReserveModal";
import CancelQuoteModal from "./modals/CancelQuoteModal";
import NotifyModal from "./modals/NotifyModal";
import DenyModal from "./modals/DenyModal";
import EditModal from "./modals/EditModal";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  approvePendingReservation,
  denyPendingReservation,
} from "store/actions/reservations/reservationsActions";
import CustomerViewTable from "./components/CustomerViewTable";

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const heardAboutUsFromSelections = [
  "Past Customer",
  "Google",
  "Yelp",
  "HomeAdvisor",
  "Referral",
  "Other (Please specify below)",
  "NextDoor",
  "Social Media"
];

const locationSelections = [
  "Right Side of Driveway",
  "Left Side of Driveway",
  "Middle of Driveway",
  "On Street",
  "Side of House",
  "Backyard",
  "Alley",
  "Other (Please specify below)"
];

const denySelections = [
  "Size Not Available For Selected Dates",
  "Out of Delivery Range",
  "Long-term Rental",
  "Heavy Materials",
  "Price",
  "Wanted Quote",
  "Other (Please specify below)"
]

function Dashboard(props) {
  const {
    classes,
    inventoryData,
    history,
    customerData,
    regularsData,
    pendingReservationsData,
    approveReservation,
    denyReservation,
    reservationsData,
    pickupsData,
    swapsData,
    quotesData,
    user
  } = props;
  const [searchString, setSearchString] = useState("");
  const [defaultHeader, setDefaultHeader] = useState([]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [quantityOfPaymentsDue, setQuantityOfPaymentsDue] = useState(0);
  const [paymentsDue, setPaymentsDue] = useState(
    moment(new Date()).format("ddd MM/DD/YY")
  );
  console.log("quotesData", quotesData);
  const [startAddressString, setStartAddressString] = useState("");
  const [price, setPrice] = useState("");
  const [errors, setErrors] = useState({ price: false });
  const [hasErrors, setHasErrors] = useState(false);
  const [editErrors, setEditErrors] = useState({
    phoneNumber: false,
    billingAddress: false,
    siteAddress: false,
    startDate: false,
    endDate: false,
    equiptmentNeeded: false,
    equipmentLocation: false,
    price: false,
    heardAboutUsFrom: false,
  });
  const [editHasErrors, setEditHasErros] = useState(false);
  const [modalEditedSuccessful, setModalEditedSuccessful] = useState(false);
  const [quoteSnackBarContent, setQuoteSnackBarContent] = useState(false);

  useEffect(() => {
    const defaultDays = getDates(
      new Date(),
      new Date().setDate(new Date().getDate() + 8)
    );
    setDefaultHeader(defaultDays);
  }, []);

  useEffect(() => {
    //maybe need to filter reservations data for determining past due signifier
    if (reservationsData.length > 0) {
      console.log("checking reservations:", reservationsData);
      const pastDueCount = handleCheckIfPaymentDue(
        new Date(),
        reservationsData
      );
      setQuantityOfPaymentsDue(pastDueCount.length);
    }
  }, [props]);

  const handlePreviousFourteenDays = () => {
    const prevNine = getPrevFourteen(defaultHeader[2]);
    setDefaultHeader(prevNine);
  };
  const handleNextFourteenDays = (header) => {
    const nextNine = getNextFourteen(defaultHeader[2]);
    setDefaultHeader(nextNine);
  };

  const handleModalOpen = (type, item) => {
    console.log("checking items", item)
    setModalOpen(false);
    setModalType(type);
    setSelectedItem(item);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalType("");
    setModalOpen(false);
    setSelectedItem("");
  };

  // const seedMockUsers = () => {
  //   seedUsers();
  // };
  const seedMockInventoryAndDrivers = () => {
    seedInventory();
    seedDrivers();
  };
  // const seedMockReservations = () => {
  //   seedReservations();
  // };
  const deleteAllMockData = () => {
    deleteCollections();
  };
  const seedMockPendingReservations = () => {
    seedCustomers();
    seedReservations();
    // seedPendingReservations();
    seedPastReservations();
  };
  const handleSearchQuery = () => {
    history.push({
      pathname: `/admin/search/customers/${searchString}`,
    });
  };

  const handleApproveReservation = () => {
    approveReservation(selectedItem);
    testingCustomerSignUp(selectedItem)
    handleModalClose();
  };
  const handleDenyReservation = () => {
    denyReservation(selectedItem);
    handleModalClose();
  };

  return (
    <div>
      <GridContainer>
        {/* <GridItem xs={12}>
          <Button onClick={seedMockInventoryAndDrivers}>Seed Inventory And Drivers</Button>
        </GridItem> */}
        <GridItem xs={5}>
          <h1 className={classes.title}>Dashboard</h1>
        </GridItem>
        <GridItem xs={7}>
          <GridContainer>
            <GridItem className={classes.remindersGridItem} xs={7}>
              <div className={classes.eggNumberCount}>
                {quantityOfPaymentsDue}
              </div>
              {user.role && user.role === "Super Admin" ?
                <>
                  <Link
                    className={classes.link}
                    to={{
                      pathname: "/admin/upcoming-payments",
                      state: { paymentsDue: paymentsDue },
                    }}
                  >
                    <span
                      style={{ margin: "0px 20px" }}
                      className={classes.paymentsDue}
                    >
                      Payments Due {paymentsDue}
                    </span>
                  </Link>
                  <Tooltip
                    id="tooltip-top"
                    title="Change Payment Date"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <CalendarIcon
                      className={classes.calendarIcon}
                      onClick={() => setCalendarOpen(true)}
                    />
                  </Tooltip>
                  {calendarOpen && (
                    <Datetime
                      open={calendarOpen}
                      input
                      // className={classes.textFieldRoot}
                      // isValidDate={disablePastDt}
                      style={{
                        color: "#323232 !important",
                        height: "100%",
                        width: "100%",
                      }}
                      selected={paymentsDue}
                      dateFormat="MM/DD/YY"
                      onChange={(date, e) => {
                        setPaymentsDue(date.format("MM/DD/YY"));
                        const pastDueCount = handleCheckIfPaymentDue(
                          new Date(date),
                          reservationsData
                        );
                        setQuantityOfPaymentsDue(pastDueCount.length);
                        setCalendarOpen(false);
                      }}
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside
                    />
                  )}
                </>
                :
                <>
                  <Link
                    className={classes.link}
                    to={{
                      pathname: "/admin/upcoming-payments",
                      state: { paymentsDue: paymentsDue },
                    }}
                  >
                    <span
                      style={{ margin: "0px 20px", color: "red" }}
                      className={classes.paymentsDue}
                    >
                      Overdue Invoices
                    </span>
                  </Link>
                </>
              }
            </GridItem>

            <GridItem className={classes.remindersGridItem} xs={5}>
              <div className={classes.eggNumberCount}>10</div>
              {user.role && user.role === "Super Admin" ?
                <Link
                  className={classes.link}
                  to={{
                    pathname: "/admin/service-reminders",
                  }}
                >
                  <span
                    style={{ margin: "0px 20px" }}
                    className={classes.paymentsDue}
                  >
                    Service Reminders
                  </span>
                </Link>
                :
                <Link
                  className={classes.link}
                  to={{
                    pathname: "/admin/service-reminders",
                  }}
                >
                  <span
                    style={{ margin: "0px 20px" }}
                    className={classes.paymentsDue}
                  >
                    Credit Memos Available
                  </span>
                </Link>
              }
            </GridItem>
          </GridContainer>
        </GridItem>
        {user.role && user.role === "Super Admin" ?
          <>
            <GridItem xs={12}>
              <SearchCustomers
                searchString={searchString}
                setSearchString={setSearchString}
                handleSearchQuery={handleSearchQuery}
              />
            </GridItem>


            <GridItem xs={12} style={{ marginTop: "30px", marginBottom: "20px" }}>
              <InventoryTable
                handleNextFourteenDays={handleNextFourteenDays}
                handlePreviousFourteenDays={handlePreviousFourteenDays}
                defaultHeader={defaultHeader}
                inventoryData={inventoryData}
                history={history}
              />
              {console.log("checking header", defaultHeader)}
            </GridItem>


            <GridItem xs={5} style={{ marginTop: "30px" }}>
              <FrequentCustomers history={history} regularsData={regularsData} />
            </GridItem>

            <GridItem xs={7} style={{ marginTop: "7px" }}>
              <Pending
                setModalOpen={setModalOpen}
                handleModalOpen={handleModalOpen}
                pendingReservationsData={pendingReservationsData}
                pickupsData={pickupsData}
                swapsData={swapsData}
                quotesData={quotesData}
                history={history}
              />
            </GridItem>
          </>
          :
          <>
            <GridItem xs={12} style={{ marginTop: "10px" }}>
              <div style={{ display: "flex", alignContent: "center", justifyContent: "space-evenly", height: "80px", background: "#FFC821", }}>
                <Button
                  onClick={() => {
                    history.push('/admin/request-rental')
                  }}
                  className={classes.customerViewButton}
                >
                  <AddIcon className={classes.customerViewIcon} /> New Rental
                </Button>
                <Button 
                className={classes.customerViewButton}
                onClick={() => {
                  history.push('/admin/request-early-pickup')
                }}
                >
                  <ClockIcon className={classes.customerViewIcon} /> Request Early Pick Up
                </Button>
                <Button
                  className={classes.customerViewButton}
                  onClick={() => {
                    history.push('/admin/request-swap')
                  }}
                >
                  <SwapIcon className={classes.customerViewIcon} /> Swap
                </Button>
                <Button className={classes.customerViewButton}>
                  <img src={moneyIcon} style={{ height: "15px", marginRight: "10px" }} /> Pay Invoice
                </Button>
                <Button
                  className={classes.customerViewButton}
                  onClick={() => {
                    history.push('/admin/create-quote')
                  }}
                >
                  <AddIcon className={classes.customerViewIcon} /> New Quote
                </Button>
                <Button 
                className={classes.customerViewButton}
                onClick={() => {
                  history.push('/admin/request-change')
                }}
                >
                  <EditIcon className={classes.customerViewIcon} /> Request Change
                </Button>
              </div>
            </GridItem>
            <GridItem xs={12}>
              <CustomerViewTable />
            </GridItem>
          </>
        }
      </GridContainer>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        {modalType === "Approve" ? (
          <ApproveModal
            editErrors={editErrors}
            editHasErrors={editHasErrors}
            setEditHasErrors={setEditErrors}
            inventoryData={inventoryData}
            handleModalClose={handleModalClose}
            selectedPendingReservation={selectedItem}
            handleModalOpen={handleModalOpen}
            setModalEditedSuccessful={setModalEditedSuccessful}
            handleApproveReservation={handleApproveReservation}
            heardAboutUsFromSelections={heardAboutUsFromSelections}
            locationSelections={locationSelections}
          />
        ) : modalType === "Edit" ? (
          <EditModal
            editErrors={editErrors}
            editHasErrors={editHasErrors}
            setEditHasErrors={setEditErrors}
            inventoryData={inventoryData}
            handleModalClose={handleModalClose}
            selectedPendingReservation={selectedItem}
            setModalEditedSuccessful={setModalEditedSuccessful}
            heardAboutUsFromSelections={heardAboutUsFromSelections}
            locationSelections={locationSelections}
          />
        ) : modalType === "View" ? (
          <ViewModal
            editErrors={editErrors}
            editHasErrors={editHasErrors}
            setEditHasErrors={setEditErrors}
            inventoryData={inventoryData}
            handleModalClose={handleModalClose}
            selectedPendingReservation={selectedItem}
            handleModalOpen={handleModalOpen}
            setModalEditedSuccessful={setModalEditedSuccessful}
            heardAboutUsFromSelections={heardAboutUsFromSelections}
            locationSelections={locationSelections}
          />
        ) : modalType === "Deny" ? (
          <DenyModal
            handleModalClose={handleModalClose}
            handleDenyReservation={handleDenyReservation}
            selectedPendingReservation={selectedItem}
            heardAboutUsFromSelections={heardAboutUsFromSelections}
            locationSelections={locationSelections}
            denySelections={denySelections}
          />
        ) : modalType === "View Quote" ? (
          <ViewQuoteModal
            editErrors={editErrors}
            editHasErrors={editHasErrors}
            setEditHasErrors={setEditErrors}
            inventoryData={inventoryData}
            handleModalClose={handleModalClose}
            selectedPendingReservation={selectedItem}
            handleModalOpen={handleModalOpen}
            setModalEditedSuccessful={setQuoteSnackBarContent}
            heardAboutUsFromSelections={heardAboutUsFromSelections}
            locationSelections={locationSelections}
          />
        ) : modalType === "Edit Quote" ? (
          <EditQuoteModal
            editErrors={editErrors}
            editHasErrors={editHasErrors}
            setEditHasErrors={setEditErrors}
            inventoryData={inventoryData}
            handleModalClose={handleModalClose}
            selectedPendingReservation={selectedItem}
            handleModalOpen={handleModalOpen}
            setModalEditedSuccessful={setQuoteSnackBarContent}
            heardAboutUsFromSelections={heardAboutUsFromSelections}
            locationSelections={locationSelections}
          />
        ) : modalType === "Reserve" ? (
          <ReserveModal
            editErrors={editErrors}
            editHasErrors={editHasErrors}
            setEditHasErrors={setEditErrors}
            inventoryData={inventoryData}
            handleModalClose={handleModalClose}
            selectedPendingReservation={selectedItem}
            handleModalOpen={handleModalOpen}
            setModalEditedSuccessful={setQuoteSnackBarContent}
            heardAboutUsFromSelections={heardAboutUsFromSelections}
            locationSelections={locationSelections}
          />
        ) : modalType === "Cancel Quote" ? (
          <CancelQuoteModal
            editErrors={editErrors}
            editHasErrors={editHasErrors}
            setEditHasErrors={setEditErrors}
            inventoryData={inventoryData}
            handleModalClose={handleModalClose}
            selectedPendingReservation={selectedItem}
            handleModalOpen={handleModalOpen}
            setModalEditedSuccessful={setQuoteSnackBarContent}
            heardAboutUsFromSelections={heardAboutUsFromSelections}
            locationSelections={locationSelections}
          />
        ) : modalType === "Notify" ? (
          <NotifyModal
            editErrors={editErrors}
            editHasErrors={editHasErrors}
            setEditHasErrors={setEditErrors}
            inventoryData={inventoryData}
            handleModalClose={handleModalClose}
            selectedPendingReservation={selectedItem}
            handleModalOpen={handleModalOpen}
            setModalEditedSuccessful={setQuoteSnackBarContent}
            heardAboutUsFromSelections={heardAboutUsFromSelections}
            locationSelections={locationSelections}
          />
        ) : (
          <div></div>
        )}
      </Dialog>

      <Snackbar
        open={modalEditedSuccessful}
        onClose={() => setModalEditedSuccessful(false)}
      >
        <Alert
          onClose={() => setModalEditedSuccessful(false)}
          severity={"success"}
        >
          Pending Reservation Updated Successfully
        </Alert>
      </Snackbar>

      <Snackbar
        open={quoteSnackBarContent}
        onClose={() => setQuoteSnackBarContent(false)}
      >
        <Alert
          onClose={() => setQuoteSnackBarContent(false)}
          severity={"success"}
        >
          {quoteSnackBarContent}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  console.log("state", state.auth.user)
  return {
    user: state.auth.user,
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  approveReservation: (reservation) =>
    dispatch(approvePendingReservation(reservation)),
  denyReservation: (reservation) =>
    dispatch(denyPendingReservation(reservation)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(dashboardStyles)
)(React.memo(Dashboard));
