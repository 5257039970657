import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import regularsStyles from "assets/jss/material-dashboard-pro-react/views/regularsStyle.js";

import { ReactComponent as SwapIcon } from "assets/img/swapIcons.svg";
import { ReactComponent as PickUpIcon } from "assets/img/pickupIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as InfoIcon } from "assets/img/infoIcon.svg";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AccountingIcon } from "assets/img/accountingIcon.svg";
import { ReactComponent as DumpsterIcon } from "assets/img/dumpster.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIconBlack.svg";
import Tooltip from "@material-ui/core/Tooltip";

import { Card, MenuItem, Select } from "@material-ui/core";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

import Filters from "./Filters";

const pageArr = ["3", "All"]

function FrequentCustomers(props) {
  const {
    classes,
    regulars,
    filterSelection,
    setFilterSelection,
    directionSelection,
    setDirectionSelection,
    handleSearch,
    searchString,
    setSearchString,
    history,
    handleRemoveRegularTagClick,
    handleFilters,
  } = props;

  const itemsPerPage = 3;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("3")


  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)
  };

  useEffect(() => {
    regulars.slice(index, endIndex)
  }, [index])

  const renderTableData = () => {
    if (pageDropdown == "3") {
      return regulars
        .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
        .map((d, index) => {
          return (
            <tr className={classes.tableRow} key={d.name}>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                <span
                  onClick={() =>
                    history.push({
                      pathname: `/admin/customers/${d.customerId}`,
                      state: { customer: d },
                    })
                  }
                  className={classes.nameAndCompanyText}
                >
                  {" "}
                  {d.name}
                </span>{" "}
                |
                <span className={d.company ? classes.nameAndCompanyText : ""}>
                  {d.company || "N/A"}
                </span>
              </td>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                <div style={{ display: "flex", width: "100%" }}>
                  <Tooltip
                    id="tooltip-top"
                    title="Create Swap"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      style={{ background: "#FFC720" }}
                      className={classes.frequentButtonDiv}
                    >
                      <SwapIcon /> <span></span>
                    </div>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Create Pick Up"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      style={{ background: "#ECB22E" }}
                      className={classes.frequentButtonDiv}
                    >
                      <PickUpIcon /> <span></span>
                    </div>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Create Rental"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      style={{ background: "#D9D9D9" }}
                      className={classes.frequentButtonDiv}
                    >
                      <AddIcon /> <span></span>
                    </div>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Accounting"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      style={{ background: "#323232" }}
                      className={classes.frequentButtonDiv}
                    >
                      <AccountingIcon />
                      <span style={{ color: "white" }}></span>
                    </div>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Go to Slack"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      style={{ background: "#FFFFFF" }}
                      className={classes.frequentButtonDiv}
                    >
                      <SlackIcon />
                      <span></span>
                    </div>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Remove Regular Tag"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <div
                      onClick={() => handleRemoveRegularTagClick(d)}
                      style={{ background: "#F5EB29" }}
                      className={classes.frequentButtonDiv}
                    >
                      <DenyIcon />
                      <span ></span>
                    </div>
                  </Tooltip>
                </div>
              </td>
            </tr>
          );
        });
    }
    else {
      return regulars
        .map((d, index) => {
          return (
            <tr className={classes.tableRow} key={d.name}>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                <span
                  onClick={() =>
                    history.push({
                      pathname: `/admin/customers/${d.customerId}`,
                      state: { customer: d },
                    })
                  }
                  className={classes.nameAndCompanyText}
                >
                  {" "}
                  {d.name}
                </span>{" "}
                |
                <span className={d.company ? classes.nameAndCompanyText : ""}>
                  {d.company || "N/A"}
                </span>
              </td>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{ background: "#FFC720" }}
                    className={classes.frequentButtonDiv}
                  >
                    <SwapIcon /> <span></span>
                  </div>
                  <div
                    style={{ background: "#ECB22E" }}
                    className={classes.frequentButtonDiv}
                  >
                    <PickUpIcon /> <span></span>
                  </div>
                  <div
                    style={{ background: "#D9D9D9" }}
                    className={classes.frequentButtonDiv}
                  >
                    <AddIcon /> <span></span>
                  </div>

                  <div
                    style={{ background: "#323232" }}
                    className={classes.frequentButtonDiv}
                  >
                    <AccountingIcon />
                    <span style={{ color: "white" }}></span>
                  </div>
                  <div
                    style={{ background: "#FFFFFF" }}
                    className={classes.frequentButtonDiv}
                  >
                    <SlackIcon />
                    <span></span>
                  </div>
                  <div
                    onClick={() => handleRemoveRegularTagClick(d)}
                    style={{ background: "#F5EB29" }}
                    className={classes.frequentButtonDiv}
                  >
                    <DenyIcon />
                    <span></span>
                  </div>
                </div>
              </td>
            </tr>
          );
        });
    }
  };

  return (

    <GridContainer className={classes.searchGridContainer}>
      <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
        <p className={classes.subHeader}>{regulars.length} Regulars</p>
      </GridItem>
      <Card style={{ marginTop: "10px", padding: "15px 5px 0px 5px" }}>
        <GridItem xs={12}>
          <Filters
            handleFilters={handleFilters}
            filterSelection={filterSelection}
            setFilterSelection={setFilterSelection}
            directionSelection={directionSelection}
            setDirectionSelection={setDirectionSelection}
            handleSearch={handleSearch}
            setSearchString={setSearchString}
            searchString={searchString}
          />
        </GridItem>

        <GridItem xs={12}>
          <div style={pageDropdown != 3 && regulars.length > 3 ? { height: "270px", width: "100%", overflowY: "scroll" } : null}>
            <table className={classes.inventoryTable}>
              <tbody>{renderTableData()}</tbody>
            </table>
          </div>
          {pageDropdown == "3" ?
            <GridContainer>
              <GridItem xs={6} >
                <p className={classes.view}>View
                  <span>
                    <Select
                      style={{ width: "20%", marginLeft: "10px", marginTop: "10px", textAlign: "center" }}
                      disableUnderline
                      MenuProps={{
                        // className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor
                      }}

                      onChange={(e) => {
                        setPageDrowdown(e.target.value)
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
              <GridItem xs={3} style={{ marginTop: "10px" }}>
                <h5 className={classes.indexes}>{index + 1} - {endIndex} of
                  {regulars.length < itemsPerPage ? " " + regulars.length :
                    " " + Math.ceil(regulars.length / itemsPerPage)}
                </h5>
              </GridItem>
              <GridItem xs={3} style={{ marginTop: "10px" }}>
                <ReactPaginate
                  paginationSize={0}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(regulars.length / itemsPerPage)}
                  nextLabel={<ArrowRight style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                  previousLabel={<ArrowLeft style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </GridItem>
            </GridContainer>
            :
            <GridContainer>
              <GridItem xs={6}>
                <p className={classes.view}>View
                  <span>
                    <Select
                      style={{ width: "20%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                      disableUnderline
                      MenuProps={{
                        // className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor
                      }}
                      onChange={(e) => {
                        setPageDrowdown(e.target.value)
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
            </GridContainer>
          }
        </GridItem>
      </Card>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    regularsData: state.customers.regulars,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(regularsStyles)
)(FrequentCustomers);
