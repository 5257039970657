import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";

function QuoteType(props) {
  const {
    classes,
    quote,
    customerData,
    sites,
    customer,
    setCustomer,
    site,
    setSite,
    errors,
  } = props;

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={5}>
          <span className={classes.inputTypeTitle}>
            Customer{" "}
            {errors.customer && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <Autocomplete
            id="country-select-demo"
            // style={{ width: 180 }}
            options={customerData.map((c) => c.name)}
            classes={{
              option: classes.option,
            }}
            onChange={(e) => {
              const index = e.target.id.split("-")[4];
              console.log("indexx", e.target.id.split("-"));
              setCustomer(customerData[index]);
            }}
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <SearchIcon
                        className={classes.calendarIcon}
                        // onClick={openCalendar}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Site{" "}
            {errors.site && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <Autocomplete
            id="country-select-demo"
            // style={{ width: 180 }}
            options={sites.map((c) => c.siteAddress)}
            classes={{
              option: classes.option,
            }}
            style={{ paddingRight: "5px" }}
            onChange={(e) => {
              const index = e.target.id.split("-")[4];
              console.log("indexx", e.target.id.split("-"));
              setSite(sites[index]);
              // setCustomerBillingAddress(customerData[index].billingAddress);
              // setSiteOwner(customerData[index]);
              // setSelectedCustomerName(customerData[index].name);
            }}
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <SearchIcon
                        className={classes.calendarIcon}
                        // onClick={openCalendar}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
    sites: state.sites.sites,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(QuoteType));
