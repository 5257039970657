const reservationDetailStyle = (theme) => ({
  row: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  justifyEnd: {
    display: "flex",
    justifyContent: "end",
  },
  cardMargin: {
    marginTop: "30px",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
  },
  radioChecked: {
    width: "30px",
    height: "30px",
    border: "1px solid #FFC821",
    color: "#FFC821",
    borderRadius: "50%",
  },
  radioUnchecked: {
    width: "30px",
    height: "30px",
    border: "1px solid #FFC821",
    borderRadius: "50%",
  },
  columnGridItemJustifyCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  reservationDetailStyle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
  },
  spaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  columnGridItem: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  gridItemWithJustifyEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  inputTypeTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    marginBottom: "10px",
  },
  alignedCenter: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px",
    textAlign: "left",
  },
  customerIdInvoiceDetail: {
    fontWeight: 400,
    fontSize: "17px",
    fontFamily: "Archivo",
    color: "#0071C5",
    margin: "0px",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  paymentsDivRed: {
    width: "50%",
    borderLeft: "7px solid #F83030",
  },
  paymentsDivGreen: {
    width: "50%",
    borderLeft: "7px solid #2EB67D",
  },
  paymentDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingLeft: "10px",
  },
  ReservationInfoLeftCard: {
    background: "#FFFFFF",
    border: "0.5px solid #8E8E8E",
    padding: "15px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  jobsLeftCard: {
    padding: "30px 30px 0px 30px",
    background: "#FFFFFF",
    border: "0.5px solid #8E8E8E",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  inventoryRentedCard: {
    background: "#FFFFFF",
    border: "0.5px solid #8E8E8E",
    padding: "25px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  invoiceCard: {
    background: "#FFFFFF",
    border: "0.5px solid #8E8E8E",
    padding: "25px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "0px",
    marginBottom: "30px",
  },
  inventoryTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    // [theme.breakpoints.down("md")]: {
    //   tableLayout: "fixed",
    //   width: "100%",
    // },
  },
  tableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "30px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "19px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  tableRow: {
    height: "95px",
  },
  tableHeaders: {
    border: "0.5px solid #8E8E8E",
    background: "#D9D9D9",
    color: "#323232",
    fontFamily: "Archivo",
    fontSize: "17px",
    padding: "10px",
  },
  invoiceGridItem: {
    marginBottom: "30px",
  },
  jobsCard: {
    background: "#FFFFFF",
    border: "0.5px solid #8E8E8E",
    padding: "25px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "0px",
  },
  inventoryRentedTable: {
    background: "#FFFFFF",
    border: "0.5px solid #8E8E8E",
    padding: "15px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  ReservationInfoRightCard: {
    background: "#F3F3F3",
    border: "0.5px solid #8E8E8E",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "15px",
  },
  mainGrid: {
    height: "100%",
  },
  addedPadding: {
    padding: "30px 30px 0px 30px !important",
  },
  addedPaddingLeft: {
    padding: "0px 30px 0px 30px !important",
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "19px",
    color: "#323232",
  },
  category: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
  },
  categoryValue: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
  },
  reservationIdJobDetail: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#0071C5",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  categoryTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
    marginBottom: "20px",
    marginRight: "40px",
  },
  paidDiv: {
    background: "#2EB67D",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "10%",
    padding: "10px",
    color: "white",
  },
  overdueDiv: {
    background: "#F83030",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "10%",
    padding: "10px",
    color: "white",
  },
  editCustomer: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "100%",
    padding: "10px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    color: "#323232",
    background: "#FFC720",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
    whiteSpace: "noWrap"
  },
  inventoryHeaderGridItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  jobsHeaderGridItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "0px",
  },
  tableGridItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  dateRange: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#323232",
    marginRight: "30px",
  },
  calendarIconDiv: {
    background: "#F1C84E",
    borderRadius: "7px",
    padding: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  editRentalRatesGridItem: {
    display: "flex",
    justifyContent: "center",
  },
  payNowButton: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    width: "45%",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    background: "#2EB67D",
    color: "white",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
      background: "#2EB67D",
    },
  },
  editRentalRatesButton: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    background: "#FFC821",
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
      color: "#323232",
    },
  },
  siteInfoTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textTransform: "uppercase",
    color: "#323232",
    marginBottom: "30px",
  },
  siteInfoValue: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    marginBottom: "30px",
    padding: "0px !important",
  },
  contactsText: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "28px",
    color: "#323232",
    padding: "0px !important",
  },
  selectMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "100%",
    height: "50px",
    marginRight: "15px",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
  },
  jobsSelectMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "100%",
    height: "50px",
    marginRight: "15px",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
  },
  attachmentsSelectMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    marginRight: "15px",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
  },
  attachmentsTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "23px",
    textTransform: "uppercase",
    color: "#323232",
  },
  notesTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    marginBottom: "20px",
  },
  notesInfo: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
  },
  addCustomer: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "23px",
    // textTransform: "uppercase",
    color: "#323232",
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "12px",
    display: "flex",
    justifyContent: "space-evenly",
    "&:hover": {
      background: "#FFC720",
    },
  },
  commentDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    margin: "0px 10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
  },
  totalsTitle: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    textTransform: "uppercase",
    textAlign: "center",
  },
  textCenter: {
    textAlign: "end",
  },
  modifiedDiscountInput: {
    height: "20px",
  },
  emailInput: {
    background: "#F5F5F5",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    width: "100%",
    marginBottom: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: "33px",
    color: "#000000",
  },
  marginBottomGridItem: {
    marginBottom: "15px",
  },
  totalsValue: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    textTransform: "uppercase",
  },
  invoiceTotal: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    textTransform: "uppercase",
  },
  end: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginBottom: "10px",
  },
  statusDiv: {
    // background: "#F83030",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80px",
  },
  termsText: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
  },
  justifyEnd: {
    display: "flex",
    justifyContent: "end",
  },
  attachmentGridContainer: {
    marginBottom: "15px",
  },
  fileName: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    textDecoration: "underline",
    color: "#0071C5",
    "&:hover": {
      cursor: "pointer",
    },
  },
  fileDetails: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
  },
  closeModal: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  deleteJob: {
    background: "#323232",
    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
  },
  deleteJobText: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "17px",
    color: "#FFFFFF",
    marginLeft: "10px",
  },
  modalCard: {
    margin: "0px",
    background: "rgb(243, 250, 255)",
  },
  modalTitle: {
    textAlign: "center",
    background: "#F1C84E !important",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "30px",
    margin: "0px",
    padding: "30px",
  },
  modalCardBody: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    flexDirection: "column",
  },
  modalCardButton: {
    width: "100%",
    height: "60px",
    color: "#323232",
    "&:hover": {
      cursor: "pointer",
    },
  },
  body: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
  },
  sigCanvas: {
    background: "white",
    border: "3px solid #323232",
    width: "100%",
    height: "200px",
  },
  viewEditButton: {
    background: "#FFC821",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "13px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "17px",
  },
  cancelSaveButton: {
    background: "#323232",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "10%",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
  },
  cancelSaveBtnGrid: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  datetime: {
    background: "white",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    width: "100%",
    position: "relative",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "text",
    display: "inline-flex",
    fontSize: "1rem",
    boxSizing: "border-box",
    fontFamily: "Archivo",
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    border: "1px solid lightgray",
  },
  filterMenu: {
    marginBottom:"20px",
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "100%",
    height: "42px",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "19px",
    textAlign: "center"
  },
  addCustomer: {
    textTransform: "capitalize",
    whiteSpace: "noWrap",
    background: "#FFC720",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "#323232",
    padding: "12px",
    width: "45%",
    display: "flex",
    justifyContent: "space-evenly",
    "&:hover": {
      background: "#FFC720",
    },
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
  },
  dataResult:{
    marginTop:"5px",
    width:"300px",
    height:"200px",
    backgroundColor:"white",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    overflow: "hidden",
    overFlowY: "auto",
  },
  hoverOne:{
    "&:hover": {
      background: "#FFC720",
      cursor: "pointer",
    },
  }

});

export default reservationDetailStyle;
