import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import serviceRemindersStyle from "assets/jss/material-dashboard-pro-react/views/serviceRemindersStyle.js";
import Switch from "@material-ui/core/Switch";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

function ServiceTypeToggle(props) {
  const { classes, handleChange, serviceType, setServiceType } = props;

  return (
    <div className={classes.toggleDiv}>
      <div
        onClick={handleChange}
        style={
          serviceType === "Truck"
            ? {
                background: "#323232",
                border: "0.5px solid #8E8E8E",
                borderRadius: "50px",
                color: "white"
              }
            : {}
        }
        className={classes.toggleSelection}
      >
        Trucks
      </div>
      <div
        onClick={handleChange}
        style={
          serviceType === "Dumpster"
            ? {
                background: "#323232",
                border: "0.5px solid #8E8E8E",
                borderRadius: "50px",
                color: "white"
              }
            : {}
        }
        className={classes.toggleSelection}
      >
        Dumpsters
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(serviceRemindersStyle)
)(React.memo(ServiceTypeToggle));
