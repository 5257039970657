import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import CustomerViewTabs from "./CustomerViewTabs";
import CustomerViewRentals from "./CustomerViewRental/CustomerViewRentals";
import CustomerViewSites from "./CustomerViewSites/CustomerViewSites";
import CustomerViewInvoices from "./CustomerViewInvoices/CustomerViewInvoices";
function CustomerViewTable(props) {
  const { classes, history } = props;

  return (
    <GridContainer>
      <GridItem className={classes.customTabsGridItem} xs={12}>
        <CustomerViewTabs
          rentalsClasses={classes}
          tabs={[
            {
              tabName: "Rentals",
              tabContent: <CustomerViewRentals classes={classes} history={history} />,
            },
            {
              tabName: "Sites",
              tabContent: <CustomerViewSites classes={classes} history={history} />,
            },
            {
              tabName: "Invoices",
              tabContent: <CustomerViewInvoices classes={classes} history={history} />,
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(CustomerViewTable));
