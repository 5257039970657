import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { BsFillCircleFill } from "react-icons/bs";
import { MenuItem, Select } from "@material-ui/core";

const headers = [
  "Name",
  "Preferences",
  "Actions",
];

const mockData = [
  {
    name: "Northeast"
  },
  {
    name: "Northwest"
  },
  {
    name: "Southeast"
  },
  {
    name: "Southwest"
  },
  {
    name: "Extended"
  }
];

const driverArr = ["Select Driver"]

function TerritoriesTable(props) {
  const {
    classes,
    reservations,
    history,
    pageDropdown,
    pageNumber,
    itemsPerPage,
    user
  } = props;
  const [driver, setDriver] = useState("Select Driver")

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    return mockData
      .map((d, index) => {
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td className={classes.tableCell}>
              <BsFillCircleFill
                className={classes.bulletPoint}
                style={
                  d.name === "Northeast" ?
                    { color: "#E01E5A" }
                    : d.name === "Northwest" ?
                      { color: "#3F9C42" }
                      : d.name === "Southeast" ?
                        { color: "#0071C5" }
                        : d.name === "Southwest" ?
                          { color: "#ECB22E" }
                          : { color: "#929292" }
                }
              />
              {d.name}</td>
            <td className={classes.tableCell}>
              <Select
                style={{ width: "100%", border: "2px solid #FEDA1B", paddingLeft: "5px" }}
                className={classes.filterMenu}
                value={driver}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => setDriver(e.target.value)}
              >
                {driverArr.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#FFC821" }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Deny"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#323232" }}
                    className={classes.actionBtn}
                  >
                    <DenyIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <table className={classes.inventoryTable}>
          <tbody>
            {renderTableHeader()}
            {renderTableData()}
          </tbody>
        </table>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(TerritoriesTable));
