import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import AgingTable from "./components/AgingTable";
import { MenuItem, Select } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

// import BusinessOverview from "./components/BusinessOverview";
// import WhoOwes from "./components/WhoOwes";
// import SalesAndCustomers from "./components/SalesAndCustomers";

function AgingReport(props) {
  const { classes, reservationsData, history } = props;
  const [checkedCount, setCheckedCount] = useState(0);
  const [checkedAll, setCheckedAll] = useState(false);


  const [agingData, setAgingData] = useState([
    {
      name: "Aaron Guardado",
      current: "$0.00",
      oneThroughThirty: "$4,313.00",
      thirtyOneThroughSixty: "$0.00",
      sixtyOneThroughNinety: "$0.00",
      overNinety: "$0.00",
      total: "$4,313.00",
      checked: false,
    },
    {
      name: "Steve Miller",
      current: "$0.00",
      oneThroughThirty: "$1,938.98",
      thirtyOneThroughSixty: "$320.00",
      sixtyOneThroughNinety: "$0.00",
      overNinety: "$0.00",
      total: "$2,303.98",
      checked: false,
    },
    {
      name: "Ron Becat",
      current: "$0.00",
      oneThroughThirty: "$1,350.00",
      thirtyOneThroughSixty: "$0.00",
      sixtyOneThroughNinety: "$0.00",
      overNinety: "$0.00",
      total: "$1,350.00",
      checked: false,
    },
    {
      name: "Bernard Charles",
      current: "$0.00",
      oneThroughThirty: "$1,532.00",
      thirtyOneThroughSixty: "$0.00",
      sixtyOneThroughNinety: "-$487.00",
      overNinety: "$0.00",
      total: "$1,075.00",
      checked: false,
    },
  ]);

  useEffect(() => {
    if (checkedAll) {
      setAgingData(
        agingData.map((i) => {
          i.checked = true;
          return i;
        })
      );
      setCheckedCount(agingData.length);
    } else {
      setAgingData(
        agingData.map((i) => {
          i.checked = false;
          return i;
        })
      );
      setCheckedCount(0);
    }
  }, [checkedAll]);

  return (
    <GridContainer>
      <GridItem className={classes.jobsHeaderGridItem} xs={12}>
        <h1 className={classes.title}>Aging</h1>
      </GridItem>
      <GridItem xs={12}>
        <AgingTable
          history={history}
          agingData={agingData}
          setCheckedCount={setCheckedCount}
          checkedCount={checkedCount}
          setCheckedAll={setCheckedAll}
          checkedAll={checkedAll}
          setAgingData={setAgingData}
          // pageNumber={pageNumber}
          // pageDropdown={pageDropdown}
          // itemsPerPage={itemsPerPage} 
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(AgingReport));
