import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import GoogleMap from "./GoogleMap";

function SiteInformation(props) {
  const { classes, site, siteID, contacts } = props;
  console.log('site', site)

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Site Nickname
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {site.siteName ? site.siteName : "N/A"}
            </GridItem>

            <GridItem className={classes.siteInfoTitle} xs={6}>
              Territory
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              N/A
            </GridItem>

            <GridItem className={classes.siteInfoTitle} xs={6}>
              Address
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {site.siteAddress}
            </GridItem>

            <GridItem className={classes.siteInfoTitle} xs={6}>
              County
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              N/A
            </GridItem>

            <GridItem className={classes.siteInfoTitle} xs={6}>
              Site ID
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {siteID}
            </GridItem>

            <GridItem className={classes.siteInfoTitle} xs={6}>
              Load Type
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              N/A
            </GridItem>

            <GridItem className={classes.siteInfoTitle} xs={6}>
              Contacts
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {contacts.map((c, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}
                  >
                    <span className={classes.contactsText}>{c.name}</span>
                    <span className={classes.contactsText}>{c.email}</span>
                    <span className={classes.contactsText}>{c.phoneNumber}</span>
                  </div>
                );
              })}
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6}>
          <GoogleMap site={site} />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(SiteInformation));
