import { logging } from "variables/config"

function log(actionName, asyncAction) {
    return dispatch => {
        console.log(`asyncAction ${actionName} started`)
        return Promise.resolve(dispatch(asyncAction))
            .then(result => {
                console.log(`asyncAction ${actionName} returns`, result);
                return result;
            });
    }    
}

function dontLog(_actionName, asyncAction) {
    return asyncAction;
}

export const logger = logging.LOG_ASYNC_ACTIONS ? log : dontLog;

export function handleError(action, errorAction) {
    return logger("handleError", (dispatch) => Promise.resolve(dispatch(action)).catch(error => { dispatch(errorAction(error)) }));
}

export function ignoreError(action) {
    return logger("ignoreError", (dispatch) => Promise.resolve(dispatch(action)).catch(error => undefined));
}

export function pipeline(head, ...actions) {
    return logger("pipeline", 
        (dispatch) => actions.reduce((accumulator, action) => {
            return accumulator.then((result) => {
                return Promise.resolve(dispatch(action(result)))
            });
        }, Promise.resolve(dispatch(head)))
    );    
}

export function chain(...actions) {
    return logger("chain",
        (dispatch) => actions.reduce((accumulator, action) => {
            return accumulator.then(() => {
                return Promise.resolve(dispatch(action))
            });
        }, Promise.resolve())
    );    
}

export function bind(action, ...parameters) {
    return (...moreParameters) => logger(`bind ${parameters}`, (dispatch) => dispatch(action(...parameters, ...moreParameters)));
}



