import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db } from "firebase/client";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import PaymentDetailHeader from "./components/PaymentDetailHeader";
import PaymentDetails from "./components/PaymentDetails";
import BillTo from "./components/BillTo";
import InvoiceCard from "./components/InvoiceCard";
import loader from "assets/img/loader.gif";

function PaymentDetail(props) {
  const { classes, history } = props;
  const [editReservationModal, setEditReservationModal] = useState(false);
  const [payment, setPayment] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [reservation, setReservation] = useState(false);
  const [customer, setCustomer] = useState(false);
  const paymentId = window.location.pathname.split("/")[3];

  console.log("reservationxxxxxx", reservation);

  useEffect(() => {
    if (!payment) {
      const getPaymentInfo = async () => {
        return db
          .collection("payments")
          .doc(paymentId)
          .get()
          .then((snapshot) => {
            snapshot = snapshot.data();
            setPayment(snapshot);
            return db
              .collection("invoices")
              .doc(snapshot.invoiceId)
              .get()
              .then((snap) => {
                const invoiceData = snap.data();
                console.log('look invoice', snap, invoiceData)
                setInvoice(invoiceData);
                const reservationID = invoiceData && invoiceData.reservationId;
                if (reservationID) {
                  const promiseArray = [
                    db.collection("reservations").doc(reservationID).get(),
                    db
                      .collection("pending-reservations")
                      .doc(reservationID)
                      .get(),
                    db.collection("past-reservations").doc(reservationID).get(),
                  ];
                  Promise.all(promiseArray).then((results) => {
                    console.log("resultsss", results);
                    results.forEach((snapshot) => {
                      if (snapshot.exists) {
                        snapshot = snapshot.data();
                        setReservation(snapshot);
                      }
                    });
                    return db
                      .collection("customers")
                      .doc(invoiceData.customerId)
                      .get()
                      .then((customerData) => {
                        setCustomer(customerData.data());
                      });
                  });
                }
              });
          });
      };
      getPaymentInfo();
    }
  }, []);

  if (!payment)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );
  return (
    <GridContainer>
      <GridItem xs={12}>
        <PaymentDetailHeader payment={payment} />
      </GridItem>
      <GridItem xs={12}>
        <PaymentDetails
          invoice={invoice}
          reservation={reservation}
          history={history}
          payment={payment}
        />
      </GridItem>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        {invoice ?
          <InvoiceCard
            reservation={reservation}
            payment={payment}
            history={history}
            invoice={invoice}
          /> : "No invoice found"
        }
      </GridItem>
      <GridItem xs={12}>
        {invoice && customer ? (
          <BillTo
            customer={customer}
            reservation={reservation}
            payment={payment}
            history={history}
            invoice={invoice}
          />
        ) : (
          "No invoice or customer found"
        )}
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(PaymentDetail));
