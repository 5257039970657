import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = (theme) => ({
  container: {
    ...container,
    width: "100%",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  img: {
    height: "20vh",
    margin: "-240px 0 0 0",
  },
  largeImg: {
    height: "32vh",
    margin: "20px 0 0 0",
  },
  subdescription: {
    color: "#000",
  },
  signUpButton: {
    backgroundColor: "#05669b",
    width: "100%",
    height: "8vh",
    fontSize: "1.3rem",
    marginBottom: "20px",
  },
  logInButton: {
    backgroundColor: "#18528c",
    width: "100%",
    height: "8vh",
    fontSize: "1.3rem",
  },
  continueButton: {
    fontWeight: "600",
    color: "#FFFFFF !important",
    backgroundColor: "#333",
    marginTop: "10px",
  },
  signInButton: {
    background: "#ffbd4a",
    fontWeight: 700,
    color: "#323232",
    width: "95%",
    "&:hover": {
      cursor: "pointer",
      background: "#ffbd4a",
    },
  },
  signInCard: {
    padding: "15px",
    boxShadow: "0px 4px 20px rgb(0 0 0 / 10%)",
  },
  cardHeaderSignIn: {
    fontSize: "25px",
    fontWeight: 500,
  },
  forgotYourPassword: {
    color: "blue",
    "&:hover": {
      cursor: "pointer",
    },
  },
  logoGridItem: {
    display: "flex",
    justifyContent: "center",
  },
  loginCardFooter: {
    display: "flex",
    flexDirection: "column",
  },
  orContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "30px !important",
  },
  useGoogleButton: {
    border: "2px solid #F1C84E",
    borderRadius: "5px",
    fontWeight: 700,
    fontSize: "14px",
    color: "#323232",
    background: 'transparent',
    width: "95% !important",
    "&:hover": {
      background: 'transparent',
      cursor: "pointer",
      color: "#323232 !important",
    }
  },
  errorMessage: {
    color: "red !important", 
    textAlign: "center", 
    fontSize: "18px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
  }
});

export default loginPageStyle;
