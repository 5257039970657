import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { CSVLink } from "react-csv";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import { Menu } from "@material-ui/core";


const selectOptions = ["Quote ID", "Scheduled Date", "End Date", "Site Address"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = [
  "All",
  "Scheduled",
  "In Progress",
  "Unassigned",
  "Approved",
];

const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Comments", key: "comments" },
  { label: "Company", key: "company" },
  { label: "Customer Address", key: "billingAddress" },
  { label: "Customer ID", key: "customerId" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phoneNumber" },

  { label: "Cost Of Rental", key: "amount" },
  { label: "Cost Paid", key: "amountPaid" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Inventory Needed", key: "equiptment" },
  { label: "Dropoff Location", key: "equiptmentLocaion" },
  { label: "Existing Customer", key: "existingCustomer" },

  { label: "Referred from", key: "heardAboutUsFrom" },
  { label: "Last Emailed", key: "lastEmailedDate" },
  { label: "Notes", key: "notes" },
  { label: "Payment Due Date", key: "paymentDueDate" },
  { label: "PO #", key: "poNumber" },
  { label: "Quantity", key: "quantity" },
  { label: "Rental ID", key: "reservationId" },

  { label: "Seven Day Rental", key: "sevenDayReservation" },
  { label: "Site Address", key: "siteAddress" },
  { label: "Rental Status", key: "status" },
  { label: "Recurring Rental", key: "recurring" },
];

function Filters(props) {
  const {
    classes,
    sortBySelection,
    setSortBySelection,
    setDirectionSelection,
    directionSelection,
    setFilterSelection,
    filterSelection,
    setSearchString,
    searchString,
    handleFilters,
    handleSearch,
    handleCommentFilter,
    reservationsData,
  } = props;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [allValues, setAllValues] = useState("")
  const [keepOpen, setKeepOpen] = useState(false);
  const [secOpen, setSecOpen] = useState(false)
  const [menu, setMenu] = useState(null);
  const menuOpen = Boolean(menu);

  const handleAllChange = (e) => {
    setAllValues(sortBySelection, fromDate, toDate)
  }

  console.log("checking all values", allValues)

  return (
    <div>
      <GridContainer>
        <GridItem className={classes.filtersGridItem} xs={1}>
          <h5 className={classes.subHeader2}>Sort By</h5>
        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={6}>
          <Select
            style={{ width: "40%" }}
            className={classes.selectMenuSort}
            value={filterSelection}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => handleFilters("filter", e.target.value)}
          >
            {selectOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            className={classes.ascMenu}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            value={directionSelection}
            onChange={(e) => handleFilters("direction", e.target.value)}
          >
            {directionOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.filtersGridItemWithCsvDownload} xs={5}>
          <TextField
            className={classes.searchBar}
            placeholder="Search Rentals"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            variant="outlined"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
              endAdornment: (
                <InputAdornment className={classes.adournedEnd} position="end">
                  <Button
                    onClick={handleSearch}
                    className={classes.searchButton}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <CSVLink
            filename={"Rentals_Data"}
            data={reservationsData.map((x) => ({
              name: x.name,
              comments: x.comments,
              company: x.company,
              billingAddress: x.billingAddress,
              customerId: x.customerId,
              email: x.email,
              phoneNumber: x.phoneNumber,
              amount: x.reservation.amount,
              amountPaid: x.reservation.amountPaid,
              startDate: x.reservation.startDate,
              endDate: x.reservation.endDate,
              equiptment: x.reservation.equiptment,
              equiptmentLocaion: x.reservation.equiptmentLocaion,
              existingCustomer: x.reservation.existingCustomer,
              heardAboutUsFrom: x.reservation.heardAboutUsFrom,
              lastEmailedDate: x.reservation.lastEmailedDate,
              notes: x.reservation.notes,
              paymentDueDate: x.reservation.paymentDueDate,
              poNumber: x.reservation.poNumber,
              quantity: x.reservation.quantity,
              reservationId: x.reservation.reservationId,
              sevenDayReservation: x.reservation.sevenDayReservation,
              siteAddress: x.reservation.siteAddress,
              status: x.reservation.status,
              recurring: x.reservation.recurring,
            }))}
            headers={csvHeaders}
          >
            <Button
              style={{
                width: "100%",
                color: "#ffffff",
                background: "#323232",
                fontSize: "17px"
              }}
              className={classes.addCustomer}>
              Export CSV
            </Button>
          </CSVLink>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(Filters));
