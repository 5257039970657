const initState = {
  inventory: [],
};

export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_INVENTORY":
      return {
        ...state,
        inventory: [...action.inventory],
      };
    default:
      return state;
  }
}
