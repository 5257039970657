import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase } from 'firebase/client';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";

const sortByOptions = ["First Name", "Last Name", "Title"];
const directionOptions = ["Asc", "Desc"];

function Filter(props,) {
  const {
    classes,
    handleFilters,
    filterSelection,
    setFilterSelection,
    directionSelection,
    setDirectionSelection,
    handleSearch,
    searchString,
    setSearchString
  } = props;

  return (
    <GridContainer>
      <GridItem className={classes.filtersGridItem} xs={1}>
        <h5 className={classes.subHeader} style={{ whiteSpace: "noWrap" }}>Sort By</h5>
      </GridItem>
      <GridItem className={classes.filtersGridItem} xs={7}>
        <Select
          style={{ width: "20%" }}
          className={classes.selectMenuSort}
          value={filterSelection}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => handleFilters("filter", e.target.value)}
        // IconComponent={"none"}
        >
          {sortByOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          style={{ width: "15%" }}
          className={classes.ascMenu}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          value={directionSelection}
          // onChange={(e) => setDirectionSelection(e.target.value)}
          onChange={(e) => handleFilters("direction", e.target.value)}
        >
          {directionOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>
      <GridItem className={classes.filtersGridItem} xs={4}>
        <TextField
          className={classes.searchBar}
          placeholder="Search Staff"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          variant="outlined"
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearch();
            }
          }}
          InputProps={{
            classes: {
              adornedEnd: classes.adournedEnd,
            },
            endAdornment: (
              <InputAdornment className={classes.adournedEnd} position="end">
                <Button
                  className={classes.searchButton}
                  onClick={handleSearch}
                >Search</Button>
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(Filter));
