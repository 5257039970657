import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";

function TermsAndConditions(props) {
  const { classes, invoice, history } = props;
  return (
    <Card className={classes.invoiceCard}>
      <GridContainer>
        <GridItem className={classes.jobsHeaderGridItem} xs={12}>
          <h1 style={{ marginBottom: "30px" }} className={classes.title}>
            Terms And Conditions
          </h1>
        </GridItem>
        <GridItem className={classes.termsText} xs={12}>
          <p>Terms and Conditions:</p>
          <p>
            I agree to the following terms and conditions for the rental of
            described services.
          </p>
          <p>
            <br />
          </p>
          <p>Pricing &amp; Payments:</p>
          <p>
            1. Customer agrees to pay the amount and container sizes listed on
            the invoice. Payment is due once the invoice is sent. Failure to pay
            immediately may result in the cancelation of your reservation. The
            terms for container sizes are listed below. Please review the
            requirements for the size of the container that you are renting
          </p>
          <p>
            &bull;10 Yard Dumpster: Includes up to 2 tons of materials as well
            as any overages incurred due to overloading or additional days
            requested by the Customer. The containers include up to 2 tons,
            however, due to strict weight limitations and associated dump fees,
            any additional materials will be billed at $80 per ton above 2 tons.
          </p>
          <p>
            &bull;15 Yard Dumpster: Includes up to 2 tons of materials as well
            as any overages incurred due to overloading or additional days
            requested by the Customer. The containers include up to 2 tons,
            however, due to strict weight limitations and associated dump fees,
            any additional materials will be billed at $80 per ton above 2 tons.
          </p>
          <p>
            &bull;20 Yard Dumpster: Includes up to 3 tons of materials as well
            as any overages incurred due to overloading or additional days
            requested by the Customer. The containers include up to 3 tons,
            however, due to strict weight limitations and associated dump fees,
            any additional materials will be billed at $80 per ton above 3 tons.
          </p>
          <p>
            &bull;30 Yard Dumpster: Includes up to 3 tons of materials as well
            as any overages incurred due to overloading or additional days
            requested by the Customer. The containers include up to 3 tons,
            however, due to strict weight limitations and associated dump fees,
            any additional materials will be billed at $80 per ton above 3 tons.
          </p>
          <p>
            *Please note the price per ton changes if the dumpster weighs more
            than 10 tons. Any additional materials will be billed at $150 per
            ton above 10 tons.
          </p>
          <p>
            <br />
          </p>
          <p>
            2. Customer is responsible for any additional fees assessed by the
            landfill for certain items such as tires, appliances, etc.
          </p>
          <p>
            3. The container rental includes use for up to 7 days unless
            otherwise specified on your reservation. If the container is kept
            longer than 7 days, there will be an additional fee of $25.00 a day
            if inventory is available.
          </p>
          <p>
            4. Payment for all base fees as well as any known additional rental
            time will be due upon receipt. Any additional fees due to overweight
            or other fees not paid upon delivery are due within 5 days of
            container pick up.
          </p>
          <p>
            5. Any unpaid balance after 5 days will start to accrue 20% interest
            from the date of container pick up until paid in full. There will be
            a minimum of a $125 late fee. (in some circumstances we may
            redeliver the amount of debr/is back to your site if your balance
            isn&apos;t paid within 5 days)
          </p>
          <p>
            6. Customer agrees to be liable for a $125.00 cancellation fee if
            customer cancels after executing the Agreement or after submitting
            payment.
          </p>
          <p>
            7. Improperly loaded dumpster: In the event that material/waste is
            not properly loaded and causes it to be unsafe to pick up or
            transport, materials will need to be unloaded or reloaded. A $75
            trip charge is incurred when a dumpster cannot be removed. An
            additional $100.00 per day will be added to the invoice if dumpster
            can not be picked up after the scheduled pick up date due to unsafe,
            overweight and or obstruction blocking a direct path to dumpster.
          </p>
          <p>
            8. Blocked Dumpster: Delivery and/or removal of the dumpster is at
            the discretion of the driver. If the driver is unable to deliver or
            pick-up a dumpster due to cars or other items blocking the drive,
            there will be a $75 trip charge.
          </p>
          <p>
            9.Reservation changes for delivery or final pickup must be made a
            minimum 24 hours prior to 7 A.M of the first or last date of the
            reservation. Failure to give notice in that time will result in a
            $100.00 reschedule fee plus additional day fee of $25.00
          </p>
          <p>
            <br />
          </p>
          <p>Dumpster Use:</p>
          <p>
            10. The Parties hereby agree and accept that Colorado law shall
            govern this Service Agreement. In the event of any controversy,
            claim or dispute relating to or arising from the provisions of this
            Service Agreement or the Services, the prevailing Party or Parties
            shall recover their actual, reasonable legal fees and related
            expenses including, without limitation, all post-judgment fees and
            expenses and costs of collection.
          </p>
          <p>
            1. While refuse dumpsters are in your possession, you will NOT place
            or allow to be placed into the dumpster:
          </p>
          <p>
            &bull;Substances that are hazardous to health such as toxic or
            corrosive materials or liquids.
          </p>
          <p>&bull;Liquids of any kind whether contained or not.</p>
          <p>
            &bull;Cans, drums, or other containers of any kind unless emptied
            and crushed and incapable of carrying any liquid.
          </p>
          <p>&bull;Medical waste or animal carcasses of any kind.</p>
          <p>
            &bull;Any material not listed above however considered unsuitable
            for containment e.g. malodorous waste: asbestos, paint, tires, gas
            bottle, fluorescent tubes, light bulbs, vehicle batteries, household
            appliances such as but not limited to refrigerators, conventional
            ovens, microwave ovens, washer, dryer, etc.
          </p>
          <p>
            &bull;Extremely heavy material such as rock, dirt, or concrete.
            Please let us know and we can help you dispose of such heavy items
            in a more efficient manner.
          </p>
          <p>
            2. All refuse shall remain within the confines of the dumpster and
            shall not exceed the top or sides. Every attempt shall be taken to
            equally disperse the weight of the refuse within the dumpster.
          </p>
          <p>
            3. Customer shall be liable for any loss or damage to rented
            equipment in excess of reasonable wear and tear.
          </p>
          <p>
            4. Customer agrees to not move or reposition the dumpster from the
            original placement by a humpty dump employee.
          </p>
          <p>
            <br />
          </p>
          <p>Access and Ground Conditions:</p>
          <p>
            1. The Customer will be responsible for the provision of free and
            suitable access to and from the delivery site (including the removal
            and reinstatement of local obstructions) and for ensuring suitable
            ground conditions for delivery, placement, and removal of the
            dumpster. No responsibility will be accepted for the damage to any
            surface and you should, therefore, take steps to protect surfaces
            (e.g. paving slabs, soft ground) before delivery.
          </p>
          <p>
            2. Should the container cause damage to concrete or asphalt during
            loading or unloading. Humpty Dump, Inc is only responsible if this
            is due to our negligence. We have specifically identified that heavy
            container may cause damage and that the placement of these heavy
            containers
          </p>
          <p>
            3. Should the Customer fail to return the container, Humpty Dump,
            Inc. maintains the right to enter upon the premises where the
            container may be without notice and take possession of and remove it
            at the Customer&rsquo;s expense all without legal process, the
            Customer hereby waives any claims for damages from any such entry or
            removal.
          </p>
          <p>
            4. We reserve the right to leave or return any materials at the
            sight or property where the dumpster sits due to overweight,
            improperly loaded or unpaid balances.
          </p>
          <p>
            <br />
          </p>
          <p>
            By submitting payment for this invoice, you agree to all terms
            listed above.
          </p>
          <p>
            <br />
          </p>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(TermsAndConditions));
