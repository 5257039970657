import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import ProgressBar from "@ramonak/react-progress-bar";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { addPendingReservationExistingCustomer } from "store/actions/reservations/reservationsActions";
import { getOneWeekLater } from "../../Dashboard/helpers/helpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import pointInPolygon from "point-in-polygon";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
// import Submitted from "./Submitted";
import moment from "moment";
import { Link } from "react-router-dom";



function NewFormStepOne(props) {
    const {
        classes,
        customerInfo,
        inventoryData,
        handleAddPendingReservationExistingCustomer,
        territoriesData,
        setSpokenWithTeamMember,
        spokenWithTeamMember,
        communicationPreference,
        setCommunicationPreference,
        nextStep,
        phoneNumber,
        handlePhoneNumberChange,
        errors,
        email,
        setEmail,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        reservationType,
        setReservationType,
        reserveTypeErr,
        setReserveTypeErr,
        customerType,
        setCustomerType
    } = props;
    return (
        <Card className={classes.tempCard}>
            <span style={{ justifyContent: "flex-end", display: "flex" }}>
                <p style={{fontWeight:"600"}}>
                    1/3
                </p>
            </span>
            <h5 className={classes.formTitle}>
                Type of Customer:{" "}
            </h5>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "30px",
                    }}
                >
                    <Checkbox
                        style={{
                            color: "#FFC720",
                        }}
                        checked={customerType.existing}
                        onChange={() => setCustomerType({ new: false, existing: true })}

                    />
                    <span>Existing Customer</span>
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "30px",
                }}>
                    <Checkbox
                        checked={customerType.new}
                        style={{
                            color: "#FFC720",
                        }}
                        onChange={() => {
                            setCustomerType({ new: true, existing: false })
                        }}
                    />
                    <span>New Customer</span>
                </div>
            </div>
            <h5 className={classes.formTitle}>
                Type of Reservation:{" "}
                {reserveTypeErr === true ? (
                    <span style={{ color: "red" }}>Required*</span>
                ) : (
                    <div></div>
                )}
            </h5>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "30px",
                    }}
                >
                    <Checkbox
                        checked={reservationType.new}
                        style={{
                            color: "#FFC720",
                        }}
                        onChange={() => {
                            setReservationType({ new: true, update: false })
                        }}
                    />
                    <span>New Reservation</span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                        style={{
                            color: "#FFC720",
                        }}
                        checked={reservationType.update}
                        onChange={() => setReservationType({ new: false, update: true })}

                    />
                    <span>Update to Existing</span>
                </div>
            </div>
            <h5 className={classes.formTitle}>
                Are you ready to finalize?{" "}
                {errors.spokenWithTeamMember ? (
                    <span style={{ color: "red" }}>Required*</span>
                ) : (
                    <div></div>
                )}
            </h5>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "30px",
                    }}
                >
                    <Checkbox
                        checked={spokenWithTeamMember.yes}
                        style={{
                            color: "#FFC720",
                        }}
                        onChange={() => {
                            setSpokenWithTeamMember({ yes: true, no: false });
                        }}
                    />
                    <span>I understand the rental process and I am ready to finalize.</span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                        style={{
                            color: "#FFC720",
                        }}
                        checked={spokenWithTeamMember.no}
                        onChange={() => setSpokenWithTeamMember({ yes: false, no: true })}
                    />
                    <span>I have questions, please call me to finalize my reservation.</span>
                </div>
            </div>
            <h5 className={classes.formTitle}>
                Contact Information:{" "}
                {errors.phoneNumber ||
                    errors.firstName ||
                    errors.lastName ||
                    errors.email ? (
                    <span style={{ color: "red" }}>Required*</span>
                ) : (
                    <div></div>
                )}
            </h5>
            <TextField
                disabled={customerInfo ? true : false}
                className={classes.emailInput}
                value={customerInfo ? customerInfo.email : email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                InputProps={{
                    classes: {
                        adornedEnd: classes.adournedEnd,
                    },
                }}
            />

            <TextField
                disabled={customerInfo ? true : false}
                className={classes.emailInput}
                value={customerInfo ? customerInfo.name.split(" ")[0] : firstName}
                placeholder="First Name"
                onChange={(e) => {
                    if (e.target.value.length === 1) {
                        setFirstName(e.target.value.toUpperCase());
                    } else {
                        setFirstName(e.target.value);
                    }
                }}
                variant="outlined"
                InputProps={{
                    classes: {
                        adornedEnd: classes.adournedEnd,
                    },
                }}
            />

            <TextField
                className={classes.emailInput}
                disabled={customerInfo ? true : false}
                value={customerInfo ? customerInfo.name.split(" ")[1] : lastName}
                onChange={(e) => {
                    if (e.target.value.length === 1) {
                        setLastName(e.target.value.toUpperCase());
                    } else {
                        setLastName(e.target.value);
                    }
                }}
                variant="outlined"
                placeholder="Last Name"
                InputProps={{
                    classes: {
                        adornedEnd: classes.adournedEnd,
                    },
                }}
            />

            <TextField
                className={classes.emailInput}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                variant="outlined"
                placeholder="Phone Number"
                InputProps={{
                    classes: {
                        adornedEnd: classes.adournedEnd,
                    },
                }}
            />
            {!customerInfo && (!phoneNumber ||
                !firstName ||
                !lastName ||
                !email ||
                (!reservationType.new && !reservationType.update) ||
                (!spokenWithTeamMember.yes && !spokenWithTeamMember.no)) ?
                <Button
                    disabled
                    style={{ background: "#FFC821", color: "#323232" }}
                    onClick={() => nextStep()}
                >
                    Next
                </Button>
                :
                customerInfo && (!phoneNumber ||
                    (!reservationType.new && !reservationType.update) ||
                    (!spokenWithTeamMember.yes && !spokenWithTeamMember.no) ||
                    (!customerType.new && !customerType.existing)) ?
                    <Button
                        disabled
                        style={{ background: "#FFC821", color: "#323232" }}
                        onClick={() => nextStep()}
                    >
                        Next
                    </Button>
                    :
                    <Button
                        style={{ background: "#FFC821", color: "#323232" }}
                        onClick={() => nextStep()}
                    >
                        Next
                    </Button>}

            {/* {phoneNumber &&
                firstName &&
                lastName &&
                email &&
                (reservationType.new || reservationType.update) &&
                (spokenWithTeamMember.yes || spokenWithTeamMember.no) &&
                (customerType.new || customerType.existing) ?
                <ProgressBar
                    margin="15px 0px 0px 0px"
                    completed={33}
                    isLabelVisible={false}
                    bgColor="#323232"
                    animateOnRender
                    maxCompleted={100}
                />
                :
                <ProgressBar
                    margin="15px 0px 0px 0px"
                    completed={1}
                    isLabelVisible={false}
                    bgColor="#323232"
                    animateOnRender
                    maxCompleted={100}
                />
            } */}
            {/* <ProgressBar
                margin="15px 0px 0px 0px"
                completed={1}
                isLabelVisible={false}
                bgColor="#323232"
                // animateOnRender
                maxCompleted={100}
            /> */}

        </Card>
    );
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    handleAddPendingReservationExistingCustomer: (pendingRes) =>
        dispatch(addPendingReservationExistingCustomer(pendingRes)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(reservationFormStyle)
)(React.memo(NewFormStepOne));
