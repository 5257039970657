import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase } from "firebase/client";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";
import Checkbox from "@material-ui/core/Checkbox";

const mockData = [
    {
        message: "Your rental is almost completed....",
        lastSent: "04/09/21 4:08 PM",
        nextScheduled: "04/16/21 4:08 PM",
        freq: "1 Week(s)",
        taskId: "R34522",
        status: "Unknown"

    },
    {
        message: "Your rental is almost completed....",
        lastSent: "04/09/21 4:08 PM",
        nextScheduled: "04/16/21 4:08 PM",
        freq: "1 Week(s)",
        taskId: "R34522",
        status: "Delivered"

    }
];

const headers = [
    "Message",
    "Last Sent",
    "Next Scheduled",
    "Frequency",
    "Task ID",
    "Status",
];
const sortByOptions = ["Invoice Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];

function RemindersTable(props) {
    const { classes, reservation, invoices, history } = props;
    const [invoicesState, setInvoicesState] = useState([
        { ...reservation.reservation },
    ]);
    console.log('reservationxxxx', reservation)
    const renderTableHeader = () => {
        return (
            <tr className={classes.notesTableRow}>
                {headers.map((key, index) => {
                    return (
                        <th className={classes.notesTableHeaders} key={key}>
                            {key}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        return mockData.map((d, index) => {
            return (
                <tr className={classes.notesTableRow} key={d.invoiceId}>
                    <td
                        className={classes.notesTableCell}
                    >
                        {d.message}
                    </td>
                    <td className={classes.notesTableCell}>{d.lastSent}</td>
                    <td className={classes.notesTableCell}>
                        {d.nextScheduled}
                    </td>
                    <td className={classes.notesTableCell}>{d.freq}</td>
                    <td className={classes.notesTableCell}>{d.taskId}</td>
                    <td className={classes.notesTableCell}>
                        <div
                            style={
                                d.status === "Delivered"
                                    ? { background: "#3F9C42", color: "white" }
                                    : { background: "#929292", color: "white" }
                            }
                            className={classes.statusDiv}
                        >
                            {d.status}
                        </div>
                    </td>
                </tr>
            );
        });
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <table style={{ marginTop: "30px" }} className={classes.inventoryTable}>
                    <tbody>
                        {renderTableHeader()}
                        {renderTableData()}
                    </tbody>
                </table>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        customersData: state.customers.customers,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(customerInfoStyle)
)(React.memo(RemindersTable));
