import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./Dnd/Sortable";
import Droppable from "./Dnd/Droppable";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";

const containerStyle = {
  background: "#dadada",
  padding: 10,
  margin: 10,
  flex: 1,
};

export default function DriverDayScheduleOpen(props) {
  const {
    id,
    items,
    classes,
    width,
    history,
    driver,
    columnWidth,
    weeklyItems,
    setWeeklyItems,
    searchString,
    splitDate,
  } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    driverId: id,
    data: {
      data: items,
    },
  });
  return (
    <SortableContext
      strategy={verticalListSortingStrategy}
      id={id}
      items={items}
    >
      <div
        ref={setNodeRef}
        style={{
          display: "flex",
          flexDirection: "column",
          width: `${columnWidth}%`,
          paddingBottom: "50px",
        }}
      >
        {items.map((singleItem, singleItemIndex) => {
          let startOrEnd;
          if (singleItem.reservation.startDate === splitDate) {
            startOrEnd = "START";
          } else {
            startOrEnd = "END";
          }
          return (
            <SortableItem
              key={singleItemIndex}
              reservationId={singleItem.reservation.reservationId}
              driverId={id}
              driver={driver}
              weeklyItems={weeklyItems}
              setWeeklyItems={setWeeklyItems}
              classes={classes}
              data={singleItem}
              id={singleItem.reservation.reservationId + " ? " + startOrEnd}
              startOrEnd={startOrEnd}
              columnWidth={columnWidth}
              searchString={searchString}
              splitDate={splitDate}
              territoryColor={singleItem.reservation.territoryColor}
            />
          );
        })}
      </div>
    </SortableContext>
  );
}
