import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase } from "firebase/client";
import ReactPaginate from "react-paginate";
import "views/Dashboard/components/pagination.css";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";
import AccountingCustomTabs from "./AccountingCustomTabs";
import Checkbox from "@material-ui/core/Checkbox";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import moment from "moment";

const headers = [
  "",
  "Invoice ID",
  "Site Address",
  "Due Date",
  "Amount",
  "Overdue",
  "Emailed",
  "Status",
  "Actions",
];
const sortByOptions = ["Invoice Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];
const statementOptions = ["Statement", "Email Detail Statement", "Email Summary Statement"]

const pageArr = ["4", "All"];

function AccountingInvoicesTab(props) {
  const { classes, invoices, history, customerId } = props;
  const [sortBy, setSortBy] = useState("Invoice Date");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterBySelection, setFilterBySelection] = useState("Filter By");
  const [statementSelection, setStatementSelection] = useState("Statement");
  const [searchString, setSearchString] = useState("");
  const [invoicesState, setInvoicesState] = useState([
    ...invoices.filter((i) => i.customerId === customerId),
  ]);
  const [checkedCount, setCheckedCount] = useState(0);
  const [checkedAll, setCheckedAll] = useState(false);
  const [invoicesLoaded, setInvoicesLoaded] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const itemsPerPage = 4;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("4");

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  useEffect(() => {
    invoicesState.slice(index, endIndex);
  }, [index]);

  // load past reservations with current reservations
  // useEffect(() => {
  //   if (!invoicesLoaded && customerInfo) {
  //     const getInvoiceData = async () => {
  //       return db
  //         .collection("customers")
  //         .doc(customerInfo.customerId)
  //         .get()
  //         .then((snapshot) => {
  //           const customerData = snapshot.data();
  //           setInvoicesState(
  //             customerData.reservations.map((d) => {
  //               return { ...d, checked: false };
  //             })
  //           );
  //           setInvoicesLoaded(true);
  //         });
  //     };
  //     getInvoiceData();
  //   }
  // }, [customerInfo]);

  useEffect(() => {
    if (checkedAll) {
      setInvoicesState(
        invoicesState.map((i) => {
          i.checked = true;
          return i;
        })
      );
      setCheckedCount(invoicesState.length);
    } else {
      setInvoicesState(
        invoicesState.map((i) => {
          i.checked = false;
          return i;
        })
      );
      setCheckedCount(0);
    }
  }, [checkedAll]);

  const renderTableHeader = () => {
    return (
      <tr className={classes.notesTableRow}>
        {headers.map((key, index) => {
          return (
            <th className={classes.notesTableHeaders} key={key}
              style={{
                border: "0.5px solid #8E8E8E",
                padding: "10px",
                fontSize: "17px"
              }}
            >
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    let data;
    if (pageDropdown == "4") {
      data = invoicesState.slice(
        pageNumber * itemsPerPage,
        pageNumber * itemsPerPage + itemsPerPage
      );
    } else {
      data = invoicesState;
    }
    console.log("invoicesState", invoicesState);
    return data.map((d, index) => {
      const invoicePaymentStatus =
        d.invoiceDetails.paymentStatus === "Paid"
          ? "Paid"
          : d.invoiceDetails.paymentStatus === "Void"
            ? "Void"
            : "Unpaid";
      const paymentDateString = moment(d.invoiceDate.toDate()).format(
        "MM/DD/YY"
      );
      return (
        <tr
          style={{ height: "50px" }}
          className={classes.notesTableRow}
          key={index}
        >
          <td className={classes.notesTableCell}>
            <Checkbox
              // classes={{
              //   root: marginTop,
              //   disabled: classes.disabled,
              // }}
              onChange={() => {
                let newState = [...invoicesState];
                if (newState[index].checked) {
                  newState[index].checked = false;
                  setCheckedCount((prevState) => prevState - 1);
                } else {
                  newState[index].checked = true;
                  setCheckedCount((prevState) => prevState + 1);
                }
                setInvoicesState([...newState]);
              }}
              id={index}
              checked={d.checked}
            />
          </td>
          <td
            onClick={() =>
              history.push({
                pathname: `/admin/invoice/${d.invoiceId}`,
                state: {
                  invoice: d,
                },
              })
            }
            style={{ color: "#0071C5", textDecoration: "underline" }}
            className={classes.notesTableCell}
          >
            {d.invoiceId}
          </td>
          <td className={classes.notesTableCell}>{d.siteAddress}</td>
          <td className={classes.notesTableCell}>{paymentDateString}</td>

          <td className={classes.notesTableCell}>${d.servicesTotal}</td>
          <td className={classes.notesTableCell}>
            {" "}
            ${d.invoiceDetails.paymentStatus === "Paid" ? 0 : d.servicesTotal}
          </td>
          <td className={classes.notesTableCell}>
            {/* {d.services[0].serviceInfo.lastEmailedDate} */}
            N/A
          </td>
          <td className={classes.notesTableCell}>
            <div
              style={
                invoicePaymentStatus === "Overdue"
                  ? { background: "#F83030" }
                  : invoicePaymentStatus === "Paid"
                    ? { background: "#2EB67D" }
                    : invoicePaymentStatus === "Void"
                      ? { background: "#D9D9D9", color: "black" }
                      : { background: "#E0AC12", color: "black" }
              }
              className={classes.statusDiv}
            >
              {invoicePaymentStatus}
            </div>
          </td>

          <td className={classes.notesTableCell}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Tooltip
                id="tooltip-top"
                title="View Invoice"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{ background: "#FFC720" }}
                  className={classes.actionBtn}
                >
                  <EyeIcon />
                </Button>
              </Tooltip>
              <Tooltip
                id="tooltip-top"
                title="Edit Invoice"
                onClick={() =>
                  history.push(`/admin/edit-invoice/${d.invoiceId}`)
                }
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#ECB22E" }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Email Invoice"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#323232" }}
                >
                  <MailIcon />
                </Button>
              </Tooltip>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card className={classes.tabCard} style={{ paddingBottom: "-60px" }}>
      <GridContainer>
        <GridItem className={classes.filtersGridItem} xs={1}>
          <h5 className={classes.subHeader} style={{ margin: "25px 0px" }}>Sort By</h5>
        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={7}>
          <Select
            style={{ height: "45px", fontFamily: "Archivo" }}
            className={classes.selectMenuSort}
            value={sortBy}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setSortBy(e.target.value)}
          >
            {sortByOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            style={{ height: "45px", fontFamily: "Archivo" }}
            className={classes.ascMenu}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            value={directionSelection}
            onChange={(e) => setDirectionSelection(e.target.value)}
          >
            {directionOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            className={classes.filterMenu}
            value={filterBySelection}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setFilterBySelection(e.target.value)}
          >
            {filterOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={4}>
          <Datetime
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
              padding: "11.5px 14px"
            }}
            selected={fromDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              setFromDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  className={classes.datetime}
                  placeholder="From"
                  value={fromDate}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />
          <Datetime
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
            }}
            selected={fromDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              setToDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  className={classes.datetime}
                  placeholder="From"
                  value={toDate}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                      style: { padding: "11.5px 14px" }
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />
          <Button className={classes.createInvoiceButton} style={{ textTransform: "capitalize", margin: "5px 0px 5px 10px" }}>Export CSV</Button>
        </GridItem>
        <GridItem xs={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              onChange={() => setCheckedAll((prevState) => !prevState)}
              checked={checkedAll}
            />
            <span style={{ fontSize: "18px", whiteSpace: "noWrap" }}>
              Select All ({checkedCount} of {invoicesState.length})
            </span>
          </div>
        </GridItem>
        <GridItem xs={4}>
          <Select
            style={{ height: "45px", fontFamily: "Archivo", width: "60%" }}
            className={classes.selectMenuSort}
            value={statementSelection}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setStatementSelection(e.target.value)}
          >
            {statementOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem xs={6}>
          <TextField
            className={classes.searchBar}
            placeholder="Search Invoice Keywords, Addresses, Etc"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            variant="outlined"
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
              endAdornment: (
                <InputAdornment className={classes.adournedEnd} position="end">
                  <Button className={classes.searchButton} style={{ padding: "13px 14px !important", }}>Search</Button>
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <div
            style={
              pageDropdown != 4 && invoicesState.length > 4
                ? { height: "500px", width: "100%", overflowY: "scroll" }
                : null
            }
          >
            <table
              style={{ marginTop: "30px" }}
              className={classes.inventoryTable}
            >
              <tbody>
                {renderTableHeader()}
                {renderTableData()}
              </tbody>
            </table>
          </div>
          {pageDropdown == "4" ? (
            <GridContainer style={{ paddingTop: "10px" }}>
              <GridItem xs={6}>
                <p className={classes.view}>
                  View
                  <span>
                    <Select
                      style={{
                        width: "10%",
                        marginLeft: "10px",
                        marginTop: "5px",
                        textAlign: "center",
                      }}
                      disableUnderline
                      MenuProps={
                        {
                          // className: classes.selectMenu,
                        }
                      }
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor,
                      }}
                      onChange={(e) => {
                        setPageDrowdown(e.target.value);
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple,
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
              <GridItem xs={4}>
                <h5
                  className={classes.indexes}
                >
                  {index + 1} - {endIndex} of{" "}
                  {Math.ceil(invoicesState.length / itemsPerPage)}
                </h5>
              </GridItem>
              <GridItem xs={2}>
                <ReactPaginate
                  paginationSize={0}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(invoicesState.length / itemsPerPage)}
                  nextLabel={
                    <ArrowRight
                      style={{
                        fontSize: 25,
                        fontWeight: "bold",
                        color: "white",
                        marginBottom: "-8px",
                      }}
                    />
                  }
                  previousLabel={
                    <ArrowLeft
                      style={{
                        fontSize: 25,
                        fontWeight: "bold",
                        color: "white",
                        marginBottom: "-8px",
                      }}
                    />
                  }
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer>
              <GridItem xs={6}>
                <p className={classes.view}>
                  View
                  <span>
                    <Select
                      style={{
                        width: "10%",
                        marginLeft: "10px",
                        marginTop: "5px",
                        textAlign: "center",
                      }}
                      disableUnderline
                      MenuProps={
                        {
                          // className: classes.selectMenu,
                        }
                      }
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor,
                      }}
                      onChange={(e) => {
                        setPageDrowdown(e.target.value);
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple,
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
            </GridContainer>
          )}
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(AccountingInvoicesTab));
