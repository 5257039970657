import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIconWhite.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIconBlack.svg";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Card, MenuItem, Select } from "@material-ui/core";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

import EditCustomerModal from "../../CustomerInfo/components/EditCustomerModal";

const headers = [
  "Customer",
  "Billing Address",
  "Contact Info",
  "Customer Tags",
  "Actions",
];
const pageArr = [4, 10, 20, 50, "All"];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomersTable(props) {
  const { classes, customers, history } = props;

  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [customerEdited, setCustomerEdited] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(false);
  const itemsPerPage = 4;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(4)
  const [pageDropdown, setPageDrowdown] = useState(4)

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * pageDropdown)
    setEndIndex((selected * pageDropdown) + pageDropdown)
  };

  useEffect(() => {
    customers.slice(index, endIndex)
  }, [index])

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if (pageDropdown > 0) {
      return (
        customers &&
        customers
          .slice(pageNumber * pageDropdown, (pageNumber * pageDropdown) + pageDropdown)
          .map((d, index) => {
            return (
              <tr className={classes.tableRow} key={index} style={{ height: "38px" }}>
                <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${d.customerId}`,
                        state: { customer: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                    style={{ textTransform: "capitalize" }}
                  >
                    {" "}
                    {d.name}
                  </span>{" "}
                  |
                  <span
                    className={d.company ? classes.nameAndCompanyText : ""}
                    onClick={() =>
                      history.push({
                        pathname: `/admin/company/${d.companyId}/${d.customerId}`,
                        state: { customer: d },
                      })
                    }
                  >
                    {d.company || "N/A"}
                  </span>
                </td>
                <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
                  {d.billingAddress ? d.billingAddress : "N/A"}
                </td>
                <td className={classes.tableCell}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      className={classes.nameAndCompanyText}
                      onClick={() =>
                        history.push({
                          pathname: `/admin/customers/${d.customerId}`,
                          state: { customer: d },
                        })
                      }>{d.phoneNumber || d.phone}</span>
                    <span
                      className={classes.nameAndCompanyText}
                      onClick={() =>
                        history.push({
                          pathname: `/admin/customers/${d.customerId}`,
                          state: { customer: d },
                        })
                      }>{d.email || d.emails}</span>
                  </div>
                </td>
                <td className={classes.tableCell}>
                  <div style={{ display: "flex", width: "100%" }}>
                    {d.comments.map((c, index) => {
                      return (
                        <div
                          key={index}
                          style={
                            c === "Regular"
                              ? { background: "#2EB67D" }
                              : c === "Cautious"
                                ? { background: "#323232" }
                                : c === "Overdue"
                                  ? { background: "#F83030" }
                                  : c === "New"
                                    ? { background: "#0071C5" }
                                    : { background: "#ECB22E", color: "#323232" }
                          }
                          className={classes.commentDiv}
                        >
                          {c}
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td className={classes.tableCell}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      marginRight: "25px"
                    }}
                  >
                    <Tooltip
                      id="tooltip-top"
                      title="Mail"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        style={{ background: "#FFC720" }}
                        className={classes.actionBtn}
                        onClick={() =>
                          history.push({
                            pathname: `/admin/customers/${d.customerId}`,
                            state: { customer: d },
                          })
                        }
                      >
                        <MailIcon />
                      </Button>
                    </Tooltip>

                    <Tooltip
                      id="tooltip-top"
                      title="Add Customer"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        style={{ background: "#323232" }}
                        className={classes.actionBtn}
                      >
                        <AddIcon />
                      </Button>
                    </Tooltip>

                    {/* <Tooltip
                      id="tooltip-top"
                      title="Edit"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        onClick={() => {
                          setCustomerInfo(d);
                          setEditCustomerModal(true);
                        }}
                        className={classes.actionBtn}
                        style={{ background: "#ECB22E" }}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip> */}
                  </div>
                </td>
              </tr>
            );
          })
      );
    }
    else {
      return (
        customers &&
        customers.map((d, index) => {
          return (
            <tr className={classes.tableRow} key={index}>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                <span
                  onClick={() =>
                    history.push({
                      pathname: `/admin/customers/${d.customerId}`,
                      state: { customer: d },
                    })
                  }
                  className={classes.nameAndCompanyText}
                >
                  {" "}
                  {d.name}
                </span>{" "}
                |
                <span className={d.company ? classes.nameAndCompanyText : ""}>
                  {d.company || "N/A"}
                </span>
              </td>
              <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
                {d.billingAddress ? d.billingAddress : "N/A"}
              </td>
              <td className={classes.tableCell}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  <span
                    className={classes.nameAndCompanyText}
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${d.customerId}`,
                        state: { customer: d },
                      })
                    }
                  >
                    {d.phoneNumber || d.phone}
                  </span>
                  <span
                    className={classes.nameAndCompanyText}
                    onClick={() =>
                      history.push({
                        pathname: `/admin/customers/${d.customerId}`,
                        state: { customer: d },
                      })
                    }
                  >
                    {d.email || d.emails}
                  </span>
                </div>
              </td>
              <td className={classes.tableCell}>
                <div style={{ display: "flex", width: "100%" }}>
                  {d.comments.map((c, index) => {
                    return (
                      <div
                        key={index}
                        style={
                          c === "Regular"
                            ? { background: "#2EB67D" }
                            : c === "Cautious"
                              ? { background: "#323232" }
                              : c === "Overdue"
                                ? { background: "#F83030" }
                                : c === "New"
                                  ? { background: "#0071C5" }
                                  : { background: "#ECB22E", color: "#323232" }
                        }
                        className={classes.commentDiv}
                      >
                        {c}
                      </div>
                    );
                  })}
                </div>
              </td>
              <td className={classes.tableCell}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    marginRight: "25px"
                  }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      style={{ background: "#FFC720" }}
                      className={classes.actionBtn}
                      onClick={() =>
                        history.push({
                          pathname: `/admin/customers/${d.customerId}`,
                          state: { customer: d },
                        })
                      }
                    ><MailIcon />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      style={{ background: "#323232" }}
                      className={classes.actionBtn}
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>

                  {/* <Tooltip
                      id="tooltip-top"
                      title="Edit"
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        onClick={() => {
                          setCustomerInfo(d);
                          setEditCustomerModal(true);
                        }}
                        className={classes.actionBtn}
                        style={{ background: "#ECB22E" }}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip> */}
                </div>
              </td>
            </tr>
          );
        })
      );
    }

  };

  return (
    // <Card style={{padding:"30px 30px 0px 30px"}}>
    <GridContainer>
      <GridItem xs={12}>
        <div style={pageDropdown != 4 && customers.length > 4 ? { height: "500px", width: "100%", overflowY: "scroll" } : null}>
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>{pageDropdown == "4" ?
          <GridContainer style={{ paddingTop: "10px" }}>
            <GridItem xs={6} >
              <p className={classes.view}>View
                <span>
                  <Select
                    style={{ width: "20%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                    disableUnderline
                    MenuProps={{
                      // className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor
                    }}

                    onChange={(e) => {
                      setPageDrowdown(e.target.value)
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} >
              <h5 className={classes.indexes}>{index + 1} - {endIndex} of
                {customers.length < pageDropdown ? " " + customers.length :
                  " " + Math.ceil(customers.length / pageDropdown)}
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(customers.length / pageDropdown)}
                nextLabel={<ArrowRight style={{ fontSize: 25, fontWeight: "bold", color: "white", marginBottom: "-8px" }} />}
                previousLabel={<ArrowLeft style={{ fontSize: 25, fontWeight: "bold", color: "white", marginBottom: "-8px" }} />}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
          :
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>View
                <span>
                  <Select
                    style={{ width: "10%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                    disableUnderline
                    MenuProps={{
                      // className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor
                    }}
                    onChange={(e) => {
                      if (e.target.value === "All") {
                        setPageDrowdown(0);
                      } else {
                        setPageDrowdown(e.target.value);
                      }
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        }
      </GridItem>


      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={editCustomerModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setEditCustomerModal(false)
          setCustomerInfo(false)
        }}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        {customerInfo && (
          <EditCustomerModal
            setCustomerEdited={setCustomerEdited}
            setEditCustomerModal={setEditCustomerModal}
            customerInfo={customerInfo}
            setCustomerInfo={setCustomerInfo}
            history={history}
          />
        )}
      </Dialog>

      <Snackbar open={customerEdited} onClose={() => setCustomerEdited(false)}>
        <Alert onClose={() => setCustomerEdited(false)} severity={"success"}>
          Customer Edited
        </Alert>
      </Snackbar>
    </GridContainer>

  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(CustomersTable));
