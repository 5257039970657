import React, { useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/modalsStyle.js";
import Card from "components/Card/Card.js";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import moment from "moment";
import { updatePendingReservation } from "store/actions/reservations/reservationsActions";
import { Checkbox, FormControlLabel, InputAdornment, Radio, RadioGroup } from "@material-ui/core";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";

function ReccurenceModal(props) {
    const {
        classes,
        recurrError,
        setRecurrError,
        freqValue,
        setFreqValue,
        weeklyValue,
        setWeeklyValue,
        weekFreq,
        setWeekFreq,
        monthlyValue,
        setMonthlyValue,
        monthWeekFreq,
        setMonthWeekFreq,
        monthDayFreq,
        setMonthDayFreq,
        monthValue,
        setMonthValue,
        monthDayValue,
        setMonthDayValue,
        rangeValue,
        setRangeValue,
        rangeStartDate,
        setRangeStartDate,
        setRangeEndNum,
        setRangeEndDate,
        rangeEndDate,
        days,
        weekly,
        dailyNum,
        setDailyNum,
        dailyValue,
        setDailyValue,
        handleModalClose,
        checkRecurr
    } = props;

    return (
        <Card style={{ padding: "20px" }}>
            <GridContainer>
                <GridItem xs={2}>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={freqValue}
                        onChange={(e) => setFreqValue(e.target.value)}
                        style={{ textColor: "#323232", padding: "10px" }}
                    >
                        <p style={{ fontSize: "16px", fontWeight: "bold" }}>Frequency</p>
                        <FormControlLabel value="Daily" control={<Radio style={{ color: "#323232" }} />} label="Daily" />
                        <FormControlLabel value="Weekly" control={<Radio style={{ color: "#323232" }} />} label="Weekly" />
                        <FormControlLabel value="Monthly" control={<Radio style={{ color: "#323232" }} />} label="Monthly" />
                    </RadioGroup>
                    {recurrError.valueErr === true ? <p style={{ marginLeft: "5px", color: "red" }}>Error! Choose a frequency</p> : null}

                </GridItem>
                <GridItem xs={1} style={{ borderRight: "1px solid black", margin: "50px 5px 15px -100px" }} />
                <GridItem xs={8} style={{ marginTop: "20px" }}>
                    {freqValue === "Weekly" ?
                        <>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={weeklyValue}
                                onChange={(e) => {
                                    setWeeklyValue(e.target.value)
                                }}
                            >
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <p style={{ fontSize: "16px", marginTop: "30px" }}>Every{" "}
                                            <span>
                                                <TextField
                                                    style={{ width: "70px", marginTop: "-10px" }}
                                                    size="small"
                                                    type="number"
                                                    placeholder="01"
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.textFieldRoot,
                                                        },
                                                        inputProps: {
                                                            onChange: (e) => setWeekFreq(e.target.value),
                                                            min: 0.00,

                                                        }
                                                    }}
                                                    className={classes.textFieldRoot}
                                                    variant="outlined"
                                                />
                                            </span>
                                            {" "}week(s) on:

                                        </p>

                                    </GridItem>
                                    <GridItem xs={12}>
                                        <FormControlLabel value="Sunday" control={<Checkbox style={{ color: "#323232" }} />} label="Sunday" />
                                        <FormControlLabel value="Monday" control={<Checkbox style={{ color: "#323232" }} />} label="Monday" />
                                        <FormControlLabel value="Tuesday" control={<Checkbox style={{ color: "#323232" }} />} label="Tuesday" />
                                        <FormControlLabel value="Wednesday" control={<Checkbox style={{ color: "#323232" }} />} label="Wednesday" />
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <FormControlLabel value="Thursday" control={<Checkbox style={{ color: "#323232" }} />} label="Thursday" />
                                        <FormControlLabel value="Friday" control={<Checkbox style={{ color: "#323232" }} />} label="Friday" />
                                        <FormControlLabel value="Saturday" control={<Checkbox style={{ color: "#323232" }} />} label="Saturday" />
                                    </GridItem>
                                </GridContainer>
                                {recurrError.weeklyValueErr ? <p style={{ color: "red" }}>Error! Please fill in missing field!</p> : null}
                            </RadioGroup>
                        </>
                        : freqValue === "Monthly" ?
                            <>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={monthlyValue}
                                    onChange={(e) => setMonthlyValue(e.target.value)}
                                    style={{ marginTop: "20px" }}
                                >
                                    <GridContainer>
                                        <GridItem xs={12} style={{ marginTop: "20px" }}>
                                            <FormControlLabel value="Day" control={<Radio style={{ color: "#323232" }} />} label="" />
                                            <span style={{ marginLeft: "-20px", fontSize: "16px" }}>
                                                Day
                                                <TextField
                                                    style={{ width: "70px", marginRight: "10px", marginLeft: "10px" }}
                                                    size="small"
                                                    type="number"
                                                    placeholder="01"
                                                    // defaultValue={monthDayValue}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.textFieldRoot,
                                                        },
                                                        inputProps: {
                                                            onChange: (e) => setMonthDayValue(e.target.value),
                                                            min: 0.00,

                                                        }
                                                    }}
                                                    className={classes.textFieldRoot}
                                                    variant="outlined"
                                                />

                                                of every

                                                <TextField
                                                    style={{ width: "70px", marginRight: "10px", marginLeft: "10px" }}
                                                    size="small"
                                                    type="number"
                                                    // defaultValue={monthValue}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.textFieldRoot,
                                                        },
                                                        inputProps: {
                                                            onChange: (e) => setMonthValue(e.target.value),
                                                            min: 0.00,

                                                        }
                                                    }}
                                                    className={classes.textFieldRoot}
                                                    variant="outlined"
                                                />
                                                month(s)
                                            </span>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <FormControlLabel value="The" control={<Radio style={{ color: "#323232" }} />} label="" />
                                            <span style={{ marginLeft: "-20px", fontSize: "16px" }}>
                                                The
                                                <Select
                                                    style={{ width: "100px", height: "30px", marginLeft: "10px", marginRight: "10px" }}
                                                    MenuProps={{
                                                        className: classes.selectMenu,
                                                    }}
                                                    classes={{
                                                        select: classes.select,
                                                    }}
                                                    value={monthWeekFreq}
                                                    onChange={(event) => {
                                                        setMonthWeekFreq(event.target.value)
                                                    }}
                                                    variant="outlined"
                                                    inputProps={{
                                                        name: "pageSelect",
                                                        id: "page-select",
                                                    }}
                                                >
                                                    {weekly.map((prop, key) => {
                                                        return (
                                                            <MenuItem
                                                                key={key}
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected,
                                                                }}
                                                                value={prop}
                                                            >
                                                                {prop}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                <Select
                                                    style={{ width: "120px", height: "30px", marginRight: "10px" }}
                                                    MenuProps={{
                                                        className: classes.selectMenu,
                                                    }}
                                                    classes={{
                                                        select: classes.select,
                                                    }}
                                                    value={monthDayFreq}
                                                    onChange={(event) => {
                                                        setMonthDayFreq(event.target.value)
                                                    }}
                                                    variant="outlined"
                                                    inputProps={{
                                                        name: "pageSelect",
                                                        id: "page-select",
                                                    }}
                                                >
                                                    {days.map((prop, key) => {
                                                        return (
                                                            <MenuItem
                                                                key={key}
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected,
                                                                }}
                                                                value={prop}
                                                            >
                                                                {prop}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                of every
                                                <TextField
                                                    style={{ width: "70px", marginRight: "10px", marginLeft: "10px" }}
                                                    size="small"
                                                    type="number"
                                                    placeholder="01"
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.textFieldRoot,
                                                        },
                                                        inputProps: {
                                                            onChange: (e) => setMonthValue(e.target.value),
                                                            min: 0.00,

                                                        }
                                                    }}
                                                    className={classes.textFieldRoot}
                                                    variant="outlined"
                                                />
                                                month(s)
                                            </span>
                                        </GridItem>
                                    </GridContainer>
                                    {recurrError.monthlyValueErr ? <p style={{ color: "red" }}>Error! please select an option</p> : null}

                                </RadioGroup>
                            </>
                            :
                            <>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={dailyValue}
                                    onChange={(e) => setDailyValue(e.target.value)}
                                    style={{ marginTop: "20px" }}
                                >
                                    <GridContainer>
                                        <GridItem xs={12} style={{ marginTop: "20px" }}>
                                            <FormControlLabel value="Every" control={<Radio style={{ color: "#323232" }} />} label="" />
                                            <span style={{ marginLeft: "-20px", fontSize: "16px" }}>
                                                Every
                                                <TextField
                                                    style={{ width: "70px", marginRight: "10px", marginLeft: "10px" }}
                                                    size="small"
                                                    type="number"
                                                    placeholder="01"
                                                    value={dailyNum > 0? dailyNum : ""}
                                                    InputProps={{
                                                        classes: {
                                                            root: classes.textFieldRoot,
                                                        },
                                                        inputProps: {
                                                            onChange: (e) => setDailyNum(e.target.value),
                                                            min: 0.00,

                                                        }
                                                    }}
                                                    className={classes.textFieldRoot}
                                                    variant="outlined"
                                                />
                                                day(s)
                                            </span>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <FormControlLabel value="Weekday" control={<Radio style={{ color: "#323232" }} onClick={() => setDailyNum(0)} />} label="" />
                                            <span style={{ marginLeft: "-20px", fontSize: "16px" }}>
                                                Every weekday
                                            </span>
                                        </GridItem>
                                    </GridContainer>
                                    {recurrError.dailyValueErr ? <p style={{ color: "red" }}>Error! please select an option</p> : null}
                                </RadioGroup>
                            </>
                    }
                </GridItem>
            </GridContainer>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={rangeValue}
                onChange={(e) => setRangeValue(e.target.value)}
                style={{ marginTop: "20px" }}
            >
                <GridContainer style={{ marginTop: "20px" }}>
                    <GridItem xs={4} style={{  marginTop: "20px" }}>
                        <p style={{ fontSize: "16px", fontWeight: "bold" }}>Range of recurrence</p>
                        <div style={{ display: "flex" }}>
                            <p style={{ fontSize: "16px", marginRight: "10px", marginTop: "10px" }}>Start: {" "}</p>
                            <span>
                                <Datetime
                                    style={{
                                        color: "#323232 !important",
                                        height: "100%",
                                        width: "100%",
                                    }}
                                    selected={rangeStartDate}
                                    dateFormat="MM/DD/YY"
                                    onChange={(date, e) => {
                                        setRangeStartDate(date.format("MM/DD/YY"));
                                    }}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    closeOnClickOutside
                                    renderInput={(props, openCalendar, closeCalendar) => (
                                        <div onClick={openCalendar}>
                                            <TextField
                                                className={classes.datetimeCustomerSide}
                                                placeholder="MM/DD/YY"
                                                value={rangeStartDate}
                                                variant="outlined"
                                                InputProps={{
                                                    classes: {
                                                        adornedEnd: classes.adournedEndCustomer,
                                                    },
                                                    endAdornment: (
                                                        <InputAdornment
                                                            className={classes.adournedEndCustomer}
                                                            position="end"
                                                        >
                                                            <CalendarIcon
                                                                className={classes.calendarIcon}
                                                                onClick={openCalendar}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={recurrError.rangeStartDateErr}
                                            // helperText={recurrError.rangeStartDateErr ? "Please enter a start date" : ""}
                                            />
                                        </div>
                                    )}
                                />
                                {recurrError.rangeStartDateErr ? <p style={{ color: "red" }}>Error! Please select a start date!</p>
                                    : null}
                            </span>
                        </div>
                    </GridItem>
                    <GridItem xs={8} style={{ marginTop: "30px" }}>
                        <GridContainer>
                            <GridItem xs={12} style={{ marginTop: "10px" }}>
                                <FormControlLabel value="No End Date" control={<Radio style={{ color: "#323232" }} />} label="" />
                                <span style={{ marginLeft: "-20px", fontSize: "16px" }}>
                                    No end Date
                                </span>
                            </GridItem>
                            <GridItem xs={12} style={{ marginTop: "10px" }}>
                                <FormControlLabel value="End after" control={<Radio style={{ color: "#323232" }} />} label="" />
                                <span style={{ marginLeft: "-20px", fontSize: "16px" }}>
                                    End after: {" "}
                                    <TextField
                                        style={{ width: "70px", marginRight: "10px", marginLeft: "10px", paddingRight: "10px" }}
                                        size="small"
                                        type="number"
                                        placeholder="02"
                                        // defaultValue={rangeEndNum}
                                        InputProps={{
                                            classes: {
                                                root: classes.textFieldRoot,
                                            },
                                            inputProps: {
                                                onChange: (e) => setRangeEndNum(e.target.value),
                                                min: 1,

                                            }
                                        }}
                                        className={classes.textFieldRoot}
                                        variant="outlined"
                                        error={recurrError.rangeEndNumErr}
                                    />
                                    jobs
                                </span>
                                {recurrError. rangeEndNumErr ? <p style={{ color: "red" }}>Error! Please fill out the missing field!</p>
                            : null}
                            </GridItem>
                            <GridItem xs={12} style={{ marginTop: "10px", marginBottom: "10px", display: "flex" }}>
                                <FormControlLabel value="End by" control={<Radio style={{ color: "#323232" }} />} label="" />

                                <div style={{ display: "flex" }}>
                                    <span style={{ margin: "10px 10px 0px -20px ", fontSize: "16px", whiteSpace: "noWrap" }}>
                                        End by: {" "}</span>
                                    <Datetime
                                        style={{
                                            color: "#323232 !important",
                                            height: "100%",
                                            width: "100%",
                                        }}
                                        selected={rangeEndDate}
                                        dateFormat="MM/DD/YY"
                                        onChange={(date, e) => {
                                            setRangeEndDate(date.format("MM/DD/YY"));
                                        }}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        closeOnClickOutside
                                        renderInput={(props, openCalendar, closeCalendar) => (
                                            <div onClick={openCalendar}>
                                                <TextField
                                                    className={classes.datetimeCustomerSide}
                                                    placeholder="MM/DD/YY"
                                                    value={rangeEndDate}
                                                    variant="outlined"
                                                    InputProps={{
                                                        classes: {
                                                            adornedEnd: classes.adournedEndCustomer,
                                                        },
                                                        endAdornment: (
                                                            <InputAdornment
                                                                className={classes.adournedEndCustomer}
                                                                position="end"
                                                            >
                                                                <CalendarIcon
                                                                    className={classes.calendarIcon}
                                                                    onClick={openCalendar}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    error={recurrError.rangeEndDateErr}
                                                // helperText={recurrError.rangeEndDateErr ? "Please enter an end date" : ""}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                                {recurrError.rangeEndDateErr ? <p style={{ color: "red" }}>Error! Please select an end date!</p>
                                        : null}
                                {/* </span> */}
                            </GridItem>
                        </GridContainer>
                        {recurrError.rangeValueErr ? <p style={{ color: "red" }}>Error! Please select an option</p>
                            : null}
                    </GridItem>
                </GridContainer>
            </RadioGroup>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                    className={classes.modalCustomerButton}
                    style={{
                        background: "#323232",
                        color: "white"
                    }}
                    onClick={handleModalClose}
                >
                    Cancel
                </Button>
                <Button
                    className={classes.modalCustomerButton}
                    onClick={() => checkRecurr()}
                >
                    Save
                </Button>
            </div>
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleUpdatePendingReservation: (pendingRes) =>
        dispatch(updatePendingReservation(pendingRes)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(modalStyles)
)(ReccurenceModal);
