import React from "react";
import { CardFieldFormats } from "variables/constants";
import { startCase } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";

import styles from "assets/jss/material-dashboard-pro-react/components/cardFieldStyle";

function formatValue(format, value) {
    switch (format) {
        case CardFieldFormats.SIMPLE_DATE:
            if (DateTime.isDateTime(value)) {
                let displayFormat = value.toLocaleString(DateTime.DATE_SHORT);
                return displayFormat;
            } else
                return value;
        default: 
            return value;
    }
}

function formatLabel(label, className) {
    if (label !== "") {
        return (<span className={className}>{label}: </span>);
    }
}

export function CardField(props) {
    const { name, format, classes, value } = props;
    const label = props.label === undefined ? startCase(name) : props.label;
    return (
        <span className={classes.value}>
            { formatLabel( label, classes.label) }
            { formatValue( format, value) }
        </span>      
    );
}

export default withStyles(styles)(CardField);