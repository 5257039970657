import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { createCustomer } from "store/actions/customers/customerActions";
import { getOneWeekLater } from "../../Dashboard/helpers/helpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

const commentSelections = ["Cautious", "New", "Regular"];

function AddCustomerModal(props) {
  const {
    classes,
    handleAddNewCustomer,
    setAddCustomerModal,
    setCustomerCreated,
  } = props;
  const [billingAddress, setBillingAddress] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentSelections, setCommentSelections] = useState([
    "Cautious",
    "New",
    "Regular",
  ]);
  const [errors, setErrors] = useState({
    phoneNumber: false,
    billingAddress: false,
    siteAddress: false,
    dropOffDate: false,
    equipmentLocation: false,
    equiptmentNeeded: false,
    pickupDate: false,
    firstName: false,
    lastName: false,
    email: false,
  });

  const handleSelect = (address, placeholder) => {
    geocodeByAddress(address).then((results) => {
      if (placeholder === "Search your billing address..") {
        setBillingAddress(results[0].formatted_address);
      }
    });
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;
    setPhoneNumber(value);
  };

  const handleSubmit = () => {
    if (!phoneNumber || !billingAddress || !firstName || !lastName || !email) {
      setHasErrors(true);
      let newErrors = {
        phoneNumber: false,
        billingAddress: false,
        firstName: false,
        lastName: false,
        email: false,
      };
      if (!phoneNumber) {
        newErrors = { ...newErrors, phoneNumber: true };
      }
      if (!billingAddress) {
        newErrors = { ...newErrors, billingAddress: true };
      }
      setErrors({ ...newErrors });
    } else {
      const newCustomer = {
        comments: comments,
        company: "",
        email: email,
        name: firstName + " " + lastName,
        firstName,
        lastName,
        billingAddress,
        billingAddressLine2: addressLine2,
        reservations: [],
        phoneNumber
      };
      handleAddNewCustomer(newCustomer).then(() => {
        setCommentSelections(["Cautious", "New", "Regular"]);
        setAddCustomerModal(false);
        setComments([]);
        setEmail("");
        setFirstName("");
        setLastName("");
        setBillingAddress("");
        setAddressLine2("");
        setPhoneNumber("");
        setCustomerCreated(true);
      });
    }
  };

  const handleCommentsChange = (e) => {
    const indexToRemove = commentSelections.indexOf(e.target.value);
    let newCommentsSelections = [...commentSelections];
    newCommentsSelections.splice(indexToRemove, 1);
    setCommentSelections([...newCommentsSelections]);
    setComments([...comments, e.target.value]);
  };

  const handleRemoveComment = (comment) => {
    const indexToRemove = comments.indexOf(comment);
    let newComments = [...comments];
    newComments.splice(indexToRemove, 1);
    setCommentSelections([...commentSelections, comment]);
    setComments([...newComments]);
  };

  return (
    <Card style={{ margin: "0px" }} className={classes.card}>
      <h5 className={classes.formTitle}>
        Contact Information:{" "}
        {errors.phoneNumber ||
        errors.firstName ||
        errors.lastName ||
        errors.email ? (
          <span style={{ color: "red" }}>Required*</span>
        ) : (
          <div></div>
        )}
      </h5>
      <TextField
        className={classes.emailInput}
        value={email}
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={firstName}
        placeholder="First Name"
        onChange={(e) => setFirstName(e.target.value)}
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        variant="outlined"
        placeholder="Last Name"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <TextField
        className={classes.emailInput}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        variant="outlined"
        placeholder="Phone Number"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <h5 className={classes.formTitle}>
        Billing Address:{" "}
        {errors.billingAddress && (
          <span style={{ color: "red" }}>Required*</span>
        )}{" "}
      </h5>
      <GooglePlacesAutoComplete
        classes={classes}
        value={billingAddress}
        width={"100%"}
        placeholder="Search your billing address.."
        onChange={(e) => setBillingAddress(e)}
        onSelect={handleSelect}
        margin="0px 0px 20px 0px"
        background="#F5F5F5"
        googleCallbackName="initOne"
      />

      <TextField
        className={classes.emailInput}
        value={addressLine2}
        placeholder="Address line 2 (optional)"
        variant="outlined"
        onChange={(e) => setAddressLine2(e.target.value)}
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      <h5 className={classes.formTitle}>Comments: </h5>

      <Select
        className={classes.filterMenu}
        value={""}
        sx={{ background: "#F5F5F5" }}
        disableUnderline
        defaultValue={"Select Option"}
        classes={{
          root: classes.selectText,
        }}
        onChange={handleCommentsChange}
      >
        {commentSelections.map((o, i) => {
          return (
            <MenuItem
              key={i}
              value={o}
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              {o}
            </MenuItem>
          );
        })}
      </Select>

      <div style={{ display: "flex", width: "100%", marginTop: "15px" }}>
        {comments.map((c) => {
          console.log("comments", comments);
          return (
            <div
              style={
                c === "Regular"
                  ? { background: "#2EB67D" }
                  : c === "Cautious"
                  ? { background: "#323232" }
                  : c === "Overdue"
                  ? { background: "#F83030" }
                  : c === "New"
                  ? { background: "#0071C5" }
                  : { background: "#ECB22E", color: "#323232" }
              }
              className={classes.commentDiv}
            >
              {c}{" "}
              <span
                onClick={() => handleRemoveComment(c)}
                style={{ color: "white", fontSize: "20px" }}
              >
                X
              </span>
            </div>
          );
        })}
      </div>

      {/* <h5 className={classes.formTitle}>
        Drop-Off Address:{" "}
        {errors.siteAddress && <span style={{ color: "red" }}>Required*</span>}{" "}
      </h5>
      <GooglePlacesAutoComplete
        classes={classes}
        value={siteAddress}
        width={"100%"}
        placeholder="Search your drop-off address.."
        onChange={(e) => setSiteAddress(e)}
        onSelect={handleSelect}
        margin="0px 0px 20px 0px"
        background="#F5F5F5"
      />

      <h5 className={classes.formTitle}>
      </h5>
      <Datetime
        input
        // className={classes.textFieldRoot}
        inputProps={{
          className: classes.datetime,
          placeholder: "Choose rental drop-off date..",
        }}
        style={{
          color: "#323232 !important",
          height: "100%",
          width: "100%",
        }}
        selected={dropOffDate}
        dateFormat="MM/DD/YY"
        onChange={(date, e) => {
          setDropOffDate(date.format("MM/DD/YY"));
        }}
        timeFormat={false}
        closeOnSelect={true}
        closeOnClickOutside
      />

      <h5 className={classes.formTitle}>
        Requested Pick Up Date:{" "}
        {errors.pickupDate && <span style={{ color: "red" }}>Required*</span>}
      </h5>
      <span>Do you want a 7 day rental?</span>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "30px" }}
        >
          <Checkbox
            checked={sevenDayRental}
            disabled={!dropOffDate ? true : false}
            style={{
              color: "#FFC720",
            }}
            onChange={() => {
              setSevenDayRental(true);
              setPickupDate(getOneWeekLater(dropOffDate));
            }}
          />
          <span>Yes</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            style={{
              color: "#FFC720",
            }}
            disabled={!dropOffDate ? true : false}
            checked={!sevenDayRental ? true : false}
            onChange={() => setSevenDayRental(false)}
          />
          <span>No</span>
        </div>
      </div>

      {!sevenDayRental && (
        <>
          <h5 className={classes.formTitle}>
            *PLEASE NOTE WE WILL PICK UP ON THE DAY YOU SELECT BELOW. Please
            ensure you select the correct pick up date for your desired rental
            duration.{" "}
          </h5>
          <Datetime
            input
            // className={classes.textFieldRoot}
            inputProps={{
              className: classes.datetime,
              placeholder: "Choose rental pickup date..",
            }}
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
            }}
            selected={pickupDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              setPickupDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
          />
        </>
      )}

      <h5 className={classes.formTitle}>
        Container Needed:{" "}
        {errors.equiptmentNeeded && (
          <span style={{ color: "red" }}>Required*</span>
        )}
      </h5>
      <Select
        className={classes.filterMenu}
        value={equiptmentNeeded}
        sx={{ background: "#F5F5F5" }}
        disableUnderline
        defaultValue={"Select Option"}
        classes={{
          root: classes.selectText,
        }}
        onChange={(e) => setEquiptmentNeeded(e.target.value)}
      >
        {inventoryData.map((o, i) => {
          return (
            <MenuItem
              key={i}
              value={o}
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              {o.inventoryItem}
            </MenuItem>
          );
        })}
      </Select>

      <h5 className={classes.formTitle}>
        Container Location (if you are facing the house):{" "}
        {errors.equipmentLocation && (
          <span style={{ color: "red" }}>Required*</span>
        )}
      </h5>
      <Select
        className={classes.filterMenu}
        value={equipmentLocation}
        sx={{ background: "#F5F5F5" }}
        disableUnderline
        defaultValue={"Select Option"}
        classes={{
          root: classes.selectText,
        }}
        onChange={(e) => setEquiptmentLocation(e.target.value)}
      >
        {locationSelections.map((o, i) => {
          return (
            <MenuItem
              key={i}
              value={o}
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              {o}
            </MenuItem>
          );
        })}
      </Select>

      <h5 className={classes.formTitle}>How did you hear about us?</h5>
      <Select
        className={classes.filterMenu}
        value={heardAboutUsFrom}
        sx={{ background: "#F5F5F5" }}
        disableUnderline
        defaultValue={"Select Option"}
        classes={{
          root: classes.selectText,
        }}
        onChange={(e) => setHeardAboutUsFrom(e.target.value)}
      >
        {heardAboutUsFromSelections.map((o, i) => {
          return (
            <MenuItem
              key={i}
              value={o}
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              {o}
            </MenuItem>
          );
        })}
      </Select>

      <h5 className={classes.formTitle}>Other Information</h5>
      <TextareaAutosize
        aria-label="empty textarea"
        className={classes.emailInput}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="On-Site Contact Information, Special Requests, Long-Term Rental, Multiple Containers, etc. "
        style={{ maxWidth: "100%" }}
      />

      <TextField
        className={classes.emailInput}
        value={promoCode}
        placeholder="Promo Code"
        onChange={(e) => setPromoCode(e.target.value)}
        variant="outlined"
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      /> */}

      <Button
        style={{ marginTop: "30px" }}
        onClick={handleSubmit}
        className={classes.sendBtn}
      >
        Create Customer
      </Button>

      <Snackbar open={hasErrors} onClose={() => setHasErrors(false)}>
        <Alert onClose={() => setHasErrors(false)} severity={"error"}>
          Please Fill Out All Mandatory Fields
        </Alert>
      </Snackbar>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  handleAddNewCustomer: (newCustomer) => dispatch(createCustomer(newCustomer)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(AddCustomerModal));
