import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/modalsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";

function AddRegularModal(props) {
  const {
    classes,
    selectedCustomer,
    handleModalClose,
    handleApproveRegular
  } = props;

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
        <Card className={classes.modalCard}>
          <h1 className={classes.modalTitle}>
            Are you sure you want to make {selectedCustomer.name} a regular?
          </h1>
          <CardBody className={classes.modalCardBody}>
            <Button
              onClick={handleApproveRegular}
              style={{ background: "#ECB22E", color: "#323232" }}
              className={classes.modalCardButton}
            >
              Yes
            </Button>
            <Button
              onClick={handleModalClose}
              style={{ color: "white", background: "#323232" }}
              className={classes.modalCardButton}
            >
              No
            </Button>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(modalStyles)
)(AddRegularModal);
