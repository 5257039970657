import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import ChipInput from "material-ui-chip-input";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomChipInput(props) {

    const classes = useStyles();

    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        error,
        white,
        inputRootCustomClasses,
        success,
        helperText,
        ...rest
    } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
        formControlProps.className,
        classes.formControl
        );
    } else {
        formControlClasses = classes.formControl;
    }

    var helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });

    return (
        <FormControl {...formControlProps} className={formControlClasses}>
            {labelText !== undefined ? (
                <InputLabel
                    className={classes.labelRoot + " " + labelClasses}
                    htmlFor={id}
                    {...labelProps}
                >
                    {labelText}
                </InputLabel>
            ) : null}        
            <ChipInput
                classes={{input: { ...inputClasses, ...underlineClasses}, inputRoot: inputRootCustomClasses}}
                InputProps={inputProps}
                {...rest}
            />     
            {helperText !== undefined ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {helperText}
                </FormHelperText>
            ) : null}
        </FormControl>           
    );
}
