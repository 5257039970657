import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./Dnd/Sortable";
import Droppable from "./Dnd/Droppable";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import DriverDayScheduleOpen from "./DriverDayScheduleOpen.jsx";

const containerStyle = {
  background: "#dadada",
  padding: 10,
  margin: 10,
  flex: 1,
};

export default function DriverRow(props) {
  const {
    id,
    items,
    classes,
    width,
    history,
    driver,
    columnWidth,
    weeklyItems,
    setWeeklyItems,
    weeklySchedule,
    searchString,
    driverScheduleCount,
  } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useDroppable({
    id,
    driverId: id,
    data: {
      data: items,
    },
  });
  // const wSched = Object.values(weeklyItems[id].weeklySchedule)
  return (
    // <SortableContext
    //   strategy={verticalListSortingStrategy}
    //   id={id}
    //   // items={items[id].weeklySchedule}
    // >
    <div style={{ display: "flex", width: "100%" }}>
      <div
        style={{
          width: `${columnWidth}%`,
          background: "white",
          display: "flex",
          alignItems: "center",
          border: "0.5px solid #929292",
          padding: "10px",
        }}
      >
        <span
          style={{ display: "flex", alignItems: "center" }}
          className={classes.scheduleHeaderText}
        >
          {driver.open ? (
            <ArrowDropDownIcon
              onClick={() => {
                const itemsCopy = weeklyItems;
                itemsCopy[driver.driverId].open = false;
                setWeeklyItems({ ...itemsCopy });
              }}
            />
          ) : (
            <ArrowRightIcon
              onClick={() => {
                const itemsCopy = weeklyItems;
                itemsCopy[driver.driverId].open = true;
                setWeeklyItems({ ...itemsCopy });
              }}
            />
          )}
          {`${driver.name} (${driverScheduleCount})`}
        </span>
      </div>

      {weeklySchedule.map((w, index) => {
        const splitDate = w.split(" ")[1];
        const driversDaySchedule = driver.weeklySchedule[splitDate];
        if (driver.open && driversDaySchedule[0]) {
          return (
            <DriverDayScheduleOpen
              key={index}
              id={driver.driverId}
              items={driversDaySchedule}
              columnWidth={columnWidth}
              driver={driver}
              weeklyItems={weeklyItems}
              setWeeklyItems={setWeeklyItems}
              classes={classes}
              searchString={searchString}
              splitDate={splitDate}
              // driverColor={driver.color}
            />
          );
        } else {
          if (driversDaySchedule[0]) {
            let startOrEnd;
            if (driversDaySchedule[0].reservation.startDate === splitDate) {
              startOrEnd = "START";
            } else {
              startOrEnd = "END";
            }
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: `${columnWidth}%`,
                }}
              >
                <SortableItem
                  reservationId={
                    driversDaySchedule[0].reservation.reservationId
                  }
                  driverId={driver.driverId}
                  // id={driver.driverId}
                  id={
                    driversDaySchedule[0].reservation.reservationId +
                    " ? " +
                    startOrEnd
                  }
                  driver={driver}
                  weeklyItems={weeklyItems}
                  setWeeklyItems={setWeeklyItems}
                  classes={classes}
                  data={driversDaySchedule[0]}
                  columnWidth={columnWidth}
                  searchString={searchString}
                  splitDate={splitDate}
                  territoryColor={driversDaySchedule[0].reservation.territoryColor}
                />
              </div>
            );
          } else {
            return (
              <Droppable
                key={index}
                columnWidth={columnWidth}
                id={driver.driverId + " - " + splitDate + " - " + driver.name}
                splitDate={splitDate}
              />
            );
          }
        }
      })}
    </div>
    // </SortableContext>
  );
}
