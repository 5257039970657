import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Checkbox from "@material-ui/core/Checkbox";
import Email from "@material-ui/icons/Email";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { ReactComponent as OR } from "assets/img/orSvg.svg";
import { FcGoogle } from "react-icons/fc";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { connect } from "react-redux";
import compose from "recompose/compose";
import withStyles from "@material-ui/core/styles/withStyles";
import AuthActions from "store/actions/auth";

const LoginBox = (props) => {
  const {
    classes,
    handleSubmitLogIn,
    onChange,
    handleRememberMe,
    checked,
    handleSignUp,
    startGoogleLogin,
    handlePasswordReset,
    handleForgotPw
  } = props;

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} className={classes.formContainer}>
        <form onSubmit={handleSubmitLogIn}>
          <Card className={classes.signInCard}>
            <CardHeader className={classes.cardHeaderSignIn}>
              Sign In
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Email"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: onChange,
                  disableUnderline: true,
                  autoComplete: "off",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email />
                    </InputAdornment>
                  )
                }}
              />
              <CustomInput
                labelText="Password"
                id="password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: onChange,
                  type: "password",
                  autoComplete: "off",
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon>lock_outline</Icon>
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <Checkbox onChange={handleRememberMe} checked={checked} />
                  <span>Remember Me</span>
                </div>

                <span className={classes.forgotYourPassword} onClick={handleForgotPw}>
                  Forgot Your Password?
                </span>
              </div>
            </CardBody>
            <CardFooter className={classes.loginCardFooter}>
              <GridContainer>
                <GridItem xs={6}>
                  <Button
                    onClick={handleSubmitLogIn}
                    className={classes.signInButton}
                  >
                    Log In
                  </Button>
                </GridItem>
                <GridItem xs={6}>
                  <Button
                    style={{ background: "#323232", color: "white" }}
                    onClick={handleSignUp}
                    className={classes.signInButton}
                  >
                    Sign Up
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem className={classes.orContainer} xs={12}>
                  {" "}
                  <OR />
                </GridItem>
              </GridContainer>
              <Button
                onClick={startGoogleLogin}
                className={classes.useGoogleButton}
              >
                Access With Google <FcGoogle style={{ marginLeft: "20px" }} />
              </Button>
            </CardFooter>
          </Card>
        </form>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startGoogleLogin: () => dispatch(AuthActions.startGoogleLogin()),
  passwordReset: (email) => dispatch(AuthActions.startPasswordReset(email)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(LoginBox);
