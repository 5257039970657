import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import settingsStyle from "assets/jss/material-dashboard-pro-react/views/settingsStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Tooltip from "@material-ui/core/Tooltip";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";

import AccountingTabCustomTabs from "./AccountingTabCustomTabs";
import MiscChargesTable from "./MiscChargesTable";
import ChartOfAccountsTable from "./ChartOfAccountsTable";
import CCProcessingTab from "./CCProcessingTab";
import QuickbooksData from "./QuickbooksData";

function AccountingTab(props) {
  const { classes, history, userInfo, company, companyId, staff } = props;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card className={classes.card}>
          <AccountingTabCustomTabs
            classes={classes}
            history={history}
            tabs={[
              {
                tabName: "Misc. Charges",
                tabContent: <MiscChargesTable history={history} />,
              },
              {
                tabName: "Chart of Accounts",
                tabContent: <ChartOfAccountsTable history={history} />,
              },
              {
                tabName: "CC Processing",
                tabContent: <CCProcessingTab history={history} />,
              },
              {
                tabName: "QuickBooks Data",
                tabContent: <QuickbooksData history={history} />
              },
            ]}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(settingsStyle)
)(React.memo(AccountingTab));
