import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getNextNine,
  getPrevFourteen,
  getDates,
  getNewDataFromCalendarSelection,
  getRangeBetweenTwoDates,
} from "../helpers/helpers";
import Datetime from "react-datetime";
import moment from "moment";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InvoicesTable from "./InvoicesTable";
import InvoicesFilters from "./InvoicesFilters";

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

function Invoices(props) {
  const { classes, reservation, history } = props;
  const [filteredTableData, setFilteredTableData] = useState([]);

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.jobsHeaderGridItem} xs={12}>
          <h1 className={classes.title}>Invoices</h1>
          <Button
            onClick={() => history.push("/admin/create-invoice")}
            className={classes.editRentalRatesButton}
          >
            <AddIcon />
            Create Invoice
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <InvoicesFilters />
        </GridItem>
        <GridItem xs={12}>
          <InvoicesTable history={history} reservation={reservation} classes={classes} />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(Invoices));
