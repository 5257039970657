import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/modalsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const statusChoices = ["Draft", "Approved", "Cancelled"];

function UpdateInvoiceStatusModal(props) {
  const {
    classes,
    setUpdateInvoiceStatusModal,
    selectedInvoiceStatus,
    setSelectedInvoiceStatus,
    saveNewInvoiceStatus
  } = props;

  console.log("selectedInvoiceStatus", selectedInvoiceStatus);

  return (
    <GridContainer className={classes.updateInvoiceStatusModal}>
      <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
        <Card className={classes.modalCard}>
          <h1 className={classes.modalTitle}>Update Invoice Status</h1>
          <CardBody className={classes.modalCardBody}>
            <Select
              className={classes.filterMenu}
              style={{ zIndex: 9999 }}
              value={selectedInvoiceStatus}
              disableUnderline
              classes={{
                root: classes.selectText,
              }}
              onChange={(e) => setSelectedInvoiceStatus(e.target.value)}
            >
              {statusChoices.map((o, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={o}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {o}
                  </MenuItem>
                );
              })}
            </Select>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <Button
                onClick={saveNewInvoiceStatus}
                style={{ background: "#ECB22E", color: "#323232", width: "45%" }}
                className={classes.modalCardButton}
              >
                Submit
              </Button>
              <Button
                onClick={() => setUpdateInvoiceStatusModal(false)}
                style={{ color: "white", background: "#323232", width: "45%" }}
                className={classes.modalCardButton}
              >
                Cancel
              </Button>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(modalStyles)
)(UpdateInvoiceStatusModal);
