import React, { useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/modalsStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { deleteQuote } from "store/actions/quotes/quotesActions";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";

function DenyModal(props) {
  const {
    classes,
    selectedPendingReservation,
    handleModalClose,
    handleDeleteQuote,
    setModalEditedSuccessful,
  } = props;
  const [denialReason, setDenialReason] = useState("");
  const [editErrors, setEditErrors] = useState({ denialReason: false });

  const submitCancelQuote = () => {
    return handleDeleteQuote(
      selectedPendingReservation,
      handleModalClose,
      setModalEditedSuccessful
    );
  };

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
        <Card style={{ margin: "0px" }}>
          <div
            style={{
              background: "#F1C84E",
              display: "flex",
              width: "100%",
              justifyContent: "end",
              padding: "10px 10px 0px 0px",
            }}
          >
            <BlackDenyIcon
              onClick={() => handleModalClose()}
              className={classes.closeModal}
            />
          </div>
          <h1 className={classes.modalTitle}>
            Are you sure you want to delete this quote?
          </h1>
          <CardBody className={classes.modalCardBody}>
            <GridContainer>
              <GridItem xs={6}>
                <Button
                  onClick={submitCancelQuote}
                  style={{ background: "#ECB22E", color: "#323232" }}
                  className={classes.modalCardButton}
                >
                  Delete Quote
                </Button>
              </GridItem>
              <GridItem xs={6}>
                <Button
                  onClick={handleModalClose}
                  style={{ color: "white", background: "#323232" }}
                  className={classes.modalCardButton}
                >
                  Close
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleDeleteQuote: (pendingRes, handleModalClose, setModalEditedSuccessful) =>
    dispatch(
      deleteQuote(pendingRes, handleModalClose, setModalEditedSuccessful)
    ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(modalStyles)
)(DenyModal);
