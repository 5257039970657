import React from "react";
import SortChip from "./SortChip";
import withStyles from "@material-ui/core/styles/withStyles";
import Style from "assets/jss/material-dashboard-pro-react/components/searchDialogStyle"

/**
 * 
 * A dialog box which contains all the fields specified in a config parameter.
 * 
 */
export function SearchDialog(props) {
    const { classes, order, children, Dialog, Form, FormFields, SortChips } = props;
    return (
        <Dialog title="Advanced Query" classes={classes}>
            <Form>
                <SortChips name="_sort" Chip={SortChip}/>
                <FormFields order={order}>
                    { children }
                </FormFields>
            </Form>
        </Dialog>
    );
}

export default withStyles(Style)(SearchDialog);