/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import { Search } from "@material-ui/icons";

function ProfileCard(props) {
  const {
    classes,
    history,
    userInfo,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    website,
    setWebsite,
    phoneNumber,
    setPhoneNumber,
    secondaryPhoneNumber,
    setSecondaryPhoneNumber,
    email,
    setEmail,
    company,
    setCompany,
    setChangedEmail
  } = props;

  return (
    <Card style={{ marginBottom: "0px" }} className={classes.card}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>First Name</span>
          <TextField
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Last Name</span>
          <TextField
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Website</span>
          <TextField
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Phone Number (Primary)</span>
          <TextField
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Phone Number (Secondary)
          </span>
          <TextField
            value={secondaryPhoneNumber}
            onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Email</span>
          <TextField
            value={email}
            onChange={(e) => {
              setChangedEmail(true)
              setEmail(e.target.value)
            }}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Company</span>
          <TextField
            value={company}
            onChange={(e) => {
              setCompany(e.target.value)
            }}
            variant="outlined"
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
              endAdornment: (
                <InputAdornment
                  className={classes.adournedEnd}
                  position="end"
                >
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <p style={{
            fontFamily: "Archivo",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            display: "flex",
            whiteSpace: "break-spaces"
          }}>Start typing to search. You can also {" "}
            <p style={{ textDecoration: "underline", color: "#0071C5" }}>
              add a new customer.</p>
          </p>
        </GridItem>
      </GridContainer>
      {/* <Snackbar open={snackbarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackbarContent !== "Succesfully Changed Password"
              ? "error"
              : "success"
          }
        >
          {snackbarContent}
        </Alert>
      </Snackbar> */}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(ProfileCard));
