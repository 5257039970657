import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./Dnd/Sortable";
import Droppable from "./Dnd/Droppable";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";

const containerStyle = {
  background: "#dadada",
  padding: 10,
  margin: 10,
  flex: 1,
};

export default function DriverColumn(props) {
  const { id, items, classes, width, history, driver, searchString, splitSchedule,     setHoveredItemId,
    hoveredItemId, } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useDroppable({
    id,
    driverId: id,
    data: {
      data: items,
    },
  });
  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <div
        style={{ width: width }}
        ref={setNodeRef}
        className={classes.scheduleColumn}
      >
        <div className={classes.scheduleColumnHeader}>
          <span className={classes.scheduleHeaderText}>{driver.name}</span>
          <ThreeDots />
        </div>

        {items.map((item, index) => {
          return (
            <div key={item.reservation.reservationId}>
              <SortableItem
                splitSchedule={splitSchedule}
                driverItemIndex={index}
                history={history}
                classes={classes}
                r={item || []}
                id={item.reservation.reservationId}
                reservationId={item.reservation.reservationId}
                data={item}
                driverId={id}
                searchString={searchString}
                territoryColor={item.reservation.territoryColor}
                setHoveredItemId={setHoveredItemId}
                hoveredItemId={hoveredItemId}
              />
            </div>
          );
        })}
      </div>
    </SortableContext>
  );
}
