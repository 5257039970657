/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";

function CustomerCard(props) {
  const {
    classes,
    customersData,
    setCustomer,
    errors,
    sites,
    setSite,
    history,
  } = props;

  return (
    <Card className={classes.invoiceCard}>
      <span style={{ fontSize: "30px", fontWeight: 800, marginBottom: "1rem" }}>
        Customer
      </span>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Customer{" "}
            {errors.customer && (
              <span style={{ color: "red", marginLeft: "10px" }}>
                Required*
              </span>
            )}
          </span>
          <Autocomplete
            id="country-select-demo"
            options={customersData}
            classes={{
              option: classes.option,
            }}
            onChange={(e, value) => {
              setCustomer(value);
            }}
            getOptionLabel={(customer) =>
              customer.name ? customer.name : "No Name Found"
            }
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <SearchIcon
                        className={classes.calendarIcon}
                        // onClick={openCalendar}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <span>
            Start typing to search. You can also{" "}
            <span
              style={{ color: "blue" }}
              onClick={() =>
                history.push({
                  pathname: "/admin/customers",
                  state: { edit: true },
                })
              }
            >
              add a new customer.{" "}
            </span>{" "}
          </span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Site{" "}
            {errors.site && (
              <span style={{ color: "red", marginLeft: "10px" }}>
                Required*
              </span>
            )}
          </span>
          <Autocomplete
            id="country-select-demo"
            options={sites}
            classes={{
              option: classes.option,
            }}
            onChange={(e, value) => {
              setSite(value);
            }}
            getOptionLabel={(site) =>
              site.siteName ? site.siteName : site.siteAddress
            }
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <SearchIcon className={classes.calendarIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <span>
            Start typing to search. You can also{" "}
            <span
              style={{ color: "blue" }}
              onClick={() => history.push("/admin/add-new-site")}
            >
              add a new site.
            </span>
          </span>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    sites: state.sites.sites,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(CustomerCard));
