import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Datetime from "react-datetime";
import moment from "moment";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Radio } from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

function QuoteJobCard(props) {
  const {
    classes,
    inventoryData,
    equipmentLocation,
    setEquipmentLocation,
    locationSelections,
    setCost,
    cost,
    schedulingConsent,
    setSchedulingConsent,
    equipmentNeeded,
    setEquipmentNeeded,
    errors,
  } = props;

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Size of New Dumpster{" "}
            {errors.productType && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <Select
            className={classes.filterMenu}
            value={equipmentNeeded}
            variant="outlined"
            onChange={(e) => {
              setEquipmentNeeded(e.target.value);
              setCost(e.target.value.cost);
            }}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
          >
            {inventoryData.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o.inventoryItem}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Location of Dumpster{" "}
            {errors.equipmentLocation && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <Select
            className={classes.filterMenu}
            value={equipmentLocation}
            sx={{ background: "#F5F5F5" }}
            disableUnderline
            defaultValue={"Select Option"}
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => {
              setEquipmentLocation(e.target.value);
            }}
          >
            {locationSelections.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span
            style={{ textTransform: "none", marginBottom: "5px" }}
            className={classes.siteInfoTitle}
          >
            Have you spoken with a member of our team?{" "}
            {errors.schedulingConsent && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // marginRight: "30px",
              }}
            >
              <Radio
                checked={schedulingConsent.yes}
                onChange={() => {
                  setSchedulingConsent({ yes: true, no: false });
                }}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                style={{
                  width: "50px",
                  height: "50px",
                  // borderRadius: "10%",
                  // border: "1px solid #929292",
                }}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span>Yes</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Radio
                checked={schedulingConsent.no}
                onChange={() => setSchedulingConsent({ yes: false, no: true })}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                style={{
                  width: "50px",
                  height: "50px",
                  // borderRadius: "10%",
                  // border: "1px solid #929292",
                }}
                checkedIcon={
                  <CheckRounded className={classes.radioChecked} style={{ color: "#929292", border: "1px solid #929292", }} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span>No, call me to finalize.</span>
            </div>
          </div>
        </GridItem>

      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(QuoteJobCard));
