import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import GrossSalesTable from "./components/GrossSalesTable";
import Button from "components/CustomButtons/Button.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
// import BusinessOverview from "./components/BusinessOverview";
// import WhoOwes from "./components/WhoOwes";
// import SalesAndCustomers from "./components/SalesAndCustomers";

const GrossSalesData = [
  {
    date: "11/26/22",
    type: "Invoice",
    subtotal: "$362.00",
    discount: "",
    taxRate: "",
    nonTaxable: "$362.00",
    taxable: "$0.00",
    tax: "$0.00",
    total: "$1097.00",
  },
  {
    date: "11/26/22",
    type: "Credit Memo",
    subtotal: "-$312.00",
    discount: "",
    taxRate: "",
    nonTaxable: "-$312.00",
    taxable: "$0.00",
    tax: "$0.00",
    total: "-$312.00",
  },
  {
    date: "11/26/22",
    type: "Invoice",
    subtotal: "$362.00",
    discount: "",
    taxRate: "",
    nonTaxable: "$362.00",
    taxable: "$0.00",
    tax: "$0.00",
    total: "$1097.00",
  },
  {
    date: "11/26/22",
    type: "Refund",
    subtotal: "-$262.00",
    discount: "",
    taxRate: "",
    nonTaxable: "-$262.00",
    taxable: "$0.00",
    tax: "$0.00",
    total: "-$262.00",
  },
  {
    date: "11/26/22",
    type: "Invoice",
    subtotal: "$362.00",
    discount: "",
    taxRate: "",
    nonTaxable: "$362.00",
    taxable: "$0.00",
    tax: "$0.00",
    total: "$1097.00",
  },
];

function DepositDetailReport(props) {
  const { classes, reservationsData, history } = props;
  return (
    <GridContainer>
      <GridItem className={classes.jobsHeaderGridItem} xs={12}>
        <h1 className={classes.title}>Gross Sales</h1>
      </GridItem>
      <GridItem xs={12}>
        <GrossSalesTable history={history} GrossSalesData={GrossSalesData} />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(DepositDetailReport));
