import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from "firebase/client.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import JobDetailHeader from "./components/JobDetailHeader";
import JobDetails from "./components/JobDetails";
import loader from "assets/img/loader.gif";

function JobDetail(props) {
  const { classes, history } = props;
  const [editReservationModal, setEditReservationModal] = useState(false);
  // const job = props.location.state.job;
  const [job, setJob] = useState(
    props.location.state ? props.location.state.job : false
  );
  const jobId = window.location.pathname.split("/")[3];
    console.log('jobbbb', job)
  useEffect(() => {
    if (!job) {
      const getJobInfo = async () => {
        return db
          .collection("jobs")
          .doc(jobId)
          .onSnapshot((snapshot) => {
            snapshot = snapshot.data();
            setJob({...snapshot});
          });
      };
      getJobInfo();
    }
  }, []);
  console.log("jobId", jobId);
  if (!job)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );
  return (
    <GridContainer>
      <GridItem xs={12}>
        <JobDetailHeader
          setEditReservationModal={setEditReservationModal}
          job={job}
        />
      </GridItem>
      <GridItem xs={12}>
        <JobDetails jobId={jobId} history={history} job={job} />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(JobDetail));
