import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase } from 'firebase/client';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";
import Checkbox from "@material-ui/core/Checkbox";

const mockInvoiceData = [
  {
    invoiceId: "I342115",
    siteAddress: "3792 S Acoma St Englewood, CO",
    paymentDueDate: "Oct 18, 2022",
    invoiceDate: "Oct 18, 2022",
    amount: "387.00",
    amountPaid: "387.00",
    lastEmailed: "Oct 16, 2022",
    status: "Paid",
    checked: false,
  },
  {
    invoiceId: "I342116",
    siteAddress: "3792 S Acoma St Englewood, CO",
    paymentDueDate: "Oct 18, 2022",
    invoiceDate: "Oct 18, 2022",
    amount: "387.00",
    amountPaid: "0",
    lastEmailed: "Oct 16, 2022",
    status: "Overdue",
    checked: false,
  },
  {
    invoiceId: "I342116",
    siteAddress: "3792 S Acoma St Englewood, CO",
    paymentDueDate: "Oct 18, 2022",
    invoiceDate: "Oct 18, 2022",
    amount: "387.00",
    amountPaid: "0",
    lastEmailed: "Oct 16, 2022",
    status: "Void",
    checked: false,
  },
  {
    invoiceId: "I342116",
    siteAddress: "3792 S Acoma St Englewood, CO",
    paymentDueDate: "Oct 18, 2022",
    invoiceDate: "Oct 18, 2022",
    amount: "387.00",
    amountPaid: "0",
    lastEmailed: "Oct 16, 2022",
    status: "Unpaid",
    checked: false,
  },
];

const headers = [
  "Invoice ID",
  "Site Address",
  "Due Date",
  "Invoice Date",
  "Amount",
  "Remaining",
  "Emailed",
  "Status",
  "Actions",
];
const sortByOptions = ["Invoice Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];

function InvoicesTable(props) {
  const { classes, reservation, history } = props;
  const [invoicesState, setInvoicesState] = useState([{...reservation.reservation}]);
  console.log('invoicesStateinvoicesState', invoicesState)
  const renderTableHeader = () => {
    return (
      <tr className={classes.notesTableRow}>
        {headers.map((key, index) => {
          return (
            <th className={classes.notesTableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    return invoicesState.map((d, index) => {
      return (
        <tr className={classes.notesTableRow} key={d.name}>
          <td className={classes.notesTableCell}>{d.reservationId}</td>
          <td className={classes.notesTableCell}>{d.siteAddress}</td>
          <td className={classes.notesTableCell}>{d.paymentDueDate}</td>
          <td className={classes.notesTableCell}>{d.startDate}</td>
          <td className={classes.notesTableCell}>{d.amount}</td>
          <td className={classes.notesTableCell}>
            {" "}
            ${parseFloat(d.amount) - parseFloat(d.amountPaid)}
          </td>
          <td className={classes.notesTableCell}>{d.lastEmailedDate}</td>
          <td className={classes.notesTableCell}>
            <div
              style={
                d.status === "Overdue"
                  ? { background: "#F83030" }
                  : d.status === "Paid"
                  ? { background: "#2EB67D" }
                  : d.status === "Void"
                  ? { background: "#D9D9D9", color: "#323232" }
                  : { background: "#E0AC12", color: "#323232" }
              }
              className={classes.statusDiv}
            >
              {d.status}
            </div>
          </td>
          <td className={classes.notesTableCell}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Tooltip
                id="tooltip-top"
                title="View Invoice"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{ background: "#FFC720" }}
                  className={classes.actionBtn}
                >
                  <EyeIcon />
                </Button>
              </Tooltip>
              <Tooltip
                id="tooltip-top"
                title="Edit Invoice"
                onClick={() => history.push(`/admin/edit-invoice/${d.invoiceId}`)}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#ECB22E" }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Email Invoice"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#323232" }}
                >
                  <MailIcon />
                </Button>
              </Tooltip>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <table style={{ marginTop: "30px" }} className={classes.inventoryTable}>
          <tbody>
            {renderTableHeader()}
            {renderTableData()}
          </tbody>
        </table>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(InvoicesTable));
