/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";

const communcationsOptions = ["Email", "Call"];
const tagOptions = ["Overdue", "Unpaid", "New", "Regular", "Cautious"];

function JobNameCard(props) {
  const {
    classes,
    companyName,
    setCompanyName,
    customersData,
    sites,
    jobName,
    setJobName,
    customer,
    setCustomer
  } = props;

  const handleSelect = (address, setFunction) => {
    setFunction(address);
  };

  return (
    <Card style={{ marginTop: "0px" }} className={classes.card}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Job Name (Optional)</span>
          <TextField
            value={jobName}
            onChange={(e) => setJobName(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem
          className={
            classes.columnGridItem + " " + classes.spaceBetweenWidth100
          }
          xs={4}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <span style={{width: "30%"}} className={classes.inputTypeTitle}>START</span>
            <span className={classes.inputTypeTitle}>December 12, 2022</span>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <span style={{width: "30%"}} className={classes.inputTypeTitle}>END</span>
            <span className={classes.inputTypeTitle}>December 12, 2022</span>
          </div>
        </GridItem>

        <GridItem
          className={
            classes.columnGridItem + " " + classes.spaceBetweenWidth100
          }
          xs={4}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "30%"
            }}
          >
            <span style={{width: "30%"}} className={classes.inputTypeTitle}>CUSTOMER</span>
            <Autocomplete
              id="country-select-demo"
              // style={{ width: 180 }}
              // value={contactsString}
              inputProps={{ style: { maxHeight: 20 } }}
              className={classes.customerInput}
              options={customersData.map((c) => c.name)}
              classes={{
                option: classes.option,
              }}
              onChange={(e) => {
                const index = e.target.id.split("-")[4];
                // if (index) {
                //   console.log("indexxxx", index, e.target.id);
                //   if (contacts.indexOf(customerData[index]) === -1) {
                //     setContacts((prevState) => [
                //       ...prevState,
                //       customerData[index],
                //     ]);
                //   }
                // }
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  // value={contactsString}
                  // onChange={(e) => setContactsString(e.target.value)}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <SearchIcon
                          className={classes.calendarIcon}
                          // onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "30%"
            }}
          >
            <span style={{width: "30%"}} className={classes.inputTypeTitle}>SITE</span>
            <Autocomplete
              id="country-select-demo"
              // style={{ width: 180 }}
              // value={contactsString}
              className={classes.customerInput}
              options={sites.map((c) => c.siteAddress)}
              classes={{
                option: classes.option,
              }}
              onChange={(e) => {
                const index = e.target.id.split("-")[4];
                // if (index) {
                //   console.log("indexxxx", index, e.target.id);
                //   if (contacts.indexOf(customerData[index]) === -1) {
                //     setContacts((prevState) => [
                //       ...prevState,
                //       customerData[index],
                //     ]);
                //   }
                // }
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  // value={contactsString}
                  // onChange={(e) => setContactsString(e.target.value)}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <SearchIcon
                          className={classes.calendarIcon}
                          // onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </GridItem>
        {/* <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Primary Phone Number</span>
          <TextField
            value={primaryPhoneNumber}
            onChange={(e) => setPrimaryPhoneNumber(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Secondary Phone Number</span>
          <TextField
            value={secondaryPhoneNumber}
            onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Primary Email Address</span>
          <TextField
            value={primaryEmailAddress.address}
            onChange={(e) =>
              setPrimaryEmailAddress({
                ...primaryEmailAddress,
                address: e.target.value,
              })
            }
            variant="outlined"
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div
              style={{ display: "flex", width: "50%", alignItems: "center" }}
            >
              <Checkbox
                onChange={() =>
                  setPrimaryEmailAddress({
                    ...primaryEmailAddress,
                    sendInvoices: !primaryEmailAddress.sendInvoices,
                  })
                }
                checked={primaryEmailAddress.sendInvoices}
              />
              <span className={classes.sameAsOwnerAddress}>Send Invoices</span>
            </div>

            <div
              style={{ display: "flex", width: "50%", alignItems: "center" }}
            >
              <Checkbox
                onChange={() =>
                  setPrimaryEmailAddress({
                    ...primaryEmailAddress,
                    sendReminders: !primaryEmailAddress.sendReminders,
                  })
                }
                checked={primaryEmailAddress.sendReminders}
              />
              <span className={classes.sameAsOwnerAddress}>Send Reminders</span>
            </div>
          </div>
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Secondary Email Address
          </span>
          <TextField
            value={secondaryEmailAddress.address}
            onChange={(e) =>
              setSecondaryEmailAddress({
                ...secondaryEmailAddress,
                address: e.target.value,
              })
            }
            variant="outlined"
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div
              style={{ display: "flex", width: "50%", alignItems: "center" }}
            >
              <Checkbox
                onChange={() =>
                  setSecondaryEmailAddress({
                    ...secondaryEmailAddress,
                    sendInvoices: !secondaryEmailAddress.sendInvoices,
                  })
                }
                checked={secondaryEmailAddress.sendInvoices}
              />
              <span className={classes.sameAsOwnerAddress}>Send Invoices</span>
            </div>

            <div
              style={{ display: "flex", width: "50%", alignItems: "center" }}
            >
              <Checkbox
                onChange={() =>
                  setSecondaryEmailAddress({
                    ...secondaryEmailAddress,
                    sendReminders: !secondaryEmailAddress.sendReminders,
                  })
                }
                checked={secondaryEmailAddress.sendReminders}
              />
              <span className={classes.sameAsOwnerAddress}>Send Reminders</span>
            </div>
          </div>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Preferred Communication
          </span>
          <Select
            className={classes.filterMenu}
            value={preferredCommunication}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setPreferredCommunication(e.target.value)}
          >
            {communcationsOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Billing Address (required)
          </span>
          <GooglePlacesAutoComplete
            classes={classes}
            value={billingAddress}
            width={"100%"}
            placeholder="Search addresses"
            onChange={(e) => setBillingAddress(e)}
            onSelect={(e) => handleSelect(e, setBillingAddress)}
            margin="0px 0px 20px 0px"
            background="white"
            googleCallbackName="initOne"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Assign Tags (Maximum 3)
          </span>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "50%" }}>
              <Select
                className={classes.filterMenu}
                value={""}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) =>
                  setTags({
                    ...tags,
                    tagsArray: Array.from(
                      new Set([...tags.tagsArray, e.target.value])
                    ),
                  })
                }
              >
                {tagOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                onChange={() =>
                  setTags({ ...tags, visibleToDrivers: !tags.visibleToDrivers })
                }
                checked={tags.visibleToDrivers}
              />
              <span className={classes.sameAsOwnerAddress}>
                Visible To Drivers
              </span>
            </div>
          </div>
          {tags.tagsArray.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {tags.tagsArray.map((t) => {
                return <div>{t}</div>;
              })}
            </div>
          )}
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Industry Type</span>
          <TextField
            value={industryType}
            onChange={(e) => setIndustryType(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Physical Address</span>
          <GooglePlacesAutoComplete
            classes={classes}
            value={physicalAddress}
            width={"100%"}
            placeholder="Search addresses"
            onChange={(e) => setPhysicalAddress(e)}
            onSelect={(e) => handleSelect(e, setPhysicalAddress)}
            margin="0px 0px 20px 0px"
            background="white"
            googleCallbackName="initTwo"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Mailing Address</span>
          <GooglePlacesAutoComplete
            classes={classes}
            value={mailingAddress}
            width={"100%"}
            placeholder="Search addresses"
            onChange={(e) => setMailingAddress(e)}
            onSelect={(e) => handleSelect(e, setMailingAddress)}
            margin="0px 0px 20px 0px"
            background="white"
            googleCallbackName="initThree"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Website</span>
          <TextField
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={6}>
          <span className={classes.inputTypeTitle}>Notes</span>
          <TextareaAutosize
            aria-label="empty textarea"
            className={classes.emailInput}
            onChange={(e) => setNotes(e.target.value)}
            placeholder=""
            style={{ maxWidth: "100%", minHeight: "100px" }}
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={6}>
          <span className={classes.inputTypeTitle}>Company Slack URL</span>
          <TextField
            value={slackAddress}
            onChange={(e) => setSlackAddress(e.target.value)}
            variant="outlined"
          />
        </GridItem> */}
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log('stateee', state)
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    sites: state.sites.sites
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(JobNameCard));
