const initState = {
  swaps: [],
};

export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_SWAPS":
      return {
        ...state,
        swaps: [...action.swaps],
      };
    default:
      return state;
  }
}
