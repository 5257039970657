import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "views/Dashboard/components/pagination.css";
// core components
import { MenuItem, Select } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconWhite.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIconBlack.svg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

const headers = ["Name", "Actions"];

const pageArr = ["2", "All"];

function PriceBooksTable(props) {
  const {
    classes,
    reminders,
    history,
    justCustomerInfo,
    priceBooksData,
  } = props;

  const itemsPerPage = 2;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("2");

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  useEffect(() => {
    priceBooksData.slice(index, endIndex);
  }, [index]);


  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    if (pageDropdown == "2") {
      return priceBooksData
        .slice(
          pageNumber * itemsPerPage,
          pageNumber * itemsPerPage + itemsPerPage
        )
        .map((d, index) => {
          return (
            <tr className={classes.tableRow} key={d.name}>
              <td
                className={classes.tableCell}
                style={{
                  background: "#FFFFFF",
                  color: "#0071C5",
                  textDecoration: "underline",
                }}
              >
                {d.name}
              </td>
              <td className={classes.tableCell}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      style={{ background: "#FFC720" }}
                      className={classes.actionBtn}
                    >
                      <EyeIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#323232" }}
                    >
                      <EditIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Delete"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#FEDA1B" }}
                    >
                      <DenyIcon />
                    </Button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          );
        });
    }
    else {
      return priceBooksData.map((d, index) => {
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td
              className={classes.tableCell}
              style={{
                background: "#FFFFFF",
                color: "#0071C5",
                textDecoration: "underline",
              }}
            >
              {d.name}
            </td>
            <td className={classes.tableCell}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#323232" }}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Delete"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#FEDA1B" }}
                  >
                    <DenyIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div
          style={
            pageDropdown != 2 && priceBooksData.length > 2
              ? { height: "270px", width: "100%", overflowY: "scroll" }
              : null
          }
        >
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
        {pageDropdown == "2" ? (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={
                      {
                        // className: classes.selectMenu,
                      }
                    }
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginTop: "5px" }}>
              <h5
                className={classes.indexes}              >
                {index + 1} - {endIndex} of{" "}
                {Math.ceil(priceBooksData.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(priceBooksData.length / itemsPerPage)}
                nextLabel={
                  <ArrowRight
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                previousLabel={
                  <ArrowLeft
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={
                      {
                        // className: classes.selectMenu,
                      }
                    }
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(PriceBooksTable));
