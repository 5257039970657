import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as CheckIcon } from "assets/img/checkIconWhite.svg";

const headers = [
  "Quote Details",
  "Pick Up",
  "Dropoff",
  "Site Address",
  "Inventory",
  "Communicate Via",
  "Rate",
  "Actions",
];

function ListViewTable(props) {
  const { classes, reservations, history, pageDropdown, pageNumber, itemsPerPage, user } = props;
  console.log("reservations", reservations);
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key} style={{ fontSize: "14px" }}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.entries(e).forEach(([key, value]) => {
        if (value) {
          let str = "";
          switch (key) {
            case "tenYard":
              str = "10 Yard Container";
              break;
            case "fifteenYard":
              str = "15 Yard Container";
              break;
            case "twentyYard":
              str = "20 Yard Container";
              break;
            case "thirtyYard":
              str = "30 Yard Container";
              break;
            case "metalOrConcrete":
              str = "Metal or Concrete";
              break;
            case "standardThirty":
              str = "Standard 30 Yard";
              break;
          }
          returnStr = returnStr + str + " ";
        }
      });
      return returnStr;
    } else {
      return e;
    }
  };

  const renderTableData = () => {
    if (pageDropdown == "4") {
      return reservations
        .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
        .map((d, index) => {
          return (
            <tr className={classes.tableRow} key={d.name}>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell2}>
                {user.role && user.role === "Super Admin" ?
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      <span
                        onClick={() =>
                          history.push({
                            pathname: `/admin/rentals/${d.reservation.reservationId}`,
                            state: { reservation: d },
                          })
                        }
                        className={classes.nameAndCompanyText}
                      >
                        {" "}
                        {d.reservation.reservationId}
                      </span>{" "}
                      |
                      <span
                        onClick={() => {
                          history.push({
                            pathname: `/admin/customers/${d.customerId}`,
                            // state: { customer: d },
                          });
                        }}
                        className={classes.nameAndCompanyText}
                      >
                        {d.name}
                      </span>
                    </div>
                    <div style={{ fontSize: "16px" }}>
                      Driver: <span>{d.reservation.driver}</span>{" "}
                    </div>
                  </div>
                  :
                  <span
                    onClick={() =>
                      history.push({
                        pathname: `/admin/rentals/${d.reservation.reservationId}`,
                        state: { reservation: d },
                      })
                    }
                    className={classes.nameAndCompanyText}
                  >
                    {" "}
                    {d.reservation.reservationId}
                  </span>
                }
              </td>
              <td className={classes.tableCell2} style={{ background: "#FFFFFF" }}>
                {d.reservation.startDate}
              </td>
              <td className={classes.tableCell2}>{d.reservation.endDate}</td>
              <td className={classes.tableCell2}>{d.reservation.siteAddress}</td>
              <td className={classes.tableCell2}>
                {renderEquipment(d.reservation.equiptment)}
                {/* ({d.reservation.equiptment.length}) */}
              </td>
              <td className={classes.tableCell2}>
                {d.reservation.recurring ? "Yes" : "No"}
              </td>
              <td className={classes.tableCell2}>
                {user.role && user.role === "Super Admin" ?
                  <div
                    style={
                      d.reservation.status === "Scheduled"
                        ? { background: "#E01E5A", fontWeight: 600, textAlign: "center", justifyContent: "center" }
                        : d.reservation.status === "Completed"
                          ? { background: "#2EB67D", fontWeight: 600, textAlign: "center", justifyContent: "center" }
                          : d.reservation.status === "In Progress"
                            ? { background: "#E0AC12", color: "#323232", fontWeight: 600, textAlign: "center", justifyContent: "center" }
                            : { color: "#323232", background: "#FEDA1B !important", display: "block", fontWeight: 600, textAlign: "center" }
                    }
                    className={classes.commentDiv}
                  >
                    {d.reservation.status}
                  </div>
                  :
                  <div
                    style={
                      d.reservation.status === "Scheduled"
                        ? { background: "#E01E5A", fontWeight: 600, textAlign: "center", justifyContent: "center" }
                        : d.reservation.status === "Completed"
                          ? { background: "#2EB67D", fontWeight: 600, textAlign: "center", justifyContent: "center" }
                          : { background: "#FFC821", color: "#323232", fontWeight: 600, textAlign: "center", justifyContent: "center" }
                    }
                    className={classes.commentDiv}
                  >
                    {d.reservation.status === "Approved" ? "In Progress" : d.reservation.status}
                  </div>
                }
              </td>
              <td className={classes.tableCell2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Tooltip
                    id="tooltip-top"
                    title="View"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      style={{ background: "#FFC720" }}
                      className={classes.actionBtn}
                    >
                      <EyeIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      className={classes.actionBtn}
                      style={{ background: "#323232" }}
                    >
                      <CheckIcon />
                    </Button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          );
        });
    }
    else {
      return reservations.map((d, index) => {
        console.log('dddddd', d)
        return (
          <tr className={classes.tableRow} key={d.name}>
            <td style={{ background: "#FFFFFF" }} className={classes.tableCell2}>
              {user.role && user.role === "Super Admin" ?
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <span
                      onClick={() =>
                        history.push({
                          pathname: `/admin/rentals/${d.reservation.reservationId}`,
                          state: { reservation: d },
                        })
                      }
                      className={classes.nameAndCompanyText}
                    >
                      {" "}
                      {d.reservation.reservationId}
                    </span>{" "}
                    |
                    <span
                      onClick={() => {
                        history.push({
                          pathname: `/admin/customers/${d.customerId}`,
                          // state: { customer: d },
                        });
                      }}
                      className={classes.nameAndCompanyText}
                    >
                      {d.name}
                    </span>
                  </div>
                  <div style={{ fontSize: "16px" }}>
                    Driver: <span>{d.reservation.driver}</span>{" "}
                  </div>
                </div>
                :
                <span
                  onClick={() =>
                    history.push({
                      pathname: `/admin/rentals/${d.reservation.reservationId}`,
                      state: { reservation: d },
                    })
                  }
                  className={classes.nameAndCompanyText}
                >
                  {" "}
                  {d.reservation.reservationId}
                </span>
              }
            </td>
            <td className={classes.tableCell2} style={{ background: "#FFFFFF" }}>
              {d.reservation.startDate}
            </td>
            <td className={classes.tableCell2}>{d.reservation.endDate}</td>
            <td className={classes.tableCell2}>{d.reservation.siteAddress}</td>
            <td className={classes.tableCell2}>{renderEquipment(d.reservation.equiptment)}</td>
            <td className={classes.tableCell2}>
              {d.reservation.recurring ? "Yes" : "No"}
            </td>
            <td className={classes.tableCell2}>
              {user.role && user.role === "Super Admin" ?
                <div
                  style={
                    d.reservation.status === "Scheduled"
                      ? { background: "#E01E5A", fontWeight: 600, textAlign: "center", justifyContent: "center" }
                      : d.reservation.status === "Completed"
                        ? { background: "#2EB67D", fontWeight: 600, textAlign: "center", justifyContent: "center" }
                        : d.reservation.status === "In Progress"
                          ? { background: "#E0AC12", color: "#323232", fontWeight: 600, textAlign: "center", justifyContent: "center" }
                          : { color: "#323232", background: "#FEDA1B !important", display: "block", fontWeight: 600, textAlign: "center", justifyContent: "center" }
                  }
                  className={classes.commentDiv}
                >
                  {d.reservation.status}
                </div>
                :
                <div
                  style={
                    d.reservation.status === "Scheduled"
                      ? { background: "#E01E5A", fontWeight: 600, textAlign: "center" }
                      : d.reservation.status === "Completed"
                        ? { background: "#2EB67D", fontWeight: 600, textAlign: "center" }
                        : { background: "#FFC821", color: "#323232", fontWeight: 600, textAlign: "center" }
                  }
                  className={classes.commentDiv}
                >
                  {d.reservation.status === "Approved" ? "In Progress" : d.reservation.status}
                </div>
              }
            </td>
            <td className={classes.tableCell2}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Tooltip
                  id="tooltip-top"
                  title="View"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    style={{ background: "#FFC720" }}
                    className={classes.actionBtn}
                  >
                    <EyeIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  id="tooltip-top"
                  title="Edit"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    className={classes.actionBtn}
                    style={{ background: "#323232", }}
                  >
                    <CheckIcon />
                  </Button>
                </Tooltip>
              </div>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <GridContainer >
      <GridItem xs={12}>
        <div style={pageDropdown != 4 && reservations.length > 4 ? { height: "550px", width: "100%", overflowY: "scroll" } : null}>
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    user: state.auth.user,

  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(ListViewTable));
