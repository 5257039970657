import React from "react";
import Card from "components/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import PopupMenu from "components/Navbars/PopupMenu";

import styles from "assets/jss/material-dashboard-pro-react/components/explorerStyle";

const useStyles = makeStyles(styles);

export default function ExplorerCard(props) {

    const classes = useStyles();

    const { CardFields, CardField, editRoute, viewRoute, order, children, index } = props;

    return (
        <Card key={index} onDoubleClick={() => props.openChild("View Record",viewRoute)}>
            <CardHeader icon

            >
                <CardIcon color="info">
                    <PopupMenu items={{ edit: () => props.openChild("Edit Record", editRoute), showDetail: () => props.openChild("View Record", viewRoute) }} />
                </CardIcon>
                <CardField
                    classes={{ value: classes.cardTextTitle }}
                    label=""
                    name="fullName"
                    index={index}
                />
            </CardHeader>
            <CardBody>
                <CardFields index={index} order={order} />
                { children }
            </CardBody>
        </Card>
    );
}