import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
// core components
import { MenuItem, Select } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Button from "components/CustomButtons/Button.js";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

const pageArr = ["3", "All"];
function InventoryAvailabilityTable(props) {
  const {
    classes,
    defaultHeader,
    handleNextFourteenDays,
    handlePreviousFourteenDays,
    inventoryData,
  } = props;

  const itemsPerPage = 3;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("3")

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)

  };

  useEffect(() => {
    inventoryData.slice(index, endIndex)
  }, [index])

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          // height: "45px !important",
          borderBottom: "1px solid #8E8E8E",
        }}
      >
        {defaultHeader.map((key, index) => {
          return (
            <th
              style={
                index > 1
                  ? {
                      border: "0.5px solid #8E8E8E",
                      background: "#D9D9D9",
                      width: "140px",
                    }
                  : {}
              }
              key={key}
            >
              {index === 2 ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <KeyboardArrowLeft
                    onClick={handlePreviousFourteenDays}
                    className={classes.arrowIcon}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ width: "100%" }}>{key.split(" ")[0]}</span>
                    <span>{key.split(" ")[1]}</span>
                  </div>
                </div>
              ) : index === 15 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ width: "100%" }}>{key.split(" ")[0]}</span>
                    <span>{key.split(" ")[1]}</span>
                  </div>
                  <KeyboardArrowRight
                    onClick={() => handleNextFourteenDays(defaultHeader)}
                    className={classes.arrowIcon}
                  />
                </div>
              ) : index === 0 || index === 1 ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ width: "100%" }}>{key}</span>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ width: "100%" }}>{key.split(" ")[0]}</span>
                  <span>{key.split(" ")[1]}</span>
                </div>
              )}
            </th>
          );
        })}
      </tr>
    );
  };


  const getTableCell = (data, headerIndex) => {
    const headerDate = new Date(defaultHeader[headerIndex]);
    const year = headerDate.getFullYear();
    const month = headerDate.toLocaleString("default", { month: "short" });
    if (data[year] && data[year][month][headerDate] !== undefined) {
      return data[year][month][headerDate];
    } else {
      return "N/A";
    }
  };

  const renderTableData = () => {
    if(pageDropdown == "3"){
      return (
        defaultHeader.length > 0 &&
        inventoryData &&
        inventoryData
        .slice(pageNumber * itemsPerPage, (pageNumber * itemsPerPage) + itemsPerPage)
  
        .map((d, index) => {
          const tableCell3 = getTableCell(d, 2);
          const tableCell4 = getTableCell(d, 3);
          const tableCell5 = getTableCell(d, 4);
          const tableCell6 = getTableCell(d, 5);
          const tableCell7 = getTableCell(d, 6);
          const tableCell8 = getTableCell(d, 7);
          const tableCell9 = getTableCell(d, 8);
          const tableCell10 = getTableCell(d, 9);
          const tableCell11 = getTableCell(d, 10);
  
          const tableCell12 = getTableCell(d, 11);
          const tableCell13 = getTableCell(d, 12);
          const tableCell14 = getTableCell(d, 13);
          const tableCell15 = getTableCell(d, 14);
          const tableCell16 = getTableCell(d, 15);
          return (
            <tr key={d.product}>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                {d.inventoryItem}
              </td>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                {d.totalQuantity}
              </td>
              <td
                className={
                  tableCell3 > 0
                    ? classes.yellowTableCell
                    : tableCell3 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell3}
              </td>
              <td
                className={
                  tableCell4 > 0
                    ? classes.yellowTableCell
                    : tableCell4 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell4}
              </td>
              <td
                className={
                  tableCell5 > 0
                    ? classes.yellowTableCell
                    : tableCell5 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell5}
              </td>
              <td
                className={
                  tableCell6 > 0
                    ? classes.yellowTableCell
                    : tableCell6 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell6}
              </td>
              <td
                className={
                  tableCell7 > 0
                    ? classes.yellowTableCell
                    : tableCell7 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell7}
              </td>
              <td
                className={
                  tableCell8 > 0
                    ? classes.yellowTableCell
                    : tableCell8 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell8}
              </td>
              <td
                className={
                  tableCell9 > 0
                    ? classes.yellowTableCell
                    : tableCell9 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell9}
              </td>
              <td
                className={
                  tableCell10 > 0
                    ? classes.yellowTableCell
                    : tableCell10 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell10}
              </td>
  
              <td
                className={
                  tableCell11 > 0
                    ? classes.yellowTableCell
                    : tableCell11 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell11}
              </td>
  
              <td
                className={
                  tableCell12 > 0
                    ? classes.yellowTableCell
                    : tableCell12 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell12}
              </td>
  
              <td
                className={
                  tableCell13 > 0
                    ? classes.yellowTableCell
                    : tableCell13 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell13}
              </td>
              <td
                className={
                  tableCell14 > 0
                    ? classes.yellowTableCell
                    : tableCell14 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell14}
              </td>
              <td
                className={
                  tableCell15 > 0
                    ? classes.yellowTableCell
                    : tableCell15 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell15}
              </td>
              <td
                className={
                  tableCell16 > 0
                    ? classes.yellowTableCell
                    : tableCell16 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell16}
              </td>
            </tr>
          );
        })
      );
    }
    else{
      return (
        defaultHeader.length > 0 &&
        inventoryData &&
        inventoryData
        .map((d, index) => {
          const tableCell3 = getTableCell(d, 2);
          const tableCell4 = getTableCell(d, 3);
          const tableCell5 = getTableCell(d, 4);
          const tableCell6 = getTableCell(d, 5);
          const tableCell7 = getTableCell(d, 6);
          const tableCell8 = getTableCell(d, 7);
          const tableCell9 = getTableCell(d, 8);
          const tableCell10 = getTableCell(d, 9);
          const tableCell11 = getTableCell(d, 10);
  
          const tableCell12 = getTableCell(d, 11);
          const tableCell13 = getTableCell(d, 12);
          const tableCell14 = getTableCell(d, 13);
          const tableCell15 = getTableCell(d, 14);
          const tableCell16 = getTableCell(d, 15);
          return (
            <tr key={d.product}>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                {d.inventoryItem}
              </td>
              <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
                {d.totalQuantity}
              </td>
              <td
                className={
                  tableCell3 > 0
                    ? classes.yellowTableCell
                    : tableCell3 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell3}
              </td>
              <td
                className={
                  tableCell4 > 0
                    ? classes.yellowTableCell
                    : tableCell4 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell4}
              </td>
              <td
                className={
                  tableCell5 > 0
                    ? classes.yellowTableCell
                    : tableCell5 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell5}
              </td>
              <td
                className={
                  tableCell6 > 0
                    ? classes.yellowTableCell
                    : tableCell6 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell6}
              </td>
              <td
                className={
                  tableCell7 > 0
                    ? classes.yellowTableCell
                    : tableCell7 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell7}
              </td>
              <td
                className={
                  tableCell8 > 0
                    ? classes.yellowTableCell
                    : tableCell8 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell8}
              </td>
              <td
                className={
                  tableCell9 > 0
                    ? classes.yellowTableCell
                    : tableCell9 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell9}
              </td>
              <td
                className={
                  tableCell10 > 0
                    ? classes.yellowTableCell
                    : tableCell10 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell10}
              </td>
  
              <td
                className={
                  tableCell11 > 0
                    ? classes.yellowTableCell
                    : tableCell11 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell11}
              </td>
  
              <td
                className={
                  tableCell12 > 0
                    ? classes.yellowTableCell
                    : tableCell12 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell12}
              </td>
  
              <td
                className={
                  tableCell13 > 0
                    ? classes.yellowTableCell
                    : tableCell13 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell13}
              </td>
              <td
                className={
                  tableCell14 > 0
                    ? classes.yellowTableCell
                    : tableCell14 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell14}
              </td>
              <td
                className={
                  tableCell15 > 0
                    ? classes.yellowTableCell
                    : tableCell15 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell15}
              </td>
              <td
                className={
                  tableCell16 > 0
                    ? classes.yellowTableCell
                    : tableCell16 === 0
                    ? classes.whiteTableCell
                    : classes.redTableCell
                }
              >
                {tableCell16}
              </td>
            </tr>
          );
        })
      );
    }
  };


  return (
    <div>
    <GridContainer className={classes.searchGridContainer2}>
      <GridItem xs={12}>
        <table className={classes.inventoryTable}>
          <tbody >
            {renderTableHeader()}
            {renderTableData()}
          </tbody>
        </table>
      </GridItem>
    </GridContainer>
    {pageDropdown == "3" ?
          <GridContainer>
            <GridItem xs={6} >
              <p className={classes.view}>View
                <span>
                  <Select
                    style={{ width: "20%", marginLeft: "10px", marginTop: "10px", textAlign: "center"}}
                    disableUnderline 
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor
                    }}

                    onChange={(e) => {
                      setPageDrowdown(e.target.value)
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginTop: "10px"}}>
            <h5 className={classes.indexes}>{index + 1} - {endIndex} of 
            {inventoryData.length < itemsPerPage? " " + inventoryData.length :
             " " + Math.ceil(inventoryData.length / itemsPerPage)}
             </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(inventoryData.length / itemsPerPage)}
                nextLabel={<ArrowRight style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                previousLabel={<ArrowLeft style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
          :
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>View
                <span>
                  <Select
                    style={{ width: "10%", marginLeft: "10px", marginTop: "10px", textAlign: "center" }}
                    disableUnderline 
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value)
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(InventoryAvailabilityTable);
