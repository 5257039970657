import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Datetime from "react-datetime";
import moment from "moment";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

function QuoteJobCard(props) {
  const {
    classes,
    quote,
    inventoryData,
    productType,
    setProductType,
    quantity,
    setQuantity,
    rate,
    setRate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    duration,
    setNotes,
    errors,
  } = props;

  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Select Product Type{" "}
            {errors.productType && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <Select
            className={classes.filterMenu}
            value={productType}
            variant="outlined"
            onChange={(e) => setProductType(e.target.value)}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
          >
            {inventoryData.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o.inventoryItem}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Quantity{" "}
            {errors.quantity && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <TextField
            value={quantity}
            type="number"
            onChange={(e) => {
              setQuantity(e.target.value);
              if (productType) {
                setRate(productType.cost * e.target.value);
              }
            }}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Rate{" "}
            {errors.rate && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <TextField
            value={rate}
            type="number"
            onChange={(e) => setRate(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            Start Date{" "}
            {errors.startDate && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <Datetime
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
            }}
            selected={startDate}
            isValidDate={disablePastDt}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              setStartDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  className={classes.datetime}
                  placeholder="From"
                  value={startDate}
                  variant="outlined"
                  style={{
                    color: "#323232 !important",
                    height: "100%",
                    width: "100%",
                  }}
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            End Date{" "}
            {errors.endDate && (
              <span style={{ color: "red", marginLeft: "5px" }}>Required*</span>
            )}
          </span>
          <Datetime
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
            }}
            selected={endDate}
            dateFormat="MM/DD/YY"
            isValidDate={disablePastDt}
            onChange={(date, e) => {
              setEndDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  className={classes.datetime}
                  placeholder="To"
                  value={endDate}
                  variant="outlined"
                  style={{
                    color: "#323232 !important",
                    height: "100%",
                    width: "100%",
                  }}
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Duration</span>
          <span className={classes.inputTypeTitle}>
            {duration && duration + " days"}
          </span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={12}>
          <span className={classes.inputTypeTitle}>Other Information</span>
          <TextareaAutosize
            aria-label="empty textarea"
            className={classes.emailInput}
            onChange={(e) => setNotes(e.target.value)}
            placeholder=""
            style={{ maxWidth: "100%", minHeight: "100px" }}
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(QuoteJobCard));
