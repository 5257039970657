import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const headerStyle = (theme) => ({
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    // paddingTop: "10px",
    zIndex: "1029",
    color: "#323232",
    border: "0",
    borderRadius: "3px",
    // padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    borderBottom: "1px solid #8E8E8E",
  },
  container: {
    minHeight: "50px",
    paddingLeft: "15px",
    paddingRight: "0px !important",
    [theme.breakpoints.down("sm")]: {
      height: "70px !important",
    },
  },
  sixtyPixels: {
    height: "56px !important",
    width: "100% !important",
    margin: "0px",
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  hiddenSmall: {
    width: "100%",
    height: "56px !important",
  },
  sidebarMinimize: {
    float: "left",
    padding: "0 0 0 15px",
    display: "block",
    color: grayColor[6],
  },
  sidebarMinimizeRTL: {
    padding: "0 15px 0 0 !important",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px",
  },
  notificationsCount: {
    background: "#F5EB29",
    fontSize: "14px",
    marginLeft: "5px",
    padding: "5px",
    borderRadius: "300px",
    color: "#323232",
    fontWeight: 700,
  },
  headerLinksText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    textTransform: "capitalize",
    margin: "0px 3%",
    fontFamily: "Archivo",
    color: "#323232"
  },
  GridContainerClass: {
    height: "100%",
  },
  userInfoDropdownGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingRight: "42px !important",
    "&:hover": {
      cursor: "pointer",
      background: '#FFC720',
    },
  },
  userInfoDropdownMenuGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingRight: "42px !important",
    "&:hover": {
      cursor: "pointer",
      // borderBottom: '5px solid #FFC720',
    },
  },
  initialsDiv: {
    background: "#D9D9D9",
    borderRadius: "14px",
    fontWeight: 400,
    fontSize: "19px",
    display: "flex",
    alignItems: "center",
    padding: "12px 10px",
  },
  nameAndCompanyDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  name: {
    fontWeight: 600,
    fontSize: "15px",
    color: "#323232",
  },
  company: {
    fontWeight: 400,
    fontSize: "13px",
    color: "#323232",
  },
  routesGridContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    padding: " 0px 50px 0px 0px"
  },
  routesGridContainerCustomer: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    // padding: " 0px 64px 0px 0px"
  },
  routesGridItem: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
    borderBottom: "5px solid transparent",
    justifyContent: "center"
  },
  routesGridItemCustomer: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
    borderBottom: "5px solid transparent",
    justifyContent: "center",
    padding: "0px 50px !important"
  },
  routesGridItemUnderline: {
    display: "flex",
    alignItems: "center",
    borderBottom: "5px solid #FFC720",
    padding: "0px !important",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  routesGridItemUnderlineCustomer: {
    display: "flex",
    alignItems: "center",
    borderBottom: "5px solid #FFC720",
    padding: "0px 50px !important",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  masterIconGridItem: {
    padding: "0px 0px 0px 20px !important",
  },
  iconsGridContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    margin: "0px"
  },
  iconGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px !important",
    boxShadow: "-4px 0px 4px rgba(0, 0, 0, 0.18)",
  },
  iconGridItemTrue: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px !important",
    boxShadow: "-4px 0px 4px rgba(0, 0, 0, 0.18)",
    borderBottom: "5px solid #FFC720",

  },
  searchIconGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px !important",
    boxShadow: "-4px 0px 4px rgba(0, 0, 0, 0.18)",
    background: "#F1C84E",
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchDropDownGridItem: {
    height: "200px",
    background: "#D9D9D9",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  selectMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "50%",
    height: "50px",
    marginRight: "15px",
  },
  ascendingMenu: {
    background: "#F1C84E",
    borderRadius: "7px",
    width: "10%",
    height: "50px",
    marginRight: "15px",
  },
  filterMenu: {
    background: "white",
    borderRadius: "7px",
    height: "50px",
    marginRight: "15px",
    fontSize: "16px",
    width: "202px",
    "&:hover": {
      cursor: "pointer",
      background: "#FFC720"
    },

  },
  filtersGridItem: {
    display: "flex",
    alignItems: "center",
  },
  searchCategoryTitle: {
    marginBottom: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "17px",
    color: "#323232",
  },
  searchBar: {
    background: "white",
    borderRadius: "5px",
    height: "40px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    width: "100%",
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "40px !important",
  },
  searchButton: {
    height: "40px",
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "10px 14px !important",
    color: "#323232",
    width: "120px",
    "&:hover": {
      background: "#FFC720",
    },
  },
  flexGridItem: {
    display: "flex",
  },
  calculatorInput: {
    background: "white",
    width: "90%",
  },
  calculatorOutput: {
    fontSize: "17px"
  }
});

export default headerStyle;
