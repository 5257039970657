const initState = {
  quotes: [],
};

export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_QUOTES":
      return {
        ...state,
        quotes: [...action.quotes],
      };
    default:
      return state;
  }
}
