const scheduleStyle = (theme) => ({
  scheduleHeaderGridItem: {
    padding: "0px !important",
    display: "flex",
  },
  dndContext: {
    padding: "0px !important",
    display: "flex",
  },
  loaderGridItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px",
  },
  upcomingPaymentsGridItem: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "0px !important",
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    background: "#F1C84E",
    borderRadius: "7px",
    color: "#323232",
    padding: "8px",
  },
  calendarIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  filterMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    height: "50px",
    color: "#323232",
    padding: "10px",
  },
  selectText: {
    fontSize: "22px",
    display: "flex",
    justifyContent: "center",
  },
  remindersGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  hideNavGridItem: {
    display: "flex",
    justifyContent: "end",
    height: "50px",
    marginBottom: "30px",
  },
  hideNavDiv: {
    background: "#929292",
    borderRadius: "14px",
    display: "flex",
    alignItems: "center",
    width: "60%",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  viewSelectionDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px solid #323232",
    borderRadius: "7px 0px 0px 7px",
  },
  destinations: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "12px",
    textTransform: "uppercase",
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectedViewSelection: {
    padding: "5px",
    border: "1px solid #323232",
    background: "#323232",
    fontFamily: "Archivo",
    color: "white",
    fontStyle: "normal",
    width: "25%",
    fontWeight: 500,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  unselectedViewSelection: {
    padding: "5px",
    border: "1px solid #323232",
    color: "#323232",
    background: "white",
    fontFamily: "Archivo",
    fontStyle: "normal",
    width: "25%",
    fontWeight: 500,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  siteGridItem: {
    display: "flex",
    marginTop: "40px",
  },
  gridItemWithoutArrow: {
    display: "flex",
    alignItems: "center",
  },
  gridItemWithArrow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  siteCard: {
    display: "flex",
    background: "white",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    width: "100%",
    padding: "5px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  siteDiv: {
    display: "flex",
    flexDirection: "column",
  },
  siteName: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontSize: "80%",
    fontWeight: 700,
  },
  cityName: {
    fontSize: "80%",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
  searchBar: {
    background: "white",
    width: "40%",
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    color: "#323232",
    padding: "9px",
    "&:hover": {
      background: "#FFC720",
    },
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height: "45px",
  },
  headerButtons: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "1%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "30%",
  },
  scheduleGridContainer: {
    marginTop: "30px",
    minHeight: "800px",
    height: "calc(100% + 200px) !important",
    background: "#929292",
  },
  scheduleWeeklyGridContainer: {
    marginTop: "30px",
    // minHeight: "800px",
    // height: "calc(100% + 200px) !important",
    background: "#929292",
  },
  scheduleMapGridContainer: {
    marginTop: "30px",
  },
  listScheduleGridContainer: {
    marginTop: "30px",
    minHeight: "800px",
    height: "calc(100% + 200px) !important",
    background: "white",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    padding: "35px",
  },
  scheduleColumn: {
    display: "flex",
    flexDirection: "column",
    border: "0.5px solid #929292",
    minHeight: "100%",
  },
  scheduleRow: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },
  scheduleColumnHeader: {
    height: "60px",
    background: "#FFC821",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  scheduleHeaderText: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#323232",
  },
  reservationNameDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    marginBottom: "10px",
  },
  name: {
    width: "50%",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "16px",
    color: "#323232",
    textAlign: "left",
  },
  jobType: {
    width: "50%",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "16px",
    color: "#323232",
    textAlign: "left",
  },
  nameAndCommentsDiv: {
    display: "flex",
    width: "50%",
    justifyContent: "space-evenly",
  },
  commentColorDiv: {
    height: "10px",
    width: "10px",
    borderRadius: "100px",
  },
  reservationId: {
    width: "100%",
    marginBottom: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "16px",
    color: "#0071C5",
    zIndex: 9999,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  equiptment: {
    width: "100%",
    marginBottom: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "16px",
  },
  jobIdText: {
    width: "100%",
    marginBottom: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "16px",
    color: "#0071C5",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  reservationInfo: {
    width: "100%",
    marginBottom: "10px",
    textAlign: "left",
  },
  listItemGridContainer: {
    maxWidth: "100%",
  },
  noPadding: {
    padding: "0px !important",
    marginBottom: "10px",
  },
  frequentButtonDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  scheduleSwapButtons: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "13px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer"
    }
  },
  btnDescriptor: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "17px",
    color: "#323232",
    marginLeft: "10px",
  },
  anchoredFabLoggedIn: {
    zIndex: 9999,
    color: "#323232",
    right: "20px",
    width: "15%",
    bottom: "20px",
    display: "flex",
    position: "fixed",
    fontSize: "22px",
    alignItems: "center",
    background: "#FFC821",
    minHeight: "83px",
    borderRadius: "56px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    "&:hover": {
      background: "rgb(254, 218, 27)",
    },
    [theme.breakpoints.down("sm")]: {
      right: "20px",
      width: "50%",
      bottom: "80px",
      fontSize: "18px",
      maxHeight: "40px",
      borderRadius: "56px",
    },
  },
  assignColors: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "14px",
    textDecoration: "underline",
    color: "#0071C5",
    margin: "0px auto",
    padding: "20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  hoverSort: {
    "&:active": {
      cursor: "grabbing",
    },
    "&:hover": {
      cursor: "grab"
    },
  }
});

export default scheduleStyle;
