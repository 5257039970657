/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";
import Datetime from "react-datetime";
import moment from "moment";

const jobTypes = ["Pick Up", "Delivery"];
const productType = ["Pick Up", "Delivery"];

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

function ScheduleJobFor(props) {
  const {
    classes,
    inventoryData,
    jobType,
    setJobType,
    productType,
    setProductType,
    quantity,
    setQuantity,
    selectedDriver,
    setSelectedDriver,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    emergencyJob,
    setEmergencyJob,
    setRecurringJob,
    recurringJob,
  } = props;

  const handleSelect = (address, setFunction) => {
    setFunction(address);
  };

  return (
    <Card style={{ marginTop: "0px" }} className={classes.card}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={12}>
          <span className={classes.subTitle}>Schedule Job for..</span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Job Type</span>
          <Select
            className={classes.filterMenu}
            value={""}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
          >
            {jobTypes.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Select Product Type</span>
          <Select
            className={classes.filterMenu}
            value={productType}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
          >
            {inventoryData.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o.inventoryItem}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o.inventoryItem}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Quantity</span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              value={quantity}
              className={classes.qtyTextField}
              type="number"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => setQuantity(e.target.value)}
              variant="outlined"
            />
          </div>
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Driver</span>
          <Select
            className={classes.filterMenu}
            value={selectedDriver}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
          >
            {inventoryData.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o.inventoryItem}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o.inventoryItem}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Start Date</span>
          <Datetime
            input
            isValidDate={disablePastDt}
            // className={classes.textFieldRoot}
            type="text"
            inputProps={{
              className: classes.datetime,
              placeholder: "Choose start date",
            }}
            style={{
              color: "black !important",
              height: "100%",
              width: "100%",
            }}
            selected={startDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              if (typeof date === "string") {
              } else {
                setStartDate(date.format("MM/DD/YY"));
              }
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Expected End Date</span>
          <Datetime
            input
            isValidDate={disablePastDt}
            // className={classes.textFieldRoot}
            type="text"
            inputProps={{
              className: classes.datetime,
              placeholder: "Choose end date",
            }}
            style={{
              height: "100%",
              width: "100%",
            }}
            selected={endDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              if (typeof date === "string") {
              } else {
                setEndDate(date.format("MM/DD/YY"));
              }
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
          />
        </GridItem>
        <GridItem className={classes.rowGridItem} xs={12}>
          <div style={{ display: "flex", width: "15%", alignItems: "center" }}>
            <Checkbox
              style={{
                color: "#FFC720",
              }}
              onChange={() => setEmergencyJob(!emergencyJob)}
              checked={emergencyJob}
            />
            <span className={classes.sameAsOwnerAddress}>Emergency Job</span>
          </div>
          <div style={{ display: "flex", width: "15%", alignItems: "center" }}>
            <Checkbox
              style={{
                color: "#FFC720",
              }}
              onChange={() => setRecurringJob(!recurringJob)}
              checked={recurringJob}
            />
            <span className={classes.sameAsOwnerAddress}>Recurring Job</span>
          </div>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log("stateee", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    sites: state.sites.sites,
    inventoryData: state.inventory.inventory,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(ScheduleJobFor));
