import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

import ProfileCustomTabs from "./ProfileCustomTabs";
import BillingInformation from "./BillingInformation";

function BillingCard(props) {
  const { classes, history, userInfo, secret, setSecret, customerId, setCustomerId } = props;

  return (
    <Card >
      <GridContainer>
        <GridItem xs={12}>
          <ProfileCustomTabs
            classes={classes}
            tabs={[
              {
                tabName: "Billing Information",
                tabContent: (
                  <BillingInformation
                    secret={secret}
                    setSecret={setSecret}
                    history={history}
                    userInfo={userInfo}
                    customerId={customerId}
                    setCustomerId={setCustomerId}
                  />
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(BillingCard));
