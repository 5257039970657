/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { db, firebase, storage } from "firebase/client";

import PaymentForm from "./PaymentForm";
import "./App.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

export default function StripeContainer(props) {
  const { setSecret, secret, classes, userInfo, customerId, setCustomerId } = props;

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: secret,
    appearance,
  };

  return (
    <div style={{ width: "600px", padding: "20px" }}>
      {secret && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentForm
            classes={classes}
            secret={secret}
            setBillingModal={props.setBillingModal}
            setSnackBarContent={props.setSnackBarContent}
            userInfo={props.userInfo}
            customerId={customerId}
          />
        </Elements>
      )}
    </div>
  );
}
