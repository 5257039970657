import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { CSVLink } from "react-csv";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import "views/Rentals/components/RentalsCustomTabs/List/calendar.css";

import { Menu } from "@material-ui/core";


const selectOptions = [
  "Invoice Date",
  "Due Date",
  "Invoice Number",
  "Amount",
  "Remaining",
  "Site Address",
  "Site Name"
];
const directionOptions = ["Asc", "Desc"];
const filterOptions = [
  "All",
  // "Scheduled",
  // "In Progress",
  // "Unassigned",
  // "Approved",
];

const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Comments", key: "comments" },
  { label: "Company", key: "company" },
  { label: "Customer Address", key: "billingAddress" },
  { label: "Customer ID", key: "customerId" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phoneNumber" },

  { label: "Cost Of Rental", key: "amount" },
  { label: "Cost Paid", key: "amountPaid" },
  { label: "Start Date", key: "startDate" },
  { label: "End Date", key: "endDate" },
  { label: "Inventory Needed", key: "equiptment" },
  { label: "Dropoff Location", key: "equiptmentLocaion" },
  { label: "Existing Customer", key: "existingCustomer" },

  { label: "Referred from", key: "heardAboutUsFrom" },
  { label: "Last Emailed", key: "lastEmailedDate" },
  { label: "Notes", key: "notes" },
  { label: "Payment Due Date", key: "paymentDueDate" },
  { label: "PO #", key: "poNumber" },
  { label: "Quantity", key: "quantity" },
  { label: "Rental ID", key: "reservationId" },

  { label: "Seven Day Rental", key: "sevenDayReservation" },
  { label: "Site Address", key: "siteAddress" },
  { label: "Rental Status", key: "status" },
  { label: "Recurring Rental", key: "recurring" },
];

function CustomerViewInvoicesFilter(props) {
  const {
    classes,
    sortBySelection,
    setSortBySelection,
    setDirectionSelection,
    directionSelection,
    setFilterSelection,
    filterSelection,
    setSearchString,
    searchString,
    handleFilters,
    handleSearch,
    handleCommentFilter,
    reservationsData,
    checkedAll,
    setCheckedAll,
    checkedCount,
    invoicesState
  } = props;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [allValues, setAllValues] = useState("")
  const [keepOpen, setKeepOpen] = useState(false);
  const [secOpen, setSecOpen] = useState(false)
  const [menu, setMenu] = useState(null);
  const menuOpen = Boolean(menu);

  const handleAllChange = (e) => {
    setAllValues(sortBySelection, fromDate, toDate)
  }


  return (
    <div>
      <GridContainer>
        <GridItem className={classes.filtersGridItem} xs={1}>
          <h5 className={classes.subHeader2}>Sort By</h5>
        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={6}>
          <Select
            style={{ width: "40%" }}
            className={classes.selectMenuSort}
            value={filterSelection}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => handleFilters("filter", e.target.value)}
          >
            {selectOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Select
            className={classes.ascMenu}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            value={directionSelection}
            onChange={(e) => handleFilters("direction", e.target.value)}
          >
            {directionOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <Button
            style={sortBySelection ? {
              height: "50px",
              width: "150px",
              marginLeft: "10px",
              fontWeight: 500,
              fontSize: "16px",
              background: "#FEDA1B"
            }
              : {
                height: "50px",
                width: "150px",
                marginLeft: "10px",
                fontWeight: 500,
                fontSize: "16px",
                background: "#D9D9D9"
              }}
            className={classes.addCustomer}
            onClick={(e) => setMenu(e.currentTarget)}
          >
            Filter By
          </Button>
          <Menu
            anchorEl={menu}
            open={menuOpen}
            onClose={() => setMenu(null)}
            classes={{
              paper: classes.menuPaper
            }}
          >
            <p className={classes.dateRange}>Status</p>
            <MenuItem>
              <Select
                open={secOpen}
                onOpen={() => setSecOpen(true)}
                onClose={() => setSecOpen(false)}
                style={{ width: "205px", height: "40px", marginLeft: "-7px" }}
                className={classes.filterMenu}
                value={sortBySelection}
                disableUnderline
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => handleFilters("status", e.target.value)}
              >
                {filterOptions.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {o}
                    </MenuItem>
                  );
                })}
              </Select>
            </MenuItem>

            <p className={classes.dateRange}>Start Date Range</p>
            <MenuItem style={{ display: "contents" }}>
              <Datetime
                style={{
                  color: "#323232 !important",
                  // height: "100%",
                  width: "100%",
                }}
                selected={fromDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setFromDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      style={{ width: "200px", }}
                      className={classes.datetime}
                      placeholder="From"
                      value={fromDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
            </MenuItem>
            <p className={classes.dateRange}>End Date Range</p>
            <MenuItem style={{ display: "contents" }}>
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "100%",
                }}
                selected={toDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setToDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      style={{ width: "200px" }}
                      className={classes.datetime}
                      placeholder="To"
                      value={toDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
            </MenuItem>
          </Menu>
        </GridItem>
        {/* <GridItem className={classes.filtersGridItemWithCsvDownload} xs={5}>
          <TextField
            className={classes.searchBar}
            placeholder="Search Rentals"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            variant="outlined"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
              endAdornment: (
                <InputAdornment className={classes.adournedEnd} position="end">
                  <Button
                    onClick={handleSearch}
                    className={classes.searchButton}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <CSVLink
            filename={"Rentals_Data"}
            data={reservationsData.map((x) => ({
              name: x.name,
              comments: x.comments,
              company: x.company,
              billingAddress: x.billingAddress,
              customerId: x.customerId,
              email: x.email,
              phoneNumber: x.phoneNumber,
              amount: x.reservation.amount,
              amountPaid: x.reservation.amountPaid,
              startDate: x.reservation.startDate,
              endDate: x.reservation.endDate,
              equiptment: x.reservation.equiptment,
              equiptmentLocaion: x.reservation.equiptmentLocaion,
              existingCustomer: x.reservation.existingCustomer,
              heardAboutUsFrom: x.reservation.heardAboutUsFrom,
              lastEmailedDate: x.reservation.lastEmailedDate,
              notes: x.reservation.notes,
              paymentDueDate: x.reservation.paymentDueDate,
              poNumber: x.reservation.poNumber,
              quantity: x.reservation.quantity,
              reservationId: x.reservation.reservationId,
              sevenDayReservation: x.reservation.sevenDayReservation,
              siteAddress: x.reservation.siteAddress,
              status: x.reservation.status,
              recurring: x.reservation.recurring,
            }))}
            headers={csvHeaders}
          >
            <Button
              style={{
                width: "100%",
                color: "#ffffff",
                background: "#323232",
                fontSize: "17px"
              }}
              className={classes.addCustomer}>
              Export CSV
            </Button>
          </CSVLink>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(CustomerViewInvoicesFilter));
