import {
  firebase,
  db,
  sendNewPendingReservationNotification,
  sendInvoiceLink,
  sendInvoiceLinkLocalHost,
  testingCustomerSignUp,
  sendingDenialEmail
} from "firebase/client";
import { createCustomer } from "store/actions/customers/customerActions";
import dateAndtime from "date-and-time";
import moment from "moment";
const reservationsCollection = db.collection("reservations");
const pendingReservationsCollection = db.collection("pending-reservations");

export const setPastReservations = (pastReservations) => {
  return {
    type: "SET_PAST_RESERVATIONS",
    pastReservations,
  };
};

export const updateSchedule = (
  scheduleObject,
  scheduleYear,
  scheduleMonth,
  scheduledDate,
  drivers,
  setSavedScheduleAlert,
  setShowSaveButton,
  setSavingSchedule
) => {
  return (dispatch) => {
    console.log(
      `api call first`,
      scheduleObject,
      drivers
      // scheduleYear,
      // scheduleMonth,
      // scheduledDate,
      // drivers
    );
    const driverIds = drivers.map((driver) => driver.driverId);
    const masterUpdateBatch = db.batch();
    const alreadyUpdatedObject = {};
    for (const driverId in scheduleObject) {
      const driverWeeklySchedule = scheduleObject[driverId].weeklySchedule;

      for (const date in driverWeeklySchedule) {
        const scheduledDate = new Date(date);
        const scheduleYear = scheduledDate.getFullYear();
        const scheduleMonth = scheduledDate.toLocaleString("default", {
          month: "short",
        });
        const dateArray = driverWeeklySchedule[date];
        dateArray.forEach((d) => {
          if (!alreadyUpdatedObject[d.reservation.reservationId]) {
            if (
              d.reservation.jobs.delivery.driverId &&
              !d.reservation.jobs.delivery.jobId
            ) {
              const jobId = db.collection("jobs").doc().id;
              d.reservation.jobs.delivery.jobId = jobId;
              masterUpdateBatch.set(db.collection("jobs").doc(jobId), {
                reservation: { ...d },
                jobId: jobId,
                attachments: [],
                driverNotes: [],
              });
            }
            if (
              d.reservation.jobs.delivery.driverId &&
              d.reservation.jobs.delivery.jobId
            ) {
              masterUpdateBatch.update(
                db.collection("jobs").doc(d.reservation.jobs.delivery.jobId),
                {
                  reservation: { ...d },
                }
              );
            }
            if (
              d.reservation.jobs.pickup.driverId &&
              !d.reservation.jobs.pickup.jobId
            ) {
              const jobId = db.collection("jobs").doc().id;
              d.reservation.jobs.pickup.jobId = jobId;
              masterUpdateBatch.set(db.collection("jobs").doc(jobId), {
                reservation: { ...d },
                jobId: jobId,
                attachments: [],
                driverNotes: [],
              });
            }
            if (
              d.reservation.jobs.pickup.driverId &&
              d.reservation.jobs.pickup.jobId
            ) {
              masterUpdateBatch.update(
                db.collection("jobs").doc(d.reservation.jobs.pickup.jobId),
                {
                  reservation: { ...d },
                }
              );
            }

            alreadyUpdatedObject[d.reservation.reservationId] = d;
            // getCustomerData.push(
            //   db.collection("customers").doc(d.customerId).get()
            // );
            masterUpdateBatch.update(
              db.collection("reservations").doc(d.reservation.reservationId),
              { reservation: { ...d.reservation }, customerId: d.customerId }
            );
          }
        });
        if (driverId !== "unassigned") {
          const indexOfDriver = driverIds.indexOf(driverId);
          drivers[indexOfDriver][scheduleYear][scheduleMonth][
            scheduledDate
          ] = dateArray;
          masterUpdateBatch.update(db.collection("drivers").doc(driverId), {
            ...drivers[indexOfDriver],
          });
        }
      }
    }
    masterUpdateBatch
      .commit()
      .then(() => {
        console.log("finished");
        setSavedScheduleAlert(true);
        setShowSaveButton(false);
        setSavingSchedule(false);
      })
      .catch((err) => {
        console.log("batch write error", err);
      });
    // });
  };
};

export const listenToReservations = () => {
  return (dispatch) => {
    reservationsCollection
      // .orderBy("paymentDueDateTimestamp")
      .onSnapshot((snapshot) => {
        const reservationsData = snapshot.docs.map((d) => d.data());
        console.log("reservationsreservationsreservations", reservationsData);

        const reservations = [];
        const customerDataPromiseArray = [];
        reservationsData.forEach((r) => {
          customerDataPromiseArray.push(
            db.collection("customers").doc(r.customerId).get()
          );
        });
        Promise.all(customerDataPromiseArray).then((results) => {
          results.forEach((r, index) => {
            r = r.data();
            reservations.push({ ...reservationsData[index], ...r });
          });

          pendingReservationsCollection
            // .orderBy("paymentDueDateTimestamp")
            .onSnapshot((snapshot) => {
              const pendingReservations = snapshot.docs.map((d) => d.data());
              // const sortedReservations = reservations.sort((a, b) =>
              //   a.name.localeCompare(b.name)
              // );
              // const sortedPendingReservations = pendingReservations.sort((a, b) =>
              //   a.name.localeCompare(b.name)
              // );
              dispatch(setReservationsState(reservations, pendingReservations));
            });
        });
      });
  };
};

export const deletePendingReservation = (reservation) => {
  return pendingReservationsCollection
    .doc(reservation.reservation.reservationId)
    .delete()
    .then(() => { });
};

const getAWeekOfDays = (startDate) => {
  const start = new Date(startDate);
  const dateArray = [];
  for (var x = 0; x < 7; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var new_date = dateAndtime.addDays(start, x);
    // return result;
    dateArray.push(moment(new_date).format("MM/DD/YY"));
  }
  return [...dateArray];
};

const getARangeOfDays = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  // To calculate the time difference of two dates
  var Difference_In_Time = end.getTime() - start.getTime();
  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  const dateArray = [];
  for (var x = 0; x < Difference_In_Days + 1; x++) {
    // var new_date = moment(start, "ddd MM/DD").add(x, 'days');
    var new_date = dateAndtime.addDays(start, x);
    // return result;
    dateArray.push(moment(new_date).format("MM/DD/YY"));
  }
  return [...dateArray];
};

export const updateInventoryQuantity = (reservation) => {
  const inventoryId = reservation.reservation.inventoryId;
  const quantity = reservation.reservation.quantity;
  const startDate = reservation.reservation.startDate;
  const endDate = reservation.reservation.endDate;
  return db
    .collection("inventory")
    .doc(inventoryId)
    .get()
    .then((snapshot) => {
      let inventoryData = snapshot.data();
      let dayRange = getARangeOfDays(startDate, endDate);
      console.log("dayRange", dayRange, inventoryData);

      dayRange.forEach((day) => {
        const scheduledDate = new Date(day);
        const scheduleYear = scheduledDate.getFullYear();
        const scheduleMonth = scheduledDate.toLocaleString("default", {
          month: "short",
        });
        inventoryData[scheduleYear][scheduleMonth][scheduledDate] =
          inventoryData[scheduleYear][scheduleMonth][scheduledDate] - quantity;
      });
      return db
        .collection("inventory")
        .doc(inventoryId)
        .update({ ...inventoryData })
        .then(() => {
          console.log("finsihed update");
        });
    });
};

export const createReservation = (reservation) => {
  return reservationsCollection
    .doc(reservation.reservation.reservationId)
    .set({
      reservation: {
        ...reservation.reservation,
      },
      customerId: reservation.customerId,
    })
    .then(() => { });
};

export const denyPendingReservation = (pendingRes, denialMessage, denialNotes, sendEmail) => {
  return (dispatch) => {
    let allData = {
      pendingRes,
      denialMessage,
      denialNotes
    }
    return db
      .collection("pending-reservations")
      .doc(pendingRes.reservation.reservationId)
      .delete()
      .then(() => {
        dispatch(defaultType());
        if (sendEmail) {
          sendingDenialEmail(allData)
        }
        console.log("denied reservation", allData);
      });
  };
};

export const updatePendingReservation = (pendingRes) => {
  return (dispatch) => {
    return db
      .collection("pending-reservations")
      .doc(pendingRes.reservation.reservationId)
      .update({
        ...pendingRes,
      })
      .then(() => { });
  };
};

const defaultType = () => {
  return {
    type: "DEFAULT",
  };
};

export const approvePendingReservation = (reservation) => {
  return (dispatch) => {
    console.log("reservationreservationreservation", reservation);
    reservation.reservation.status = "Approved";
    createReservation(reservation);
    updateInventoryQuantity(reservation);
    deletePendingReservation(reservation);
    // sendInvoiceLink(reservation);
  };
};

export const addPendingReservationNewCustomerTemp = (pendingRes) => async (
  dispatch
) => {
  try {
    const reservationId = db.collection("pending-reservations").doc().id;
    const invoiceId = db.collection("invoices").doc().id;
    const customerId = db.collection("customers").doc().id;
    const heardAboutUsFromChoice = pendingRes.reservation.heardAboutUsFrom;
    let existOrNew = pendingRes.reservation.existingCustomer === true ? true : false
    pendingRes = {
      ...pendingRes,
      customerId,
      reservation: {
        ...pendingRes.reservation,
        reservationId,
        existingCustomer: existOrNew,
        amountPaid: "0",
        jobs: {
          pickup: { driverId: "", driverName: "N/A" },
          delivery: { driverId: "", driverName: "N/A" },
        },
        invoiceId,
      },
      customerSince: firebase.firestore.Timestamp.now(),
    };

    await db
      .collection("customers")
      .doc(customerId)
      .set({
        ...pendingRes,
        reservations: [reservationId],
        invoices: [invoiceId],
        reminders: [],
        accountingStatus: "Unpaid",
      });

    pendingRes.reservation.paymentDueDateTimestamp = firebase.firestore.Timestamp.fromDate(
      new Date(pendingRes.reservation.paymentDueDate)
    );

    await db
      .collection("pending-reservations")
      .doc(reservationId)
      .set(pendingRes);

    let termsAndConditionsSnap = await db
      .collection("terms-and-conditions")
      .doc("terms-and-conditions")
      .get();

    const termsAndConditionsData = termsAndConditionsSnap.data();
    const getServiceArr = () => {
      if (typeof pendingRes.reservation.equiptment === "object") {
        return Object.entries(pendingRes.reservation.equiptment).map(
          ([key, quantity]) => {
            let name = "No equipment found";
            switch (key) {
              case "tenYard":
                name = "10 Yard Container";
                break;
              case "fifteenYard":
                name = "15 Yard Container";
                break;
              case "twentyYard":
                name = "20 Yard Container";
                break;
              case "thirtyYard":
                name = "30 Yard Container";
                break;
              case "metalOrConcrete":
                name = "Metal or Concrete";
                break;
              case "standardThirty":
                name = "Standard 30 Yard";
                break;
            }
            return {
              serviceInfo: { ...pendingRes.reservation },
              rate: pendingRes.reservation.amount,
              description: "N/A",
              type: "reservation",
              name,
              quantity,
              agreedToTermsOfService: true,
            };
          }
        );
      } else {
        return [
          {
            serviceInfo: { ...pendingRes.reservation },
            rate: pendingRes.reservation.amount,
            description: "N/A",
            type: "reservation",
            name: pendingRes.reservation.equiptment,
            quantity: pendingRes.reservation.quantity,
            agreedToTermsOfService: true,
          },
        ];
      }
    };

    await db
      .collection("invoices")
      .doc(invoiceId)
      .set({
        services: getServiceArr(),
        customerId: pendingRes.customerId,
        invoiceId,
        servicesTotal: pendingRes.reservation.amount,
        invoiceStatus: "Draft",
        reservationId: pendingRes.reservation.reservationId,
        invoiceDetails: {
          paymentStatus: "",
          paymentMethod: "",
          paymentId: "",
        },
        attachments: [],
        internalMemo: "",
        emailed: "",
        invoiceDate: firebase.firestore.Timestamp.fromDate(new Date()),
        termsAndConditions: termsAndConditionsData.termsHtml,
        clerk: "SC",
        recurring: false,
        siteId: "",
        discount: "",
      });

    const referralSnap = await db
      .collection("referrals")
      .doc("referrals-doc")
      .get();
    const referralData = referralSnap.data();
    referralData[heardAboutUsFromChoice] =
      referralData[heardAboutUsFromChoice] + 1;
    referralData.totalCount = referralData.totalCount + 1;
    await db
      .collection("referrals")
      .doc("referrals-doc")
      .update({
        ...referralData,
      });

    await sendNewPendingReservationNotification(pendingRes);
  } catch (error) {
    console.error("checking error1 inside actions", error);
    throw error;
  }
};

export const addPendingReservationNewCustomer = (pendingRes) => async (
  dispatch
) => {
  try {
    const reservationId = db.collection("pending-reservations").doc().id;
    const invoiceId = db.collection("invoices").doc().id;
    const customerId = db.collection("customers").doc().id;
    const heardAboutUsFromChoice = pendingRes.reservation.heardAboutUsFrom;
    pendingRes = {
      ...pendingRes,
      customerId,
      reservation: {
        ...pendingRes.reservation,
        reservationId,
        existingCustomer: false,
        amountPaid: "0",
        jobs: {
          pickup: { driverId: "", driverName: "N/A" },
          delivery: { driverId: "", driverName: "N/A" },
        },
        invoiceId,
      },
      customerSince: firebase.firestore.Timestamp.now(),
      paymentDueDateTimestamp: firebase.firestore.Timestamp.fromDate(
        new Date(pendingRes.reservation.paymentDueDate)
      ),
    };

    // pendingRes.reservation.paymentDueDateTimestamp = firebase.firestore.Timestamp.fromDate(
    //   new Date(pendingRes.reservation.paymentDueDate)
    // );

    let termsAndConditionsSnap = await db
      .collection("terms-and-conditions")
      .doc("terms-and-conditions")
      .get();

    const termsAndConditionsData = termsAndConditionsSnap.data();

    await db
      .collection("pending-reservations")
      .doc(reservationId)
      .set(pendingRes);
    const getServiceArr = () => {
      if (typeof pendingRes.reservation.equiptment === "object") {
        return Object.entries(pendingRes.reservation.equiptment).map(
          ([key, quantity]) => {
            let name = "No equipment found";
            switch (key) {
              case "tenYard":
                name = "10 Yard Container";
                break;
              case "fifteenYard":
                name = "15 Yard Container";
                break;
              case "twentyYard":
                name = "20 Yard Container";
                break;
              case "thirtyYard":
                name = "30 Yard Container";
                break;
              case "metalOrConcrete":
                name = "Metal or Concrete";
                break;
              case "standardThirty":
                name = "Standard 30 Yard";
                break;
            }
            return {
              serviceInfo: { ...pendingRes.reservation },
              rate: pendingRes.reservation.amount,
              description: "N/A",
              type: "reservation",
              name,
              quantity,
              agreedToTermsOfService: true,
            };
          }
        );
      } else {
        return [
          {
            serviceInfo: { ...pendingRes.reservation },
            rate: pendingRes.reservation.amount,
            description: "N/A",
            type: "reservation",
            name: pendingRes.reservation.equiptment,
            quantity: pendingRes.reservation.quantity,
            agreedToTermsOfService: true,
          },
        ];
      }
    };
    await db
      .collection("invoices")
      .doc(invoiceId)
      .set({
        services: getServiceArr(),
        customerId: pendingRes.customerId,
        invoiceId,
        servicesTotal: pendingRes.reservation.amount,
        invoiceStatus: "Draft",
        reservationId: pendingRes.reservation.reservationId,
        invoiceDetails: {
          paymentStatus: "",
          paymentMethod: "",
          paymentId: "",
        },
        attachments: [],
        internalMemo: "",
        emailed: "",
        invoiceDate: firebase.firestore.Timestamp.fromDate(new Date()),
        termsAndConditions: termsAndConditionsData.termsHtml,
        clerk: "SC",
        recurring: false,
        siteId: "",
        discount: "",
      });

    delete pendingRes.reservation;

    await db
      .collection("customers")
      .doc(customerId)
      .set({
        ...pendingRes,
        reservations: [reservationId],
        invoices: [invoiceId],
        reminders: [],
        accountingStatus: "Unpaid",
      });
    console.log("finsihed");
    const referralSnap = await db
      .collection("referrals")
      .doc("referrals-doc")
      .get();
    const referralData = referralSnap.data();
    referralData[heardAboutUsFromChoice] =
      referralData[heardAboutUsFromChoice] + 1;
    referralData.totalCount = referralData.totalCount + 1;
    await db
      .collection("referrals")
      .doc("referrals-doc")
      .update({
        ...referralData,
      });
    sendNewPendingReservationNotification(pendingRes);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const addPendingReservationExistingCustomer = (pendingRes) => async (
  dispatch
) => {
  try {
    const reservationId = db.collection("pending-reservations").doc().id;
    const invoiceId = db.collection("invoices").doc().id;
    const heardAboutUsFromChoice = pendingRes.reservation.heardAboutUsFrom;
    pendingRes = {
      ...pendingRes,
      reservation: {
        ...pendingRes.reservation,
        reservationId,
        existingCustomer: true,
        amountPaid: "0",
        jobs: {
          pickup: { driverId: "", driverName: "N/A" },
          delivery: { driverId: "", driverName: "N/A" },
        },
        invoiceId,
      },
    };

    await db
      .collection("customers")
      .doc(pendingRes.customerId)
      .update({
        ...pendingRes,
        reservations: [reservationId, ...pendingRes.reservations],
        accountingStatus: "Unpaid",
      });

    pendingRes.reservation.paymentDueDateTimestamp = firebase.firestore.Timestamp.fromDate(
      new Date(pendingRes.reservation.paymentDueDate)
    );

    await db
      .collection("pending-reservations")
      .doc(reservationId)
      .set(pendingRes);

    let termsAndConditionsSnap = await db
      .collection("terms-and-conditions")
      .doc("terms-and-conditions")
      .get();

    const termsAndConditionsData = termsAndConditionsSnap.data();
    const getServiceArr = () => {
      if (typeof pendingRes.reservation.equiptment === "object") {
        return Object.entries(pendingRes.reservation.equiptment).map(
          ([key, quantity]) => {
            let name = "No equipment found";
            switch (key) {
              case "tenYard":
                name = "10 Yard Container";
                break;
              case "fifteenYard":
                name = "15 Yard Container";
                break;
              case "twentyYard":
                name = "20 Yard Container";
                break;
              case "thirtyYard":
                name = "30 Yard Container";
                break;
              case "metalOrConcrete":
                name = "Metal or Concrete";
                break;
              case "standardThirty":
                name = "Standard 30 Yard";
                break;
            }
            return {
              serviceInfo: { ...pendingRes.reservation },
              rate: pendingRes.reservation.amount,
              description: "N/A",
              type: "reservation",
              name,
              quantity,
              agreedToTermsOfService: true,
            };
          }
        );
      } else {
        return [
          {
            serviceInfo: { ...pendingRes.reservation },
            rate: pendingRes.reservation.amount,
            description: "N/A",
            type: "reservation",
            name: pendingRes.reservation.equiptment,
            quantity: pendingRes.reservation.quantity,
            agreedToTermsOfService: true,
          },
        ];
      }
    };
    await db
      .collection("invoices")
      .doc(invoiceId)
      .set({
        services: getServiceArr(),
        customerId: pendingRes.customerId,
        invoiceId,
        servicesTotal: pendingRes.reservation.amount,
        invoiceStatus: "Draft",
        reservationId: pendingRes.reservation.reservationId,
        invoiceDetails: {
          paymentStatus: "",
          paymentMethod: "",
          paymentId: "",
        },
        attachments: [pendingRes.reservation.attachmentUrl],
        internalMemo: "",
        invoiceDate: firebase.firestore.Timestamp.fromDate(new Date()),
        termsAndConditions: termsAndConditionsData.termsHtml,
        clerk: "SC",
        emailed: "",
        recurring: false,
        siteId: "",
        discount: "",
      });
    const referralSnap = await db
      .collection("referrals")
      .doc("referrals-doc")
      .get();
    const referralData = referralSnap.data();
    referralData[heardAboutUsFromChoice] =
      referralData[heardAboutUsFromChoice] + 1;
    referralData.totalCount = referralData.totalCount + 1;
    await db
      .collection("referrals")
      .doc("referrals-doc")
      .update({
        ...referralData,
      });
    sendNewPendingReservationNotification(pendingRes);
  } catch (error) {
    console.error("checking what the error is", error);
    throw error;
  }
};

export const setReservationsState = (reservations, pendingReservations) => {
  return {
    type: "SET_RESERVATIONS",
    reservations,
    pendingReservations,
  };
};
