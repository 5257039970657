import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import upcomingPaymentsStyle from "assets/jss/material-dashboard-pro-react/views/upcomingPaymentsStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Datetime from "react-datetime";
import moment from "moment";

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

function PaymentsSubHeader(props) {
  const {
    classes,
    paymentDueDate,
    handleDayIncrement,
    handleDayDecrement,
    setSearchString,
    searchString,
    setCalendarOpen,
    calendarOpen,
    setPaymentDueDate,
    handleSearch,
    handleFilterByDay
  } = props;

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem className={classes.upcomingPaymentsGridItem} xs={6} style={{marginBottom:"10px"}}>
        <p className={classes.subHeader} style={{lineHeight:"1px", margin: "0px"}}>Due</p>
        <div
          style={{ display: "flex", marginLeft: "20px", alignItems: "center" }}
        >
          <KeyboardArrowLeft
            className={classes.arrowIcon}
            style={paymentDueDate === "All" ? { visibility: "hidden" } : {}}
            onClick={handleDayDecrement}
          />
          <span className={classes.paymentsDue}>{paymentDueDate}</span>
          <KeyboardArrowRight
            onClick={handleDayIncrement}
            className={classes.arrowIcon}
            style={paymentDueDate === "All" ? { visibility: "hidden" } : {}}
          />
        </div>
        <Tooltip
          id="tooltip-top"
          title="Change Payment Date"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <CalendarIcon
            className={classes.calendarIcon}
            onClick={() => setCalendarOpen(true)}
          />
        </Tooltip>
        {calendarOpen && (
          <Datetime
          isValidDate={disablePastDt}
            open={calendarOpen}
            input
            // className={classes.textFieldRoot}
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
            }}
            selected={paymentDueDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              handleFilterByDay(new Date(date))
              setPaymentDueDate(date.format("ddd MM/DD/YY"));
              setCalendarOpen(false);
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
          />
        )}
      </GridItem>
      <GridItem className={classes.upcomingPaymentsGridItemEnd} xs={6} style={{marginBottom:"10px"}}>
        <TextField
          className={classes.searchBar}
          placeholder="Search Invoices"
          value={searchString}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSearch()
            }
          }}
          onChange={(e) => setSearchString(e.target.value)}
          variant="outlined"
          InputProps={{
            classes: {
              adornedEnd: classes.adournedEnd,
            },
            endAdornment: (
              <InputAdornment className={classes.adournedEnd} position="end">
                <Button onClick={handleSearch} className={classes.searchButton}>Search</Button>
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(upcomingPaymentsStyle)
)(React.memo(PaymentsSubHeader));
