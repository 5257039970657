import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reportsStyle from "assets/jss/material-dashboard-pro-react/views/reportsStyle.js";
import Card from "components/Card/Card.js";
import { ReactComponent as Minus } from "assets/img/minus.svg";
import Button from "components/CustomButtons/Button.js";

const overviews = [
  {
    title: "Aging",
    description: "Unpaid balances for each customer, grouped by days past due.",
    link: "/admin/reports/aging",
  },
];

function WhoOwes(props) {
  const { classes, history } = props;

  return (
    <Card>
      <div className={classes.cardHeaderDiv}>
        <Minus />
        <span className={classes.cardTitle}>Who Owes You</span>
      </div>
      <div className={classes.cardBodyDiv}>
        <GridContainer>
          {overviews.map((o, index) => {
            return (
              <GridItem className={classes.reportGridItem} key={index} xs={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className={classes.reportTitle}
                    onClick={() => history.push(o.link)}
                  >
                    {o.title}
                  </span>
                  <span className={classes.reportDescription}>
                    {o.description}
                  </span>
                </div>
                <Button
                  onClick={() => history.push(o.link)}
                  className={classes.viewBtn}
                >
                  View Report{" "}
                </Button>
              </GridItem>
            );
          })}
        </GridContainer>
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reportsStyle)
)(React.memo(WhoOwes));
