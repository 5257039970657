import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customersStyle from "assets/jss/material-dashboard-pro-react/views/customersStyle.js";
import Dialog from "@material-ui/core/Dialog";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import Filters from "./components/Filters";
import CustomersTable from "./components/CustomersTable";
import {
  filterData,
  filterDataWithComments,
} from "../../globalHelperFunctions/globalHelperFunctions";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AddCustomerModal from "./components/AddCustomerModal";
import Card from "components/Card/Card";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Customers(props) {
  const { classes, customersData, history, openModal } = props;
  const [sortBySelection, setSortBySelection] = useState("Filter By");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterSelection, setFilterSelection] = useState("First Name");
  const [searchString, setSearchString] = useState("");
  const [customers, setCustomers] = useState([]);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [customerCreated, setCustomerCreated] = useState(false);

  useEffect(() => {
    setCustomers(customersData);
  }, [customersData]);

  useEffect(() => {
    if (
      history.location &&
      history.location.state &&
      history.location.state.edit
    ) {
      setAddCustomerModal(true);
    }
  }, [history.location]);

  const handleFilters = (type, filterValue) => {
    if (type === "filter") {
      const filtered = filterDataWithComments(
        filterValue,
        directionSelection,
        sortBySelection,
        customersData
      );
      setFilterSelection(filterValue);
      setCustomers([...filtered]);
    }
    if (type === "direction") {
      const filtered = filterDataWithComments(
        filterSelection,
        filterValue,
        sortBySelection,
        customersData
      );
      setDirectionSelection(filterValue);
      setCustomers([...filtered]);
    }
    if (type === "comments") {
      const filtered = filterDataWithComments(
        filterSelection,
        directionSelection,
        filterValue,
        customersData
      );
      setSortBySelection(filterValue);
      setCustomers([...filtered]);
    }
  };

  const handleCommentFilter = (value) => {
    if (value !== "All") {
      const filtered = customersData.filter((c) => {
        if (c.comments.includes(value)) {
          return true;
        }
      });
      setCustomers(filtered);
    } else {
      setCustomers(customersData);
    }
    setSortBySelection(value);
  };

  const handleSearch = () => {
    if (searchString) {
      const lowerCaseQuery = searchString.toLowerCase();
      const filtered = customersData.filter((u) => {
        let lowerCaseBillingAddress = '';
        let lowerCaseEmail = '';
        let lowerCaseCustomerId = '';
        const lowerCaseName = u.name.toLowerCase();
        if (u.billingAddress) {
          lowerCaseBillingAddress = u.billingAddress.toLowerCase();
        } else if (u.address) {
          lowerCaseBillingAddress = u.address.toLowerCase();
        }
        // const lowerCaseBillingAddress = u.billingAddress.toLowerCase();
        const lowerCaseCompany = u.company.toLowerCase();
        if (u.email) {
          lowerCaseEmail = u.email.toLowerCase();
        } else if (u.emails) {
          lowerCaseEmail = u.emails.toLowerCase();
        }
        // const lowerCaseEmail = u.email.toLowerCase();
        if (u.customerId) {
          lowerCaseCustomerId = u.customerId.toLowerCase();
        } else if (u.id) {
          lowerCaseCustomerId = u.id.toLowerCase();
        }
        // const lowerCaseCustomerId = u.customerId.toLowerCase();
        if (
          lowerCaseName.includes(lowerCaseQuery) ||
          lowerCaseBillingAddress.includes(lowerCaseQuery) ||
          lowerCaseCompany.includes(lowerCaseQuery) ||
          lowerCaseEmail.includes(lowerCaseQuery) ||
          lowerCaseCustomerId.includes(lowerCaseQuery)
        ) {
          return true;
        }
      });
      console.log("filtered", filtered);
      setCustomers(filtered);
    } else {
      setCustomers(customersData);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={7}>
        <h1
          className={classes.title}
          style={{ display: "flex", alignItems: "center" }}
        >
          Customers
        </h1>
      </GridItem>
      <GridItem className={classes.buttonGridItem} xs={5}>
        <Button
          onClick={() => setAddCustomerModal(true)}
          className={classes.addCustomer}
          style={{ whiteSpace: "noWrap" }}
        >
          <AddIcon /> Add Customer
        </Button>
      </GridItem>
      <Card style={{ padding: "0px 30px 0px 30px", height: "100%" }}>
        <GridItem xs={12}>
          <Filters
            handleCommentFilter={handleCommentFilter}
            handleSearch={handleSearch}
            handleFilters={handleFilters}
            searchString={searchString}
            setSearchString={setSearchString}
            filterSelection={filterSelection}
            setFilterSelection={setFilterSelection}
            directionSelection={directionSelection}
            setDirectionSelection={setDirectionSelection}
            sortBySelection={sortBySelection}
            setSortBySelection={setSortBySelection}
            customers={customers}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomersTable history={history} customers={customers} />
        </GridItem>
      </Card>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={addCustomerModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setAddCustomerModal(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <AddCustomerModal
          setCustomerCreated={setCustomerCreated}
          setAddCustomerModal={setAddCustomerModal}
        />
      </Dialog>

      <Snackbar
        open={customerCreated}
        onClose={() => setCustomerCreated(false)}
      >
        <Alert onClose={() => setCustomerCreated(false)} severity={"success"}>
          Customer Created
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customersStyle)
)(React.memo(Customers));
