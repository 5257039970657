import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Button from "components/CustomButtons/Button.js";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

function InventoryRentedTable(props) {
  const {
    classes,
    defaultHeader,
    handleNextFourteenDays,
    handlePreviousFourteenDays,
    inventoryData,
    reservation,
    dateRange,
  } = props;

  console.log("defaultHeader", defaultHeader);

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          borderBottom: "1px solid #8E8E8E",
          width: "140px",
        }}
      >
        {defaultHeader.map((key, index) => {
          return (
            <th
              style={
                index > 0
                  ? { border: "0.5px solid #8E8E8E", background: "#D9D9D9" }
                  : {}
              }
              key={key}
            >
              {index === 0 ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ width: "100%" }}>{key}</span>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ width: "100%" }}>{key.split(" ")[0]}</span>
                  <span>{key.split(" ")[1]}</span>
                </div>
              )}
            </th>
          );
        })}
      </tr>
    );
  };
  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };

  const renderTableData = () => {
    return (
      <tr>
        <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
          {renderEquipment(reservation.reservation.equiptment)}
        </td>
        {defaultHeader.slice(1).map((header, index) => {
          if (dateRange[index]) {
            return <td className={classes.yellowTableCell}>1</td>;
          }
        })}
      </tr>
    );
  };

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem xs={12}>
        <table className={classes.inventoryTable}>
          <tbody>
            {renderTableHeader()}
            {renderTableData()}
          </tbody>
        </table>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(InventoryRentedTable);
