import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import compose from "recompose/compose";
import { connect } from "react-redux";
import actionsForNavigation from "store/actions/navigation";

import userProfileStyle from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import avatar from "assets/img/faces/marc.jpg";

export function UserProfile(props) {
    const { classes, firstName, lastName, userType, email, dateOfBirth, phoneNumber } = props;

    function formatPhoneNumber(phoneNumber) {
        var cleaned = ("" + phoneNumber).replace(/\D/g, "")
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3]
        }
        return "Invalid phone number length"
    }

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                    <Card profile>
                        <CardAvatar profile>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                                <img src={avatar} alt="..." />
                            </a>
                        </CardAvatar>
                        <CardBody profile>
                            <h4 className={classes.cardTitle}>{firstName + " " + lastName}</h4>
                            <div className={classes.cardCategory}>
                                <h6>{userType}</h6>
                                <h6>{formatPhoneNumber(phoneNumber)}</h6>
                                <h6>{email}</h6>
                            </div>
                            <div className={classes.description}>
                                <p>Date of birth: {dateOfBirth}</p>
                                <p>User description will live here</p>
                            </div>
                            <Button className={classes.updateProfileButton} onClick={e=> props.edit()}>
                                Update Profile
                            </Button>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

UserProfile.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {
        firstName: state.auth.user && state.auth.user.firstName,
        lastName: state.auth.user && state.auth.user.lastName,
        userType: state.auth.user && state.auth.user.userType,
        email: state.auth.user && state.auth.user.email,
        dateOfBirth: state.auth.user && state.auth.user.dateOfBirth,
        phoneNumber: state.auth.user && state.auth.user.phoneNumber,
    };
}

function mapDispatchToProps(actions) {
    return (dispatch) => ({
        edit: (key) => dispatch(actionsForNavigation.openChild("Profile Editor", `/admin/user/editor`))
    })
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(userProfileStyle)
)(UserProfile);
