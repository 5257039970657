import moment from "moment";
import dateAndtime from "date-and-time";

export const getNextDay = (startDate) => {
  const start = new Date(startDate);
  const dateToReturn = dateAndtime.addDays(start, 1);
  return moment(dateToReturn).format("ddd MM/DD/YY");
}

export const getPreviousDay = (startDate) => {
  const date = new Date(startDate);
  const dateToReturn = date.setDate(date.getDate() - 1);
  return moment(dateToReturn).format("ddd MM/DD/YY");
}

export const getThisWeekDays = (startDate) => {
  const start = new Date(startDate);
  const thisWeek = [moment(start).format("ddd MM/DD/YY")]
  for (var x = 1; x < 7; x++) {
    var new_date = dateAndtime.addDays(start, x);
    var toPush = moment(new_date).format("ddd MM/DD/YY");
    thisWeek.push(toPush)
  }
  return thisWeek;
}

