import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import scheduleStyle from "assets/jss/material-dashboard-pro-react/views/scheduleStyle.js";
import moment from "moment";
import { ReactComponent as ArrowDown } from "assets/img/arrowDown.svg";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ReactComponent as DeliveryIcon } from "assets/img/deliveryIcon.svg";

import GoogleMap from "./GoogleMap";

function MapSchedule(props) {
  const {
    classes,
    drivers,
    weeklyItems,
    setWeeklyItems,
    scheduledDate,
    scheduleYear,
    scheduleMonth,
    weeklySchedule,
    activeId,
    setActiveId,
    data,
    setData,
    history,
    setChangedWeeklyTable,
    searchString,
    handleDragStart,
    setShowSaveButton,
    setMovingItemError,
    mapData,
  } = props;
  const widthOfColumn = drivers.length + 1;

  const columnWidth = 100 / 8;
  return (
    <GridContainer className={classes.scheduleMapGridContainer}>
      <GridItem className={classes.dndContext} xs={12}>
        <div
          style={{ width: "12.5%", display: "flex", flexDirection: "column" }}
        >
          <div style={{ width: `100%` }}>
            <div
              style={{ background: "#D9D9D9" }}
              className={classes.scheduleColumnHeader}
            >
              <span className={classes.scheduleHeaderText}>Driver</span>
            </div>
          </div>
          {mapData.map((i, index) => {
            const driverScheduleCount = i.route.length;
            return (
              <div
                key={i.driverId}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: `100%`,
                  background: "white",
                  alignItems: "center",
                  border: "0.5px solid #929292",
                  padding: "10px",
                  height: "100px",
                  borderLeft: `10px solid ${i.color}`
                }}
              >
                <span>{`${i.name} (${driverScheduleCount})`}</span>
              </div>
            );
          })}
          <span className={classes.assignColors}>Assign Colors</span>
        </div>
        <div style={{ width: "90%" }}>
          <GoogleMap mapData={mapData} />
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(scheduleStyle)
)(MapSchedule);
