import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import loader from "assets/img/loader.gif";

function ReservationForm(props) {
  const {
    classes,
    existingCustomer,
    setExistingCustomer,
    newCustomer,
    setNewCustomer,
    dataLoaded,
  } = props;

  return (
    <Card className={classes.card}>
      {dataLoaded ? (
        <>
          <h1 className={classes.title}>
            Are you a returning or new customer?
          </h1>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={existingCustomer}
                onChange={() => setExistingCustomer(!existingCustomer)}
              />
              <span>Existing Customer</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={newCustomer}
                onChange={() => setNewCustomer(!newCustomer)}
              />
              <span>New Customer</span>
            </div>
          </div>
        </>
      ) : (
        <img
          style={{ width: "50%", margin: "0px auto" }}
          src={loader}
          alt="..."
        />
      )}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(ReservationForm));
