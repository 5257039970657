
const cardFieldStyle = {
  label: {
    padding: "10px",
    fontWeight: 800
  },

  value: {
      padding: "10px",
  }
};

export default cardFieldStyle;
