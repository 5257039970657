import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import QueryField from "./QueryField";
import { startCase } from "lodash";



export function BoundQueryField(props) {
    const { inputProps = {}, operatorProps = {}, sortProps={}, onChangeInput, onChangeOperator, onChangeSort, onBlur, ...rest } = props;

    return <QueryField {...rest} 
        inputProps={{ ...inputProps, onChange: onChangeInput, onBlur }} 
        operatorProps={{ ...operatorProps, onChange: onChangeOperator }}
        sortProps={{ ...sortProps, onChange: onChangeSort }}
    />;
}

BoundQueryField.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  operatorProps: PropTypes.object,
  sortProps: PropTypes.object,
  onChangeInput: PropTypes.func,
  onChangeOperator: PropTypes.func,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  name: PropTypes.string.isRequired,
  config: PropTypes.object
};

function getOperatorFieldName(props) {
    return "_" + props.name + "Operator";
}

function mapStateToProps(accessors) {
  return (state, ownProps) => {
    const { type, values, label } = accessors.getFieldConfig(ownProps.name);
    const labelText = ownProps.labelText || (label === undefined ? startCase(ownProps.name) : label);
    const error = accessors.getFieldError(state, ownProps.name);
    const errorText = error && error.message;
    return {
      inputProps: {
        ...ownProps.inputProps,
        value: accessors.getField(state, ownProps.name) || ""
      },
      operatorProps: {
        ...ownProps.operatorProps,
        value: accessors.getField(state, getOperatorFieldName(ownProps))
      },
      sortProps: {
        ...ownProps.sortProps
      },      
      error: !!error,
      helperText: errorText,
      labelText,
      type,
      values,
      id: `${accessors.getFormName()}-${accessors.getStepName()}-${ownProps.name}`,
    };
  };
}

function addSort(actions, accessors, field, order) {
    return (dispatch, getState) => {
        const sortField = accessors.getField(getState(), "_sort") || [];
        let replace = sortField.findIndex(({field : key})=>key===field);
        let newSortField = [ ...sortField ];
        if (replace >= 0)
            newSortField.splice(replace, 1, { field, order });
        else
            newSortField.push({ field, order});
        dispatch(actions.editField("_sort", newSortField));
    }
}

function mapDispatchToProps(actions, accessors) {
  return (dispatch, ownProps) => ({
    onChangeInput: (e) => dispatch(actions.editField(ownProps.name, e.target.value)),
    onBlur: (e) => dispatch(actions.validateField(ownProps.name, e.target.value)),
    onChangeOperator: (value) => dispatch(actions.editField(getOperatorFieldName(ownProps), value)),
    onChangeSort: (value) => dispatch(addSort(actions, accessors, ownProps.name, value))
  });
}

export default (actions, accessors) =>
  connect(
    mapStateToProps(accessors),
    mapDispatchToProps(actions, accessors)
  )(BoundQueryField);
