import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

function NotesTab(props) {
  const { classes, site, siteID } = props;

  return (
    <Card
      style={{ marginTop: "0px", padding: "40px" }}
      className={classes.jobsCard}
    >
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={6}>
          <span className={classes.notesTitle}>Notes</span>
          <span className={classes.notesInfo}>{site.notes || "N/A"}</span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={6}>
          <span className={classes.notesTitle}>Internal Notes</span>
          <span className={classes.notesInfo}>
            {site.internalNotes || "N/A"}
          </span>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(NotesTab));
