import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";
import "views/Dashboard/components/pagination.css";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import loader from "assets/img/loader.gif";
import MenuItem from "@material-ui/core/MenuItem";
import { db } from "firebase/client";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconWhite.svg";
import { ReactComponent as PhoneIcon } from "assets/img/phoneIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIconBlack.svg";
import { ReactComponent as VerifiedIcon } from "assets/img/verifiedIcon.svg";
import { ReactComponent as CreateRefund } from "assets/img/createRefund.svg";
import { ReactComponent as ApplyCredit } from "assets/img/applyCredit.svg";
import { ReactComponent as VoidPayment } from "assets/img/voidPayment.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import AccountingCustomTabs from "./AccountingCustomTabs";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import moment from "moment";

const headers = [
  "Details",
  "Due Date",
  "Method",
  "Invoice ID",
  "Amount",
  "Remaining",
  "Payment Status",
  "Actions",
];
const sortByOptions = ["Payment Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];

const pageArr = ["8", "All"];

function AccountingPaymentsTab(props) {
  const { classes, invoices, history, tabName, setTabName } = props;
  console.log("invoicesxxxxx", invoices);
  const [sortBy, setSortBy] = useState("Payment Date");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterBySelection, setFilterBySelection] = useState("Filter By");
  const [searchString, setSearchString] = useState("");
  const itemsPerPage = 8;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("8");
  const [payments, setPayments] = useState(false);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  useEffect(() => {
    if (tabName !== "Payments") {
      setTabName("Payments")
    }
  }, [])

  useEffect(() => {
    // if (invoices.length > 0) {
    // const getData = async () => {
    //   const paymentsPromiseArray = [];
    //   const customerPromiseArray = [];
    //   invoices.forEach((i) => {
    //     if (i.invoiceDetails.paymentId) {
    //       paymentsPromiseArray.push(
    //         db.collection("payments").doc(i.invoiceDetails.paymentId).get()
    //       );
    //       customerPromiseArray.push(
    //         db.collection("customers").doc(i.customerId).get()
    //       );
    //     }
    //   });
    //   return Promise.all(paymentsPromiseArray).then((results) => {
    //     let paymentsData = results.reduce((result, r) => {
    //       if (r.exists) {
    //         result.push(r.data());
    //       }
    //       return result;
    //     }, []);
    //     console.log('look payments', paymentsData)
    //     return Promise.all(customerPromiseArray).then((results) => {
    //       results.forEach((r, index) => {
    //         if (r.exists) {
    //           const customer = r.data();
    //           paymentsData[index].customerInfo = { ...customer };
    //         }
    //       });
    //       console.log("paymentsData", paymentsData);
    //       setPayments(paymentsData);
    //     });
    //   });
    // };
    const getData = () => {
      db.collection("payments")
        .get()
        .then((snapshot) => {
          return snapshot.docs.map((doc) => doc.data());
        })
        .then((payments) => setPayments(payments));
    }
    getData();
    // }
  }, []);

  // useEffect(() => {
  //   reservationsData.slice(index, endIndex);
  // }, [index]);

  const renderTableHeader = () => {
    return (
      <tr className={classes.notesTableRow} style={{ height: "38px" }}>
        {headers.map((key, index) => {
          return (
            <th className={classes.notesTableHeaders2} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };
  console.log("payment check", payments)
  const renderTableData = () => {
    let data;
    if (pageDropdown === "8") {
      data = payments.slice(
        pageNumber * itemsPerPage,
        pageNumber * itemsPerPage + itemsPerPage
      );
    }
    else {
      data = payments
    }
    return data.map((d, index) => {
      const newDate = d["Date Due"].toString();
      let paymentDate = "N/A";
      if (newDate) {
        paymentDate = newDate.toDate();
      }
      console.log('look status', d.Status)
      return (
        <tr className={classes.notesTableRow} key={d.id}>
          <td
            style={{ color: "#0071C5", textDecoration: "underline" }}
            className={classes.notesTableCell}
            className={classes.notesTableCell}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <span
                onClick={() =>
                  history.push({
                    pathname: `/admin/payment/${d.id}`,
                  })
                }
              >
                {" "}
                {d["Transaction Number"]}
              </span>
              <span
                onClick={() =>
                  history.push({
                    pathname: `/admin/customers/${d.name || d["Customer Name"]
                      }`,
                  })
                }
              >
                {" "}
                {d.name || d["Customer Name"]}
              </span>
            </div>
          </td>
          <td className={classes.notesTableCell}>
            {moment(paymentDate).format("MM/DD/YY")}
          </td>
          <td className={classes.notesTableCell}>
            {d.card && d.card.brand ? d.card.brand.toUpperCase() : "N/A"}
          </td>
          <td className={classes.notesTableCell}>{d["Transaction ID"]}</td>
          <td className={classes.notesTableCell}>${d.paymentTotal}</td>
          <td className={classes.notesTableCell}>${d.remaining}</td>
          <td className={classes.notesTableCell}>
            <div
              style={
                d.Status === "Unapplied"
                  ? { background: "#0071C5" }
                  : d.Status === "Closed"
                    ? { background: "#3F9C42" }
                    : d.Status === "Void"
                      ? { background: "#D9D9D9", color: "black" }
                      : { background: "#E0AC12", color: "black" }
                // { background: "#2EB67D" }
              }
              className={classes.statusDiv}
            >
              Paid
            </div>
          </td>
          <td className={classes.notesTableCell}>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Tooltip
                id="tooltip-top"
                title="View Details"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  style={{ background: "#FFC821" }}
                  className={classes.actionBtn}
                >
                  <EyeIcon />
                </Button>
              </Tooltip>
              <Tooltip
                id="tooltip-top"
                title="Edit Payment"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#323232" }}
                >
                  <EditIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Send Email"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#FEDA1B" }}
                >
                  <MailIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Create Refund"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{
                    background: "#929292",
                    border: "1px solid #323232",
                  }}
                >
                  <CreateRefund />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Apply Credit"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#F5EB29" }}
                >
                  <ApplyCredit />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Void Payment"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#FFFFFF" }}
                >
                  <VoidPayment />
                </Button>
              </Tooltip>
            </div>
          </td>
        </tr>
      );
    });
};

if (!payments)
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img style={{ width: "20%" }} src={loader} alt="..." />
    </div>
  );

return (
  <Card className={classes.tabCard}>
    <GridContainer>
      <GridItem className={classes.filtersGridItem} xs={1}>
        <h5 className={classes.subHeader} style={{ margin: "30px 0px" }}>Sort By</h5>
      </GridItem>
      <GridItem className={classes.filtersGridItem} xs={7}>
        <Select
          style={{ fontWeight: 400 }}
          className={classes.filterMenu}
          value={sortBy}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => setSortBy(e.target.value)}
        >
          {sortByOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          className={classes.ascendingMenu}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          value={directionSelection}
          onChange={(e) => setDirectionSelection(e.target.value)}
        >
          {directionOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          className={classes.filterMenu}
          value={filterBySelection}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          onChange={(e) => setFilterBySelection(e.target.value)}
        >
          {filterOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>
      <GridItem className={classes.filtersGridItem} xs={4}>
        <TextField
          className={classes.searchBar}
          placeholder="Search Payments"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          variant="outlined"
          InputProps={{
            classes: {
              adornedEnd: classes.adournedEnd,
            },
            endAdornment: (
              <InputAdornment className={classes.adournedEnd} position="end">
                <Button className={classes.searchButton}>Search</Button>
              </InputAdornment>
            ),
          }}
        />
        <Button className={classes.createInvoiceButton} style={{ width: "25%", margin: "0px 0px 0px 17px" }}>Export CSV</Button>
      </GridItem>

      <GridItem xs={12}>
        <div
          style={
            pageDropdown != 8 && payments.length > 8
              ? { height: "500px", width: "100%", overflowY: "scroll" }
              : null
          }
        >
          <table
            style={{ marginTop: "30px" }}
            className={classes.inventoryTable}
          >
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
        {pageDropdown == "8" ? (
          <GridContainer style={{ paddingTop: "10px" }}>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={
                      {
                        // className: classes.selectMenu,
                      }
                    }
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4}>
              <h5
                className={classes.indexes}
              >
                {index + 1} - {endIndex} of{" "}
                {Math.ceil(payments.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(payments.length / itemsPerPage)}
                nextLabel={
                  <ArrowRight
                    style={{
                      fontSize: 25,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-8px",
                    }}
                  />
                }
                previousLabel={
                  <ArrowLeft
                    style={{
                      fontSize: 25,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-8px",
                    }}
                  />
                }
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "10%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={
                      {
                        // className: classes.selectMenu,
                      }
                    }
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  </Card>
);
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(AccountingPaymentsTab));
