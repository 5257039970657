import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css";
import { firebase, db } from "firebase/client";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";
import { MenuItem, Select } from "@material-ui/core";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import Button from "@material-ui/core/Button";
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    ContentState,
    createWithContent,
    convertToRaw,
    convertFromRaw,
    convertFromHtml,
    convertFromHTML
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function TermsOfUse(props) {
    const { classes, history, reservationsData, user, terms } = props;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        db.collection('terms-and-conditions').doc('terms-and-conditions')
            .get()
            .then((snapshot) => {
                setEditorState(EditorState.createWithContent(
                    ContentState.createFromBlockArray(convertFromHTML(snapshot.data().termsHtml))
                ))
            })
    }, [])

    const handleSubmit = () => {
        db.collection('terms-and-conditions').doc('terms-and-conditions')
            .update({
                termsHtml: draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                )
            })
            .then(()=> {
                window.location.reload()
            })
    }

    return (
        <GridContainer>
            <GridItem xs={6}>
                <h5 className={classes.title} style={{ whiteSpace: "noWrap" }}>Load Types</h5>
            </GridItem>
            <GridItem xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    className={classes.addCustomer}
                    style={{
                        margin: "-2px 0px 5px 0px",
                        height: "42px",
                        width: "30%",
                    }}
                    onClick={() => handleSubmit()}
                    >
                    <CheckIcon style={{ marginRight: "5px" }} /> Save Changes
                </Button>
            </GridItem>
            <GridItem xs={12}>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={(state) => setEditorState(state)}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    editorStyle={{ border: "1px solid grey", height: "500px" }}
                    toolbarStyle={{ border: "1px solid grey" }}
                />
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        reservationsData: state.reservations.reservations,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(rentalsStyles)
)(React.memo(TermsOfUse));
