import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customersStyle from "assets/jss/material-dashboard-pro-react/views/customersStyle.js";
import { CSVLink, CSVDownload } from "react-csv";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

const selectOptions = ["First Name", "Last Name", "Company", "Email", "Billing Address", "Industry Type"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By", "Paid", "Overdue", "Unpaid", "New", "Regular"];
const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Comments", key: "comments" },
  { label: "Company", key: "company" },
  { label: "Customer Address", key: "billingAddress" },
  { label: "Customer ID", key: "customerId" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phoneNumber" },
];

function Filters(props) {
  const {
    classes,
    sortBySelection,
    setSortBySelection,
    setDirectionSelection,
    directionSelection,
    setFilterSelection,
    filterSelection,
    setSearchString,
    searchString,
    handleFilters,
    handleSearch,
    handleCommentFilter,
    customersData,
  } = props;

  return (
    <div>
      <GridContainer>
        <GridItem className={classes.filtersGridItem} xs={1}>
          <h5 className={classes.subHeader} style={{whiteSpace:"noWrap"}}>Sort By</h5>
        </GridItem>
        <GridItem className={classes.filtersGridItem} xs={4}>
          <Select
            className={classes.selectMenuSort}
            value={filterSelection}
            disableUnderline
            classes={{
              // root: classes.selectText,
            }}
            onChange={(e) => handleFilters("filter", e.target.value)}
          >
            {selectOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          {/* <Select
            className={classes.ascMenu}
            disableUnderline
            classes={{
              // root: classes.selectText,
            }}
            value={directionSelection}
            onChange={(e) => handleFilters("direction", e.target.value)}
          >
            {directionOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select> */}

          <Select

            className={classes.filterMenu}
            value={sortBySelection}
            disableUnderline
            classes={{
              // root: classes.selectText,
            }}
            onChange={(e) => handleFilters("comments", e.target.value)}
          >
            {filterOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </GridItem>
        <GridItem className={classes.filtersGridItemWithCsvDownload} xs={7} style={{justifyContent:"flex-end"}}>
          <TextField
          style={{width:"64%", marginRight:"20px"}}
            className={classes.searchBar}
            placeholder="Search Customers"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            variant="outlined"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSearch();
              }
            }}
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
              endAdornment: (
                <InputAdornment className={classes.adournedEnd} position="end">
                  <Button
                    onClick={handleSearch}
                    className={classes.searchButton}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <CSVLink
            filename={"Customer_Data"}
            data={customersData.map((x) => ({
              name: x.name,
              comments: x.comments,
              company: x.company,
              billingAddress: x.billingAddress,
              customerId: x.customerId,
              email: x.email,
              phoneNumber: x.phoneNumber,
            }))}
            headers={csvHeaders}
          >
            <Button style={{
              width: "100%",
               background: "#323232",
               "&:hover": {
                background: "#323232",
              },
              color:"white"
            }}
            className={classes.addCustomer}>
              Export CSV
            </Button>
          </CSVLink>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customersStyle)
)(React.memo(Filters));
