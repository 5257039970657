import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.js";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIconBlack.svg";
import { ReactComponent as CheckIcon } from "assets/img/checkIconGreenBg.svg";
import {ReactComponent as EditIcon } from "assets/img/editIconSmall.svg";
import { BsFillCircleFill } from "react-icons/bs";
import moment from 'moment'
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const formatDate = (value) => {
  if (value) {
    return moment(value).format("MM/DD/YY");
  }
}

function CustomerDetails(props) {
  const { classes, customerInfo, history, handleTabSwitch } = props;
  const [fullAddress, setFullAddress] = useState("");

  useEffect(() => {
    if (customerInfo.billingAddress === undefined) {
      let combine =
        customerInfo.address +
        "," +
        customerInfo.city +
        "," +
        customerInfo.state +
        " " +
        customerInfo.zip;
      setFullAddress(combine);
    }
  }, []);

  return (
    <GridContainer>
      <GridItem xs={9}>
        <Card className={classes.customerInfoLeftCard}>
          <GridContainer className={classes.mainGrid}>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Customer Number</span>
              <span className={classes.categoryValue}>
                {customerInfo.customerId}
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Has Reviewed Already?</span>
              <span className={classes.categoryValue}>
                Yes
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Industry Type</span>
              <span className={classes.categoryValue}>Commercial</span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Customer Since</span>
              <p className={classes.categoryValue}>{customerInfo.customerSince.seconds ? formatDate(new Date(customerInfo.customerSince.seconds * 1000)) : customerInfo.customerSince}</p>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Company</span>
              <span className={classes.categoryValue}>
                {customerInfo.company || 'N/A'}
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Customer Receives</span>
              <span className={classes.categoryValue} style={{ whiteSpace: "noWrap" }}>
                <CheckIcon /> Invoices  {" "} <DenyIcon /> Reminder
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Heard About Us</span>
              <span className={classes.categoryValue}>
                Friend
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Communicate Via</span>
              <span className={classes.categoryValue}>Email</span>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Billing Address</span>
              <span className={classes.categoryValue}>
                {customerInfo.billingAddress? customerInfo.billingAddress
                : fullAddress}
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Emails
              <Button style={{marginTop:"-5px", marginLeft:"-10px"}}>
              <EditIcon/>
              </Button>
              </span>
              <span className={classes.categoryValue}>
                {customerInfo.email || customerInfo.emails}
              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Phone (Primary)</span>
              <span className={classes.categoryValue}>
                {customerInfo.phoneNumber? "(" +customerInfo.phoneNumber.slice(0,3) +") " +
                customerInfo.phoneNumber.slice(3,6) + "-" +
                customerInfo.phoneNumber.slice(6,10) :"N/A"}

              </span>
            </GridItem>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={3}
            >
              <span className={classes.categoryTitle}>Phone (Secondary)</span>
              <span className={classes.categoryValue}>
                {customerInfo.secondaryPhoneNumber
                  ? customerInfo.secondaryPhoneNumber
                  : "N/A"}
              </span>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
      <GridItem xs={3}>
        <Card className={classes.customerInfoRightCard}>
          <GridContainer className={classes.mainGrid}>
            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={12}
            >
              <span className={classes.categoryTitle}>Credit Memos</span>
              <span className={classes.categoryValueUnderline}
                onClick={() => handleTabSwitch('creditMemos')}
              >
                2 Remaining
              </span>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={12}
            >
              <span className={classes.categoryTitle}>
                credit/debit cards on files
              </span>
              <span className={classes.categoryValueUnderline}
                onClick={() =>
                  history.push({
                    pathname: `/admin/customers/${customerInfo.customerId}`,
                    state: { tabIndex: 5 },
                  })}
              >
                0 Cards Saved</span>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={12}
            >
              <span className={classes.categoryTitle}>Total Rentals</span>
              <span className={classes.categoryValueUnderline}
                onClick={() => handleTabSwitch("rentals")}
              >4 Rentals</span>
            </GridItem>

            <GridItem
              className={classes.column + " " + classes.addedPadding}
              xs={12}
            >
              <span className={classes.categoryTitle} >Unpaid Invoices</span>
              <span className={classes.categoryValue} style={{ color: "red", fontSize: "24px", fontWeight: 700 }}>0</span>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,

  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(CustomerDetails));
