import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Card from "components/Card/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ReactPaginate from "react-paginate";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

const headers = [
  "Name",
  "Shortcode",
  "Description",
  "Account",
  "Rate",
  "Taxable By Default",
  "Surcharge",
  "Actions",
];
const pageArr = ["3", "All"];
const mockData = [
  {
    name: "asbestos yardage charge",
    shortcode: "asb",
    description: "---",
    account: "Sales",
    rate: "$40.00",
    taxable: "NO",
    surcharge: "NO",
  },
  {
    name: "Environmental Fee",
    shortcode: "env",
    description: "---",
    account: "Accounts Receivable (A/R)",
    rate: "$12.00",
    taxable: "NO",
    surcharge: "NO",
  },
  {
    name: "Extra Day",
    shortcode: "ext",
    description: "Extra Day",
    account: "Sales",
    rate: "$25.00",
    taxable: "NO",
    surcharge: "NO",
  },
  {
    name: "Extra Week",
    shortcode: "ext",
    description: "Extra Day",
    account: "Sales",
    rate: "$100.00",
    taxable: "NO",
    surcharge: "NO",
  },
  {
    name: "Overweight",
    shortcode: "over",
    description: "tons overweight",
    account: "Sales",
    rate: "$80.00",
    taxable: "NO",
    surcharge: "NO",
  },
];

function MiscChargesTable(props) {
  const { classes, history } = props;

  const itemsPerPage = 3;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("3");

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    let data;
    if (pageDropdown == "5") {
      data = mockData.slice(
        pageNumber * itemsPerPage,
        pageNumber * itemsPerPage + itemsPerPage
      );
    } else {
      data = mockData;
    }
    return mockData.map((d, index) => {
      console.log("dddd", d.status);
      return (
        <tr className={classes.tableRow} key={index}>
          <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
            {d.name}
          </td>
          <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
            {d.shortcode}
          </td>
          <td className={classes.tableCell}>{d.description}</td>
          <td className={classes.tableCell}>{d.account}</td>

          <td className={classes.tableCell}>{d.rate}</td>
          <td className={classes.tableCell}>{d.taxable}</td>
          <td className={classes.tableCell}>{d.surcharge}</td>
          <td className={classes.tableCell}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <Tooltip
                id="tooltip-top"
                title="Edit"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <div
                  // onClick={() => handleModalOpen("Edit", d)}
                  style={{ background: "#323232", width: "50%" }}
                  className={classes.pendingButtonDiv}
                >
                  <WhiteEditIcon />
                </div>
              </Tooltip>
              <Tooltip
                id="tooltip-top"
                title="Deny"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <div
                  style={{ background: "#FEDA1B", width: "50%" }}
                  className={classes.pendingButtonDiv}
                  // onClick={() => handleModalOpen("Deny", d)}
                >
                  <BlackDenyIcon />
                </div>
              </Tooltip>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <GridContainer>
      <GridItem className={classes.justifyEnd} xs={12}>
        {" "}
        <Button
          style={{ background: "#FFC821", color: "#323232", width: "15%" }}
          className={classes.addEditButton}
          // onClick={() =>
          //   setMaintenanceSchedule([
          //     ...maintenanceSchedule,
          //     {
          //       timeFrame: "",
          //       description: "",
          //     },
          //   ])
          // }
        >
          {" "}
          <AddIcon />
          Add Misc. Charge
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <div
          style={
            pageDropdown != 5 && mockData.length > 5
              ? { height: "500px", width: "100%", overflowY: "scroll" }
              : null
          }
        >
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>

        {pageDropdown == "3" ? (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "13%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginTop: "5px" }}>
              <h5
                className={classes.indexes}
              >
                {index + 1} - {endIndex} of{" "}
                {Math.ceil(mockData.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(mockData.length / itemsPerPage)}
                nextLabel={
                  <ArrowRight
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                previousLabel={
                  <ArrowLeft
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "13%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(MiscChargesTable));
