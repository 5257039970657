import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import { db, firebase, storage } from "firebase/client.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import QuoteDetailHeader from "./components/QuoteDetailHeader";
import QuoteType from "./components/QuoteType";
import QuoteCustomerCard from "./components/QuoteCustomerCard";
import QuoteJobCard from "./components/QuoteJobCard";
import loader from "assets/img/loader.gif";
import Dialog from "@material-ui/core/Dialog";
import EditQuoteModal from "./components/EditQuoteModal";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function QuoteDetail(props) {
  const { classes, history, inventoryData } = props;
  // const [editReservationModal, setEditReservationModal] = useState(false);
  // // const job = props.location.state.job;
  const [quote, setQuote] = useState(false);
  const quoteID = window.location.pathname.split("/")[3];
  const [modalOpen, setModalOpen] = useState(false);
  const [editErrors, setEditErrors] = useState({
    phoneNumber: false,
    billingAddress: false,
    siteAddress: false,
    startDate: false,
    endDate: false,
    equiptmentNeeded: false,
    equipmentLocation: false,
    price: false,
  });
  const [editHasErrors, setEditHasErros] = useState(false);
  const [quoteSnackBarContent, setQuoteSnackBarContent] = useState("");

  useEffect(() => {
    if (!quote) {
      const getQuote = async () => {
        return db
          .collection("quotes")
          .doc(quoteID)
          .onSnapshot((snapshot) => {
            const quoteData = snapshot.data();
            setQuote(quoteData);
          });
      };
      getQuote();
    }
  }, []);

  if (!quote)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );
  return (
    <GridContainer>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <QuoteDetailHeader
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          quote={quote}
          history={history}
        />
      </GridItem>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <QuoteType quote={quote} history={history} />
      </GridItem>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <QuoteCustomerCard quote={quote} history={history} />
      </GridItem>
      <GridItem className={classes.marginBottomGridItem} xs={12}>
        <QuoteJobCard quote={quote} history={history} />
      </GridItem>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModalOpen(false)}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <EditQuoteModal
          editErrors={editErrors}
          editHasErrors={editHasErrors}
          setEditHasErrors={setEditErrors}
          inventoryData={inventoryData}
          handleModalClose={() => setModalOpen(false)}
          selectedPendingReservation={quote}
          setModalEditedSuccessful={setQuoteSnackBarContent}
        />
      </Dialog>

      <Snackbar
        open={quoteSnackBarContent}
        onClose={() => setQuoteSnackBarContent(false)}
      >
        <Alert
          onClose={() => setQuoteSnackBarContent(false)}
          severity={"success"}
        >
          {quoteSnackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(QuoteDetail));
