import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Card from "components/Card/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import { reportSelections } from "../../config/globals";

const headers = [
  "Date",
  "Type",
  "Subtotal",
  "Discount",
  "Tax Rate",
  "Nontaxable",
  "Taxable",
  "Tax",
  "Total",
];

const sortByOptions = ["Total Sales", "Scheduled Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];

const viewOptions = ["Yesterday", "Today", "This Month", "This Year"];

function GrossSalesTable(props) {
  const { classes, DepositSummaryData, history } = props;
  const [tableSelection, setTableSelection] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [type, setType] = useState("Today");

  return (
    <Card style={{ padding: "15px" }}>
      <GridContainer className={classes.searchGridContainerNoScroll}>
        <GridItem xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "20px",
              maxHeight: "80px",
            }}
          >
            <div style={{ width: "43%" }} className={classes.agingGridItem}>
              <span
                className={classes.switchReport}
                style={{
                  // marginRight: "10px"
                }}
              >
                Switch Report</span>
              <Select
                variant="outlined"
                className={classes.summaryFilterMenu}
                value={tableSelection}
                sx={{ background: "#F5F5F5", }}
                disableUnderline
                defaultValue={"Select Option"}
                classes={{
                  root: classes.selectText,
                }}
                onChange={(e) => history.push(e.target.value.link)}
                displayEmpty
                renderValue={
                  tableSelection !== "" ? undefined : () => "Deposit Summary"
                }
              >
                {reportSelections.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItemReport,
                      }}
                    >
                      {o.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div
              style={{ justifyContent: "end" }}
              className={classes.agingGridItem}
            >
              <div
                style={{
                  display: "flex",
                  borderTop: "1px solid #323232",
                  borderRadius: "5px",
                  background: "#323232",
                  width: "80%",
                  height: "60px",
                  fontSize: "15px",
                  whiteSpace: "noWrap"
                }}
              >
                {viewOptions.map((v, index) => {
                  if (v === type) {
                    return (
                      <div
                        key={index}
                        onClick={() => setType(v)}
                        className={classes.viewSelection}
                        style={{
                          background: "#323232",
                          color: "white",
                          padding: "10px",
                          fontSize: "15px",
                        }}
                      >
                        {v}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        onClick={() => setType(v)}
                        className={classes.viewSelection}
                        style={{
                          background: "white",
                          color: "#323232",
                          padding: "10px",
                          fontSize: "16px",
                        }}
                      >
                        {v}
                      </div>
                    );
                  }
                })}
              </div>
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "50%",
                  margin: "20px",
                }}
                selected={fromDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setFromDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      style={{ marginLeft: "30px", width: "80%" }}
                      className={classes.datetime}
                      placeholder="From"
                      value={fromDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Datetime
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "50%",
                  margin: "20px",
                }}
                selected={toDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  setToDate(date.format("MM/DD/YY"));
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
                renderInput={(props, openCalendar, closeCalendar) => (
                  <div onClick={openCalendar}>
                    <TextField
                      style={{ marginLeft: "25px", width: "80%" }}
                      className={classes.datetime}
                      placeholder="To"
                      value={toDate}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          adornedEnd: classes.adournedEnd,
                        },
                        endAdornment: (
                          <InputAdornment
                            className={classes.adournedEnd}
                            position="end"
                          >
                            <CalendarIcon
                              className={classes.calendarIcon}
                              onClick={openCalendar}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
              />
              <Button
                style={{
                  width: "25%",
                  margin: "5px 5px 5px 20px",
                }}
                className={classes.exportCSV}
              >
                Export CSV
              </Button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "20px",
              maxHeight: "80px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h5 className={classes.agingText} style={{ padding: "10px 20px" }}>
                Deposit Total ($2,316,931.05)
              </h5>
            </div>
          </div>

          <GridContainer className={classes.depositSummaryGridContainer}>
            <GridItem className={classes.depositSummaryGridItem} xs={12}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  padding: "25px",
                  border: "0.5px solid #323232",
                }}
              >
                <span
                  style={{ fontWeight: 700 }}
                  className={classes.depositSummaryText}
                >
                  Total
                </span>
                <span
                  style={{ fontWeight: 700 }}
                  className={classes.depositSummaryTextCenter}
                >
                  $2,316,931.05
                </span>
                <span
                  style={{ fontWeight: 700 }}
                  className={classes.depositSummaryTextRight}
                >
                  5,944 Transactions
                </span>
              </div>
            </GridItem>
            {DepositSummaryData.map((d, index) => {
              return (
                <GridItem
                  className={classes.depositSummaryGridItem}
                  key={index}
                  xs={6}
                >
                  <div
                    style={index % 2 !== 0 ? {
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      padding: "25px",
                      border: "0.5px solid #929292",
                      borderLeft: "0px",
                      borderTop: "0px"
                    } : {
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      padding: "25px",
                      border: "0.5px solid #929292",
                      borderTop: "0px"
                    }}
                  >
                    <span className={classes.depositSummaryText}>{d.type}</span>
                    <span className={classes.depositSummaryTextCenter}>
                      {d.amount}
                    </span>
                    <span className={classes.depositSummaryTextRight}>
                      {d.transactionCount} Transactions
                    </span>
                  </div>
                </GridItem>
              );
            })}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(GrossSalesTable));
