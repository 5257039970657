import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";

import NewForm from "./components/NewForm";

function ReservationForm(props) {
  const { classes } = props;
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const [emailString, setEmailString] = useState("");
  const [searchingCustomers, setSearchingCustomers] = useState(false);
  const [didntFindCustomerError, setDidntFindCustomerError] = useState(false);
  const [businessError, setBusinessError] = useState("");
  const [individualError, setIndividualError] = useState("");
  const [customersLoaded, setCustomersLoaded] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerInfo, setCustomerInfo] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [BusinessOrIndividual, setBusinessOrInBusinessOrIndividual] = useState(
    ""
  );
  const [pin, setPin] = useState("");
  const [businesses, setBusinesses] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  const [errors, setErrors] = useState({
    emailString: false,
    pin: false,
  });
  const [returningCustomerAlert, setReturningCustomerAlert] = useState(false);
  const [territoriesData, setTerritoriesData] = useState(false);
  const [textOrEmail, setTextOrEmail] = useState({
    text: false,
    email: false,
  });
  const [communicationPreference, setCommunicationPreference] = useState(
    "Email"
  );
  console.log("territoriesData", territoriesData);

  useEffect(() => {
    if (!territoriesData) {
      const fetchData = async () => {
        return db
          .collection("territories")
          .get()
          .then((snapshot) => {
            const territories = snapshot.docs.map((d) => d.data());
            setTerritoriesData(territories);
          });
      };
      fetchData();
    }
  }, [territoriesData]);

  useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        return db
          .collection("inventory")
          .orderBy("sortOrder")
          .get()
          .then((snapshot) => {
            const inventory = snapshot.docs.map((d) => d.data());
            setInventoryData(inventory);
            setDataLoaded(true);
          });
      };
      fetchData();
    }
  }, [dataLoaded]);

  useEffect(() => {
    if (!customersLoaded) {
      const fetchData = async () => {
        return db
          .collection("customers")
          .get()
          .then((snapshot) => {
            console.log("snapshot", snapshot);
            const customerSnap = snapshot.docs.map((d) => d.data());
            setCustomers(customerSnap);
            setCustomersLoaded(true);
          });
      };
      fetchData();
    }
  }, [customersLoaded]);

  return (
    <NewForm
      customers={customers}
      setIndividualError={setIndividualError}
      inventoryData={inventoryData}
      setReturningCustomerAlert={setReturningCustomerAlert}
      returningCustomerAlert={returningCustomerAlert}
      territoriesData={territoriesData}
      textOrEmail={textOrEmail}
      setTextOrEmail={setTextOrEmail}
      communicationPreference={communicationPreference}
      setCommunicationPreference={setCommunicationPreference}
    />
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(ReservationForm));
