import { firebase, db } from "firebase/client";

const driversCollection = db.collection("drivers");

export const listenToDrivers = () => {
  return (dispatch) => {
    return driversCollection.onSnapshot((snapshot) => {
      const drivers = snapshot.docs.map((d) => d.data());
      dispatch(setDrivers(drivers));
    });
  };
};

export const setDrivers = (drivers) => {
  return {
    type: "SET_DRIVERS",
    drivers,
  };
};
