import { firebase, db } from "firebase/client";

const quotesCollection = db.collection("quotes");

export const createReservationFromQuotePreviousCustomer = (
  quote,
  handleModalClose,
  setModalEditedSuccessful,
  userInfo
) => {
  return async (dispatch) => {
    const reservationId = db.collection("reservations").doc().id;
    const invoiceId = db.collection("invoices").doc().id;

    await db
      .collection("reservations")
      .doc(reservationId)
      .set({
        customerId: quote.customerId,
        reservation: {
          ...quote.reservation,
          jobs: {
            pickup: { driverId: "", driverName: "N/A" },
            delivery: { driverId: "", driverName: "N/A" },
          },
          invoiceId,
          reservationId,
        },
      });

    const quoteCopyWithoutReservation = { ...quote };
    delete quoteCopyWithoutReservation.reservation;
    delete quoteCopyWithoutReservation.quoteTimestamp;
    delete quoteCopyWithoutReservation.quoteId;
    await db
      .collection("customers")
      .doc(quote.customerId)
      .update({
        invoices: [...quote.invoices, invoiceId],
        reservations: [...quote.reservations, reservationId],
      });

    let termsAndConditionsSnap = await db
      .collection("terms-and-conditions")
      .doc("terms-and-conditions")
      .get();

    const termsAndConditionsData = termsAndConditionsSnap.data();
    const getServiceArr = () => {
      if (typeof quote.reservation.equiptment === "object") {
        return Object.entries(quote.reservation.equiptment).map(
          ([key, quantity]) => {
            let name = "No equipment found";
            switch (key) {
              case "tenYard":
                name = "10 Yard Container";
                break;
              case "fifteenYard":
                name = "15 Yard Container";
                break;
              case "twentyYard":
                name = "20 Yard Container";
                break;
              case "thirtyYard":
                name = "30 Yard Container";
                break;
              case "metalOrConcrete":
                name = "Metal or Concrete";
                break;
              case "standardThirty":
                name = "Standard 30 Yard";
                break;
            }
            return {
              serviceInfo: { ...quote.reservation },
              rate: quote.reservation.amount,
              description: "N/A",
              type: "reservation",
              name,
              quantity,
              agreedToTermsOfService: true,
            };
          }
        );
      } else {
        return [
          {
            serviceInfo: { ...quote.reservation },
            rate: quote.reservation.amount,
            description: "N/A",
            type: "reservation",
            name: quote.reservation.equiptment,
            quantity: quote.reservation.quantity,
            agreedToTermsOfService: true,
          },
        ];
      }
    };
    await db
      .collection("invoices")
      .doc(invoiceId)
      .set({
        services: getServiceArr(),
        customerId: quote.customerId,
        invoiceId,
        servicesTotal: quote.reservation.amount,
        invoiceStatus: "Draft",
        reservationId,
        invoiceDetails: {
          paymentStatus: "",
          paymentMethod: "",
          paymentId: "",
        },
        attachments: [],
        internalMemo: "",
        emailed: "",
        invoiceDate: firebase.firestore.Timestamp.fromDate(new Date()),
        termsAndConditions: termsAndConditionsData.termsHtml,
        clerk: userInfo.firstName[0] + userInfo.lastName[0],
        recurring: false,
        siteId: "",
        discount: "",
      });
    await quotesCollection.doc(quote.quoteId).delete();
    handleModalClose();
    setModalEditedSuccessful("Successfully Converted Quote To Reservation");
  };
};

export const createReservationFromQuote = (
  quote,
  handleModalClose,
  setModalEditedSuccessful,
  userInfo
) => {
  return async (dispatch) => {
    const customerId = db.collection("customers").doc().id;
    const reservationId = db.collection("reservations").doc().id;
    const invoiceId = db.collection("invoices").doc().id;

    await db
      .collection("reservations")
      .doc(reservationId)
      .set({
        customerId,
        reservation: {
          ...quote.reservation,
          jobs: {
            pickup: { driverId: "", driverName: "N/A" },
            delivery: { driverId: "", driverName: "N/A" },
          },
          invoiceId,
          reservationId,
        },
      });

    const quoteCopyWithoutReservation = { ...quote };
    delete quoteCopyWithoutReservation.reservation;
    delete quoteCopyWithoutReservation.quoteTimestamp;
    delete quoteCopyWithoutReservation.quoteId;
    await db
      .collection("customers")
      .doc(customerId)
      .set({
        ...quoteCopyWithoutReservation,
        invoices: [invoiceId],
        reminders: [],
        reservations: [reservationId],
        customerSince: firebase.firestore.Timestamp.now(),
        customerId,
        accountingStatus: "Unpaid",
      });

    let termsAndConditionsSnap = await db
      .collection("terms-and-conditions")
      .doc("terms-and-conditions")
      .get();

    const termsAndConditionsData = termsAndConditionsSnap.data();
    const getServiceArr = () => {
      if (typeof quote.reservation.equiptment === "object") {
        return Object.entries(quote.reservation.equiptment).map(
          ([key, quantity]) => {
            let name = "No equipment found";
            switch (key) {
              case "tenYard":
                name = "10 Yard Container";
                break;
              case "fifteenYard":
                name = "15 Yard Container";
                break;
              case "twentyYard":
                name = "20 Yard Container";
                break;
              case "thirtyYard":
                name = "30 Yard Container";
                break;
              case "metalOrConcrete":
                name = "Metal or Concrete";
                break;
              case "standardThirty":
                name = "Standard 30 Yard";
                break;
            }
            return {
              serviceInfo: { ...quote.reservation },
              rate: quote.reservation.amount,
              description: "N/A",
              type: "reservation",
              name,
              quantity,
              agreedToTermsOfService: true,
            };
          }
        );
      } else {
        return [
          {
            serviceInfo: { ...quote.reservation },
            rate: quote.reservation.amount,
            description: "N/A",
            type: "reservation",
            name: quote.reservation.equiptment,
            quantity: quote.reservation.quantity,
            agreedToTermsOfService: true,
          },
        ];
      }
    };
    await db
      .collection("invoices")
      .doc(invoiceId)
      .set({
        services: getServiceArr(),
        customerId,
        invoiceId,
        servicesTotal: quote.reservation.amount,
        invoiceStatus: "Draft",
        reservationId,
        invoiceDetails: {
          paymentStatus: "",
          paymentMethod: "",
          paymentId: "",
        },
        attachments: [],
        internalMemo: "",
        emailed: "",
        invoiceDate: firebase.firestore.Timestamp.fromDate(new Date()),
        termsAndConditions: termsAndConditionsData.termsHtml,
        clerk: userInfo.firstName[0] + userInfo.lastName[0],
        recurring: false,
        siteId: "",
        discount: "",
      });
    await quotesCollection.doc(quote.quoteId).delete();
    handleModalClose();
    setModalEditedSuccessful("Successfully Converted Quote To Reservation");
  };
};

export const deleteQuote = (
  quote,
  handleModalClose,
  setModalEditedSuccessful
) => {
  return (dispatch) => {
    quotesCollection
      .doc(quote.quoteId)
      .delete()
      .then(() => {
        handleModalClose();
        setModalEditedSuccessful("Successfully Deleted Quote");
      });
  };
};

export const updateQuote = (
  quote,
  handleModalClose,
  setModalEditedSuccessful
) => {
  return (dispatch) => {
    quotesCollection
      .doc(quote.quoteId)
      .update({
        ...quote,
      })
      .then(() => {
        handleModalClose();
        setModalEditedSuccessful("Successfully Updated Quote");
      });
  };
};

export const addQuote = (quote) => {
  return (dispatch) => {
    const quoteId = db.collection("quotes").doc().id;
    const heardAboutUsFromChoice = quote.reservation.heardAboutUsFrom;
    quotesCollection
      .doc(quoteId)
      .set({
        quoteId,
        ...quote,
      })
      .then(async () => {
        const referralSnap = await db
          .collection("referrals")
          .doc("referrals-doc")
          .get();
        const referralData = referralSnap.data();
        referralData[heardAboutUsFromChoice] =
          referralData[heardAboutUsFromChoice] + 1;
        referralData.totalCount = referralData.totalCount + 1;
        await db
          .collection("referrals")
          .doc("referrals-doc")
          .update({
            ...referralData,
          });
      });
  };
};

export const listenToQuotes = () => {
  return (dispatch) => {
    quotesCollection.orderBy("quoteTimestamp").onSnapshot((snapshot) => {
      const quotes = snapshot.docs.map((d) => d.data());
      dispatch(setQuotes(quotes));
    });
  };
};

export const setQuotes = (quotes) => {
  return {
    type: "SET_QUOTES",
    quotes,
  };
};
