import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { ReactComponent as MailIcon } from "assets/img/mailIcon.svg";

const headers = [
  "",
  "Invoice ID",
  "Billing Address",
  "Start Date",
  "Invoice Date",
  "Amount",
  "Remaining",
  "Emailed",
  "Status",
  "Actions",
];

function CustomerViewInvoicesTables(props) {
  const { 
    classes, 
    reservations, 
    history, 
    pageDropdown, 
    pageNumber, 
    itemsPerPage ,
    invoicesState,
    setCheckedCount,
    setInvoicesState,
    checkedAll
  } = props;

  useEffect(() => {
    if (checkedAll) {
      setInvoicesState(
        invoicesState.map((i) => {
          i.checked = true;
          return i;
        })
      );
      setCheckedCount(invoicesState.length);
    } else {
      setInvoicesState(
        invoicesState.map((i) => {
          i.checked = false;
          return i;
        })
      );
      setCheckedCount(0);
    }
  }, [checkedAll]);
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "38px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key} style={{ fontSize: "14px" }}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    let data;
    if (pageDropdown == "4") {
      data = invoicesState.slice(
        pageNumber * itemsPerPage,
        pageNumber * itemsPerPage + itemsPerPage
      );
    } else {
      data = invoicesState;
    }
    return data.map((d, index) => {
      // console.log("checking data tables", d)
      const invoicePaymentStatus =
        d.Status === "Paid"
          ? "Paid"
          : d.Status === "Void"
          ? "Void"
          : "Unpaid";

          console.log('ddd', d)
      // const newDate =  d["Date Due"].toString()
      // const paymentDateString = moment(newDate).format(
      //   "MM/DD/YY"
      // );
      return (
        <tr className={classes.notesTableRow} key={d.invoiceId}>
          <td className={classes.notesTableCell}>
            <Checkbox
              style={{ color: "#FEDA1B" }}
              // classes={{
              //   root: marginTop,
              //   disabled: classes.disabled,
              // }}
              onChange={() => {
                let newState = [...invoicesState];
                if (newState[index].checked) {
                  newState[index].checked = false;
                  setCheckedCount((prevState) => prevState - 1);
                } else {
                  newState[index].checked = true;
                  setCheckedCount((prevState) => prevState + 1);
                }
                setInvoicesState([...newState]);
              }}
              id={index}
              checked={d.checked}
            />
          </td>
          <td
            style={{ color: "#0071C5", textDecoration: "underline" }}
            className={classes.notesTableCell}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <span
                onClick={() =>
                  history.push({
                    pathname: `/admin/invoice/${d.invoiceId}`,
                    state: {
                      invoice: d,
                    },
                  })
                }
              >
                {" "}
                {d.invoiceId}
              </span>
              <span
                onClick={() =>
                  history.push({
                    pathname: `/admin/customers/${d["Customer ID"]}`,
                  })
                }
              >
                {" "}
                {d["Customer Name"]}
              </span>
            </div>
          </td>
          <td className={classes.notesTableCell}>
            {d.billingAddress}
          </td>
          <td className={classes.notesTableCell}>
            {/* {paymentDateString} */} 03/10/2023
            </td>
          <td className={classes.notesTableCell}> {d.paymentTotal}</td>
          <td className={classes.notesTableCell}>
            {" "}
            ${invoicePaymentStatus === "Paid" ? 0 : d.remaining}
          </td>
          <td className={classes.notesTableCell}>N/A</td>
          <td className={classes.notesTableCell}>N/A</td>
          <td className={classes.notesTableCell}>
            <div
              style={
                invoicePaymentStatus === "Overdue"
                  ? { background: "#F83030" }
                  : invoicePaymentStatus === "Paid"
                  ? { background: "#2EB67D" }
                  : invoicePaymentStatus === "Void"
                  ? { background: "#D9D9D9", color: "#323232" }
                  : { background: "#FFC821", color: "#323232" }
              }
              className={classes.statusDiv}
            >
              {invoicePaymentStatus}
            </div>
          </td>
          <td className={classes.notesTableCell}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <Tooltip
                id="tooltip-top"
                title="View Invoice"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  onClick={() =>
                    history.push({
                      pathname: `/admin/invoice/${d.invoiceId}`,
                      state: {
                        invoice: d,
                      },
                    })
                  }
                  style={{ background: "#FFC821" }}
                  className={classes.actionBtn}
                >
                  <EyeIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Email Invoice"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  className={classes.actionBtn}
                  style={{ background: "#323232" }}
                >
                  <MailIcon />
                </Button>
              </Tooltip>

              <Tooltip
                id="tooltip-top"
                title="Pay Invoice"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  onClick={() =>
                    history.push({
                      pathname: `/admin/invoice/${d.invoiceId}`,
                      state: {
                        invoice: d,
                      },
                    })
                  }
                  style={{ background: "#FEDA1B" }}
                  className={classes.actionBtn}
                >
                  <CheckIcon />
                </Button>
              </Tooltip>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <GridContainer >
      <GridItem xs={12}>
        <div style={pageDropdown != 4 && reservations.length > 4 ? { height: "550px", width: "100%", overflowY: "scroll" } : null}>
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(CustomerViewInvoicesTables));
