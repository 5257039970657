/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import addProductStyle from "assets/jss/material-dashboard-pro-react/views/addProductStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

function ItemCard(props) {
  const {
    classes,
    history,
    product,
    setProduct,
    type,
    setType,
    barcode,
    setBarcode,
    make,
    setMake,
    serialNumber,
    setSerialNumber,
    itemName,
    setItemName,
    status,
    setStatus,
    lastServiced,
    setLastServiced,
    condition,
    setCondition,
    currentLocation,
    setCurrentLocation,
    lastRental,
    setLastRental,
  } = props;

  return (
    <Card style={{ marginTop: "0px" }} className={classes.card}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Add New</span>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio
                checked={product.type}
                onChange={() => setProduct({ type: true, item: false })}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span className={classes.values}>Product Type</span>
            </div>
            <div
              style={{
                width: "45%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                checked={product.item}
                onChange={() => setProduct({ type: false, item: true })}
                value="a"
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{ root: classes.radio, checked: classes.checked }}
              />
              <span className={classes.values}>Product Item</span>
            </div>
          </div>
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={8}>
          <span className={classes.inputTypeTitle}>Product Item Type</span>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "35%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio
                checked={type.dumpster}
                onChange={() => setType({ dumpster: true, truck: false })}
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
              <span className={classes.values}>Dumpster</span>
            </div>
            <div
              style={{
                width: "45%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: "blue",
                  },
                }}
                checked={type.truck}
                onChange={() => setType({ dumpster: false, truck: true })}
                value="a"
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{ root: classes.radio, checked: classes.checked }}
              />
              <span className={classes.values}>Truck</span>
            </div>
          </div>
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Serial Number</span>
          <TextField
            value={serialNumber}
            onChange={(e) => setSerialNumber(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Barcode (Optional)</span>
          <TextField
            value={barcode}
            onChange={(e) => setBarcode(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Make (Optional)</span>
          <TextField
            value={make}
            onChange={(e) => setMake(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Item Name</span>
          <TextField
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Status</span>
          <TextField
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Last Serviced</span>
          <TextField
            value={lastServiced}
            onChange={(e) => setLastServiced(e.target.value)}
            variant="outlined"
          />
        </GridItem>




        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Condition (Optional)</span>
          <TextField
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Current Location</span>
          <TextField
            value={currentLocation}
            onChange={(e) => setCurrentLocation(e.target.value)}
            variant="outlined"
          />
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Last Rental</span>
          <TextField
            value={lastRental}
            onChange={(e) => setLastRental(e.target.value)}
            variant="outlined"
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(addProductStyle)
)(React.memo(ItemCard));
