import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import ReservationDetailHeader from "./components/ReservationDetailHeader";
import ReservationDetails from "./components/ReservationDetails";
import { db } from "firebase/client";
import loader from "assets/img/loader.gif";

function ReservationDetail(props) {
  const { classes, history } = props;
  const [editReservationModal, setEditReservationModal] = useState(false);
  const [reservation, setReservation] = useState(
    false
  );
  const [refresh, setRefresh] = useState(false)
  const [invoices, setInvoices] = useState([]);
  const [customer, setCustomer] = useState(false);
  const reservationId = window.location.pathname.split("/")[3];

  useEffect(() => {
    if (!reservation || refresh === true ) {
      const getReservationInfo = async () => {
        const promiseArray = [
          db.collection("reservations").doc(reservationId).get(),
          db.collection("pending-reservations").doc(reservationId).get(),
          db.collection("past-reservations").doc(reservationId).get(),
        ];

        const invoicePromiseArray = [];
        Promise.all(promiseArray).then((results) => {
          let reservation;
          results.forEach((snapshot) => {
            if (snapshot.exists) {
              snapshot = snapshot.data();
              if (snapshot.reservation.invoiceId) {
                invoicePromiseArray.push(
                  db
                    .collection("invoices")
                    .doc(snapshot.reservation.invoiceId)
                    .get()
                );
              }
              reservation = snapshot;
              setReservation(snapshot);
            }
          });
          Promise.all(invoicePromiseArray).then((results) => {
            const allInvoices = [];
            results.forEach((r) => {
              r = r.data();
              allInvoices.push(r);
            });
            setInvoices(allInvoices);
            return db
              .collection("customers")
              .doc(reservation.customerId)
              .get()
              .then((customerDataSnap) => {
                const customerData = customerDataSnap.data();
                setCustomer(customerData);
              });
          });
        });
      };
      getReservationInfo();
    }
  }, [refresh]);

  if (!reservation || !customer)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );
  return (
    <GridContainer>
      <GridItem xs={12}>
        <ReservationDetailHeader
          setEditReservationModal={setEditReservationModal}
          reservation={reservation}
          history={history}
          customer={customer}
        />
      </GridItem>
      <GridItem xs={12}>
        <ReservationDetails
          invoices={invoices}
          history={history}
          reservation={reservation}
          customer={customer}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(React.memo(ReservationDetail));
