import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import ReactPaginate from 'react-paginate';
import "views/Dashboard/components/pagination.css"
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";

import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import Button from "@material-ui/core/Button";

import Filters from "./Filters";
import ListViewTable from "./ListViewTable";
import { filterDataWithStatus } from "../../../../../globalHelperFunctions/globalHelperFunctions";
import { MenuItem, Select } from "@material-ui/core";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

const pageArr = ["4", "All"]

function ListView(props) {
  const { classes, history, reservationsData } = props;
  const [sortBySelection, setSortBySelection] = useState("");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [filterSelection, setFilterSelection] = useState("Rental Number");
  const [searchString, setSearchString] = useState("");
  const [reservations, setReservations] = useState([]);
  const itemsPerPage = 4;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(itemsPerPage)
  const [pageDropdown, setPageDrowdown] = useState("4")


  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage)
    setEndIndex((selected * itemsPerPage) + itemsPerPage)
  };

  useEffect(() => {
    reservations.slice(index, endIndex)
  }, [index])

  useEffect(() => {
    setReservations(reservationsData);
  }, [reservationsData]);

  const handleFilters = (type, filterValue) => {
    if (type === "filter") {
      const filtered = filterDataWithStatus(
        filterValue,
        directionSelection,
        sortBySelection,
        reservationsData
      );
      setFilterSelection(filterValue);
      setReservations([...filtered]);
    }
    if (type === "direction") {
      const filtered = filterDataWithStatus(
        filterSelection,
        filterValue,
        sortBySelection,
        reservationsData
      );
      setDirectionSelection(filterValue);
      setReservations([...filtered]);
    }
    if (type === "status") {
      const filtered = filterDataWithStatus(
        filterSelection,
        directionSelection,
        filterValue,
        reservationsData
      );
      setSortBySelection(filterValue);
      setReservations([...filtered]);
    }
  };

  const handleSearch = () => {
    if (searchString) {
      const filtered = reservations.filter((r) => {
        if (
          r.name.toLowerCase().includes(searchString.toLowerCase()) ||
          r.billingAddress.toLowerCase().includes(searchString.toLowerCase()) ||
          r.company.toLowerCase().includes(searchString.toLowerCase()) ||
          r.email.toLowerCase().includes(searchString.toLowerCase()) ||
          r.phoneNumber.toLowerCase().includes(searchString.toLowerCase()) ||
          r.reservation.reservationId
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          return true;
        }
      });
      console.log("filtered", filtered);
      setReservations([...filtered]);
    } else {
      setReservations([...reservationsData]);
    }
  };

  return (
    <Card className={classes.card}>
      <GridContainer>
        <GridItem xs={12}>
          <h5 className={classes.rentalsSubheader}>
            {reservationsData.length} Rentals
          </h5>
        </GridItem>
        <GridItem xs={12}>
          <Filters
            sortBySelection={sortBySelection}
            setSortBySelection={setSortBySelection}
            directionSelection={directionSelection}
            setDirectionSelection={setDirectionSelection}
            setFilterSelection={setFilterSelection}
            filterSelection={filterSelection}
            searchString={searchString}
            setSearchString={setSearchString}
            handleFilters={handleFilters}
            handleSearch={handleSearch}
          />
        </GridItem>
        <GridItem xs={12}>
          <ListViewTable
            history={history}
            reservations={reservations}
            pageDropdown={pageDropdown}
            itemsPerPage={itemsPerPage}
            pageNumber={pageNumber}
          />
        </GridItem>
        {pageDropdown == "4" ?
          <GridContainer style={{ paddingTop: "20px" }}>
            <GridItem xs={6} >
              <p className={classes.view}>View
                <span>
                  <Select
                    style={{ width: "10%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                    disableUnderline
                    MenuProps={{
                      // className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor
                    }}

                    onChange={(e) => {
                      setPageDrowdown(e.target.value)
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginTop: "5px" }}>
              <h5 className={classes.indexes}>{index + 1} - {endIndex} of
                {reservations.length < itemsPerPage ? " " + reservations.length :
                  " " + Math.ceil(reservations.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(reservations.length / itemsPerPage)}
                nextLabel={<ArrowRight style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                previousLabel={<ArrowLeft style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
          :
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>View
                <span>
                  <Select
                    style={{ width: "10%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                    disableUnderline
                    MenuProps={{
                      // className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value)
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        }
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationsData: state.reservations.reservations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(React.memo(ListView));
