import React from "react";

import BoundEditorForm from 'components/Explorer/BoundEditorForm';

import actionsForExplorer from 'store/actions/explorers/leads';
import accessorsForExplorer from 'store/reducers/explorers/leads';

const EditorForm = BoundEditorForm(actionsForExplorer.editorForm, accessorsForExplorer.editorForm);

export default function LeadsEditor(props) {
    return (
        <EditorForm order={["firstName", "lastName", "currentScore", "goalScore", "state", "notes", "lastUpdated"]}/>
    );
}