import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase } from "firebase/client";
import BillingCard from "./components/BillingCard";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditBilling(props) {
  const { classes, history, userInfo } = props;

  const [billingAddress, setBillingAddress] = useState(
    userInfo.billing && userInfo.billing.billingAddress ? userInfo.billing.billingAddress : ""
  );
  const [cardHolderName, setCardHolderName] = useState(
    userInfo.billing && userInfo.billing.cardHolderName ? userInfo.billing.cardHolderName : ""
  );
  const [cardNumber, setCardNumber] = useState(
    userInfo.billing && userInfo.billing.cardNumber ? userInfo.billing.cardNumber : ""
  );
  const [expiration, setExpiration] = useState(
    userInfo.billing && userInfo.billing.expiration ? userInfo.billing.expiration : ""
  );
  const [cvv, setCVV] = useState(
    userInfo.billing && userInfo.billing.cvv ? userInfo.billing.cvv : ""
  );

  const [cardType, setCardType] = useState(
    userInfo.billing && userInfo.billing.cardType ? userInfo.billing.cardType : ""
  );
  const [notes, setNotes] = useState("");

  const [primaryOrSecondary, setPrimaryOrSecondary] = useState({
    primary: true,
    secondary: false,
  });

  const [snackBarContent, setSnackBarContent] = useState("");

  const uid = firebase.auth().currentUser.uid;
  const user = firebase.auth().currentUser;

  const saveBillingChanges = async () => {
    if (!cardNumber || !expiration || !cvv || !cardHolderName) {
    } else {
      const response = await axios.post(
        "https://us-central1-humpty-dump.cloudfunctions.net/app/check-card",
        {
          cardNumber,
          expiration,
          cvv,
        }
      );
      console.log("response: ", response);
      // return db
      //   .collection("users")
      //   .doc(uid)
      //   .update({
      //     billing: {
      //       billingAddress,
      //       cardHolderName,
      //       cardNumber,
      //       cardType,
      //       expiration,
      //       notes,
      //       primaryOrSecondary: primaryOrSecondary.primary
      //         ? "Primary"
      //         : "Secondary",
      //     },
      //   })
      //   .then(() => {
      //     setSnackBarContent("Succesfully Updated Billing");
      //     history.push("/admin/profile");
      //   });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h1 style={{ marginBottom: "30px" }} className={classes.title}>
          Edit Billing Information
        </h1>
      </GridItem>
      <GridItem xs={12}>
        <BillingCard
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          cardHolderName={cardHolderName}
          setCardHolderName={setCardHolderName}
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          history={history}
          expiration={expiration}
          setExpiration={setExpiration}
          cvv={cvv}
          setCVV={setCVV}
          userInfo={userInfo}
          cardType={cardType}
          setCardType={setCardType}
          primaryOrSecondary={primaryOrSecondary}
          setPrimaryOrSecondary={setPrimaryOrSecondary}
          setNotes={setNotes}
          notes={notes}
        />
      </GridItem>
      <GridItem className={classes.spaceBetween} xs={12}>
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.push("/admin/profile")}
        >
          Cancel
        </Button>
        <Button
          onClick={saveBillingChanges}
          style={{
            background: "#FFC821",
            color: "#323232",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent !== "Succesfully Updated Billing"
              ? "error"
              : "success"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(EditBilling));
