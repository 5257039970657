/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import dotenv from "dotenv";
import compose from "recompose/compose";
import { connect } from "react-redux";
import axios from "axios";
import Geocode from "react-geocode";
import rentalsStyles from "assets/jss/material-dashboard-pro-react/views/rentalsStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import "./googleMap.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { NavLink, withRouter } from "react-router-dom";
import CustomMarker from "assets/img/customGoogleMapMarker.png";

let map;
dotenv.config();

const loader = new Loader({
  apiKey: process.env.REACT_APP_FB_API_KEY,
  version: "weekly",
});

const GoogleMap = (props) => {
  const { lat, lng, siteAddress } = props;

  useEffect(() => {
    async function fetchData() {
      loader
        .load()
        .then((google) => {
          if (lat) {
            map = new google.maps.Map(document.getElementById("map"), {
              center: { lat, lng },
              zoom: 10,
            });
            const marker = new google.maps.Marker({
              position: {
                lat: lat,
                lng: lng,
              },
              map,
              title: "Rental",
              // icon: iconBase,
            });
            const contentString =
            '<div class="content">' +
            `<p> ${siteAddress} </p>`
          ("</div>");

            const infowindow = new google.maps.InfoWindow({
              content: contentString,
            });

            marker.addListener("click", () => {
              infowindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
              });
              marker.setMap(map);
            });
            console
            marker.setMap(map);
          } else {
            map = new google.maps.Map(document.getElementById("map"), {
              center: { lat: 39.655, lng: -105.01186 },
              zoom: 10,
            });
          }

        })
        .catch((e) => {
          console.log("error initializing map");
          // do something
        });
    }
    fetchData();
  }, [lat]);

  // function addPoly(coorArray, sColor, sOpacity, weight, fColor, fOpacity) {
  //   var poly = new google.maps.Polygon({
  //     paths: coorArray,
  //     strokeColor: sColor,
  //     strokeOpacity: sOpacity,
  //     strokeWeight: weight,
  //     fillColor: fColor,
  //     fillOpacity: fOpacity,
  //   });
  //   return poly;
  // }

  // function loadPolygons(map) {
  //   formattedStateForMap.forEach((d, index) => {
  //     var poly = addPoly(
  //       d,
  //       territoriesData[index].territoryColor,
  //       0.8,
  //       2,
  //       territoriesData[index].territoryColor,
  //       0.55
  //     );
  //     poly.setMap(map);
  //   });
  // }

  // function bindDataLayerListeners(dataLayer) {
  //   dataLayer.addListener("addfeature", savePolygon);;
  // }

  // function savePolygon() {
  //   map.data.toGeoJson(function (json) {
  //     let lastInputedTerritory = json;
  //     lastInputedTerritory.features = [lastInputedTerritory.features.pop()];
  //     setSavedPolygon(lastInputedTerritory);
  //   });
  // }

  return <div id="map" style={{ height: "400px", zIndex: 3 }}></div>;
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(actions) {
  return (dispatch) => ({});
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(rentalsStyles)
)(GoogleMap);
