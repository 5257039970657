import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { addPendingReservationExistingCustomer } from "store/actions/reservations/reservationsActions";
import { getOneWeekLater } from "../../Dashboard/helpers/helpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import pointInPolygon from "point-in-polygon";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Submitted from "./Submitted";
import moment from "moment";

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

const locationSelections = [
  "Backyard",
  "Sideyard",
  "Driveway",
  "Street",
  "Alley",
];
const heardAboutUsFromSelections = [
  "Past Customer",
  "Google",
  "Yelp",
  "HomeAdvisor",
  "Referral",
  "Other",
  "NextDoor",
  "Social Media"
];
const communcationSelections = ["Email", "Call", "Text"];

function NewFormStepThree(props) {
  const {
    classes,
    inventoryData,
    prevStep,
    dropOffDate,
    sevenDayRental,
    setSevenDayRental,
    getOneWeekLater,
    equiptmentNeeded,
    setEquiptmentNeeded,
    equipmentLocation,
    setEquiptmentLocation,
    cost,
    setCost,
    locationSelections,
    communicationPreference,
    setCommunicationPreference,
    communcationSelections,
    heardAboutUsFrom,
    setHeardAboutUsFrom,
    heardAboutUsFromSelections,
    notes,
    setNotes,
    promoCode,
    setPromoCode,
    handleSubmit,
    hasErrors,
    setHasErrors,
    submitted,
    setSubmitted,
    errors,
    returningCustomerAlert,
    setReturningCustomerAlert,
  } = props;

  if (submitted) return <Submitted />;
  return (
    <Card className={classes.card}>
      {dropOffDate && (
        <>
          <h5 className={classes.formTitle}>
            Container Needed:{" "}
            {errors.equiptmentNeeded && (
              <span style={{ color: "red" }}>Required*</span>
            )}
          </h5>
          <Select
            className={classes.filterMenu}
            value={equiptmentNeeded}
            sx={{ background: "#F5F5F5" }}
            disableUnderline
            defaultValue={"Select Option"}
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => {
              setEquiptmentNeeded(e.target.value);
              setCost(e.target.value.cost);
            }}
          >
            {inventoryData.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o.inventoryItem}
                </MenuItem>
              );
            })}
          </Select>

          <h5 className={classes.formTitle}>
            Container Location (if you are facing the house):{" "}
            {errors.equipmentLocation && (
              <span style={{ color: "red" }}>Required*</span>
            )}
          </h5>
          <Select
            className={classes.filterMenu}
            value={equipmentLocation}
            sx={{ background: "#F5F5F5" }}
            disableUnderline
            defaultValue={"Select Option"}
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setEquiptmentLocation(e.target.value)}
          >
            {locationSelections.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <h5 className={classes.formTitle}>
            How did you hear about us?{" "}
            {errors.heardAboutUsFrom && (
              <span style={{ color: "red" }}>Required*</span>
            )}
          </h5>
          <Select
            className={classes.filterMenu}
            value={heardAboutUsFrom}
            sx={{ background: "#F5F5F5" }}
            disableUnderline
            defaultValue={"Select Option"}
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setHeardAboutUsFrom(e.target.value)}
          >
            {heardAboutUsFromSelections.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>

          <h5 className={classes.formTitle}>Other Information</h5>
          <TextareaAutosize
            aria-label="empty textarea"
            className={classes.emailInput}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="On-Site Contact Information, Special Requests, Long-Term Rental, Multiple Containers, etc. "
            style={{ maxWidth: "100%" }}
          />

          <Button
            disabled={
              !equiptmentNeeded || !equipmentLocation || !heardAboutUsFrom
            }
            onClick={handleSubmit}
            className={classes.sendBtn}
          >
            Send
          </Button>
        </>
      )}

      <Snackbar open={hasErrors} onClose={() => setHasErrors(false)}>
        <Alert onClose={() => setHasErrors(false)} severity={"error"}>
          Please Fill Out All Mandatory Fields
        </Alert>
      </Snackbar>

      <Snackbar
        open={returningCustomerAlert}
        onClose={() => setReturningCustomerAlert(false)}
      >
        <Alert
          onClose={() => setReturningCustomerAlert(false)}
          severity={"error"}
        >
          {returningCustomerAlert}
        </Alert>
      </Snackbar>
      <Button
        style={{ background: "#323232", color: "white" }}
        onClick={() => prevStep()}
      >
        Go Back
      </Button>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  handleAddPendingReservationExistingCustomer: (pendingRes) =>
    dispatch(addPendingReservationExistingCustomer(pendingRes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(NewFormStepThree));
