const customTableStyle = (theme) => ({
  tableRow: {
    height: "45px",
  },
  tableHeaders: {
    border: "0.5px solid #8E8E8E",
    background: "#D9D9D9",
    color: "#323232",
    fontFamily: "Archivo",
    fontSize: "17px",
    padding: "10px",
    textTransform: "uppercase",
  },
  tableCell: {
    textAlign: "left",
    border: "0.5px solid #8E8E8E",
    padding: "10px 20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  tableCell2: {
    textAlign: "left",
    border: "0.5px solid #8E8E8E",
    padding: "7px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
    whiteSpace: "noWrap"
  },
  pendingButtonDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "25%",
    padding: "10px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nameAndCompanyText: {
    padding: "0px 5px",
    color: "#0071C5",
    fontSize: "16px",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  actionBtn: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "15px",
    height: "50px",
    marginRight: "5px"
  },
  searchGridContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "800px !important",
    // overflow: "scroll",
  },
  searchGridContainer2: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "800px !important",
    overflowY: "scroll",
  },
  searchGridContainerNoScroll: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "800px !important",
  },
  columnGridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inventoryHeaderGridItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  inventoryTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    padding: "0px 30px 10px 20px"
    // [theme.breakpoints.down("md")]: {
    //   tableLayout: "fixed",
    //   width: "100%",
    // },
  },
  paddingTable: {
    padding: "0px 30px 10px 20px"
  },
  regularsTable: {
    tableLayout: "fixed !important",
    height: "50px !important",
    width: "100% !important",
    overflowY: "scroll !important",
  },
  commentDiv: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    margin: "0px 10px",
    whiteSpace: "noWrap",
    textAlign: "center",
    justifyContent: "center"
  },
  arrowIcon: {
    fontSize: "24px",
    "&:hover": {
      cursor: "pointer",
    },
  },

  yellowTableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "23px",
    color: "#323232",
    lineHeight: "25px",
    background: "#F1C84E",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  redTableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "23px",
    color: "white",
    lineHeight: "25px",
    background: "#F83030",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  whiteTableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "23px",
    color: "#323232",
    lineHeight: "25px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "35px 0px",
  },
  inventorySubHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    // margin: "35px 0px 0px 0px",
  },
  seeMore: {
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px",
    textAlign: "left",
  },
  spaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  productQuantityHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
  },
  trimmedDay: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#323232",
    justifyContent: "center",
  },
  trimmedWithoutYear: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "17px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#323232",
    justifyContent: "center",
  },
  filterMenu: {
    background: "white",
    borderRadius: "7px",
    height: "50px",
    marginRight: "15px",
    width: "50%",
  },
  summaryFilterMenu: {
    background: "white",
    borderRadius: "7px",
    height: "50px",
    marginRight: "15px",
    width: "70%",
  },
  sortByMenu: {
    height: "50px",
    marginRight: "15px",
    width: "20%",
    background: "#D9D9D9",
    padding: "10px",
    borderRadius: "5px",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
  jobsFilterContainer: {
    padding: "20px",
  },
  switchReport: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "19px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    color: "#323232",
    marginRight: "15px",
    whiteSpace: "noWrap",
  },
  agingText: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    color: "#323232",
    marginRight: "25px",
  },
  jobsGridItem: {
    display: "flex",
    width: "37%",
    alignItems: "center",
  },
  agingGridItem: {
    display: "flex",
    alignItems: "center",
    width: "75%",
    height: "45px"
  },
  jobsFilterItem: {
    display: "flex",
    alignItems: "center",
  },
  exportCSV: {
    textTransform: "capitalize",
    width: "30%",
    background: "#323232",
    display: "flex",
    justifyContent: "space-evenly",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    margin: "5px",
    alignItems: "center",
    color: "white",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "rgba(0,0,0,0.7)"
    },
    whiteSpace: "noWrap",
  },
  exportCSV2: {
    textTransform: "capitalize",
    width: "30%",
    background: "#323232",
    display: "flex",
    justifyContent: "space-evenly",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    margin: "5px 5px 5px 10px",
    alignItems: "center",
    color: "white",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
      backgroundColor: "rgba(0,0,0,0.7)"
    },
    whiteSpace: "noWrap",

  },
  sortBy: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
    margin: "0px 20px 0px 0px",
  },
  searchBar: {
    width: "100%",
    background: "white",
  },
  agingSearchBar: {
    width: "70%",
    background: "white",
    marginRight: "10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    height: "40px",
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "16px 30px !important",
    color: "#323232",
    "&:hover": {
      background: "#FFC720",
    },
    height: "40px"
  },
  searchButton2: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "11px 30px !important",
    color: "#323232",
    "&:hover": {
      background: "#FFC720",
    },
    height: "40px"
  },
  sendStatementsButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "9px 30px !important",
    color: "#323232",
    "&:hover": {
      background: "#FFC720",
    },
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize"
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
    height: "40px"
  },
  calendarIcon: {
    marginRight: "10px",
    width: "18px",
    height: "18px"
  },
  calendarIcon2: {
    marginRight: "10px",
    width: "20px",
    height: "20px"
  },
  viewSelectionDiv: {
    display: "flex",
    alignItems: "center",
    borderRadius: "7px 0px 0px 7px",
    border: "2px solid #323232",
    // padding: "20px",
  },
  justifyEnd: {
    display: "flex",
    justifyContent: "end",
  },
  addEditButton: {
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "space-evenly",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    width: "50%",
    alignItems: "center",
    fontFamily: "Archivo",
    fontStyle: "normal",
    margin: "20px 0px",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  viewSelection: {
    fontFamily: "Archivo",
    border: "1px solid #323232",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    width: "25%",
    justifyContent: "center",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  viewSelection2: {
    fontFamily: "Archivo",
    border: "1px solid #323232",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    width: "25%",
    justifyContent: "center",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  inventoryItemName: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "20px",
    color: "#4F4F4F",
  },
  territoryMenu: {
    background: "white",
    borderRadius: "7px",
    height: "50px",
    marginRight: "15px",
    width: "25%",
  },
  territoryText: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "19px",
    color: "#323232",
    marginRight: "20px",
  },
  salesByQuarterTableCell: {
    textAlign: "left",
    borderTop: "0.5px solid #8E8E8E",
    borderBottom: "0.5px solid #8E8E8E",
    padding: "30px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "19px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  salesByQuarterValue: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "17px",
    color: "#323232",
  },
  salesByQuarterDescriptor: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "17px",
    color: "#323232",
  },
  depositSummaryText: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    width: "33%",
  },
  depositSummaryTextCenter: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    width: "33%",
    textAlign: "center",
  },
  depositSummaryTextRight: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "17px",
    lineHeight: "18px",
    color: "#323232",
    width: "33%",
    textAlign: "right",
  },
  depositSummaryGridContainer: {
    maxWidth: "100%",
    margin: "0px auto",
  },
  depositSummaryGridItem: {
    padding: "0px !important",
  },
  select: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    background: "#323232",
    "&.MuiSelect-select:focus ": {
      background: "#323232",
      color: "white",
      fontWeight: "bold",
      borderRadius: "5px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid red",
    },
  },
  whiteColor: {
    color: "white",
  },
  filtersGridItem: {
    display: "flex",
    alignItems: "center",
  },
  ascendingMenu: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    background: "#F1C84E",
    borderRadius: "7px",
    height: "50px",
    marginRight: "15px",
  },
  selectMenuSort: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "30%",
    height: "50px",
    textAlign: "center",
    fontWeight: "bold",
    margin: "15px 15px 0px 10px",
  },
  ascMenu: {
    background: "#D9D9D9",
    borderRadius: "7px",
    width: "20%",
    height: "50px",
    textAlign: "center",
    fontWeight: "bold",
    margin: "15px 15px 0px 10px",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
  statusDiv: {
    // background: "#F83030",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    fontWeight: "600",
    fontFamily: "Archivo",
    fontStyle: "normal",
    whiteSpace: "noWrap"
  },
  indexes: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
  },
  indexes2: {
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontStyle: "normal",
    marginRight: "-45px"
  },
  view: {
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },
  referralCard: {
    padding: "20px",
  },
  justifyContentCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  slider: {
    height: "50px",
  },
  datetime: {
    background: "white",
    boxShadow: "0px 5px 10px rgba(203, 215, 235, 0.2)",
    // width: "100%",
    position: "relative",
    borderRadius: "4px",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "text",
    display: "inline-flex",
    fontSize: "1rem",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: "Archivo",
    fontWeight: 400,
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    border: "1px solid lightgray",
  },
  toCustomerProfileText: {
    padding: "0px 5px",
    color: "#0071C5",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer"
    }
  },
  selectMenuItemReport: {
    fontFamily: "Archivo",
    "&:hover": {
      background: "#FFC821"
    }
  },
  editRentalRatesButton: {
    textTransform: "capitalize",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px 15px",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    background: "#FFC821",
    color: "#323232",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
      color: "#323232",
    },
    height: "45px"
  },
  bulletPoint: {
    fontWeight: "700",
    marginRight: "20px"
  }
});

export default customTableStyle;
