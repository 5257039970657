/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";

function PONumberCard(props) {
  const { classes, reservationData, setPONumber } = props;

  return (
    <Card className={classes.invoiceCard}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>
            P.O. Number (optional){" "}
          </span>
          <Autocomplete
            id="country-select-demo"
            options={reservationData.map((c) =>
              c.reservation && c.reservation.poNumber
                ? c.reservation.poNumber
                : c.reservation
                ? c.reservation.reservationId
                : "No PO Number or ID Found"
            )}
            classes={{
              option: classes.option,
            }}
            onChange={(e) => {
              const index = e.target.id.split("-")[4];
              setPONumber(reservationData[index]);
            }}
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                  endAdornment: (
                    <InputAdornment
                      className={classes.adournedEnd}
                      position="end"
                    >
                      <SearchIcon
                        className={classes.calendarIcon}
                        // onClick={openCalendar}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    reservationData: state.reservations.reservations,
    sites: state.sites.sites,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(PONumberCard));
