import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Card from "components/Card/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ReactPaginate from "react-paginate";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";

import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";
import { ReactComponent as BlackDenyIcon } from "assets/img/blackDenyIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

const headers = ["Name", "Category", "Customer", "First Line Item", "Actions"];
const sortByOptions = ["Name", "Type", "Source", "Code"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];
const pageArr = ["8", "All"];
const mockData = [
  {
    name: "AP Mountain - Yard",
    category: "Customer",
    customer: "AP Mountain States",
    firstLineItem:
      "Service: 30 Yard Dumpster Rate: $375.00 Adjusted Rate: $350.00",
  },
  {
    name: "AP Mountain - Yard",
    category: "Customer",
    customer: "AP Mountain States",
    firstLineItem:
      "Service: 30 Yard Dumpster Rate: $375.00 Adjusted Rate: $350.00",
  },
  {
    name: "AP Mountain - Yard",
    category: "Customer",
    customer: "AP Mountain States",
    firstLineItem:
      "Service: 30 Yard Dumpster Rate: $375.00 Adjusted Rate: $350.00",
  },
  {
    name: "AP Mountain - Yard",
    category: "Customer",
    customer: "AP Mountain States",
    firstLineItem:
      "Service: 30 Yard Dumpster Rate: $375.00 Adjusted Rate: $350.00",
  },
  {
    name: "AP Mountain - Yard",
    category: "Customer",
    customer: "AP Mountain States",
    firstLineItem:
      "Service: 30 Yard Dumpster Rate: $375.00 Adjusted Rate: $350.00",
  },
  {
    name: "AP Mountain - Yard",
    category: "Customer",
    customer: "AP Mountain States",
    firstLineItem:
      "Service: 30 Yard Dumpster Rate: $375.00 Adjusted Rate: $350.00",
  },
  {
    name: "AP Mountain - Yard",
    category: "Customer",
    customer: "AP Mountain States",
    firstLineItem:
      "Service: 30 Yard Dumpster Rate: $375.00 Adjusted Rate: $350.00",
  },
  {
    name: "AP Mountain - Yard",
    category: "Customer",
    customer: "AP Mountain States",
    firstLineItem:
      "Service: 30 Yard Dumpster Rate: $375.00 Adjusted Rate: $350.00",
  },
];

function PriceBooksTab(props) {
  const { classes, history } = props;

  const itemsPerPage = 8;
  const [pageNumber, setPageNumber] = useState(0);
  const [index, setIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemsPerPage);
  const [pageDropdown, setPageDrowdown] = useState("8");
  const [sortBy, setSortBy] = useState("Name");
  const [directionSelection, setDirectionSelection] = useState("Asc");
  const [searchString, setSearchString] = useState("")

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    setIndex(selected * itemsPerPage);
    setEndIndex(selected * itemsPerPage + itemsPerPage);
  };

  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    let data;
    if (pageDropdown == "5") {
      data = mockData.slice(
        pageNumber * itemsPerPage,
        pageNumber * itemsPerPage + itemsPerPage
      );
    } else {
      data = mockData;
    }
    return data.map((d, index) => {
      return (
        <tr className={classes.tableRow} key={index}>
          <td style={{ background: "#FFFFFF" }} className={classes.tableCell}>
            {d.name}
          </td>
          <td className={classes.tableCell} style={{ background: "#FFFFFF" }}>
            {d.category}
          </td>
          <td className={classes.tableCell}>{d.customer}</td>
          <td className={classes.tableCell}>{d.firstLineItem}</td>
          <td className={classes.tableCell}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
              }}
            >
              <Tooltip
                id="tooltip-top"
                title="View"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <div
                  style={{ background: "#FFC821", width: "50%" }}
                  className={classes.pendingButtonDiv}
                >
                  <EyeIcon />
                </div>
              </Tooltip>
              <Tooltip
                id="tooltip-top"
                title="Edit"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <div
                  // onClick={() => handleModalOpen("Edit", d)}
                  style={{ background: "#323232", width: "50%" }}
                  className={classes.pendingButtonDiv}
                >
                  <WhiteEditIcon />
                </div>
              </Tooltip>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <GridContainer>
      <GridItem className={classes.spaceBetween} xs={12}>
        <h1 className={classes.title}>{mockData.length} Price Books</h1>
        <Button
          style={{ background: "#FFC821", color: "#323232", width: "15%" }}
          className={classes.addEditButton}
        >
          {" "}
          <AddIcon />
          Add Price Book
        </Button>
      </GridItem>
      <GridItem xs={8} style={{ display: "flex" }}>
        <p
          className={classes.subHeader}
          style={{ fontWeight: "normal", fontSize: "18px", margin: "10px 0px" }}
        >
          Sort By
        </p>
        <Select
          style={{
            width: "20%",
            height: "45px",
            fontWeight: 500,
            margin: "0px 15px 20px 10px"
          }}
          className={classes.selectMenuSort}
          value={sortBy}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          inputProps={{ IconComponent: () => null }}
        // onChange={(e) => handleFilters("filter", e.target.value)}
        >
          {sortByOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>

        <Select
          style={{
            width: "10%",
            height: "45px",
            fontWeight: 500,
            margin: "0px 15px 0px 10px"
          }}
          className={classes.ascMenu}
          disableUnderline
          classes={{
            root: classes.selectText,
          }}
          value={directionSelection}
        // onChange={(e) => handleFilters("direction", e.target.value)}
        >
          {directionOptions.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o}
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                {o}
              </MenuItem>
            );
          })}
        </Select>
      </GridItem>
      <GridItem xs={4}>
        <TextField
          className={classes.searchBar}
          placeholder="Search Accounts"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          variant="outlined"
          InputProps={{
            classes: {
              adornedEnd: classes.adournedEnd,
            },
            endAdornment: (
              <InputAdornment className={classes.adournedEnd} position="end">
                <Button className={classes.searchButton2}>Search</Button>
              </InputAdornment>
            ),
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <div
          style={
            pageDropdown == "All" && mockData.length > itemsPerPage
              ? { height: "500px", width: "100%", overflowY: "scroll" }
              : null
          }
        >
          <table className={classes.inventoryTable}>
            <tbody>
              {renderTableHeader()}
              {renderTableData()}
            </tbody>
          </table>
        </div>

        {pageDropdown != "All" ? (
          <GridContainer>
            <GridItem xs={6}>
              <p sclassName={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "13%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
            <GridItem xs={4} style={{ marginTop: "5px" }}>
              <h5
                className={classes.indexes}
              >
                {index + 1} - {endIndex} of{" "}
                {Math.ceil(mockData.length / itemsPerPage)}
              </h5>
            </GridItem>
            <GridItem xs={2}>
              <ReactPaginate
                paginationSize={0}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(mockData.length / itemsPerPage)}
                nextLabel={
                  <ArrowRight
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                previousLabel={
                  <ArrowLeft
                    style={{
                      fontSize: 30,
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "-10px",
                    }}
                  />
                }
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={6}>
              <p className={classes.view}>
                View
                <span>
                  <Select
                    style={{
                      width: "13%",
                      marginLeft: "10px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                    disableUnderline
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                      icon: classes.whiteColor,
                    }}
                    onChange={(e) => {
                      setPageDrowdown(e.target.value);
                    }}
                    value={pageDropdown}
                  >
                    {pageArr.map((d, index) => {
                      return (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple,
                          }}
                          value={d}
                        >
                          {d}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </span>
              </p>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(PriceBooksTab));
