import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as DeliveryIcon } from "assets/img/deliveryIcon.svg";
import { ReactComponent as ThreeDots } from "assets/img/threeDots.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ArrowDown } from "assets/img/arrowDown.svg";
import { ReactComponent as SwapIcon } from "assets/img/swapIcons.svg";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import { ReactComponent as BlackMailIcon } from "assets/img/blackMailIcon.svg";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export function Item(props) {
  const {
    id,
    classes,
    data,
    history,
    reservationId,
    driver,
    setItems,
    itemsState,
    reservation
  } = props;
  const renderEquipment = (e) => {
    if (typeof e === "object") {
      let returnStr = "";
      Object.keys(e).forEach((key) => {
        returnStr = returnStr + key + " ";
      });
      return returnStr;
    } else {
      return e;
    }
  };

  const renderQuantity = (q) => {
    if (typeof q === "object") {
      let returnStr = "";
      Object.entries(q).forEach(([key, value]) => {
        returnStr = returnStr + value + " ";
      });
      return returnStr;
    } else {
      return q;
    }
  };
  return (
    <div style={{ display: "flex", width: "100%", borderLeft: `6px solid ${props.territoryColor}` }}>
      <div
        style={{
          width: "30%",
          display: "flex",
          border: "0.5px solid #929292",
          alignItems: "center",
          padding: "10px",
          background: "white"
        }}
      >
        <DragIndicatorIcon />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "10px",
          }}
        >
          <span>{reservation.name}</span>
          <span>{reservation.reservation.siteAddress}</span>
        </div>
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          flexDirection: "column",
          border: "0.5px solid #929292",
          justifyContent: "center",
          padding: "10px",
          background: "white"
        }}
      >
        <div style={{ display: "flex" }}>
          Reservation ID: <span>{reservation.reservation.reservationId} |</span>
          <span>
            {renderEquipment(reservation.reservation.equiptment)} (
            {renderQuantity(reservation.reservation.quantity)})
          </span>
        </div>
      </div>
      <div
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          border: "0.5px solid #929292",
          justifyContent: "center",
          background: "white"
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Tooltip
            id="tooltip-top"
            title="Swap"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{ background: "#FFC821" }}
              className={classes.frequentButtonDiv}
            >
              <SwapIcon />
            </div>
          </Tooltip>
          <Tooltip
            id="tooltip-top"
            title="Pick Up"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{ background: "#323232" }}
              className={classes.frequentButtonDiv}
            >
              <WhiteEditIcon />
            </div>
          </Tooltip>
          <Tooltip
            id="tooltip-top"
            title="Add Rental"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{ background: "#FEDA1B" }}
              className={classes.frequentButtonDiv}
            >
              <CalendarIcon />
            </div>
          </Tooltip>
          <Tooltip
            id="tooltip-top"
            title="Add Rental"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{ background: "#929292" }}
              className={classes.frequentButtonDiv}
            >
              <BlackMailIcon />
            </div>
          </Tooltip>

          <Tooltip
            id="tooltip-top"
            title="Add Rental"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{ background: "#F5EB29" }}
              className={classes.frequentButtonDiv}
            >
              <EyeIcon />
              <span className={classes.btnDescriptor}>View On Map</span>
            </div>
          </Tooltip>

          <Tooltip
            id="tooltip-top"
            title="Add Rental"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{
                background: "#FFFFFF",
                border: "1px solid #323232",
              }}
              className={classes.frequentButtonDiv}
            >
              <AddIcon />
              <span className={classes.btnDescriptor}>Add Job</span>
            </div>
          </Tooltip>
          <Tooltip
            id="tooltip-top"
            title="Add Rental"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{ background: "#FFC821" }}
              className={classes.frequentButtonDiv}
            >
              <AddIcon />
              <span className={classes.btnDescriptor}>Add Rental</span>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props.id,
    data: {
      // change this if using for something other than reservations
      reservationId: props.reservationId,
      data: props.data,
      driverId: props.driverId,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
      <Item
        reservationId={props.reservationId}
        classes={props.classes}
        data={props.data}
        id={props.id}
        driverId={props.driverId}
        driver={props.driver}
        itemsState={props.itemsState}
        setItems={props.setItems}
        reservation={props.reservation}
        territoryColor={props.reservation.reservation.territoryColor}
      />
    </div>
  );
}
