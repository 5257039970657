import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import serviceRemindersStyle from "assets/jss/material-dashboard-pro-react/views/serviceRemindersStyle.js";
import Switch from "@material-ui/core/Switch";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import ServiceTypeToggle from "./components/ServiceTypeToggle.jsx";
import TruckServicesMap from "./components/TruckServicesMap.jsx";
import DumpsterServicesMap from "./components/DumpsterServicesMap.jsx";
import Button from "@material-ui/core/Button";
import { MockTrucks, MockDumpsters } from "./mockData/mockData";
import { ReactComponent as WhiteEditIcon } from "assets/img/whiteEditIcon.svg";

function ServiceReminders(props) {
  const { classes, history } = props;
  const [serviceType, setServiceType] = useState("Truck");

  const handleChange = () => {
    if (serviceType === "Truck") {
      setServiceType("Dumpster");
    } else {
      setServiceType("Truck");
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={8}>
          <h1 className={classes.title}>{serviceType} Service Reminders</h1>
        </GridItem>
        <GridItem className={classes.flexGrid} xs={4}>
          <div style={{ display: "flex", width: "40%" }}>
            <Button
              onClick={() => history.push("/admin/create-service-reminder")}
              style={{ background: "#FFC821", color: "#323232" }}
              className={classes.addEditButton}
            >
              <AddIcon />
              Add
            </Button>
            <Button
              style={{ background: "#323232", color: "white" }}
              className={classes.addEditButton}
            >
              <WhiteEditIcon />
              Edit
            </Button>
          </div>
          <ServiceTypeToggle
            serviceType={serviceType}
            setServiceType={setServiceType}
            handleChange={handleChange}
          />
        </GridItem>
        <GridItem className={classes.upcomingPaymentsGridItem} xs={6}>
          <p className={classes.subHeader}>
            {serviceType === "Truck" ? "All Trucks" : "All Dumpsters"}
          </p>
        </GridItem>

        {serviceType === "Truck" ? (
          <TruckServicesMap MockTrucks={MockTrucks} />
        ) : (
          <DumpsterServicesMap MockDumpsters={MockDumpsters} />
        )}
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(serviceRemindersStyle)
)(React.memo(ServiceReminders));
