import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase, sendInvoiceLinkLocalHost } from "firebase/client";
import InvoiceNameCard from "./components/InvoiceNameCard";
import ServicesCard from "./components/ServicesCard";
import MemoCard from "./components/MemoCard";
import ScheduleJobFor from "./components/ScheduleJobFor";
import DriverNotes from "./components/DriverNotes";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import loader from "assets/img/loader.gif";
import Attachments from "./components/Attachments";

import ViewTerms from "./components/ViewTerms";
import EditTerms from "./components/EditTerms";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditInvoice(props) {
  const { classes, history, userInfo } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState("");

  const uid = firebase.auth().currentUser.uid;
  const user = firebase.auth().currentUser;

  const invoiceId = window.location.pathname.split("/")[3];
  const [invoice, setInvoice] = useState(false);
  const [reservation, setReservation] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(false);

  const [servicesSubTotal, setServicesSubTotal] = useState(0);
  const [servicesTotal, setServicesTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discount, setDiscount] = useState("");

  const [invoiceDate, setInvoiceDate] = useState("");
  const [inventoryNeeded, setInventoryNeeded] = useState("");
  const [quantityNeeded, setQuantityNeeded] = useState("");
  const [services, setServices] = useState([]);
  const [poNumber, setPoNumber] = useState("");
  const [memo, setMemo] = useState("");
  const [jobType, setJobType] = useState("");
  const [recurring, setRecurring] = useState("");
  const [preferredCommunication, setPreferredCommunication] = useState("Email");
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [viewAndEditTermsModal, setViewAndEditTermsModal] = useState("");
  const [savingImage, setSavingImage] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [siteInfo, setSiteInfo] = useState(false);
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(false);

  const [errors, setErrors] = useState({
    customer: false,
    invoiceDate: false,
    services: false,
  });

  useEffect(() => {
    if (!dataLoaded) {
      const getInvoice = async () => {
        return db
          .collection("invoices")
          .doc(invoiceId)
          .get()
          .then((invoiceSnap) => {
            const invoiceData = invoiceSnap.data();
            return db
              .collection("customers")
              .doc(invoiceData.customerId)
              .get()
              .then((customerSnap) => {
                const customerData = customerSnap.data();
                setTaxTotal(parseFloat(invoiceData.appliedTaxTotal));
                setTermsAndConditions(invoiceData.termsAndConditions);
                setAttachments(invoiceData.attachments);
                // setPreferredCommunication()
                setMemo(invoiceData.internalMemo);
                if (invoiceData.reservationId) {
                  const reservationPromiseArray = [
                    db
                      .collection("reservations")
                      .doc(invoiceData.reservationId)
                      .get(),
                    db
                      .collection("pending-reservations")
                      .doc(invoiceData.reservationId)
                      .get(),
                    db
                      .collection("past-reservations")
                      .doc(invoiceData.reservationId)
                      .get(),
                  ];

                  Promise.all(reservationPromiseArray).then((results) => {
                    let reservationData;
                    results.forEach((r) => {
                      if (r.exists) {
                        reservationData = r.data();
                      }
                    });
                    setReservation(reservationData);
                    setCustomerInfo(customerData);
                    setServices(invoiceData.services);
                    setInvoice(invoiceData);
                    const invoiceDateData = invoiceData.invoiceDate.toDate();
                    setInvoiceDate(moment(invoiceDateData).format("MM/DD/YY"));
                    setInventoryNeeded(reservationData.reservation.equiptment);
                    setDataLoaded(true);
                  });
                } else {
                  setCustomerInfo(customerData);
                  setServices(invoiceData.services);
                  setInvoice(invoiceData);
                  const invoiceDateData = invoiceData.invoiceDate.toDate();
                  setInvoiceDate(moment(invoiceDateData).format("MM/DD/YY"));
                  // setInventoryNeeded(reservationData.reservation.equiptment);
                  setDataLoaded(true);
                }
              });
          });
      };
      getInvoice();
    }
  }, [dataLoaded]);

  const setDefaultCosts = () => {
    let subtotal = 0;
    services.forEach((s) => {
      const parsedTotal = parseFloat(s.rate);
      subtotal += parsedTotal;
    });
    setServicesSubTotal(subtotal);
    setDiscount(invoice.discount ? invoice.discount : "");
    setServicesTotal(subtotal + taxTotal);
  };

  useEffect(() => {
    if (services.length > 0) {
      setDefaultCosts();
    }
  }, [services]);

  useEffect(() => {
    let subtotal = 0;
    services.forEach((s) => {
      const parsedRate = parseFloat(s.rate);
      const totalTimesQty = parsedRate * quantityNeeded;
      subtotal += totalTimesQty;
    });
    const total = subtotal + taxTotal;
    setServicesTotal(total.toFixed(2));
  }, [taxTotal]);

  const handleChangeDiscount = (value) => {
    if (!value) {
      setDiscount("");
      setDefaultCosts();
    } else {
      const valueNumber = parseFloat(value);
      let subtotal = 0;
      services.forEach((s) => {
        const parsedRate = parseFloat(s.rate);
        const totalTimesQty = parsedRate * s.quantity;
        subtotal += totalTimesQty;
      });
      setDiscount(valueNumber);
      setServicesTotal(subtotal + taxTotal - valueNumber);
      const total = subtotal + taxTotal - valueNumber;
      setServicesTotal(total.toFixed(2));
    }
  };
  const submitInvoiceChanges = () => {
    if (!customerInfo || !invoiceDate || services.length === 0) {
      let newErrors = {
        customer: false,
        invoiceDate: false,
        services: false,
      };
      if (!customerInfo) {
        newErrors = { ...newErrors, customer: true };
      }
      if (!invoiceDate) {
        newErrors = { ...newErrors, invoiceDate: true };
      }
      if (services.length === 0) {
        newErrors = { ...newErrors, services: true };
      }
      setErrors({ ...newErrors });
    } else {
      const invoiceData = {
        customerId: customerInfo.customerId,
        invoiceDate: new Date(invoiceDate),
        invoiceDetails: {
          paymentId: "",
          paymentMethod: "",
          paymentStatus: "",
        },
        invoiceId: invoiceId,
        servicesTotal,
        appliedTaxTotal: taxTotal.toFixed(2),
        emailed: sendConfirmationEmail ? new Date() : invoice.emailed,
        invoiceStatus: "Draft",
        reservationId: "",
        services: services,
        termsAndConditions: termsAndConditions,
        attachments: attachments,
        internalMemo: memo,
        clerk: userInfo.firstName[0] + userInfo.lastName[0],
        recurring: recurring,
        siteId: invoice.siteId ? invoice.siteId : "",
        discount: discount,
      };
      return db
        .collection("invoices")
        .doc(invoiceId)
        .set(invoiceData)
        .then(() => {
          if (sendConfirmationEmail) {
            sendInvoiceLinkLocalHost(customerInfo.email);
            setSnackBarContent("Successfully created invoice");
            history.push(`/admin/invoice/${invoiceId}`);
          } else {
            setSnackBarContent("Successfully created invoice");
            history.push(`/admin/invoice/${invoiceId}`);
          }
        });
    }
  };

  if (!dataLoaded)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );

  return (
    <GridContainer>
      <GridItem
        className={classes.alignItems + " " + classes.marginBottom}
        xs={11}
      >
        <h1 style={{ marginBottom: "0px" }} className={classes.title}>
          Edit Invoice {invoiceId}
        </h1>
      </GridItem>
      <GridItem
        className={classes.alignItems + " " + classes.marginBottom}
        xs={1}
      >
        {!invoice.invoiceDetails.paymentStatus ? (
          <span className={classes.totalTitle}>$0.00</span>
        ) : (
          <span className={classes.totalTitle}>${servicesSubTotal}</span>
        )}
      </GridItem>
      <GridItem className={classes.columnGridItem} xs={12}>
        <InvoiceNameCard
          invoice={invoice}
          reservation={reservation}
          classes={classes}
          history={history}
          customerInfo={customerInfo}
          invoiceDate={invoiceDate}
          setInvoiceDate={setInvoiceDate}
          userInfo={userInfo}
          poNumber={poNumber}
          setPoNumber={setPoNumber}
          errors={errors}
        />
      </GridItem>
      <GridItem className={classes.columnGridItem} xs={12}>
        <ServicesCard
          servicesTotal={servicesTotal}
          taxTotal={taxTotal}
          servicesSubTotal={servicesSubTotal}
          invoice={invoice}
          errors={errors}
          history={history}
          customer={customerInfo}
          poNumber={poNumber}
          reservation={reservation}
          inventoryNeeded={inventoryNeeded}
          setInventoryNeeded={setInventoryNeeded}
          quantityNeeded={quantityNeeded}
          setQuantityNeeded={setQuantityNeeded}
          services={services}
          setServices={setServices}
          discount={discount}
          handleChangeDiscount={handleChangeDiscount}
          setViewAndEditTermsModal={setViewAndEditTermsModal}
          jobType={jobType}
          invoiceDate={invoiceDate}
          setInvoiceDate={setInvoiceDate}
          setJobType={setJobType}
          recurring={recurring}
        />
      </GridItem>

      <GridItem className={classes.columnGridItem} xs={12}>
        <MemoCard
          invoice={invoice}
          history={history}
          memo={memo}
          setMemo={setMemo}
          preferredCommunication={preferredCommunication}
          setPreferredCommunication={setPreferredCommunication}
        />
      </GridItem>

      <GridItem className={classes.columnGridItem} xs={12}>
        <Attachments
          history={history}
          setAttachments={setAttachments}
          attachments={attachments}
          setSavingImage={setSavingImage}
          savingImage={savingImage}
          newInvoiceID={invoiceId}
          sendConfirmationEmail={sendConfirmationEmail}
          setSendConfirmationEmail={setSendConfirmationEmail}
        />
      </GridItem>

      <GridItem
        className={classes.spaceBetween + " " + classes.marginTop}
        xs={12}
      >
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.push(`/admin/invoice/${invoiceId}`)}
        >
          Cancel
        </Button>
        <Button
          onClick={submitInvoiceChanges}
          style={{
            background: "#FFC821",
            color: "black",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent.includes("Successfully") ? "success" : "error"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>

      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={viewAndEditTermsModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setViewAndEditTermsModal("")}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        {viewAndEditTermsModal === "View" ? (
          <ViewTerms
            setViewAndEditTermsModal={setViewAndEditTermsModal}
            invoice={invoice}
            setTermsAndConditions={setTermsAndConditions}
            termsAndConditions={termsAndConditions}
          />
        ) : viewAndEditTermsModal === "Edit" ? (
          <EditTerms
            setViewAndEditTermsModal={setViewAndEditTermsModal}
            invoice={invoice}
            invoiceId={invoiceId}
            setSnackBarContent={setSnackBarContent}
            setDataLoaded={setDataLoaded}
            setTermsAndConditions={setTermsAndConditions}
            termsAndConditions={termsAndConditions}
          />
        ) : (
          <div></div>
        )}
      </Dialog>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(EditInvoice));
