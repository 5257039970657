import {
  firebase,
  db,
} from "firebase/client";

export const listenToJobs = () => {
  return (dispatch) => {
    db.collection("jobs").onSnapshot((snapshot) => {
      const jobs = snapshot.docs.map((d) => d.data());
      dispatch(setJobs(jobs));
    });
  };
};

export const setJobs = (jobs) => {
  return {
    type: "SET_JOBS",
    jobs,
  };
};
