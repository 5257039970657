import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Card from "components/Card/Card";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Datetime from "react-datetime";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import { reportSelections } from "../../config/globals";

const headers = ["", "", "", ""];

const sortByOptions = ["Total Sales", "Scheduled Date"];
const directionOptions = ["Asc", "Desc"];
const filterOptions = ["Filter By"];

function SalesByQuarterTable(props) {
  const { classes, salesByQuarterData, history } = props;
  const [tableSelection, setTableSelection] = useState("");
  const renderTableHeader = () => {
    return (
      <tr
        className={classes.tableRow}
        style={{
          height: "45px",
          border: "1px solid #8E8E8E",
        }}
      >
        {headers.map((key, index) => {
          return (
            <th className={classes.tableHeaders} key={key}>
              {key}
            </th>
          );
        })}
      </tr>
    );
  };

  const renderTableData = () => {
    return salesByQuarterData.map((d, index) => {
      return (
        <tr className={classes.salesByQuarterTableCell} key={index}>
          <td
            style={{ background: "#FFFFFF", fontWeight: 700, fontSize: "24px" }}
            className={classes.salesByQuarterTableCell}
          >
            {d.title}
          </td>
          <td
            className={classes.salesByQuarterTableCell}
            style={{ background: "#FFFFFF" }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className={classes.salesByQuarterValue}>{d.lastYear}</span>
              <span className={classes.salesByQuarterDescriptor}>Last Year</span>
            </div>
          </td>
          <td className={classes.salesByQuarterTableCell}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className={classes.salesByQuarterValue}>{d.thisYear}</span>
              <span className={classes.salesByQuarterDescriptor}>This Year</span>{" "}
            </div>
          </td>
          <td className={classes.salesByQuarterTableCell}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className={classes.salesByQuarterValue}>{d.change}</span>
              <span className={classes.salesByQuarterDescriptor}>Change</span>{" "}
            </div>
          </td>
          <td
            style={
              d.upOrDown === "Up" ? { color: "#2EB67D" } : { color: "#D40707" }
            }
            className={classes.salesByQuarterTableCell}
          >
            {d.changePercentage}
          </td>
        </tr>
      );
    });
  };

  return (
    <GridContainer className={classes.searchGridContainer}>
      <GridItem xs={12}>
        <Card style={{marginTop:"0px"}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "20px",
              maxHeight: "80px",
            }}
          >
            <div style={{ width: "40%" }} className={classes.agingGridItem}>
              <span className={classes.switchReport}>Switch Report</span>
              <Select
                variant="outlined"
                className={classes.filterMenu}
                value={tableSelection}
                sx={{ background: "#F5F5F5" }}
                disableUnderline
                defaultValue={"Select Option"}
                classes={{
                  root: classes.selectTextReport,
                }}
                onChange={(e) => history.push(e.target.value.link)}
                displayEmpty
                renderValue={
                  tableSelection !== "" ? undefined : () => "Sales by Quarter"
                }
              >
                {reportSelections.map((o, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={o}
                      classes={{
                        root: classes.selectMenuItemReport,
                      }}
                    >
                      {o.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>

          <table className={classes.inventoryTable}>
            <tbody>
              {/* {renderTableHeader()} */}
              {renderTableData()}
            </tbody>
          </table>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customTableStyle)
)(React.memo(SalesByQuarterTable));
