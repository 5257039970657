import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import { db } from "firebase/client";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "@material-ui/core/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import settingsStyle from "assets/jss/material-dashboard-pro-react/views/settingsStyle.js";
import InventorySettingCustomTabs from "../InventorySettingCustomTab";
import { InputAdornment, TextField } from "@material-ui/core";
import ProductTable from "./Product/ProductTable"
import MapView from "./Map/MapView";


function Inventory(props) {
    const {
        classes,
        history,
        user
    } = props;
    const [searchString, setSearchString] = useState("");
    const handleSearch = () => {
        console.log("checking handle search")
        // if (searchString) {
        //   const filtered = reservations.filter((r) => {
        //     if (
        //       r.name.toLowerCase().includes(searchString.toLowerCase()) ||
        //       r.billingAddress.toLowerCase().includes(searchString.toLowerCase()) ||
        //       r.company.toLowerCase().includes(searchString.toLowerCase()) ||
        //       r.email.toLowerCase().includes(searchString.toLowerCase()) ||
        //       r.phoneNumber.toLowerCase().includes(searchString.toLowerCase()) ||
        //       r.reservation.reservationId
        //         .toLowerCase()
        //         .includes(searchString.toLowerCase())
        //     ) {
        //       return true;
        //     }
        //   });
        //   console.log("filtered", filtered);
        //   setReservations([...filtered]);
        // } else {
        //   setReservations([...reservationsData]);
        // }
    };
    return (
        <GridContainer>
            <GridItem xs={12}>
                <GridContainer>
                    <GridItem xs={12} >
                        <GridContainer>
                            <GridItem xs={6} />
                            <GridItem xs={6} style={{ justifyContent: "flex-end", display: "flex" }}>
                                <TextField
                                    style={{ width: "80%", marginRight: "20px" }}
                                    className={classes.searchBar}
                                    placeholder="Search Rentals"
                                    value={searchString}
                                    onChange={(e) => setSearchString(e.target.value)}
                                    variant="outlined"
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            handleSearch();
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            adornedEnd: classes.adournedEnd,
                                        },
                                        endAdornment: (
                                            <InputAdornment className={classes.adournedEnd} position="end">
                                                <Button
                                                    onClick={handleSearch}
                                                    className={classes.searchButton}
                                                >
                                                    Search
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    className={classes.addCustomer}
                                    style={{
                                        margin: "-2px 0px 0px 0px",
                                        height: "42px",
                                        width: "35%",
                                    }}>
                                    <AddIcon style={{ marginRight: "5px" }} /> Create Product
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem
                        xs={12}
                    >
                        <InventorySettingCustomTabs
                            classes={classes}
                            tabs={[
                                {
                                    tabName: "Products",
                                    tabContent: (
                                        <ProductTable />
                                    ),
                                },
                                {
                                    tabName: "Map",
                                    tabContent: (
                                        <MapView classes={classes} history={history} user={user}/>
                                    ),
                                },
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        customersData: state.customers.customers,
        reservationsData: state.reservations.reservations,
        pastReservations: state.reservations.pastReservations,
        user: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(settingsStyle)
)(Inventory);

