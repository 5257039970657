/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Datetime from "react-datetime";
import loader from "assets/img/loader.gif";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";

const communcationsOptions = ["Email", "Call"];
const tagOptions = ["Overdue", "Unpaid", "New", "Regular", "Cautious"];

function InvoiceNameCard(props) {
  const {
    classes,
    customerInfo,
    invoice,
    reservation,
    invoiceDate,
    setInvoiceDate,
    userInfo,
    poNumber,
    setPoNumber,
    history,
  } = props;

  const handleSelect = (address, setFunction) => {
    setFunction(address);
  };

  return (
    <Card style={{ marginTop: "0px" }} className={classes.card}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Customer</span>
          <TextField
            value={customerInfo.name}
            disabled
            // onChange={(e) => setJobName(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Invoice Date</span>
          <Datetime
            // style={{
            //   color: "black !important",
            //   height: "100%",
            //   width: "100%",
            // }}
            selected={invoiceDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              setInvoiceDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  // className={classes.datetime}
                  style={{ width: "100%" }}
                  placeholder="From"
                  value={invoiceDate}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Due Date</span>
          <Datetime
            // style={{
            //   color: "black !important",
            //   height: "100%",
            //   width: "100%",
            // }}
            selected={invoiceDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              setInvoiceDate(date.format("MM/DD/YY"));
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
            renderInput={(props, openCalendar, closeCalendar) => (
              <div onClick={openCalendar}>
                <TextField
                  // className={classes.datetime}
                  style={{ width: "100%" }}
                  placeholder="From"
                  value={invoiceDate}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <CalendarIcon
                          className={classes.calendarIcon}
                          onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />
        </GridItem>

        {invoice.reservationId && (
          <GridItem className={classes.columnGridItem} xs={4}>
            <span className={classes.inputTypeTitle}>
              Rental{" "}
              <span
                style={{
                  marginLeft: "3px",
                  color: "#0071C5",
                  textDecoration: "underline",
                }}
                className={classes.inputTypeTitle}
                onClick={() =>
                  history.push(
                    `/admin/rentals/${invoice.reservationId}`
                  )
                }
              >
                {invoice.reservationId}
              </span>
            </span>
            <span className={classes.invoiceNameCardText}>
              {reservation.reservation.siteAddress}
            </span>
          </GridItem>
        )}
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>Clerk</span>
          <TextField
            value={userInfo.firstName[0] + userInfo.lastName[0]}
            disabled
            // onChange={(e) => setJobName(e.target.value)}
            variant="outlined"
          />
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={4}>
          <span className={classes.inputTypeTitle}>P.O. Number (optional)</span>
          <TextField
            value={poNumber}
            onChange={(e) => setPoNumber(e.target.value)}
            variant="outlined"
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log("stateee", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    sites: state.sites.sites,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(InvoiceNameCard));
