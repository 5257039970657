import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";

function PaymentDetails(props) {
  const { classes, payment, history, reservation, invoice } = props;

  let date = "No date found";
  if (payment && payment.paymentDate) {
    date = payment.paymentDate.toDate();
  } else if (payment && payment["Transaction Date"]) {
    date = payment["Transaction Date"];
  }
  return (
    <GridContainer>
      <GridItem className={classes.invoiceGridItem} xs={12}>
        <Card className={classes.invoiceCard}>
          <GridContainer>
            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Reference Number
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {payment["Transaction Number"] ? payment["Transaction Number"]: "----"}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Payment Method
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {payment.card && payment.card.brand
                    ? payment.card.brand.toUpperCase()
                    : payment["Payment Method"]
                    ? payment["Payment Method"]
                    : "No method found"}{" "}
                  (Online Payment)
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Payment Date
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {moment(date).format("MM/DD/YY, hh:mm A")}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Amount Received
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  ${payment.amountReceived || payment.paymentTotal}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Payment Status
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  <div
                    style={{ background: "#2EB67D", width: "50%" }}
                    className={classes.statusDiv}
                  >
                    {invoice
                      ? invoice.invoiceDetails.paymentStatus
                      : "No Invoice Found"}
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Amount Applied
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  ${payment.amountReceived}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Payment ID
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {payment.paymentId || payment.id}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Amount Remaining
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  ${payment.remaining}
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6}>
              <GridContainer>
                <GridItem className={classes.siteInfoTitle} xs={6}>
                  Internal Memo
                </GridItem>
                <GridItem className={classes.siteInfoValue} xs={6}>
                  {payment.memo ? payment.memo : payment.Message ? payment.Message : "---"}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(PaymentDetails));
