import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import InventoryRentedTable from "./InventoryRentedTable";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getNextNine,
  getPrevFourteen,
  getDates,
  getNewDataFromCalendarSelection,
  getRangeBetweenTwoDates
} from "../helpers/helpers";
import Datetime from "react-datetime";
import moment from "moment";

const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};

function InventoryRented(props) {
  const { classes, inventoryData, reservation } = props;
  const [defaultHeader, setDefaultHeader] = useState([]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [inventoryStartDate, setInventoryStartDate] = useState("");
  const [dateRange, setDateRange] = useState([])

  useEffect(() => {
    const defaultDays = getDates(new Date(reservation.reservation.startDate));
    const range = getRangeBetweenTwoDates(reservation.reservation.startDate, reservation.reservation.endDate)
    setDateRange(range)
    range.unshift("Product")
    setDefaultHeader(range);
    setInventoryStartDate(defaultDays[2]);
  }, [reservation]);

  const handlePreviousFourteenDays = () => {
    const prevNine = getPrevFourteen(defaultHeader[2]);
    setDefaultHeader(prevNine);
  };
  const handleNextFourteenDays = () => {
    const nextNine = getNextNine(defaultHeader[2]);
    setDefaultHeader(nextNine);
  };
  const handleNextFourteenDaysWithInput = (date) => {
    const nextNine = getNewDataFromCalendarSelection(date);
    setDefaultHeader(nextNine);
  };

  return (
    <Card className={classes.inventoryRentedCard}>
      <GridContainer>
        <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
          <h1 className={classes.title}>Inventory Rented</h1>
        </GridItem>
        <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
          <div style={{ display: "flex" }}>
            <span className={classes.dateRange}>
              {defaultHeader[1]} - {defaultHeader[8]}
            </span>
            <Tooltip
              id="tooltip-top"
              title="Change Inventory Start Date"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <div
                onClick={() => setCalendarOpen(true)}
                className={classes.calendarIconDiv}
              >
                <CalendarIcon />
              </div>
            </Tooltip>
            {calendarOpen && (
              <Datetime
                open={calendarOpen}
                isValidDate={disablePastDt}
                input
                // className={classes.textFieldRoot}
                style={{
                  color: "#323232 !important",
                  height: "100%",
                  width: "100%",
                }}
                selected={inventoryStartDate}
                dateFormat="MM/DD/YY"
                onChange={(date, e) => {
                  handleNextFourteenDaysWithInput(date);
                  setCalendarOpen(false);
                }}
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside
              />
            )}
          </div>
        </GridItem>
        <GridItem xs={12}>
          <InventoryRentedTable
            reservation={reservation}
            handleNextFourteenDays={handleNextFourteenDays}
            handlePreviousFourteenDays={handlePreviousFourteenDays}
            defaultHeader={defaultHeader}
            inventoryData={inventoryData}
            classes={classes}
            dateRange={dateRange}
          />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(InventoryRented));
