import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Card from "components/Card/Card";
import { filterDataWithComments } from "../../../../../globalHelperFunctions/globalHelperFunctions";
import Button from "components/CustomButtons/Button.js";
import StaffTable from "./components/StaffTable";
import Filters from "./components/Filters";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";


function StaffTab(props) {
    const { classes, customerInfo, history, priceBooksData, staffData } = props;

    const [searchString, setSearchString] = useState("");
    const [data, setData] = useState(staffData);
    // const [sortBySelection, setSortBySelection] = useState("All");
    const [directionSelection, setDirectionSelection] = useState("Asc");
    const [filterSelection, setFilterSelection] = useState("First Name");


    const handleFilters = (type, filterValue) => {
        if (type === "filter") {
            const filtered = filterDataWithComments(
                filterValue,
                directionSelection,
                "All",
                data
            );
            setFilterSelection(filterValue);
            setData([...filtered]);
        }
        if (type === "direction") {
            const filtered = filterDataWithComments(
                filterSelection,
                filterValue,
                "All",
                data
            );
            setDirectionSelection(filterValue);
            setData([...filtered]);
        }
        // if (type === "comments") {
        //   const filtered = filterDataWithComments(
        //     filterSelection,
        //     directionSelection,
        //     filterValue,
        //     data
        //   );
        //   setData([...filtered]);
        // }
    };

    const handleSearch = () => {
        console.log("checking search string", searchString)
        if (searchString) {
            const filtered = data.filter((r) => {
                console.log("checking r", r)
                if (
                    r.firstName.toLowerCase().includes(searchString.toLowerCase()) ||
                    r.lastName.toLowerCase().includes(searchString.toLowerCase()) ||
                    r.email.toLowerCase().includes(searchString.toLowerCase()) ||
                    r.phoneNumber.toLowerCase().includes(searchString.toLowerCase()) 
                )
                 {
                    return true;
                }
            });
            console.log("filtered", filtered);
            setData([...filtered]);
        } else {
            setData([...staffData]);
        }
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card className={classes.tabCard}>
                    <GridContainer>
                        <GridItem
                            className={classes.column + " " + classes.justifyContent}
                            xs={4}
                        >
                            <span className={classes.notesTabTitle2}>
                                {staffData.length} Staff Members
                            </span>
                        </GridItem>
                        <GridItem xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button className={classes.addCustomer} style={{ marginRight: "12px", textTransform: "capitalize" }}>
                                <AddIcon style={{ marginRight: "12px" }} /> Add Staff
                            </Button>
                        </GridItem>
                        <GridItem xs={12}>
                            <Filters
                                // sortBySelection={sortBySelection}
                                // setSortBySelection={setSortBySelection}
                                directionSelection={directionSelection}
                                setDirectionSelection={setDirectionSelection}
                                setFilterSelection={setFilterSelection}
                                filterSelection={filterSelection}
                                searchString={searchString}
                                setSearchString={setSearchString}
                                handleFilters={handleFilters}
                                handleSearch={handleSearch}
                            />
                        </GridItem>
                        <GridItem className={classes.column} xs={12}>
                            <StaffTable
                                staffData={data}
                                history={history}
                                handleFilters={handleFilters}
                            />
                        </GridItem>
                    </GridContainer>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
        customersData: state.customers.customers,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(customerInfoStyle)
)(React.memo(StaffTab));
