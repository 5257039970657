import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";
import Card from "components/Card/Card.js";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getNextNine,
  getPrevFourteen,
  getDates,
  getNewDataFromCalendarSelection,
  getRangeBetweenTwoDates,
} from "../helpers/helpers";
import Datetime from "react-datetime";
import moment from "moment";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import GoogleMap from "./GoogleMap";

const yesterday = moment().subtract(1, "day");
const mockSiteInfo = [
  { title: "Site Number", value: "S342" },
  { title: "Territory", value: "Southwest" },
  { title: "Address", value: "7216 W Portland Ave Littleton, CO 80128" },
  { title: "County", value: "Jefferson County" },
  { title: "Site County", value: "---" },
];

function SiteInformation(props) {
  const { classes, reservation, history } = props;
  const [filteredTableData, setFilteredTableData] = useState([]);
  console.log("reservationxxxxxxx", reservation);
  return (
    <Card className={classes.jobsCard}>
      <GridContainer>
        <GridItem className={classes.jobsHeaderGridItem} xs={12}>
          <h1 style={{ marginBottom: "30px" }} className={classes.title}>
            Site Information
          </h1>
        </GridItem>
        <GridItem xs={6}>
          <GridContainer>
            <GridItem className={classes.siteInfoTitle} xs={6}>
              Site ID
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {reservation.reservation.siteId ? (
                <span
                  onClick={() =>
                    history.push(
                      `/admin/site/${reservation.reservation.siteId}`
                    )
                  }
                  style={{ color: "#0071C5", textDecoration: "underline" }}
                  className={classes.siteInfoValue}
                >
                  {reservation.reservation.siteId}
                </span>
              ) : (
                <span className={classes.siteInfoValue}>N/A</span>
              )}
            </GridItem>

            <GridItem className={classes.siteInfoTitle} xs={6}>
              Territory
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {reservation.reservation.territory}
            </GridItem>

            <GridItem className={classes.siteInfoTitle} xs={6}>
              Address
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              {reservation.reservation.siteAddress}
            </GridItem>

            <GridItem className={classes.siteInfoTitle} xs={6}>
              County
            </GridItem>
            <GridItem className={classes.siteInfoValue} xs={6}>
              N/A
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6}>
          <GoogleMap reservations={[]} />
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(SiteInformation));
