import {
  cardTitle,
  successColor,
  dangerColor
} from "assets/jss/material-dashboard-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const explorerStyle = {
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "10px",
    marginBottom: "0px"
  },
  cardTextTitle: {
    ...cardTitle,
    marginTop: "10px",
    fontSize: "large",
    fontWeight: "800",
    display: "block"
  },
  newRecord: {
    "&:hover": {
      backgroundColor: "#f2cc8f",
    },
    backgroundColor: "#ffb163",
    borderRadius: "50%",
    height: "12vh",
    width: "7%",
    color: "#fff",
    position: "fixed",
    bottom: 20,
    right: 40,
  },
  advanceStatus: {
    backgroundColor: "#10669b",
  },
  remind: {
    backgroundColor: "#18528c", 
  },
  staticFormGroup: {
    marginLeft: "0",
    marginRight: "0",
    paddingBottom: "10px",
    margin: "8px 0 0 0",
    position: "relative",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    }
  },
  staticFormControl: {
    marginBottom: "0",
    paddingTop: "8px",
    paddingBottom: "8px",
    minHeight: "34px"
  },
  inputAdornment: {
    marginRight: "8px",
    position: "relative"
  },
  inputAdornmentIconSuccess: {
    color: successColor[0] + "!important"
  },
  inputAdornmentIconError: {
    color: dangerColor[0] + "!important"
  }
};

export default explorerStyle;
