import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import settingsStyle from "assets/jss/material-dashboard-pro-react/views/settingsStyle.js";
import Button from "components/CustomButtons/Button.js";

export default function SettingsCustomTabs(props) {
  const [value, setValue] = React.useState(props.value);
  const handleChange = (event, value) => {
    setValue(value);
  };

  const { headerColor, plainTabs, tabs, title, rtlActive, classes } = props;
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });

  return (
    <div>
      <Tabs
        style={{ borderBottom: "0.5px solid #8E8E8E" }}
        value={value}
        onChange={handleChange}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.displayNone,
        }}
      >
        {tabs.map((prop, key) => {
          var icon = {};
          if (prop.tabIcon) {
            icon = {
              icon: <prop.tabIcon />,
            };
          }
          return (
            <Tab
              classes={{
                root: classes.tabRootButton,
                selected: classes.selected,
                wrapper: classes.tabWrapper,
              }}
              key={key}
              label={prop.tabName}
              {...icon}
            />
          );
        })}
      </Tabs>
      <div>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
}

SettingsCustomTabs.defaultProps = {
  value: 0,
};

SettingsCustomTabs.propTypes = {
  // the default opened tab - index starts at 0
  value: PropTypes.number,
  // function for changing the value
  // note, if you pass this function,
  // the default function that changes the tabs will no longer work,
  // so you need to create the changing functionality as well
  changeValue: PropTypes.func,
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
};
