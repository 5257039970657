const initState = {
  territories: [],
};

export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_TERRITORIES":
      return {
        ...state,
        territories: [...action.territories],
      };
    default:
      return state;
  }
}
