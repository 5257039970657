import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationDetailStyle from "assets/jss/material-dashboard-pro-react/views/reservationDetailStyle.js";
import { ReactComponent as SlackIcon } from "assets/img/slackIcon.svg";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { BsFillCircleFill } from "react-icons/bs";
import Button from "components/CustomButtons/Button.js";

function ReservationDetailHeader(props) {
  const { classes, reservation, setEditReservationModal, customer, user } = props;

  return (
    <GridContainer>
      <GridItem className={classes.gridItem} xs={9}>
        <h1 className={classes.title}>
          {reservation.reservation.reservationId + ": " + customer.name}
        </h1>
      </GridItem>
      <GridItem xs={3}>
        <div className={classes.row + " " + classes.fullWidth}>
          <div className={classes.paymentsDivRed}>
            <div className={classes.paymentDiv} style={{marginRight:"5px"}}>
              <span>{reservation.reservation.amount}</span>
              {user.role && user.role === "Super Admin" ?
                <span style={{ fontWeight: 600 }}>Total</span>
                :
                <span style={{ fontWeight: 600 }}>OPEN</span>
              }

            </div>
          </div>
          <div className={classes.paymentsDivGreen}>
            <div className={classes.paymentDiv}>
              <span>{reservation.reservation.amountPaid}</span>
              <span style={{ fontWeight: 600 }}>PAID</span>

            </div>
          </div>
          {user.role && user.role === "Super Admin" ?
            <div
              onClick={() => setEditReservationModal(true)}
              className={classes.editCustomer}
            >
              <EditIcon style={{ marginRight: "10px" }}/>
              <span>Edit Rental</span>
            </div>
            :
            <div
              onClick={() => setEditReservationModal(true)}
              className={classes.editCustomer}
            >
              <EditIcon style={{ marginRight: "10px" }} />
              <span>Request Change</span>
            </div>
          }
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationDetailStyle)
)(React.memo(ReservationDetailHeader));
