import React from "react";
import ChartistGraph from "react-chartist";
import "./chart.css";

export default function App() {
  var data = {
    labels: ["Northeast", "Northwest", "Southeast", "Southwest"],
    series: [
      [300, 400, 250, 199], //first item on all charts
      [100, 150, 180, 90], //second item on all charts
      [400, 419, 212, 333], //third item on all charts
      [200, 120, 109, 205], //fourth item on all charts
    ],
  };

  var options = {
    high: 1000,
    stackBars: true,
    low: 0,
    seriesBarDistance: 10,
    axisX: {
      offset: 50,
    },
    axisY: {
      offset: 40,
      labelInterpolationFnc: function (value) {
        return value;
      },
      scaleMinSpace: 40,
    },
  };

  var responsiveOptions = [];

  let type = "Bar";

  return (
    <ChartistGraph
      style={{ width: "100%", height: "500px" }}
      data={data}
      options={options}
      type={type}
    />
  );
}
