import { push } from "connected-react-router";
import { uris } from "variables/config";

function _open(title, url) {
    return {
        type: "NAVIGATION_OPEN",
        title,
        url
    }
}

function _openChild(title, url) {
    return {
        type: "NAVIGATION_OPEN_CHILD",
        title,
        url
    }
}

export function _close() {
    return {
        type: "NAVIGATION_CLOSE",
    }
}

export function open(title, url) {
    return (dispatch) => {
        dispatch(_open(title, url));
        dispatch(push(url));
    }
}

export function openChild(title, url) {
    return (dispatch) => {
        dispatch(_openChild(title, url));
        dispatch(push(url));
    }
}

export function close() {
    return (dispatch, getState) => {
        dispatch(_close());
        const state = getState().navigation;
        if (state && state.pageStack && state.pageStack.url) 
            dispatch(push(state.pageStack.url));
        else
            dispatch(push(uris.HOME));
    }
}

export default {
    open, openChild, close
}