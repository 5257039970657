import { firebase } from "@firebase/app";
import "@firebase/firestore";
import "@firebase/storage";
import "@firebase/functions";
import dotenv from "dotenv";

// ensure config is read
dotenv.config();

// Initialize Firebase from .env files
const projectId = process.env.REACT_APP_FB_PROJECT_ID;

var config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  projectId: projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};

firebase.initializeApp(config);
firebase.firestore().settings({});
const storage = firebase.storage();
const functions = firebase.functions();
const sendNewPendingReservationNotification = functions.httpsCallable(
  "sendNewPendingReservationNotification"
);
const testingCustomerSignUp = functions.httpsCallable("testingCustomerSignUp");
const sendingDenialEmail = functions.httpsCallable("sendingDenialEmail");
const sendInvoiceLink = functions.httpsCallable("sendInvoiceLink");
const createNewUser = functions.httpsCallable("createNewUser");
const sendInvoiceLinkLocalHost = functions.httpsCallable(
  "sendInvoiceLinkLocalHost"
);
const notifyCustomerAboutQuote = functions.httpsCallable(
  "notifyCustomerAboutQuote"
);
const sendSmsAboutQuote = functions.httpsCallable("sendSmsAboutQuote");

const db = firebase.firestore();

export {
  db,
  firebase,
  storage,
  sendNewPendingReservationNotification,
  testingCustomerSignUp,
  sendingDenialEmail,
  sendInvoiceLinkLocalHost,
  sendInvoiceLink,
  createNewUser,
  notifyCustomerAboutQuote,
  sendSmsAboutQuote,
  firebase as default,
};
