import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactPaginate from "react-paginate";
import "views/Dashboard/components/pagination.css";
// core components
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customTableStyle from "assets/jss/material-dashboard-pro-react/views/customTableStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { ReactComponent as EyeIcon } from "assets/img/eyeIcon.svg";
import { ReactComponent as EditIcon } from "assets/img/editIconWhite.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIconBlack.svg";
import { BsFillCircleFill } from "react-icons/bs";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";

const pageArr = ["3", "All"];
const headers = [
    "Serial #",
    "Barcode",
    "Condition",
    "Task",
    "Location",
    "Status",
    "Make",
    "Actions",
];

const mockData = [
    {
        serial: "15-01",
        barcode: "013",
        condition: "105 (repaired To 105 But Wasnt Needed On 12/28)",
        task: "J234",
        location: "18 Beacon Hill Ln Greenwood Village, CO 80111",
        status: "Active",
        make: "MCS Black"
    },
    {
        serial: "15-02",
        barcode: "009",
        condition: "90 (CORRECTED TO 105 ON 12/28)",
        task: "J234",
        location: "18 Beacon Hill Ln Greenwood Village, CO 80111",
        status: "Inactive",
        make: "MCS Black"
    },
    {
        serial: "15-02",
        barcode: "024",
        condition: "90 (CORRECTED TO 105 ON 12/28)",
        task: "J234",
        location: "18 Beacon Hill Ln Greenwood Village, CO 80111",
        status: "Storage",
        make: "MCS Black"
    },
]

function CollapseTable(props) {
    const { classes, history } = props;
    const itemsPerPage = 3;
    const [pageNumber, setPageNumber] = useState(0);
    const [index, setIndex] = useState(0)
    const [endIndex, setEndIndex] = useState(itemsPerPage)
    const [pageDropdown, setPageDrowdown] = useState("3")

    useEffect(() => {
        mockData.slice(index, endIndex)
    }, [index])
    const renderTableHeader = () => {
        return (
            <tr
                className={classes.tableRow}
                style={{
                    height: "38px",
                    border: "1px solid #8E8E8E",
                }}
            >
                {headers.map((key, index) => {
                    return (
                        <th className={classes.tableHeaders} key={key} style={{ fontSize: "14px" }}>
                            {key}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderTableData = () => {
        let tableData;
        if (pageDropdown == "3") {
            tableData = mockData.slice(
                pageNumber * itemsPerPage,
                pageNumber * itemsPerPage + itemsPerPage
            );
        }
        else {
            tableData = mockData
        }
        return tableData.map((d, index) => {
            return (
                <>
                    <tr className={classes.tableRow} key={d.name}>
                        <td style={{ background: "#FFFFFF" }} className={classes.tableCell2}>
                            {d.serial}
                        </td>
                        <td className={classes.tableCell2} style={{ background: "#FFFFFF" }}>
                            {d.barcode}
                        </td>
                        <td className={classes.tableCell2}>{d.condition}</td>
                        <td className={classes.tableCell2}>
                            <span className={classes.nameAndCompanyText}>
                                {d.task}
                            </span>
                        </td>
                        <td className={classes.tableCell2}>
                            <span className={classes.nameAndCompanyText}>
                                {d.location}
                            </span>
                        </td>
                        <td className={classes.tableCell2}>
                            <div
                                style={
                                    d.status === "Active"
                                        ? { background: "#3F9C42", fontWeight: 600, textAlign: "center" }
                                        : d.status === "Inactive"
                                            ? { background: "#F83030", fontWeight: 600, textAlign: "center" }
                                            : { color: "#323232", background: "#D9D9D9", display: "block", fontWeight: 600, textAlign: "center" }
                                }
                                className={classes.commentDiv}
                            >
                                {d.status}
                            </div>
                        </td>
                        <td className={classes.tableCell2}>{d.make}</td>
                        <td className={classes.tableCell2}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-evenly",
                                }}
                            >
                                <Tooltip
                                    id="tooltip-top"
                                    title="View"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        style={{ background: "#FFC821" }}
                                        className={classes.actionBtn}
                                    >
                                        <EyeIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Edit"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionBtn}
                                        style={{ background: "#323232" }}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id="tooltip-top"
                                    title="Delete"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <Button
                                        className={classes.actionBtn}
                                        style={{ background: "#FEDA1B" }}
                                    >
                                        <DenyIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </td>
                    </tr>
                </>
            );
        });
    };

    return (
        <GridContainer >
            <GridItem xs={12}>
                {/* <div style={pageDropdown != 4 && mockData.length > 4 ? { height: "550px", width: "100%", overflowY: "scroll" } : null}> */}
                <table className={classes.inventoryTable}>
                    <tbody>
                        {renderTableHeader()}
                        {renderTableData()}
                    </tbody>
                </table>
                {/* </div> */}
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.error,
        authenticated: state.auth.authenticated,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(customTableStyle)
)(React.memo(CollapseTable));
