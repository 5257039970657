function openDialog(dialog) {
    return {
        type: "DIALOG_OPEN",
        dialog
    }
}

function closeDialog(dialog) {
    return {
        type: "DIALOG_CLOSE",
        dialog
    }
}

export function actionsForDialog(dialog) {
    return {
        openDialog: () => openDialog(dialog),
        closeDialog: () => closeDialog(dialog)
    }
}

export function addCloseHandler(actions, closeHandler, errorAction) {
    return {
        ...actions,
        closeDialog: () => (dispatch, getState) => Promise.resolve(closeHandler(getState)).then(()=>dispatch(actions.closeDialog())).catch(error=>dispatch(errorAction(error)))
    }
}

