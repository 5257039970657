import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
const searchOptions = ["Customers", "Jobs", "Rentals", "Sites"];

function GlobalSearch(props) {
  const {
    classes,
    setFilterSelection,
    filterSelection,
    searchString,
    setSearchString,
    handleSearchQuery,
  } = props;

  return (
    <div
      style={{
        height: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "20%",
            justifyContent: "space-between",
          }}
        >
          <span className={classes.searchCategoryTitle} style={{ marginBottom: "6px" }}>Search Category</span>
          <Select
            style={{ height: "40px", padding: "0px 24px 0px 10px" }}
            className={classes.filterMenu}
            value={filterSelection}
            disableUnderline
            classes={{
              root: classes.selectText,
            }}
            onChange={(e) => setFilterSelection(e.target.value)}
          >
            {searchOptions.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {o}
                </MenuItem>
              );
            })}
          </Select>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{ visibility: "hidden" }}
            className={classes.searchCategoryTitle}
          >
            Search Category
          </span>
          <TextField
            className={classes.searchBar}
            placeholder="Search..."
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSearchQuery()
              }
            }}
            variant="outlined"
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
              endAdornment: (
                <InputAdornment className={classes.adournedEnd} position="end">
                  <Button
                    onClick={handleSearchQuery}
                    className={classes.searchButton}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(React.memo(GlobalSearch));
