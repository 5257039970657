import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import form from "./form";
import auth from "./auth";
import dialog from "./dialog";
import recordset from "./recordset";
import search from "./search/searchReducer";
import inventory from "./inventory/inventoryReducer";
import pickups from "./pickups/pickupsReducer";
import swaps from "./swaps/swapsReducer";
import quotes from "./quotes/quotesReducer";
import system from "./system";
import navigation from "./navigation";
import drivers from "./drivers/driversReducer";
import territories from "./territories/territoriesReducer";
import customers from "./customers/customerReducers";
import reservations from "./reservations/reservationsReducer";
import company from "./company/companyReducers";
import jobs from "./jobs/jobsReducer";
import creditMemos from "./creditMemos/creditMemosReducer";
import sites from "./sites/sitesReducer";
import messaging from "./messaging/messagingReducer";
import { config as leads } from "./recordsets/leads";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    dialog,
    system,
    navigation,
    form,
    search,
    customers,
    inventory,
    reservations,
    quotes,
    swaps,
    pickups,
    company,
    drivers,
    territories,
    jobs,
    creditMemos,
    sites,
    messaging,
    recordset: recordset({ leads }),
  });
