const initState = {
  customers: [],
  regulars: [],
};

export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_CUSTOMERS":
      return {
        ...state,
        customers: [...action.customers],
        regulars: [...action.regulars],
      };
    default:
      return state;
  }
}
