import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import { ReactComponent as CalendarIcon } from "assets/img/calendarIcon.svg";

import InventoryAvailabilityTable from "./components/InventoryAvailabilityTable";
import inventoryAvailabilityStyle from "assets/jss/material-dashboard-pro-react/views/inventoryAvailabilityStyle.js";
import Tooltip from "@material-ui/core/Tooltip";
import { MenuItem, Select } from "@material-ui/core";
import {
  getNextFourteen,
  getPrevFourteen,
  getDates,
  getNextFourteenWithInput,
  getNewDataFromCalendarSelection,
} from "../helpers/helpers";
import Datetime from "react-datetime";
import moment from "moment";


const yesterday = moment().subtract(1, "day");
const disablePastDt = (current) => {
  return current.isAfter(yesterday);
};


function InventoryAvailability(props) {
  const { classes, inventoryData } = props;
  const [defaultHeader, setDefaultHeader] = useState([]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [inventoryStartDate, setInventoryStartDate] = useState("");


  useEffect(() => {
    const defaultDays = getDates(
      new Date(),
      new Date().setDate(new Date().getDate() + 8)
    );
    setDefaultHeader(defaultDays);
    setInventoryStartDate(defaultDays[2]);
  }, []);

  const handlePreviousFourteenDays = () => {
    const prevNine = getPrevFourteen(defaultHeader[2]);
    setDefaultHeader(prevNine);
  };
  const handleNextFourteenDays = () => {
    const nextNine = getNextFourteen(defaultHeader[2]);
    setDefaultHeader(nextNine);
  };
  const handleNextFourteenDaysWithInput = (date) => {
    console.log('dateeee', date)
    const nextNine = getNextFourteenWithInput(date);
    setDefaultHeader(nextNine);
  };

  const handleToday = () => {
    const today = moment(new Date()).format("ddd MM/DD/YY");
    const nine = getDates(today);
    setDefaultHeader(nine);
  };

  return (
    <GridContainer>
      <GridItem className={classes.inventoryHeaderGridItem} xs={12}>
        <h1 className={classes.title}>Inventory Availability</h1>
        <span className={classes.seeMore}>View Inventory Settings</span>
      </GridItem>
      <GridItem className={classes.inventoryHeaderGridItem} style={{marginBottom:"13px"}}xs={12}>
        <div style={{ display: "flex" }}>
          <span className={classes.dateRange}>
            <p className={classes.today} onClick={handleToday}>
              Today{" "}
            </p>{" "}
            {" " + " " + defaultHeader[2]} - {defaultHeader[10]}
          </span>
          <Tooltip
            id="tooltip-top"
            title="Change Inventory Start Date"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              onClick={() => setCalendarOpen(true)}
              className={classes.calendarIconDiv}
              style={{background:"#929292"}}
            >
              <CalendarIcon />
            </div>
          </Tooltip>
          {calendarOpen && (
            <Datetime
              open={calendarOpen}
              isValidDate={disablePastDt}
              input
              // className={classes.textFieldRoot}
              style={{
                color: "#323232 !important",
                height: "100%",
                width: "100%",
              }}
              selected={inventoryStartDate}
              dateFormat="MM/DD/YY"
              onChange={(date, e) => {
                handleNextFourteenDaysWithInput(date);
                setCalendarOpen(false);
              }}
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside
            />
          )}
        </div>
      </GridItem>
      <GridItem xs={12}>
        <InventoryAvailabilityTable
          handleNextFourteenDays={handleNextFourteenDays}
          handlePreviousFourteenDays={handlePreviousFourteenDays}
          defaultHeader={defaultHeader}
          inventoryData={inventoryData}
          classes={classes}
        />
      </GridItem>

      {/* <GridContainer>
              <GridItem xs={6}>
                <p style={{ fontWeight: "bold", fontSize: "18px" }}>View
                  <span>
                    <Select
                      style={{ width: "20%", marginLeft: "10px", marginTop: "5px", textAlign: "center" }}
                      disableUnderline
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                        icon: classes.whiteColor
                      }}
                      onChange={(e) => {
                        setPageDrowdown(e.target.value)
                      }}
                      value={pageDropdown}
                    >
                      {pageArr.map((d, index) => {
                        return (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={d}
                          >
                            {d}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </span>
                </p>
              </GridItem>
              <GridItem xs={5}>
              <h5 style={{ fontWeight: "bold", display:"flex", justifyContent:"flex-end"}}>{index + 1} - {endIndex} of {Math.ceil(inventoryData.length / itemsPerPage)}</h5>
            </GridItem>
              <GridItem xs={1}>
                <ReactPaginate
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(inventoryData.length / itemsPerPage)}
                  nextLabel={<ArrowRight style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                  previousLabel={<ArrowLeft style={{ fontSize: 30, fontWeight: "bold", color: "white", marginBottom: "-10px" }} />}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </GridItem>
            </GridContainer> */}
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    inventoryData: state.inventory.inventory,
    customerData: state.customers.customers,
    regularsData: state.customers.regulars,
    pendingReservationsData: state.reservations.pendingReservations,
    reservationsData: state.reservations.reservations,
    pickupsData: state.pickups.pickups,
    quotesData: state.quotes.quotes,
    swapsData: state.swaps.swaps,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(inventoryAvailabilityStyle)
)(React.memo(InventoryAvailability));
