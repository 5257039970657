export const MockUsers = [
  {
    name: "Rick Mayo",
    company: "Kordspace",
    email: "rick@test.com",
    address: "3792 S Acoma St Englewood, CO",
    phoneNumber: "(303) 432 - 3235",
    comments: ["Regular"],
    rental: {
      rentalNumber: "JS2115",
      paymentDueDate: "November 1, 2022",
      amount: "387.00",
      amountPaid: "50.00",
      lastEmailedDate: "October 25, 2022",
    },
    reservation: {
      equiptment: "15 Yard Container",
      quantity: 1,
      startDate: "Oct 13, 2022",
    },
  },
  {
    name: "Justin Turner",
    company: "Apple",
    address: "3792 S Acoma St Englewood, CO",
    phoneNumber: "(303) 432 - 3235",
    email: "justin@test.com",
    comments: ["Regular", "Cautious", "Overdue"],
    rental: {
      rentalNumber: "JS2235",
      paymentDueDate: "November 2, 2022",
      amount: "215.00",
      amountPaid: "50.00",
      lastEmailedDate: "October 25, 2022",
    },
    reservation: {
      equiptment: "15 Yard Container",
      quantity: 1,
      startDate: "Oct 13, 2022",
    },
  },
  {
    name: "Alex Smith",
    company: "Facebook",
    email: "alex@test.com",
    address: "3792 S Acoma St Englewood, CO",
    comments: ["Cautious", "New"],
    phoneNumber: "(303) 432 - 3235",
    rental: {
      rentalNumber: "JS1035",
      paymentDueDate: "November 5, 2022",
      amount: "515.00",
      amountPaid: "50.00",
      lastEmailedDate: "October 25, 2022",
    },
    reservation: {
      equiptment: "15 Yard Container",
      quantity: 1,
      startDate: "Oct 13, 2022",
    },
  },
  {
    name: "Bryan Callen",
    company: "Netflix",
    email: "bryan@test.com",
    address: "3792 S Acoma St Englewood, CO",
    comments: ["Regular", "Overdue", "Unpaid"],
    rental: {
      rentalNumber: "JS8835",
      paymentDueDate: "November 5, 2022",
      amount: "265.00",
      amountPaid: "265.00",
      lastEmailedDate: "October 25, 2022",
    },
    phoneNumber: "(303) 432 - 3235",
    reservation: {
      equiptment: "15 Yard Container",
      quantity: 1,
      startDate: "Oct 13, 2022",
    },
  },
];

export const MockTrucks = [
  {
    truckNumber: "1",
    driver: "Chris W",
    model: "2003 Freightliner FL50",
    lastServiced: "165,400",
    serviceDue: "180,000",
    recommendedServices: [
      { miles: "180,000", service: "Rotate Tires, Change Oil and Filter" },
      { miles: "190,000", service: "Rotate Tires, Change Oil and Filter" },
      {
        miles: "200,000",
        service:
          "Replace Air Filter, Rotate Tires, Fuel Filter Replacement, Change Oil and Filter",
      },
      { miles: "210,000", service: "Rotate Tires, Change Oil and Filter" },
      {
        miles: "220,000",
        service:
          "Replace Air Filter, Rotate Tires, Fuel Filter Replacement, Change Oil and Filter",
      },
      { miles: "230,000", service: "Rotate Tires, Change Oil and Filter" },
    ],
  },
  {
    truckNumber: "2",
    driver: "John S",
    model: "2006 Freightliner FL50",
    lastServiced: "155,400",
    serviceDue: "180,000",
    recommendedServices: [
      { miles: "180,000", service: "Rotate Tires, Change Oil and Filter" },
      { miles: "190,000", service: "Rotate Tires, Change Oil and Filter" },
      {
        miles: "200,000",
        service:
          "Replace Air Filter, Rotate Tires, Fuel Filter Replacement, Change Oil and Filter",
      },
      { miles: "210,000", service: "Rotate Tires, Change Oil and Filter" },
      {
        miles: "220,000",
        service:
          "Replace Air Filter, Rotate Tires, Fuel Filter Replacement, Change Oil and Filter",
      },
      { miles: "230,000", service: "Rotate Tires, Change Oil and Filter" },
    ],
  },
  {
    truckNumber: "3",
    driver: "Alex E",
    model: "2008 Freightliner FL50",
    lastServiced: "145,400",
    serviceDue: "190,000",
    recommendedServices: [
      { miles: "190,000", service: "Rotate Tires, Change Oil and Filter" },
      {
        miles: "200,000",
        service:
          "Replace Air Filter, Rotate Tires, Fuel Filter Replacement, Change Oil and Filter",
      },
      { miles: "210,000", service: "Rotate Tires, Change Oil and Filter" },
      {
        miles: "220,000",
        service:
          "Replace Air Filter, Rotate Tires, Fuel Filter Replacement, Change Oil and Filter",
      },
      { miles: "230,000", service: "Rotate Tires, Change Oil and Filter" },
    ],
  },
  {
    truckNumber: "4",
    driver: "Joe P",
    model: "2010 Freightliner FL50",
    lastServiced: "133,400",
    serviceDue: "190,000",
    recommendedServices: [
      { miles: "190,000", service: "Rotate Tires, Change Oil and Filter" },
      {
        miles: "200,000",
        service:
          "Replace Air Filter, Rotate Tires, Fuel Filter Replacement, Change Oil and Filter",
      },
      { miles: "210,000", service: "Rotate Tires, Change Oil and Filter" },
      {
        miles: "220,000",
        service:
          "Replace Air Filter, Rotate Tires, Fuel Filter Replacement, Change Oil and Filter",
      },
      { miles: "230,000", service: "Rotate Tires, Change Oil and Filter" },
    ],
  },
];

export const MockDumpsters = [
  {
    dumpsterNumber: "1",
    barCode: "0 36000 29145 2",
    recommendedServices: [{ months: "2", service: "Inspect For Damage" }],
  },
  {
    dumpsterNumber: "2",
    barCode: "0 83462 37281 2",
    recommendedServices: [{ months: "4", service: "Inspect For Damage" }],
  },
  {
    dumpsterNumber: "3",
    barCode: "0 27233 29145 2",
    recommendedServices: [{ months: "6", service: "Inspect For Damage" }],
  },
  {
    dumpsterNumber: "4",
    barCode: "0 96048 29145 2",
    recommendedServices: [{ months: "8", service: "Inspect For Damage" }],
  },
  {
    dumpsterNumber: "5",
    barCode: "1 27152 03983 8",
    recommendedServices: [{ months: "10", service: "Inspect For Damage" }],
  },
  {
    dumpsterNumber: "6",
    barCode: "0 94736 38271 9",
    recommendedServices: [{ months: "12", service: "Inspect For Damage" }],
  },
];
