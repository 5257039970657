/* eslint-disable react/prop-types */
import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import profileStyle from "assets/jss/material-dashboard-pro-react/views/profileStyle.js";
import Card from "components/Card/Card";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import { firebase, db, storage } from "firebase/client";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import loader from "assets/img/loader.gif";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as BiggerLogo } from "assets/img/biggerLogo.svg";
import { ReactComponent as Upload } from "assets/img/upload.svg";
import { ReactComponent as DenyIcon } from "assets/img/denyIcon.svg";

function PermissionLevelCard(props) {
  const { classes, permissionLevel, setPermissionLevel } = props;

  return (
    <Card style={{ marginBottom: "0px" }} className={classes.card} style={{padding: "30px 30px 0px 30px"}}>
      <GridContainer>
        <GridItem className={classes.columnGridItem} xs={12}>
          <span
            // style={{ marginBottom: "30px" }}
            className={classes.permissionTitle}
          >
            Permission Level
          </span>
        </GridItem>
        <GridItem className={classes.columnGridItem} xs={6}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Radio
              checked={permissionLevel.superAdmin}
              onChange={() =>
                setPermissionLevel({
                  superAdmin: true,
                  finance: false,
                  dispatcher: false,
                  driver: false,
                })
              }
              sx={{
                "&, &.Mui-checked": {
                  color: "blue",
                },
              }}
              aria-label="A"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot,
              }}
            />
            <span className={classes.values}>Super Admin</span>
          </div>
          <span style={{ marginBottom: "20px" }} className={classes.values}>
            Has full access except for deleting admin staff and account owner
          </span>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Radio
              checked={permissionLevel.dispatcher}
              onChange={() =>
                setPermissionLevel({
                  superAdmin: false,
                  finance: false,
                  dispatcher: true,
                  driver: false,
                })
              }
              sx={{
                "&, &.Mui-checked": {
                  color: "blue",
                },
              }}
              aria-label="A"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot,
              }}
            />
            <span className={classes.values}>Dispatcher</span>
          </div>
          <span style={{ marginBottom: "20px" }} className={classes.values}>
            Can view schedule and accounting, plus view and edit customers
          </span>
        </GridItem>

        <GridItem className={classes.columnGridItem} xs={6}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Radio
              checked={permissionLevel.finance}
              onChange={() =>
                setPermissionLevel({
                  superAdmin: false,
                  finance: true,
                  dispatcher: false,
                  driver: false,
                })
              }
              sx={{
                "&, &.Mui-checked": {
                  color: "blue",
                },
              }}
              aria-label="A"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot,
              }}
            />
            <span className={classes.values}>Finance</span>
          </div>
          <span style={{ marginBottom: "20px" }} className={classes.values}>
            Can view schedule and accounting, view and export financial reports
            plus view and edit customers
          </span>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Radio
              checked={permissionLevel.driver}
              onChange={() =>
                setPermissionLevel({
                  superAdmin: false,
                  finance: false,
                  dispatcher: false,
                  driver: true,
                })
              }
              sx={{
                "&, &.Mui-checked": {
                  color: "blue",
                },
              }}
              aria-label="A"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot,
              }}
            />
            <span className={classes.values}>Driver</span>
          </div>
          <span style={{ marginBottom: "20px" }} className={classes.values}>
            Can view schedule and jobs
          </span>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(profileStyle)
)(React.memo(PermissionLevelCard));
