import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import LeadsExplorer from "./leads/LeadsExplorer";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
//import logo from "assets/img/logo.svg";

// actions
// import AuthActions from "store/actions/auth";

import explorerPageStyle from "assets/jss/material-dashboard-pro-react/views/explorerPageStyle.js";

export function ExplorerPage(props) {

  const { classes } = props;

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4} className={classes.formContainer}>
          <p
            style={{ padding: "0px 15px 0px 27px" }}
            className={classes.subdescription}
          >
            This where the Explorer Page explanation/header area will be
          </p>
        </GridItem>
      </GridContainer>
      <LeadsExplorer></LeadsExplorer>
    </div>
  );
}

ExplorerPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(explorerPageStyle)(ExplorerPage);
