import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import ProgressBar from "@ramonak/react-progress-bar";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/CustomButtons/Button.js";
import loader from "assets/img/loader.gif";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import Datetime from "react-datetime";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { addPendingReservationExistingCustomer } from "store/actions/reservations/reservationsActions";
import { getOneWeekLater } from "../../Dashboard/helpers/helpers.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import pointInPolygon from "point-in-polygon";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
// import "../calendar.css"
// import TempReservationForm from "../TempReservationForm.jsx";

function NewFormStepTwo(props) {
  const {
    classes,
    nextStep,
    prevStep,
    step,
    errors,
    billingAddress,
    setBillingAddress,
    handleSelect,
    addressLine2,
    setAddressLine2,
    siteAddress,
    setSiteAddress,
    dropOffDate,
    setDropOffDate,
    disablePastDt,
    sevenDayRental,
    setSevenDayRental,
    pickupDate,
    setPickupDate,
    previousAddress,
    setPreviousAddress,
    previousSites,
    customerInfo,
    dropOffAddressLine2,
    setDropOffAddressLine2
  } = props;
  const disablePastDropOff = (current) => {
    return current.isAfter(dropOffDate);
  };

  return (
    <Card className={classes.tempCard}>
      <span style={{ justifyContent: "flex-end", display: "flex" }}>
        <p style={{ fontWeight: "600" }}>
          2/3
        </p>
      </span>
      <h5 className={classes.formTitle}>
        Billing Address:{" "}
        {errors.billingAddress && (
          <span style={{ color: "red" }}>Required*</span>
        )}{" "}
      </h5>
      <GooglePlacesAutoComplete
        classes={classes}
        value={billingAddress}
        width={"100%"}
        placeholder="Search your billing address.."
        onChange={(e) => setBillingAddress(e)}
        onSelect={handleSelect}
        margin="0px 0px 20px 0px"
        background="#F5F5F5"
        googleCallbackName="initOne"
      />

      <TextField
        className={classes.emailInput}
        value={addressLine2}
        placeholder="Address line 2 (optional)"
        variant="outlined"
        onChange={(e) => setAddressLine2(e.target.value)}
        InputProps={{
          classes: {
            adornedEnd: classes.adournedEnd,
          },
        }}
      />

      {customerInfo ?
        <>
          <h5 className={classes.formTitle}>
            Drop-Off Address:{" "}
            {errors.siteAddress && <span style={{ color: "red" }}>Required*</span>}{" "}
          </h5>

          <span>Do you want to use a previous address?</span>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", marginRight: "30px" }}
            >
              <Checkbox
                checked={previousAddress}
                style={{
                  color: "#FFC720",
                }}
                onChange={() => setPreviousAddress(true)}
              />
              <span>Yes</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={!previousAddress ? true : false}
                style={{
                  color: "#FFC720",
                }}
                onChange={() => setPreviousAddress(false)}
              />
              <span>No</span>
            </div>
          </div>
          {previousAddress ? (
            <Select
              className={classes.filterMenu}
              disableUnderline
              defaultValue={"Select Option"}
              classes={{
                root: classes.selectText,
              }}
              onChange={(e) => {
                handleSelect(e.target.value, "Search your drop-off address..");
              }}
            >
              {previousSites.map((o, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={o}
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {o}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <>
              <GooglePlacesAutoComplete
                classes={classes}
                value={siteAddress}
                width={"100%"}
                placeholder="Search your drop-off address.."
                onChange={(e) => setSiteAddress(e)}
                onSelect={handleSelect}
                margin="0px 0px 20px 0px"
                background="#F5F5F5"
                googleCallbackName="initTwo"
              />
              <TextField
                className={classes.emailInput}
                value={dropOffAddressLine2}
                placeholder="Drop-off Address line 2 (optional)"
                variant="outlined"
                onChange={(e) => setDropOffAddressLine2(e.target.value)}
                InputProps={{
                  classes: {
                    adornedEnd: classes.adournedEnd,
                  },
                }}
              />
            </>
          )}
        </>
        :
        <>
          <h5 className={classes.formTitle}>
            Drop-Off Address:{" "}
            {errors.siteAddress && <span style={{ color: "red" }}>Required*</span>}{" "}
          </h5>
          <GooglePlacesAutoComplete
            classes={classes}
            value={siteAddress}
            width={"100%"}
            placeholder="Search your drop-off address.."
            onChange={(e) => setSiteAddress(e)}
            onSelect={handleSelect}
            margin="0px 0px 20px 0px"
            background="#F5F5F5"
            googleCallbackName="initTwo"
          />
          <TextField
            className={classes.emailInput}
            value={dropOffAddressLine2}
            placeholder="Drop-off Address line 2 (optional)"
            variant="outlined"
            onChange={(e) => setDropOffAddressLine2(e.target.value)}
            InputProps={{
              classes: {
                adornedEnd: classes.adournedEnd,
              },
            }}
          />
        </>
      }



      <h5 className={classes.formTitle}>
        Requested Drop-Off Date:{" "}
        {errors.dropOffDate && <span style={{ color: "red" }}>Required*</span>}{" "}
      </h5>
      <Datetime
        input
        isValidDate={disablePastDt}
        // className={classes.textFieldRoot}
        type="text"
        inputProps={{
          className: classes.datetime,
          placeholder: "Choose rental drop-off date..",
        }}
        style={{
          color: "#323232 !important",
          height: "100%",
          width: "100%",
        }}
        selected={dropOffDate}
        dateFormat="MM/DD/YY"
        onChange={(date, e) => {
          if (typeof date === "string") {
          } else {
            setDropOffDate(date.format("MM/DD/YY"));
          }
        }}
        timeFormat={false}
        closeOnSelect={true}
        closeOnClickOutside
      />
      {dropOffDate && (
        <>
          <h5 className={classes.formTitle}>
            Requested Pick Up Date:{" "}
            {errors.pickupDate && (
              <span style={{ color: "red" }}>Required*</span>
            )}
          </h5>
          <span>Do you want a 7 day rental?</span>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <Checkbox
                checked={sevenDayRental === "Yes"}
                disabled={!dropOffDate ? true : false}
                style={{
                  color: "#FFC720",
                }}
                onChange={() => {
                  setSevenDayRental("Yes");
                  setPickupDate(getOneWeekLater(dropOffDate));
                  setPickupDate("")
                }}
              />
              <span>Yes</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                disabled={!dropOffDate ? true : false}
                checked={sevenDayRental === "No"}
                style={{
                  color: "#FFC720",
                }}
                onChange={() => {
                  setSevenDayRental("No")
                }}

              />
              <span>No</span>
            </div>
          </div>
        </>
      )}

      {sevenDayRental && sevenDayRental === "No" && dropOffDate &&(
        <>
          <h5 className={classes.formTitle}>
            *PLEASE NOTE WE WILL PICK UP ON THE DAY YOU SELECT BELOW. Please
            ensure you select the correct pick up date for your desired rental
            duration.{" "}
          </h5>
          <Datetime
            className="datePicker"
            input
            isValidDate={disablePastDropOff}
            // className={classes.textFieldRoot}
            inputProps={{
              className: classes.datetime,
              placeholder: "Choose rental pickup date..",
            }}
            style={{
              color: "#323232 !important",
              height: "100%",
              width: "100%",
            }}
            selected={pickupDate}
            dateFormat="MM/DD/YY"
            onChange={(date, e) => {
              if (typeof date === "string") {
              } else {
                setPickupDate(date.format("MM/DD/YY"));
              }
            }}
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside
          />
        </>
      )}

      {!billingAddress ||
        !siteAddress ||
        !dropOffDate ||
        !sevenDayRental ||
        (sevenDayRental === "No" && !pickupDate) ?
        <Button
          disabled
          style={{ background: "#FFC821", color: "#323232" }}
          onClick={() => nextStep()}
        >
          Next
        </Button>
        : <Button
          style={{ background: "#FFC821", color: "#323232" }}
          onClick={() => nextStep()}
        >
          Next
        </Button>}
      <Button
        style={{
          background: "#323232",
          color: "white"
        }}
        onClick={() => prevStep()}>
        Go Back
      </Button>

      {/* {billingAddress &&
        siteAddress &&
        dropOffDate &&
        (sevenDayRental === "Yes" ||
          sevenDayRental === "No" && pickupDate) ?
        <ProgressBar
          margin="15px 0px 0px 0px"
          completed={66}
          isLabelVisible={false}
          bgColor="#323232"
          animateOnRender
          maxCompleted={100}
        />
        :
        <ProgressBar
          margin="15px 0px 0px 0px"
          completed={33}
          isLabelVisible={false}
          bgColor="#323232"
          animateOnRender
          maxCompleted={100}
        />
      } */}
      {/* <ProgressBar
        margin="15px 0px 0px 0px"
        completed={33}
        isLabelVisible={false}
        bgColor="#323232"
        animateOnRender
        maxCompleted={100}
      /> */}
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  handleAddPendingReservationExistingCustomer: (pendingRes) =>
    dispatch(addPendingReservationExistingCustomer(pendingRes)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(NewFormStepTwo));
