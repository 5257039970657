const serviceRemindersStyle = (theme) => ({
  title: {
    fontWeight: 700,
    fontSize: "30px",
    fontFamily: "Archivo",
    color: "#323232",
    margin: "0px",
    textAlign: "left"
  },
  tableRow: {
    height: "75px",
  },
  flexGrid: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  upcomingPaymentsGridItem: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px",
  },
  upcomingPaymentsGridItemEnd: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    justifyContent: "end",
  },
  tableHeaders: {
    border: "0.5px solid #8E8E8E",
    background: "#FFC720",
    color: "#323232",
    fontFamily: "Archivo",
    fontSize: "17px",
  },
  addEditButton: {
    display: "flex",
    justifyContent: "space-evenly",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    width: "50%",
    margin: "5px",
    alignItems: "center",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  addEditButtonSmaller: {
    display: "flex",
    justifyContent: "space-evenly",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "10px",
    width: "40%",
    height:"70%",
    margin: "5px",
    alignItems: "center",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableCell: {
    textAlign: "center",
    border: "0.5px solid #8E8E8E",
    padding: "20px",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "19px",
    background: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      fontSize: "14px",
    },
  },
  searchGridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
  inventoryHeaderGridItem: {
    display: "flex",
    justifyContent: "space-between",
    height: "100px",
  },
  inventoryTable: {
    width: "100% !important",
    borderCollapse: "collapse",
    // [theme.breakpoints.down("md")]: {
    //   tableLayout: "fixed",
    //   width: "100%",
    // },
  },
  actionBtn: {
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    padding: "15px",
    height: "50px",
  },
  subHeader: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "26px",
    color: "#323232",
  },
  paymentsDue: {
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
  },
  searchBar: {
    width: "60%",
    background: "white",
  },
  adournedEnd: {
    paddingRight: "0px !important",
    margin: "0px !important",
    maxHeight: "none !important",
  },
  searchButton: {
    background: "#FFC720",
    display: "block",
    margin: "0px !important",
    padding: "16px 30px !important",
    color: "#323232",
    "&:hover": {
      background: "#FFC720",
    },
  },
  toggleDiv: {
    display: "flex",
    width: "50%",
    border: "0.5px solid #8E8E8E",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: "50px",
  },
  toggleSelection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "50%",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "17px",
    lineHeight: "18px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#323232",
    padding: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  card: {
    overflow: "hidden",
    border: "1px solid #8E8E8E",
    borderRadius: "2px",
  },
  truckGridContainer: {
    // paddingLeft: "20px",
  },
  gridItemWithPadding: {
    padding: "20px 45px !important",
  },
  serviceDueDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FFC720",
    borderLeft: "1px solid #8E8E8E",
    borderBottom: "1px solid #8E8E8E",
    height: "100%",
    justifyContent: "center",
  },
  recommendedMaintenance: {
    paddingBottom: "20px !important"
    // padding: "30px 0px 10px 20px !important",
  },
  recommendedServicesYellow: {
    background: "#F1C84E",
    border: "0.5px solid #8E8E8E",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px !important",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
  },
  recommendedServicesBlue: {
    background: "#FFFFFF",
    border: "0.5px solid #8E8E8E",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px !important",
    fontFamily: "Archivo",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
  },
  conditionDiv: {
    width: "100%",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    color: "white",
    padding: "10px",
    textAlign: 'center',
    fontWeight: 600,
    fontSize: "17px"
  },
  dumpsterGridContainer: {
    width: "100% !important",
    margin: "0px auto !important"
  },
  noPaddingRight: {
    paddingRight: "0px !important"
  },
  dumpsterIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20px !important",
  paddingBottom: "20px !important"
  },
  dumpsterNumber: {
  paddingTop: "20px !important",
  paddingBottom: "20px !important"
  }
});

export default serviceRemindersStyle;
