export const messageKey = {
  "24 hr Service Reminder": `<p style="margin-left:0px;"><span style="color: var(--color-text,#444);background-color: rgb(255,255,255);font-size: 16px;font-family: Inter, sans-serif;">Hi this is Humpty Dump! We have you on the schedule for tomorrow with these details: </span></p>
<p><span style="color: var(--color-text,#444);background-color: rgb(255,255,255);font-size: 16px;font-family: Inter, sans-serif;">Drop Off: </span></p>
<p><span style="color: var(--color-text,#444);background-color: rgb(255,255,255);font-size: 16px;font-family: Inter, sans-serif;">Pick Up: </span></p>
<p><span style="color: var(--color-text,#444);background-color: rgb(255,255,255);font-size: 16px;font-family: Inter, sans-serif;">Dumpster Size: </span></p>
<p><span style="color: var(--color-text,#444);background-color: rgb(255,255,255);font-size: 16px;font-family: Inter, sans-serif;">Placement: </span></p>
<p><span style="color: var(--color-text,#444);background-color: rgb(255,255,255);font-size: 16px;font-family: Inter, sans-serif;">Job Address: </span></p>
<p><span style="color: var(--color-text,#444);background-color: rgb(255,255,255);font-size: 16px;font-family: Inter, sans-serif;">If the details of your reservation are incorrect, please reach out to us at <strong>(303) 334- 3423</strong> to fix it before tomorrow! </span></p>
<p><span style="color: var(--color-text,#444);background-color: rgb(255,255,255);font-size: 16px;font-family: Inter, sans-serif;">Best, </span></p>
<p><span style="color: var(--color-text,#444);background-color: rgb(255,255,255);font-size: 16px;font-family: Inter, sans-serif;">Humpty Dump</span><span style="font-size: 16px;"><br></span></p>`,
};