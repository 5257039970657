import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import { db } from "firebase/client";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle from "assets/jss/material-dashboard-pro-react/views/customerInfoStyle.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";

import AccountingCustomTabs from "./AccountingCustomTabs";
// import AccountingInvoicesTab from "./components/AccountingInvoicesTab";
// import AccountingPaymentsTab from "./components/AccountingPaymentsTab";
// import AccountingRefunds from "./components/AccountingRefunds";

import { setPastReservations } from "store/actions/reservations/reservationsActions";
import loader from "assets/img/loader.gif";
import AccountingInvoicesTab from "./AccountingInvoicesTab";
import MiscChargesTab from "./MiscChargesTab";

function Accounting(props) {
  const {
    classes,
    reservationsData,
    pastReservations,
    handleSetPastReservations,
    history,
    invoices,
    timeSelect,
    setTimePeriod,
    timePeriod,
    invoicesLoaded,
    tabName,
    setTabName
  } = props;
  // const [invoices, setInvoices] = useState(false);
  const [payments, setPayments] = useState(false);
  // useEffect(() => {
  //   if (!invoices) {
  //     const getInvoices = async () => {
  //       return db
  //         .collection("invoices")
  //         .get()
  //         .then((snapshots) => {
  //           if (!snapshots.empty) {
  //             const allInvoices = [];
  //             const customerPromiseArray = [];
  //             snapshots.forEach((s, index) => {
  //               const invoiceIndex = index;
  //               s = s.data();
  //               allInvoices.push(s);
  //               customerPromiseArray.push(
  // db.collection("customers").doc(s.customerId).get()
  //               );
  //             });
  //             return Promise.all(customerPromiseArray).then((results) => {
  //               results.forEach((snapshot, index) => {
  //                 snapshot = snapshot.data();
  //                 allInvoices[index] = {
  //                   ...allInvoices[index],
  //                   customerInfo: snapshot,
  //                 };
  //               });
  //               setInvoices(allInvoices);
  //             });
  //           } else {
  //             setInvoices([]);
  //           }
  //         });
  //     };
  //     getInvoices();
  //   }
  // }, []);

  if (!invoices)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );

  return (
    <Card style={{ padding: "10px 30px 0px 30px", marginTop: "0px" }}>
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12}>
              <AccountingCustomTabs
                classes={classes}
                tabs={[
                  {
                    tabName: "Invoices",
                    tabContent: (
                      <AccountingInvoicesTab
                        history={history}
                        invoices={invoices}
                        timeSelect={timeSelect}
                        setTimePeriod={setTimePeriod}
                        timePeriod={timePeriod}
                        invoicesLoaded={invoicesLoaded}
                        tabName={tabName}
                        setTabName={setTabName}
                      />
                    ),
                  },
                  {
                    tabName: "Misc. Charges",
                    tabContent: (
                      <MiscChargesTab
                        invoices={invoices}
                        history={history}
                        tabName={tabName}
                        setTabName={setTabName}
                      />
                    ),
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Card>
  );
}

const mapStateToProps = (state) => {
  console.log("state.reservations", state.reservations);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    reservationsData: state.reservations.reservations,
    pastReservations: state.reservations.pastReservations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSetPastReservations: (pastReservations) =>
    dispatch(setPastReservations(pastReservations)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(customerInfoStyle)
)(Accounting);
