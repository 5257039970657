const initState = {
  jobs: [],
};
export default function reduce(state = initState, action) {
  switch (action.type) {
    case "SET_JOBS":
      return {
        ...state,
        jobs: [...action.jobs],
      };
    default:
      return state;
  }
}
