import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card";
import addNewSiteStyle from "assets/jss/material-dashboard-pro-react/views/addNewSiteStyle.js";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { ReactComponent as AddIcon } from "assets/img/addIcon.svg";
import { saveTerritory } from "store/actions/territories/territoriesActions";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as SearchIcon } from "assets/img/searchIcon.svg";
import { db } from "firebase/client";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "components/CustomButtons/Button.js";
import GooglePlacesAutoComplete from "components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { styled } from "@material-ui/core/styles";
import loader from "assets/img/loader.gif";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import GoogleMap from "./components/GoogleMap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

function EditSite(props) {
  const {
    classes,
    history,
    handleSaveTerritory,
    territoriesData,
    customerData,
  } = props;

  const [sameAsAddress, setSameAsAddress] = useState(false);
  const [customerBillingAddress, setCustomerBillingAddress] = useState("");
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [siteAddress, setSiteAddress] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [notes, setNotes] = useState("");
  const [internalNotes, setInternalNotes] = useState("");
  const [contacts, setContacts] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [siteOwner, setSiteOwner] = useState(false);
  const [city, setCity] = useState("");
  const [snackBarContent, setSnackBarContent] = useState("");

  const [site, setSite] = useState(false);
  const siteID = window.location.pathname.split("/")[3];

  const handleSelect = (address) => {
    setSiteAddress(address);
  };

  useEffect(() => {
    if (!site) {
      const getSiteInfo = async () => {
        return db
          .collection("sites")
          .doc(siteID)
          .onSnapshot((snapshot) => {
            console.log("snapshot", snapshot, siteID);
            snapshot = snapshot.data();
            const contactsPromiseArray = snapshot.contacts.map((c) =>
              db.collection("customers").doc(c).get()
            );
            Promise.all(contactsPromiseArray).then((results) => {
              const contactsData = results.map((r) => r.data());
              return db
                .collection("customers")
                .doc(snapshot.siteOwner)
                .get()
                .then((siteOwnerSnap) => {
                  console.log('look site', siteOwnerSnap)
                  if (siteOwnerSnap.exists) {
                    const siteOwnerData = siteOwnerSnap.data();
                    setCustomerBillingAddress(siteOwnerData.billingAddress);
                    setSiteOwner({ ...siteOwnerData });
                  }
                  setSameAsAddress(true);
                  setContacts(contactsData);
                  setSiteName(snapshot.siteName);
                  setNotes(snapshot.notes);
                  setInternalNotes(snapshot.internalNotes);
                  setLat(snapshot.lat);
                  setLng(snapshot.lng);
                  setCity(snapshot.city);
                  setSiteAddress(snapshot.siteAddress);
                  setSite({ ...snapshot });
                });
            });
          });
      };
      getSiteInfo();
    }
  }, []);

  console.log("latttlng", lat, lng);

  const handleLatLng = () => {
    geocodeByAddress(siteAddress).then((results) => {
      console.log("results", results);
      const addressComponents = results[0].address_components;
      for (var x = 0; x < addressComponents.length; x++) {
        var current = addressComponents[x];
        if (current && current.types.includes("locality")) {
          setCity(current.long_name);
        }
      }
      const latitude = results[0].geometry.location.lat();
      const longitude = results[0].geometry.location.lng();
      setLng(longitude);
      setLat(latitude);
    });
  };

  const handleContacts = () => {
    const docMap = contacts.map((c) => {
      const customerDoc = db.collection("customers").doc(c).get();
      if (customerDoc.exists) {
        return customerDoc.data();
      } else {
        return null;
      }
    });
    return docMap.filter((d) => d);
  }

  useEffect(() => {
    if (contacts.length) {
      setContacts(handleContacts());
    }
  }, [contacts]);

  const clearLocation = () => {
    setLat("");
    setLng("");
    setSiteAddress("");
    setSameAsAddress("");
  };

  const saveSite = () => {
    return db
      .collection("sites")
      .doc(siteID)
      .update({
        siteName,
        lng,
        lat,
        siteAddress,
        notes,
        internalNotes,
        contacts: contacts.map((c) => c.customerId),
        siteOwner: siteOwner.customerId,
        city: city ? city : "N/A",
      })
      .then(() => {
        setSnackBarContent("Successfully Update Site");
        // history.push("/admin/schedule");
      });
  };

  if (!site)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "20%" }} src={loader} alt="..." />
      </div>
    );

  return (
    <>
      <h1 className={classes.title}>Edit Site {siteID}</h1>
      <Card className={classes.card}>
        <GridContainer>
          <GridItem className={classes.columnGridItem} xs={4}>
            <span className={classes.inputTypeTitle}>Nickname</span>
            <TextField
              onChange={(e) => setSiteName(e.target.value)}
              variant="outlined"
              value={siteName}
            />
          </GridItem>
          <GridItem className={classes.columnGridItem} xs={4}>
            <span className={classes.inputTypeTitle}>Site Owner</span>
            <Autocomplete
              id="country-select-demo"
              // style={{ width: 180 }}
              value={siteOwner.name}
              options={customerData.map((c) => c.name)}
              classes={{
                option: classes.option,
              }}
              onChange={(e) => {
                const index = e.target.id.split("-")[4];
                console.log("indexx", e.target.id.split("-"));
                setCustomerBillingAddress(customerData[index].billingAddress);
                setSiteOwner(customerData[index]);
                setSelectedCustomerName(customerData[index].name);
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <SearchIcon
                          className={classes.calendarIcon}
                          // onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {customerBillingAddress && (
              <span
                style={{ marginTop: "10px" }}
                className={classes.inputTypeTitle}
              >
                {customerBillingAddress}
              </span>
            )}
          </GridItem>
          <GridItem className={classes.columnGridItem} xs={4}>
            <span className={classes.inputTypeTitle}>Address</span>
            <GooglePlacesAutoComplete
              classes={classes}
              value={siteAddress}
              width={"100%"}
              placeholder="Search addresses"
              onChange={(e) => setSiteAddress(e)}
              onSelect={handleSelect}
              margin="0px 0px 20px 0px"
              background="white"
              googleCallbackName="initOne"
            />
            <div
              style={{
                display: "flex",
                margin: "15px 0px",
                alignItems: "center",
              }}
            >
              <Checkbox
                onChange={() => {
                  handleSelect(customerBillingAddress);
                  setSameAsAddress(!sameAsAddress);
                }}
                checked={sameAsAddress}
                disabled={!customerBillingAddress}
              />
              <span className={classes.sameAsOwnerAddress}>
                Same as Owner’s Billing Address
              </span>
            </div>
            <span className={classes.siteAddressInfo}>
              This is the site address that will appear on jobs, rentals,
              service tickets, emails and PDFs.
            </span>
          </GridItem>
        </GridContainer>
      </Card>

      <Card className={classes.card}>
        <GridContainer>
          <GridItem className={classes.columnGridItem} xs={12}>
            <span className={classes.inputTypeTitle}>Location</span>
            <span className={classes.siteAddressInfo}>
              Latitude and longitude coordinates are used for routing and
              directions. To use the site address to calculate coordinates,
              click the button below:
            </span>
          </GridItem>
          <GridItem className={classes.marginTop} xs={6}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Button
                disabled={!siteAddress}
                onClick={handleLatLng}
                className={classes.calcCoordinates}
              >
                Calculate Coordinates
              </Button>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                }}
              >
                <span className={classes.inputTypeTitle}>Latitude</span>
                <TextField value={lat} disabled variant="outlined" />
              </div>
            </div>
          </GridItem>

          <GridItem className={classes.marginTop} xs={6}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                }}
              >
                <span className={classes.inputTypeTitle}>Longitude</span>
                <TextField value={lng} disabled variant="outlined" />
              </div>
              <Button
                style={{ background: "#D40707", color: "white" }}
                className={classes.calcCoordinates}
                onClick={clearLocation}
              >
                Clear Location
              </Button>
            </div>
          </GridItem>

          <GridItem className={classes.marginTop} xs={12}>
            <span
              style={{ margin: "15px 0px" }}
              className={classes.siteAddressInfo}
            >
              To use the map to calculate coordinates, enter an address in the
              search box or drag and drop the red pin.
            </span>
          </GridItem>

          <GridItem className={classes.marginTop} xs={12}>
            <GoogleMap siteAddress={siteAddress} lat={lat} lng={lng} />
          </GridItem>
        </GridContainer>
      </Card>

      <Card className={classes.card}>
        <GridContainer>
          <GridItem className={classes.columnGridItem} xs={4}>
            <span className={classes.inputTypeTitle}>Notes (optional)</span>
            <TextareaAutosize
              aria-label="empty textarea"
              className={classes.emailInput}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder=""
              style={{ maxWidth: "100%", minHeight: "100px" }}
            />
          </GridItem>

          <GridItem className={classes.columnGridItem} xs={4}>
            <span className={classes.inputTypeTitle}>
              Internal Notes (only viewed here)
            </span>
            <TextareaAutosize
              aria-label="empty textarea"
              className={classes.emailInput}
              value={internalNotes}
              onChange={(e) => setInternalNotes(e.target.value)}
              placeholder=""
              style={{ maxWidth: "100%", minHeight: "100px" }}
            />
          </GridItem>

          <GridItem className={classes.columnGridItem} xs={4}>
            <span className={classes.inputTypeTitle}>Contacts</span>
            <Autocomplete
              id="country-select-demo"
              // style={{ width: 180 }}
              // value={contactsString}
              options={customerData.map((c) => c.name)}
              classes={{
                option: classes.option,
              }}
              onChange={(e) => {
                const index = e.target.id.split("-")[4];
                if (index) {
                  console.log("indexxxx", index, e.target.id);
                  if (contacts.indexOf(customerData[index]) === -1) {
                    setContacts((prevState) => [
                      ...prevState,
                      customerData[index],
                    ]);
                  }
                }
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  // value={contactsString}
                  // onChange={(e) => setContactsString(e.target.value)}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      adornedEnd: classes.adournedEnd,
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.adournedEnd}
                        position="end"
                      >
                        <SearchIcon
                          className={classes.calendarIcon}
                          // onClick={openCalendar}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {contacts.length > 0 &&
              contacts.map((c, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <div>
                      <span className={classes.nameIndex}>{index + 1}.</span>
                      <span
                        onClick={() =>
                          history.push(`/admin/customers/${c.customerId}`)
                        }
                        className={classes.nameLink}
                      >
                        {c.name}
                      </span>
                    </div>
                    <span
                      onClick={() => {
                        setContacts((prevState) => [
                          ...prevState.filter(
                            (d, filterIndex) => index !== filterIndex
                          ),
                        ]);
                      }}
                      className={classes.nameRemove}
                      style={{ color: "red" }}
                    >
                      Remove
                    </span>
                  </div>
                );
              })}
          </GridItem>
        </GridContainer>
      </Card>

      <GridContainer>
        <GridItem xs={6}>
          <Button
            style={{ background: "#323232", color: "white" }}
            className={classes.cancelSave}
          >
            Cancel
          </Button>
        </GridItem>
        <GridItem className={classes.end} xs={6}>
          <Button
            style={{ background: "#FFC821", color: "#323232" }}
            className={classes.cancelSave}
            onClick={saveSite}
            disabled={!lat || !lng}
          >
            Save
          </Button>
        </GridItem>
      </GridContainer>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent.includes("Successfully") ? "success" : "error"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    territoriesData: state.territories.territories,
    customerData: state.customers.customers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSaveTerritory: (
    territoryData,
    setAlert,
    setSavedPolygon,
    setNewTerritoryColor
  ) =>
    dispatch(
      saveTerritory(
        territoryData,
        setAlert,
        setSavedPolygon,
        setNewTerritoryColor
      )
    ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(addNewSiteStyle)
)(React.memo(EditSite));
