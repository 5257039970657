import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import addProductStyle from "assets/jss/material-dashboard-pro-react/views/addProductStyle.js";
import { ReactComponent as EditIcon } from "assets/img/editIcon.svg";
import Button from "@material-ui/core/Button";
import { db, firebase } from "firebase/client";
import { ReactComponent as CheckIcon } from "assets/img/checkIcon.svg";
import { getAuth, updateEmail } from "firebase/auth";
import Dialog from "@material-ui/core/Dialog";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Alert from "@material-ui/lab/Alert";
import { ReactComponent as Eyecon } from "assets/img/eyecon.svg";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import InputAdornment from "@material-ui/core/InputAdornment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddProduct(props) {
  const { classes, history, userInfo } = props;

  const [type, setType] = useState({ dumpsters: false, trucks: true });

  const [recMileage, setRecMileage] = useState("");
  const [description, setDescription] = useState("");
  const [reminderForMap, setReminderForMap] = useState([
    "All Trucks",
    "Truck #1",
    "Truck #2",
    "Truck #3",
    "Truck #4",
  ]);
  const [reminderFor, setReminderFor] = useState("All Trucks");

  const [snackBarContent, setSnackBarContent] = useState("");

  const uid = firebase.auth().currentUser.uid;
  const user = firebase.auth().currentUser;

  const handleSelectChange = (e) => {
    setReminderFor(e.target.value);
  };

  const saveBillingChanges = () => {
    // return db
    //   .collection("users")
    //   .doc(uid)
    //   .update({
    //     billing: {
    //       billingAddress,
    //       cardHolderName,
    //       cardNumber,
    //       cardType,
    //       expiration,
    //       notes,
    //       primaryOrSecondary: primaryOrSecondary.primary
    //         ? "Primary"
    //         : "Secondary",
    //     },
    //   })
    //   .then(() => {
    //     setSnackBarContent("Succesfully Updated Billing");
    //     history.push("/admin/profile");
    //   });
  };

  return (
    <GridContainer>
      <GridItem xs={8} style={{ display: "flex" }}>
        <h1 style={{ marginBottom: "30px" }} className={classes.title} align="left">
          Create New Service Reminder
        </h1>
      </GridItem>
      <GridItem xs={4} />
      <Card style={{ marginTop: "0px" }} className={classes.card}>
        <GridContainer>
          <GridItem className={classes.columnGridItem} xs={5}>
            <span className={classes.inputTypeTitle}>
              Type of Service Reminder
            </span>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "45%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Radio
                  checked={type.trucks}
                  onChange={() => setType({ trucks: true, dumpsters: false })}
                  sx={{
                    "&, &.Mui-checked": {
                      color: "blue",
                    },
                  }}
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                  }}
                />
                <span className={classes.values}>Trucks</span>
              </div>
              <div
                style={{
                  width: "45%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "blue",
                    },
                  }}
                  checked={type.dumpsters}
                  onChange={() => setType({ trucks: false, dumpsters: true })}
                  value="a"
                  aria-label="A"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
                <span className={classes.values}>Dumpsters</span>
              </div>
            </div>
          </GridItem>
          <GridItem xs={7} className={classes.columnGridItem}>
            <span className={classes.inputTypeTitle}>Set Reminder For</span>
            <Select
              value={reminderFor}
              variant="outlined"
              label="Set Reminder For"
              defaultValue="All Trucks"
              onChange={handleSelectChange}
            >
              {reminderForMap.map((item) => {
                return (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </GridItem>
        </GridContainer>
      </Card>
      <Card
        className={classes.card}
        style={{ marginTop: "1rem", marginBottom: "2rem" }}
      >
        <GridContainer>
          <GridItem xs={5} className={classes.columnGridItem}>
            <span className={classes.inputTypeTitle}>
              {type.dumpsters ? "Recommended Timeframe" : "Recommended Mileage"}
            </span>
            <TextField
              value={recMileage}
              onChange={(e) => setRecMileage(e.target.value)}
              variant="outlined"
            />
          </GridItem>
          <GridItem xs={7} className={classes.columnGridItem}>
            <span className={classes.inputTypeTitle}>Description</span>
            <TextField
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
            />
          </GridItem>
        </GridContainer>
      </Card>
      <GridItem className={classes.spaceBetween} xs={12}>
        <Button
          style={{ background: "#323232", color: "white" }}
          className={classes.cancelSaveButton}
          onClick={() => history.push("/admin/service-reminders")}
        >
          Cancel
        </Button>
        <Button
          onClick={saveBillingChanges}
          style={{
            background: "#FFC821",
            color: "#323232",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
          className={classes.cancelSaveButton}
        >
          <CheckIcon />
          Save
        </Button>
      </GridItem>

      <Snackbar open={snackBarContent} onClose={() => setSnackBarContent("")}>
        <Alert
          onClose={() => setSnackBarContent("")}
          severity={
            snackBarContent !== "Succesfully Updated Billing"
              ? "error"
              : "success"
          }
        >
          {snackBarContent}
        </Alert>
      </Snackbar>
    </GridContainer>
  );
}

const mapStateToProps = (state) => {
  console.log("customer state", state);
  return {
    authError: state.auth.error,
    authenticated: state.auth.authenticated,
    customersData: state.customers.customers,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(addProductStyle)
)(React.memo(AddProduct));
