import React, { useState, useEffect } from "react";
import { firebase, db } from "firebase/client";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import reservationFormStyle from "assets/jss/material-dashboard-pro-react/views/reservationFormStyle.js";
import { ReactComponent as Logo } from "assets/img/biggerLogo.svg";

function Submitted(props) {
  const { classes } = props;

  return (
    <Card className={classes.card}>
      <h1 className={classes.title}>We have received your quote request!</h1>
      <span className={classes.receivedRequest}>
        Please note that your quote is being reviewed. You will be contacted via
        your commuication preference. Thank you!
      </span>
      <Logo className={classes.humptyLogo} />
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(reservationFormStyle)
)(React.memo(Submitted));
